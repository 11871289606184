import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import Utils from '../../utils';

@Component({
  selector: 'app-sort-list',
  templateUrl: './sort-list.component.html'
})
export class SortListComponent implements OnInit, OnChanges {
  @Input() sortingArray;
  @Input() isProject;
  selectedSortOption = '';
  @Input() sortOptions;
  @Input() labelFor = 'Sort by';
  @Input() defaultSortOption: any;
  @Output() sortByoption: EventEmitter<any>;

  constructor() { 
    this.sortByoption=new EventEmitter<any>();
  }

  ngOnInit() {
    if (this.defaultSortOption) {
      this.selectedSortOption = this.defaultSortOption.display;
    }
  }

  ngOnChanges() {
    if (this.sortingArray && this.sortingArray.length) {
      // Utils.sortDataArray(this.sortingArray, this.isProject ? 'project_name' : 'title', false, true);
    }
  }

  sortDefalut(selectedSortOption) {
    this.selectedSortOption = selectedSortOption;
    this.sortData(this.sortingArray, 'created_at', true, false,null);
  }

  sortPublished(selectedSortOption) {
    this.selectedSortOption = selectedSortOption;
    this.sortData(this.sortingArray, 'updated_at', true, false,null);
  }

  sortData(event, type, isDate, isAsce,data) {
    if (document.getElementById('sortDropdownBtn')) {
      document.getElementById('sortDropdownBtn').focus();
    }
    if (event && event.target) {
      this.selectedSortOption = event.target.innerHTML;
      this.sortByoption.emit(data);
    }
    if (this.sortingArray) {
      return Utils.sortDataArray(this.sortingArray, type, isDate, isAsce);
    }
  }
}
