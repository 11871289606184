import {
  Component,
  OnInit
} from '@angular/core';
import {
  LoginService
} from './login.service';
import {
  Router, ActivatedRoute, Params
} from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import {
  HostListener
} from '@angular/core';
import {
  CustomValidators
} from 'ng2-validation';
import {
  GlobalSettings
} from '../global.settings';
import {
  CommonService
} from '../common.service';
import {
  SharedService
} from '../shared.service';
import Utils from '../utils';
import { IndexedDBService } from '../services/indexed-db.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './../nav-bar/nav-bar-logo-img.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  username: FormControl;
  password: FormControl;
  erromessage = true;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  userEmail;
  enableResetButton = false;
  resetFormEnabled = false;
  passwordReset = false;
  organization_id = '';
  loginLoader = false; // holds loading state for login response
  resetPasswordURL = ''; // holds URL to navigate for reset password
  isSSOUser = GlobalSettings.SSO_USER; // holds boolean to determine whether to run application as standalone

  constructor(
    private service: LoginService,
    private router: Router,
    private fb: FormBuilder,
    private commonService: CommonService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private indexedDBService: IndexedDBService) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: [null, Validators.compose([Validators.required])],
    });
    if (localStorage.getItem('register_mail')) {
      this.form.value.username = localStorage.getItem('register_mail');
      localStorage.removeItem('register_mail');
    }
    // Remove the popup backdroap
    if (document.getElementsByClassName('modal-backdrop fade show')) {
      const obj = document.getElementsByClassName('modal-backdrop fade show');
      if (obj && obj.length > 0) {
        obj[0].classList.remove('modal-backdrop');
      }
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['reset_password'] !== undefined && (params['reset_password'].trim() === 'reset_password')) {
        this.resetPasswordForm();
      } else {
        const org = this.commonService.getOrgDetails();
        if (org) {
          const path = org + '/' + Utils.ROUTE_LOGIN;
          this.router.navigate([path]);
        }
      }
    });
    document.title = 'ACMT : Login';
    const elem = document.getElementById(this.resetFormEnabled ? 'fp_title' : (!this.resetFormEnabled && !this.passwordReset
      ? 'login_title' : ''));
    if (elem) {
      elem.focus();
    }

    const environment = GlobalSettings.ENVIRONMENT ? GlobalSettings.ENVIRONMENT : 'dev';
    // this.resetPasswordURL = 'https://' + GlobalSettings.DOMAIN + '-' + environment + '.learningmate.co/#/forget-password?app=standards';
    setTimeout(() => {
      if (document.getElementById('emailID')) {
        document.getElementById('emailID').focus();
      }
    }, 500);
  }

  signin() {
    console.log('data111', this.form);
    if (this.form.valid) {
      this.loginLoader = true;
      console.log('form submitted', sessionStorage.getItem('current_url'));
      this.service.login(this.form.value).then((respones: any) => {
        this.callProjectPermissionAPI();
        this.getUserPermission();
        console.log('resone ', respones);
        this.commonService.setOrgDetails(respones.organization);
        if (sessionStorage.getItem('current_url') && (sessionStorage.getItem('current_url').indexOf('/login') !== -1
          || sessionStorage.getItem('current_url').indexOf('/home') !== -1)) {
          sessionStorage.clear();
        }
        if (sessionStorage.getItem('current_url') && sessionStorage.getItem('current_url').split(',')[0].length > 1) {
          if (sessionStorage.getItem('current_url').split(',')[0] === '/app/login') {
            if (respones && respones.system_role &&
              respones.system_role.role_code && respones.system_role.role_code === 'PPR00') {
              const path = this.commonService.getOrgDetails() + '/' + Utils.ROUTE_PUBLIC_REVIEW_LIST;
              this.router.navigate([path]);
            } else {
              const permissions = respones.system_role_permissions; // holds entire permission list
              const role = respones.system_role_permissions.role_user_permissions; // holds role permissions
              const permissionArr = []; // holds available permissions for the user

              let path: any; // holds url to redirect after successful login

              // storing valued permission names into the array
              for (const key in permissions) {
                if (permissions[key].length) {
                  permissionArr.push(key);
                }
              }

              if (permissionArr.length === 0) {
                // when user has no permission
                this.sharedService.sucessEvent.next({
                  type: 'no_permission'
                });
                path = this.commonService.getOrgDetails() + '/' + Utils.ROUTE_HOME;
              } else {
                // when user has permissions
                path = this.commonService.getOrgDetails() + '/' + this.findPath(role, permissionArr);
              }

              this.router.navigate([path]);
            }
          } else {
            this.router.navigate([sessionStorage.getItem('current_url').split(',')[0]]);
          }
        } else {

          if (respones && respones.system_role &&
            respones.system_role.role_code && respones.system_role.role_code === 'PPR00') {
            const path = this.commonService.getOrgDetails() + '/' + Utils.ROUTE_PUBLIC_REVIEW_LIST;
            this.router.navigate([path]);
          } else {
            const permissions = respones.system_role_permissions; // holds entire permission list
            const role = respones.system_role_permissions.role_user_permissions; // holds role permissions
            const permissionArr = []; // holds available permissions for the user

            let path: any; // holds url to redirect after successful login

            // storing valued permission names into the array
            for (const key in permissions) {
              if (permissions[key].length) {
                permissionArr.push(key);
              }
            }

            if (permissionArr.length === 0) {
              // when user has no permission
              this.sharedService.sucessEvent.next({
                type: 'no_permission'
              });
              path = this.commonService.getOrgDetails() + '/' + Utils.ROUTE_HOME;
            } else {
              // when user has permissions
              path = this.commonService.getOrgDetails() + '/' + this.findPath(role, permissionArr);
            }

            this.router.navigate([path]);
          }
        }
        this.loginLoader = false;
      }).catch(ex => {
        this.callProjectPermissionAPI();
        this.erromessage = false;
        console.log('signin ex ', ex);
        this.sharedService.sucessEvent.next({
          customMsg: ex.error.message,
          type: 'login_error'
        });
        // this.form.reset();
        this.loginLoader = false;
      });
    } else {
      this.validateAllFormFields(this.form);
    }
  }


  // FUNCTION to call API for project permissions

  callProjectPermissionAPI() {
    const url = GlobalSettings.GET_PROJECT_PERMISSION;
    this.commonService.getServiceData(url).then((res: any) => {
      Utils.projectPermissions = res;
      this.indexedDBService.addData((res), 'project_role_permissions');
      this.sharedService.projectPermissionLoaded.next(true);
    });
  }

  findPath(role, permissionArr) {
    let path;
    if (role.length > 0 && (this.hasPermission(role, 'View Home Screen') ||
      this.hasPermission(role, 'View Additional Statistics'))) {
      path = Utils.ROUTE_HOME;
    } else {
      if (permissionArr.find((item: any) => item.includes('taxonomy_permissions'))) {
        path = Utils.ROUTE_TAXONOMY_LIST;
      } else {
        if (permissionArr.find((item: any) => item.includes('project_permissions'))) {
          path = Utils.ROUTE_PROJECT_LIST;
        } else {
          if (permissionArr.find((item: any) => item.includes('pacing_guide_permissions'))) {
            path = Utils.ROUTE_PACING_GUIDE_LIST;
          } else {
            if (permissionArr.find((item: any) => item.includes('public_review_permissions'))) {
              path = Utils.ROUTE_PUBLIC_REVIEW_LIST;
            } else {
              if (role.length) {
                path = Utils.ROUTE_ADMIN_TEAM;
              } else {
                if (permissionArr.find((item: any) => item.includes('workflow_permissions'))) {
                  path = Utils.ROUTE_ADMIN_WORKFLOW_LIST;
                } else {
                  if (permissionArr.find((item: any) => item.includes('metadata_permissions'))) {
                    path = Utils.ROUTE_ADMIN_METADATA_LIST;
                  } else {
                    if (permissionArr.find((item: any) => item.includes('node_template_permissions'))) {
                      path = Utils.ROUTE_ADMIN_NODETEMPLATE_LIST;
                    } else {
                      if (permissionArr.find((item: any) => item.includes('note_permissions'))) {
                        path = Utils.ROUTE_ADMIN_CASESERVER_LIST;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
    return path;

  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      // console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  click() {
    this.erromessage = true;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    window.history.forward();
  }

  onClose() {
    this.erromessage = true;
  }

  /* --------- On key up event handler to check to user name form control validation functionality start--------- */
  onModelChange() {
    if (this.form.controls.username.valid) {
      this.enableResetButton = true;
    } else {
      this.enableResetButton = false;
    }
  }
  /* --------- On key up event handler to check to user name form control validation functionality end --------- */

  /* --------- On click of reset anchor tag in login form functionality start --------- */
  resetPasswordForm() {
    if (this.isSSOUser) {
      const orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
      const path = GlobalSettings.UNIFIED_FORGOT_PASSWORD; // + '/' + orgCode;
      window.location.href = path;
    } else {
      this.resetFormEnabled = true;
      this.enableResetButton = false;
      this.passwordReset = false;
      this.form.controls.username.reset();
      document.title = 'ACMT : Forgot Password';
      setTimeout(() => {
        focusOnEmailInputBox();
      }, 500);
    }
  }
  /* --------- On click of reset anchor tag in login form functionality end --------- */


  /* --------- On click of reset password button in reset password form functionality start --------- */
  resetPassword() {
    const url = GlobalSettings.GENERATE_RESET_PASSWORD_LINK;
    if (localStorage.getItem('orgDetails')) {
      this.organization_id = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }
    const createObj = {
      'email': this.userEmail,
      'auth_user': {
        'organization_id': this.organization_id
      }
    };

    this.commonService.registerService(url, createObj).then((res: any) => {
      document.title = 'ACMT : Reset Password';
      this.passwordReset = true;
      this.resetFormEnabled = false;
      this.enableResetButton = false;
      localStorage.setItem('forgot_mail', this.userEmail);
    }).catch((ex) => {
      console.log('Error caught while generating reset link', JSON.stringify(ex));
      this.erromessage = false;
      this.sharedService.sucessEvent.next({
        customMsg: ex.msg,
        type: 'login_error'
      });
      setTimeout(() => {
        this.erromessage = true;
      }, 8000);
    });





  }
  /* --------- On click of reset password button in reset password form functionality end --------- */

  /* --------- On click of ok button in acknowledge screen after password reset functionality start --------- */

  redirectToLogin() {
    this.cancel();
  }
  /* --------- On click of ok button in acknowledge screen after password reset functionality end --------- */

  /* --------- On click of cancle in reset password form functionality start --------- */
  cancel() {
    document.title = 'ACMT : Login';
    this.form.reset();
    this.resetFormEnabled = false;
    this.passwordReset = false;
    this.enableResetButton = false;
    this.userEmail = '';
    this.onClose();
    const path = this.commonService.getOrgDetails() + '/' + Utils.ROUTE_LOGIN;
    this.router.navigate([path]);
    setTimeout(() => {
      if (document.getElementById('emailID')) {
        document.getElementById('emailID').focus();
      }
    }, 500);
  }
  /* --------- On click of cancle in reset password form functionality end --------- */


  navigateToRegister() {
    const org = this.commonService.getOrgDetails();
    if (localStorage.getItem('orgDetails') && org) {
      const path = org + '/' + Utils.ROUTE_REGISTER;
      this.router.navigate([path]);
    }
  }

  hasPermission(array, name) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.display_name === name) {
        return true;
      }
    }
    return false;
  }


  /* --------- Functionality for fetching permissions on login & tenant switch start --------- */

  getUserPermission() {
    const url = GlobalSettings.GET_PERMISSION + '' + localStorage.getItem('role_id');
    if (localStorage.getItem('access_token')) {
      this.commonService.getServiceData(url).then((res: any) => {
        // console.log(' getUerPemfsfsfsfs ', res);
        localStorage.setItem('permissions', JSON.stringify(res));
        this.commonService.role_user_permissions = res.role_permissions.role_user_permissions;
        this.commonService.project_permissions = res.role_permissions.project_permissions;
        this.commonService.taxonomy_permissions = res.role_permissions.taxonomy_permissions;
        this.commonService.workflow_permissions = res.role_permissions.workflow_permissions;
        this.commonService.metadata_permissions = res.role_permissions.metadata_permissions;
        this.commonService.note_permissions = res.role_permissions.note_permissions;
        this.commonService.comment_permissions = res.role_permissions.comment_permissions;
        this.commonService.node_template_permissions = res.role_permissions.node_template_permissions;
        this.commonService.node_type_permissions = res.role_permissions.node_type_permissions;
        this.commonService.notification_permissions = res.role_permissions.notification_permissions;
        this.sharedService.userPermissionLoaded.next(true);
      });
    }
  }

  /* --------- Functionality for fetching permissions on login & tenant switch end --------- */


}
