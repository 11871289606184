

import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html'
})
export class DropDownComponent implements OnInit {

  list;
  selectedItem;

  constructor() {}

  ngOnInit() {}

  onChanged(event) {

  }
}
