import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import Utils from '../../utils';
import { Router, NavigationEnd } from '@angular/router';
import {
  Subscription
} from 'rxjs/Subscription';
import { SharedService } from '../../shared.service';
import {
  environment
} from '../../../environments/environment';

@Component({
  selector: 'app-front-facing-home',
  templateUrl: './front-facing-home.component.html',
  styleUrls: ['./front-facing-home.component.scss']
})
export class FrontFacingHomeComponent implements OnInit, OnDestroy {

  blocks = []; // holds the home page blocks
  orgId = ''; // holds the organistion if from local storage
  taxonomyList = [];

  eventSubscription: Subscription;
  currentUrl = null;
  showPublishedTaxonomy = true; // holds boolean to show if published taxonomy needs to be viewed based on environment selected

  constructor(private service: CommonService, private router: Router, private sharedService: SharedService) {
    // this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
    //   setTimeout(() => {
    //     if (event.url.indexOf('/home') >= 0) {
    //       if (document.getElementById('Home-button')) {
    //         const element = document.getElementById('Home-button');
    //         element.className += ' active';
    //       }
    //     }
    //   }, 100);
    // });

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      if (event.url.indexOf('/home') >= 0) {
        this.currentUrl = 'home';
      }
    });
    this.eventSubscription = this.sharedService.activeLink.subscribe((events: any) => {
    });


  }

  ngOnInit() {
    setTimeout(() => {
      if (document.getElementById('Home-button') && this.router.url.split('/')[2] === 'home') {
        document.getElementById('Home-button').click();
      }
      this.getHomePageBlocks();
      this.getTaxonomiesList();
    }, 1500);


    for (const i in environment.tenant) {
      if (i && this.router.url.split('/')[2] === environment.tenant[i]) {
        this.showPublishedTaxonomy = false;
        break;
      } else {
        this.showPublishedTaxonomy = environment.showPublishedTaxonomy;
        // tslint:disable-next-line: max-line-length
        const { renamePacingGuide, showPublishedTaxonomy } = Utils.checkChangesForSpecificOrg(this.showPublishedTaxonomy, this.router.url.split('/')[2]);
        this.showPublishedTaxonomy = showPublishedTaxonomy;
      }
    }

  }


  /* --------- Fetch Notes List functionality Started --------- */

  getHomePageBlocks() {
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
      console.log('#### path front-facing-home if', this.orgId);
    } else {
      console.log('#### path front-facing-home else', this.orgId);
    }

    const url = GlobalSettings.HOME_PAGE_BLOCK_CACHE + '/' + this.orgId;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.blocks = res;
    });
  }


  /* --------- Fetch Notes List functionality End --------- */

  /* --------- Get taxonomies functionality Started --------- */
  getTaxonomiesList() {
    this.taxonomyList = [];
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }
    const url = GlobalSettings.FRONT_FACE_TAXONOMY_LIST + '/' + this.orgId;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.taxonomyList = res;
      this.taxonomyList = this.taxonomyList.slice(0, 5);
    }).catch((ex) => {
      console.log('getTaxonomiesList Ex', ex);
    });
  }
  /* --------- Get taxonomies functionality Ended --------- */

  navigateTaxonomy(data) {
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_FRONT_FACE_TAXONOMY_DETAIL + '/' + data['document_id'];
    this.router.navigate([path]);
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

}
