import {
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import {
  CommonModule
} from '@angular/common';
import {
  SortListComponent
} from '../common/sort-list/sort-list.component';
import {
  TreeAccordianComponent
} from '../tree-accordian/tree-accordian.component';
import {
  TreeTabComponent
} from '../tree-tab/tree-tab.component';
import {
  TabularTreeViewComponent
} from '../common/tabular-tree-view/tabular-tree-view.component';
import {
  TabularTreeCellComponent
} from '../common/tabular-tree-cell/tabular-tree-cell.component';
import {
  TabularTreeSidePaneComponent
} from '../common/tabular-tree-side-pane/tabular-tree-side-pane.component';
import {
  TableConfigurationComponent
} from '../common/table-configuration/table-configuration.component';

import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  Shared3Module
} from './shared3/shared3.module';
import { LazyTabularTreeComponent } from '../common/lazy-tabular-tree/lazy-tabular-tree.component';


// import { TreeComponent } from '../common/tree/tree.component';
import { BuildTaxonomyComponent } from '../taxonomy/build-taxonomy/build-taxonomy.component';
import { TreeReorderComponent } from '../common/tree-reorder/tree-reorder.component';
import { PacingGuideTreeReorderComponent } from '../common/pacing-guide-tree-reorder/pacing-guide-tree-reorder.component';
import { SearchComponent } from '../common/search/search-panel/search-panel.component';
// import { ClickOutsideModule } from 'ng-click-outside';
import { SearchFilterComponent } from '../common/search/search-filter/search-filter.component';
import { AdvanceSearchComponent } from '../common/advance-search/advance-search.component';
import { ItemDetailModule } from './item-detail/item-detail.module';
import { CopyClipboardDirective } from '../common/directives/copy-clipboard/copy-clipboard.directive';
import { AcmtSearchComponent } from '../common/acmt-search-block/acmt-search/acmt-search.component';
import { AcmtSearchListComponent } from '../common/acmt-search-block/acmt-search-list/acmt-search-list.component';
import { AppPasswordDirective } from '../common/directives/show-hide-password/app-password.directive';
import { PreLoaderModule } from './preloader/pre-loader.module';
import { DragulaModule } from 'ng2-dragula';
import { TreeModule } from './tree.module';
import { AngularSplitModule } from 'angular-split';
import { SelectTaxonomyComponent } from '../common/select-taxonomy/select-taxonomy.component';
import { DragDropListModule } from 'ng-drag-drop-list';
import { NodeTypeTableViewComponent } from '../common/node-type-table-view/node-type-table-view.component';
import { CustomTableViewComponent } from '../common/custom-table-view/custom-table-view.component';
import { ExportAsModule } from 'ngx-export-as';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { VirtualScrollModule } from 'ngx-virtual-scroll-plus';
import { LazyTreeComponent } from '../common/lazy-tree/lazy-tree.component';


@NgModule({
  imports: [CommonModule, FormsModule,
    ReactiveFormsModule, Shared3Module,
    // ClickOutsideModule,
    ItemDetailModule,
    PreLoaderModule,
    DragulaModule,
    TreeModule,
    AngularSplitModule.forRoot(),
    DragDropListModule,
    ExportAsModule,
    ScrollingModule,
    // VirtualScrollModule,
  ],
  exports: [
    CommonModule, SortListComponent,
    TreeTabComponent,
    TabularTreeViewComponent,
    TabularTreeCellComponent,
    TabularTreeSidePaneComponent,
    BuildTaxonomyComponent,
    TreeReorderComponent,
    PacingGuideTreeReorderComponent,
    // TreeComponent,
    SearchComponent,
    // ClickOutsideModule,
    SearchFilterComponent,
    AdvanceSearchComponent,
    AcmtSearchComponent,
    CopyClipboardDirective,
    AcmtSearchListComponent,
    TableConfigurationComponent,
    AppPasswordDirective,
    TreeModule,
    AngularSplitModule,
    SelectTaxonomyComponent,
    NodeTypeTableViewComponent,
    CustomTableViewComponent,
    LazyTabularTreeComponent,
    LazyTreeComponent
  ],
  declarations: [
    SortListComponent,
    TreeTabComponent,
    TabularTreeViewComponent,
    TabularTreeCellComponent,
    TabularTreeSidePaneComponent,
    BuildTaxonomyComponent,
    TreeReorderComponent,
    PacingGuideTreeReorderComponent,
    // TreeComponent,
    SearchComponent,
    SearchFilterComponent,
    AdvanceSearchComponent,
    AcmtSearchComponent,
    CopyClipboardDirective,
    AcmtSearchListComponent,
    TableConfigurationComponent,
    AppPasswordDirective,
    SelectTaxonomyComponent,
    NodeTypeTableViewComponent,
    CustomTableViewComponent,
    LazyTabularTreeComponent,
    LazyTreeComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class PublicModule { }
