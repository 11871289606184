import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appPassword]'
})
export class AppPasswordDirective {

  @Input() private shown = false;

  constructor(private el: ElementRef) {
    this.initSetup();
  }

  toggle(elem: HTMLElement) {
    this.shown = !this.shown;
    if (this.shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      this.setBtnProprty('show', elem);
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      this.setBtnProprty('hide', elem);
    }
  }

  initSetup() {
    const parent = this.el.nativeElement.parentNode;
    const btn = document.createElement('button');
    btn.className = 'btn btn-icon px-2';
    this.setBtnProprty('hide', btn);
    btn.addEventListener('click', (event) => {
      this.toggle(btn);
    });
    parent.appendChild(btn);
  }

  private setBtnProprty(type: string, htmlElement: HTMLElement) {
    if (type === 'show') { // display passwoed field
      htmlElement.setAttribute('aria-label', 'Show Password');
      htmlElement.innerHTML = `<em class="fa fa-eye-slash fa-fw"></em>`;
    } else if (type === 'hide') { // hide passwoed field
      htmlElement.setAttribute('aria-label', 'Hide Password');
      htmlElement.innerHTML = '<em class="fa fa-eye fa-fw"></em>';
    }
  }

}
