import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-view-type',
  templateUrl: './view-type.component.html'
})
export class ViewTypeComponent implements OnInit {
  viewType = 'listview';
  @Output() selectedViewTypeEvent: EventEmitter <any>;

  constructor() {
    this.selectedViewTypeEvent = new EventEmitter <any> ();
  }

  ngOnInit() {
    this.changeView(this.viewType);
  }

  changeView(type) {
    this.viewType = type;
    this.selectedViewTypeEvent.emit(type);
  }

}
