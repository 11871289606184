import {
  environment
} from '../environments/environment';
export class GlobalSettings {
  public static BASE_URL = environment.base_url;
  public static AUTH_URL = environment.auth_url;
  public static VERSION = environment.version;
  public static ENVIRONMENT = environment.env;
  public static DOMAIN = environment.domain;
  public static UNIFIED_LOGOUT = environment.unifiedLogout;
  public static UNIFIED_LOGIN = environment.unifiedLogin;
  public static UNIFIED_ADMIN = environment.unifiedAdmin;
  public static UNIFIED_FORGOT_PASSWORD = environment.unifiedForgotPassword;
  public static SSO_USER = environment.SSO_USER;
  public static CLIENT_SECRET = environment.client_secret;
  public static CLIENT_ID = environment.client_id;
  public static GRANT_TYPE = environment.grant_type;
  public static ADMIN_HOST = environment.adminHost;
  public static API_HOST_AUTH_URL = environment.apiHostAuthServer;
  public static LOGIN_URL = GlobalSettings.VERSION + 'validateLogin';
  public static SSO_LOGOUT_URL = GlobalSettings.VERSION + 'logoutUser';
  public static LOGOUT_URL = GlobalSettings.VERSION + 'accounts/revoke';
  public static TAXONOMY_LIST = GlobalSettings.VERSION + 'taxonomy/list';
  public static TAXONOMY_SEARCH = GlobalSettings.VERSION + 'taxonomy/filter/title?search_key=';
  public static PROJECT_LIST = GlobalSettings.VERSION + 'projects'; // projects,'getProjectList'
  public static WORKFLOW_LIST = GlobalSettings.VERSION + 'workflows'; // 'getworkflowlist';
  public static CG_PACKAGE = GlobalSettings.VERSION + 'getCFPackage/';
  public static PROJECT_SEARCH = GlobalSettings.VERSION + 'projects?search=';
  public static GET_TREE_VIEW = GlobalSettings.VERSION + 'taxonomy/getTreeHierarchy/';
  public static GET_TREE_VIEW_ENHANCED = 'api/v2/taxonomy/getTreeHierarchy/';
  public static GET_TREE_VIEW_ENHANCED_V5 = 'api/v2/taxonomy/getTreeHierarchyV5/';
  public static GET_TREE_VIEW_V4 = 'api/v2/taxonomy/getTreeHierarchyV4/';
  public static GET_TREE_DETAILS_ENHANCED = 'api/v2/taxonomy/getTreeDetails/';
  public static GET_PROJECT_TREE_VIEW_ENHANCED = 'api/v2/project/getTreeHierarchy/';
  public static GET_PROJECT_TREE_DETAILS_ENHANCED = 'api/v2/project/getTreeDetails/';


  public static GENERATE_RESET_PASSWORD_LINK = GlobalSettings.VERSION + 'forgotPassword';
  public static FORGOT_PASSWORD = GlobalSettings.VERSION + 'resetPassword';
  public static VALIDATE_USER = GlobalSettings.VERSION + 'checkResetPasswordToken/';
  public static REGENERATE_TOKEN = GlobalSettings.VERSION + 'regenerateAccessToken';
  public static VALIDATE_USER_TOKEN = GlobalSettings.VERSION + 'validateUser';
  public static GET_PROJECT_PERMISSION = GlobalSettings.VERSION + 'projectRolePermission';



  /** PROJECT AUTHORING */
  public static GET_MAPPED_NODES = GlobalSettings.VERSION + 'projects/nodes_mapped';
  public static GET_MAPPED_NODES_ALL = GlobalSettings.VERSION + 'projects/nodesWithTaxonomyTree';
  public static GET_MAPPED_NODES_ALL_ENHANCED = 'api/v2/project/nodesWithTaxonomyTree';
  public static GET_PROJECT_ASSOCIATED_NODES = GlobalSettings.VERSION + 'projects/nodes';
  public static GET_PROJECT_DETAIL = GlobalSettings.VERSION + 'projects';
  public static GET_CASE_ASSOCIATION_TYPES = GlobalSettings.VERSION + 'caseAssociation/listTypes';
  public static GET_TREE_ITEM_DETAIL = GlobalSettings.VERSION + 'cfitem/fetch';
  public static CREATE_CASE_ASSOCIATION = GlobalSettings.VERSION + 'caseAssociation/create';
  public static DELETE_ITEM_ASSOCIATION = GlobalSettings.VERSION + 'caseAssociation/delete';
  public static EDIT_ITEM_ASSOCIATION = GlobalSettings.VERSION + 'caseAssociation/edit';
  public static ITEM_COMMENT = GlobalSettings.VERSION + 'itemComment';
  public static SAVE_CFDOC_NODE = GlobalSettings.VERSION + 'cfdoc/edit';
  public static GET_CFDOC_ITEM_DETAIL = GlobalSettings.VERSION + 'cfdoc';
  public static EXEMPLER_ASSOCIATION = GlobalSettings.VERSION + 'caseAssociation/exemplar';
  public static ASSET_ASSOCIATION = GlobalSettings.VERSION + 'asset';
  public static PROJECT_COMMENTS = GlobalSettings.VERSION + 'projects/comments';
  public static CREATE_CFITEM_CASE_ASSOCIATION = GlobalSettings.VERSION + 'cfitem/createAssociations';
  public static APPEND_NODES = GlobalSettings.VERSION + 'taxonomy/CopyNodes';


  /** BUILD TAXONOMY */
  public static GET_CFMASTER_DATA = GlobalSettings.VERSION + 'getCFMasterData';
  public static CREATE_TAXONOMY = GlobalSettings.VERSION + 'cfdoc/create';
  public static BUILD_TAXONOMY = GlobalSettings.VERSION + 'taxonomy';
  public static DELETE_TAXONOMY = GlobalSettings.VERSION + 'cfdoc/delete';
  public static CHECK_DELETE_TAXONOMY = GlobalSettings.VERSION + 'cfdoc/checkProject';
  public static SET_TREE_HIERARCHY = GlobalSettings.VERSION + 'taxonomy/setTreeHierarchy';
  public static SET_TREE_HIERARCHY_ENHANCED = 'api/v2/taxonomy/setTreeHierarchy';

  /** UPLOAD CASE JSON */
  // public static IMPORT_CASE_JSON =  GlobalSettings.VERSION + 'importCFPackageJson/';
  public static IMPORT_CASE_JSON = GlobalSettings.VERSION + 'importTaxonomy';

  /** UPLOAD CSV */
  // public static IMPORT_CSV =  GlobalSettings.VERSION + 'importCFPackageCsv';
  public static IMPORT_CSV = GlobalSettings.VERSION + 'importTaxonomyFromCsv';
  public static UPLOAD_CSV = GlobalSettings.VERSION + 'importTaxonomiesFromCsv';
  public static CANCEL_CSV_IMPORT = GlobalSettings.VERSION + 'cancel-csv-import-batch';

  /** CREATE PROJECT */
  public static CREATE_PROJECT = GlobalSettings.VERSION + 'projects';
  public static MAPPED_PROJECT_TAXONOMY = GlobalSettings.VERSION + 'projects/mapTaxonomies';

  /** PROJECT */
  public static PROJECT_ACCESS_REQUEST = GlobalSettings.VERSION + 'projectAccessRequests';

  /** USER PERMISSIONS */
  public static GET_PERMISSION = GlobalSettings.VERSION + 'permissions/';
  public static SET_PERMISSION = GlobalSettings.VERSION + 'roles/permissions/';


  /** ROLES */
  public static CREATE_ROLE = GlobalSettings.VERSION + 'roles';
  public static GET_ROLE = GlobalSettings.VERSION + 'roles';
  public static UPDATE_ROLE_STATUS = GlobalSettings.VERSION + 'roles/status';

  /** USER */
  public static CREATE_USER = GlobalSettings.VERSION + 'users';
  public static GET_USERS = GlobalSettings.VERSION + 'users';
  public static RESEND_EMAIL = GlobalSettings.VERSION + 'users/resendMail';

  /** REGISTER  */
  public static GET_USER_DETAIL_BY_TOCKEN = GlobalSettings.VERSION + 'register';
  public static REGISTER_COMPLETE = GlobalSettings.VERSION + 'registerComplete';
  public static SELF_REGISTRATION_URL = GlobalSettings.VERSION + 'self-register';
  public static GET_ORGANIZATION_DETAILS = GlobalSettings.VERSION + 'getDefaultOrganization';
  public static GET_ORGANIZATION_CODE = GlobalSettings.VERSION + 'orgDetails';
  public static CONFIRM_EMAIL = GlobalSettings.VERSION + 'confirm/';

  /** TAXONOMY */
  public static COPY_TAXONOMY = GlobalSettings.VERSION + 'taxonomy/CopyNodes';
  public static DELETE_NODE = GlobalSettings.VERSION + 'taxonomy/delete';
  public static CREATE_NODE = GlobalSettings.VERSION + 'cfitem/create';
  public static EDIT_NODE = GlobalSettings.VERSION + 'cfitem/edit';
  public static PROJECT_STATUS_TO_PUBLISH = GlobalSettings.VERSION + 'taxonomy/getProjectStatusToPublish';
  public static PUBLISH_TAXONOMY = GlobalSettings.VERSION + 'taxonomy/publish';
  public static TAXONOMY_IMPORT_STATUS = GlobalSettings.VERSION + 'getStatusForBack';
  public static TAXONOMY_PUBLISH_HISTORY = GlobalSettings.VERSION + 'taxonomy/PublishHistory';
  public static DOWNLOAD_PUBLISHED_TAXONOMY = GlobalSettings.VERSION + 'taxonomy/downloadTaxonomy';

  /** TABLE CONFIGURATION */
  public static TABLE_CONFIG = GlobalSettings.VERSION + 'system/config';
  public static TAXONOMY_NODE_TYPE_DETAILS = GlobalSettings.VERSION + 'taxonomy/node_type';


  /** WORKFLOW */
  public static GET_WORKFLOW = GlobalSettings.VERSION + 'workflows';
  public static UPDATE_WORKFLOW_STAGES = GlobalSettings.VERSION + 'workflows/stages/';
  public static DELETE_WORKFLOW_STAGES_ROLE = GlobalSettings.VERSION + 'workflows/stageRoleDelete';
  public static CREATE_WORKFLOW = GlobalSettings.VERSION + 'workflows/create';
  public static CREATE_WORKFLOW_STAGE = GlobalSettings.VERSION + 'workflows/stage';
  public static DELETE_WORKFLOW_STAGE = GlobalSettings.VERSION + 'workflows/stageDelete';
  public static EDIT_WORKFLOW_STAGE = GlobalSettings.VERSION + 'workflows/edit';
  public static DELETE_WORKFLOW = GlobalSettings.VERSION + 'workflows/delete';
  public static DUPLICATE_WORKFLOW = GlobalSettings.VERSION + 'workflows/copy';


  /** REPORTS */
  public static GET_DASHBOARD_DATA = GlobalSettings.VERSION + 'report/getDashboardData';

  /*PROJECT - TEAM */
  public static GET_All_PROJECT_USER = GlobalSettings.VERSION + 'projects/users';
  public static GET_All_WORKFLOW_ROLES = GlobalSettings.VERSION + 'workflows/roles';
  public static ASSIGN_PROJECT_USER = GlobalSettings.VERSION + 'projects/users/assign';
  public static UNASSIGN_PROJECT_USER = GlobalSettings.VERSION + 'projects/users/unassign';

  public static GET_DOCUMENT_DETAIL = GlobalSettings.VERSION + 'cfdoc';

  /** METADATA */
  public static GET_METADATA_LIST = GlobalSettings.VERSION + 'metadata';
  public static CHANGE_STATUS = GlobalSettings.VERSION + 'metadata';
  public static CREATE_CUSTOM_METADATA = GlobalSettings.VERSION + 'metadata';
  public static ITEM_LEVEL_ADDITIONAL_METADATA = GlobalSettings.VERSION + 'cfitem/additional/metadata';
  public static DOCUMENT_LEVEL_ADDITIONAL_METADATA = GlobalSettings.VERSION + 'cfdoc/additional/metadata';
  public static CHECK_META_DATA_USAGE = GlobalSettings.VERSION + 'metadata';


  /** NODETYPE */
  public static GET_NODETYPE_LIST = GlobalSettings.VERSION + 'nodeTypes';
  public static GET_NODETYPE = GlobalSettings.VERSION + 'nodeTypes';
  public static METADATA_SEARCH = GlobalSettings.VERSION + 'metadata?search=';
  public static NODETYPE_SEARCH = GlobalSettings.VERSION + 'nodeTypes?search=';
  public static SAVE_NODETYPE_METADATA = GlobalSettings.VERSION + 'nodeTypeMetadata';
  public static Get_All_Node_Templates = GlobalSettings.VERSION + 'nodeTemplate';
  public static DUPLICATE_NODE_TYPE = GlobalSettings.VERSION + 'nodeTypes/duplicate';
  /** Export Taxonomy */
  public static EXPORT_TAXONOMY = GlobalSettings.VERSION + 'exportCFPackageJson';
  public static EXPORT_TAXONOMY_PDF = GlobalSettings.VERSION + 'exportTaxonomyPdf';
  public static EXPORT_TAXONOMY_CSV = GlobalSettings.VERSION + 'export/csv';
  public static CSV_EXPORT_TAXONOMY = GlobalSettings.VERSION + 'taxonomy/export';
  public static PROTIP_JSON_URL = GlobalSettings.VERSION + 'proxy/protip/' + environment.s3_bucket;
  // public static PROTIP_JSON_URL = './assets/json/protip.json';
  public static WALKTHROUGH_JSON_URL = GlobalSettings.VERSION + 'proxy/walkthrough/' + environment.s3_bucket;
  // public static WALKTHROUGH_JSON_URL = './assets/json/walkthrough.json';
  public static INPUTCONTROLS_JSON_URL = GlobalSettings.VERSION + 'proxy/inputControls/' + environment.s3_bucket;
  public static EXPORT_CUSTOM_VIEW_URL = GlobalSettings.VERSION + 'exportCustomView/';

  /** SESSION ACTIVITY */
  public static SESSION_ACITIVITY = GlobalSettings.VERSION + 'projects/logSessionActivity';

  /** NODE TEMPLATE */
  public static NODE_TEMPLATE = GlobalSettings.VERSION + 'nodeTemplate';
  public static NODE_TEMPLATE_SEARCH = GlobalSettings.VERSION + 'nodeTemplate?search=';
  public static LANGUAGE_LIST = GlobalSettings.VERSION + 'language/list';

  /** SEARCH */
  public static SEARCH = GlobalSettings.VERSION + 'search';
  public static SEARCH_IN_TAXONOMY = GlobalSettings.VERSION + 'taxonomy/search?query=';
  public static SAVE_SEARCH = GlobalSettings.VERSION + 'search/save';
  public static GET_SAVED_SEARCH = GlobalSettings.VERSION + 'search/search-history';



  /** PACING GUIDES */
  public static PACING_GUIDES = GlobalSettings.VERSION + 'projects';
  public static CREATE_PACING_GUIDES = GlobalSettings.VERSION + 'projects';
  public static PACING_GUIDES_AUTHORING = GlobalSettings.VERSION + 'projects/pacingNodes';
  public static GET_PACINGGUIDE_ASSOCIATED_NODES = GlobalSettings.VERSION + 'projects/nodesWithTaxonomyTree';
  public static DELETE_PACINGGUIDE_ASSOCIATED_TAXONOMY_NODES = GlobalSettings.VERSION + 'projects/delete/nodes';
  public static CREATE_CONTAINER = GlobalSettings.VERSION + 'cfitem/create';
  // public static PACING_GUIDES_AUTHORED_ITEMS =  GlobalSettings.VERSION + 'projects/authoredNodes';
  public static PACING_GUIDE_SET_TREE_HIERARCHY = GlobalSettings.VERSION + 'taxonomy/setPacingTreeHierarchy';
  public static PACING_GUIDES_AUTHORED_ITEMS = GlobalSettings.VERSION + 'projects/pacingNodes';
  /** CASE Server Link */
  public static GET_SERVER_LIST = GlobalSettings.VERSION + 'link/listAll';
  public static CREATE_LINK_SERVER = GlobalSettings.VERSION + 'link/create';
  public static DELETE_SERVER_LINK = GlobalSettings.VERSION + 'link/delete';
  public static GET_SERVER_DETAILS = GlobalSettings.VERSION + 'link/list';
  public static UPDATE_SERVER_DETAILS = GlobalSettings.VERSION + 'link/update';
  public static GET_SERVER_TAXONOMY_LIST = GlobalSettings.VERSION + 'link/listTaxonomy';
  public static IMPORT_CF_PACKAGE_API_URL = GlobalSettings.VERSION + 'importCFPackageApi';
  public static CREATE_TENANT_URL = GlobalSettings.VERSION + 'user/createTenant';
  public static TENANT_LIST_URL = GlobalSettings.VERSION + 'org/OrgList';
  public static GET_TENANT_URL = GlobalSettings.VERSION + 'org/GetOrgDetails';
  public static UPDATE_TENANT_URL = GlobalSettings.VERSION + 'org/UpdateOrg';
  /** COMPLIANCE REPORTS */
  public static COMPLIANCE_REPORTS = GlobalSettings.VERSION + 'report/compliance';
  public static ITEM_COMPLIANCE_REPORT = GlobalSettings.VERSION + 'report/itemCompliance';

  /** PUBLIC REVIEW */
  public static SET_PUBLIC_REVIEW = GlobalSettings.VERSION + 'taxonomy/setForPublicReview';
  public static USER_DOCUMENT_COMMENTS = GlobalSettings.VERSION + 'document/user-comments';
  public static GET_COMMENT_REPORT = GlobalSettings.VERSION + 'report/get_comment_report';
  public static SUBMIT_REVIEW_COMMENTS = GlobalSettings.VERSION + 'submit_review';
  public static PUBLIC_REVIEW_HISTORY = GlobalSettings.VERSION + 'taxonomy/publicReviewHistory';
  public static STOP_PUBLIC_REVIEW = GlobalSettings.VERSION + 'taxonomy/stopPublicReview';
  public static DOWNLOAD_REVIEW_URL = GlobalSettings.VERSION + 'report/PublicReviewReport';

  /** NOTIFICATION ACTIVITY */
  public static NOTIFICATION_READ_MARK = GlobalSettings.VERSION + 'set_notifications_read';
  public static NOTIFICATION_LIST = GlobalSettings.VERSION + 'notifications';
  public static NOTIFICATION_COUNT = GlobalSettings.VERSION + 'notification_count';
  public static REFRESH_TOCKEN = GlobalSettings.VERSION + 'refreshToken';
  public static SET_USER_INFO = GlobalSettings.VERSION + 'users/user_info';

  /** HOME PAGE BLOCK NOTES */
  public static HOME_PAGE_BLOCK_NOTE = GlobalSettings.VERSION + 'notes';
  public static HOME_PAGE_BLOCK_REORDER = GlobalSettings.VERSION + 'notes/reorder';
  public static HOME_PAGE_BLOCK_CACHE = GlobalSettings.VERSION + 'cache/notes';

  /** FRONT FACING TAXONOMIES */
  public static FRONT_FACE_TAXONOMY_LIST = GlobalSettings.VERSION + 'cache/getTaxonomyList';
  public static FRONT_FACE_TAXONOMY_HIERARCHY = GlobalSettings.VERSION + 'cache/getTaxonomyHierarchy/';
  public static FRONT_FACE_TAXONOMY_DETAIL = GlobalSettings.VERSION + 'cache/getTaxonomyDetails/';
  public static FRONT_FACE_METADATA_CACHE = GlobalSettings.VERSION + 'cache/node_types';

  /** FRONT FACING DOCUMENT */
  public static FRONT_FACE_UPLOAD_DOC = GlobalSettings.VERSION + 'asset';
  public static FRONT_FACE_UPLOAD_CACHE = GlobalSettings.VERSION + 'cache/file';

  /** COMMENTS */
  public static COMMENT_LIST = GlobalSettings.VERSION + 'taxonomyProjectComments';

  /** ORGANIZATION */
  public static SWITCH_ORGANIZATION = GlobalSettings.VERSION + 'user/set_org';
  public static ORGANIZATION_LIST = GlobalSettings.VERSION + 'user/orgs';
  public static USER_ORGANIZATION_REGISTRAION_STATUS = GlobalSettings.VERSION + 'user/check_user_status';

  /** TABULAR TREE */
  public static TAXONOMY_CACHE_HIERARCHY = GlobalSettings.VERSION + 'taxonomy/getTaxonomyHierarchy/';
  public static TAXONOMY_CACHE_DETAIL = GlobalSettings.VERSION + 'taxonomy/getTaxonomyHierarchy/';
  public static PROJECT_CACHE_HIERARCHY = GlobalSettings.VERSION + 'projects/getProjectHierarchy/';
  public static PROJECT_CACHE_DETAIL = GlobalSettings.VERSION + 'projects/getProjectDetails/';

  public static TAXONOMY_CACHE_DETAIL_POC = GlobalSettings.VERSION + 'taxonomy/child-hierachy/';

  /** REPORT URL */
  public static JASPER_REPORT_URL = GlobalSettings.VERSION + 'statistics/';
  public static CUSTOM_TAXONOMY_REPORT_URL = GlobalSettings.VERSION + 'taxonomy_custom_view/';

  /** JASPER REPORT */
  public static JASPER_REPORT_LIST = GlobalSettings.VERSION + 'report/list';
  public static JASPER_REPORT_VIEW = GlobalSettings.VERSION + 'report/view';
  public static COVERAGE_REPORT = GlobalSettings.VERSION + 'report/coverage';
  public static SNS_REPORT = GlobalSettings.VERSION + 'report/SNSReport';

  public static TAXONOMY_LIST_COUNT = 10;

  /** TAXONOMY GROUPING */
  public static TAXONOMY_GROUP_URL = GlobalSettings.VERSION + 'taxonomy/group';
  public static ADD_TAXONOMY_TO_GROUP_URL = GlobalSettings.VERSION + 'taxonomy/add_group';
  public static DELETE_TAXONOMY_TO_GROUP_URL = GlobalSettings.VERSION + 'taxonomy/delete_group';
  public static GET_ALL_TAXONOMY_FROM_GROUP_URL = GlobalSettings.VERSION + 'taxonomy/get_group';

  public static USER_PROFILE_URL = GlobalSettings.VERSION + 'user';
  public static USER_PASSWORD_URL = GlobalSettings.VERSION + 'user/password';

  /** ASSOCIATION PRESETS */
  public static ASSOCIATION_PRESET_URL = GlobalSettings.VERSION + 'association/preset';
  public static ASSOCIATION_METADATA = GlobalSettings.VERSION + 'association/metadata';
  public static SAVE_ASSOCIATION_METADATA = GlobalSettings.VERSION + 'item/association/metadata';

  /** CASE API URL */
  public static CASE_API_CFDOC_URL = '/ims/case/v1p0/CFDocuments';
  public static CASE_API_CFITEM_URL = '/ims/case/v1p0/CFItems';

  /** CSV BACKGROUND */
  public static CSV_SUMMARY_URL = GlobalSettings.VERSION + 'getStatusForBack';
  public static CSV_SUMMARY_PROCEED_UPLOAD_URL = GlobalSettings.VERSION + 'importTaxonomiesFromCsv';
  public static CSV_EXPORT_BACKGROUND = GlobalSettings.VERSION + 'taxonomy/export';
  public static CSV_STATUS = GlobalSettings.VERSION + 'getStatusForBack/';

  /** TAXONOMY VERSION */
  public static VERSION_SUMMARY_REPORT = GlobalSettings.VERSION + 'comparison-summary-report';
  public static VERSION_NODE_DETAILS = GlobalSettings.VERSION + 'versionNodeDetails';
  public static UPDATE_VERSION_URL = GlobalSettings.VERSION + 'compareUpdate';

  /** FRONT-FACING WEBSITE PUBLIC REVIEW */
  public static PR_CONFIG_DETAILS_URL = GlobalSettings.VERSION + 'publicReview/Config/';
  public static PR_USER_INFO_PARAMS = GlobalSettings.VERSION + 'frontFacing/metadata';
  public static SUBMIT_REVIEW_FRONT_FACE_URL = GlobalSettings.VERSION + 'frontfacing/submit_review';

  /** IRM REPORT */
  public static IRM_DOWNLOAD_URL = GlobalSettings.VERSION + 'report/IRMReport';

  /** EXPORT TAXONOMY ON FRONT-FACE */
  public static EXPORT_PERMISSION_URL = GlobalSettings.VERSION + 'organization/exportOption';
  public static EXPORT_TAXONOMY_URL = GlobalSettings.VERSION + 'exportedTaxonomy';
  public static DOWNLOAD_TAXONOMY_URL = GlobalSettings.VERSION + 'getS3exportURL';
  public static CONFIG_EXPORT_PERMISSION_URL = GlobalSettings.VERSION + 'changeExportOption';

}
