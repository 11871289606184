import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import {
  ActivatedRoute,
  Params,
  Router
} from '@angular/router';
import {
  CommonService
} from '../common.service';
import {
  GlobalSettings
} from '../global.settings';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  CustomValidators
} from 'ng2-validation';
import {
  SharedService
} from '../shared.service';
import Utils from '../utils';
import {
  Subscription
} from 'rxjs/Subscription';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss', './../nav-bar/nav-bar-logo-img.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  passwordEnt;
  repasswordEnt;
  match = false;
  email;
  pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/;
  token;
  isSuccess = false;
  alertMsg = 'Password updated successfully.';
  isFirstTime = true;
  validateUser: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: CommonService,
    private fb: FormBuilder,
    private router: Router,
    private sharedService: SharedService) {
    this.isSuccess = false;
    this.form = this.fb.group({
      email: [''],
      password: [null, [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
      cpassword: [null, Validators.compose([Validators.required])],
    });
    this.validateUser = this.activatedRoute.params.subscribe((params: Params) => {
      this.isSuccess = false;
      if (params['id'] !== undefined) {
        this.token = params['id'];
        this.getUserDetail();
      } else {
        console.log('Error retreiving route parameters');
      }
      console.log(' token received ', this.token);
    });
  }

  ngOnInit() {
    /* if (localStorage.getItem('forgot_mail')) {
      this.email = localStorage.getItem('forgot_mail');
      // localStorage.removeItem('forgot_mail');
    } */
  }


  getUserDetail() {
    const self = this;
    const url = GlobalSettings.VALIDATE_USER + this.token;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      console.log('RES getUserDetail', JSON.stringify(res));
      if (res.result === 'valid') {
        this.email = res['email'];
        console.log('Valid', this.isFirstTime);
      } else if (res.result === 'invalid') {
        this.sharedService.sucessEvent.next({
          customMsg: 'The password reset link is no longer valid. Please request another password reset email from the login page.',
          type: 'error'
        });
      } else if (res.result === 'expired') {
        this.sharedService.sucessEvent.next({
          customMsg: 'Token has expired',
          type: 'error'
        });
      }
    }).catch((ex) => {
      console.log('ex ', ex);
    });
  }

  repasswordEntered(control) {
    if (this.passwordEnt === this.repasswordEnt) {
      this.match = true;
    } else {
      this.match = false;
    }
  }

  onResetPassword() {
    const resetObj = {
      reset_request_token: this.token,
      password: this.form.value.password,
    };

    const url = GlobalSettings.FORGOT_PASSWORD;
    const self = this;
    this.service.registerService(url, resetObj).then((res: any) => {
      if (res.data.result !== 'exist') {
        this.sharedService.sucessEvent.next({
          customMsg: 'Password updated successfully.'
        });
        this.isFirstTime = true;
        const path = this.service.getOrgDetails() + '/login';
        this.router.navigate([path]);
      } else {
        this.sharedService.sucessEvent.next({
          customMsg: 'This password has been used previously, Please enter a new password.',
          type: 'error'
        });
        this.form.controls['password'].reset();
        this.form.controls['cpassword'].reset();
      }
    }).catch((ex) => {
      this.isSuccess = false;
      console.log('Error occured while resetting password', ex);
      this.sharedService.sucessEvent.next({
        customMsg: ex.msg,
        type: 'error'
      });
    });
  }

  goToHome() {
    const path = this.service.getOrgDetails() + '/home';
    sessionStorage.setItem('frontface_url', this.service.getOrgDetails() + '/home');
    this.router.navigate([path]);
  }

  ngOnDestroy() {
    if (this.validateUser) {
      this.validateUser.unsubscribe();
    }
  }
}
