import {
  Injectable
} from '@angular/core';
import {
  CommonService
} from './common.service';
import Utils from './utils';

@Injectable()
export class ProjectMappedNodeService {
  DUMMY_ID_FOR_ORPHANLABEL = Utils.DUMMY_ID_FOR_ORPHANLABEL;
  projectName = '';
  projectDesc = '';
  workflowList = [];
  taxonomyList = [];
  taxonomyData = {
    children: []
  };
  selectedWorkFlow = null;
  selectedTaxonomy = null;
  textLength = 50;
  selectedTaxonomyNodeIs = [];
  nodes = [];
  relations: any;
  treeResponseData: any;
  selectedTaxonomyId = null;
  parentObj = {};
  topParentIds = [];
  expandLevel = 1;
  rootNodeId: any;
  orphanNodeList = [];
  topMostOrphanNodes = []; // holds list of top most orphan nodes
  externalRelations = [];
  worker: any;
  constructor(private service: CommonService) { }


  getTreeData(url, level = 2, isExport = false) {
    this.expandLevel = level;
    this.externalRelations = [];
    return new Promise((resolve, reject) => {

      this.service.getServiceData(url).then((res: any) => {
        // console.log('GET_TREE_VIEW ', res);
        if (typeof Worker !== 'undefined') {
          // this.worker = new Worker('./projectMappedNodeService.worker', { type: 'module' });
        }
        this.setTreeResponse(res);
        this.setNodes();
        this.setRelations(isExport);
        this.findTopmostOrphan(); // Finding list of topmost orphan nodes
        resolve(this.parseTreeData());
        // if (this.worker) {
        //   this.worker.onmessage = ({ data }) => {
        //     if (data) {
        //       // console.log('data received from worker', data);
        //       resolve(data);
        //     }
        //   };
        //   this.worker.postMessage({ data: res, parameters: { level: level, DUMMY_ID_FOR_ORPHANLABEL: this.DUMMY_ID_FOR_ORPHANLABEL } });
        // }
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
        reject(ex);
      });
    });

  }

  setTreeResponse(data: any): void {
    this.treeResponseData = data;
  }

  setNodes(): void {
    const apiResponse: any = this.treeResponseData;
    this.nodes = apiResponse.nodes;
  }

  getNodes(): any {
    return this.nodes;
  }

  setRelations(isExport = false): void {
    const apiResponse: any = this.treeResponseData;
    // this.relations = apiResponse.relations;
    // const relationsArr = [];
    // // Filter out external_link relation ( those have relation with other taxonomy)
    // apiResponse.relations.forEach(relation => {
    //   if (relation && relation.external_link === false) {
    //     relationsArr.push(relation);
    //   }
    // });
    const relationsArr = [];
    apiResponse.relations.forEach((relation: any) => {
      if (relation) {
        let assoCondition;
        if (!isExport) {
          assoCondition = relation.external_link === true || relation.external_association;
        } else {
          assoCondition = true;
        }
        if (assoCondition) {
          // external_association flag for export CSV external association
          // if (relation.external_association) {
          this.externalRelations.push(relation);
          // }
          // For external taxonomy, if relation does not exists in array, push it otherwise don't push for dulpicating
          if (!this.isRelationDupli(relationsArr, relation)) {
            relationsArr.push(relation);
          }
        } else {
          // If not external taxonomy, push it directly
          relationsArr.push(relation);
        }
      }
    });
    this.relations = relationsArr;
  }

  /**
   * To check any relation is duplicated or not as child id in relations array
   * @param relations (list of relations array)
   * @param relation (relation to be checked)
   */
  isRelationDupli(relations: any[], relation: any) {
    if (relations.length) {
      if (relations.find(rel => rel.child_id === relation.child_id)) {
        return true; // duplicate
      } else {
        return false; // not duplicate
      }
    } else {
      return false; // not duplicate
    }
  }

  getRelations(): any {
    return this.relations;
  }

  parseTreeData() {
    const parsedTrees = [];
    if (this.getNodes().length < 1) {
      return parsedTrees;
    }
    this.rootNodeId = null;
    let hasDocumentNode = false;
    // const treeNodes: any = this.getNodes();
    this.findTopMostParent();
    if (this.topParentIds.length === 1) {
      console.log('Got the top parent ', this.topParentIds[0]);
    } else {
      console.log('2 NeedRATING_CONTROL_VALUE_ACCESSOR to find top parent ', this.topParentIds);
    }
    if (this.topParentIds && this.topParentIds.length < 1) {
      this.topParentIds.push(this.getNodes()[0].id);
    }
    if (this.topParentIds.length > 0) {
      for (let index = 0; index < this.topParentIds.length; index++) {
        // for (let index = 0; index < 1; index++) {
        const parentNodeId = this.topParentIds[index];
        let documentNode: any = {
          id: parentNodeId,
          children: [],
          full_statement: '',
          is_document: 0
        };
        // check if data has document id
        if (this.nodes && this.nodes.length > 0) {
          const node = this.nodes[0];
          //SOF For Export CSV 
          if (node.document_id) {
            node.is_document = 1;
            if (node.case_metadata && node.case_metadata[0]) {
              node.title = node.case_metadata[0].title;
            }
          }
          //EOF Export CSV
          if ((node.is_document === 1) && (parentNodeId === node.id)) {
            documentNode = node;
            this.setDocumentNode(documentNode, node);
            hasDocumentNode = true;
          } else {
            const nodeDetail = this.nodes.find(item => item.id === parentNodeId);
            if (nodeDetail) {
              this.setParentNodeData(documentNode, nodeDetail);
            }
          }
        }
        const parsedTree = documentNode;

        if (parsedTree.is_document === 1 || !this.isTopMostOrphan(documentNode.id)) {
          // If node is document or is not top most orphan, build tree structure
          parsedTree['children'] = this.createTreeStructure(documentNode.id, '', 1, documentNode.breadCrumb);
        } else {
          parsedTree.id = '';
        }
        parsedTree.isFirst = index === 0 ? true : false; // set is it first node or not
        if (parsedTree['children'] && parsedTree['children'].length > 0 && this.topParentIds.length > 1) {
          parsedTree['children'][0].isFirst = index === 0 ? true : false; // set is it first node or not
        }
        try {
          const sortPropsarr = ['relation_sequence_number', 'human_coding_scheme', 'full_statement'];
          Utils.sortDataArray(parsedTree['children'], sortPropsarr[0], false, true, sortPropsarr);
          // Utils.sortData(parsedTree['children']);
        } catch (error) {
          console.log('sorting error', error);
        }
        /* Orphan lable node appending under document node STARTS */
        // if (index === (this.topParentIds.length - 1)) {
        //   this.orphanNodeList = this.getOrphanNodes();
        //   if (this.orphanNodeList.length > 0) {
        //   this.buildOrphanLableNode(parsedTree);
        //   }
        // }
        /* Orphan lable node appending under document node ENDS */

        // if (this.topParentIds.length === 1 && hasDocumentNode) {
        if (hasDocumentNode) {
          // parsedTree.is_document = 1;
          // parsedTree.is_editable = parsedTree.is_editable;
          const wrapperNode = {
            children: [parsedTree]
          };
          // if (!this.isTopMostOrphan(this.topParentIds[index])) {
          //   parsedTrees.push(wrapperNode);
          // }
          if (parsedTree.id === (this.topParentIds[index])) { // to prevent duplicate pushing

            /* Orphan lable node appending under document node STARTS */
            this.buildOrphanLableNode(wrapperNode.children[0]);
            /* Orphan lable node appending under document node ENDS */
            if (parsedTrees.length && parsedTrees[0].children.length) {
              parsedTrees[0].children.push(wrapperNode.children[0]);
            } else {
              parsedTrees.push(wrapperNode);
            }
          }
        } else {
          /*if (parsedTree.children && parsedTree.children.length === 0) {
            const dummyWapper: any = {
              id: parentNodeId,
              children: [],
              full_statement: '',
              is_document: 0
            };
            dummyWapper.children.push(parsedTree);
            parsedTrees.push(dummyWapper);
          } else {}*/
          // In case of no dument node i.e. partially selected
          if (index === (this.topParentIds.length - 1)) {
            // Appending orphan tree at last iteration

            /* Orphan lable node appending at last position of tree STARTS */
            this.buildOrphanLableNode(parsedTree);
            /* Orphan lable node appending at last position of tree ENDS */
          }
          if (parsedTree.children.length) {
            parsedTrees.push(parsedTree);
          }
          // if (parsedTrees.length < 1 && parsedTree && parsedTree.children.length) {
          //   parsedTrees.push(parsedTree);
          // }
        }
      }
    } else {
      // when there is no relation in relation array
      let documentNode;
      const treeNodes: any = this.getNodes();
      const node: any = treeNodes[0] ? treeNodes[0] : treeNodes;
      node.children = [];
      const wrapper = {
        children: []
      };
      if (node.is_document === 1) {
        documentNode = node;
        this.setDocumentNode(documentNode, node);
      }
      wrapper.children.push(documentNode);
      return [wrapper];

    }

    // console.log('parsedTree*****************   ', parsedTrees);
    return parsedTrees;
  }
  setDocumentNode(documentNode: any, node: any) {
    // tslint:disable-next-line:max-line-length
    documentNode.full_statement = node.title ? node.title : (node.full_statement ? node.full_statement : null);
    documentNode.full_statement_html = node.title_html ? node.title_html : node.title ? node.title : node.full_statement_html ? node.full_statement_html : node.full_statement ? node.full_statement : null;
    documentNode.level = 1;
    documentNode.expand = true;
    documentNode.cut = 0;
    documentNode.reorder = 0;
    documentNode.paste = 0;
    documentNode.breadCrumb = node.title;
    documentNode.is_document = node.is_document;
    documentNode.breadCrumbArray = documentNode.breadCrumb.split('>');
    documentNode.list_enumeration = documentNode.list_enumeration;
    documentNode.sequence_number = documentNode.sequence_number ? '' + documentNode.sequence_number : documentNode.list_enumeration;
    documentNode.hasMoreChild = false;
    this.rootNodeId = node.id;
  }

  setParentNodeData(documentNode: any, node: any) {
    // tslint:disable-next-line:max-line-length
    documentNode.full_statement = node.full_statement ? node.full_statement : '';
    documentNode.full_statement_html = node.full_statement_html ? node.full_statement_html : node.full_statement ? node.full_statement : '';
    documentNode.level = 1;
    documentNode.expand = true;
    documentNode.cut = 0;
    documentNode.reorder = 0;
    documentNode.paste = 0;
    documentNode.breadCrumb = node.title;
    documentNode.is_document = node.is_document;
    documentNode.breadCrumbArray = documentNode.breadCrumb.split('>');
    documentNode.list_enumeration = documentNode.list_enumeration;
    documentNode.sequence_number = documentNode.sequence_number ? '' + documentNode.sequence_number : documentNode.list_enumeration;
    documentNode.hasMoreChild = false;
    this.rootNodeId = node.id;
  }

  createTreeStructure(nodeId: string, parent_id, level, previousBC) {
    const data: any[] = [];
    const childrenNodes = this.extractChildren(nodeId);
    if (childrenNodes.length > 0) {
      level = level + 1;
      for (const key in childrenNodes) {
        // console.log(key, '---------', childrenNodes[key]);
        if (childrenNodes[key]) {
          const childNode = childrenNodes[key];
          if (childNode) {
            nodeId = childNode.id;
            childNode.children = [];
            childNode.level = level;
            childNode.expand = level <= this.expandLevel ? true : false;
            childNode.full_statement_html = childNode.full_statement_html ? childNode.full_statement_html : childNode.full_statement;
            childNode.cut = 0;
            childNode.reorder = 0;
            childNode.paste = 0;
            childNode.hasMoreChild = true;
            // tslint:disable-next-line:max-line-length
            childNode.breadCrumb = previousBC + ' > ' + (childNode.human_coding_scheme && childNode.human_coding_scheme.length > 0 ? childNode.human_coding_scheme : childNode.full_statement);
            childNode.list_enumeration = '' + childNode.list_enumeration;
            childNode.sequence_number = '' + childNode.relation_sequence_number;
            childNode.breadCrumbArray = childNode.breadCrumb.split('>');
            if (nodeId !== parent_id) {
              const nodeChildren = this.createTreeStructure(nodeId, childNode.parent_id, level,
                childNode.breadCrumb);
              if (nodeChildren.length > 0 && (childNode.tree_association || childNode.tree_association === undefined)) {
                childNode.children = nodeChildren;
              }
            }
            data.push(childNode);
          }
        }
      }
    }
    try {
      const sortPropsarr = ['relation_sequence_number', 'human_coding_scheme', 'full_statement'];
      Utils.sortDataArray(data, sortPropsarr[0], false, true, sortPropsarr);
      // Utils.sortData(parsedTree['children']);
    } catch (error) {
      console.log('sorting error', error);
    }
    return data;
  }

  extractChildren(nodeId: string): any {
    const treeNodes: any = this.getNodes();
    const nodeRelations: any = this.getRelations();
    const data: any[] = [];
    for (const key in nodeRelations) {
      if (nodeRelations[key]) {
        const relation: any = nodeRelations[key];
        if (relation.child_id !== relation.parent_id) {
          const parentId: string = relation.parent_id;
          //  console.log(' extractChildren  ', relation, '  ', parentId);
          if (nodeId === parentId) {
            const childId: string = relation.child_id;
            const childNode = this.extractNode(childId);
            childNode['relation_sequence_number'] = relation.sequence_number;
            if (childNode) {
              childNode.parent_id = parentId;
              // These properties used in Export CSV to show association and association type
              if (nodeRelations[key].tree_association !== undefined) {
                childNode.tree_association = nodeRelations[key].tree_association;
                childNode.association_type = nodeRelations[key].association_type;
              }
              data.push(childNode);
            }
          }
        }
      }
    }
    return data;
  }

  extractNode(nodeIdToSearchWith: string): any {
    const treeNodes: any = this.getNodes();
    let nodeFound: any;
    for (const key in treeNodes) {
      if (treeNodes[key]) {
        const node: any = treeNodes[key];
        if (nodeIdToSearchWith === node.id) {
          nodeFound = Object.assign({}, node);
          break;
        }
      }
    }
    return nodeFound;
  }

  findTopMostParent() {

    this.parentObj = {};
    this.topParentIds = [];
    // console.log('Need to find this.parentObj ', this.relations);
    for (let index = 0; index < this.relations.length; index++) {
      const element = this.relations[index];
      this.findParent(element.parent_id, element.child_id);
    }
    // console.log('Need to find this.parentObj ', this.parentObj);
    const parentArray = [];
    for (const key in this.parentObj) {
      if (this.parentObj.hasOwnProperty(key)) {
        // const element = this.parentObj[key];
        parentArray.push(key);
      }
    }
    // If didn't find relation between relation array, it means all nodes are child of first node
    if (parentArray.length === 0) {
      if (this.relations.length > 0) {
        this.parentObj = this.relations[0];
        parentArray.push(this.relations[0].parent_id);
      }
    }
    if (parentArray.length === 1) {
      this.topParentIds.push(parentArray[0]);
      // console.log('Got the top parent ', parentArray[0]);
    } else {
      // console.log('1 Need to find top parent ', parentArray);
      parentArray.forEach(element => {
        let found = false;
        for (let index = 0; index < this.relations.length; index++) {
          const relation = this.relations[index];
          if (relation.child_id === element) {
            found = true;
          }
        }
        if (found === false) {
          this.topParentIds.push(element);
        }
      });
    }
  }

  findParent(parentId, child_id) {
    let foundTopParentId = false;
    let index = 0;
    for (let i = 0; i < this.relations.length; i++) {
      const n = this.relations[i];
      index++;
      if (n.child_id !== n.parent_id) {
        if (n.child_id === parentId) {
          this.parentObj[n.parent_id] = n.child_id;
          foundTopParentId = true;
          if (child_id !== n.parent_id) {
            this.findParent(n.parent_id, n.child_id);
          }
        } else {
          // If parentId is not a child of any node then it should be top node of that branch
          if (index === this.relations.length && foundTopParentId === false) {
            this.parentObj[parentId] = parentId;
          }
        }
      }
    }
  }

  setExpandLevel(level) {
    this.expandLevel = level;
  }

  /**
   * To find list of top most orphan root nodes
   */
  findTopmostOrphan() {
    const nodes = this.getNodes();
    this.topMostOrphanNodes = [];
    for (const node of nodes) {
      if (node.is_orphan === 1) { // top most orphan node
        this.topMostOrphanNodes.push(node);
      }
    }
  }

  /**
   * To check whether node is topmost orphan or not by node id given
   * @param nodeId (node id)
   */
  isTopMostOrphan(nodeId: any) {
    let flag = false;
    for (const node of this.topMostOrphanNodes) {
      if (node.id === nodeId) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  /**
   * To get orphan node list
   */
  /*getOrphanNodes() {
    const nodes = this.getNodes();
    const orphanNodes = [];
    for (const node of nodes) {
      // Check node is orphan or not skipping root node
      if ((node.is_document !== 1) && this.isNodeOrphan(node)) {
        node.isOrphan = true;
        node.cut = 0;
        node.paste = 0;
        node.reorder = 0;
        node.parent_id = '';
        node.children = [];
        // node.list_enumeration = node.sequence_number ? '' + node.sequence_number : node.list_enumeration;
        orphanNodes.push(node);
      }
    }
    return orphanNodes;
  }*/

  /**
   * To check node is orphan or not by giving node object as parameter
   * @param node (selected node object)
   */
  /*isNodeOrphan(node: any) {
    const nodeId = node.id;
    const nodeRelations = this.getRelations();
    let flag = false;
    let parentId: any;
    for (const key in nodeRelations) {
      if (nodeRelations[key]) {
        const relation: any = nodeRelations[key];
        if ((relation.child_id === nodeId) && relation.parent_id) { // If node exists in relation, set flag=true
          parentId = relation.parent_id;
          flag = true;
          break;
        }
      }
    }
    if (flag) { // If flag is true, then check its parent is orphan or not recursively
      if (parentId !== this.rootNodeId) { // excluding root node checking
        node.firstRootOrphan = false;
        return this.isNodeOrphan({
          id: parentId
        });
      } else {
        return false; // not orphan
      }
    } else {
      if (nodeId === this.rootNodeId) {
        return false;
      } else {
        // If node exists in relation as only parent_id but not exists in nodes list, don't cosider the node as orphan
        const orphan = !this.findNodeById(nodeId) ? false : true;
        node.firstRootOrphan = orphan;
        return orphan; // orphan
      }
    }
  }*/

  /**
   * To create Orphan level root node which will be non-clickable i.e. only a text, under which orphan node list
   * will remain. This root node can be identified by attribute 'isOrphanLabel' which will be true
   * @param parentNode (Node of which one children will be orphan lable node i.e. orphan lable node will be one
   * of children of given parameter node- 'parentNode')
   */
  buildOrphanLableNode(parentNode: any) {
    let orphanFound = false;
    const orphanLableNode: any = {}; // 'orphanLableNode' is one dummy node
    const title = 'Nodes with no linked parent';
    orphanLableNode.full_statement = title;
    orphanLableNode.full_statement_html = title;
    orphanLableNode.human_coding_scheme = '';
    orphanLableNode.is_document = 0;
    // orphanLableNode.title = title;
    orphanLableNode.cut = 0;
    orphanLableNode.paste = 0;
    orphanLableNode.reorder = 0;
    orphanLableNode.children = [];
    orphanLableNode.sequence_number = '';
    orphanLableNode.isOrphan = true;
    orphanLableNode.isOrphanLabel = true;
    orphanLableNode.parent_id = parentNode ? parentNode.id : '';
    orphanLableNode.id = this.DUMMY_ID_FOR_ORPHANLABEL; // assigning dummy id
    orphanLableNode.list_enumeration = (parentNode && parentNode.children.length) ? ('' + (Utils.getMaxLE(parentNode.children))) : ('' + 1);
    orphanLableNode.sequence_number = (parentNode && parentNode.children.length) ? ('' + (Utils.getMaxLE(parentNode.children))) : ('' + 1);
    if (parentNode) {
      parentNode.level = (parentNode.level !== undefined) ? parentNode.level : 1;
    }
    orphanLableNode.level = parentNode ? (parentNode.level + 1) : 1;
    // orphanLableNode.expand = orphanLableNode.level <= this.expandLevel ? true : false;
    orphanLableNode.expand = true;

    // for (const orphanNode of this.orphanNodeList) {
    for (const node of this.topMostOrphanNodes) {
      // tslint:disable-next-line:max-line-length
      node.breadCrumb = 'Orphan node > ' + (node.human_coding_scheme && node.human_coding_scheme.length > 0 ? node.human_coding_scheme : node.full_statement);
      node.breadCrumbArray = node.breadCrumb.split('>');
      orphanFound = true;
      // const node = this.findNodeById(orphanNode.id);
      node.cut = 0;
      node.paste = 0;
      node.reorder = 0;
      node.parent_id = this.DUMMY_ID_FOR_ORPHANLABEL;
      node.level = orphanLableNode.level + 1;
      node.expand = node.level <= this.expandLevel ? true : false;
      node.children = this.createTreeStructure(node.id, node.parent_id, node.level, node.breadCrumb);
      orphanLableNode.children.push(node);
    }
    console.log('Orphan node tree =========================>', orphanLableNode);
    if (parentNode && orphanFound) { // If any orphan tree nodes found, then append orphan tree in parent tree's children
      parentNode.children.push(orphanLableNode);
    }
    return orphanLableNode;
  }

  /**
   * To fetch node from node list by node id
   * @param nodeId (selected node id)
   */
  findNodeById(nodeId: any) {
    const nodeList = this.getNodes();
    for (const node of nodeList) {
      if (node.id.trim() === nodeId.trim()) {
        return node;
      }
    }
    return null;
  }

}
