import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import Utils from '../../utils';
@Component({
  selector: 'app-create-project-tree',
  templateUrl: './create-project-tree.component.html',
  styleUrls: ['./create-project-tree.component.scss']
})
export class CreateProjectTreeComponent implements OnInit, OnChanges {

  @Input() viewLocation = '';
  @Input() treeNodes: any; // holds all the tree nodes
  @Input() documentId = null; // holds document id passed to tree view component for disabling document node
  @Output() hybridSelectedNodes: EventEmitter<any>;
  @Output() currentSelectedNode: EventEmitter<any>;
  @Input() searchResultList = []; // holds search result list
  @Input() isFilterApplied = false; // holds flag to apply search result
  @Input() isReset = false;
  @Input() disableDocument = true;
  @Input() clearNodesArray = false;
  worker: any;
  nodes = []; // holds array of selected ids while creating project
  partialNodes = []; // holds array of parent ids while creating project
  clicked = null; // holds current clicked item, used for adding active class
  expandCollapse = true; // holds boolean to toggle expand and collapse
  charLimit = 100; // holds character limit to trim hc and full statement
  // treeNodes = [];
  currentNode = null;

  constructor() {
    this.hybridSelectedNodes = new EventEmitter<any>();
    this.currentSelectedNode = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  ngOnChanges(change: SimpleChanges) {
    if (this.clearNodesArray) {
      this.nodes = [];
    }
    if (change.treeNodes && change.treeNodes.currentValue) {
      if (!(this.treeNodes instanceof Array)) {
        this.treeNodes = [this.treeNodes];
        console.log('check check', this.treeNodes);
      }
    }
    if (change.searchResultList && change.searchResultList.currentValue && change.searchResultList.currentValue.length
      && (!change.isReset || !change.isReset.currentValue)) {
      if (typeof Worker !== 'undefined') {
        this.worker = new Worker('../../treeService.worker', { type: 'module' });
      }
      if (this.worker) {
        this.worker.onmessage = ({ data }) => {
          this.treeNodes = [];
          if (data) {
            this.treeNodes.push(data.taxonomyData);
            console.log('data received from worker in create-project-tree', this.treeNodes);
          }
        };
        console.log('CREATE PROJECT NGONCHAGES WORKER');
        this.worker.postMessage({
          data: this.treeNodes[0],
          location: 'utils',
          parameters: {
            searchResultList: this.searchResultList,
            propName: 'isSearchResult',
            isMultipleCall: true
          }
        });
      }
    }
    if (this.treeNodes && change.isReset && change.isReset.currentValue) {
      setTimeout(() => {
        this.resetTree();
      }, 500);
    }
  }

  /* --------- Functionality to add padding on tree nodes start --------- */


  calculatePadding(level) {
    if (this.viewLocation === 'taxonomy_builder') {
      return Utils.calculateTaxonomyBuilderPadding(level, '');
    } else {
      return Utils.calculatePadding(level, 'treeview');
    }
  }

  /* --------- Functionality to add padding on tree nodes end --------- */



  /* --------- Functionality to update current node status and call update / remove accordingly start --------- */

  getCurrentItem(currentNode, event?) {
    if (this.viewLocation !== 'add-association' && currentNode.project_enabled === 1) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.clicked = currentNode;
      this.currentNode = currentNode;
      currentNode.checked = !currentNode.checked;
      // this.treeData = this.treeNodes[0];
      if (this.viewLocation === 'create-project' || this.viewLocation === 'add-association') {
        if (currentNode.checked) {
          this.updateNodes(currentNode);
        } else {
          this.removeCurrentNode(currentNode.value, currentNode);
        }
      }
    }

  }

  /* --------- Functionality to update current node status and call update / remove accordingly end --------- */


  /* --------- Functionality to select all child nodes and update status and call update method start --------- */

  selectAllChildren(currentNode) {
    if (currentNode.children && currentNode.children.length > 0) {
      this.iterateChildren(currentNode.children);
      const [nodeId, parentId] = currentNode.value.split('::');
      const index = this.nodes.indexOf(nodeId, 0);
      if (index > -1) {
        // this.removeParentNode(nodeId);
      }
    }
  }

  iterateChildren(children) {
    children.forEach(element => {
      if (!element.disabled) {
        if (!element.checked) {
          element.checked = true;
          this.updateNodes(element);
        }
      }
      if (element.children && element.children.length > 0) {
        this.iterateChildren(element.children);
      }
    });
  }

  /* --------- Functionality to select all child nodes and update status and call update method end --------- */


  /* --------- Functionality to push selected node in updated nodes object start --------- */

  updateNodes(data) {
    const [nodeId, parentId] = data.value.split('::');
    // if (this.documentId !== nodeId) {
    // }
    const index = this.nodes.indexOf(nodeId, 0);
    if (index === -1) {
      this.nodes.push(nodeId);
      this.updatePartialNodes(parentId);
    } else {
      return;
    }
  }

  /* --------- Functionality to push selected node in updated nodes object end --------- */


  /* --------- Functionality to push selected node's parent id in partial nodes object start --------- */

  updatePartialNodes(parentId) {
    if (!parentId || parentId === 'undefined') {
      parentId = this.documentId;
    }
    this.partialNodes.push(parentId);
    // this.filterParentNodes();
    if (this.viewLocation === 'add-association') {
      this.hybridSelectedNodes.emit({
        node: this.currentNode,
        nodes: this.nodes,
        treeData: this.treeNodes
      });
    } else {
      this.hybridSelectedNodes.emit({
        nodes: this.nodes,
        partialNodes: this.partialNodes
      });
    }
  }

  /* --------- Functionality to push selected node's parent id in partial nodes object end --------- */


  /* --------- Functionality to remove current node id and corresponding parent id on uncheck start --------- */

  removeCurrentNode(data, currentNode) {
    const [nodeId, parentId] = data.split('::');
    // this.nodes.push(nodeId);
    const index = this.findNodeIndex(nodeId);
    this.nodes = this.nodes.filter(obj => obj !== nodeId);

    delete this.partialNodes[index];
    this.partialNodes = this.partialNodes.filter((el) => el);

    if (this.viewLocation === 'add-association') {
      this.hybridSelectedNodes.emit({
        node: this.currentNode,
        nodes: this.nodes,
        treeData: this.treeNodes
      });
    } else {
      this.hybridSelectedNodes.emit({
        nodes: this.nodes,
        partialNodes: this.partialNodes
      });
    }

  }

  /* --------- Functionality to remove current node id and corresponding parent id on uncheck end --------- */


  /* --------- Functionality to grab current node's index start --------- */

  findNodeIndex(nodeId) {
    return this.nodes.findIndex(item => item === nodeId);
  }

  /* --------- Functionality to grab current node's index end --------- */


  removeParentNode(parentId) {
    delete this.partialNodes[this.partialNodes.findIndex(item => item === parentId)];
    this.partialNodes = this.partialNodes.filter((el) => el);
  }

  /* --------- Functionality to remove nodes for partial nodes if same present in selected node start --------- */

  filterParentNodes() {
    this.nodes.forEach(nodeId => {
      this.partialNodes = this.partialNodes.filter(parent => nodeId !== parent);
    });
  }

  /* --------- Functionality to remove nodes for partial nodes if same present in selected node end --------- */

  checkIfChildrenSelected(nodeId, currentNode) {
    let childSelected = false;
    for (const i in currentNode) {
      if (currentNode.i.internalDisabled === true || currentNode.i.checked === true) {
        childSelected = true;
        break;
      }
    }
    return childSelected;
  }


  /* --------- Functionality to refine and emit final object start --------- */

  emitSelectedNodes() {
    this.filterParentNodes();
    this.makePartialNodesUnique();
    this.hybridSelectedNodes.emit({
      nodes: this.nodes,
      partialNodes: this.partialNodes
    });
  }

  /* --------- Functionality to refine and emit final object end --------- */


  /* --------- Functionality to make partial nodes unique start --------- */

  makePartialNodesUnique() {
    this.partialNodes = this.partialNodes.filter((item, i, ar) => ar.indexOf(item) === i);
  }

  /* --------- Functionality to make partial nodes unique end --------- */


  /* --------- Functionality to update expand collapse button via javascript start --------- */

  // ngAfterViewInit() {
  //   if (document.getElementsByClassName('pull-right form-check-label') && this.viewLocation === 'create-project') {
  //     const element = document.getElementsByClassName('pull-right form-check-label')[0];
  //     if (element) {
  //       // tslint:disable-next-line:max-line-length
  //       element.innerHTML = '<em class="fa fa-compress" aria-hidden="true" id="expandCollapse" title="Collapse"></em> <span id="expandCollapseText" class="cursor-pointer"> Collapse </span>';
  //     }
  //     document.getElementById('expandCollapse').addEventListener('click', () => {
  //       this.updateExpandCollapseButton();
  //     });

  //     document.getElementById('expandCollapseText').addEventListener('click', () => {
  //       this.updateExpandCollapseButton();
  //     });

  //   }
  // }

  updateExpandCollapseButton() {
    this.expandCollapse = !this.expandCollapse;
    let button;
    if (document.getElementById('expandCollapse')) {
      button = document.getElementById('expandCollapse');
    }

    if (!this.expandCollapse) {
      button.innerText = ' Expand';
      button.setAttribute('title', 'Expand');
      button.setAttribute('class', 'fa fa-expand btn-clear');
    } else {
      button.innerText = ' Collapse';
      button.setAttribute('title', 'Collapse');
      button.setAttribute('class', 'fa fa-compress btn-clear');
    }
    this.treeNodes[0].expand = this.expandCollapse;
    if (typeof Worker !== 'undefined') {
      // this.worker = new Worker('./tree.worker', { type: 'module' });
    }
    this.iterateChildren1();
  }

  updateExpansion(nodes) {
    nodes.forEach(element => {
      element.expand = this.expandCollapse;
      if (element.children && element.children.length > 0) {
        this.updateExpansion(element.children);
      }
    });
  }

  /* --------- Functionality to update expand collapse button via javascript end --------- */


  /* --------- Functionality to update emittion object at start on open of modal while update start --------- */

  updatedExistingIds(nodeIds, partialNodeIds) {
    this.nodes = nodeIds;
    this.partialNodes = partialNodeIds;
  }

  /* --------- Functionality to update emittion object at start on open of modal while update end --------- */


  iterateChildren1_(children) {
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        // console.log('data received from worker', data);
        if (data) {
          console.log('CREATE PROJECT iterateChildren1_ WORKER');
          this.worker.postMessage(data);
        }
      };
      console.log('CREATE PROJECT iterateChildren1_ WORKER');
      this.worker.postMessage(children);
    }
  }
  iterateChildren1() {
    // if (this.worker) {
    //   this.worker.onmessage = ({ data }) => {
    //     // console.log('data received from worker', data);
    //     if (data) {
    //       this.treeNodes = data;
    //       // this.worker.postMessage(data);
    //     }
    //   };
    //   this.worker.postMessage({ data: this.treeNodes, isExpand: this.expandCollapse });
    // }
  }

  /* ------------------------------------- Tree Search -------------------------------------------- */
  captureSearchEvent(searchData) {
    this.isFilterApplied = searchData.isFilterApplied;
    this.searchResultList = (searchData.filterData);
    this.treeNodes = searchData.treeData;
    console.log('get tree data', this.treeNodes);
    if (this.isFilterApplied) {
      this.isReset = false;
    }
    if (!this.isFilterApplied && !this.isReset) {
      // this.getProjectAssociatedNodes();
      this.isReset = true;
    }

  }

  resetTree() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        this.treeNodes = null;
        // this.treeData = null;
        if (data) {
          this.treeNodes = data.taxonomyData;
          // this.treeData = data.taxonomyData;
          // console.log('data received from worker in project', this.treeNodes);
        }
      };
      console.log('CREATE PROJECT RESETTREE WORKER');
      this.worker.postMessage({
        data: this.treeNodes,
        location: 'utils',
        parameters: {
          level: 1,
          reset: true
        }
      });
    }
  }

  emitCurrentSelectedNode(currentNode) {
    this.currentSelectedNode.emit({ node: currentNode });
  }

}

