import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AutoCompleteComponent } from '../../common/auto-complete/auto-complete.component';
import { GlobalSettings } from 'src/app/global.settings';
import { CommonService } from 'src/app/common.service';
import { VersionSummaryComponent } from '../version-summary/version-summary.component';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-taxonomy-version',
  templateUrl: './taxonomy-version.component.html',
  styleUrls: ['./taxonomy-version.component.scss']
})
export class TaxonomyVersionComponent implements OnInit {

  versionCriteriaList = []; // holds list of criteria whose versions are displayed for the taxonomy
  autoCompleteTitle = ''; // holds title to show for auto-complete dropdown
  selectType = 'single'; // holds type of selection to be made in auto-complete dropdown (single-select / multi-select)
  uniqueId = 'id'; // holds unique id to identify list-items in auto-complete
  propName = 'title'; // holds property name (in data object) of which the value will be displayed in auto-complete dropdown list
  defaultCriteria: any; // holds version criteria to be selected by default
  selectedCriteria: any; // holds currently selected criteria
  tabItems = ['new', 'updated', 'deleted']; // holds list of tab items to display versioning
  currentTab = 'new'; // holds currently selected tab
  rawVersionData: any; // holds raw data array of version report

  @ViewChild('autoComplete', { static: false }) autoComplete: AutoCompleteComponent; // holds reference of auto-complete component
  @ViewChild('summaryReport', { static: false }) summaryReport: VersionSummaryComponent; // holds reference of version-summary component

  @Input() comparisonId = ''; // holds comparison ID of published taxonomy
  @Input() domain = ''; // holds environment domain url

  @Output() changeCriteriaEvent: EventEmitter<any>;

  constructor(
    private service: CommonService,
    private sharedService: SharedService
  ) {
    this.changeCriteriaEvent = new EventEmitter<any>();
  }

  ngOnInit() {
    this.versionCriteriaList = [
      {
        id: 1,
        title: 'Summary',
        type: 'summary'
      },
      {
        id: 2,
        title: 'Metadata',
        type: 'metadata'
      },
      {
        id: 3,
        title: 'Node Type',
        type: 'nodetype'
      },
      {
        id: 4,
        title: 'Association',
        type: 'association'
      }
    ];
    this.defaultCriteria = this.versionCriteriaList[0];
    this.selectedCriteria = this.defaultCriteria;
    this.onCriteriaSelected(this.defaultCriteria);
  }

  // FUNCTION to select new criteria on which taxonomy versions will be shown
  onCriteriaSelected(val) {
    this.selectedCriteria = val;
    this.changeVersionReport(val);
  }


  // FUNCTION to select tab item
  onTabSelected(tab) {
    this.currentTab = tab;
  }

  // FUNCTION to load version report as per selected criteria
  changeVersionReport(criteria) {
    switch (criteria.type) {
      case 'summary':
        this.getSummaryReport();
    }
  }

  // FUNCTION to get version summary data
  getSummaryReport() {
    this.sharedService.showLoader.next(true);
    if (this.domain) {
      // sGlobalSettings.env = this.domain;
    }
    console.log('comparison id', this.comparisonId);
    const url = GlobalSettings.VERSION_SUMMARY_REPORT + '?comparison_id=' + this.comparisonId + '&is_internal=false';

    this.service.getServiceData(url, true, true).then((res: any) => {
      if (res.success) {
        this.rawVersionData = res.data;
        this.loadReport(this.selectedCriteria.type);
      } else {
        this.rawVersionData = [];
        this.sharedService.showLoader.next(false);
      }
    }).catch(ex => {
      this.sharedService.showLoader.next(false);
    });
  }

  // FUNCTION to load table data
  loadReport(criteria) {
    switch (criteria) {
      case 'summary':
        this.summaryReport.extractSummaryData(this.rawVersionData);
        this.sharedService.showLoader.next(false);
        break;
    }
  }

  // FUNCTION to close auto-complete on clicking outside
  onClickedOutside(event) {
    this.autoComplete.openPanel(false);
  }


}
