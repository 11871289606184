import {
  Component,
  Inject
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  GlobalSettings
} from './global.settings';
import {
  CommonService
} from './common.service';
import {
  SharedService
} from './shared.service';
import {
  environment
} from 'src/environments/environment';
import { LoginService } from './login/login.service';
import { IndexedDBService } from './services/indexed-db.service';
import Utils from './utils';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  isPublic = false;
  title = ''; // holds the title to be displayed based on route path
  selectedTopMenu = ''; // holds the selected tab based on route path
  currentURL = ''; // holds the current url
  searchPlaceholder; // holds the place holder for search input box
  searchTrigger = false; // holds the boolean condition for search component to toggle
  orgCode = ''; // holds the org code from the url
  orgId = ''; // holds the org id from the url
  showMaintenance = false; // holds boolean to determine maintenance activity
  constructor(
    private router: Router, private activatedRoute: ActivatedRoute, private service: CommonService,
    private loginService: LoginService,
    private sharedService: SharedService, @Inject('Window') private window: Window, private idb: IndexedDBService) {
    console.log('hostName ', this.window.location.hostname);
    environment.base_url = 'https://api.' + this.window.location.hostname + '/server/'; //'https://acmt-qa.learningmate.com/server/';
    // environment.base_url = 'https://api.' + 'acmtapp-mtdev.learningmate.co' + '/server/'; //'https://acmt-qa.learningmate.com/server/';
    GlobalSettings.BASE_URL = environment.base_url;
    this.service.baseURL = environment.base_url;
    Utils.indexedDBServiceObj = idb;
    localStorage.setItem('project_role_permissions', '');
    this.isPublic = false;
    this.activatedRoute.params.subscribe((params: Params) => {
      console.log('$$ PARAMS in APP', params, this.service.baseURL);
      const org = JSON.parse(localStorage.getItem('orgDetails'));
      const cookieObj = document.cookie;
      let SSOUser;
      if (cookieObj.indexOf('SSOUser') !== -1 && cookieObj.indexOf('central_auth_token') !== -1) {
        SSOUser = JSON.parse(Utils.getCookie('SSOUser'));
      }

      if (params['org_code'] !== undefined) {
        this.getOrgCode(SSOUser ? SSOUser.TenantShortCode : params['org_code']);
      } else if (params['org_code'] === undefined && org && org.orgCode) {
        this.getOrgCode(SSOUser ? SSOUser.TenantShortCode : org.orgCode);
      } else {
        this.getOrgCode('undefined');
      }
    });

    this.checkCookie();
  }

  checkCookie() {
    setInterval(() => {
      const cookieObj = document.cookie;
      if (cookieObj.indexOf('SSOUser') === -1 && cookieObj.indexOf('central_auth_token') === -1) {
        localStorage.removeItem('access_token');
      }
    }, 1000);
  }

  getOrgCode(org_code) {
    const url = GlobalSettings.GET_ORGANIZATION_CODE + '/' + org_code;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.orgCode = res['org_code'];
      this.orgId = res['organization_id'];
      if (sessionStorage.getItem('frontface_url')) {
        const org = sessionStorage.getItem('frontface_url').split('/')[2];
        localStorage.setItem('orgDetails', JSON.stringify({
          'orgCode': org,
          'orgId': ''
        }));
        this.setOrgId(org);
      } else {
        localStorage.setItem('orgDetails', JSON.stringify({
          'orgCode': this.orgCode,
          'orgId': this.orgId
        }));
      }

      console.log('$$ org_code in APP', org_code, 'router.url', this.router.url, this.orgCode);
      if (this.router.url !== '/') {
        sessionStorage.setItem('frontface_url', this.router.url);
        const org = sessionStorage.getItem('frontface_url').split('/')[2];
        this.setOrgId(org);
      }

      try {
        if (org_code !== this.orgCode) {
          if (sessionStorage.getItem('current_url')) {
            console.log('$$ APP IF $$', '--org_code--', org_code, '--this.orgCode--', this.orgCode,
              [sessionStorage.getItem('current_url').split(',')[0]]);
            if (this.router.url.indexOf('register') === -1) {
              this.router.navigate([sessionStorage.getItem('current_url').split(',')[0]]);
            }
          } else {
            console.log('$$ APP ELSE $$', '--org_code--', org_code, '--this.orgCode--', this.orgCode, this.router.url);
            if (sessionStorage.getItem('frontface_url')) {
              if (this.router.url !== sessionStorage.getItem('frontface_url')) {
                this.router.navigate([sessionStorage.getItem('frontface_url')]);
              }
            } else {
              this.router.navigate(['/org/' + this.orgCode + '/home']);
            }

          }
        } else {
          console.log('Match Found App', window.location);
          if (!window.location.href.includes('org')) {
            this.router.navigate(['/org/' + this.orgCode + '/home']);
          }
        }
      } catch (err) { }
      // this.sharedService.orgDetails.next(res);
    })
      .catch((err: any) => { });
  }

  searchTriggered() {
    console.log('this.currentURL', this.currentURL);
    if (this.currentURL) {
      this.searchPlaceholder = this.currentURL.split('/')[this.currentURL.split('/').length - 2];
      if (this.searchPlaceholder === 'taxonomies') {
        this.searchPlaceholder = 'Taxonomies';
      }
      if (this.searchPlaceholder === 'pacingguides') {
        this.searchPlaceholder = 'Pacing Guide';
      }
      if ((this.currentURL.indexOf('taxonomies') !== -1) || (this.currentURL.indexOf('pacingguides') !== -1)) {
        this.searchTrigger = true;
      } else {
        this.searchTrigger = false;
      }
    } else {
      this.searchTrigger = false;
    }
  }

  setSearchTrigger($event) {
    this.searchTrigger = $event;
  }

  setOrgId(org_code) {
    const url = GlobalSettings.GET_ORGANIZATION_CODE + '/' + org_code;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.orgCode = res['org_code'];
      this.orgId = res['organization_id'];
      if (org_code === this.orgCode) {
        localStorage.setItem('orgDetails', JSON.stringify({
          'orgCode': this.orgCode,
          'orgId': this.orgId
        }));
      }
    })
      .catch((err: any) => { });
  }
}
