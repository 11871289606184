import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Inject
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  Observable
} from 'rxjs';
import 'rxjs/add/operator/filter';
import {
  SharedService
} from '../shared.service';
import {
  GlobalSettings
} from '../global.settings';
import {
  CommonService
} from '../common.service';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  HostListener
} from '@angular/core';
import {
  NgProgress
} from 'ngx-progressbar';
/*import {
  NgxBootstrapProductTourService
} from 'ngx-bootstrap-product-tour';
import {
  WalktroughService
} from '../help/walkthrough/walktrough.service';*/
import {
  NavBarComponent
} from '../nav-bar/nav-bar.component';
import {
  AuthenticateUserService
} from '../authenticateuser.service';
import Utils from '../utils';
import {
  HttpClient
} from '@angular/common/http';
import {
  ProjectStatusComponent
} from '../project/project-status/project-status.component';

import 'rxjs/add/observable/fromEvent';

import {
  environment
} from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { StatusPopupWindowComponent } from './status-popup-window/status-popup-window.component';

/// <reference path="../..assets/js/common.d.ts">
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss', './../nav-bar/nav-bar-logo-img.scss', './../pacing-guide/pacing-guide-authoring/pacing-guide-authoring-header.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  title = '';
  titleHtml = '';
  showNav = false;
  showPGHClass = false;
  showPReviewHClass = false;
  showCViewHClass = false;
  showBack = false;
  showCommentIcon = false;
  showWorkflowStatus = false;
  searchText = '';
  intervalId = null;
  alertEventRef: Subscription;
  justAfterLogin = true;
  currentURL = null;
  showLoader = false;
  showSearch = false;
  showHelp = false;
  selectedLeftMenu: any;
  inProgressProject = false;
  editTaxonomyTitle = false;
  private document_id: string;
  alertTime = 8;
  private editing = {};
  isWorkflow = false;
  canRquestProjectAccess = false;
  searchTrigger = false; // holds the boolean condition for search component to toggle
  searchPlaceholder; // holds the place holder for search input box
  workFlowId;
  showProjectProgress = false;
  onlineStatus: Observable<any>;
  offlineStatus: Observable<any>;
  submitReview = false;
  showSubmitReview = false;
  preventEdit = true;
  workFlowStages = [];
  showEditButton = false;
  showMoreOption = false;
  selectedFilterOption = '';
  isAuthoringPage = false;
  enableAdvanceSearch = false;
  selectedWorkFlowStageEvent: Subscription;
  csvStatusPopupHideEventSubscription: Subscription;
  workFlowStagesEvent: Subscription;
  workFlowPermissionEvent: Subscription;
  @ViewChild('alertCloseBtn', {
    static: false
  }) alertCloseBtn: ElementRef;
  @ViewChild('navBarRef', {
    static: false
  }) navBarComponent: NavBarComponent;
  @ViewChild('projectStatus', {
    static: false
  }) projectStatus: ProjectStatusComponent;
  @ViewChild('statusPopUp', {
    static: false
  }) statusPopUp: StatusPopupWindowComponent;
  customView = false;
  renamePacingGuide = false; // holds boolean to show if PG needs to be renamed based on environment selected
  faqUrls: any;
  faqTitle = '';
  removeBG = false;
  showNavBar = true;
  projectAuthoringView = false;
  textLeft = false;
  selectedStageId; // holds selected stage from project authoring
  profileCenter = false; // indicates whether user profile is navigated or not
  projectId;
  showCSVStatus = false; // holds boolean to determine if csv status needs to be shown up
  // public toasterconfig: ToasterConfig = new ToasterConfig({showCloseButton: true});
  projectTreeViewEvent: Subscription; // holds subscription for view type selected on project authoring
  viewTypeSelected = 'active'; // // holds value for view type selected on project authoring
  preventReorder = false; // holds boolean to determine if to allow reorder, disabled for active projects
  disableBtn = false; // holds flag to disable download button while downloading public review
  closeStatusWindow = true; // holds flag to close status pop-up
  addContainerScroll = true; // holds boolean to determine if container scroll is needed

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private service: CommonService,
    public ngProgress: NgProgress,
    private acivatedRoute: ActivatedRoute,
    /*private tourService: NgxBootstrapProductTourService,
     private walkService: WalktroughService,*/
    private authenticateUser: AuthenticateUserService,
    private http: HttpClient,
    private loginService: LoginService,
    @Inject('Window') private window: Window
  ) {

    this.projectTreeViewEvent = this.sharedService.projectTreeViewEvent.subscribe((type) => {
      this.viewTypeSelected = type + '';
    });

    this.sharedService.downloadStatusEvent.subscribe((val: any) => {
      this.disableBtn = val;
    });

    this.sharedService.preventReorder.subscribe((event: any) => {
      if (event && event.preventReorder && event.preventReorder === true) {
        this.preventReorder = true;
      } else {
        this.preventReorder = false;
      }
    });

    this.getJSON().subscribe(data => {
      this.faqUrls = data;
    });

    this.sharedService.faqEvent.subscribe((event: any) => {
      if (event && event.name) {
        this.faqTitle = event.name;
      }
    });
    this.sharedService.searchText.subscribe((event: any) => {
      if (event.searchText === 'clear') {
        this.searchText = '';
        this.updateSearchList();
      }
    });

    this.workFlowPermissionEvent = this.sharedService.workFlowPermission.subscribe((event: any) => {
      if (event.is_editable !== undefined) {
        setTimeout(() => {
          this.preventEdit = event.is_editable;
        }, 1000);
      }
    });

    this.sharedService.preventEdit.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
        if (!this.preventEdit) {
          if (router.url.indexOf('/app/taxonomy/detail') >= 0) {
            this.editTaxonomyTitle = true;
          }
        }
      } else {
        if (router.url.indexOf('/app/taxonomy/detail') >= 0) {
          this.editTaxonomyTitle = true;
        }
      }
      if (event.importTypeEdit === false) {
        this.editTaxonomyTitle = false;
      }

    });

    this.onlineStatus = Observable.fromEvent(window, 'online');
    this.onlineStatus.subscribe(e => {
      console.log('Online...');
    });
    this.offlineStatus = Observable.fromEvent(window, 'offline');
    this.offlineStatus.subscribe(e => {
      this.sharedService.sucessEvent.next({
        type: 'offline'
      });
    });
    // Calling the walkthrogh data service so that the data pre-loaded
    //  this.tourService.initialize(this.walkService.getWalkthroughData('taxonomy_list'));
    // const temp: any = [];
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      console.log('event.url123 ', event);
      Utils.removeShowClass();
      if (event.url !== '/' && event.url.indexOf('/register') === -1 && event.url.indexOf('/reset/') === -1 &&
        event.url.indexOf('/search') === -1 && event.url.indexOf('acceptInvite') === -1) {
        if (localStorage.getItem('access_token') === '' || localStorage.getItem('access_token') === null ||
          localStorage.getItem('access_token') === undefined) {
          // this.router.navigate(['/login']);
        } else {
          sessionStorage.setItem('current_url', event.url);
          // this.getUserPermission();
        }
      }
      if (event.url) {
        this.searchText = '';
        this.sharedService.searchEvent.next({
          'type': this.title,
          'text': this.searchText
        });

        const arr = event.url.split('/');

        if (arr.length > 3) {
          const ar = arr.slice(3);
          const str = ar.join('/');
          const url = '/' + str;
          this.currentURL = url;
        }
        console.log('event.url123 ####', this.currentURL);


        this.sharedService.currentComponentEvent.next({
          component: null
        });
        this.customView = false;
        this.showCommentIcon = false;
        this.showSearch = false;
        this.showHelp = false;
        this.inProgressProject = false;
        this.editTaxonomyTitle = false;
        this.isWorkflow = false;
        this.showNav = true;
        this.showWorkflowStatus = false;
        this.showPGHClass = false;
        this.showPReviewHClass = false;
        this.showCViewHClass = false;
        this.justAfterLogin = false;
        this.showMoreOption = false;
        this.titleHtml = '';
        this.removeBG = false;
        this.projectAuthoringView = false;
        this.textLeft = false;
        this.profileCenter = false;
        setTimeout(() => {
          Utils.preloaderWidthAdjustment(this.showNavBar);
        }, 100);
        switch (this.currentURL.length > 0) {
          case this.currentURL.includes('/app/home'):
            this.title = 'Dashboard';
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/project/list'):
            this.title = 'Projects';
            this.showSearch = true;
            this.showHelp = true;
            this.selectedLeftMenu = this.title;
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.faqTitle = 'project_list';
            this.checkCanUserHasPermissionToAccessProjectRequest();
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/taxonomy/list'):
            this.title = 'Taxonomies';
            this.showSearch = true;
            this.showHelp = true;
            this.selectedLeftMenu = this.title;
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.faqTitle = 'taxonomy_list';
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/team'):
            this.title = 'Team';
            this.showSearch = true;
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/workflow/list'):
            this.title = 'Workflows';
            this.showSearch = true;
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/metadata'):
            this.title = 'Metadata';
            this.showSearch = true;
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/caseservers'):
            this.title = 'Settings';
            this.showSearch = true;
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/nodetemplate'):
            this.title = 'Node Template';
            this.showSearch = true;
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/workflow'):
            this.isWorkflow = true;
            this.showSearch = true;
            if (this.currentURL.includes('workflow/create')) {
              this.showSearch = false;
              this.showHelp = true;
            }
            this.title = 'Workflows';
            this.selectedLeftMenu = 'Workflows';
            this.showEditButton = false;
            this.isAuthoringPage = false;
            if (this.navBarComponent) {
              this.navBarComponent.setTheCurrentItem(this.selectedLeftMenu);
            }
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/workflow/create'):
            this.isWorkflow = true;
            this.showSearch = false;
            this.showHelp = true;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/admin/reports'):
            this.title = 'Reports';
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.addContainerScroll = true;
            break;
          case (this.currentURL === '/'):
            this.justAfterLogin = true;
            break;
          case this.currentURL.includes('/app/pacingguide/list'):
            this.renamePacingGuide = environment.renamePacingGuide;
            // tslint:disable-next-line: max-line-length
            const {
              renamePacingGuide, showPublishedTaxonomy
            } = Utils.checkChangesForSpecificOrg(this.renamePacingGuide, this.router.url.split('/')[2]);
            this.renamePacingGuide = renamePacingGuide;
            if (this.renamePacingGuide) {
              this.title = 'Unit Planners';
            } else {
              this.title = 'Pacing Guides';
            }
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.showSearch = true;
            this.showHelp = true;
            this.selectedLeftMenu = this.title;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/publicreview/list'):
            this.title = 'Public Review';
            this.showEditButton = false;
            this.isAuthoringPage = false;
            this.showSearch = true;
            this.showHelp = true;
            this.selectedLeftMenu = this.title;
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes('/app/notifications'):
            this.title = 'Notifications';
            this.addContainerScroll = true;
            break;
          case this.currentURL.includes(Utils.ROUTE_USER_PROFILE):
            this.title = 'My Profile';
            this.selectedLeftMenu = this.title;
            this.showHelp = true;
            this.profileCenter = true;
            this.addContainerScroll = true;
            break;
        }
        document.title = 'ACMT : ' + this.title;
        this.showBack = false;
        if (this.currentURL.indexOf('/app/admin/workflow/edit/') >= 0) {
          this.isWorkflow = true;
          this.title = 'Workflows';
          this.isAuthoringPage = false;
          this.showSearch = true;
          this.showHelp = true;
        }
        if (this.currentURL.indexOf('/app/admin/workflow/view/') >= 0) {
          this.isWorkflow = true;
          this.title = 'Workflows';
          this.isAuthoringPage = false;
          this.showSearch = true;
        }
        if (this.currentURL.indexOf('/app/project/authoring/') >= 0) {
          this.addContainerScroll = false;
          this.showBack = true;
          this.showNav = false;
          this.projectAuthoringView = true;
          this.textLeft = true;
          this.showCommentIcon = true;
          this.showProjectProgress = false;
          this.showHelp = true;
          this.isAuthoringPage = true;
          if (localStorage.getItem('project_type') === '2') {
            if (!this.preventEdit) {
              this.showEditButton = true;
            }
          } else {
            this.showEditButton = false;
            this.showMoreOption = true;
          }
          // setTimeout(() => {
          //   this.inProgressProject = this.authenticateUser.AuthenticProjectPermissions('Change Workflow Stage');
          //   if (this.inProgressProject) {
          //     this.showProjectProgress = true;
          //   }
          // }, 1000);

          // tslint:disable-next-line:max-line-length
          console.log('this.authenticateUser.AuthenticProjectPermissions', this.authenticateUser.AuthenticProjectPermissions('Change Workflow Stage'));

          this.acivatedRoute.params.subscribe((params: Params) => {

            if (params['id'] !== undefined) {
              const projectId = params['id'];
              console.log('projectId ***** ', projectId);
              // this.router.navigate(['./app/projectdetail', projectId]);
              // tslint:disable-next-line: max-line-length
              const workflowAsso = Utils.checkProjectPermission(projectId, 'Change Workflow Stage', 'project_permissions', this.selectedStageId, 'individual_project_role_permissions');
              if (workflowAsso.hasProject) {
                this.inProgressProject = workflowAsso.valid;
              }
            }
          });
          this.selectedLeftMenu = 'Projects';
        }
        // if (this.currentURL.indexOf('/app/project/tableconfiguration/') >= 0) {
        //   this.showSaveBtn = true;
        //   this.showCancelBtn = true;
        //   this.showBack = true;
        //   this.showNav = false;
        //   this.title = 'Configure Table';
        //   this.removeBG = true;
        // }
        if (this.currentURL.indexOf('/app/pacingguide/authoring') >= 0) {
          this.isWorkflow = true;
          this.showBack = true;
          this.showNav = false;
          this.projectAuthoringView = true;
          this.textLeft = true;
          // this.showHelp = true;
          this.showWorkflowStatus = true;
          this.showPGHClass = true;
          this.showEditButton = false;
          this.isAuthoringPage = false;
          this.selectedLeftMenu = 'Pacing Guides';
        }
        if (this.currentURL.indexOf('/app/admin/permissions') >= 0) {
          this.showBack = true;
          this.textLeft = true;
          this.showHelp = true;
        }
        if (this.currentURL.indexOf('/app/taxonomy/detail') >= 0) {
          // setTimeout(() => {
          //   console.log('this.preventEdit', this.preventEdit);
          //   if (this.preventEdit) {
          //     this.editTaxonomyTitle = true;
          //   }
          // }, 1000);
          this.selectedLeftMenu = 'Taxonomies';
          this.showHelp = true;
          this.showSearch = true;
          this.showEditButton = false;
          this.isAuthoringPage = false;
          this.addContainerScroll = false;
        }
        if (this.currentURL.indexOf('/app/project/detail') >= 0) {
          this.selectedLeftMenu = 'Projects';
          this.showHelp = true;
          this.showSearch = true;
          this.showEditButton = false;
          this.isAuthoringPage = false;
          this.showProjectProgress = false;
          this.addContainerScroll = false;
        }
        if (this.currentURL.indexOf('/app/pacingguide/detail') >= 0) {
          this.renamePacingGuide = environment.renamePacingGuide;
          // tslint:disable-next-line: max-line-length
          const {
            renamePacingGuide,
            showPublishedTaxonomy
          } = Utils.checkChangesForSpecificOrg(this.renamePacingGuide, this.router.url.split('/')[2]);
          this.renamePacingGuide = renamePacingGuide;
          if (this.renamePacingGuide) {
            this.selectedLeftMenu = 'Unit Planners';
          } else {
            this.selectedLeftMenu = 'Pacing Guides';
          }
          this.addContainerScroll = false;
        }
        if (this.currentURL.indexOf('/app/publicreview/review/') >= 0 || this.currentURL.indexOf('/app/publicreview/reviewed/') >= 0) {
          this.showBack = true;
          this.showNav = false;
          this.showCommentIcon = false;
          this.showProjectProgress = false;
          this.showHelp = false;
          this.showPReviewHClass = true;
          this.submitReview = true;
          this.showSubmitReview = true;
          this.showEditButton = false;
          this.projectAuthoringView = true;
          this.textLeft = true;
          this.isAuthoringPage = false;
          if (this.currentURL.indexOf('/app/publicreview/reviewed/') >= 0) {
            this.showSubmitReview = false;
          }
          // this.selectedLeftMenu = 'Projects';
          this.onToggle('inactive');
        } else {
          this.submitReview = false;
        }
        if (this.currentURL.indexOf('/app/taxonomy/customview/') >= 0) {
          this.customView = true;
          this.showBack = true;
          this.showNav = false;
          this.textLeft = true;
          this.projectAuthoringView = true;
          this.showProjectProgress = false;
          this.showCViewHClass = true;
        }
        this.searchTrigger = false;
      }

    });

    this.sharedService.setTitleEvent.subscribe((event: any) => {
      this.titleHtml = '';

      if (event && event.title) {

        switch (event.type) {
          case 'project':
            this.title = event.title;
            break;
          case 'taxonomy':
            this.title = event.title;
            this.titleHtml = event.title_html;
            this.document_id = event.document_id;
            this.editTaxonomyTitle = event.isEditable;
            break;
          case 'team':
            this.title = event.title;
            this.selectedLeftMenu = 'Team';
            break;
          case 'workflows':
            this.title = event.title;
            this.selectedLeftMenu = 'Workflows';
            break;
          case 'notification':
            this.title = event.title;
            break;
          case 'public_review':
            this.title = event.title;
            break;
          default:
            break;
        }
        document.title = 'ACMT : ' + this.title;
        if (document.getElementById(this.editTaxonomyTitle ? 'titleTagEdit' : 'titleTag')) {
          document.getElementById(this.editTaxonomyTitle ? 'titleTagEdit' : 'titleTag').focus();
        }
      }
    });

    this.sharedService.showLoader.subscribe((event: any) => {
      console.log('sharedService  ', event);
      if (event === true) {
        ngProgress.start();
      } else {
        ngProgress.done();
      }

    });
    this.sharedService.workFlowId.subscribe((event: any) => {
      if (event.workFlowId !== undefined) {
        setTimeout(() => {
          this.workFlowId = event.workFlowId;
          this.selectedStageId = event.currentWorkflowStage;
          this.setWorkflowId(event.workFlowId, event.projectId);
        }, 1000);

      }
    });

    this.workFlowStagesEvent = this.sharedService.workFlowStages.subscribe((event: any) => {
      if (event.workFlowStages !== undefined) {
        setTimeout(() => {
          this.workFlowStages = event.workFlowStages;
        }, 1000);
      }
      if (event.selectedFilterOption !== undefined) {
        setTimeout(() => {
          this.selectedFilterOption = event.selectedFilterOption;
        }, 1000);
      }
    });


    this.selectedWorkFlowStageEvent = this.sharedService.workFlowId.subscribe((event: any) => {
      this.showProjectProgress = false;
      if (this.currentURL && this.currentURL.indexOf('/app/project/authoring') >= 0) {
        if (event.workFlowId !== undefined) {
          setTimeout(() => {
            this.selectedStageId = event.currentWorkflowStage;
            this.projectId = event.projectId;
            this.userRoleRight();
          }, 1000);
        } else {
          setTimeout(() => {
            this.inProgressProject = this.authenticateUser.AuthenticProjectPermissions('Change Workflow Stage');
            if (this.inProgressProject) {
              this.showProjectProgress = true;
            }
          }, 1000);
        }
      }
    });

    this.sharedService.sucessEvent.subscribe((event: any) => {
      if (event && event.type === 'update_permissions') {
        this.userCheckImportTaxonomyPermission();
      }
    });

    this.sharedService.csvStatusUpdateEvent.subscribe((event: any) => {
      if (event && event.checkStatus !== undefined && event.checkStatus) {
        this.userCheckImportTaxonomyPermission();
      }
    });

    this.csvStatusPopupHideEventSubscription = this.sharedService.hidePopup.subscribe((event: any) => {
      this.showCSVStatus = !event;
    });

  }

  userRoleRight() {
    this.inProgressProject = this.authenticateUser.AuthenticProjectPermissions('Change Workflow Stage');
    if (this.inProgressProject && this.currentURL.indexOf('/app/project/authoring/') >= 0) {
      this.showProjectProgress = true;
    }
    // tslint:disable-next-line: max-line-length
    const changeWorkflowStage = Utils.checkProjectPermission(this.projectId, 'Change Workflow Stage', 'project_permissions', this.selectedStageId, 'individual_project_role_permissions');
    if (changeWorkflowStage.hasProject) {
      this.showProjectProgress = changeWorkflowStage.valid;
      this.inProgressProject = changeWorkflowStage.valid;
    }
  }

  userCheckImportTaxonomyPermission() {
    const self = this;
    setTimeout(() => {
      // console.log('self.authenticateUser.authenticTaxonomy(', self.authenticateUser.authenticTaxonomy('Import Taxonomy'), self.authenticateUser.authenticTaxonomy('Create Taxonomy'))
      if (self.authenticateUser.authenticTaxonomy('Import Taxonomy') && self.authenticateUser.authenticTaxonomy('Create Taxonomy')) {
        this.showCSVStatus = true;
      } else {
        this.showCSVStatus = false;
      }
    }, 1200);
  }

  checkCSVStatus() {
    const self = this;
    setTimeout(() => {
      // console.log('self.authenticateUser.authenticTaxonomy(', self.authenticateUser.authenticTaxonomy('Import Taxonomy'), self.authenticateUser.authenticTaxonomy('Create Taxonomy'))
      if (self.authenticateUser.authenticTaxonomy('Import Taxonomy') &&
        self.authenticateUser.authenticTaxonomy('Create Taxonomy')) {
        const url = GlobalSettings.CSV_STATUS + localStorage.getItem('user_id') + '?polling_for=taxonomy';
        this.service.getServiceData(url, true, false).then((res: any) => {
          const statusData = res;
          if (statusData && statusData.length) {
            if (statusData.some((elem: any) => (elem.status !== 3 && elem.status !== 4))) {
              this.showCSVStatus = true;
            } else {
              this.showCSVStatus = false;
            }
          }
          this.closeStatusWindow = false;
        });
      } else {
        this.showCSVStatus = false;
      }
    }, 1200);
  }

  ngOnInit() {
    if (GlobalSettings.SSO_USER) {
      this.checkSessionCookie();
    }
    this.checkCSVStatus();
  }

  setWorkflowId(id, projectId) {
    this.workFlowId = id;
    if (this.projectStatus) {
      this.projectStatus.getProjectDetail(projectId);
      this.projectStatus.getWorkFlowStagesList(this.workFlowId);
    }
  }


  // public getJSON(): Observable<any> {
  //   return this.http.get('./assets/json/messages.json');
  // }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.currentURL.indexOf('/app/home') >= 0) {
      window.history.forward();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sharedService.windowResizeEvent.next({
      'event': event
    });
    this.calculateContainerHeight();
    Utils.preloaderWidthAdjustment(this.showNavBar);
  }

  /* --------- Functionality to calcualate height on container start --------- */

  calculateContainerHeight() {
    const windowHeight = window.innerHeight;
    let navHeight;
    if (document.getElementById('topNav')) {
      navHeight = document.getElementById('topNav').offsetHeight;
    }
    const containerHeight = windowHeight - navHeight;
    if (document.getElementById('touchsurface')) {
      document.getElementById('touchsurface').style.height = containerHeight + 'px';
    }
  }

  /* --------- Functionality to calcualate height on container end --------- */


  // onClose() {
  //   this.showAlert = false;
  //   this.alertMsg = '';
  // }

  onBack() {
    if (this.currentURL) {
      if (this.currentURL.indexOf('/app/project/authoring/') >= 0) {
        let path;
        if (localStorage.getItem('project_type') === '1') {
          this.showNav = true;
          this.showPGHClass = false;
          path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_DETAIL + '/' + localStorage.getItem('project_id');
        } else {
          this.showNav = true;
          path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PACING_GUIDE_LIST;
        }
        this.router.navigate([path]);


      }
      if (this.currentURL.indexOf('/app/admin/permissions') >= 0) {
        const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_ADMIN_WORKFLOW_LIST;
        this.router.navigate([path]);
      }
      if (this.currentURL.indexOf('/app/pacingguide/authoring/') >= 0) {
        this.showNav = true;
        this.projectAuthoringView = true;
        this.textLeft = true;
        // this.router.navigate(['/app/pacingguide/list']);
        const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PACING_GUIDE_LIST;
        this.router.navigate([path]);
      }
      if ((this.currentURL.indexOf('/app/publicreview/review/') >= 0) || (this.currentURL.indexOf('/app/publicreview/reviewed/') >= 0)) {
        this.showNav = true;
        this.showPReviewHClass = false;
        this.projectAuthoringView = true;
        this.textLeft = true;
        // this.router.navigate(['/app/publicreview/list']);
        const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PUBLIC_REVIEW_LIST;
        this.router.navigate([path]);
      }
      if (this.currentURL.indexOf('/app/taxonomy/customview/') >= 0) {
        this.showNav = true;
        this.showCViewHClass = false;
        const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_DETAIL + '/' + localStorage.getItem('taxonomy_id');
        this.router.navigate([path]);
      }
    }
    this.sharedService.workFlowId.next({
      workFlowId: null,
      workFlowStageId: null
    });
  }

  onSearch() {
    // this.sharedService.searchEvent.next({
    //   'type': this.title,
    //   'text': this.searchText
    // });
    this.sharedService.searchTextEvent.next({
      text: this.searchText
    });
    if ((this.title).toLocaleLowerCase() === 'taxonomies') {
      this.searchText = '';
      setTimeout(() => {
        if (document.getElementById('searchTaxonomies')) {
          document.getElementById('searchTaxonomies').focus();
        }
      }, 300);
    }
  }

  updateSearchList() {
    if (this.searchText) {
      this.sharedService.searchTextEvent.next({
        text: this.searchText
      });
      // this.sharedService.searchEvent.next({
      //   'type': this.title,
      //   'text': ''
      // });
    }

  }

  onProgressChange(event) {

  }

  getUserPermission() {
    const url = GlobalSettings.GET_PERMISSION + '' + localStorage.getItem('role_id');
    if (localStorage.getItem('access_token')) {
      this.service.getServiceData(url).then((res: any) => {
        // console.log(' getUerPemfsfsfsfs ', res);
        localStorage.setItem('permissions', JSON.stringify(res));
        this.service.role_user_permissions = res.role_permissions.role_user_permissions;
        this.service.project_permissions = res.role_permissions.project_permissions;
        this.service.taxonomy_permissions = res.role_permissions.taxonomy_permissions;
        this.service.workflow_permissions = res.role_permissions.workflow_permissions;
        this.service.metadata_permissions = res.role_permissions.metadata_permissions;
        this.service.note_permissions = res.role_permissions.note_permissions;
        this.service.comment_permissions = res.role_permissions.comment_permissions;
        this.service.node_template_permissions = res.role_permissions.node_template_permissions;
        this.service.node_type_permissions = res.role_permissions.node_type_permissions;
        this.service.notification_permissions = res.role_permissions.notification_permissions;
        this.sharedService.userPermissionLoaded.next(true);
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculateContainerHeight();
    }, 1000);
    // init_searchAnimation();
  }

  ngOnDestroy() {
    if (this.onlineStatus) {
      this.onlineStatus = null;
      this.offlineStatus = null;
    }
    if (this.workFlowStagesEvent) {
      this.workFlowStagesEvent.unsubscribe();
    }
    this.selectedFilterOption = '';
    if (this.workFlowPermissionEvent) {
      this.workFlowPermissionEvent.unsubscribe();
    }
    if (this.selectedWorkFlowStageEvent) {
      this.selectedWorkFlowStageEvent.unsubscribe();
    }
  }

  onToggle(type) {
    console.log('onToggle ', type);
    this.sharedService.projectTreeViewEvent.next(type);
  }

  onHelpClick() {
    const base_url = 'https://api.' + this.window.location.hostname + '/faq/';
    const fAqUrL = base_url + this.faqUrls[this.faqTitle];
    window.open(fAqUrL, '_blank');
    /*if (this.tourService) {
      this.tourService.start();
      setTimeout(() => {
        // To move focus on 'Next' or 'End' button of tour service popup
        if (document.getElementById('nextTourBtn')) {
          document.getElementById('nextTourBtn').focus();
        } else if (!document.getElementById('prevTourBtn') && document.getElementById('endTourBtn')) {
          document.getElementById('endTourBtn').focus();
        }
      }, 300);
    }*/
  }

  checkCanUserHasPermissionToAccessProjectRequest() {

    this.canRquestProjectAccess = false;

    if (this.authenticateUser.AuthenticProjectPermissions('Delete Project') === false &&
      this.authenticateUser.AuthenticProjectPermissions('Edit Project') === false &&
      this.authenticateUser.AuthenticProjectPermissions('View Associated Nodes') === false &&
      this.authenticateUser.AuthenticProjectPermissions('View Project List')) {
      this.canRquestProjectAccess = true;
    }
  }

  /* --------- Functionality to emit project request modal click event start ---------*/

  requestAccessModalClicked() {
    this.sharedService.projectRequestClicked.next(true);
  }

  /* --------- Functionality to emit project request modal click event end ---------*/

  searchTriggered() {
    this.sharedService.searchTextEvent.next({
      trigger: true
    });
    if (this.currentURL) {
      this.enableAdvanceSearch = false;
      this.searchPlaceholder = this.currentURL.split('/')[this.currentURL.split('/').length - 2];
      if (this.searchPlaceholder === 'pacingguide') {
        this.searchPlaceholder = 'Pacing Guide';
      }
      this.searchTrigger = true;
      setTimeout(() => {
        if (document.getElementById('searchTaxonomies')) {
          document.getElementById('searchTaxonomies').focus();
        }
      }, 100);
      // if ((currentUrl.indexOf('taxonomies') !== -1) || (currentUrl.indexOf('dashboard') !== -1)) {
      if ((this.currentURL.indexOf('taxonomy/list') !== -1) || (this.currentURL.indexOf('project/list') !== -1) ||
        (this.currentURL.indexOf('taxonomy/detail') !== -1) || (this.currentURL.indexOf('project/detail') !== -1)) {
        this.enableAdvanceSearch = true;
      } else {
        // this.searchTrigger = false;
        this.enableAdvanceSearch = false;
      }
    } else {
      this.searchTrigger = false;
      this.enableAdvanceSearch = false;
    }
  }

  setSearchTrigger($event) {
    this.sharedService.searchTextEvent.next({
      trigger: false
    });
    this.searchTrigger = $event;
  }

  getCommentReport() {
    this.sharedService.getCommentReport.next({
      getCommentReport: true,
    });
  }

  downloadReviewReport() {
    this.disableBtn = true;
    this.sharedService.downloadReview.next({
      downloadReport: true,
    });
  }

  filterData(obj) {
    if (obj) {
      this.selectedFilterOption = obj.stage_name;
      this.sharedService.workFlowStages.next({
        'workFlowId': obj.workflow_id,
        'workFlowStageId': obj.workflow_stage_id
      });
    }
  }
  goToEditPage() {
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PACING_GUIDE_AUTHORING + '/' + localStorage.getItem('project_id');
    this.router.navigate([path]);
  }

  onUpdateTaxonomyClick(event, action) {
    if (action === 'update') {
      if (this.viewTypeSelected === 'active') {
        event.preventDefault();
        event.stopPropagation();
        this.sharedService.sucessEvent.next({
          type: 'disallowUpdateTaxonomy'
        });
      } else {
        this.sharedService.updateTaxonomyEvent.next({
          update: true
        });
      }
      this.sharedService.modalTriggerLocation.next({
        modalTriggerLocation: 'project'
      });
    } else {
      this.sharedService.updateTaxonomyEvent.next({
        reorder: true
      });
    }
  }

  // onCancelClicked() {
  //   if (this.currentURL) {
  //     let path;
  //     if (this.currentURL.indexOf('/app/project/tableconfiguration/') >= 0) {
  //       const projectDetail = JSON.parse(localStorage.getItem('project_details'));
  //       this.showNav = true;
  //       this.showPGHClass = false;
  //       this.title = projectDetail.title;
  //       path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_DETAIL + '/' + projectDetail.id;
  //     }
  //     this.router.navigate([path]);
  //   }
  // }

  // onSaveClicked() {
  //   if (this.currentURL) {
  //     let path;
  //     if (this.currentURL.indexOf('/app/project/tableconfiguration/') >= 0) {
  //       this.sharedService.saveTableChangeEvent.next({
  //         page: 'project'
  //       });
  //       const projectDetail = JSON.parse(localStorage.getItem('project_details'));
  //       this.showNav = true;
  //       this.showPGHClass = false;
  //       this.title = projectDetail.title;
  //       path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_LIST;
  //     }
  //     this.router.navigate([path]);
  //   }
  // }

  // minimize() {
  //   document.getElementById("headerTitle").style.display = "none";
  //   document.getElementById("collapseToggle").style.display = "none";
  //   document.getElementById("btnMax").style.display = "inline-block";
  //   document.getElementById("btnMin").style.display = "none";
  //   document.getElementById("myForm").style.width = 50 + 'px';
  //   document.getElementById("collapseCSV").style.minHeight = 0 + "px";
  //   document.getElementById("collapseCSV").style.maxHeight = 0 + "px";
  // }

  // maximize() {
  //   document.getElementById("btnMax").style.display = "none";
  //   document.getElementById("headerTitle").style.display = "inline-block";
  //   document.getElementById("collapseToggle").style.display = "inline-block";
  //   document.getElementById("btnMin").style.display = "inline-block";
  //   document.getElementById("myForm").style.width = 350 + 'px';
  //   document.getElementById("collapseCSV").style.minHeight = 200 + "px";
  //   document.getElementById("collapseCSV").style.maxHeight = 430 + "px";
  // }

  updateShowNav(e) {
    this.showNavBar = e;
    Utils.preloaderWidthAdjustment(e);
  }
  public getJSON(): Observable<any> {
    return this.http.get('./assets/json/helpFAQ.json');
  }


  close() {
    this.closeStatusWindow = true;
    this.showCSVStatus = false;
  }

  generateCustomLink() {
    const base_url = 'https://api.' + this.window.location.hostname + '/faq/';
    let fAqUrL = '';
    if (this.faqUrls) {
      fAqUrL = base_url + this.faqUrls[this.faqTitle];
    }
    return fAqUrL;
  }

  checkSessionCookie() {
    let SSOUser;
    const cookieObj = Utils.getCookie('SSOUser');
    if (cookieObj) {
      SSOUser = JSON.parse(cookieObj);
    }

    if (SSOUser) {
      // tslint:disable-next-line: radix
      this.loginService.refreshTimer = parseInt(SSOUser.RefreshTime) * 1000;
    }
    this.loginService.validateCookie();
  }

  /* --------- Fetch to emit event for edit title start --------- */

  selectEditIcon() {
    this.sharedService.editTitle.next(true);
  }

  /* --------- Fetch to emit event for edit title end --------- */
}
