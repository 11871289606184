import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-version-summary',
  templateUrl: './version-summary.component.html',
  styleUrls: ['./version-summary.component.scss']
})
export class VersionSummaryComponent implements OnInit {

  listOfColumn = []; // holds list of column headers in version-summary table
  summaryData = []; // holds the data array to be shown in version-summary table
  defaultSortByColumn: any; // holds the column object by which the table data will be sorted by default
  pageDetail: any; // holds object for pagination details
  showLoader = false; // holds flag to show loader until data gets prepared

  @Input() rawData: any; // holds raw input data for version summary report

  constructor() { }

  ngOnInit() {
    this.listOfColumn = [
      {
        name: 'Updates to',
        propName: 'displayName',
        class: '',
        type: 'text'
      },
      {
        name: 'Type',
        propName: 'type',
        class: '',
        type: 'text'
      },
      {
        name: 'New',
        propName: 'new',
        class: '',
        type: 'text'
      },
      {
        name: 'Updated',
        propName: 'edit',
        class: '',
        type: 'text'
      },
      {
        name: 'Deleted',
        propName: 'delete',
        class: '',
        type: 'text'
      }
    ];
    this.defaultSortByColumn = this.listOfColumn[2];
  }

  // FUNCTION to change pagination
  getChanges(evt) {
  }

  // FUNCTION to extract version summary data from raw data to display in the table
  extractSummaryData(rawData) {
    this.showLoader = true;
    const data = rawData;
    this.summaryData = [];
    let associationObj;
    if (data.document) {
      this.summaryData.push(data.document);
    }
    if (data.associations) {
      associationObj = {
        displayName: data.associations.displayName,
        type: data.associations.displayName,
        delete: 0,
        edit: 0,
        new: 0
      };
      data.associations.items.forEach(item => {
        associationObj.delete += item.delete;
        associationObj.edit += item.edit;
        associationObj.new += item.new;
      });
      this.summaryData.push(associationObj);
    }
    if (data.metadata) {
      data.metadata.items.forEach(item => {
        if (this.summaryData.length <= 25) {
          this.summaryData.push({
            displayName: item.type,
            type: data.metadata.displayName,
            delete: item.delete,
            edit: item.edit,
            new: item.new
          });
        }
      });
    }
    if (data.nodetype) {
      data.nodetype.items.forEach(item => {
        if (this.summaryData.length <= 25) {
          this.summaryData.push({
            displayName: item.type,
            type: data.nodetype.displayName,
            delete: item.delete,
            edit: item.edit,
            new: item.new
          });
        }
      });
    }
    this.showLoader = false;
  }

}
