import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import {
  SharedService
} from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
/* {
  ToasterService,
  ToasterConfig
} from 'angular5-toaster';*/
import {
  ToastrService
} from 'ngx-toastr';
import {
  HttpClient
} from '@angular/common/http';
import {
  Observable
} from 'rxjs';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html'
})
export class ToastMessageComponent implements OnInit, OnDestroy {

  alertMsg = '';
  alertMsgs: any;
  alertEventRef: Subscription;
  isSuccess = true;


  @Input() justAfterLogin;
  @ViewChild('alertCloseBtn', { static: false }) alertCloseBtn: ElementRef;

  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    /*private toasterService: ToasterService*/
    private toastrService: ToastrService) {
    this.getJSON().subscribe(data => {
      this.alertMsgs = data;
    });
    this.alertEventRef = this.sharedService.sucessEvent.subscribe((event: any) => {
      if (this.justAfterLogin === false) {
        if ((event && event.type) || (event && event.customMsg)) {
          if (event.customMsg) {
            this.alertMsg = event.customMsg;
          } else {
            this.alertMsg = this.alertMsgs[event.type];
          }
          this.isSuccess = true;
          if (event.type === 'session_expired') {
            this.isSuccess = false;
          }
          if (event.msgType === this.sharedService.MSG_TYPE_WARNING) {
            this.isSuccess = false;
          }

          if (event.type === 'node_details_missing' || event.type === 'unsaved_exempler_data' || event.type === 'required_fields_missing' ||
            event.type === 'taxonomy_empty_fields' || event.type === 'document_title_empty' ||
            event.type === 'error' || event.type === 'invalid_form_submission' ||
            event.type === 'workflow_exist' || event.type === 'workflow_step_exist' ||
            event.type === 'metadata_usage' || event.type === 'workflow_associated' ||
            event.type === 'prevent_delete_stage' || event.type === 'prevent_delete_role' ||
            event.type === 'prevent_last_delete_stage' || event.type === 'test_connection_failed' || event.type === 'server_name_exist' ||
            event.type === 'server_url_exist' || event.type === 'server_name_mandatory' || event.type === 'case_server_response' ||
            event.type === 'offline' || event.type === 'prevent_delete_metadataset' || event.type === 'prevent_taxonomy_edit' ||
            event.type === 'prevent_taxonomy_delete' || event.type === 'login_error' || event.type === 'servername_api_inuse' ||
            event.type === 'duplicate_review_create' || event.type === 'exe_file_upload' || event.type === 'no_project' ||
            event.type === 'no_taxonomy' || event.type === 'cache_detail_error' || event.type === 'reorder_error' ||
            event.type === 'rte_update_missing' || event.type === 'no_permission' || event.type === 'error_association' ||
            event.type === 'user_deleted' || event.type === 'user_exist' || event.type === 'user_deactive' ||
            event.type === 'not_delete_child_association' || event.type === 'project_permission_denied' ||
            event.type === 'delete_taxanomy_failure' || event.type === 'unregistered_user' || event.type === 'preventReorder' ||
            event.type === 'disallowReorder' || event.type === 'disallowUpdateTaxonomy') {
            this.isSuccess = false;
          }


          if (event.type === 'delete_taxonomy_permission') {
            this.isSuccess = false;
            const countNO = event.count;
            this.alertMsg = this.alertMsg + ' ' + countNO + ' project(s)';
          }

          this.popToast(this.isSuccess, this.alertMsg);

        }
      } else {
        this.justAfterLogin = false;
      }
      setTimeout(() => {
        // focusOnAlertCloseBtn();
      }, 1000);
    });
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.alertEventRef) {
      this.alertEventRef.unsubscribe();
    }
  }

  popToast(type, alertMsg) {

    if (type) {
      this.toastrService.success(alertMsg, 'Notification', {
        closeButton: true,
        disableTimeOut: false,
        timeOut: 5000,
        extendedTimeOut: 1000,
        enableHtml: false,
        progressBar: true,
        toastClass: 'ngx-toastr',
        positionClass: 'toast-top-center',
        titleClass: 'toast-title',
        messageClass: 'toast-message',
        easing: 'ease-in',
        easeTime: 300,
        tapToDismiss: true,
        onActivateTick: false,
        progressAnimation: 'decreasing'
      });
    } else {
      this.toastrService.error(alertMsg, '', {
        closeButton: true,
        disableTimeOut: false,
        timeOut: 5000,
        extendedTimeOut: 1000,
        enableHtml: false,
        progressBar: true,
        toastClass: 'ngx-toastr',
        positionClass: 'toast-top-center',
        titleClass: 'toast-title',
        messageClass: 'toast-message',
        easing: 'ease-in',
        easeTime: 300,
        tapToDismiss: true,
        onActivateTick: false,
        progressAnimation: 'decreasing'
      });
    }
    // this.toastrService.show(alertMsg,'',null,type ? 'toast-success' : 'toast-error')
    setTimeout(() => {
      if (document.querySelector('.toast-message')) {
        document.querySelector('.toast-message').setAttribute('role', 'alert');
      }
      if (this.isSuccess && document.querySelector('.toast-message div')) {
        document.querySelector('.toast-message div').setAttribute('aria-live', 'assertive');
      }
      if (document.querySelector('.toast-message')) {
        document.querySelector('.toast-message').removeAttribute('aria-label');
      }
      if (document.querySelector('.toast-message')) {
        document.querySelector('.toast-message').setAttribute('id', 'textmsg');
      }
      localStorage.setItem('datatableItem', '');
      focusOnCancelBtn('.toast-close-button');
    }, 50);
  }

  public getJSON(): Observable<any> {
    return this.http.get('./assets/json/messages.json');
  }
}
