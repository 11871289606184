import {
  from
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { IndexedDBService } from './services/indexed-db.service';


export default class Utils {

  static indexedDBServiceObj: IndexedDBService;
  static projectPermissions;
  static individualProjectPermissions;

  static fileTypes = [
    {
      type: 'AVI',
      displayName: 'Audio Video Interleave'
    },
    {
      type: 'MOV',
      displayName: 'MOV video file'
    },
    {
      type: 'MP4',
      displayName: 'MPEG video file'
    },
    {
      type: 'OGG',
      displayName: 'OGG video file'
    },
    {
      type: 'WMV',
      displayName: 'WMV video file'
    },
    {
      type: 'WEBM',
      displayName: 'WEBM video file'
    },
    {
      type: 'MP3',
      displayName: 'MPEG-1 Audio Layer-3'
    },
    {
      type: 'WAV',
      displayName: 'Wave file'
    },
    {
      type: 'OGG',
      displayName: 'Ogg Vorbis Audio File'
    },
    {
      type: 'DOC',
      displayName: 'Microsoft Word Document'
    },
    {
      type: 'DOCX',
      displayName: 'Microsoft Word Document'
    },
    {
      type: 'XLS',
      displayName: 'Microsoft Excell Spreadsheet'
    },
    {
      type: 'XLSX',
      displayName: 'Microsoft Excell Spreadsheet'
    },
    {
      type: 'PPT',
      displayName: 'Microsoft PowerPoint Presentation'
    },
    {
      type: 'PPTX',
      displayName: 'Microsoft PowerPoint Presentation'
    },
    {
      type: 'PDF',
      displayName: 'Portable Document Format'
    },
    {
      type: 'ODT',
      displayName: 'OpenDocument (text)'
    },
    {
      type: 'ODS',
      displayName: 'OpenDocument (spreadsheet)'
    },
    {
      type: 'ODP',
      displayName: 'OpenDocument (presentation)'
    },
    {
      type: 'RTF',
      displayName: 'Rich Text Format (text document)'
    },
    {
      type: 'JPG',
      displayName: 'Joint Photographic Experts Group'
    },
    {
      type: 'JPEG',
      displayName: 'Joint Photographic Experts Group'
    },
    {
      type: 'PNG',
      displayName: 'Portable Network Graphics'
    },
    {
      type: 'GIF',
      displayName: 'Graphics Interchange Format'
    },
    {
      type: 'TIFF',
      displayName: 'Tag Image File Format'
    },
    {
      type: 'ICO',
      displayName: 'ICO FILE'
    },
    {
      type: 'SVG',
      displayName: 'Scalable Vector Graphics'
    },
    {
      type: 'WEBP',
      displayName: 'WEB PICTURE'
    },
    {
      type: 'CSV',
      displayName: 'Comma Separated Values File'
    },
    {
      type: 'JSON',
      displayName: 'JavaScript Object Notation'
    },
    {
      type: 'HTML',
      displayName: 'Hypertext Markup Language File'
    },
    {
      type: 'ZIP',
      displayName: 'Zipped File'
    },
    {
      type: 'XML',
      displayName: 'XML Document'
    },
    {
      type: 'TS',
      displayName: 'Typescript file'
    },
    {
      type: 'CSS',
      displayName: 'Cascading Style Sheet'
    }
  ];

  static PAGINATION_LOWER_LIMIT = 25;
  static STATUS_DRAFT = 1;
  static STATUS_PUBLIC_REVIEW = 5;
  static STATUS_PUBLISHED = 3;
  static STATUS_PUBLIC_REVIEW_COPIED = 6;
  static NOTIFICATION_INTERVAL_TIME = 30; // in seconds

  static ROUTE_HOME = 'app/home';
  static ROUTE_ADMIN_TEAM = 'app/admin/team';
  static ROUTE_ADMIN_PERMISSIONS = 'app/admin/permissions';
  static ROUTE_ADMIN_WORKFLOW_LIST = 'app/admin/workflow/list';
  static ROUTE_ADMIN_WORKFLOW = 'app/admin/workflow';
  static ROUTE_ADMIN_WORKFLOW_CREATE = 'app/admin/workflow/create';
  static ROUTE_ADMIN_METADATA_LIST = 'app/admin/metadata';
  static ROUTE_ADMIN_NODETEMPLATE_LIST = 'app/admin/nodetemplate';
  static ROUTE_ADMIN_CASESERVER_LIST = 'app/admin/caseservers';

  static ROUTE_PROJECT_DETAIL = 'app/project/detail';
  static ROUTE_PROJECT_LIST = 'app/project/list';
  static ROUTE_PROJECT_AUTHORING = 'app/project/authoring';
  static ROUTE_TABLE_CONFIGURATION = 'app/project/tableconfiguration';

  static ROUTE_PACING_GUIDE_LIST = 'app/pacingguide/list';
  static ROUTE_PACING_GUIDE_AUTHORING = 'app/pacingguide/authoring';
  static ROUTE_PACING_GUIDE_DETAIL = 'app/pacingguide/detail';

  static ROUTE_PUBLIC_REVIEW_LIST = 'app/publicreview/list';
  static ROUTE_PUBLIC_REVIEW_DONE = 'app/publicreview/reviewed';
  static ROUTE_PUBLIC_REVIEW_PENDING = 'app/publicreview/review';

  static ROUTE_TAXONOMY_LIST = 'app/taxonomy/list';
  static ROUTE_TAXONOMY_DETAIL = 'app/taxonomy/detail';
  static ROUTE_TAXONOMY_CUSTOM_VIEW = 'app/taxonomy/customview';

  static ROUTE_SEARCH = 'app/search';
  static ROUTE_LOGIN = 'login';
  static ROUTE_REGISTER = 'register';

  static ROUTE_ACCEPT_INVITE = 'acceptInvite';

  static ROUTE_FRONT_FACE_TAXONOMY_DETAIL = 'taxonomies/detail';

  static ROUTE_NOTIFICATIONS = 'app/notifications';

  static ROUTE_USER_PROFILE = 'app/user/profile';

  static EXPAND_LEVEL = 1;

  /* Uploading progress status */
  static UPLOAD_NOT_STARTED = 1; // upload not started
  static UPLOAD_IN_PROGRESS = 2; // upload in progress
  static UPLOAD_FINISHED = 3; // upload finished
  static UPLOAD_ERROR = 4; // upload error

  static UPLOAD_COUNTER = 5; // User will not wait after this seconds while only taxonomy JSON file uploaded in server 100%

  static taxonomyData: any; // For tree expanding function (expandTreeTillSelectedNode())

  static DUMMY_ID_FOR_ORPHANLABEL = 'orphan_label_node_id';

  static LEFT_PANEL_MIN_SIZE = 30; // Left panel minimum size in percentage for resizable
  static LEFT_PANEL_MAX_SIZE = 70; // Left panel maximum size in percentage for resizable

  static textLength = 300; // holds the length to be set for each node's text limit to display
  static breadCrumbLength = 50; // holds the length to be set for each node's text limit to display
  static caseField = {
    creator: 'Creator',
    title: 'Title',
    official_source_url: 'Official Source Url',
    publisher: 'Publisher',
    description: 'Description',
    subject_title: 'Subject Title',
    subject_hierarchy_code: 'Subject Hierarchy Code',
    subject_description: 'Subject Description',
    language_name: 'Language',
    version: 'Version',
    status_start_date: 'Status Start Date',
    status_end_date: 'Status End Date',
    license_title: 'License Title',
    license_description: 'License Description',
    license_text: 'License Text',
    notes: 'Notes',
    full_statement: 'Full Statement',
    alternative_label: 'Alternative Label',
    human_coding_scheme: 'Human Coding Scheme',
    list_enumeration: 'List Enumeration',
    sequence_number: 'Sequence Number',
    abbreviated_statement: 'Abbreviated Statement',
    concept_title: 'Concept Title',
    concept_keywords: 'Concept Keywords',
    concept_hierarchy_code: 'Concept Hierarchy Code',
    concept_description: 'Concept Description',
    education_level: 'Education Level',
    pacing_guide_item_type: 'Pacing Guide Item Type'
  };
  static MAX_CACHE_AGE = 60000; // (in milliseconds) holds time for which api data will be stored in cache

  // User survey/demographic information
  static GRADE_LEVEL_LIST = [{
    name: 'K-5',
    id: '9f30e198-961d-4a56-91a3-58c87c8c741f',
    value: 'K to 5',
    checked: false
  },
  {
    name: '6-8',
    id: 'd629761f-ca97-4ea8-bcf4-44df06b37325',
    value: '6 to 8',
    checked: false
  },
  {
    name: '9-12',
    id: '57954137-5b29-49e8-8ca9-06c4daafb512',
    value: '9 to 12',
    checked: false
  }
  ];
  static SUBJECT_AREA_LIST = [{
    name: 'Modern',
    id: '4ece9b76-aba0-4c41-bcc8-9bc8cae42902',
    value: 'modern',
    checked: false
  },
  {
    name: 'Classics',
    id: 'f9527237-c3b3-448f-9dee-f59636ca8a6f',
    value: 'classics',
    checked: false
  }
  ];
  static LANGUAGE_LIST = [{
    label: 'Arabic',
    id: '28cb9cda-5958-4f28-b477-6554d9798c98',
    value: 'arabic',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Chinese (Mandarin)',
    id: 'ba421ce5-166b-416c-8b33-1b64ea3b0afe',
    value: 'chinese',
    is_selected: 0,
    checked: false
  },
  {
    label: 'French',
    id: '37837614-4b4f-4879-a8a0-161d6ea465e2',
    value: 'french',
    is_selected: 0,
    checked: false
  },
  {
    label: 'German',
    id: 'c4a46b8f-7996-4a5f-b50a-bb7f4cd0bdd3',
    value: 'german',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Hebrew',
    id: '1363d5df-5841-49f4-9766-82820685569f',
    value: 'hebrew',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Japanese',
    id: '7d0f4368-23f8-4dbf-a7d4-59367583c755',
    value: 'japanese',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Latin',
    id: '2116a8bc-542f-416a-8432-78a506b65f02',
    value: 'latin',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Portuguese',
    id: 'ab9a14ea-e02b-48dd-bf75-69cf995a5a4f',
    value: 'portuguese',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Russian',
    id: 'b3867218-1879-4915-8336-72708b0d3ec6',
    value: 'russian',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Spanish',
    id: 'cb1ed39f-8981-4924-94e1-b2bbd92f18be',
    value: 'spanish',
    is_selected: 0,
    checked: false
  },
  {
    label: 'Other language',
    id: 'cb1ed39f-8981-4924-94e1-b2bbd92f18de',
    value: 'other',
    is_selected: 0,
    checked: false
  }
  ];

  static navBarHeight = 0;

  static checkNumber(value) {
    return parseInt(value, 10);
  }
  /**
   * This function take taxonomy item array and re-order array item by the list_enumeration
   * @param data
   */
  static sortData1(data) {

    data.sort(function (a, b) {
      if (Utils.checkNumber(a.sequence_number) && Utils.checkNumber(b.sequence_number)) {
        return (parseInt(a.sequence_number, 10) < parseInt(b.sequence_number, 10) ? -1 : 1) * (1);
      } else {
        const val1 = a.sequence_number;
        const val2 = b.sequence_number;
        if (val1 === val2) {
          return 0;
        }
        if (val1 > val2) {
          return 1;
        }
        if (val1 < val2) {
          return -1;
        }
      }

    });

    data.forEach(element => {
      if (element.children && element.children.length > 0) {
        Utils.sortData1(element.children);
      }
    });
  }

  /**
   * This function take taxonomy item array and re-order array item by the
   * list_enumeration or human_coding_scheme or full_statement
   * @param data
   */
  static sortData(data, sortBy: string = 'human_coding_scheme') {
    if (data instanceof Array) {
      if (data.length > 0) {
        sortBy = Utils.checkSortType(data);
      }
    } else {
      if (data.children && data.children.length > 0) {
        sortBy = Utils.checkSortType(data.children);
      }
    }
    data.sort(function (a, b) {

      if (sortBy === 'sequence_number' &&
        Utils.checkNumber(a[sortBy]) && Utils.checkNumber(b[sortBy])) {
        return (parseInt(a[sortBy], 10) < parseInt(b[sortBy], 10) ? -1 : 1) * (1);
      } else {
        const valA = a[sortBy] ? a[sortBy].toUpperCase() : '';
        const valB = b[sortBy] ? b[sortBy].toUpperCase() : '';

        let comparison = 0;
        if (valA > valB) {
          comparison = 1;
        } else if (valA < valB) {
          comparison = -1;
        }
        return comparison;
      }

    });

    data.forEach(element => {
      if (element.children && element.children.length > 0) {
        sortBy = Utils.checkSortType(element.children);
        Utils.sortData(element.children, sortBy);
      }
    });
  }

  static checkSortType(children) {
    if (children[0].sequence_number && children[0].sequence_number.trim() !== ''
      && children[0].sequence_number !== 'undefined') {
      return 'sequence_number';
    } else {
      if (children[0].human_coding_scheme && children[0].human_coding_scheme.trim() !== '') {
        return 'human_coding_scheme';
      } else {
        return 'full_statement';
      }
    }
  }
  /**
   * This function check the project level permission and return {
      'valid': boolean,
      'hasProject': boolean
    }
   * @param project_id:any
   * @param permissionName:any
   */
  static checkProjectPermission(project_id, permissionName, propertyName, selectedStageId = '', keyName) {


    // const projectsPermission = JSON.parse(localStorage.getItem('project_role_permissions'));
    if (keyName === 'project_role_permissions') {
      if (!Utils.projectPermissions) {
        Utils.indexedDBServiceObj.getData('acmt', keyName).then((permission: any) => {
          Utils.projectPermissions = permission;
          return Utils.checkDataForPermission(project_id, permissionName, propertyName, selectedStageId, keyName);
        }).catch((ex) => {
          console.log('Exception in idb connection');
        });
      } else {
        return Utils.checkDataForPermission(project_id, permissionName, propertyName, selectedStageId, keyName);
      }
    } else {
      if (!Utils.individualProjectPermissions) {
        Utils.indexedDBServiceObj.getData('acmt', keyName).then((permission: any) => {
          Utils.individualProjectPermissions = permission;
          return Utils.checkDataForPermission(project_id, permissionName, propertyName, selectedStageId, keyName);
        }).catch((ex) => {
          console.log('Exception in idb connection');
        });
      } else {
        return Utils.checkDataForPermission(project_id, permissionName, propertyName, selectedStageId, keyName);
      }
    }

  }

  static checkDataForPermission(project_id, permissionName, propertyName, selectedStageId, keyName) {
    const projectsPermission = keyName === 'project_role_permissions' ? Utils.projectPermissions : Utils.individualProjectPermissions;
    let valid = false;
    let hasProjectPermission = false;
    if (projectsPermission && projectsPermission.length > 0) {
      for (let i = 0; i < projectsPermission.length; i++) {
        const data = projectsPermission[i];
        if (data.project_id === project_id) {
          hasProjectPermission = true;
          for (let j = 0; j < data.stages.length; j++) {
            const element = data.stages[j];
            if (element['stage_id'].includes(selectedStageId)) {
              if (element[propertyName] !== undefined) {
                from(element[propertyName])
                  .filter((x: any) => x.display_name === permissionName)
                  .subscribe(val => {
                    valid = true;
                  });
              }
              break;
            }
          }
        }
      }
    } else {
      // console.log('There is no project permission');
    }




    return {
      valid,
      hasProject: hasProjectPermission
    };
  }

  /**
   * Calculate tree node padding
   */
  static calculatePadding(level, treeType) {

    if (treeType === 'treeview') {
      if (level === 1) {
        return '.5rem';
      }
    }
    return (.5 + (level - 1) * 1.5) + 'rem';
  }

  static calculateNodeTemplatePadding(level, treeType) {

    if (treeType === 'treeview') {
      if (level === 1) {
        return '1rem';
      }
    }
    return (1 + (level - 1) * 1.2) + 'rem';
  }

  static calculateTaxonomyBuilderPadding(level, treeType) {

    if (level === 1) {
      return '1.25rem';
    }
    let padding = 1.78 + (level - 1);
    if (level > 2) {
      padding = padding + (level - 2) * .50;
    }
    return padding + 'rem';
  }

  /**
   * Function to convert bytes to readable format
   * @param  {} bytes
   */
  static formatBytes(bytes) {
    if (bytes < 1024) {
      return bytes + ' Bytes';
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(1) + ' KB';
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(1) + ' MB';
    } else {
      return (bytes / 1073741824).toFixed(1) + ' GB';
    }
  }
  /**
   * To accept only number
   * @param  {} evt
   */
  static isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const key = evt.which || evt.keyCode || evt.charCode;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else if (key === 13) {
      evt.preventDefault();
    }
  }

  /**
   * To navigate elements using up and down arrow key
   * @param event
   */
  static navigateElemWithUpDownKey(event) {
    const keyCode = event.keyCode;
    if (keyCode === 38) { // Up arrow key code
      if (event.target.previousElementSibling) {
        event.target.previousElementSibling.focus();
        event.preventDefault();
        event.stopPropagation();
      }
    } else if (keyCode === 40) { // Down arrow key code
      if (event.target.nextElementSibling) {
        event.target.nextElementSibling.focus();
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  /**
   * Method to sort Array of objects
   * @param  array: Array that need to be sort
   * @param  sortby: object property name
   * @param  isDate: true/false if property is date type
   * @param  isAsce: true/false sort order
   */
  static sortDataArray(array, sortby, isDate, isAsce, arrOfProps?) {
    array.sort(function (a, b) {
      let val1: any = '';
      let val2: any = '';
      if (!isDate && typeof (a[sortby]) === 'number') {
        val1 = a[sortby];
        val2 = b[sortby];
      } else {
        val1 = a[sortby] ? a[sortby].toUpperCase() : '';
        val2 = b[sortby] ? b[sortby].toUpperCase() : '';
      }
      if (isDate) {
        val1 = new Date(a[sortby].replace(/-/g, '/'));
        val2 = new Date(b[sortby].replace(/-/g, '/'));
      }
      if (val1 === val2) {
        if (arrOfProps && arrOfProps.length) {
          return Utils.compareArrayElem(a, b, sortby, true, arrOfProps);
        } else {
          return 0;
        }
      }
      if (val1 > val2) {
        return isAsce ? 1 : -1;
      }
      if (val1 < val2) {
        return isAsce ? -1 : 1;
      }
    });
  }

  static compareArrayElem(a, b, sortBy, isAsce, arrOfProps) {
    const i = arrOfProps.indexOf(sortBy);
    if ((i + 1) < arrOfProps.length) {
      const newProp = arrOfProps[i + 1];
      if (a[newProp] === b[newProp]) {
        return Utils.compareArrayElem(a, b, newProp, isAsce, arrOfProps);
      }
      return a[newProp] > b[newProp] ? (isAsce ? 1 : -1) : (isAsce ? -1 : 1);
    } else {
      return 0;
    }
  }

  static changeDateToLocalTimeZone(date) {
    let newDate: any;
    if (date === '') {
      return '-';
    }
    if (date) {
      let dateTrimed;
      if (date.length > 10) {
        dateTrimed = date.substr(0, date.length - 3);
      } else {
        dateTrimed = date;
      }
      newDate = new Date(dateTrimed.replace(/-/g, '/') + ' UTC');
    }
    return newDate ? (newDate.toString()) : '';
  }

  static getDateWithoutTime(date: string) {
    let d;
    let formatted = '';
    d = new Date(date.replace(/-/g, '/') + ' UTC');
    formatted = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
    return formatted;
  }

  /**
   * To group same value element in array
   * @param array
   * @param propName (object's property name to group)
   */
  static getFilteredArray(array: any[], propName: string) {
    let arr = [];
    let isFirst = true;
    const arr1 = [];

    for (let i = 0; i < array.length; i++) {
      if (isFirst === true) {
        arr.push(array[i]);
        isFirst = false;
      }
      if ((i + 1 < array.length) && array[i][propName] === array[i + 1][propName]) {
        arr.push(array[i + 1]);
      } else {
        isFirst = true;
        arr1.push({
          key: array[i][propName],
          value: arr
        });
        arr = [];
      }
    }
    return arr1;
  }

  static removeBodyScrollClass() {
    // document.querySelector('body').classList.remove('hide-vScroll');
  }

  static removeBodyScroll() {
    // document.querySelector('body').classList.add('overflow-hidden');
  }

  static addBodyScroll() {
    // document.querySelector('body').classList.remove('overflow-hidden');
  }

  static addBodyScrollClass() {
    this.removeBodyScrollClass();
    // document.querySelector('body').classList.add('hide-vScroll');
  }

  static changePacingGuideIds(array, association_as_parent_id) {
    array.forEach(element => {
      if (element.id) {
        const id = element.id;
        element['item_id'] = id;
        if (element['is_document'] === 1) {
          element.id = element.item_association_id ? element.item_association_id : element.id;
        } else {
          element.id = element.item_association_id;
        }
        element['new_parent_id'] = element.hierarchy_id;
        element.parent_id = association_as_parent_id ? association_as_parent_id : '';
      }
      if (element.children && element.children.length > 0) {
        // id will become parent_id for its children
        this.changePacingGuideIds(element.children, element.id);
      }
    });
  }

  static getCustomMetadataType(val) {
    const metadataType = +val;
    let type: string;
    switch (metadataType) {
      case 1:
        type = 'text';
        break;
      case 2:
        type = 'long text';
        break;
      case 3:
        type = 'list';
        break;
      case 4:
        type = 'date';
        break;
      case 5:
        type = 'time';
        break;
      case 6:
        type = 'number';
        break;
    }
    return type;

  }

  static focusOnElement(elementId) {
    if (document.getElementById(elementId)) {
      document.getElementById(elementId).focus();
    }
  }

  static removeShowClass() {
    if (document.getElementsByClassName('modal-backdrop') && document.getElementsByClassName('modal-backdrop')[0]) {
      document.getElementsByClassName('modal-backdrop')[0].classList.remove('modal-backdrop');
    }
  }

  /********************************************************/
  /** To expand node of any taxonomy tree data upto selected node
   * @param selectedNode (upto which node tree needs to be expanded)
   * @param taxonomydata (main taxonomy tree data)
   */
  static expandTreeTillSelectedNode(selectedNode: any, taxonomydata: any, propName?: any) {
    this.taxonomyData = taxonomydata;
    this.getAllParents(selectedNode, propName);
  }
  static getAllParents(node: any, propName) {
    if (node) {
      if (node.parent_id && node.is_document !== 1) {
        this.iterate(this.taxonomyData, node, propName);
      } else if (node.is_document === 1) {
        node['expand'] = true;
        if (propName) {
          node[propName] = true;
        }
      }
    }
  }
  static iterate(current: any, node: any, propName) {
    const id = node.parent_id;
    if (current && current.children) {
      const children = current.children;
      if (current.id === id) {
        current['expand'] = true;
        if (propName) {
          current[propName] = true;
        }
        this.getAllParents(current, propName);
      } else if (propName && current.id === node.id) {
        current[propName] = true;
        // this.getAllParents(current, propName);
      }
      for (let i = 0, len = children.length; i < len; i++) {
        this.iterate(children[i], node, propName);
      }
    }
  }
  /********************************************************/

  // To extract content from HTML
  static extractContentFromHtml(html: any) {
    return (new DOMParser).parseFromString(html, 'text/html').documentElement.textContent;
  }

  // Container or panel resizable from left to right
  static initResizablePanel(resizepanelId?: string, grabberId?: string) {
    const resizePanelId = resizepanelId ? resizepanelId : 'resizable-panel';
    const dragId = grabberId ? grabberId : 'grabber';
    const resizePanel = document.querySelector('#' + resizePanelId);
    const grabber = document.querySelector('#' + dragId);
    const MIN_WIDTH = resizePanel ? resizePanel.clientWidth : 200; // panel can not be resized less than minwidth (in 'px')

    let newWidth = 0;
    let isResizing = false;

    const resize = function (event: any) {
      if (!isResizing) {
        return;
      }
      newWidth = event.clientX;
      if (newWidth < MIN_WIDTH) {
        newWidth = MIN_WIDTH;
      }
      (resizePanel as HTMLElement).style.width = newWidth + 'px';
    };

    if (resizePanel && grabber) {
      const shadowDiv = resizePanel.firstElementChild;
      // mousedown listener
      grabber.addEventListener('mousedown', function (event: any) {
        shadowDiv.classList.add('drop-shadow');
        isResizing = true;
      }, false);

      // mousemove listener
      document.addEventListener('mousemove', resize, false);

      // mouseup listener
      document.addEventListener('mouseup', function (event) {
        // stop resizing
        isResizing = false;
        shadowDiv.classList.remove('drop-shadow');
      });
    }
  }

  /********************************************************/
  /** To count the occurance of a node within a tree
   * @param id (upto which node tree needs to be expanded)
   * @param taxonomydata (main taxonomy tree data)
   */
  /* update tree view after deleting a node having multiple isChildOf association */

  static delNodeOccurance(taxonomydata: any, id: any) {
    taxonomydata.forEach(node => {
      this.delOccurance(node, id);
    });
  }

  static delOccurance(node, id) {
    const idx = node.children.findIndex((selectedInx) => {
      return (selectedInx.id === id);
    });
    if (idx > -1) {
      node.children.splice(idx, 1);
    } else {
      if (node.children.length > 0) {
        node.children.forEach(child => {
          this.delOccurance(child, id);
        });
      }
    }
  }

  /********************************************************/

  /**
   * To find max list enumeration number for next node adding.
   * (This is basically used for dummy node adding so that it is displayed below tree
   * when sorting based on list inumeration)
   * @param nodes (list of nodes)
   */
  static getMaxLE(nodes: any[]) {
    const listEnumerations = [];
    for (const node of nodes) {
      listEnumerations.push(node.sequence_number);
    }
    return (Math.max(...listEnumerations) + 1);
  }

  /* Arrange orientation of association list according */

  static reArrangeArray(dataArr: any) {
    const sameTaxoArr = [];
    const diffTaxoArr = [];
    let newDataArr = [];
    dataArr.forEach(data => {
      const srcDoc = data.origin_node.document_id;
      const destDoc = data.destination_node.document.document_id;
      if (srcDoc === destDoc) {
        data['isDifferent'] = false;
        sameTaxoArr.push(data);
      } else {
        data['isDifferent'] = true;
        diffTaxoArr.push(data);
      }
    });
    newDataArr = sameTaxoArr.concat(diffTaxoArr);
    return newDataArr;
  }

  /********************************************************/

  /* Group an array by value */

  static groupByType(array, type, sortBy?) {
    const propArr = [];
    const dataArr = [];
    array.forEach(item => {
      if (item[type] && propArr.indexOf(item[type]) === -1) {
        propArr.push(item[type]);
      }
    });

    propArr.forEach(prop => {
      const obj = {};
      obj['key'] = prop;
      obj['value'] = array.filter(data => {
        return (data[type] === prop);
      });
      if (sortBy) {
        this.sortDataArray(obj['value'], sortBy, false, true);
      }
      dataArr.push(obj);
    });
    this.sortDataArray(dataArr, 'key', false, true);
    return dataArr;
  }

  /**********************************************************/

  /**
   * To update any specific property values in a array list respective of specific id
   * @param idValue (id for which object value would be changed)
   * @param list (array list)
   * @param uniqueProp (property name for id value)
   * @param updateProp (property name of which value whould be changed)
   * @param updateValue (updated value)
   */
  static updateArray(idValue: any, list: any[], uniqueProp: string, updateProp: any, updateValue: any) {
    for (const element of list) {
      if (element[uniqueProp] === idValue) {
        element[updateProp] = updateValue;
        break;
      }
    }
  }

  // To get initial letters of full name like 'JK' is inital name of 'John Kennedy'
  // If you require only to get first name's letter, then send 'letterNumber' parameter as 1
  static getInitialNames(firstName: string, lastName: string, letterNumber = 2) {
    let initName = '';
    if (firstName) {
      initName = firstName.trim()[0];
    }
    if (lastName) {
      initName = initName + lastName.trim()[0];
    }
    if (letterNumber === 1) {
      initName = initName[0];
    }
    initName = initName ? initName.toUpperCase() : initName;
    return initName;
  }

  static getFirstCapitalLetterString(str: string) {
    str = str ? (str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()) : '';
    return str;
  }

  static preloaderWidthAdjustment(val) {
    if ((document.getElementsByClassName('preloader') && document.getElementsByClassName('preloader')[0])) {
      if (val) {
        setTimeout(() => {
          if (document.getElementById('mainNav')) {
            if ((document.getElementsByClassName('preloader') && document.getElementsByClassName('preloader')[0])) {
              (<HTMLElement>document.getElementsByClassName('preloader')[0]).style.width =
                (window.innerWidth - (document.getElementById('mainNav').offsetWidth)) + 'px';
            }
          }
        }, 10);
      } else {
        (<HTMLElement>document.getElementsByClassName('preloader')[0]).style.width = (window.innerWidth) + 'px';
      }
    }
  }

  static getFileType(type, fileTypes) {
    let displayName = type + ' file';
    if (fileTypes && fileTypes.length) {
      for (let i = 0; i <= fileTypes.length; i++) {
        if (fileTypes[i] && fileTypes[i]['type'] === type) {
          displayName = fileTypes[i]['displayName'];
          break;
        }
      }
    }
    return displayName;
  }

  /** Function to get import type of taxonomy */
  static getImportType(val, stype) {
    let type;
    switch (val) {
      case 1:
        type = (stype === 1) ? 'New' : (stype === 2 || stype === 3) ? 'Keep original' : '-';
        break;
      case 2:
        type = 'Copy';
        break;
      case 4:
        type = 'Updated';
        break;
      case 3:
        type = `Updated URI`;
        break;
      default:
        type = '-';
    }
    return type;
  }

  static checkChangesForSpecificOrg(originalValue, org) {
    let obj = { renamePacingGuide: originalValue, showPublishedTaxonomy: originalValue };
    for (const i in environment.allowTenantSpecificChangesOnly) {
      if (i && org === environment.allowTenantSpecificChangesOnly[i]) {
        obj = { renamePacingGuide: true, showPublishedTaxonomy: false };
        break;
      }
    }
    console.log('obj', obj);
    return obj;
  }

  static focusOnDataTableElement() {
    if (localStorage.getItem('datatableItem') && localStorage.getItem('datatableItem').length > 0) {
      const obj = JSON.parse(localStorage.getItem('datatableItem'));
      if (document.getElementById(obj.idToClick)) {
        document.getElementById(obj.idToClick).focus();
        // if (document.getElementById(obj.idToFocus)) {
        //   document.getElementById(obj.idToFocus).focus();
        // }
        localStorage.setItem('datatableItem', '');
      }
    }
  }

  static getNavbarHeight() {
    if (document.getElementById('navBar')) {
      this.navBarHeight = document.getElementById('navBar').offsetHeight;
    }
  }

  static getCookie(name) {
    const cName = name + '=';
    const cookieObj = document.cookie.split(';');

    for (let c of cookieObj) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cName) === 0) {
        return decodeURIComponent(c.substring(cName.length, c.length));
      }
    }
    return '';
  }
}
