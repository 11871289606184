import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  HostListener
} from '@angular/core';
import {
  SharedService
} from '../shared.service';
import { GlobalSettings } from '../global.settings';
@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, OnChanges {

  currentFocusedTab; // holds tabname on which focus is set during tab or arrow press

  @Input() tabItems = [];
  @Input() counts = [];
  @Input() showCheckbox;
  @Input() enableEmail;
  @Input() currentTab;
  @Input() viewLocation;
  @Input() showVersionBtn = false;


  @Output() tabSelectedEvent: EventEmitter<any>;
  @Output() checkboxClickEvent: EventEmitter<any>;
  @Output() loadVersionSummaryEvent: EventEmitter<any>;

  constructor(private sharedService: SharedService) {
    // this.tabItems = ['Taxonomy', 'Projects', 'Activity', 'Publish'];
    this.tabSelectedEvent = new EventEmitter<any>();
    this.checkboxClickEvent = new EventEmitter<any>();
    this.loadVersionSummaryEvent = new EventEmitter<any>();
  }
  ngOnInit() {
    if (this.tabItems && this.tabItems.length > 0) {
      if (this.currentTab === undefined) {
        this.currentTab = this.tabItems[0];
      }
    }
  }

  ngOnChanges() {
    // if (this.tabItems && this.tabItems.length > 0) {
    //   // this.currentTab = this.tabItems[0];
    // }
  }

  onTabSelected(tab) {
    this.currentTab = tab;
    this.tabSelectedEvent.emit(this.currentTab.toLowerCase());
    this.sharedService.searchText.next({
      'searchText': 'clear'
    });
  }

  setOption() {
    console.log(this.enableEmail);
    this.checkboxClickEvent.next(this.enableEmail);
  }

  loadVersionReport() {
    this.loadVersionSummaryEvent.emit();
  }

  /* --------- Functionality to mimic tab and switch tab via left and right arrow start --------- */

  @HostListener('document:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    let nextElementIndex = -1;
    if (document.activeElement.getAttribute('tab-item')) {
      if (!this.currentFocusedTab) {
        this.currentFocusedTab = this.currentTab;
      }
      const maxIndex = this.tabItems.length - 1;
      const minIndex = 0;
      if (event.key === 'ArrowRight' || (!event.shiftKey && event.keyCode === 9)) {
        if (maxIndex === this.tabItems.indexOf(this.currentFocusedTab)) {
          nextElementIndex = 0;
        } else {
          nextElementIndex = this.tabItems.indexOf(this.currentFocusedTab) + 1;
        }
        this.currentFocusedTab = this.tabItems[nextElementIndex];
        document.getElementById((this.tabItems[nextElementIndex]).split(' ').join('').toLowerCase() + 'Tab').focus();
        // tslint:disable-next-line: deprecation
      } else if (event.key === 'ArrowLeft' || (event.shiftKey && event.keyCode === 9)) {
        if (minIndex === this.tabItems.indexOf(this.currentFocusedTab)) {
          nextElementIndex = maxIndex;
        } else {
          nextElementIndex = this.tabItems.indexOf(this.currentFocusedTab) - 1;
          if (nextElementIndex === -2) {
            nextElementIndex = maxIndex;
          }
        }
        this.currentFocusedTab = this.tabItems[nextElementIndex];
        document.getElementById((this.tabItems[nextElementIndex]).split(' ').join('').toLowerCase() + 'Tab').focus();
      }
    } else {
      this.currentFocusedTab = 'null';
    }
  }

  /* --------- Functionality to mimic tab and switch tab via left and right arrow start --------- */

}
