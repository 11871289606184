import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList } from '@angular/core';
import Utils from 'src/app/utils';
import { MultiSelectDropdownComponent } from '../multi-select-dropdown/multi-select-dropdown.component';
import { SharedService } from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
@Component({
  selector: 'app-tree-search',
  templateUrl: './tree-search.component.html',
  styleUrls: ['./tree-search.component.scss']
})
export class TreeSearchComponent implements OnInit, OnChanges {
  @Input() filterCriteriaList; // holds filter criteria list to be populated in dropdown
  @Input() treeData; // holds tree data
  @Output() searchEvent: EventEmitter<any>; // holds search event value
  @Output() loaderEvent: EventEmitter<any>; // holds search event value
  @ViewChildren('filterCriteria') filterCriteriaDropdown: QueryList<MultiSelectDropdownComponent>;

  Object = Object;
  treeNodes: any;
  searchText = ''; // holds text input in search box
  openPanel = false; // holds flag value to toggle filter criteria drop-down
  filterListObj = {}; // holds formatted filter object with values
  selectedFilterList = []; // holds selected values to filter tree
  filterResult = []; // holds filtered tree nodes
  isSearchApplied = false; // holds flag if any filter or search is applied on tree data
  dropdownSettings = {
    textField: 'label',
    showLabel: true,
    buttonType: 'button'
  };
  filterObj = [];
  worker: any;
  searchResultList = [];
  arrayToSearch = [];
  disableFilter = true;
  isLoading = false;
  searchTextEvent: Subscription; // holds subscription for search
  searchEnabled = false; // holds boolean to determine if search is enabled
  isFirstTime = false;
  @Input() intializeCondition = false;
  @Input() showFilter = true;
  @Input() location = '';
  constructor(private sharedService: SharedService) {
    this.searchEvent = new EventEmitter<any>();
    this.loaderEvent = new EventEmitter<any>();

    this.searchTextEvent = this.sharedService.searchTextEvent.subscribe((res: any) => {
      setTimeout(() => {
        if (!res.trigger && this.treeData && this.treeData !== 'undefined' && this.treeData.length) {
          this.isLoading = true;
          this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: true });
          if (typeof Worker !== 'undefined') {
            this.worker = new Worker('../../treeService.worker', { type: 'module' });
          }
          if (this.worker) {
            this.worker.onmessage = ({ data }) => {

              if (data) {
                this.treeData = data.treeData;
                this.treeNodes = data.treeNodes;
                this.arrayToSearch = data.arrayToSearch;
                this.filterObj = data.filterObj;
                this.filterResult = data.filterResult;
                this.searchText = data.searchText;
                setTimeout(() => {
                  this.isLoading = false;
                  this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: false });
                }, 500);
                this.searchEvent.emit({ filterData: this.searchResultList, isFilterApplied: this.isSearchApplied, treeData: this.treeData });
              }
            };
            if (!res.trigger && this.treeData && this.treeData !== 'undefined' && this.treeData.length) {
              console.log('Constructor worker');
              this.worker.postMessage({
                data: this.treeData,
                location: 'tree-search',
                parameters: {
                  filterCriteriaList: this.filterCriteriaList,
                  search: false,
                  searchText: this.searchText,
                  isReset: true,
                  clearMarkNode: true,
                  filterResult: this.filterResult,
                  filterObj: this.filterObj,
                }
              });
            }
          }
        } else {
          this.searchEnabled = false;
        }
      }, 500);

    });

  }

  ngOnInit() { }

  ngOnChanges() {
    // if (this.treeData) {
    //   if (this.treeData instanceof Array) {
    //     this.treeData.forEach(tree => {
    //       this.getFilterObj(tree.children);
    //     });
    //     this.treeNodes = this.treeData;
    //     this.arrayToSearch = this.treeData;
    //   } else if (this.treeData instanceof Object) {
    //     this.getFilterObj(this.treeData.children);
    //     this.treeNodes = this.treeData.children;
    //     this.arrayToSearch = this.treeData.children;
    //   }
    //   console.log(this.filterListObj);
    //   if (!this.isSearchApplied) {
    //     this.clearMarkNode(this.treeNodes);
    //   }
    // }
  }

  getFilterData(treeData, isFirstTime = false) {
    if (treeData) {
      this.isFirstTime = isFirstTime;
      this.isLoading = true;
      this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: this.isFirstTime });
      if (typeof Worker !== 'undefined') {
        this.worker = new Worker('../../treeService.worker', { type: 'module' });
      }
      if (this.worker) {
        if (this.filterCriteriaList && this.filterCriteriaList.length === 0) {
          if (localStorage.getItem('filterCriteriaList')) {
            this.filterCriteriaList = JSON.parse(localStorage.getItem('filterCriteriaList'));
          }
        }
        this.worker.onmessage = ({ data }) => {
          if (data) {
            this.treeData = data.treeData;
            this.treeNodes = data.treeNodes;
            this.arrayToSearch = data.arrayToSearch;
            this.searchResultList = data.searchResultList;
            this.searchText = data.searchText;
            this.filterListObj = data.filterListObj;
            this.filterObj = data.filterObj;
            this.filterResult = data.filterResult;
            setTimeout(() => {
              this.isLoading = false;
              this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: this.isFirstTime });
              this.isFirstTime = false;
            }, 500);
          }
        };
        console.log('getFilterData worker');
        this.worker.postMessage({
          data: treeData,
          location: 'tree-search',
          parameters: {
            filterCriteriaList: this.filterCriteriaList,
            search: false,
            searchResultList: this.searchResultList,
            selectedFilterList: this.selectedFilterList,
            filterObj: this.filterObj,
            filterResult: this.filterResult,
            searchText: this.searchText,
            showFilter: this.showFilter
          }
        });
      }
    }
  }

  // FUNCTION to emit search-box text to parent component
  emitSearchText(isReset) {
    this.isSearchApplied = ((this.searchText && this.searchText.length) || this.selectedFilterList.length) ? true : false;
    this.isLoading = true;
    this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: false });
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        if (data) {
          this.treeData = data.treeData;
          this.treeNodes = data.treeNodes;
          this.searchResultList = data.searchResultList;
          this.searchText = data.searchText;
          this.filterObj = data.filterObj;
          this.filterResult = data.filterResult;
          this.searchText = data.searchText;
          if (this.searchResultList && this.searchResultList.length) {
            this.arrayToSearch = this.searchResultList;
          } else {
            this.arrayToSearch = data.arrayToSearch;
          }
          if (isReset) {
            this.resetFilter(true);
          } else {
            this.searchEvent.emit({ filterData: this.searchResultList, isFilterApplied: this.isSearchApplied, treeData: this.treeData });
          }
          setTimeout(() => {
            this.isLoading = false;
            this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: false });
          }, 500);
        }
      };
      console.log('emitSearchText worker');
      this.worker.postMessage({
        data: this.treeData,
        location: 'tree-search',
        parameters: {
          filterCriteriaList: this.filterCriteriaList,
          search: true,
          searchText: this.searchText,
          isReset,
          selectedFilterList: this.selectedFilterList,
          filterResult: this.filterResult,
          filterObj: this.filterObj,
          clearMarkNode: isReset
        }
      });
    }




    // this.searchNode(this.searchText, isReset);

  }

  // FUNCTION to search user input text in tree data
  // searchNode(text, isReset: boolean = false) {
  //   this.searchResultList = [];
  //   this.searchText = text;
  //   if (this.searchText.length) {
  //     if (this.filterResult && this.filterResult.length) {
  //       this.arrayToSearch = this.filterResult;
  //     }
  //     this.getSearchResult(this.arrayToSearch, text);
  //   } else {
  //     if (this.selectedFilterList && this.selectedFilterList.length && !isReset) {
  //       this.searchResultList = this.filterResult;
  //     } else {
  //       this.searchResultList = [];
  //       this.arrayToSearch = this.treeNodes;

  //     }
  //   }
  //   console.log(this.searchResultList);
  // }

  // getSearchResult(treeData: any, text) {
  //   treeData.forEach(element => {
  //     if (element.level) {
  //       element.isSearchResult = false;
  //       if (element.full_statement.toLowerCase().includes(text.toLowerCase()) || element.human_coding_scheme.toLowerCase().includes(text.toLowerCase())) {
  //         if (!this.searchResultList.find(data => data.id === element.id)) {
  //           element.isSearchResult = true;
  //           element.isHighlighted = true;
  //           this.searchResultList.push(element);
  //         }
  //       }
  //     }
  //     if (element.children && element.children.length) {
  //       this.getSearchResult(element.children, text);
  //     }
  //   });

  // }

  // FUNCTION to clear search box
  clearSearchKeyword(isReset: boolean = false) {
    this.searchText = '';
    this.emitSearchText(isReset);

    // setTimeout(() => {
    //   this.isLoading = true;
    //   this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: true });
    //   if (typeof Worker !== 'undefined') {
    //     this.worker = new Worker('../../treeService.worker', { type: 'module' });
    //   }
    //   if (this.worker) {
    //     this.worker.onmessage = ({ data }) => {

    //       if (data) {
    //         this.treeData = data.treeData;
    //         this.treeNodes = data.treeNodes;
    //         this.arrayToSearch = data.arrayToSearch;
    //         this.filterObj = data.filterObj;
    //         this.filterResult = data.filterResult;
    //         this.searchText = data.searchText;
    //         setTimeout(() => {
    //           this.isLoading = false;
    //           this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: false });
    //         }, 500);
    //         this.searchEvent.emit({ filterData: this.searchResultList, isFilterApplied: this.isSearchApplied, treeData: this.treeData });
    //       }
    //     };
    //     this.worker.postMessage({
    //       data: this.treeData,
    //       location: 'tree-search',
    //       parameters: {
    //         filterCriteriaList: this.filterCriteriaList,
    //         search: false,
    //         searchText: this.searchText,
    //         isReset,
    //         clearMarkNode: true,
    //         filterResult: this.filterResult,
    //         filterObj: this.filterObj
    //       }
    //     });
    //   }
    // }, 500);


    // this.clearMarkNode(this.treeNodes);
  }


  // FUNCTION to extract filter object from tree data
  // getFilterObj(dataSet) {
  //   if (this.filterCriteriaList) {
  //     this.filterCriteriaList.forEach(col => {
  //       const property = col.hasOwnProperty('propName') ? 'propName' : 'internal_name';
  //       const propStr = col[property].toLowerCase().replace(/ /gi, '_');
  //       if (propStr !== 'human_coding_scheme' && propStr !== 'full_statement') {
  //         dataSet.forEach(data => {
  //           const element = data[propStr];
  //           if ((element || element === 0)) {
  //             if (!this.filterListObj[propStr]) {
  //               this.filterListObj[propStr] = [{
  //                 colName: propStr,
  //                 label: element.trim(),
  //                 is_selected: 0
  //               }];
  //             } else {
  //               if (!this.filterListObj[propStr].find((obj) => element.trim() === (obj.label))) {
  //                 this.filterListObj[propStr].push({
  //                   colName: propStr,
  //                   label: element.trim(),
  //                   is_selected: 0
  //                 });
  //               }
  //             }
  //           }
  //           if (data.children && data.children.length) {
  //             this.getFilterObj(data.children);
  //           }
  //         });
  //       }
  //     });
  //   }

  // }

  // FUNCTION to toggle panel containing filter criteria drop-downs
  toggleFilterPanel(value) {
    this.openPanel = value;
  }

  // FUNCTION to receive individual filter drop-down values
  getSelectedFilters(ev) {
    this.disableFilter = false;
    // add selected options to filter list
    ev.selectedData.forEach(option => {
      if (!this.selectedFilterList.find((obj) => obj.label.trim() === option.label.trim())) {
        this.selectedFilterList.push(option);
      }
    });
    // remove unselected options from the filter list
    this.selectedFilterList = this.selectedFilterList.filter(obj => obj.is_selected === 1);
  }

  // FUNCTION to apply all the filters to tree data
  applyFilter(isReset: boolean = false) {
    this.filterResult = [];
    // this.clearMarkNode(this.arrayToSearch);
    this.isSearchApplied = ((this.selectedFilterList && this.selectedFilterList.length) || this.searchText.length) ? true : false;
    if (!isReset && !this.isSearchApplied) {
      this.resetSearchFilter();
      return;
    }
    if (this.selectedFilterList.length) {
      if (this.searchText && this.searchText.length) {
        this.arrayToSearch = this.searchResultList;
        this.callGetFilterResult(isReset, this.arrayToSearch);
        // this.getFilterResult(this.arrayToSearch);
      } else {
        if (this.treeData instanceof Array) {
          this.treeData.forEach(tree => {
            this.callGetFilterResult(isReset, tree.children);
            // this.getFilterResult(tree.children);
          });
        } else if (this.treeData instanceof Object) {
          this.callGetFilterResult(isReset, this.treeData.children);
          // this.getFilterResult(this.treeData.children);
        }
      }
    } else {
      this.filterObj = [];
      if (this.searchResultList && this.searchResultList.length && !isReset) {
        this.filterResult = this.searchResultList;
      } else {
        this.filterResult = [];
        this.arrayToSearch = this.treeNodes;
      }
      if (this.filterResult && this.filterResult.length) {
        this.arrayToSearch = this.filterResult;
      }
      this.callGetFilterResult(isReset, this.arrayToSearch);
      if (!isReset) {
        this.searchEvent.emit({ filterData: this.filterResult, isFilterApplied: this.isSearchApplied, treeData: this.treeData });
      }
      this.toggleFilterPanel(false);
    }

  }

  callGetFilterResult(isReset, dataForGetFilterResult) {
    this.isLoading = true;
    this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: false });
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        if (data) {
          this.treeData = data.treeData;
          this.treeNodes = data.treeNodes;
          this.arrayToSearch = data.arrayToSearch;
          this.filterResult = data.filterResult;
          this.searchText = data.searchText;
          this.filterObj = data.filterObj;
          setTimeout(() => {
            this.isLoading = false;
            this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: false });
          }, 500);
        }
        if (this.filterResult && this.filterResult.length) {
          this.arrayToSearch = this.filterResult;
        }
        if (!isReset) {
          this.searchEvent.emit({ filterData: this.filterResult, isFilterApplied: this.isSearchApplied, treeData: this.treeData });
        }

      };
      console.log('callGetFilterResult worker');
      this.worker.postMessage({
        data: this.treeData, location: 'tree-search', parameters:
        {
          filterCriteriaList: this.filterCriteriaList,
          search: false,
          searchText: this.searchText,
          isReset,
          selectedFilterList: this.selectedFilterList,
          getFilterResult: true,
          dataForGetFilterResult,
          searchResultList: this.searchResultList,
          filterResult: this.filterResult,
          filterObj: this.filterObj
        }
      });
    }
    this.toggleFilterPanel(false);
  }

  // FUNCTION to parse tree data with selected filters
  // getFilterResult(treeData: any) {
  //   let dataSet = treeData;
  //   const searchResult = [];
  //   this.filterObj = Utils.groupByType(this.selectedFilterList, 'colName');

  //   for (const obj of this.filterObj) {
  //     dataSet.forEach(data => {
  //       data.isSearchResult = false;
  //       if (obj.value.find(filter => data[obj.key] === filter.label)) {
  //         if (searchResult.indexOf(data) < 0 && this.filterResult.indexOf(data) < 0) {
  //           data.isSearchResult = true;
  //           data.isHighlighted = true;
  //           searchResult.push(data);
  //         }
  //       } else {
  //         const idx = searchResult.indexOf(data);
  //         data.isSearchResult = false;
  //         data.isHighlighted = false;
  //         if (idx > -1) {
  //           searchResult.splice(idx, 1);
  //         }
  //       }

  //       if (data.children && data.children.length) {
  //         this.getFilterResult(data.children);
  //       }
  //     });
  //     dataSet = searchResult;
  //   }

  //   this.filterResult = this.filterResult.concat(searchResult);

  //   console.log('##### FILTER ====>', this.filterResult);

  // }

  onClickOutside() {
    if (this.openPanel) {
      this.toggleFilterPanel(false);
    }
  }

  // FUNCTION to reset filter
  resetFilter(isReset) {
    const filters = this.filterCriteriaDropdown.toArray();
    for (const criteria of filters) {
      criteria.clearAllSelect();
      criteria.addSelection(true);
    }
    if (this.selectedFilterList.length) {
      this.selectedFilterList = [];
    }
    this.applyFilter(isReset);
    // this.clearMarkNode(this.treeNodes);
    this.isLoading = true;
    this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: true });
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        if (data) {
          this.treeData = data.treeData;
          this.treeNodes = data.treeNodes;
          this.arrayToSearch = data.arrayToSearch;
          this.filterObj = data.filterObj;
          this.filterResult = data.filterResult;
          this.searchText = data.searchText;
          setTimeout(() => {
            this.isLoading = false;
            this.loaderEvent.emit({ isLoading: this.isLoading, isFirstTime: false });
          }, 1000);
          this.searchEvent.emit({ filterData: this.filterResult, isFilterApplied: this.isSearchApplied, treeData: this.treeData });
        }
      };
      console.log('resetFilter worker');
      this.worker.postMessage({
        data: this.treeData,
        location: 'tree-search',
        parameters: {
          filterCriteriaList: this.filterCriteriaList,
          search: false,
          searchText: this.searchText,
          searchResultList: this.searchResultList,
          isReset: true,
          clearMarkNode: true,
          selectedFilterList: this.selectedFilterList,
          filterResult: this.filterResult,
          filterObj: this.filterObj
        }
      });
    }
  }

  resetSearchFilter() {
    this.clearSearchKeyword(true);
    // setTimeout(() => {
    //   this.resetFilter(true);
    // }, 1000);
  }

  // FUNCTION to check if search box is empty
  checkSearchText() {
    if (this.searchText.length === 0 && this.isSearchApplied) {
      this.cancelSearch();
    }
  }

  // clearMarkNode(treeArr) {
  //   if (treeArr && treeArr.length) {
  //     treeArr.forEach(node => {
  //       if (this.searchResultList.indexOf(node) === -1 && this.filterResult.indexOf(node) === -1) {
  //         node.isHighlighted = false;
  //         node.isSearchResult = false;
  //       } else {
  //         node.isHighlighted = true;
  //       }

  //       if (node.children && node.children.length) {
  //         this.clearMarkNode(node.children);
  //       }
  //     });

  //   }

  // }

  cancelSearch() {
    if (!this.selectedFilterList.length) {
      this.resetSearchFilter();
    } else {
      this.clearSearchKeyword();
      if (document.getElementById('searchTaxonomies')) {
        document.getElementById('searchTaxonomies').focus();
      }
    }
  }

  clearFilter() {
    if (!this.searchText.length) {
      this.resetSearchFilter();
    } else {
      this.resetFilter(false);
    }
  }

}
