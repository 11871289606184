import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  GlobalSettings
} from '../../../global.settings';
import {
  CommonService
} from '../../../common.service';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  TreeDataService
} from '../../../tree-data.service';
import {
  SharedService
} from '../../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  ConfirmationDialogService
} from '../../../confirmation-dialog/confirmation-dialog.service';
import Utils from '../../../utils';
import {
  AutoCompleteComponent
} from '../../../common/auto-complete/auto-complete.component';
@Component({
  selector: 'app-association',
  templateUrl: './association.component.html',
  styleUrls: ['./association.component.scss']
})
export class AssociationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() associationNode;
  @Input() firstItemNode: any;
  @Input() lastNode: any;
  @Input() nodeLoading: boolean;
  @Input() originDocumentId;
  @Input() associationData;
  @Input() projectId;
  @Input() showNavigation = false;
  @Output() associationCreateEvent: EventEmitter<any>;
  @Output() nodeNavigationClickEvent: EventEmitter<any>;
  @Output() assModalClosed: EventEmitter<any>;


  @ViewChild('autoCompleteAsso', { static: false }) autoCompleteAsso: AutoCompleteComponent;
  @ViewChild('autoCompleteTaxo', { static: false }) autoCompleteTaxo: AutoCompleteComponent;

  taxonomyList: any;
  taxonomyData: any;
  associationTypes: any;
  associationList: any;
  selectedTaxonomyId: any;
  selectedTaxonomy = null;
  selectedAssociationType = null;
  selectedTreeNode: any = null;
  description: any = null;
  previousNodeId: any = '';
  form: FormGroup;
  form_association_type: FormControl;
  form_taxonomy: FormControl;
  form_description: FormControl;
  isOpen = false;
  nodeSelectedEvent: Subscription;
  listEnding = {
    title: 'Getting added at the moment'
  };
  showText = false;
  formValid = false;
  selectType = 'single';
  uniqueId = 'document_id';
  autoCompleteTitle = 'Select Taxonomy';
  dataloaded = false;
  constructor(private service: CommonService, private treeDataService: TreeDataService, private sharedService: SharedService,
    private dialogService: ConfirmationDialogService, ) {
    this.associationCreateEvent = new EventEmitter<any>();
    this.nodeNavigationClickEvent = new EventEmitter<any>();
    this.assModalClosed = new EventEmitter<any>();
    this.nodeSelectedEvent = this.sharedService.treeNodeSelectedEvent.subscribe((item: any) => {
      console.log('associatin item -------', item);
      if (item && item.id && item.location === 'taxonomydetail') {
        this.selectedTreeNode = item;
      }
      this.formValidation();
    });

    /*this.form = new FormGroup({
      taxonomy: new FormControl(null)
    });*/
    this.associationList = [];
  }


  ngOnInit() {
    this.getTaxonomies();
    this.getAssociationType();
    this.form = new FormGroup({
      form_association_type: new FormControl(),
      form_taxonomy: new FormControl(),
      form_description: new FormControl()
    });
  }

  ngOnChanges() {
    console.log(' associationNode ', this.associationNode);
    if (this.associationData.length) {
      this.associationList = JSON.parse(JSON.stringify(this.associationData));
      this.previousNodeId = this.associationNode.id;
    } else {
      this.associationList = [];
    }
    console.log(' originDocumentId ', this.originDocumentId);
    setTimeout(() => {
      assetPanelHeight();
    }, 300);
  }


  getTaxonomies() {
    if (localStorage.getItem('access_token')) {
      const url = GlobalSettings.TAXONOMY_LIST + '?document_list_type=1&document_list_type=2';
      this.taxonomyList = [];
      this.service.getServiceData(url).then((res: any) => {
        // res.forEach(element => {
        //   this.taxonomyList.push(element);
        // });

        res.forEach(element => {
          if (element.status !== 3) {
            this.taxonomyList.push(element);
          }
        });


        // this.autoComplete.openPanel(true);
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }

  getAssociationType() {
    if (localStorage.getItem('access_token')) {
      const url = GlobalSettings.GET_CASE_ASSOCIATION_TYPES;
      this.associationTypes = [];
      this.service.getServiceData(url).then((res: any) => {
        res.forEach(element => {
          if (element.type_name !== 'exemplar') {
            this.associationTypes.push(element);
          }
        });
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }

  updateSelectedObject(event) {
    this.selectedTaxonomy = event;
    this.onTaxonomySelected();
  }
  onTaxonomySelected() {
    this.dataloaded = false;
    const id = (this.selectedTaxonomy && this.selectedTaxonomy.document_id) ? this.selectedTaxonomy.document_id : null;
    this.taxonomyData = null;
    this.selectedTreeNode = null;
    this.selectedTaxonomyId = id;
    if (id) {
      this.showText = true;
      const newUrl = GlobalSettings.GET_TREE_VIEW_ENHANCED + id;
      this.taxonomyData = this.treeDataService.getTreeData(newUrl, true).then((res: any) => {
        // Utils.sortData(res.parsedTreeNodes.children);
        this.dataloaded = true;
        this.taxonomyData = res.parsedTreeNodes;
        console.log('onTaxonomySelected  ', this.taxonomyData);
      }).catch((ex) => {
        console.log('Error ', ex);
      });
    } else {
      if (this.selectedAssociationType && !this.selectedAssociationType.type_id) {
        this.showText = false;
      }
    }
    this.formValidation();

  }

  onTypeSelected(event) {
    if (this.selectedAssociationType && this.selectedAssociationType.type_id) {
      this.showText = true;
    } else {
      if (!this.selectedTaxonomyId) {
        this.showText = false;
      }
    }
    // this.selectedAssociationType = event.target.value;
    // console.log('onTypeSelected ', event.target.value);
    this.formValidation();
  }

  onCreateAssociation() {
    console.log('onCreateAssociation ', this.form.invalid);
    console.log('Description added', this.description);
    if (this.selectedTaxonomyId && this.selectedAssociationType && this.selectedAssociationType.type_id && this.selectedTreeNode) {
      console.log('selectedTaxonomyId ', this.selectedTaxonomyId);
      console.log('selectedAssociationType ', this.selectedAssociationType);
      console.log('selectedTreeNode ', this.selectedTreeNode);
      const id = this.associationNode.item_id ? this.associationNode.item_id : this.associationNode['id'];
      const url = GlobalSettings.CREATE_CFITEM_CASE_ASSOCIATION;
      // const body = {
      //   'origin_node_id': id,
      //   'destination_node_ids': this.selectedTreeNode.id,
      //   'association_type': this.selectedAssociationType.type_id,
      //   'description': this.description,
      //   'project_type': 1,
      //   'project_id': this.projectId
      // };

      const body = {
        origin_node_id: id,
        destination_node_ids: [this.selectedTreeNode.id],
        project_type: 1,
        association_type: this.selectedAssociationType.type_id,
        project_id: this.projectId,
        description: this.description
      };


      this.service.postService(url, body).then((res: any) => {
        console.log(' onCreateAssociation ', res);
        const len = this.associationList.length;

        body['title'] = this.selectedTreeNode.human_coding_scheme + ' ' + this.selectedTreeNode.full_statement;
        this.showText = false;
        if (res && res.length > 0) {
          this.associationList = res.concat(this.associationList);
        }
        this.sharedService.sucessEvent.next({
          type: 'created_association'
        });
        this.description = '';
        // this.clearView();
        if (document.getElementById(id + '-editnode')) {
          document.getElementById(id + '-editnode').click();
        }
        // this.onCancel();
        this.associationCreateEvent.emit({ selectedAssociationType: this.selectedAssociationType });
      }).catch((ex: any) => {
        console.log('onCreateAssociation  ', ex);
      });
    }
  }
  onCancel() {
    this.isOpen = false;
    this.form.reset();
    this.clearView();
    this.showText = false;
    this.associationCreateEvent.emit({});
    if (this.autoCompleteTaxo) {
      this.autoCompleteTaxo.clearSelection();
    }
    if (this.autoCompleteAsso) {
      this.autoCompleteAsso.clearSelection();
    }
    // this.associationList = [];
  }
  onDone() {
    this.onCancel();
  }

  removeBackdrop(){
    setTimeout(() => {
      Utils.removeShowClass();
    }, 200);
  }

  clearView() {
    this.selectedTaxonomyId = null;
    this.selectedTaxonomy = null;
    this.selectedAssociationType = null;
    this.selectedTreeNode = null;
    this.taxonomyData = null;
    this.description = '';
    this.formValidation();
  }

  ngOnDestroy() {
    if (this.nodeSelectedEvent) {
      this.nodeSelectedEvent.unsubscribe();
    }
  }

  /* --------- Functionality to delete assotiation start --------- */

  deleteAssociation(item) {
    this.dialogService.confirm('Confirm', 'Do you want to delete the association?')
      .then((confirmed) => {
        if (confirmed) {
          // console.log(' deleteAssociation ', item);
          if (item.item_association_id) {
            const url = GlobalSettings.DELETE_ITEM_ASSOCIATION + '/' + item.item_association_id;
            this.service.deleteServiceData(url).then((res) => {
              this.sharedService.sucessEvent.next({
                type: 'delete_association'
              });
              this.associationList = this.associationList.filter(obj => {
                return JSON.stringify(obj) !== JSON.stringify(item);
              });
            }).catch((ex) => {
              console.log('deleteAssociation ', ex);
              this.sharedService.sucessEvent.next({
                type: 'not_delete_child_association'
              });
            });
          }
        } else {
          console.log('User cancel the dialog');
        }
      })
      .catch(() => {
        console.log('User dismissed the dialog');
      });

  }

  /* --------- Functionality to delete assotiation end --------- */


  /* --------- On excape key press function start --------- */
  onMouseCall(event) {
    if (event.keyCode === 27) {
      this.onCancel();
    }
  }
  /* --------- On excape key press function end --------- */

  formValidation() {
    if (this.selectedTaxonomy && this.selectedAssociationType
      && this.selectedAssociationType.type_id && this.selectedTreeNode) {
      this.formValid = true;
    } else {
      this.formValid = false;
    }
  }

  onClickedOutside(e: Event, type: string) {
    if (type === 'autoCompleteTaxo') {
      this.autoCompleteTaxo.openPanel(false);
    } else if (type === 'autoCompleteAsso') {
      this.autoCompleteAsso.openPanel(false);
    }
  }

  onTreeNavigatePreviousClick() {
    this.nodeNavigationClickEvent.emit({ 'navigation': 'previous' });
  }

  onTreeNavigateNextClick() {
    this.nodeNavigationClickEvent.emit({ 'navigation': 'next' });
  }

  closeModal() {
    this.assModalClosed.emit(true);
  }

}
