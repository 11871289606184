import {
  Component, OnInit, Input, ViewChild, Output,
  EventEmitter,
} from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import Utils from '../../utils';
import { Router } from '@angular/router';
import { SortListComponent } from '../sort-list/sort-list.component';
import { SharedService } from '../../shared.service';

const DRAFT_TAXONOMY = 'Draft';
const PUBLIC_REVIEW_TAXONOMY = 'Public Review';
const PUBLISHED_TAXONOMY = 'Published';

@Component({
  selector: 'app-browse-taxonomy-list',
  templateUrl: './browse-taxonomy-list.component.html'
})
export class BrowseTaxonomyListComponent implements OnInit {

  @Output() setStep: EventEmitter<any>; // holds the event which includes info for the step to toggle view on association modal

  step = 1; // holds the step for create association modal

  listOfColumn = [];
  optionList = [];

  sortOptions = [{
    type: 'title',
    isDate: false,
    isAsce: true,
    display: 'Name (A-Z)'
  }, {
    type: 'title',
    isDate: false,
    isAsce: false,
    display: 'Name (Z-A)'
  }, {
    type: 'created_at',
    isDate: true,
    isAsce: true,
    display: 'Active Since (Ascending)'
  }, {
    type: 'created_at',
    isDate: true,
    isAsce: false,
    display: 'Active Since (Descending)'
  }, {
    type: 'associated_projects_count',
    isDate: false,
    isAsce: true,
    display: 'Project Count (Low - High)'
  }, {
    type: 'associated_projects_count',
    isDate: false,
    isAsce: false,
    display: 'Project Count (High - Low)'
  }];
  filteredTaxonomyList;
  taxonomyList;
  searchText: boolean;
  taxonomyType;
  hasPermission = false;
  defaultColumnList;
  currentColumnList;
  taxonomyTypeList = [];

  @Input() taxonomyEditButton = false;
  @Input() taxonomyDeleteButton = false;
  @Input() showProtip = false;
  @ViewChild('sortListRef', { static: false }) sortListRef: SortListComponent;

  constructor(private service: CommonService, private router: Router, private sharedService: SharedService) {
    this.setStep = new EventEmitter<any>();

    this.sharedService.taxonomyColumnListEvent.subscribe((obj: any) => {
      if (obj.defaultColumnList) {
        this.hasPermission = obj.hasPermission;
        if (this.hasPermission) {
          this.listOfColumn = obj.columnList;
        } else {
          this.listOfColumn = obj.defaultColumnList;
        }
      } else {
        this.listOfColumn = [];
      }
    });
  }

  ngOnInit() {
    if (!this.listOfColumn.length) {
      this.getTableConfig();
    }
    this.listTaxonomies();

  }

  listTaxonomies() {
    this.getDictionaryMetadata();
  }


  callTaxonomyListApi() {
    this.searchText = true;
    const url = GlobalSettings.TAXONOMY_LIST;
    this.service.getServiceData(url).then((res: any) => {
      this.taxonomyList = [];
      if (res && res.length > 0) {
        res.forEach(element => {
          if (element.status !== Utils.STATUS_PUBLIC_REVIEW_COPIED) {
            this.taxonomyList.push(element);
          }
        });
        this.taxonomyList.forEach(item => {
          item.taxonomy_type = this.getTypeNameByValue(item.document_type);
          item.current_status = item.status === 5 ? PUBLIC_REVIEW_TAXONOMY :
            (item.status === 3 ? PUBLISHED_TAXONOMY : DRAFT_TAXONOMY);
          item.isEditable = item.import_type === 1 ? false : this.taxonomyEditButton;
          item.isDelete = this.taxonomyDeleteButton;
          item.showOptions = item.status === 3 ? false : (!item.isEditable && !item.isDelete) ? false : true;
          item.imported_as = Utils.getImportType(item.actual_import_type, item.source_type_id);
          item.showCreator = true;
          item.showDetail = true;
          item.isAccessible = true;
          item.groupNames = [];
          if (item.groups && item.groups.length) { // taxonomy groups
            item.groups.forEach((grp: any) => {
              item.groupNames.push(grp.group_name);
            });
          }
          item.groupNames = item.groupNames.toString().replace(/,/gi, ', '); // replacing ',' with ', '
          item.groupNames = item.groupNames ? item.groupNames : '-'; // - means empty
        });

        if (this.taxonomyList.length > 0) {
          this.searchText = false;
          this.showProtip = false;
        } else {
          this.searchText = true;
          this.showProtip = true;
        }
        // this.sortListRef.sortDefalut();
        if (this.taxonomyType) { // 'taxonomyType' holds value based on routing param
          const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_LIST;
          this.router.navigate([path]);
        } else {
          setTimeout(() => {
            if (this.sortListRef) {
              this.sortListRef.sortDefalut('Active Since (Desc)');
            }
          }, 75);
        }
        // this.userRoleRight();
      } else if (res && res.length === 0) {
        this.filteredTaxonomyList = res;
      }
    }).catch(ex => {
      console.log('list of taxonomies ex ', ex);
    });
  }

  onTaxonomySelected(event) {
    this.step = 2;
    this.setStep.emit({ step: this.step, selectedTaxonomy: event });
  }

  getTableConfig() {
    this.listOfColumn = [];
    const featureId = 1;
    const settingId = 1;
    const url = GlobalSettings.TABLE_CONFIG + '/' + featureId + '/' + settingId;

    this.service.getServiceData(url, true, true).then((res: any) => {
      console.log('DP', res);
      this.defaultColumnList = res.data.super_default_json;
      this.currentColumnList = res.data.json_config_value;
      if (this.currentColumnList.taxonomy_list_setting.length) {
        this.constructColumnListData(this.currentColumnList.taxonomy_list_setting, false);
        this.listOfColumn = this.currentColumnList.taxonomy_list_setting;
      } else {
        this.constructColumnListData(this.defaultColumnList.taxonomy_list_setting, true);
        this.listOfColumn = this.defaultColumnList.taxonomy_list_setting;
      }
    }).catch((ex) => {

    });
  }

  constructColumnListData(columnList: any, setWidth: boolean) {
    let defaultColWidth;

    if (setWidth) {
      const tableWidth = document.getElementById('dataTable').offsetWidth;
      const optionColWidth = 0;
      const dataColWidth = tableWidth - optionColWidth;
      const noOfCol = columnList.length;
      defaultColWidth = (dataColWidth / noOfCol);
    }


    columnList.forEach(col => {
      col.name = col.display_name;
      if (setWidth) {
        col.width = Math.round(defaultColWidth);
      }

      switch (col.display_name) {
        case 'Name':
          col.propName = 'title';
          col.class = '';
          col.type = 'link';
          col.redirect = true;
          col.loadPage = true;
          col.canRemove = false;
          break;
        case 'Type':
          col.propName = 'taxonomy_type';
          col.class = ' px-1';
          col.type = 'text';
          col.canRemove = false;
          col.canFilter = true;
          break;
        case 'Status':
          col.propName = 'current_status';
          col.class = ' px-1';
          col.type = 'text';
          col.canRemove = false;
          col.canFilter = true;
          break;
        case 'Projects':
          col.propName = 'associated_projects_count';
          col.class = ' px-1';
          col.type = 'text';
          col.canRemove = false;
          break;
        case 'Last Change':
          col.propName = 'last_active_date';
          col.class = ' px-1';
          col.type = 'date';
          col.dateType = 'amCalendar';
          col.canRemove = false;
          break;
        case 'Active since':
          col.propName = 'created_at';
          col.class = ' px-1';
          col.type = 'date';
          col.dateType = 'amCalendar';
          col.canRemove = false;
          break;
        case 'Import type':
          col.propName = 'imported_as';
          col.class = ' px-1';
          col.type = 'text';
          col.canFilter = true;
          col.canRemove = false;
          break;
        case 'Imported by':
          col.propName = 'imported_by';
          col.class = ' px-1 created-by';
          col.type = 'text';
          col.canFilter = true;
          col.canRemove = false;
          break;
        case 'Group':
          col.propName = 'groupNames';
          col.class = ' px-1 group';
          col.type = 'text';
          col.canRemove = true;
          col.canFilter = true;
          break;
        default:
      }
    });
  }

  // To fetch 'Dictionary' type metadata's field values list
  // After fetching taxonomy types, call project list api to set taxonomy type for each taxonomy
  getDictionaryMetadata() {
    if (localStorage.getItem('access_token')) {
      this.taxonomyTypeList = [];
      // For getting any metadata's field values with internal name, we have to send any random id as per backend logic
      const url = GlobalSettings.GET_METADATA_LIST + '/random_id' + '?internal_name=Taxonomy Type';
      this.service.getServiceData(url).then((res: any) => {
        if (res && res.length > 0) {
          this.taxonomyTypeList = res;
        }
        this.callTaxonomyListApi();
      }).catch((ex) => {
        console.log('Error caught while fetching dictionary type metadata', ex);
      });
    }
  }

  // To get field type name of Taxonomy type against value
  getTypeNameByValue(value: any) {
    if (this.taxonomyTypeList.length) {
      const index = this.taxonomyTypeList.findIndex(element => {
        return element.key === value;
      });
      if (index > -1) { // If key value found
        return this.taxonomyTypeList[index].value;
      } else { // If not found
        return '';
      }
    } else {
      return '';
    }
  }
}
