import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import {
  Subscription
} from 'rxjs/Subscription';
import { SharedService } from '../../../shared.service';
import Utils from '../../../utils';
import { SelectAssociationComponent } from '../../select-association/select-association.component';
@Component({
  selector: 'app-acmt-search-list',
  templateUrl: './acmt-search-list.component.html',
  styleUrls: ['./acmt-search-list.component.scss']
})
export class AcmtSearchListComponent implements OnInit {
  searchResultEvent: Subscription;
  searchResult = []; // holds search result
  searchTrigger = false; // holds boolean if search triggered and result received
  minItemPerPage; // holds min items to display per page
  startIndex = 0; // holds start index for displayed item in list used for pagination
  lastIndex = Utils.PAGINATION_LOWER_LIMIT; // holds last index for displayed item in list used for pagination
  @Output() resetSearchEvent = new EventEmitter(); // emit filter object
  @Output() updatedAssociationsObj: EventEmitter<any>; // holds the all selected node ids
  @Output() removeOptions: EventEmitter<any>; // holds event to remove options to copy
  @Output() settingsModalOpen: EventEmitter<any>; // holds boolean if settings modal is opened
  @Input() isCopy = false; // holds additional options to append or append with child nodes
  @Input() isCopyAssociation = false; // holds additional options to append or append with child nodes
  @Input() optionsToCopySaved = false; // holds flag save is clicked on modal for options to append or append with child nodes
  @Input() displayText = '';
  @Input() displayOption = '';
  @Output() setCurrentStep: EventEmitter<any>; // holds the event which includes info for the step to toggle view on association modal
  @Input() currentTab;
  @ViewChild('selectAssociation', { static: false }) selectAssociation: SelectAssociationComponent;
  backToSearch: Subscription; // holds subscription to load search list again on click of back to search on select ass comp
  loadSearchResult = true; // holds value from subscription to load search list again on click of back to search on select ass comp
  disableDocument = false; // holds boolean to disable document for association type other than ischildof

  selectedAssociationType = null; // holds selected association from drop down
  requiredFullHierarchyFlag = null; // holds requiredFullHierarchyFlag to identify if append or append with childs is selected
  selectedNodeIds = []; // holds array of node ids selected from tree, for creating associations
  constructor(private sharedService: SharedService) {
    this.updatedAssociationsObj = new EventEmitter<any>();
    this.setCurrentStep = new EventEmitter<any>();
    this.removeOptions = new EventEmitter<any>();
    this.settingsModalOpen = new EventEmitter<any>();
    this.minItemPerPage = Utils.PAGINATION_LOWER_LIMIT;
  }

  ngOnInit() {
    this.searchResultEvent = this.sharedService.searchResultEvent.subscribe((item: any) => {
      if (item) {
        this.disableDocument = false;
        this.loadSearchResult = true;
        this.searchResult = item.searchData;
        this.selectedNodeIds = [];
      }
    });

    this.backToSearch = this.sharedService.backToSearch.subscribe((item: any) => {
      if (item && item.loadSearchResult) {
        this.disableDocument = false;
        this.loadSearchResult = item.loadSearchResult;
        this.selectedNodeIds = [];

        this.searchResult.forEach(element => {
          const id = 'custom-check-' + element.unique_node_id;
          if (document.getElementById(id) && element.isChecked) {
            document.getElementById(id).click();
          }
        });
      }
    });
  }

  /* ---------Functionality to capture event to update pagination index start --------- */

  showDataInRange(event) {
    // console.log('showDataInRange ', event);
    this.startIndex = event.start - 1;
    this.lastIndex = event.last;
  }

  /* ---------Functionality to capture event to update pagination index end --------- */

  resetSearch() {
    this.disableDocument = false;
    this.resetSearchEvent.emit(true);
  }

  updatedSelection(id, isChecked) {
    if (isChecked) {
      this.selectedNodeIds.push(id);
    } else {
      this.selectedNodeIds = this.selectedNodeIds.filter(node => {
        return node !== id;
      });

    }
    this.emitSelectedNode();
  }

  emitSelectedNode() {
    this.updatedAssociationsObj.emit({
      node: this.selectedNodeIds,
      associationType: this.selectedAssociationType,
      requiredFullHierarchyFlag: this.requiredFullHierarchyFlag,
      documentId: ''
    });
  }

  /* --------- Functionality to capture and emit event to remove or reset copy options start --------- */

  removeCopiedOptions(evt) {
    this.removeOptions.emit(evt);
  }

  /* --------- Functionality to capture and emit event to remove or reset copy options end --------- */


  /* --------- Functionality to capture and emit selected association type start --------- */

  updateAssociationsObj(evt) {
    if (evt.associationType) {
      this.selectedAssociationType = evt.associationType;
    } else {
      this.selectedAssociationType = null;
    }
    this.deselectDocument(this.selectedAssociationType)
    this.requiredFullHierarchyFlag = evt.requiredFullHierarchyFlag;
    this.updatedAssociationsObj.emit({
      node: this.selectedNodeIds,
      associationType: this.selectedAssociationType,
      requiredFullHierarchyFlag: this.requiredFullHierarchyFlag,
      documentId: ''
    });
  }

  /* --------- Functionality to capture and emit selected association type end --------- */

  /* --------- Functionality called on open of association append settings modal start --------- */

  selectOptions(event) {
    this.settingsModalOpen.emit(true);
  }

  /* --------- Functionality called on open of association append settings modal end --------- */


  /* --------- Functionality on click of search result to load selected association in project authoring start --------- */

  navigateToTaxonomy(item) {
    if (document.getElementById('removeAppendChild')) {
      document.getElementById('removeAppendChild').click();
    }
    this.setCurrentStep.emit({ step: 2, selectedTaxonomy: item });
    this.loadSearchResult = false;
    this.sharedService.backToSearch.next({
      back: true
    });
    this.selectAssociation.clearAssociations();
  }

  /* --------- Functionality on click of search result to load selected association in project authoring end --------- */


  /* --------- Functionality to deselect and disable document result from list for association type other than isChildOf start --------- */

  deselectDocument(selectedAssociationType) {
    if (selectedAssociationType && selectedAssociationType.type_name === 'isChildOf' || selectedAssociationType === null) {
      this.disableDocument = false;
    } else {
      if (this.searchResult) {
        this.disableDocument = true;
        this.searchResult.forEach(element => {
          if (element.isChecked && (document.getElementById('custom-check-' + element.unique_node_id)) && element['type'][0] === 'document') {
            document.getElementById('custom-check-' + element.unique_node_id).click();
          }
        });
      }
    }
  }

  /* --------- Functionality to deselect and disable document result from list for association type other than isChildOf end --------- */

}

