import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import Utils from '../../utils';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  noOfItemsOptionList;
  @Input() dataLength = 0;
  @Input() defaultPageRange: any;
  @Output() displayItemRangeEvent: EventEmitter<any>;
  @Output() maxPageCountEvent: EventEmitter<any>;
  initialPaginamtionRange = {
    start: 0,
    last: Utils.PAGINATION_LOWER_LIMIT,
    itemPerPage: Utils.PAGINATION_LOWER_LIMIT,
    pageIndex: 1
  };
  itemNumber;
  pageIndex;
  noOfItemPerPage = Utils.PAGINATION_LOWER_LIMIT;
  maxPage = 0;
  startIndex;
  lastIndex;
  constructor() {
    this.noOfItemsOptionList = [{
      value: 10,
      isSelected: false
    },
    {
      value: 15,
      isSelected: false
    },
    {
      value: 20,
      isSelected: false
    },
    {
      value: 25,
      isSelected: false
    },
    {
      value: 50,
      isSelected: false
    },
    {
      value: 100,
      isSelected: false
    }
    ];

    this.displayItemRangeEvent = new EventEmitter<any>();
    this.maxPageCountEvent = new EventEmitter<any>();

  }

  ngOnInit() {

    this.noOfItemsOptionList.forEach(item => {
      item.isSelected = false;
      if (this.defaultPageRange.itemPerPage === item.value) {
        this.itemNumber = item;
        this.itemNumber.isSelected = true;
      }
    });
    this.calculateMaxPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.defaultPageRange === undefined) {
      this.defaultPageRange = this.initialPaginamtionRange;
    }
    this.startIndex = this.defaultPageRange.start;
    this.lastIndex = this.defaultPageRange.last;
    this.noOfItemPerPage = this.defaultPageRange.itemPerPage;
    this.pageIndex = this.defaultPageRange.pageIndex;
    this.calculateMaxPage();
    this.updatePagination(this.dataLength);
  }
  showItems(resetPageIndex?: boolean) {
    this.noOfItemsOptionList.forEach(item => {
      item.isSelected = false;
    });
    if (this.itemNumber) {
      this.itemNumber.isSelected = true;
      this.noOfItemPerPage = this.itemNumber.value;
    }
    if (resetPageIndex) {
      this.pageIndex = 1;
    }
    this.calculateMaxPage();
    // this.pageIndex = Math.ceil(this.dataLength / this.itemNumber.value);
    // console.log(this.pageIndex, this.maxPage, this.itemNumber);
    this.getStartIndex();
    this.getLastIndex();
    this.displayItemRangeEvent.emit({
      start: this.startIndex,
      last: this.lastIndex,
      itemPerPage: this.noOfItemPerPage,
      pageIndex: this.pageIndex,
      maxPages: this.maxPage
    });
  }

  onNextClicked() {
    if (this.pageIndex !== this.maxPage) {
      this.pageIndex++;
      this.getStartIndex();
      this.getLastIndex();
      // console.log('  ', this.startIndex, this.lastIndex);
      this.displayItemRangeEvent.emit({
        start: this.startIndex,
        last: this.lastIndex,
        itemPerPage: this.noOfItemPerPage,
        pageIndex: this.pageIndex,
        maxPages: this.maxPage
      });
    }

  }

  onPreviousClicked() {
    if (this.pageIndex !== 1) {
      this.pageIndex--;
      this.getStartIndex();
      this.getLastIndex();
      // console.log('  ', this.startIndex, this.lastIndex);
      this.displayItemRangeEvent.emit({
        start: this.startIndex,
        last: this.lastIndex,
        itemPerPage: this.noOfItemPerPage,
        pageIndex: this.pageIndex,
        maxPages: this.maxPage
      });
    }

  }

  calculateMaxPage() {
    this.maxPage = parseInt((this.dataLength / this.noOfItemPerPage) + '', 10) + ((this.dataLength % this.noOfItemPerPage) > 0 ? 1 : 0);
    this.maxPageCountEvent.emit(this.maxPage);
    console.log('calculateMaxPage', this.maxPage);
  }

  getStartIndex() {
    if (this.dataLength > this.noOfItemPerPage) {
      return this.startIndex = this.pageIndex === 1 ? 1 : (this.noOfItemPerPage * (this.pageIndex - 1)) + 1;
    } else {
      return this.startIndex = 1;
    }
  }

  getLastIndex() {
    this.lastIndex = ((this.noOfItemPerPage * (this.pageIndex - 1)) + this.noOfItemPerPage);
    if (this.lastIndex > this.dataLength) {
      this.lastIndex = this.dataLength;
    }
    return this.lastIndex;
  }

  updatePagination(dataLength) {
    if (dataLength <= this.noOfItemPerPage) {
      this.pageIndex = 1;
    }
    this.showItems();

  }
}
