import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import {
  CommonService
} from '../../common.service';
@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss', '../auto-complete/auto-complete.component.scss']
})
export class AdvanceSearchComponent implements OnInit {
  selectedArrayObj = [];
  toggle = false;
  search; // hold metadata text which needs to be searched
  targetElementId = ''; // holds the target element id in case of delete of selected items
  constructor(private service: CommonService) { }
  autoCompleteTitle = '';
  selectType = 'single';
  @Input() listingObj; // holds the complete data passed to component for populating dropdown
  @Input() uniqueId; // holds the unique id name in the object
  @Input() advanceOptionalFilter; // holds the filters selected
  @Input() appendMargin; // holds boolean value whether to add left-90 to panel or not
  filteredResult = [];
  searchListReult; // holds object after searched the term
  // 'adoption_status', 'item_association_type', 'document_metadata', 'item_metadata', 'custom_metadata', 'workflow_stage', 'project'
  valid = false; // holds validation for + button
  dropdownKeys = [];
  inputForFilter = [];
  filterCriteria = {}; // holds all the filters objects applied
  @Output() advanceFilterObject = new EventEmitter(); // emit filter object
  dropdownObject = {}; // holds the entire dropdown object
  getValueList = false;
  searchFilterValue = false;
  selectedFilterKeyObj = {}; // holds the key selected from advance search
  ngOnInit() {
    if (this.appendMargin) {
      console.log('appendMargin', this.appendMargin);
      document.getElementById('append-left-margin').setAttribute('style', 'position: absolute; width: 320px; top:-15px; left: -90px;');
    } else {
      console.log('appendMargin else ', this.appendMargin);
      this.onAddmoreclickfocus();
    }
    // console.log('listingObj', this.listingObj);
  }

  /* --------- Functionality for searching the object from the list start --------- */
  searchListingObject() {
    if (this.search === undefined || this.search.length === 0) {
      this.searchListReult = this.listingObj;
    } else {
      this.searchListReult = this.listingObj.filter((element) => {
        if ((element.name.toLowerCase()).includes(this.search.toLowerCase())) {
          return element;
        }
      });
      if (this.searchListReult.length === 0) {
        this.searchListReult.push({ name: 'No Record Found', [this.uniqueId]: null, is_selected: null });
      }
    }
  }
  /* --------- Functionality for searching the object from the list end --------- */

  /* --------- Functionality to toggle between open and close the dropdown functionality start --------- */
  openPanel(event, val, operation) {
    // console.log(event, val, operation);
    this.onAddmoreclickfocus();
    event.stopPropagation();
    if (val === true) {
      if ((<HTMLElement>document.querySelector('#auto-complete__list_adv')).style.display !== 'block') {
        (<HTMLElement>document.querySelector('#auto-complete__list_adv')).style.display = 'block';
        this.searchListingObject();
      } else {
        (<HTMLElement>document.querySelector('#auto-complete__list_adv')).style.display = 'none';
        this.toggle = false;
        this.search = '';
      }
    } else {
      (<HTMLElement>document.querySelector('#auto-complete__list_adv')).style.display = 'none';
      this.toggle = false;
      this.search = '';
    }
    if (this.targetElementId.length > 0) {
      (<HTMLElement>document.querySelector('#auto-complete__list_adv')).style.display = 'block';
      this.searchListingObject();
    }
    if (operation === 'delete') {
      (<HTMLElement>document.querySelector('#auto-complete__list_adv')).style.display = 'block';
      this.searchListingObject();

    }
  }
  /* --------- Functionality to toggle between open and close the dropdown functionality end --------- */

  /* --------- Functionality to emit the selected object from the list start --------- */
  selectObject(selectedObject) {
    // console.log('selectObject', JSON.stringify(selectedObject));
    if (selectedObject.is_selected !== 1 && selectedObject[this.uniqueId] !== null) {
      if (this.selectType === 'multi') {
        selectedObject.is_selected = 1;
        let flag = false;
        if (this.selectedArrayObj.length > 0) {
          this.selectedArrayObj.forEach(element => {
            if (JSON.stringify(element) === JSON.stringify(selectedObject)) {
              flag = true;
            }
          });
          if (flag === false && selectedObject[this.uniqueId] !== null) {
            this.selectedArrayObj.push(selectedObject);
          }
        } else {
          if (selectedObject[this.uniqueId] !== null) {
            this.selectedArrayObj.push(selectedObject);
          }
        }
      } else {
        if (this.getValueList === false) {
          this.searchListReult.forEach(element => {
            if (JSON.stringify(selectedObject) === JSON.stringify(element)) {
              selectedObject.is_selected = 1;
            }
            if (this.selectedArrayObj.length > 0 && JSON.stringify(element) === JSON.stringify(this.selectedArrayObj[0])) {
              element.is_selected = 0;
            }
          });
          this.selectedArrayObj = [];
          if (selectedObject[this.uniqueId] !== null && selectedObject.is_selected === 1) {
            this.selectedArrayObj.push(selectedObject);
          }
        } else {
          this.inputForFilter.forEach(element => {
            if (JSON.stringify(selectedObject) === JSON.stringify(element)) {
              selectedObject.is_selected = 1;
            }
            if (this.selectedArrayObj.length > 0 && JSON.stringify(element) === JSON.stringify(this.selectedArrayObj[0])) {
              element.is_selected = 0;
            }
          });
          this.selectedArrayObj = [];
          if (selectedObject[this.uniqueId] !== null && selectedObject.is_selected === 1) {
            this.selectedArrayObj.push(selectedObject);
          }
        }

      }
      this.checkValidation();
    }
  }
  /* --------- Functionality to emit the selected object from the list end --------- */

  /* --------- Validation functionality for + button start --------- */

  checkValidation() {
    if (this.selectedArrayObj.length > 0) {
      this.valid = true;
    } else {
      this.valid = false;
    }
    // console.log('this.valid', this.valid, this.selectedArrayObj)
  }

  /* --------- Validation functionality for + button end --------- */


  /* --------- Functionality on deleting selected items start --------- */

  deleteObj(data, evt) {
    this.targetElementId = evt.target.id;
    data.is_selected = 0;
    this.selectedArrayObj = this.selectedArrayObj.filter(obj => {
      return data[this.uniqueId] !== obj[this.uniqueId];
    });
    if (this.selectedArrayObj.length === 0) {
      this.targetElementId = '';
    }
    // this.emitSelectedObject();
    this.checkValidation();
    if (data['type'] !== 'value') {
      this.getValueList = false;
    }
  }

  /* --------- Functionality on deleting selected items end --------- */

  getValue() {
    this.inputForFilter = [];
    this.dropdownKeys.push(this.selectedArrayObj[0]['name']);
    if (this.selectedArrayObj[0]['name'] === 'adoption status') {
      this.fillAdoptionStatus();
    }
    if (this.selectedArrayObj[0]['name'] === 'item association type') {
      this.getAssociationType();
    }
    if (this.selectedArrayObj[0]['name'] === 'document metadata') {
      this.getAllMetadata('document metadata');
    }
    if (this.selectedArrayObj[0]['name'] === 'item metadata') {
      this.getAllMetadata('item metadata');
    }
    if (this.selectedArrayObj[0]['name'] === 'custom metadata') {
      this.getAllMetadata('custom metadata');
    }
    if (this.selectedArrayObj[0]['name'] === 'workflow stage') {
      this.getWorkFlowDetails();
    }
    if (this.selectedArrayObj[0]['name'] === 'project') {
      this.getProjects();
    }
  }

  /* --------- Functionality on click of + button start --------- */

  addObj() {
    if (this.selectedArrayObj[0]['type'] === 'key') {
      this.selectedFilterKeyObj = this.selectedArrayObj[0];
      this.getValueList = true;
      this.getValue();
      this.selectedArrayObj = [];
      this.checkValidation();
    } else {
      this.searchFilterValue = false;
      this.generateFilterCriteria(this.dropdownObject);
    }
    this.onAddmoreclickfocus();

    // this.emitSelectedObject();
    // (<HTMLElement>document.querySelector('.auto-complete__list_adv')).style.display = 'none';
    // this.toggle = false;
    this.search = '';
    this.selectedArrayObj = [];
  }

  /* --------- Functionality on click of + button end --------- */



  fillAdoptionStatus() {
    this.inputForFilter.push({
      filter_type: 'adoption_status',
      filter_value: 2,
      label: 'draft',
      is_selected: 0,
      filter_key: '',
      element: 'adoption status',
      type: 'value'
    }, {
      filter_type: 'adoption_status',
      filter_value: 3,
      label: 'adopted',
      is_selected: 0,
      filter_key: '',
      element: 'adoption status',
      type: 'value'
    }, {
      filter_type: 'adoption_status',
      filter_value: 4,
      label: 'deprecated',
      is_selected: 0,
      filter_key: '',
      element: 'adoption status',
      type: 'value'
    });


    this.checkSelected();

    this.createStaticDropdownObj(this.inputForFilter);
  }

  checkSelected() {
    if (this.advanceOptionalFilter && JSON.stringify(this.advanceOptionalFilter) !== JSON.stringify({})) {
      Object.keys(this.advanceOptionalFilter).forEach(key => {
        if (key === this.inputForFilter[0]['element']) {
          this.advanceOptionalFilter[key].forEach(selectedItem => {
            this.inputForFilter.forEach(filterItems => {
              if ((selectedItem['filter_type'] === 'item_metadata') || (selectedItem['filter_type'] === 'document_metadata')
                || (selectedItem['filter_type'] === 'item_custom_metadata')) {
                if (JSON.stringify(selectedItem['filter_key']) === JSON.stringify(filterItems['filter_key'])) {
                  filterItems['is_selected'] = 1;
                }
              } else {
                if (JSON.stringify(selectedItem['filter_value']) === JSON.stringify(filterItems['filter_value'])) {
                  filterItems['is_selected'] = 1;
                }
              }
            });
          });
        }
      });
    }
  }

  getAssociationType() {
    if (localStorage.getItem('access_token')) {
      const url = GlobalSettings.GET_CASE_ASSOCIATION_TYPES;
      this.service.getServiceData(url).then((res: any) => {
        res.forEach(element => {
          if (element.type_name !== 'exemplar') {
            this.inputForFilter.push({
              filter_type: 'item_association_type',
              filter_value: element.type_id,
              label: element.type_name,
              is_selected: 0,
              filter_key: '',
              element: 'item association type',
              type: 'value'
            });
          }
        });
        this.checkSelected();
        this.createStaticDropdownObj(this.inputForFilter);
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }


  /* --------- Fetch metadata List functionality Started --------- */

  getAllMetadata(filter) {
    const url = GlobalSettings.GET_METADATA_LIST;
    // const url = './assets/json/getAllMetadata.json';
    this.service.getServiceData(url).then((res: any) => {
      res.metadata.forEach(element => {
        if (filter === 'document metadata') {
          if (element.is_document === 1 || element.is_document === 2 || element.is_custom === 1) {
            this.inputForFilter.push({
              filter_type: 'document_metadata',
              filter_value: '',
              label: element.name,
              is_selected: 0,
              filter_key: element.metadata_id,
              element: 'document metadata',
              type: 'value'
            });
          }
        }
        if (filter === 'item metadata') {
          if (element.is_document === 0 || element.is_document === 2) {
            this.inputForFilter.push({
              filter_type: 'item_metadata',
              filter_value: '',
              label: element.name,
              is_selected: 0,
              filter_key: element.metadata_id,
              element: 'item metadata',
              type: 'value'
            });
          }
        }
        if (element.is_custom === 1) {
          if (filter === 'custom metadata') {
            this.inputForFilter.push({
              filter_type: 'item_custom_metadata',
              filter_value: '',
              label: element.name,
              is_selected: 0,
              filter_key: element.metadata_id,
              element: 'custom metadata',
              type: 'value'
            });
          }
        }
      });
      this.checkSelected();
      this.createStaticDropdownObj(this.inputForFilter);
    }).catch((ex) => {
      console.log('Error caught while fetching metadata list', ex);
    });
  }

  /* --------- Fetch metadata List functionality Ended --------- */


  /* --------- Functionality to fetch all workflow start --------- */
  getWorkFlowDetails() {
    const url = GlobalSettings.GET_WORKFLOW;
    this.service.getServiceData(url).then((res: any) => {
      res.WorkflowList.workflows.forEach(element => {
        this.inputForFilter.push({
          filter_type: 'project_workflow_id',
          filter_value: element.workflow_id,
          label: element.name,
          is_selected: 0,
          filter_key: '',
          element: 'workflow stage',
          type: 'value'
        });
      });
      this.checkSelected();
      this.createStaticDropdownObj(this.inputForFilter);
    }).catch((ex) => {

    });

  }
  /* --------- Functionality to fetch all workflow start --------- */

  /* --------- Functionality to fetch all projects start --------- */
  getProjects() {
    const url = GlobalSettings.PROJECT_LIST + '?project_type=1';
    this.service.getServiceData(url).then((res: any) => {
      res['projects'].forEach(element => {
        this.inputForFilter.push({
          filter_type: 'project_id',
          filter_value: element.project_id,
          label: element.project_name,
          is_selected: 0,
          filter_key: '',
          element: 'project',
          type: 'value'
        });
      });
      this.checkSelected();
      this.createStaticDropdownObj(this.inputForFilter);
    }).catch(ex => {
      console.log('list of taxonomies ex ', ex);
    });
  }

  /* --------- Functionality to fetch all projects end --------- */

  /* ---------Functionality to generate filter criteria functionality Start --------- */

  generateFilterCriteria(data) {
    // console.log('data', JSON.stringify(data));
    let filterCriteria = [];
    this.filterCriteria = {};
    Object.keys(data).forEach(element => {
      filterCriteria = data[element].filter(item => {
        if (item.is_selected === 1) {
          return item;
        }
      });
      if (filterCriteria.length > 0) {
        this.filterCriteria[element] = filterCriteria;
      }
    });

    // console.log('filetr', JSON.stringify(filterCriteria));
    Object.keys(this.advanceOptionalFilter).forEach(advOptKey => {
      this.advanceOptionalFilter[advOptKey].forEach(advItem => {
        for (const i in filterCriteria) {
          if (JSON.stringify(advItem) === JSON.stringify(filterCriteria[i])) {
            filterCriteria.splice(Number(i), 1);

          } else {
            console.log('filterCriteria[i]', filterCriteria[i], 'advItem', advItem);
          }
        }
      });



    });

    this.advanceFilterObject.emit(this.filterCriteria);
    // console.log('data filterCriteria', JSON.stringify(this.filterCriteria));
    this.searchListReult.forEach(element => {
      element.is_selected = 0;
    });
    this.getValueList = false;

  }

  /* ---------Functionality to generate filter criteria functionality End --------- */


  /* ---------Generate dropdown object functionality Start --------- */

  createStaticDropdownObj(listingData) {
    this.dropdownKeys.forEach(key => {
      this.dropdownObject[key] = [];
      listingData.forEach(obj => {
        if (obj['element'] === key) {
          this.dropdownObject[key].push(obj);
        }
      });
    });
    // console.log('this.dropdownObject', JSON.stringify(this.dropdownObject));
  }

  /* ---------Generate dropdown object functionality End --------- */


  /* --------- Functionality for searching the object from the list start --------- */
  searchItems() {
    if (!this.getValueList) {
      if (this.search === undefined || this.search.length === 0) {
        this.searchListReult = this.listingObj;
      } else {
        this.searchListReult = this.listingObj.filter((element) => {
          if ((element.name.toLowerCase()).includes(this.search.toLowerCase())) {
            return element;
          }
        });
        if (this.searchListReult.length === 0) {
          this.searchListReult.push({ name: 'No Record Found', is_selected: 1 });
        }
      }
    } else {
      this.filteredResult = [];
      if (this.search === undefined || this.search.length === 0) {
        this.filteredResult = this.inputForFilter;
      } else {
        this.filteredResult = this.inputForFilter.filter((element) => {
          if ((element.label.toLowerCase()).includes(this.search.toLowerCase())) {
            return element;
          }
        });
        if (this.filteredResult.length === 0) {
          this.filteredResult.push({ label: 'No Record Found', is_selected: 1 });
        }
      }
    }
  }
  /* --------- Functionality for searching the object from the list end --------- */
  onAddmoreclickfocus(){
    setTimeout(() => {
      if(document.getElementById('metadataSetDropdown')){
        document.getElementById('metadataSetDropdown').focus();
      }
    }, 100);
  }
}
