import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Inject, ViewChild, HostListener } from '@angular/core';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { SharedService } from '../../shared.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import Utils from 'src/app/utils';
import { ConfirmationModalComponent } from './confirmation-modal.component';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges {

  @Input() modalId; // modal's unique id
  @Input() modalContentId;
  @Input() modalSize = 'small'; // (values: big/small)
  @Input() showCancelBtn = true; // cancel button will be show or not in modal footer
  @Input() showDoneBtn = false;
  @Input() showCloseAlert = false; // to check whether alert will be shown or not
  @Input() closeAlertMsg = 'You have unsaved changes. Are you sure you want to leave this page?'; // alert message
  @Input() showErrorMsg = false;
  @Input() showFooter = true;
  @Input() showHelp = true;
  @Input() elementToFocus = ''; // to add a focus on source element

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() mouseLeaveEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('confirmationModalComponent', {
    static: false
  }) confirmationModalComponent: ConfirmationModalComponent;


  preventRequired = true; // for prevent event of modal is required or not
  alertActive = false; // to check whether close alert is opened or not
  emitCount = 0;
  faqUrls: any;
  faqTitle = '';
  lastElement;
  firstElement;
  constructor(
    private dialogService: ConfirmationDialogService,
    private sharedService: SharedService,
    private http: HttpClient,
    @Inject('Window') private window: Window
  ) {

    this.getJSON().subscribe(data => {
      this.faqUrls = data;
    });
    this.sharedService.faqEvent.subscribe((event: any) => {
      if (event && event.name) {
        this.faqTitle = event.name;
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setPreventRequired();
  }

  /**
   * On cancel modal
   */
  onCancel(event) {
    if (this.showCloseAlert && this.preventRequired) { // If close alert is required
      event.stopPropagation();
      event.preventDefault();
      if (!this.alertActive) {
        this.alertActive = true;
        this.alertForClose(event); // show alert to close
      }
    } else if (this.showErrorMsg) {
      event.stopPropagation();
      event.preventDefault();
      this.showErrorAlert();
    } else { // If close alert is not required, directly close modal without alert
      this.closeModal(event);
      this.setPreventRequired();
      this.emitCount = 0;
    }
  }

  /**
   * On escape key over modal
   * @param event
   */
  onModalKeydown(event) {
    if (event.keyCode === 27) { // '27' for Escape button
      event.stopPropagation();
      event.preventDefault();
      this.onCancel(event);
    }
  }

  onMouseLeave(event) {
    this.mouseLeaveEvent.emit(event);
  }

  /**
   * Confirmation alert showing for close modal
   */
  alertForClose(event) {
    console.log('event', event);
    this.preventRequired = true;
    if (document.getElementById('confirmationCustomDialogButton')) {
      document.getElementById('confirmationCustomDialogButton').click();
    }
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.setData('Confirm', this.closeAlertMsg, false);
    }
    setTimeout(() => {
      if (this.confirmationModalComponent) {
        this.confirmationModalComponent.setFocus();
      }
    }, 1000);
    // this.dialogService.confirm('Confirm', this.closeAlertMsg, false)
    //   .then((confirmed) => {
    //     this.alertActive = false;
    //     if (confirmed) { // If yes, close modal
    //       this.preventRequired = false;
    //       this.closeModal(event);
    //     }
    //   })
    //   .catch(() => {
    //     console.log('User dismissed the dialog');
    //   });
  }
  captureConfirmationEvent(event) {
    this.alertActive = false;
    if (event) { // If yes, close modal
      this.preventRequired = false;
      if (document.getElementById('close' + this.modalId)) {
        document.getElementById('close' + this.modalId).click();
      }
    } else {
      setTimeout(() => {
        if (document.getElementById('close' + this.modalId)) {
          document.getElementById('close' + this.modalId).focus();
        }
      }, 500);
    }
  }

  // Closing modal by click event
  closeModal(event) {
    if (this.emitCount < 1) {
      this.closeEvent.emit({ 'event': event, 'msg': 'Closed' });
      this.emitCount = this.emitCount + 1;
    }
    // if (document.getElementById('close' + this.modalId)) {
    //   document.getElementById('close' + this.modalId).click();
    // }
    setTimeout(() => {
      // this focus will be applied in individual component where data table is applied
      if (localStorage.getItem('datatableItem') && localStorage.getItem('datatableItem').length > 0) {
        const obj = JSON.parse(localStorage.getItem('datatableItem'));
        if (event.keyCode === 27) {
          if (document.getElementById('close' + this.modalId)) {
            document.getElementById('close' + this.modalId).click();
          }
        } else {
          Utils.focusOnDataTableElement();
        }
      } else {
        if (event.keyCode === 27) {
          if (document.getElementById('close' + this.modalId)) {
            document.getElementById('close' + this.modalId).click();
          }
        }
        if (this.elementToFocus && this.elementToFocus.length > 0 && document.getElementById(this.elementToFocus)) {
          document.getElementById(this.elementToFocus).focus();
        } else {
          if (document.querySelectorAll('[data-target="#' + this.modalId + '"]')[0]) {
            (document.querySelectorAll('[data-target="#' + this.modalId + '"]')[0] as HTMLElement).focus();
          }
        }
      }
    }, 500);
  }

  /**
   * Setting prevent event required or not
   */
  setPreventRequired() {
    if (this.showCloseAlert) {
      this.preventRequired = true;
    } else {
      this.preventRequired = false;
    }
  }

  showErrorAlert() {
    this.sharedService.sucessEvent.next({
      type: 'unsaved_exempler_data'
    });
  }

  onHelpClick() {
    const base_url = 'https://api.' + this.window.location.hostname + '/faq/';
    let fAqUrL = '';
    if (this.faqUrls) {
      fAqUrL = base_url + this.faqUrls[this.faqTitle];
    }
    return fAqUrL;
  }
  public getJSON(): Observable<any> {
    return this.http.get('../assets/json/helpFAQ.json');
  }

  /* --------- Functionality to rotate focus within modal start --------- */

  @HostListener('document:keydown.tab', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (document.getElementsByClassName('footer-buttons') && document.getElementsByClassName('footer-buttons')[0]
      && document.getElementsByClassName('footer-buttons')[0].lastElementChild) {
      this.lastElement = document.getElementsByClassName('footer-buttons')[0].lastElementChild;
    }
    // this.firstElement = document.getElementById('modal-header-controls').children[0];
    const lastActiveElement = document.activeElement;
    if (lastActiveElement === this.lastElement) {
      document.getElementById(this.modalId).focus();
    }

  }

  /* --------- Functionality to rotate focus within modal end --------- */

}
