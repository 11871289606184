import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import {
  GlobalSettings
} from '../global.settings';

import {
  CommonService
} from '../../../src/app/common.service';
import { SharedService } from '../shared.service';
import { IndexedDBService } from '../services/indexed-db.service';
import Utils from '../utils';
import { CookieService } from 'ngx-cookie-service';

export class User {
  email: string;
  password: any;
  accountid: any;
  refreshtoken: any;
  accesstoken: any;
  loginFromCookie: boolean;

  constructor(
    public _email: string,
    public _password: string,
    public _accountid: string,
    public _refreshtoken: string,
    public _accesstoken: string,
    public _loginfromcookie: boolean) {
    this.email = _email;
    this.password = _password;
    this.accountid = _accountid;
    this.refreshtoken = _refreshtoken;
    this.accesstoken = _accesstoken;
    this.loginFromCookie = _loginfromcookie;
  }
}


@Injectable()
export class LoginService {
  public user: User;
  public authdetail: any;
  public domainFromCookie = 'learningmate.co';
  refreshTimer = 10000;
  SSOuser = GlobalSettings.SSO_USER;

  constructor(private _router: Router,
    private http: HttpClient,
    private service: CommonService,
    private sharedService: SharedService,
    private indexedDBService: IndexedDBService,
    private cookieService: CookieService) {

  }

  navigateTo(path) {
    this._router.navigate(['home/' + path]);
  }

  login(user: User) {
    this.user = user;
    if (localStorage.getItem('orgDetails')) {
      this.user['organization_id'] = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }
    const url = this.service.baseURL + GlobalSettings.LOGIN_URL;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const options = {
      headers
    };
    const self = this;
    return new Promise((resolve, reject) => {

      this.http.post(url, this.user)
        .subscribe(
          (response: any) => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200) {

                if (data.message === 'Login successfull.') {
                  this.setDataAfterLogin(data);
                  if (this.SSOuser) {
                    this.setCookie(data.data.SSOUser, true);
                    this.setCookie(data.data.central_auth_token, false);
                  }
                  resolve(response.data);
                }
              } else {
                reject(data.message);
              }
            }
          }, error => {

            reject(error);
          }
        );
    });

  }


  logout() {
    let url = this.service.baseURL + GlobalSettings.LOGOUT_URL;
    if (this.SSOuser) {
      url = this.service.baseURL + GlobalSettings.SSO_LOGOUT_URL;
    }
    const body = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    const options = {
      headers
    };
    const self = this;
    return new Promise((resolve, reject) => {
      this.http.post(url, body, options)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.error) {

                reject(data);
              } else {
                resolve();
              }
            }
          }, error => {
            reject(error);
          }
        );
    });
  }

  regenerateToken(refreshToken, platformAccess, orgCode) {
    const option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    const url = GlobalSettings.BASE_URL + GlobalSettings.REGENERATE_TOKEN;
    const body = {
      refresh_token: refreshToken,
      platform_access: platformAccess,
      short_code: orgCode,
    };
    return new Promise((resolve, reject) => {
      this.http.post(url, body, option).subscribe(response => {
        if (response !== null) {
          const data: any = response;
          if (data.error) {
            reject(data);
          } else {
            this.setCookie(data.data.SSOUser, true);
            this.setCookie(data.data.central_auth_token, false);
            resolve(data);
          }
        }
      }, error => {
        reject(error);
      }
      );
    });
  }

  setCookie(obj, needEncode) {
    if (obj.name === 'SSOUser') {
      // tslint:disable-next-line: radix
      this.refreshTimer = parseInt(obj.value.RefreshTime) * 1000;
    }
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() + 30);
    // const cVal = needEncode ? encodeURIComponent(JSON.stringify((obj.value))) : obj.value;
    const cVal = obj.value;
    // const domain = window.location.hostname.includes('learningmate.co') ? 'learningmate.co' : window.location.hostname;
    this.domainFromCookie = obj.domain;
    // document.cookie = obj.name + '=' + cVal + ';expires=' + dt + ';domain=' + this.domainFromCookie + ';path=/';

    this.cookieService.set(obj.name, JSON.stringify(cVal), dt, '/', this.domainFromCookie, true, 'Strict');
  }

  // getCookie(name) {
  //   // const temp1 = this.cookieService.get('central_auth_token');
  //   // const temp2 = this.cookieService.get('SSOUser');
  //   const cName = name + '=';
  //   const cookieObj = document.cookie.split(';');

  //   for (let c of cookieObj) {
  //     while (c.charAt(0) === ' ') {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(cName) === 0) {
  //       return decodeURIComponent(c.substring(cName.length, c.length));
  //     }
  //   }
  //   return '';
  // }

  deleteAllcookies() {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const eqIdx = cookie.indexOf('=');
      const cName = eqIdx > -1 ? cookie.substr(0, eqIdx) : cookie;
      const dt = new Date();
      dt.setDate(dt.getDate() - 3);
      // const domain = window.location.hostname.includes('learningmate.co') ? 'learningmate.co' : window.location.hostname;
      document.cookie = cName + '=;expires=' + dt + ';domain=' + this.domainFromCookie + ';path=/';
    }

  }

  validateUserToken(userName, orgId, parseJson = true) {
    const url = this.service.baseURL + GlobalSettings.VALIDATE_USER_TOKEN;
    const body = {
      username: userName,
      organization_id: orgId,
    };

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: Utils.getCookie('central_auth_token'),
        Accept: 'application/json'
      });
      const requestOptions = {
        headers
      };
      this.http.post(url, body, requestOptions)
        .subscribe(
          (response: any) => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200) {
                if (data.message === 'Login successfull.') {
                  this.setDataAfterLogin(data);
                  localStorage.setItem('loggedIn', 'true');
                  resolve(response.data);
                }
              } else {
                reject(data.message);
              }
            }
          }, error => {

            reject(error);
          }
        );
    });

  }

  setDataAfterLogin(data) {
    localStorage.setItem('access_token', Utils.getCookie('central_auth_token'));
    // localStorage.setItem('access_token', data.data.active_access_token);
    localStorage.setItem('user_id', data.data.user_id);
    localStorage.setItem('role_id', data.data.system_role.role_id);
    localStorage.setItem('first_name', data.data.first_name);
    localStorage.setItem('last_name', data.data.last_name);
    localStorage.setItem('is_super_admin', data.data.is_super_admin);
    localStorage.setItem('system_role_code', data.data.system_role.role_code);
    localStorage.setItem('org_name', data.data.organization.name);
    this.service.role_user_permissions = data.data.system_role_permissions.role_user_permissions;
    this.service.project_permissions = data.data.system_role_permissions.project_permissions;
    this.service.taxonomy_permissions = data.data.system_role_permissions.taxonomy_permissions;
    this.service.workflow_permissions = data.data.system_role_permissions.workflow_permissions;
    this.service.metadata_permissions = data.data.system_role_permissions.metadata_permissions;
    this.service.note_permissions = data.data.system_role_permissions.note_permissions;
    this.service.comment_permissions = data.data.system_role_permissions.comment_permissions;
    this.service.node_template_permissions = data.data.system_role_permissions.node_template_permissions;
    this.service.notification_permissions = data.data.system_role_permissions.notification_permissions;
    // localStorage.setItem('project_role_permissions', JSON.stringify(data.data.project_role_permissions));
    // Utils.projectPermissions = data.data.project_role_permissions;
  }

  validateCookie() {
    const resetCookie = setInterval(() => {
      let SSOUser;
      const cookieObj = Utils.getCookie('SSOUser');
      if (cookieObj) {
        SSOUser = JSON.parse(cookieObj);
      }

      if (SSOUser) {
        this.regenerateToken(SSOUser.refresh_token, SSOUser.platform_access, SSOUser.TenantShortCode);
      }
      if (!SSOUser) {
        clearInterval(resetCookie);
      }
    }, this.refreshTimer);
  }
}
