import {
  Injectable
} from '@angular/core';
import {
  GlobalSettings
} from './global.settings';
import {
  HttpHeaders,
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import {
  FormControl
} from '@angular/forms';
import {
  ResponseContentType
} from '@angular/http';
import {
  ConfirmationDialogService
} from './confirmation-dialog/confirmation-dialog.service';
import {
  Router
} from '@angular/router';
import {
  SharedService
} from './shared.service';
import Utils from './utils';


@Injectable()
export class CommonService {

  role_user_permissions: any;
  project_permissions: any;
  taxonomy_permissions: any;
  workflow_permissions: any;
  metadata_permissions: any;
  note_permissions: any;
  node_type_permissions: any;
  comment_permissions: any;
  isDialogVisible = false;
  node_template_permissions: any;
  notification_permissions: any;
  public_review_permissions: any;
  pacing_guide_comment_permissions: any;
  baseURL: any;
  getUserRolePermission() {
    if (!this.role_user_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.role_user_permissions;
      });
    } else {
      return this.role_user_permissions;
    }
  }
  getProjectPermission() {
    if (!this.project_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.project_permissions;
      });
    } else {
      return this.project_permissions;
    }
  }
  getTaxonomyPermission() {
    if (!this.taxonomy_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.taxonomy_permissions;
      });
    } else {
      return this.taxonomy_permissions;
    }
  }

  getUserWorkFlowPermission() {
    if (!this.workflow_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.workflow_permissions;
      });
    } else {
      return this.workflow_permissions;
    }
  }

  getMetadataPermission() {
    if (!this.metadata_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.metadata_permissions;
      });
    } else {
      return this.metadata_permissions;
    }
  }


  getNoteDocPermission() {
    if (!this.note_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.note_permissions;
      });
    } else {
      console.log('this.note_permissions;', this.note_permissions);
      return this.note_permissions;
    }
  }

  getMetadataSetPermission() {
    if (!this.node_type_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.node_type_permissions;
      });
    } else {
      return this.node_type_permissions;
    }
  }

  getNodeTemplatePermission() {
    if (!this.node_template_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.node_template_permissions;
      });
    } else {
      return this.node_template_permissions;
    }
  }

  getNotificationPermissions() {
    if (!this.notification_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.notification_permissions;
      });
    } else {
      return this.notification_permissions;
    }
  }

  getCommnetPermission(type) {
    if (!this.comment_permissions || !this.pacing_guide_comment_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return (type === 'project' || type === 'taxonomy') ? self.comment_permissions : self.pacing_guide_comment_permissions;
      });
    } else {
      const res = JSON.parse(localStorage.getItem('permissions'));
      this.public_review_permissions = res.role_permissions.public_review_permissions;
      this.pacing_guide_comment_permissions = res.role_permissions.pacing_guide_permissions;
      return (type === 'project' || type === 'taxonomy') ? this.comment_permissions : this.pacing_guide_comment_permissions;
    }
  }

  getPublicReviewPermission(callback) {
    /*if (!this.public_review_permissions) {
      const self = this;
      this.getUserPermission(function () {
        return self.public_review_permissions;
      });
    } else {
      return this.public_review_permissions;
    }*/
    const self = this;
    this.getUserPermission(function () {
      callback(self.public_review_permissions);
    });
  }

  getUserPermission(callback) {
    if (localStorage.getItem('permissions')) {
      // console.log('getUserPermission  from localstorage ');
      const res = JSON.parse(localStorage.getItem('permissions'));
      this.role_user_permissions = res.role_permissions.role_user_permissions;
      this.project_permissions = res.role_permissions.project_permissions;
      this.taxonomy_permissions = res.role_permissions.taxonomy_permissions;
      this.workflow_permissions = res.role_permissions.workflow_permissions;
      this.metadata_permissions = res.role_permissions.metadata_permissions;
      this.note_permissions = res.role_permissions.note_permissions;
      this.comment_permissions = res.role_permissions.comment_permissions;
      this.node_template_permissions = res.role_permissions.node_template_permissions;
      this.node_type_permissions = res.role_permissions.node_type_permissions;
      this.notification_permissions = res.role_permissions.notification_permissions;
      this.public_review_permissions = res.role_permissions.public_review_permissions;
      this.pacing_guide_comment_permissions = res.role_permissions.pacing_guide_permissions;
      // console.log('getUserPermission  from localstorage ', this.pacing_guide_comment_permissions);
      callback();
    } else {
      // console.log('getUserPermission  from service ');
      if (localStorage.getItem('access_token')) {
        const url = GlobalSettings.GET_PERMISSION + '' + localStorage.getItem('role_id');
        this.getServiceData(url).then((res: any) => {
          localStorage.setItem('permissions', JSON.stringify(res));
          this.role_user_permissions = res.role_permissions.role_user_permissions;
          this.project_permissions = res.role_permissions.project_permissions;
          this.taxonomy_permissions = res.role_permissions.taxonomy_permissions;
          this.workflow_permissions = res.role_permissions.workflow_permissions;
          this.metadata_permissions = res.role_permissions.metadata_permissions;
          this.note_permissions = res.role_permissions.note_permissions;
          this.comment_permissions = res.role_permissions.comment_permissions;
          this.node_template_permissions = res.role_permissions.node_template_permissions;
          this.node_type_permissions = res.role_permissions.node_type_permissions;
          this.notification_permissions = res.role_permissions.notification_permissions;
          this.public_review_permissions = res.role_permissions.public_review_permissions;
          this.pacing_guide_comment_permissions = res.role_permissions.pacing_guide_permissions;
          // console.log('getUserPermission  from service ', this.pacing_guide_comment_permissions);
          callback();
        });
      }
    }
  }

  nospaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {
      'whitespace': true
    };
  }
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private shareService: SharedService
  ) { }

  getServiceData(url, isHeader = true, fullRes = false, needPrefix = true, checkCookie = true) {
    url = needPrefix ? (this.baseURL + url) : url;
    if (checkCookie) {
      this.checkCookie();
    }
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      let headers = new HttpHeaders({
        'Authorization': localStorage.getItem('access_token')
      });
      if (isHeader === false) {
        headers = new HttpHeaders({});
      }
      const requestOptions = {
        headers: headers
      };
      this.httpClient.get(url, requestOptions)
        .subscribe(
          response => {
            if (response !== null) {
              this.isDialogVisible = false;
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                resolve(fullRes ? data : data.data);
              } else {
                reject(data.message);
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject(error);
          }
        );
    });

  }

  getUserServiceData(url) {
    url = this.baseURL + url;
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      // const headers = new HttpHeaders({
      //   'Authorization': localStorage.getItem('access_token')
      // }),
      //   requestOptions = {
      //     headers: headers
      //   };
      this.httpClient.get(url)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                resolve(data.data);
              } else {
                reject(data.message);
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject(error);
          }
        );
    });

  }

  postService(url, body, index?: number, needFullRes = false, needPrefix = true) {
    index = index ? index : 0;
    url = needPrefix ? this.baseURL + url : url;
    this.checkCookie();
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('access_token'),
        'Accept': 'application/json'
      });
      const requestOptions = {
        headers
      };
      this.httpClient.post(url, body, requestOptions)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                if (data.data && (data.data instanceof Object)) {
                  data.data['returnIndex'] = index;
                }
                resolve(needFullRes ? data : data.data);
              } else {
                reject({
                  msg: data.message,
                  index: index,
                  status: data.status
                });
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject({
              msg: error.error.message,
              index: index,
              status: error.status
            });
          }
        );
    });

  }

  registerPostService(url, body, index?: 0) {
    url = this.baseURL + url;
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('access_token'),
        'Accept': 'application/json'
      }),
        requestOptions = {
          headers: headers
        };
      this.httpClient.post(url, body, requestOptions)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                if (data.data && (data.data instanceof Object)) {
                  data.data['returnIndex'] = index;
                }
                resolve(data);
              } else {
                reject({
                  msg: data.message,
                  index: index
                });
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject({
              msg: error.error.message,
              index: index,
              status: error.status
            });
          }
        );
    });

  }


  registerService(url, body, index?: 0) {
    url = this.baseURL + url;
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      const headers = new HttpHeaders({
        'Accept': 'application/json'
      }),
        requestOptions = {
          headers: headers
        };
      this.httpClient.post(url, body, requestOptions)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                if (data.success === false) { // Error response
                  reject({
                    msg: data.message[0] ? data.message[0] : data.message,
                    index: index,
                    status: data.status
                  });
                } else { // Success response
                  resolve(data);
                }
              } else {
                reject({
                  msg: data.message,
                  index: index
                });
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject({
              msg: error.error.message,
              index: index
            });
          }
        );
    });

  }

  getUserServiceDataWithoutTocken(url, needExactResponse = false, appendBaseUrl = true) {
    if (appendBaseUrl) {
      url = this.baseURL + url;
    } else {
      url = url;
    }
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      const headers = new HttpHeaders({});
      const requestOptions = {
        headers: headers
      };
      // localStorage.removeItem('access_token');
      // localStorage.clear();
      // sessionStorage.clear();

      this.httpClient.get(url)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                if (data.success === false) { // Error response
                  reject({
                    msg: data.message[0] ? data.message[0] : data.message,
                    status: data.status
                  });
                } else { // Success response
                  resolve(data.data);
                }
              } else {
                if (needExactResponse) {
                  resolve(data);
                } else {
                  reject(data.message);
                }
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject(error);
          }
        );
    });

  }

  deleteServiceData(url, fullRes = false, bodyObject?: any) {
    url = this.baseURL + url;
    this.checkCookie();
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      let requestOptions: any;
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('access_token')
      });
      if (bodyObject) {
        requestOptions = {
          headers: headers,
          body: bodyObject
        };
      } else {
        requestOptions = {
          headers: headers
        };
      }
      this.httpClient.delete(url, requestOptions)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                resolve(fullRes ? data : data.data);
              } else {
                reject({
                  msg: data.message,
                  status: data.status
                });
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject({
              msg: error.error.message,
              status: error.status
            });
          }
        );
    });

  }


  putService(url, body, _index = 0, fullRes = false) {
    url = this.baseURL + url;
    this.checkCookie();
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('access_token'),
        'Accept': 'application/json'
      }),
        requestOptions = {
          headers: headers
        };
      this.httpClient.put(url, body, requestOptions)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                resolve(fullRes ? data : data.data);
              } else {
                reject({
                  msg: data.message,
                  index: _index,
                  status: data.status
                });
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject({
              msg: error.error.message,
              index: _index,
              status: error.status
            });
          }
        );
    });

  }

  putServiceWithouToken(url, body, index?: 0) {
    url = this.baseURL + url;
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      const headers = new HttpHeaders({}),
        requestOptions = {
          headers: headers
        };
      this.httpClient.put(url, body, requestOptions)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.status === 200 || data.status === 201) {
                // console.log('Data', data);
                if (data.success === false) { // Error response
                  reject({
                    msg: data.message[0] ? data.message[0] : data.message,
                    index: index,
                    status: data.status
                  });
                } else { // Success response
                  resolve(data.data);
                }
              } else {
                reject({
                  msg: data.message,
                  index: index,
                  status: data.status
                });
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject({
              msg: error.error.message,
              index: index,
              status: error.status
            });
          }
        );
    });

  }

  downloadServiceData(url, type, method = 'get', body = null) {
    url = this.baseURL + url;
    return new Promise((resolve, reject) => {
      // console.log('tocken ', localStorage.getItem('access_token'));
      /* const headers = new HttpHeaders({
           'Authorization': localStorage.getItem('access_token')
         }),
         requestOptions = {
           headers: headers,
           responseType: ResponseContentType.Blob
         };*/
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('access_token'),
        'Content-Type': 'application/json'
      });
      /*headers.set('Content-Type', 'application/json');
      headers.set('Authorization', localStorage.getItem('access_token'));
      headers.set('responseType', 'blob');*/
      const httpOptions = {
        headers: headers
      };
      if (type === 'PDF' || type === 'DOCX' || type === 'XLS' || type === 'XLSX' || type === 'PPTX'
        || type === 'ODT' || type === 'ODS' || type === 'RTF') {
        httpOptions['responseType'] = 'blob';
      }
      if (type === 'CSV') {
        httpOptions['responseType'] = 'text';
      }
      if (type === 'HTML') {
        httpOptions['responseType'] = 'text/html';
      }
      if (method.toLowerCase() === 'get') { // download service with GET method
        this.httpClient.get(url, httpOptions)
          .subscribe(
            response => {
              if (response !== null) {
                // console.log('jkljkljlkjl ', response);
                const data: any = response;
                // if (data.status === 200 || data.status === 201)
                {
                  // console.log('Data', data);
                  resolve(response);
                }
                /* else {
                   reject(data.message);
                 }*/
              }
            }, error => {
              if (error.status === 401) {
                this.sessionTimeOut();
              }
              reject(error);
            }
          );
      } else if (method.toLowerCase() === 'post' && body) { // download service with POST method
        this.httpClient.post(url, body, httpOptions)
          .subscribe(
            response => {
              if (response !== null) {
                {
                  resolve(response);
                }
              }
            }, error => {
              if (error.status === 401) {
                this.sessionTimeOut();
              }
              reject(error);
            }
          );
      }
    });

  }


  sessionTimeOut() {

    if (!this.isDialogVisible) {
      this.isDialogVisible = true;
      this.shareService.sucessEvent.next({
        type: 'session_expired'
      });
      const self = this;
      setTimeout(function () {
        // self.isDialogVisible = false;
        // self.router.navigate(['/']);

        const path = self.getOrgDetails() + '/home';
        sessionStorage.setItem('frontface_url', self.getOrgDetails() + '/home');
        self.router.navigate([path]);


        const urls = sessionStorage.getItem('current_url');
        sessionStorage.clear();
        sessionStorage.setItem('current_url', urls);
      }, 2000);
    }
    /*if (!this.isDialogVisible) {
      this.isDialogVisible = true;
      this.dialogService.confirm('Confirm', 'Session expired. Please login again ', 'Yes', 'Cancel', 'lg', true)
        .then((confirmed) => {
          this.isDialogVisible = false;
          if (confirmed) {
            this.router.navigate(['app/login']);
          } else {
            console.log('User cancel the dialog');
          }
        })
        .catch(() => {
          console.log('User dismissed the dialog');
          this.isDialogVisible = false;
        });
    }*/
  }

  uploadService(url, body, index = 0) {
    url = this.baseURL + url;
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: localStorage.getItem('access_token'),
        Accept: 'application/json'
      });
      const requestOptions = {
        headers,
        reportProgress: true
      };
      const req = new HttpRequest('POST', url, body, requestOptions);
      this.httpClient.request(req)
        .subscribe(
          response => {
            if (response !== null) {
              const data: any = response;
              if (data.type === HttpEventType.UploadProgress) {

                // calculate the progress percentage
                const percentDone = Math.round(100 * data.loaded / data.total);
                // pass the percentage into the progress-event
                this.shareService.progressEvent.next({ 'percentDone': percentDone, 'index': index, 'resReceived': false });
              } else if (data instanceof HttpResponse) {
                const bodyData = data.body;
                this.shareService.progressEvent.next({ 'percentDone': 100, 'index': index, 'resReceived': true });
                if (bodyData && bodyData.data && (bodyData.data instanceof Object)) {
                  bodyData.data['returnIndex'] = index;
                }
                // The upload is complete
                if (data.status === 200 || data.status === 201) {
                  resolve(bodyData ? bodyData.data : bodyData);
                } else {
                  reject({
                    msg: (bodyData && bodyData.message) ? bodyData.message : 'Error in upload service',
                    index,
                    status: data.status
                  });
                }
              }
            }
          }, error => {
            if (error.status === 401) {
              this.sessionTimeOut();
            }
            reject({
              msg: error.error.message,
              index: index,
              status: error.status
            });
          }
        );
    });

  }

  getOrgDetails() {
    if (localStorage.getItem('orgDetails')) {
      const orgCode = '/org/' + JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
      return orgCode;
    }
  }

  setOrgDetails(org) {
    if (org) {
      localStorage.setItem('orgDetails', JSON.stringify({
        'orgCode': org.org_code,
        'orgId': org.organization_id
      }));
      if (sessionStorage.getItem('frontface_url')) {
        const splitFFUrl = sessionStorage.getItem('frontface_url').split('/');
        splitFFUrl[2] = org.org_code;
        sessionStorage.setItem('frontface_url', splitFFUrl.join('/')); // setting front-face url according to organization code
      }
      if (sessionStorage.getItem('current_url')) {
        const currentUrl = sessionStorage.getItem('current_url').split('/');
        currentUrl[2] = org.org_code;
        sessionStorage.setItem('current_url', currentUrl.join('/')); // setting current url according to organization code
      }
    }
  }

  setLoginResponseInStorage(data) {
    localStorage.setItem('access_token', Utils.getCookie('central_auth_token'));
    // localStorage.setItem('access_token', data.active_access_token);
    localStorage.setItem('user_id', data.user_id);
    localStorage.setItem('role_id', data.system_role.role_id);
    localStorage.setItem('first_name', data.first_name);
    localStorage.setItem('last_name', data.last_name);
    localStorage.setItem('system_role_code', data.system_role.role_code);
    localStorage.setItem('org_name', data.organization.name);
    this.setOrgDetails(data.organization);
    // localStorage.setItem('system_role_permissions', data.data.system_role_permissions.role_user_permissions);
    this.role_user_permissions = data.system_role_permissions.role_user_permissions;
    this.project_permissions = data.system_role_permissions.project_permissions;
    this.taxonomy_permissions = data.system_role_permissions.taxonomy_permissions;
    this.workflow_permissions = data.system_role_permissions.workflow_permissions;
    this.metadata_permissions = data.system_role_permissions.metadata_permissions;
    this.note_permissions = data.system_role_permissions.note_permissions;
    this.comment_permissions = data.system_role_permissions.comment_permissions;
    this.node_template_permissions = data.system_role_permissions.node_template_permissions;
    this.notification_permissions = data.system_role_permissions.notification_permissions;
    // localStorage.setItem('project_role_permissions', JSON.stringify(data.project_role_permissions));
    // this.indexedDBService.addData(data.project_role_permissions, 'project_role_permissions');
  }

  checkCookie() {
    const cookieObj = document.cookie;
    const isSSOuser = GlobalSettings.SSO_USER;
    if (isSSOuser && cookieObj.indexOf('SSOUser') === -1 && cookieObj.indexOf('central_auth_token') === -1) {
      localStorage.removeItem('access_token');
      this.shareService.sucessEvent.next({
        type: 'session_expired'
      });
      if (localStorage.getItem('orgDetails')) {
        const orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
        this.router.navigate(['/org/' + orgCode + '/home']);
      }
    }
  }

  downloadServiceWithoutToken(url, type, method = 'get', body = null) {
    url = this.baseURL + url;
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const requestOptions = {
        headers
      };

      if (type === 'PDF' || type === 'DOCX' || type === 'XLS' || type === 'XLSX' || type === 'PPTX'
        || type === 'ODT' || type === 'ODS' || type === 'RTF') {
        requestOptions['responseType'] = 'blob';
      }
      if (type === 'CSV') {
        requestOptions['responseType'] = 'text';
      }
      if (type === 'HTML') {
        requestOptions['responseType'] = 'text/html';
      }
      if (method.toLowerCase() === 'get') { // download service with GET method
        this.httpClient.get(url, requestOptions)
          .subscribe(
            response => {
              if (response !== null) {
                // console.log('jkljkljlkjl ', response);
                const data: any = response;
                // if (data.status === 200 || data.status === 201)
                {
                  // console.log('Data', data);
                  resolve(response);
                }
                /* else {
                   reject(data.message);
                 }*/
              }
            }, error => {
              if (error.status === 401) {
                this.sessionTimeOut();
              }
              reject(error);
            }
          );
      }
    });
  }
}
