import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  Observable
} from 'rxjs';
import {
  Router
} from '@angular/router';
import {
  CommonService
} from '../common.service';
import {
  GlobalSettings
} from '../global.settings';
import {
  AuthenticateUserService
} from './../authenticateuser.service';
import Utils from '../utils';
import {
  SharedService
} from '../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  environment
} from '../../environments/environment';
import {
  AutoCompleteComponent
} from '../common/auto-complete/auto-complete.component';
import { LoginService } from '../login/login.service';
import { IndexedDBService } from '../services/indexed-db.service';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss', './nav-bar-skip-main.scss', './nav-bar-logo-img.scss']

})

/// <reference path="../..assets/js/common.d.ts" />
export class NavBarComponent implements OnInit, OnChanges, OnDestroy {

  userName = '';
  nameInitials = '';
  pending = []; // pending request
  @Input() currentItem = 'Taxonomies';
  @Output() showNavEvent: EventEmitter<any>;
  navBarItems = [];
  serverPermission: boolean;
  intervalTimer = null;
  notifyCount = 0;
  orgCode;
  orgName = '';
  orgId = null;
  organizations = [];
  currentComponentEvent: Subscription;
  renamePacingGuide = false; // holds boolean to show if PG needs to be renamed based on environment selected
  orgNameChangeEvent: Subscription;
  showNav = true;
  dropdownSettings = {
    textField: 'organization_name',
    showLabel: false,
    buttonType: 'button'
  };
  selectedOrg: any;
  uniqueId = 'organization_id';
  selectType = 'single';
  autoCompleteTitle = 'organization';
  nameChangeSubscription: Subscription;
  hovered = false;
  externalLink = ''; // holds external outside of angular app link to open 
  btnFocused = false;
  isSSOUser = false;
  tenantSwitchconfig = '';
  navBarPermission = {};
  adminAccess = 0; // determines if user has admin access
  tenantSwitched = false;
  tenantSwitchEventSubscription: Subscription; // holds subscription for boolean on tenant switch
  openNav = false; // holds flag to toggle nav bar (in mobile view)
  permissionLoadEvent: Subscription;
  userPermissionLoadEvent: Subscription;
  permissionLoaded = false; // holds flag to display loader while loading project permission
  userPermissionLoaded = false; // holds flag to display loader while loading user permission
  showNotification = false; // holds boolean to determine if notification permission available
  @ViewChild('autoComplete', {
    static: false
  }) autoComplete: AutoCompleteComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private service: CommonService,
    private sharedService: SharedService,
    private loginService: LoginService,
    private indexedDbService: IndexedDBService) {
    this.showNavEvent = new EventEmitter<any>();
    this.permissionLoadEvent = this.sharedService.projectPermissionLoaded.subscribe((val: boolean) => {
      this.permissionLoaded = val;
      if (this.permissionLoaded) {
        this.getPermissionObject();
      }
    });
    this.userPermissionLoadEvent = this.sharedService.userPermissionLoaded.subscribe((val: boolean) => {
      this.userPermissionLoaded = val;
      if (this.userPermissionLoaded) {
        this.getPermissionObject();
      }
    });
    this.loadNavbarItems();


    // this.sharedService.orgDetails.subscribe((event: any) => {
    //   if (event.orgCode !== undefined) {
    //     this.orgCode = event.orgCode;
    //   }
    // });

    this.nameChangeSubscription = this.sharedService.profileNameChangeEvent.subscribe(data => {
      if (data) {
        this.setUserName();
      }
    });

    if (localStorage.getItem('orgDetails')) {
      this.orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
    }

    this.tenantSwitchEventSubscription = this.sharedService.tenantSwitchEvent.subscribe((event) => {
      this.tenantSwitched = event;
    });

  }

  loadNavbarItems() {
    this.renamePacingGuide = environment.renamePacingGuide;
    // tslint:disable-next-line: max-line-length
    const { renamePacingGuide, showPublishedTaxonomy } = Utils.checkChangesForSpecificOrg(this.renamePacingGuide, this.router.url.split('/')[2]);
    this.renamePacingGuide = renamePacingGuide;
    this.getJSON().subscribe(data => {
      this.navBarItems = data;
      if (this.renamePacingGuide) {
        for (const i in this.navBarItems) {
          if (i) {
            if (this.navBarItems[i]['name'] === 'Pacing Guides') {
              this.navBarItems[i]['name'] = 'Unit Planner';
              break;
            }
          }
        }
      }
      this.sharedService.windowResizeEvent.subscribe((item: any) => {
        if (item) {
          if (window.devicePixelRatio >= 1.5) {
            this.toggleSideBar(false);
          } else {
            this.openNav = false;
          }
        }
      });
      this.setTheCurrentItem(this.currentItem);
      Utils.getNavbarHeight();
    }, err => { });
  }

  public getJSON(): Observable<any> {
    try {
      return this.http.get('./assets/json/nav.json');
    } catch (err) { }
  }

  ngOnInit() {
    // this.getPermissionObject();
    this.isSSOUser = GlobalSettings.SSO_USER;
    this.externalLink = GlobalSettings.UNIFIED_ADMIN;
    let domain = '.learningmate.co';
    if (document.location.hostname.indexOf('acmt.frostplatform.com') !== -1) {
      domain = '.frostplatform.com';
    }
    const tenantSwitchconfig = {
      platformId: 7,
      domain,
      adminHost: GlobalSettings.ADMIN_HOST,
      apiHostAuthServer: GlobalSettings.API_HOST_AUTH_URL,
      client_id: GlobalSettings.CLIENT_ID,
      client_secret: GlobalSettings.CLIENT_SECRET,
      grant_type: GlobalSettings.GRANT_TYPE
    };
    try {
      console.log('tenantSwitchconfig', tenantSwitchconfig);
      this.tenantSwitchconfig = JSON.stringify(tenantSwitchconfig);
    } catch (e) {
      console.log('Exception in tenant switch', e);
    }

    // if (!localStorage.getItem('access_token')) {
    const path = window.location.hash.replace('#', '');
    sessionStorage.setItem('current_url', path);
    const SSOUserObj = JSON.parse(Utils.getCookie('SSOUser'));
    const token = Utils.getCookie('central_auth_token');
    if (SSOUserObj.platform_access && SSOUserObj.platform_access['standards'] === 1) {
      this.validateUser(token, SSOUserObj.Email, SSOUserObj.TenantId);
    } else {
      this.sharedService.sucessEvent.next({
        type: 'error',
        customMsg: 'Sorry, you don\'t have access to this organization.'
      });
    }
    // tslint:disable-next-line: max-line-length
    // this.loginService.regenerateToken(SSOUserObj.refresh_token, SSOUserObj.platform_access, SSOUserObj.TenantShortCode).then((res: any) => {
    //   try {
    //     console.log('#########', res);
    //     const SSOUserCookie = JSON.parse(this.loginService.getCookie('SSOUser'));
    //     const token = this.loginService.getCookie('central_auth_token');
    //     this.loginService.validateUserToken(token, SSOUserCookie.Email, SSOUserCookie.TenantId).then((respones: any) => {
    //       console.log('reached!');
    //       this.getUserPermission();
    //     });
    //   } catch (e) {
    //     console.log('Exception in regenerate', e);
    //   }

    // });
    // } else {
    //   this.initializeData();
    // }

  }

  validateUser(token, Email, TenantId) {
    console.log('reached!', token, Email, TenantId);
    this.loginService.validateUserToken(Email, TenantId, false).then((respones: any) => {
      console.log('reached!');
      // this.getUserPermission();
      this.initializeData();
    });
  }

  initializeData() {
    console.log('called');
    this.getAllProjectAccessRequest();
    this.setUserName();
    this.orgName = localStorage.getItem('org_name');
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }
    // if (JSON.parse(localStorage.getItem('loggedIn'))) {
    const SSOUser = JSON.parse(Utils.getCookie('SSOUser'));
    if (SSOUser && SSOUser['platform_access']) {
      this.adminAccess = SSOUser['platform_access']['admin'];
    }
    const obj = {
      orgCode: SSOUser.TenantShortCode,
      organization_id: SSOUser.TenantId
    };
    this.orgId = SSOUser.TenantId;
    this.setOrganization(obj);
    // }

  }

  ngOnChanges() {
    this.setTheCurrentItem(this.currentItem);
  }

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    if (this.currentComponentEvent) {
      this.currentComponentEvent.unsubscribe();
    }
    if (this.orgNameChangeEvent) {
      this.orgNameChangeEvent.unsubscribe();
    }
    if (this.nameChangeSubscription) {
      this.nameChangeSubscription.unsubscribe();
    }
    if (this.tenantSwitchEventSubscription) {
      this.tenantSwitchEventSubscription.unsubscribe();
    }
    if (this.permissionLoadEvent) {
      this.permissionLoadEvent.unsubscribe();
    }
    if (this.userPermissionLoadEvent) {
      this.userPermissionLoadEvent.unsubscribe();
    }
    this.resetStoredPermissions();
  }

  setTheCurrentItem(item) {
    // Its quick fix need to work on it
    // tslint:disable-next-line:max-line-length
    this.currentItem = item;
    if (item === 'Team' || item === 'Workflows' || item === 'Metadata' || item === 'Node Template') {
      if (this.navBarItems[3]) {
        this.navBarItems[3].isExpanded = true;
      }
    }
  }

  getCurrentItem() {

  }

  onNavItemSelected(itemName) {
    this.currentItem = itemName;
  }


  openExternalLink() {
    window.open(this.externalLink, '_blank');
  }

  onItemClick(item) {
    item.isExpanded = !item.isExpanded;
  }

  getPermission(name) {
    if (this.navBarPermission[name]) {
      // console.log('getPermission returned 1');
      return this.navBarPermission[name];
    }
    const value = this.checkPermission(name);
    this.navBarPermission[name] = value;
    // console.log('getPermission returned 2');
    return value;
  }

  checkPermission(name) {
    if (localStorage.getItem('permissions')) {
      const res = JSON.parse(localStorage.getItem('permissions'));
      // const projectPermission = JSON.parse(localStorage.getItem('project_role_permissions'));
      // const projectPermission = Utils.projectPermissions;

      // if (!Utils.projectPermissions) {
      //   Utils.indexedDBServiceObj.getData('acmt', 'project_role_permissions').then((permission: any) => {
      //     Utils.projectPermissions = permission;
      //     return this.checkDataForPermission(res, name);
      //   }).catch((ex) => {
      //     console.log('Exception in idb connection');
      //   });
      // } else {
      return this.checkDataForPermission(res, name);
      // }


    }
    return false;
  }

  checkDataForPermission(res, name) {
    const projectPermission = Utils.projectPermissions;
    // const projectPermission = this.indexedDbService.getRolePermission();
    if (name === 'Taxonomies') {
      if (res && res.role_permissions && res.role_permissions.taxonomy_permissions.length > 0) {
        return true;
      }
    } else if (name === 'Projects') {
      if (res && res.role_permissions && res.role_permissions.project_permissions.length > 0) {
        return true;
      }
      // Override global permission with project permission
      if (projectPermission && projectPermission.length > 0) {
        // console.log('projectPermission', projectPermission);
        if (projectPermission.length > 0) {
          return true;
        }
      }
    } else if (name === 'Pacing Guides') {
      if (res && res.role_permissions && res.role_permissions.pacing_guide_permissions.length > 0) {
        return true;
      }
    } else
      if (name === 'Team') {
        if (res && res.role_permissions &&
          res.role_permissions.role_user_permissions &&
          res.role_permissions.role_user_permissions.length > 0) {
          return true;
        }
      } else if (name === 'Workflows') {
        if (res && res.role_permissions &&
          res.role_permissions.workflow_permissions &&
          res.role_permissions.workflow_permissions.length > 0) {
          return true;
        }
      } else if (name === 'Metadata') {
        if (res && res.role_permissions &&
          ((res.role_permissions.metadata_permissions &&
            res.role_permissions.metadata_permissions.length > 0) || (res.role_permissions.node_type_permissions &&
              res.role_permissions.node_type_permissions.length > 0))) {
          return true;
        }
      } else if (name === 'Node Template') {
        if (res && res.role_permissions &&
          res.role_permissions.node_template_permissions &&
          res.role_permissions.node_template_permissions.length > 0) {
          return true;
        }
      } else if (name === 'Notifications') {
        if (res && res.role_permissions &&
          res.role_permissions.notification_permissions &&
          res.role_permissions.notification_permissions.length > 0) {
          return true;
        }
      } else if (name === 'Settings') {
        if (res && (res.role_permissions &&
          res.role_permissions.taxonomy_permissions &&
          res.role_permissions.taxonomy_permissions.length > 0 &&
          this.hasPermission(res.role_permissions.taxonomy_permissions, 'Manage CASE servers')) ||
          (res.role_permissions.note_permissions &&
            res.role_permissions.note_permissions.length > 0)) {
          return true;
        }
      } else if (name === 'Public Review') {
        if (res && res.role_permissions &&
          res.role_permissions.public_review_permissions &&
          res.role_permissions.public_review_permissions.length > 0 &&
          (this.hasPermission(res.role_permissions.public_review_permissions, 'View Active Public Review Taxonomies') ||
            (this.hasPermission(res.role_permissions.public_review_permissions, 'View Archived Public Review Taxonomies')))) {
          return true;
        }
      } else if (name === 'Reports') {
        if (res && res.role_permissions &&
          res.role_permissions.role_user_permissions &&
          res.role_permissions.role_user_permissions.length > 0 &&
          (this.hasPermission(res.role_permissions.role_user_permissions, 'Reports'))) {
          return true;
        }
      } else if (name === 'Home') {
        if (localStorage.getItem('system_role_code') === 'PPR00') {
          return false;
        } else {
          if (res && res.role_permissions &&
            res.role_permissions.role_user_permissions &&
            res.role_permissions.role_user_permissions.length > 0 &&
            (this.hasPermission(res.role_permissions.role_user_permissions, 'View Home Screen') ||
              this.hasPermission(res.role_permissions.role_user_permissions, 'View Additional Statistics'))) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
  }

  checkAdministration(item) {
    // if (this.tenantSwitched) {
    this.navBarPermission = {};
    // this.tenantSwitched = false;
    // }
    if (item.name === 'Admin' && item.children) {
      for (let i = 0; i < item.children.length; i++) {
        if (this.getPermission(item.children[i].name)) {
          return true;
        }
      }
    }
    return false;
  }

  logout() {
    const frontface_url = sessionStorage.getItem('frontface_url');
    this.orgCode = window.location.hash.substr(6, 5);
    // if (localStorage.getItem('orgDetails')) {
    //   this.orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
    // }
    localStorage.clear();
    sessionStorage.clear();
    if (this.isSSOUser) {
      this.loginService.deleteAllcookies();
      const path = GlobalSettings.UNIFIED_LOGOUT + '/' + this.orgCode;
      window.location.href = path;
    } else {
      sessionStorage.setItem('frontface_url', frontface_url);
      this.navigate();
      document.title = 'ACMT :Academic Competencies Management Tool';
    }
    this.resetStoredPermissions();
  }

  notifications() {
    // this.router.navigate([`/app/notifications`]);
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_NOTIFICATIONS;
    this.router.navigate([path]);
  }

  /* --------- Functionality for fetching the list of all request start --------- */

  getAllProjectAccessRequest() {
    this.pending = [];
    const url = GlobalSettings.PROJECT_ACCESS_REQUEST;
    this.service.getServiceData(url).then((res: any) => {
      if (Array.isArray(res)) {
        res.forEach(element => {
          if (element.status === 1) {
            this.pending.push(element); // pending
          }
        });
      }
    }).catch((ex) => {
      console.log('Error caught while fetching metadata list', ex);
    });
  }

  hasPermission(array, name) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.display_name === name) {
        return true;
      }
    }
    return false;
  }

  /**
   * To check any notifications, in each 30 seconds interval
   */
  startNotificationUpdatesActivity() {
    this.intervalTimer = setInterval(() => this.getNotificationUpdatesCount(), 5000 * Utils.NOTIFICATION_INTERVAL_TIME);
  }

  /**
   * To get number of notifications
   */
  getNotificationUpdatesCount() {
    const url = GlobalSettings.NOTIFICATION_COUNT;
    this.service.getServiceData(url).then((res: any) => {
      // console.log('Notification Count ', res);
      const count = res.count;
      if (this.sharedService.newNotificationEvent) {
        if (this.notifyCount !== count) { // If new notifications, send 'true'
          this.getNotificationList();
          this.sharedService.newNotificationEvent.next(true);
        } else {
          this.sharedService.newNotificationEvent.next(false);
        }
      }
      this.notifyCount = count;
    }).catch((ex) => {
      console.log('Notification Count Ex', ex);
    });
  }


  navigate() {
    const nav = [];
    nav.push('/org/' + this.orgCode + '/home');
    this.router.navigate(nav);
    // if (sessionStorage.getItem('frontface_url')) {
    //   this.router.navigate([sessionStorage.getItem('frontface_url')]);
    // } else {
    //   this.router.navigate([nav]);
    // }
  }

  /**
   * To get organization list
   */
  getOrganizationList() {
    this.organizations = [];
    const url = GlobalSettings.ORGANIZATION_LIST;
    this.service.getServiceData(url).then((res: any) => {
      console.log('Organization List ', res);
      this.organizations = res;
      this.selectedOrg = this.organizations.find(x => x.organization_id === this.orgId);
    }).catch((ex) => {
      console.log('Organization List Ex', ex);
    });
  }

  /**
   * To set organization response in localstorage after switching organization
   * @param orgId
   */
  setOrganization(org: any) {
    this.sharedService.tenantSwitchEvent.next(true);
    this.navBarPermission = {};
    const orgId = org.organization_id;
    if (orgId) {
      const url = GlobalSettings.SWITCH_ORGANIZATION;
      const body = {
        organization_id: orgId
      };
      this.service.postService(url, body).then((res: any) => {
        console.log('setOrganization  ', res);
        this.service.setLoginResponseInStorage(res);
        this.resetStoredPermissions();
        this.callProjectPermissionAPI();
        this.getUserPermission();

        this.sharedService.currentComponentEvent.next({
          component: null
        });

        this.sharedService.csvStatusUpdateEvent.next({ checkStatus: true });
        this.sharedService.tenantSwitchEvent.next(false);

        // this.router.navigate([sessionStorage.getItem('current_url')]) .then(() => window.location.reload());
        this.orgName = localStorage.getItem('org_name');
        this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
        this.selectedOrg = this.organizations.find(x => x.organization_id === this.orgId);
        if ((sessionStorage.getItem('current_url')).indexOf('app/home') !== -1) {
          this.sharedService.currentComponentEvent.next({
            component: 'home'
          });
        } else {
          if (!JSON.parse(localStorage.getItem('association_link_clicked'))) {
            const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_HOME;
            sessionStorage.setItem('current_url', path);
          } else {
            localStorage.setItem('association_link_clicked', 'false');
          }
        }
        this.router.navigate([sessionStorage.getItem('current_url')]);
        // window.location.reload();
        const self = this;
        // this.getJSON().subscribe(data => {
        //   localStorage.setItem('permissions', '');
        //   this.service.getUserPermission(function () {
        //     self.navBarItems = data;
        //   });
        // });
        this.loadNavbarItems();
        init_dropdownHover();
        this.getNotificationUpdatesCount();
        this.startNotificationUpdatesActivity();
        this.getOrganizationList();
        this.orgNameChangeEvent = this.sharedService.orgNameChangeEvent.subscribe((data: any) => {
          if (data) {
            const orgData = data.data;
            this.setOrgName(orgData.ogdId, orgData.newName);
          }
        });
        localStorage.setItem('isShowNav', JSON.stringify(this.showNav));
        // setTimeout(() => {
        //   this.loadMultiTenantWebcomponent();
        // }, 500);
      }).catch((ex: any) => {
        this.callProjectPermissionAPI();
        console.log('on Set Organization ex ', ex);
      });
    }
  }


  // FUNCTION to call API for project permissions

  callProjectPermissionAPI() {
    const url = GlobalSettings.GET_PROJECT_PERMISSION;
    this.service.getServiceData(url).then((res: any) => {
      Utils.projectPermissions = res;
      this.indexedDbService.addData((res), 'project_role_permissions');
      this.sharedService.projectPermissionLoaded.next(true);
    });
  }

  goToHomePage() {
    this.onNavItemSelected('Dashboard');
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_HOME;
    this.router.navigate([path]);
  }

  skipToMain() {
    if (document.getElementById('main_container')) {
      document.getElementById('main_container').focus();
    }
  }

  /**
   * To change organization name
   * @param orgId (organization name)
   * @param newName (organization's new name to be updated)
   */
  setOrgName(orgId: any, newName: string) {
    for (const org of this.organizations) {
      if (org.organization_id === orgId) {
        org.organization_name = newName;
        this.orgName = newName;
        localStorage.setItem('org_name', newName);
        break;
      }
    }
  }

  onClickedOutside(e) {
    // this.toggleSideBar(false);
  }
  toggleSideBar(val = true) {
    this.showNav = !this.showNav;
    localStorage.setItem('isShowNav', '' + this.showNav);
    if (document.querySelector('#main_container')) {
      // Adding/removing animation class on left panel menu collapsed/expanded
      document.querySelector('#main_container').classList.remove('container-animation');
      document.querySelector('#main_container').classList.add('container-animation');
      setTimeout(() => {
        document.querySelector('#main_container').classList.remove('container-animation');
      }, 300);
    }
    // Utils.preloaderWidthAdjustment(val);
    this.showNavEvent.emit(this.showNav);
    setTimeout(() => {
      // this.loadMultiTenantWebcomponent();
    }, 500);
  }

  loadMultiTenantWebcomponent() {
    if (document.getElementById('tenantSwitch')) {
      const mtWebcomponentContainer = document.getElementById('tenantSwitch');
      if (document.getElementsByTagName('app-tenant-switch')
        && document.getElementsByTagName('app-tenant-switch')[0]) {
        const mtWebcomponent = document.getElementsByTagName('app-tenant-switch')[0];
        mtWebcomponentContainer.removeChild(mtWebcomponent);
      }
    }

    const app = document.createElement('app-tenant-switch');
    // var config = {
    //   "platformId": 7,
    //   "domain": 'localhost',
    //   "adminHost": 'https://adminapp-dev.learningmate.co/',
    //   "apiHostAuthServer": 'https://oauth-qa.learningmate.co/devoauth/',
    //   "client_id": '68e8cf4f-3e93-4089-918a-121e77b603a8',
    //   "client_secret": 'gninraeletam',
    //   "grant_type": 'refresh_token'
    // };
    app.setAttribute('config', this.tenantSwitchconfig);
    if (document.getElementById('tenantSwitch')) {
      document.getElementById('tenantSwitch').append(app);
    }
  }

  getNavHeight() {
    const head = document.getElementById('head').offsetHeight;
    const foot = document.getElementById('foot').offsetHeight;
    const sec = document.getElementById('mainNav').offsetHeight;
    const navHeight = (sec - (head + foot)) + 'px';
    return (navHeight);
  }

  onClickedOutsideList(e) {
    this.autoComplete.openPanel(false);
  }

  onUserProfileClick() {
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_USER_PROFILE;
    this.router.navigate([path]);
  }

  setUserName() {
    if (localStorage.getItem('last_name') && localStorage.getItem('last_name') !== null && localStorage.getItem('last_name') !== 'null') {
      this.userName = localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name');
      this.nameInitials = localStorage.getItem('first_name')[0] + localStorage.getItem('last_name')[0];
    } else {
      this.userName = localStorage.getItem('first_name');
      this.nameInitials = localStorage.getItem('first_name')[0];
    }
  }

  focusNavBar(val) {
    this.hovered = val;
  }
  focustoggleBtn(val) {
    this.btnFocused = val;
  }

  getNotificationList() {
    const url = GlobalSettings.NOTIFICATION_LIST;

    this.service.getServiceData(url).then((res: any) => {
      if (res.length > 0) {
        Utils.sortDataArray(res, 'updated_at', false, false);
        // tslint:disable-next-line: max-line-length
        const versionNotificationList = res.filter(noti => noti.notification_category === 13);
        // if (versionNotificationList && versionNotificationList.length) {
        this.sharedService.notificationListEvent.next(versionNotificationList);
        // }
      }
    }).catch((ex) => {
      console.log('Notification List Ex', ex);
    });
  }

  goToPublicWebsite() {
    const prefixUrl = GlobalSettings.BASE_URL.replace('server/', '#').replace('api.', '') + this.service.getOrgDetails();
    const path = prefixUrl + '/home';
    window.open(path, '_blank');
  }

  //  Function to toggle nav bar in mobile view
  toggleMenu() {
    this.openNav = !this.openNav;
  }

  /* --------- Functionality to fetch project permission on init & project permission load start --------- */

  getPermissionObject() {
    Utils.indexedDBServiceObj.getData('acmt', 'project_role_permissions').then((permission: any) => {
      if (permission instanceof Array) {
        Utils.projectPermissions = permission;
        this.updateNavbarObject();
      }
    }).catch((ex) => {
      console.log('Exception in idb connection');
    });
  }

  /* --------- Functionality to fetch project permission on init & project permission load end --------- */


  /* --------- Functionality to update navbar object with all system & project permissions start --------- */

  updateNavbarObject() {
    if (localStorage.getItem('permissions')) {
      this.navBarItems.forEach(item => {
        const permission = this.checkPermission(item.name);
        item.hasPermission = permission ? true : false;
        if (item.children && item.children.length > 0) {
          item.children.forEach(childItem => {
            const childPermission = this.checkPermission(childItem.name);
            childItem.hasPermission = childPermission ? true : false;
          });
        }
      });
      this.showNotification = this.checkPermission('Notifications') ? this.checkPermission('Notifications') : false;
      this.permissionLoaded = true;
      this.userPermissionLoaded = true;
      setTimeout(() => {
        this.loadMultiTenantWebcomponent();
      }, 500);
    }
  }

  /* --------- Functionality to update navbar object with all system & project permissions end --------- */


  /* --------- Functionality for fetching permissions on login & tenant switch start --------- */

  getUserPermission() {
    const url = GlobalSettings.GET_PERMISSION + '' + localStorage.getItem('role_id');
    if (localStorage.getItem('access_token')) {
      this.service.getServiceData(url).then((res: any) => {
        // console.log(' getUerPemfsfsfsfs ', res);
        localStorage.setItem('permissions', JSON.stringify(res));
        this.service.role_user_permissions = res.role_permissions.role_user_permissions;
        this.service.project_permissions = res.role_permissions.project_permissions;
        this.service.taxonomy_permissions = res.role_permissions.taxonomy_permissions;
        this.service.workflow_permissions = res.role_permissions.workflow_permissions;
        this.service.metadata_permissions = res.role_permissions.metadata_permissions;
        this.service.note_permissions = res.role_permissions.note_permissions;
        this.service.comment_permissions = res.role_permissions.comment_permissions;
        this.service.node_template_permissions = res.role_permissions.node_template_permissions;
        this.service.node_type_permissions = res.role_permissions.node_type_permissions;
        this.service.notification_permissions = res.role_permissions.notification_permissions;
        // this.loadNavbarItems();
        // this.initializeData();
        this.sharedService.userPermissionLoaded.next(true);
      });
    }
  }

  /* --------- Functionality for fetching permissions on login & tenant switch end --------- */


  /* --------- Functionality reset stored permissions and its event start --------- */

  resetStoredPermissions() {
    Utils.projectPermissions = null;
    Utils.individualProjectPermissions = null;
    this.indexedDbService.addData('null', 'project_role_permissions');
    this.indexedDbService.addData('null', 'individual_project_role_permissions');
    this.sharedService.projectPermissionLoaded.next(false);
    this.sharedService.userPermissionLoaded.next(false);
    localStorage.removeItem('permissions');
  }

  /* --------- Functionality reset stored permissions and its event end --------- */

}
