import {
  Component,
  OnInit,
  OnChanges,
  Input,
  OnDestroy
} from '@angular/core';

import {
  GlobalSettings
} from '../../global.settings';
import {
  CommonService
} from '../../common.service';
import {
  SharedService
} from '../../shared.service';
import {
  AuthenticateUserService
} from '../../authenticateuser.service';
import {
  Subscription
} from 'rxjs/Subscription';
import Utils from '../../utils';
import {
  Router
} from '@angular/router';
import {
  ConfirmationDialogService
} from '../../confirmation-dialog/confirmation-dialog.service';
import { IndexedDBService } from 'src/app/services/indexed-db.service';
@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit, OnChanges, OnDestroy {
  workFlowStagesList: any[] = [];
  stageNameTitle: string;
  selectedStageId: any;
  project_id: string;
  @Input() workFlowId;
  @Input() showProjectStatus;
  @Input() preventEdit = false;
  workFlowPermissionEvent: Subscription;
  constructor(private service: CommonService, private sharedService: SharedService, private authenticateUser: AuthenticateUserService, private router: Router,
    private dialogService: ConfirmationDialogService, private indexedDbService: IndexedDBService) {
    this.workFlowPermissionEvent = this.sharedService.workFlowPermission.subscribe((event: any) => {
      if (event.is_editable !== undefined) {
        setTimeout(() => {
          this.preventEdit = event.is_editable;
        }, 1000);
      }
    });
  }

  ngOnInit() {
    this.project_id = localStorage.getItem('project_id');
    console.log('ProjectStatusComponent ngOnInit ', this.project_id);
    // if (this.authenticateUser.AuthenticProjectPermissions('Change Workflow Stage')) {
    // this.getWorkFlowList();
    this.getProjectDetail(this.project_id);
    this.getWorkFlowStagesList(this.workFlowId);
    // }
  }

  ngOnChanges() { }


  getProjectDetail(projectId) {
    this.stageNameTitle = '';
    this.project_id = projectId;
    const url = GlobalSettings.PROJECT_LIST + '/' + this.project_id;
    this.service.getServiceData(url).then((res: any) => {
      if (res && res.current_workflow_stage && res.current_workflow_stage.current_workflow_stage_name) {
        this.stageNameTitle = res.current_workflow_stage.current_workflow_stage_name;
        this.selectedStageId = res.current_workflow_stage_id;
      }
    }).catch((ex) => {
      console.log('getProjectDetail ', ex);
    });
  }

  // getWorkFlowList() {
  //   const url = GlobalSettings.GET_WORKFLOW;
  //   this.service.getServiceData(url).then((res: any) => {
  //     this.getWorkFlowStagesList(res.WorkflowList.workflows[0].workflow_id);
  //   }).catch((ex) => {
  //     console.log('error in WorkflowList', ex);
  //   });
  // }

  getWorkFlowStagesList(workflowId) {
    const url = GlobalSettings.GET_WORKFLOW + '/' + workflowId;
    this.service.getServiceData(url).then((res: any) => {
      if (res && res.workflow) {
        this.workFlowStagesList = res.workflow.stages;
      }
      // this.stageNameTitle = res.workflow.stages[0].stage_name;
    }).catch((ex) => {
      console.log('error in workFlowStagesList', ex);
    });
  }

  selectStageName(data) {
    this.stageNameTitle = data.stage_name;
    this.selectedStageId = data.workflow_stage_id;
    const tempObj = {
      project_id: this.project_id,
      workflow_stage_id: data.workflow_stage_id
    };

    const url = GlobalSettings.CREATE_PROJECT + '/' + this.project_id + '/workflowStage';
    this.service.postService(url, tempObj).then((res: any) => {

      // const projectsPermission = JSON.parse(localStorage.getItem('project_role_permissions'));
      // const projectsPermission = Utils.projectPermissions;

      if (!Utils.individualProjectPermissions) {
        Utils.indexedDBServiceObj.getData('acmt', 'individual_project_role_permissions').then((permission: any) => {
          Utils.individualProjectPermissions = permission;
          this.checkDataForPermission(res);
        }).catch((ex) => {
          console.log('Exception in idb connection');
        });
      } else {
        this.checkDataForPermission(res);
      }

    }).catch(ex => {
      console.log('create role', ex);
    });
  }

  checkDataForPermission(res) {

    const projectsPermission = Utils.individualProjectPermissions;
    let stageAssigned, hasAnyProjectLevelPermissoin, isAdmin = false;
    if (projectsPermission && projectsPermission.length > 0) {
      for (let i = 0; i < projectsPermission.length; i++) {
        const data = projectsPermission[i];
        if (data.project_id === this.project_id) {
          hasAnyProjectLevelPermissoin = true;
          data.stages.forEach(element => {
            if (element['stage_id'].includes(res.current_workflow_stage_id.split('||')[1])) {
              stageAssigned = true;
              // console.log('res.current_workflow_stage_id', res.current_workflow_stage_id, projectsPermission)
            }
          });
        }
      }
      if (!hasAnyProjectLevelPermissoin) {
        // it should be admin
        isAdmin = true;
      }
    } else {
      // it should be admin
      isAdmin = true;
    }

    if (!stageAssigned && !isAdmin) {
      const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_LIST;
      this.dialogService.confirm('Confirm', 'The current workflow stage for this project does not allow you to access this project.', true, 'Yes', 'No', null, true)
        .then((confirmed) => {
          if (confirmed) {
            this.router.navigate([path]);
            this.sharedService.sucessEvent.next({
              type: 'project_status'
            });
          }
        });
    } else {
      this.sharedService.sucessEvent.next({
        type: 'project_status'
      });
    }
  }


  ngOnDestroy() {
    if (this.workFlowPermissionEvent) {
      this.workFlowPermissionEvent.unsubscribe();
    }
  }

}
