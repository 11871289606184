import {
    NgModule,
    NO_ERRORS_SCHEMA
} from '@angular/core';
import {
    CommonModule
} from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { TreeComponent } from '../common/tree/tree.component';
import { CreateProjectTreeComponent } from '../common/create-project-tree/create-project-tree.component';
import { AutoCompleteModule } from './auto-complete/auto-complete.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { PreLoaderModule } from './preloader/pre-loader.module';
// import {
//     TabularTreeSidePaneComponent
// } from '../common/tabular-tree-side-pane/tabular-tree-side-pane.component';
import { MathJaxModule } from './mathJax/mathJax.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        ClickOutsideModule,
        PreLoaderModule,
        MathJaxModule
    ],
    exports: [
        TreeComponent,
        CreateProjectTreeComponent,
        MathJaxModule
        // TabularTreeSidePaneComponent,
    ],
    declarations: [
        TreeComponent,
        CreateProjectTreeComponent,
        // TabularTreeSidePaneComponent,
    ],
    schemas: [NO_ERRORS_SCHEMA],
})
export class TreeModule { }
