import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, AfterViewInit, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
  Observable
} from 'rxjs';
import {
  HttpClient
} from '@angular/common/http';
import Utils from 'src/app/utils';
import { VirtualScrollStrategy, CdkVirtualScrollViewport, FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { SharedService } from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import { ListRange } from '@angular/cdk/collections';
@Component({
  selector: 'app-lazy-tree',
  templateUrl: './lazy-tree.component.html',
  styleUrls: ['./lazy-tree.component.scss', './lazy-tree-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: LazyTreeComponent }]
})


export class LazyTreeComponent extends FixedSizeVirtualScrollStrategy implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  currentItemId = ''; // holds current node item on which focus is supposed to be shifted after expansion
  offset = 0; // holds the offset of current node, to used provide focus back on scroll after expansion
  loadChild = false; // holds boolean to determine loader on node expansion
  loadChildSubscription: Subscription; // holds subscription to determine whether or not children have been loaded
  clicked = ''; // identifies the the current clicked expand button
  charLimit = 120; // holds character limit to trim HC and FS
  @Input() refinedData; // holds final data that is passed to virtual scroll
  @Input() viewLocation; // holds boolean value to determin css
  @Input() showComplaince; // holds boolean value to determin compliance icons
  @Input() selectedNodeId; // holds the current selected node id
  @Output() toggleExpandEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkviewport: CdkVirtualScrollViewport;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: FixedSizeVirtualScrollStrategy;

  constructor(private http: HttpClient, private sharedService: SharedService, private cdRef: ChangeDetectorRef) {
    super(25, 0, 0);
    this.loadChildSubscription = this.sharedService.loadChild.subscribe((item: any) => {
      if (item && item.loadChild) {
        this.loadChild = item.loadChild;
      } else {
        this.loadChild = false;
      }
    });
  }

  ngOnInit() { }

  /* --------- Functionality to node expansion event start --------- */

  showChildren(item, isExpanded, expandButtonForChild) {
    this.toggleExpandEvent.emit(item);
  }

  /* --------- Functionality to node expansion event end --------- */


  /* --------- Functionality to add padding to tree nodes start --------- */

  calculatePadding(level) {
    return Utils.calculatePadding(level, 'treeview');
  }

  /* --------- Functionality to add padding to tree nodes end --------- */


  /* --------- Functionality to capture offset, call scrolltoOffset functionality after refined data is updated start --------- */

  scrollViewPort(time) {
    const self = this;
    // tslint:disable-next-line: no-unused-expression
    new Promise(() => {
      setTimeout(() => {
        // if (self.viewPort && self.viewPort.scrollToIndex) {
        // const selectedIndex = this.refinedData.findIndex(elem => elem.id === this.currentItemId);
        // self.viewPort.scrollToIndex(selectedIndex, 'smooth');
        // console.log('########################## selectedIndex after', selectedIndex);
        // console.log('#################', this.currentItemId, document.getElementById(this.currentItemId));
        // if (document.getElementById(this.currentItemId)) {
        //   document.getElementById(this.currentItemId).focus();
        // }
        // }
        if (document.getElementById(this.currentItemId)) {
          document.getElementById(this.currentItemId).focus();
        }
      }, time);
    }
    );
  }

  async scroll() {
    await this.scrollViewPort(200);
    // this.sharedService.loadChild.next({
    //   loadChild: false
    // });
  }

  getOffset(id) {
    this.currentItemId = id;
    // const selectedIndex = this.refinedData.findIndex(elem => elem.id === this.currentItemId);
    // console.log('########################## selectedIndex', selectedIndex);
    this.offset = this.cdkviewport.measureScrollOffset();
    this.sharedService.loadChild.next({
      loadChild: true
    });
  }

  /* --------- Functionality to capture offset, call scrolltoOffset functionality after refined data is updated end --------- */

  ngOnDestroy() {
    if (this.loadChildSubscription) {
      this.loadChildSubscription.unsubscribe();
    }
    Utils.addBodyScroll();
  }

  /* --------- Functionality to emit the current selected node object start --------- */

  onNodeSelected(item) {
    if (!item.isOrphanLabel) {
      if (item.id) {
        this.selectedNodeId = item.id;
      }
      item.location = this.viewLocation;
      this.sharedService.treeNodeSelectedEvent.next(item);
    }
  }

  /* --------- Functionality to emit the current selected node object end --------- */
  handler(evt) {
    // console.log('################## SCROLLED', evt);
    // this.scrolled = true;
  }

  ngAfterViewInit() { }

  ngOnChanges() { }

  trackByIdx(i) {
    return i;
  }

  setData(data) {
    // this.refinedData = data;
    // this.cdRef.detectChanges();
  }

}


