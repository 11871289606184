import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  GlobalSettings
} from '../../global.settings';
import {
  CommonService
} from '../../common.service';
import {
  SharedService
} from '../../shared.service';
import {
  isNullOrUndefined
} from 'util';
import { AutoCompleteComponent } from '../../common/auto-complete/auto-complete.component';

@Component({
  selector: 'app-additional-metadata',
  templateUrl: './additional-metadata.component.html'
})
export class AdditionalMetadataComponent implements OnInit, OnChanges {

  @Input() node_type_id;
  @Input() nodeType;
  @Input() option: string;
  @Input() nodeDetails;
  @Input() selectedNodeId: string;
  @Input() additionalMetadata; // holds data for edit metadata form
  @Input() action; //  'add' or 'edit'
  @Input() addedMetadataList = [];
  @Output() additionalMetadataObj: EventEmitter<any>;
  @Output() deleteClicked = new EventEmitter();

  @ViewChild('autoComplete', { static: false }) autoComplete: AutoCompleteComponent;

  additionalMetaDataList = []; // holds metadata list for dropdown
  metadataList = [];
  selectedMetadata; // holds data for add metadata form
  field_type_id;
  currentValue; // holds metadata value
  addMetadataForm: FormGroup; //  form instance for add
  editMetadataForm: FormGroup; //  form instance for edit
  formValid = false;
  loading = true;
  selectType = 'single';
  uniqueId = 'metadata_id';
  autoCompleteTitle = 'Key Name';
  listEndingData = {
    title: 'Getting added at the moment'
  };
  prevNodeTypeValue = '';
  initialData;

  constructor(private service: CommonService, private fb: FormBuilder, private sharedService: SharedService) {
    this.additionalMetadataObj = new EventEmitter<any>();
    this.addMetadataForm = this.createFormGroup_add();
    this.editMetadataForm = this.createFormGroup();
  }

  ngOnInit() {
    this.selectedMetadata = null;
    if (this.action === 'add') {
      this.getAllMetadata();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.nodeType) {
      this.getAllMetadata();
    }
    if (changes.option && changes.option.currentValue) {
      this.option = changes.option.currentValue;
    }
    if (changes.action && changes.action.currentValue) {
      this.action = changes.action.currentValue;
    }

    if (this.action === 'edit') { //  For edit operation
      this.loading = true;
      if (this.additionalMetadata) { // If editing data exists
        // this.additionalMetadata = this.additionalMetadata;
        if (changes.additionalMetadata && changes.additionalMetadata.currentValue) {
          this.additionalMetadata = changes.additionalMetadata.currentValue;
        }
        this.field_type_id = this.additionalMetadata.field_type_id;
        this.loading = false;
      }
      this.initialData = JSON.parse(JSON.stringify(this.additionalMetadata));
    } else if (this.action === 'add') { //  For add operation
      this.loading = true;
      if (this.metadataList.length > 0) {
        this.getNodeTypeMetadata();
      } else {
        this.getAllMetadata();
      }
    }
    setTimeout(() => {
      additionalMetadataPanelHeight();
    }, 300);
  }

  createFormGroup() {
    //  Form structure creation
    const group = new FormGroup({
      metadataKey: new FormControl(null, [<any>Validators.required]),
      metadataValue: new FormControl(null, [<any>Validators.required])
    });
    return group;
  }

  createFormGroup_add() {
    //  Form structure creation
    const group = new FormGroup({
      // metadataKey: new FormControl(null, [ < any > Validators.required]),
      metadataValue: new FormControl(null, [<any>Validators.required])
    });
    return group;
  }
  /* --------- Fetch metadata List functionality Started --------- */

  getAllMetadata() {
    if (this.prevNodeTypeValue !== this.nodeType.title) {
      this.prevNodeTypeValue = this.nodeType.title;
      const url = GlobalSettings.GET_METADATA_LIST;
      this.service.getServiceData(url).then((res: any) => {
        this.metadataList = this.filterMetadataList(res.metadata);
        this.getNodeTypeMetadata();
      }).catch((ex) => {
        console.log('Error caught while fetching metadata list', ex);
      });
    }
  }

  filterMetadataList(data) {
    const metaList = [];
    data.forEach(element => {
      if (this.nodeType.title === 'Document' && this.nodeType.created_by && this.nodeType.created_by.length === 0) {
        if (element.is_document === 1 || element.is_document === 2 || element.is_custom === 1) {
          metaList.push(element);
        }
      } else {
        if (element.is_document === 0 || element.is_document === 2) {
          metaList.push(element);
        }
      }
    });
    return metaList;
  }

  /* --------- Fetch metadata List functionality Ended --------- */

  /* --------- Functionality to fetch existing metadata of particular node type start --------- */

  getNodeTypeMetadata() {
    let nodeTypeMetadata = [];
    this.additionalMetaDataList = [];
    const url = GlobalSettings.SAVE_NODETYPE_METADATA + '/' + this.node_type_id;
    this.service.getServiceData(url).then((res: any) => {
      this.additionalMetaDataList = [];
      nodeTypeMetadata = res;
      this.filterData(nodeTypeMetadata);
      /*if (this.additionalMetaDataList.length > 0) {
        this.selectedMetadata = this.additionalMetaDataList[0];
        this.field_type_id = this.selectedMetadata.field_type_id;
      }*/
      this.loading = false;
    }).catch((ex) => {

    });
  }
  /* --------- Functionality to fetch existing metadata of particular node type end --------- */

  onMetadataSelected(obj) {
    this.selectedMetadata = obj;
    console.log('onMetadataSelected ', this.selectedMetadata);
    this.selectedMetadata ? (this.field_type_id = this.selectedMetadata.field_type_id) : (this.field_type_id = '');
    this.currentValue = null;
  }

  onChange(event) {

  }

  /**
   * Service call for adding metadata
   * @param event (optional)
   */
  addMetaData(event?) {
    if ((this.checkFormValidation() === true) && (this.checkCustomValidation() === true)) {
      if (this.selectedMetadata.field_type_id === 4) {
        this.currentValue = this.currentValue['formatted'];
      }

      let obj: any, url, metadataValue: any, metadataValueHtml: any;
      if (this.nodeDetails.is_document === 1) { // Document level selection
        url = GlobalSettings.DOCUMENT_LEVEL_ADDITIONAL_METADATA + '/' + this.selectedNodeId;
      } else if (this.nodeDetails.is_document === 0) { // Item level selection
        url = GlobalSettings.ITEM_LEVEL_ADDITIONAL_METADATA + '/' + this.selectedNodeId;
      }
      // field_type_id=1/2 means text/long text (rich text)
      if ((this.selectedMetadata.field_type_id === 1) || this.selectedMetadata.field_type_id === 2) {
        metadataValue = this.currentValue.value;
        metadataValueHtml = this.currentValue.htmlValue;
      } else {
        metadataValue = this.currentValue;
        metadataValueHtml = this.currentValue;
      }
      obj = {
        'metadata_id': this.selectedMetadata.metadata_id,
        'metadata_value': metadataValue,
        'metadata_value_html': metadataValueHtml,
        'is_additional': 1
      };
      this.service.postService(url, obj).then((res: any) => {
        this.sharedService.sucessEvent.next({
          type: 'add_additional_metadata'
        });
        const newobj = new Object({
          'metadata_id': this.selectedMetadata.metadata_id,
          'metadata_value': metadataValue,
          'metadata_value_html': metadataValueHtml,
          'is_additional_metadata': 1,
          'name': this.selectedMetadata.name,
          'field_type_id': res.field_type_id,
          'internal_name': res.internal_name
        });
        this.addedMetadataList.push(newobj);
        this.additionalMetadataObj.emit({
          'list': this.addedMetadataList,
          'response': res,
          'value': metadataValue,
          'value_html': metadataValueHtml
        });
        if (this.metadataList.length > 0) {
          this.getNodeTypeMetadata();
        } else {
          this.getAllMetadata();
        }
        this.onCancel(event);
        setTimeout(() => {
          additionalMetadataPanelHeight();
        }, 100);
        this.onCancel();
      }).catch((ex) => {
        console.log('addAdditionaMetadata ex ', ex);
      });
    } else {
      this.sharedService.sucessEvent.next({
        type: 'invalid_form_submission'
      });
    }
  }

  /**
   * Service call for editing metadata
   * @param event (optional)
   */
  editMetaData(event?) {
    if ((this.checkFormValidation() === true) && (this.checkCustomValidation() === true)) {
      // if (this.additionalMetadata.field_type_id === 4) {
      //   this.currentValue = this.currentValue['formatted'];
      // }
      let obj: any, url = '';
      if (this.nodeDetails.is_document === 1) { // Document level selection
        url = GlobalSettings.DOCUMENT_LEVEL_ADDITIONAL_METADATA + '/' + this.selectedNodeId;
      } else if (this.nodeDetails.is_document === 0) { // Item level selection
        url = GlobalSettings.ITEM_LEVEL_ADDITIONAL_METADATA + '/' + this.selectedNodeId;
      }
      let metadataValue = this.currentValue;
      let metadataValueHtml = this.currentValue;

      // field_type_id=2 means long text (rich text)
      if ((this.additionalMetadata.field_type_id === 1) || (this.additionalMetadata.field_type_id === 2)) {
        if (this.currentValue.value) {
          metadataValue = this.currentValue.value;
        } else {
          metadataValue = this.additionalMetadata['metadata_value'];
        }
        if (this.currentValue.htmlValue) {
          metadataValueHtml = this.currentValue.htmlValue;
        } else {
          metadataValueHtml = this.additionalMetadata['metadata_value_html'];
        }
      }
      obj = {
        'metadata_id': this.additionalMetadata.metadata_id,
        'metadata_value': metadataValue,
        'metadata_value_html': metadataValueHtml,
        'is_additional': 1
      };
      this.service.putService(url, obj).then((res) => {
        this.sharedService.sucessEvent.next({
          type: 'update_additional_metadata'
        });
        this.addedMetadataList = this.getModifiedMetadataList(this.addedMetadataList, obj);
        this.additionalMetadataObj.emit({
          'list': this.addedMetadataList,
          'response': res,
          'value': metadataValue,
          'value_html': metadataValueHtml
        });
        this.onCancel(event);
      }).catch((ex) => {
        console.log('editAdditionaMetadata ex ', ex);
        this.sharedService.sucessEvent.next({
          type: 'error'
        });
      });
    } else {
      this.sharedService.sucessEvent.next({
        type: 'invalid_form_submission'
      });
    }
  }

  /**
   * Delete event for metadata
   * @param metadata_id
   */
  onDeleteEvent(metadata_id) {
    this.deleteClicked.emit(metadata_id);
  }

  /**
   * To modify an item value of metadata list
   * @param list []
   * @param obj // new item data
   */
  getModifiedMetadataList(list: any[], obj: any) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].metadata_id === obj.metadata_id) {
        list[i].metadata_value = obj.metadata_value;
        list[i].metadata_value_html = obj.metadata_value_html;
        break;
      }
    }
    return list;
  }

  getAdditionMetadata() {
    this.additionalMetadataObj.emit(this.addedMetadataList);
  }

  /*formItemValueReceived(event) {
    this.currentValue = event;
    this.checkFormValidation();
  }*/

  filterData(nodeTypeMetadata) {
    if (this.addedMetadataList) {
      this.addedMetadataList.forEach(item => {
        nodeTypeMetadata.push(item);
      });
    }
    this.metadataList.forEach(data => {
      let exist = false;
      for (const nodeData of nodeTypeMetadata) {
        if (data.metadata_id === nodeData.metadata_id) {
          //  console.log('Match ', data.name);
          exist = true;
          break;
        }
      }
      if (exist === false) {
        this.additionalMetaDataList.push(data);
      }
    });
  }

  // On form cancel
  onCancel(event?) {
    this.selectedMetadata = null;
    this.field_type_id = '';
    this.formValid = false;
    this.additionalMetadata = null;
    if (this.addMetadataForm) {
      this.addMetadataForm = this.createFormGroup_add();
      this.addMetadataForm.reset();
    }
    if (this.editMetadataForm) {
      this.editMetadataForm = this.createFormGroup();
      this.editMetadataForm.reset();
    }
    this.loading = false;
    this.autoComplete.clearSelection();
  }

  // Form validation
  checkFormValidation(): boolean {
    if (this.action === 'add' && this.addMetadataForm) {
      return this.addMetadataForm.valid;
    } else if (this.action === 'edit') {
      return this.editMetadataForm.valid;
    }
    return false;
  }

  checkCustomValidation(): boolean {
    this.formValid = false;
    if (this.action === 'add') { // add additional metadata
      if (this.selectedMetadata && (this.selectedMetadata.field_type_id === 1 || this.selectedMetadata.field_type_id === 2)) {
        this.formValid = !(this.currentValue['value'] === '') && (this.currentValue['value'] !== undefined)
          && (this.currentValue['value'] !== 'undefined') && (this.currentValue['value'] !== null);
      } else {
        this.formValid = true;
      }
    } else if (this.action === 'edit') { // edit additional metadata
      if (this.initialData && ((this.initialData.metadata_value) ||
        (this.initialData.metadata_value === '')) && (isNullOrUndefined(this.currentValue) === false)) {
        if ((this.initialData.field_type_id === 4) && this.currentValue['formatted']) {
          this.currentValue = this.currentValue['formatted'];
        }
        // If rich text field value comes empty from text editor event or value same including rich value, set form as invalid
        if ((this.initialData.field_type_id === 1) || (this.initialData.field_type_id === 2)) { // field_type_id === 2 means long text
          this.formValid = !(this.currentValue['value'] === '') && (this.currentValue['value'] !== undefined)
            && (this.currentValue['value'] !== 'undefined') && (this.currentValue['value'] !== null);
          if (this.formValid) {
            if (this.currentValue['htmlValue'] !== this.initialData['metadata_value_html']) {
              this.formValid = true;
            } else if (this.currentValue['value'] === this.initialData['metadata_value']) {
              this.formValid = false;
            } else {
              this.formValid = true;
            }
          }
        } else { // for all other elements except long text
          this.formValid = !(JSON.stringify(this.initialData.metadata_value) === JSON.stringify(this.currentValue));
        }
      }
    }
    return this.formValid;
  }

  getHtml(obj: any, attribute: any) {
    let htmlContent = '';
    htmlContent = obj[attribute + '_html'] ? obj[attribute + '_html'] : obj[attribute];
    return htmlContent;
  }

  onClickedOutside(e) {
    this.autoComplete.openPanel(false);
  }
}
