import { Component, OnInit, ViewChild, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-tabular-tree-side-pane',
  templateUrl: './tabular-tree-side-pane.component.html',
  styleUrls: ['./tabular-tree-side-pane.component.scss']
})
export class TabularTreeSidePaneComponent implements OnInit, OnChanges {
  @ViewChild('itemDetailsComponent', { static: false }) itemDetailsComponent: ItemDetailsComponent;
  @Input() dataForSidePanel;
  @Input() isAllNode = false;
  @Input() taxonomyData;
  @Input() selectedNode;
  @Input() showNavigation = false; // holds boolean value for showing up navigation arrow;
  @Output() nextClickEvent = new EventEmitter();
  @Output() previousClickEvent = new EventEmitter();
  @Output() sidePanelClosed = new EventEmitter();

  itemAssociationsData = [];
  itemLinkedNodes = [];
  showAssocciation;
  showAdditionalMetadata;
  showExemplar;
  showDocument = true;
  taxonomyId;
  viewLocation = 'front-facing';
  showEmptyFields = false;
  isEditable = false;
  nodetypeList;
  selectedNodeType = {
    title: '',
    node_type_id: ''
  };
  fetchedMetadata: any = {};
  action;
  @Input() showControls = true; // holds boolean value for showing up controls as save

  addedAdditionalMetadata;
  @Input() showUserFriendlyPage = false;
  firstNode: any;
  constructor(private route: ActivatedRoute, private router: Router, private service: CommonService) {
    this.route.params.subscribe(params => {
      this.taxonomyId = params.id;
    });
  }

  ngOnInit() {
    if ((this.router.url).indexOf('/project/detail') >= 0) {
      this.isEditable = true;
    }
    this.getAllMetadata();
  }

  ngOnChanges() {
    if (this.taxonomyData) {
      if (this.taxonomyData instanceof Array) {
        this.firstNode = this.taxonomyData[0].id || this.taxonomyData[0].item_id ? this.taxonomyData[0] : this.taxonomyData[0].children[0];
      } else {
        this.firstNode = this.taxonomyData.children.id ? this.taxonomyData.children : this.taxonomyData.children[0];
      }
    }
  }

  showHideNav(value) {
    if (value) {
      this.openNav();
    } else {
      this.closeNav();
    }
  }


  openNav() {
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.right = '0px';
      this.sidePanelClosed.emit(false);
    }
    document.getElementById('close-note').focus();
  }

  closeNav() {
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.right = '-250%';
      this.sidePanelClosed.emit(true);
    }
  }

  cancleComment() {
    this.showHideNav(false);
  }

  setData(data) {
    if (this.itemDetailsComponent && this.itemDetailsComponent.nodeDetailsComponent) {
      this.itemDetailsComponent.nodeDetailsComponent.resetFormObj();
    }
    let tempData = null;
    tempData = JSON.parse(JSON.stringify(data));
    tempData['children'] = [];
    this.dataForSidePanel = tempData;
    this.selectedNodeType['title'] = this.dataForSidePanel['node_type'];
    this.selectedNodeType['node_type_id'] = this.dataForSidePanel['node_type_id'];
    this.selectedNodeType['is_document'] = this.dataForSidePanel['document_type'] ? 1 : 0;
    // tslint:disable-next-line:max-line-length
    this.itemAssociationsData = this.dataForSidePanel && this.dataForSidePanel['document_type'] ? [] : this.dataForSidePanel && this.dataForSidePanel['itemAssociations'] ? this.dataForSidePanel['itemAssociations'] : [];
    this.itemLinkedNodes = (this.dataForSidePanel && this.dataForSidePanel['linked_item']) ? this.dataForSidePanel['linked_item'] : [];
    this.addedAdditionalMetadata = this.dataForSidePanel && this.dataForSidePanel['addedAdditionalMetadata'] ? this.dataForSidePanel['addedAdditionalMetadata'] : [];
    if (this.itemDetailsComponent && this.itemDetailsComponent.nodeDetailsComponent) {
      this.showAssocciation = true;
      this.showAdditionalMetadata = true;
      if (this.dataForSidePanel['document_type'] === 1 || this.dataForSidePanel['document_type'] === 2) {
        this.showExemplar = false;
        this.dataForSidePanel['source_uuid_id'] = this.dataForSidePanel['source_document_id'];
      } else {
        this.showExemplar = true;
        this.dataForSidePanel['source_uuid_id'] = this.dataForSidePanel['source_item_id'];
      }
      this.showDocument = true;
      // console.log('this.dataForSidePanel TD ', JSON.stringify(this.dataForSidePanel));
      this.itemDetailsComponent.nodeDetailsComponent.generateForm(this.dataForSidePanel, this.dataForSidePanel.node_type);
    }
  }


  getAllMetadata() {
    if (localStorage.getItem('access_token')) {
      const url = GlobalSettings.GET_NODETYPE_LIST;
      this.service.getServiceData(url).then((res: any) => {
        this.nodetypeList = res.nodetype;
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }

  addActionEvent(evt) {
    console.log('evt', evt);
  }

  setEditable(isEditable) {
    this.isEditable = isEditable;
    this.showControls = isEditable;
    this.showEmptyFields = isEditable;
  }

  onNextNavigationClick(event) {
    this.nextClickEvent.emit(event);
  }

  onPreviousNavigationClick(event) {
    this.previousClickEvent.emit(event);
  }

}
