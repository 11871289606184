import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { GlobalSettings } from '../../../global.settings';
import { CommonService } from '../../../common.service';
import { SearchFilterDataService } from '../SearchFilterDataService';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import { AcmtSearchListComponent } from '../acmt-search-list/acmt-search-list.component';
@Component({
  selector: 'app-acmt-search',
  templateUrl: './acmt-search.component.html',
  styleUrls: ['./acmt-search.component.scss']
})
export class AcmtSearchComponent implements OnInit {
  Object = Object;
  dropdownObject = {}; // holds the entire dropdown object
  dropdownSettings = {
    textField: 'label',
  }; // settings for individual filter dropdown
  moreFilterDropdownSettings = {
    textField: 'label',
    showLabel: false
  }; // settings for more filter dropdown
  filterCriteria = {}; // holds all the filters objects applied, object structure is same as dropdown object
  searchOptionalFilters = []; // holds all the optional filters selected
  contextPage = ''; // holds context page for to determine search location
  searchResult = []; // holds search result response
  searchKeyword = ''; // hold the keyword to search
  saveSearchObj = {}; // holds search object to sent as request payload
  moreFilterClicked = false; // holds if more filter is clicked
  filterVisibility = true; // holds visibility of filters on reset for some time in order to clear inner selected values
  moreFilterOptions = [{
    is_selected: 0,
    label: 'update on'
  }, {
    is_selected: 0,
    label: 'updated by'
  }, {
    is_selected: 0,
    label: 'adoption status'
  }, {
    is_selected: 0,
    label: 'item association type'
  }, {
    is_selected: 0,
    label: 'projects'
  }, {
    is_selected: 0,
    label: 'workflows'
  }
  ]; // holds array of filters to populate as keys in dropdown on selecting more filters 

  // , {
  //   is_selected: 1,
  //   label: 'metadata set'
  // },
  // {
  //   is_selected: 1,
  //   label: 'taxonomies'
  // },

  selectedFilters = {
    'update on': false,
    'metadata set': true,
    taxonomies: true,
    'updated by': false,
    'adoption status': false,
    'item association type': false,
    projects: false,
    workflows: false
  }; // holds object which displayes selected filters
  taxonomies; // holds list of all taxonomies
  projects; // holds list of all projects
  loadedFilters = {}; // holds object to determine if filters are loaded
  dropdownServiceLoaded = false; // holds if drop down object is populated from service, used for loaders
  searchFiltersLoaded: Subscription;
  searchTriggered = false; // holds boolean if search button is clicked
  showFilters = true; // holds boolean to determine if filters is required to be displayed, used to toggle while reset search
  @Input() associationModalClose; // holds boolean to determine if modal closing is triggered or still in open state
  @Input() associationModalOpen;
  @Output() setStep: EventEmitter<any>; // holds the event which includes info for the step to toggle view on association modal
  @Output() updatedAssociationsObj: EventEmitter<any>;
  @Output() removeOptions: EventEmitter<any>; // holds event to remove options to copy
  @Output() settingsModalOpen: EventEmitter<any>; // holds boolean if settings modal is opened

  @Input() isCopy = false; // holds additional options to append or append with child nodes
  @Input() isCopyAssociation = false; // holds additional options to append or append with child nodes
  @Input() optionsToCopySaved = false; // holds flag save is clicked on modal for options to append or append with child nodes
  @Input() displayText = '';
  @Input() displayOption = '';
  @Input() currentTab;
  @ViewChild('acmtSearchList', { static: false }) acmtSearchList: AcmtSearchListComponent;

  dropdownDisplayObj = {}; // holds dropdown object to display based on selection of keys of more filter
  fixedFilters = ['taxonomies', 'metadata set']; // holds list of fixed filter keys

  constructor(private service: CommonService, private searchFilterDataService: SearchFilterDataService, private router: Router,
    private sharedService: SharedService) {
    this.setStep = new EventEmitter<any>();
    this.updatedAssociationsObj = new EventEmitter<any>();
    this.removeOptions = new EventEmitter<any>();
    this.settingsModalOpen = new EventEmitter<any>();

    this.searchFiltersLoaded = this.sharedService.searchFiltersLoaded.subscribe((item: any) => {
      if (item && item.loadedFilters) {
        this.loadedFilters = item.loadedFilters;
        this.dropdownObject = this.searchFilterDataService.createStaticDropdownObj();
        this.fillFixedFilters();
        if (this.loadedFilters['taxonomies'] === true && this.loadedFilters['metadata set'] === true) {
          this.dropdownServiceLoaded = true;
        }
      }
      if (item && item.key === 'taxonomies' && item.data) {
        this.taxonomies = item.data;
      }
      if (item && item.key === 'projects' && item.data) {
        this.projects = item.data;
      }
    });
  }

  ngOnInit() {
    this.searchFilterDataService.clearFilterArray();
    this.searchFilterDataService.getAllNodeTypes();
    this.searchFilterDataService.getProjects();
    this.searchFilterDataService.getUsers();
    this.searchFilterDataService.updateOn();
    this.searchFilterDataService.listTaxonomies();
    this.searchFilterDataService.fillAdoptionStatus();
    this.searchFilterDataService.getAssociationType();
    this.searchFilterDataService.getWorkFlowDetails();
    if (this.router.url.indexOf('taxonomy/list') !== -1 || this.router.url.indexOf('project/authoring') !== -1) {
      this.contextPage = 'taxonomy';
    } else if (this.router.url.indexOf('taxonomy/detail') !== -1) {
      this.contextPage = 'taxonomy_detail';
      // this.isAdvanceSearch = true;
    } else if (this.router.url.indexOf('project/list') !== -1) {
      this.contextPage = 'project';
    } else if (this.router.url.indexOf('project/detail') !== -1) {
      this.contextPage = 'project_detail';
      // this.isAdvanceSearch = true;
    } else if (this.router.url.indexOf('pacingguide/list') !== -1) {
      this.contextPage = 'pacingguide';
    }
    setTimeout(() => {
      this.dropdownObject = this.searchFilterDataService.createStaticDropdownObj();
      // this.dropdownServiceLoaded = true;

    }, 1500);
  }


  /* ---------Functionality to capture selected filters from dropdown functionality Start --------- */
  // generateFilterCriteria

  captureSelectedFilters(evt) {
    const flag = true;
    let filterCriteria = [];
    this.filterCriteria = {};
    Object.keys(this.dropdownObject).forEach(element => {
      filterCriteria = this.dropdownObject[element].filter(item => {
        if (item.is_selected === 1) {
          return item;
        }
      });
      if (filterCriteria.length > 0) {
        this.filterCriteria[element] = filterCriteria;
      }
    });
    if (flag) {
      // console.log('this.filterCriteria', JSON.stringify(this.filterCriteria));
      // this.filterObject.emit(this.filterCriteria);
    }
    this.collectFilterCriteria();
  }

  /* ---------Functionality to generate filter criteria functionality End --------- */


  /* ---------Search functionality Start --------- */

  search() {
    this.associationModalClose = false;
    this.searchTriggered = true;
    this.searchResult = [];
    this.updateFilterCriteria();
    const obj = {
      search_term: this.searchKeyword,
      search_optional_filters: this.searchOptionalFilters,
      context_page: this.contextPage,
    };
    this.saveSearchObj = obj;
    if (this.acmtSearchList) {
      this.acmtSearchList.deselectDocument(null);
    }

    // if (this.searchKeyword && this.searchKeyword.length > 2) {
    const url = GlobalSettings.SEARCH;
    if ((this.searchKeyword && this.searchKeyword.trim().length > 2) || this.searchOptionalFilters.length > 0) {
      this.service.postService(url, this.saveSearchObj).then((res: any) => {
        this.setStep.emit({ step: 3 });
        if (!this.associationModalClose) {
          this.showFilters = false;
          this.searchResult = [];
          // this.dataLoaded = true;
          if (res.hit.length > 0) {
            res.hit.forEach(element => {
              element.fields.unique_node_id = element.id;
              element.fields.isChecked = false;
              this.searchResult.push(element.fields);
            });
            setTimeout(() => {
              searchHeightCalculation();
            }, 500);
          } else {
            this.searchResult = [];
          }
          this.sharedService.searchResultEvent.next({
            searchData: this.searchResult,
          });
          this.searchTriggered = false;
        }
      }).catch(ex => {
        // this.showFilters = true;
        console.log('update user', ex);
      });


      // } else {
      //   console.log('Search text length < 3');
      // }
    } else {
      console.log('Search input is empty');
      this.searchResult = [];
      this.sharedService.searchResultEvent.next({
        searchData: this.searchResult,
      });
    }
    // this.advanceSearchToggle = false;
  }

  /* --------- Search functionality End --------- */



  /* ---------Functionality to update taxonomy id and project id if no project or taxonomy is selected start --------- */

  updatedProjectId() {
    let flag = false;
    this.searchOptionalFilters.forEach(element => {
      if (element.filter_type === 'project_id') {
        flag = true;
      }
    });
    if (!flag) {
      if (this.router.url.indexOf('project/detail') !== -1) {
        this.searchOptionalFilters.push({
          filter_type: 'project_id',
          filter_value: this.router.url.split('/').pop(),
          filter_key: ''
        });
      } else {
        this.projects.forEach(element => {
          this.searchOptionalFilters.push({
            filter_type: 'project_id',
            filter_value: element.project_id,
            filter_key: ''
          });
        });
      }
    }
  }



  updatedTaxonomyId() {
    let flag = false;
    this.searchOptionalFilters.forEach(element => {
      if (element.filter_type === 'document_id') {
        flag = true;
      }
    });
    if (!flag) {
      if (this.router.url.indexOf('taxonomy/detail') !== -1) {
        this.searchOptionalFilters.push({
          filter_type: 'document_id',
          filter_value: this.router.url.split('/').pop(),
          filter_key: ''
        });
      } else {
        this.taxonomies.forEach(element => {
          this.searchOptionalFilters.push({
            filter_type: 'document_id',
            filter_value: element.document_id,
            filter_key: ''
          });
        });
      }
    }
  }

  /* ---------Functionality to update taxonomy id and project id if no project or taxonomy is selected end--------- */



  /* --------- Functionality to append additional filters for search based on location start --------- */


  updateFilterCriteria() {
    if (this.router.url.indexOf('taxonomy/list') !== -1 || this.router.url.indexOf('taxonomy/detail') !== -1 ||
      this.router.url.indexOf('project/authoring') !== -1) {
      this.searchOptionalFilters.push({
        filter_type: 'type',
        filter_value: 'document'
      }, {
          filter_type: 'type',
          filter_value: 'item'
        });
      this.updatedTaxonomyId();
    } else if (this.router.url.indexOf('project/list') !== -1 || this.router.url.indexOf('project/detail') !== -1) {
      this.searchOptionalFilters.push({
        filter_type: 'type',
        filter_value: 'project'
      }, {
          filter_type: 'type',
          filter_value: 'item'
        }, {
          filter_type: 'type',
          filter_value: 'document'
        });

      this.updatedProjectId();
    } else if (this.router.url.indexOf('pacingguide/list') !== -1) {
      this.searchOptionalFilters.push({
        filter_type: 'type',
        filter_value: 'pacing_guide'
      }, {
          filter_type: 'type',
          filter_value: 'document'
        }, {
          filter_type: 'type',
          filter_value: 'item'
        });
    }
  }

  /* --------- Functionality to append additional filters for search based on location end --------- */


  /* --------- Functionality to iterate selected filters and store in flat array start --------- */


  collectFilterCriteria() {
    this.searchOptionalFilters = [];
    Object.keys(this.filterCriteria).forEach(element => {
      this.filterCriteria[element].forEach(item => {
        this.searchOptionalFilters.push(item);
      });
    });
    // this.collectAdvanceFilterCriteria(null);
    // this.search();
  }

  /* --------- Functionality to iterate selected filters and store in flat array end --------- */


  /* --------- Functionality to grab selected filter key and populate accordingly on screen start --------- */

  captureKeys(evt) {
    for (const i in this.moreFilterOptions) {
      if (i) {
        if (this.moreFilterOptions[i].is_selected === 1) {
          if (!this.selectedFilters[this.moreFilterOptions[i].label]) {
            this.updateFilters(this.moreFilterOptions[i].label);
          }
          this.selectedFilters[this.moreFilterOptions[i].label] = true;
        } else {
          this.clearSelection(this.moreFilterOptions[i].label);
          this.selectedFilters[this.moreFilterOptions[i].label] = false;
          delete this.dropdownDisplayObj[this.moreFilterOptions[i].label];
        }
      }
    }
    this.moreFilterClicked = false;
  }

  updateFilters(label) {
    for (const i in this.dropdownObject) {
      if (label === i) {
        this.dropdownDisplayObj[i] = this.dropdownObject[i];
        break;
      }
    }
  }

  fillFixedFilters() {
    if (this.associationModalOpen) {
      this.fixedFilters.forEach(label => {
        this.updateFilters(label);
      });
    }
  }

  /* --------- Functionality to grab selected filter key and populate accordingly on screen end --------- */


  /* --------- Functionality on click of remove filter button start --------- */

  removeFilter(key) {
    this.selectedFilters[key] = false;
    if (document.getElementById('clear-multiselect-' + key)) {
      document.getElementById('clear-multiselect-' + key).click();
    }
    if (document.getElementById('select-multiselect-' + key)) {
      document.getElementById('select-multiselect-' + key).click();
    }
    for (const i in this.moreFilterOptions) {
      if (i && this.moreFilterOptions[i].label === key) {
        this.moreFilterOptions[i].is_selected = 0;
        delete this.dropdownDisplayObj[key];
        break;
      }
    }
  }

  /* --------- Functionality on click of remove filter button end --------- */


  /* --------- Functionality on click of more filter button start --------- */

  moreFilters() {
    this.moreFilterClicked = true;
    setTimeout(() => {
      if (document.getElementById('btn-multiselect-keys')) {
        // document.getElementById('btn-multiselect-keys').click();
      }
    }, 500);

  }

  /* --------- Functionality on click of more filter button end --------- */


  /* --------- Functionality to reset search start --------- */

  resetSearch(evt) {
    this.searchKeyword = '';
    this.searchResult = null;
    this.sharedService.searchResultEvent.next({
      searchData: this.searchResult,
    });
    setTimeout(() => {
      this.moreFilterOptions.forEach(filter => {
        this.clearSelection(filter.label);
        if (filter.label === 'taxonomies' || filter.label === 'metadata set') {
          filter.is_selected = 1;
          this.selectedFilters[filter.label] = true;
        } else {
          filter.is_selected = 0;
          this.selectedFilters[filter.label] = false;
        }
      });
      this.clearSelection('taxonomies');
      this.clearSelection('metadata set');
    }, 25);
    this.showFilters = evt;
    this.filterVisibility = false;
    setTimeout(() => {
      this.filterVisibility = true;
      this.searchTriggered = false;
      this.moreFilterClicked = false;
      this.dropdownDisplayObj = {};
      this.fillFixedFilters();
      this.setStep.emit({ step: 1 });
    }, 50);
  }

  clearSelection(key) {
    const clearElement = 'clear-multiselect-' + key;
    const selectElement = 'select-multiselect-' + key;
    if (document.getElementById(clearElement)) {
      document.getElementById(clearElement).click();
    }
    if (document.getElementById(selectElement)) {
      document.getElementById(selectElement).click();
    }
  }
  /* --------- Functionality to reset search end --------- */


  /* --------- Functionality to emit current step to search component start --------- */

  setCurrentStep(evt) {
    this.setStep.emit(evt);
  }

  /* --------- Functionality to emit current step to search component end --------- */

  emitAssociationsObj(event) {
    this.updatedAssociationsObj.emit(event);
  }


  /* --------- Functionality to capture and emit event to remove or reset copy options start --------- */

  removeCopiedOptions(evt) {
    this.removeOptions.emit(evt);
  }

  /* --------- Functionality to capture and emit event to remove or reset copy options end --------- */

  /* --------- Functionality called on open of association append settings modal start --------- */

  selectOptions(event) {
    this.settingsModalOpen.emit(true);
  }

  /* --------- Functionality called on open of association append settings modal end --------- */

  /* --------- Functionality on click of close for more filter start --------- */

  removeMoreFilter() {
    this.moreFilterClicked = false;
  }

  /* --------- Functionality on click of close for more filter end --------- */

}



