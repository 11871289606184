import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';
import { Router, ActivatedRoute } from '@angular/router';
import Utils from '../../utils';
import { GlobalSettings } from '../../global.settings';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./../../nav-bar/nav-bar-logo-img.scss']
})
export class AcceptInviteComponent implements OnInit {
  orgName = null;
  token = null;
  orgId = null;
  userAlreadyRegistered = false;
  loading = false;
  isSSOUser = GlobalSettings.SSO_USER;

  constructor(private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute, private sharedService: SharedService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if ((params['org_code'] !== undefined) && (params['active_access_token'] !== undefined)
        && (params['organization_id'] !== undefined)) {
        const obj = {
          'org_code': params['org_code'],
          'organization_id': params['organization_id']
        };
        this.token = params['active_access_token'];
        this.orgId = params['organization_id'];
        this.commonService.setOrgDetails(obj);
        localStorage.setItem('access_token', Utils.getCookie('central_auth_token'));
        // localStorage.setItem('access_token', this.token);
        this.checkUserStatus();
      } else {
        this.goToLogin(false);
      }
    });
  }

  goToLogin(resetPassword: boolean) {
    const org = this.commonService.getOrgDetails();
    if (org) {
      const path = org + '/' + Utils.ROUTE_LOGIN;
      if (resetPassword) {
        if (this.isSSOUser) {
          const orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
          const unifiedpath = GlobalSettings.UNIFIED_FORGOT_PASSWORD; // + '/' + orgCode;
          window.location.href = unifiedpath;
        } else {
          this.router.navigate([path, 'reset_password']);
        }
      } else {
        // this.router.navigate([path]);
        if (this.isSSOUser) {
          const orgCode = window.location.hash.substr(6, 5);
          const unifiedpath = GlobalSettings.UNIFIED_LOGIN + '/' + orgCode;
          window.location.href = unifiedpath;
        } else {
          // const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_LOGIN;
          this.router.navigate([path]);
        }
      }
    }
  }

  /**
   * To register user and check already registered user
   */
  checkUserStatus() {
    this.loading = true;
    let url = GlobalSettings.USER_ORGANIZATION_REGISTRAION_STATUS + '/' + this.token + '/'
      + this.orgId + '?is_check=1';
    this.commonService.putServiceWithouToken(url, '').then((res: any) => {
      console.log(res);
      this.orgName = res['user_org']['organization_name'];

      if (res.user_status === 'inactive') { // User is not registered if inactive
        this.userAlreadyRegistered = false;
        url = GlobalSettings.USER_ORGANIZATION_REGISTRAION_STATUS + '/' + this.token + '/'
          + this.orgId + '?is_check=0';
        this.commonService.putServiceWithouToken(url, '').then((res1: any) => {
          // User registered successfully
          this.orgName = res1.organization_name;
          this.loading = false;
        }).catch((ex) => {
          console.log('checkUserStatus Ex', ex);
          this.loading = false;
          this.sharedService.sucessEvent.next({
            customMsg: ex.msg,
            type: 'error'
          });
        });

      } else { // User is already registered if active
        this.loading = false;
        this.userAlreadyRegistered = true;
      }
    }).catch((ex) => {
      console.log('checkUserStatus Ex', ex);
      this.loading = false;
      this.sharedService.sucessEvent.next({
        customMsg: ex.msg,
        type: 'error'
      });
    });
  }
}
