import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import {
  GlobalSettings
} from '../../global.settings';
import {
  TreeDataService
} from '../../tree-data.service';
import Utils from '../../utils';
import {
  CommonService
} from '../../common.service';
import {
  SharedService
} from '../../shared.service';
import {
  TabularTreeSidePaneComponent
} from '../tabular-tree-side-pane/tabular-tree-side-pane.component';
import {
  ProjectMappedNodeService
} from '../../project-mapped-node.service';
import {
  Subscription
} from 'rxjs/Subscription';

@Component({
  selector: 'app-tabular-tree-view',
  templateUrl: './tabular-tree-view.component.html',
  styleUrls: ['./../tree/tree.component.scss']
})
export class TabularTreeViewComponent implements OnInit, OnChanges, OnDestroy {

  // @Input() items;
  items; // object used in rendering table
  taxonomyId; // holds the selected taxonomy id
  orgId; // holds organisation id
  rawTreeData; // holds tree object from details view
  dataReceived = false; // holds info if data is received from details to set table loader as false
  addedAdditionalMetadata; // holds additional metadata information for a node
  detailUrl = ''; // holds details url based on tree location
  withTocken = false; // holds boolean value to determine if get service needs to hit with tocken or not
  showComments = false; // holds boolean value if comments icon needs to be visible
  dataWithAllComments; // holds complete object re assigned from raw tree object
  projectComments = null;
  @Input() isEditable = true;
  @Input() selectedNodeId; // holds the current selected node id
  @Input() showView; // holds boolean value to show hide table view on tab switch
  @ViewChild('tabularTreeSidePaneComponent', { static: false }) tabularTreeSidePaneComponent: TabularTreeSidePaneComponent;
  @Input() listOfColumn;
  @Input() assignedToMe;
  @Input() listOfClass;
  @Input() dynamicClass;
  @Input() isAllNode = false;
  @Input() showNavigation = true;
  @Input() searchText = ''; // holds user input text to search in tabular tree view
  @Input() searchResultList = []; // holds search result nodes in tree
  @Input() isFilterApplied = false; // holds search result nodes in tree
  @Input() isReset = false; // holds flag for reset tree on clear filter or search
  charLimit = 120; // holds character limit to trim HC and FS
  filenameCharlmt = 20; // holds character limit to trim file name
  firstLoad = false; // boolean flag to determine if the component is loaded first time, used to prevent hitting details api multiple times
  detailsData; // holds the response from details API
  openMode = false; // holds boolean value of right side panel to be opened in Readonly(false) or Edit(true) mode
  clicked;
  filterList = {};
  @Input() showUserFriendlyPage = false;
  worker: any;
  selectedNode: any;
  headerHeight = "30px";
  windowResizeSubscription: Subscription;
  tabelWidth;

  constructor(
    private route: ActivatedRoute, private service: CommonService,
    private sharedService: SharedService, private router: Router) {
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
      this.route.params.subscribe(params => {
        this.taxonomyId = params.id;
        this.onTaxonomySelected(params.id);
      });
    }

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      if (event.url.indexOf('/taxonomies') >= 0) {
        setTimeout(() => {
          if (document.getElementById('Taxonomies-button')) {
            const element = document.getElementById('Taxonomies-button');
            element.className += ' active';
          }
        }, 100);
      }
    });


    this.windowResizeSubscription = this.sharedService.windowResizeEvent.subscribe((item: any) => {
      if (item) {
        this.tabularViewHeightCalculation();
        this.columnHeaderWidthCalculation();
        if (this.showView) {
          this.widthCalculation();
        }
      }
    });


  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.assignedToMe) {
      this.onChangeCommentAssigned();
    }
    if (this.rawTreeData && changes.searchResultList && changes.searchResultList.currentValue
      && changes.searchResultList.currentValue.length) {


      // if (typeof Worker !== 'undefined') {
      //   this.worker = new Worker('../../treeService.worker', { type: 'module' });
      // }
      // if (this.worker) {
      //   this.worker.onmessage = ({ data }) => {
      //     this.items = [];
      //     if (data) {
      //       this.setTableData(data.taxonomyData);
      //     }
      //     console.log('data received from worker in tree', this.items);
      //   };
      //   console.log('ja jachche', this.items);
      //   this.worker.postMessage({
      //     data: this.items,
      //     location: 'utils',
      //     parameters: {
      //       searchResultList: this.searchResultList,
      //       propName: 'isSearchResult',
      //       isMultipleCall: true
      //     }
      //   });
      // }

      this.searchResultList.forEach(node => {
        if (this.rawTreeData instanceof Array) {
          Utils.expandTreeTillSelectedNode(node, this.rawTreeData[0], 'isSearchResult');
        } else {
          Utils.expandTreeTillSelectedNode(node, this.rawTreeData.children[0], 'isSearchResult');
        }
      });

    }

    if (this.listOfColumn && this.listOfColumn.length) {
      setTimeout(() => {
        this.widthCalculation();
      }, 1000);
    }
  }


  /* --------- Set Detail Url and check location on taxonomy detail page load started --------- */

  onTaxonomySelected(id) {
    this.detailUrl = '';
    if (this.router.url.indexOf('/taxonomies') >= 0) {
      this.detailUrl = GlobalSettings.FRONT_FACE_TAXONOMY_DETAIL + this.taxonomyId + '?type=details&organization_id=' + this.orgId;
      this.withTocken = false;
      this.showComments = false;
    } else if (this.router.url.indexOf('taxonomy/detail') >= 0) {
      this.detailUrl = GlobalSettings.GET_TREE_DETAILS_ENHANCED + id;
      this.withTocken = true;
      this.showComments = false;
    } else if (this.router.url.indexOf('project/detail') >= 0) {
      this.detailUrl = GlobalSettings.GET_PROJECT_TREE_DETAILS_ENHANCED + id;
      this.withTocken = true;
      this.showComments = true;
    }
  }

  /* --------- Set Detail Url and check location on taxonomy detail page load end --------- */


  /* --------- Call Details Function and set tree object for view start --------- */

  setTableData(data) {
    console.log('setTableData is called');
    if (this.rawTreeData) {
      this.dataReceived = true;
    }
    this.rawTreeData = data;
    if (this.rawTreeData) {
      const rawTreeDataWithoutParent = [];
      if (!this.rawTreeData.children && this.rawTreeData.length > 1) {
        // rawTreeDataWithoutParent.push({ id: '', children: [] });
        this.rawTreeData.forEach(item => {
          // rawTreeDataWithoutParent.push(item.children[0]);
          if (item.children && item.children.length) {
            item.children.forEach(child => {
              rawTreeDataWithoutParent.push(child);
            });
          }
        });
      }

      this.dataWithAllComments = this.rawTreeData.children ? this.rawTreeData.children :
        (this.rawTreeData.length === 1 ? this.rawTreeData[0].children : rawTreeDataWithoutParent);
      this.dataReceived = true;
      this.onChangeCommentAssigned();
    }
    if (!this.firstLoad) {
      this.getSelectedTreeItemDetail();
    }
  }

  /* --------- Call Details Function and set tree object for view end --------- */



  /* --------- Fetch Details and update the object functionality start --------- */

  getSelectedTreeItemDetail() {
    if (!this.withTocken) {
      this.service.getUserServiceDataWithoutTocken(this.detailUrl).then((res: any) => {
        this.firstLoad = true;
        this.detailsData = res['details'];
        if (this.rawTreeData) {
          this.setDetailData(this.detailsData);
        }
      }).catch((ex) => {
        this.dataReceived = true;
        this.sharedService.sucessEvent.next({
          type: 'cache_detail_error'
        });
        console.log('getSelectedTreeItemDetail front facing taxonomy ', ex);
      });
    } else {
      this.service.getServiceData(this.detailUrl).then((res: any) => {
        this.firstLoad = true;
        this.detailsData = res;
        if (this.rawTreeData) {
          this.setDetailData(this.detailsData);
        }
      }).catch((ex) => {
        this.dataReceived = true;
        this.sharedService.sucessEvent.next({
          type: 'cache_detail_error'
        });
        console.log('getSelectedTreeItemDetail front facing taxonomy ', ex);
      });
    }
  }


  setDetailData(detailData) {
    if (this.showView) {
      this.widthCalculation();
    }
    for (const j in detailData) {
      if (detailData[j]) {
        // tslint:disable-next-line:max-line-length
        this.setDetail(detailData[j], this.rawTreeData['children'] ? this.rawTreeData['children'] : this.rawTreeData[0]['children']);
      }
    }
  }

  setDetail(data, nodes) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i]) {
        if (nodes[i].id === data['item_id']) {
          nodes[i]['itemAssets'] = data['assets'];
          nodes[i]['itemExemplars'] = data['exemplar_associations'];
          nodes[i]['itemAssociations'] = data['item_associations'];
          delete data['assets'];
          delete data['exemplar_associations'];
          delete data['item_associations'];
          if (!this.withTocken) {
            Object.assign(nodes[i], data);
            this.addedAdditionalMetadata = [];
            if (data.custom_metadata) {
              data.custom_metadata.forEach(obj => {
                if (obj.is_additional_metadata === 1) {
                  this.addedAdditionalMetadata.push(obj);
                  nodes[i]['addedAdditionalMetadata'] = this.addedAdditionalMetadata;
                }
              });
            }
          }
          break;
        }
        if (nodes[i].children && nodes[i].children.length > 0) {
          this.setDetail(data, nodes[i].children);
        }
      }
    }
  }

  /* --------- Fetch Details and update the object functionality end --------- */



  /* --------- Functionality to open drawer on set data start --------- */

  openDetails(item, editable) {
    this.openMode = editable;
    this.selectedNode = item;
    this.setEditable(editable);
    if (this.router.url.indexOf('/taxonomies') >= 0) {
      if (this.tabularTreeSidePaneComponent) {
        this.tabularTreeSidePaneComponent.setData(item);
        this.tabularTreeSidePaneComponent.showHideNav(true);
      }
    } else {
      this.getSelectedItemDrawerDetail(item);
    }
    setTimeout(() => {
      this.sharedService.treeNodeSelectedEvent.next(item);
    }, 50);
  }

  /* --------- Functionality to open drawer on set data end --------- */


  /* --------- Functionality to set data for drawer for taxonomies after login start --------- */

  getSelectedItemDrawerDetail(item) {
    let url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + item.id;
    if (item['is_document'] === 1) {
      url = GlobalSettings.GET_CFDOC_ITEM_DETAIL + '/' + item.id;
    } else {
      url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + item.id;
    }
    this.service.getServiceData(url).then((res: any) => {
      const newObject = {};
      delete Object.assign(newObject, res, {
        ['itemExemplars']: res['exemplar_associations']
      })['exemplar_associations'];
      delete Object.assign(newObject, res, {
        ['itemAssets']: res['assets']
      })['assets'];
      delete Object.assign(newObject, res, {
        ['itemAssociations']: res['item_associations']
      })['item_associations'];
      this.addedAdditionalMetadata = [];
      if (res.custom_metadata) {
        res.custom_metadata.forEach(obj => {
          if (obj.is_additional_metadata === 1) {
            this.addedAdditionalMetadata.push(obj);
            newObject['addedAdditionalMetadata'] = this.addedAdditionalMetadata;
          }
        });
      }
      if (this.tabularTreeSidePaneComponent) {
        this.tabularTreeSidePaneComponent.setData(newObject);
        this.tabularTreeSidePaneComponent.showHideNav(true);
      }

    }).catch((ex) => {
      console.log('getSelectedTreeItemDetail ', ex);
    });
  }

  /* --------- Functionality to set data for drawer for taxonomies after login end --------- */


  /* --------- Functionality to set padding for table nodes start --------- */

  calculatePadding(level) {
    return Utils.calculatePadding(level, 'treeview');
  }

  /* --------- Functionality to set padding for table nodes end --------- */


  navigate(id) {
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_AUTHORING;
    localStorage.setItem('project_type', '1');
    this.router.navigate([path, this.taxonomyId, id]);
  }


  /* --------- Functionality to set comments start --------- */

  onChangeCommentAssigned() {
    if (this.assignedToMe === 'assignedToMe') {
      this.getProjectComments(this.taxonomyId); // comment icon setting for 'comments assigned to me'
    } else { // comment icon setting for 'open commented node'
      if (this.dataWithAllComments) {
        this.items = this.dataWithAllComments;
        this.tabularViewHeightCalculation();
        this.columnHeaderWidthCalculation();
      }
    }

  }


  getProjectComments(projectId) {

    if (projectId) {
      const url = GlobalSettings.PROJECT_COMMENTS + '/' + projectId;
      this.service.getServiceData(url).then((res: any) => {
        if (res) {
          this.projectComments = null;
          this.projectComments = JSON.parse(JSON.stringify(res));
          for (let i = 0; i < this.dataWithAllComments.length; i++) {
            this.setMyCommentForAllNode(this.dataWithAllComments[i], Utils.EXPAND_LEVEL);
          }
        }
        this.items = this.dataWithAllComments;
        this.tabularViewHeightCalculation();
        this.columnHeaderWidthCalculation();
      }).catch(ex => {
        console.log('getProjectComments ex: ', ex);
      });
    }
  }


  setMyCommentForAllNode(data: any, expand) {
    if (data) {
      if (data.children.length > 0) {
        // data.expand = expand;
        data.isCommented = this.isCommentAvailableForItem(data.id);
        for (let i = 0; i < data.children.length; i++) {
          this.setMyCommentForAllNode(data.children[i], expand);
        }
      } else {
        // data.expand = expand;
        data.isCommented = this.isCommentAvailableForItem(data.id);
      }
    }
  }


  isCommentAvailableForItem(itemId) {
    let flag = false;
    if (itemId) {
      for (let i = 0; i < this.projectComments.length; i++) {
        if ((itemId === this.projectComments[i].thread_source_id) &&
          (this.projectComments[i].assign_to_id === localStorage.getItem('user_id'))) {
          flag = true;
          break;
        } else { }
      }
    }
    return flag;
  }

  /* --------- Functionality to set comments end --------- */

  setEditable(editable) {
    this.tabularTreeSidePaneComponent.setEditable(editable);
  }

  onNextNavigationClick(event: any) {
    this.selectNodeOnNavigation(event.node);
  }

  onPreviousNavigationClick(event: any) {
    this.selectNodeOnNavigation(event.node);
  }

  selectNodeOnNavigation(node: any) {
    if (this.rawTreeData instanceof Array) {
      for (let i = 0; i < this.rawTreeData.length; i++) {
        Utils.expandTreeTillSelectedNode(node, this.rawTreeData[i]);
      }
    } else {
      Utils.expandTreeTillSelectedNode(node, this.rawTreeData);
    }
    setTimeout(() => {
      const id = node['id'] ? node['id'] : node['item_id'];
      if (document.getElementById(id + '-node')) {
        document.getElementById(id + '-node').focus();
      }
    }, 100);
    this.openDetails(node, this.openMode);
  }

  getFilterList(columnList, dataSet, changes) {
    columnList.forEach(col => {
      if (col.canFilter && (!changes.columnList || !this.filterList[col['propName']])) {
        this.filterList[col['propName']] = [];
        dataSet.forEach(data => {
          if (data[col['propName']] || data[col['propName']] === 0) {
            data[col['propName']].toString().split(', ').forEach(element => {
              if (!this.filterList[col['propName']].find((obj) => element.trim() === (obj.label))) {
                this.filterList[col['propName']].push({
                  colName: col['propName'],
                  label: element.trim(),
                  is_selected: 0
                });
              }
            });
          }

          if (this.filterList[col['propName']].length > 1) {
            col['showFilter'] = true;
          } else {
            col['showFilter'] = false;
          }
        });
        if (this.filterList[col['propName']].length) {
          Utils.sortDataArray(this.filterList[col['propName']], 'label', false, true);
        }
      }
    });

  }

  checkVisibility(itemArr: any) {
    if (itemArr.some(item => item.isSearchResult)) {
      return true;
    } else {
      return false;
    }

  }

  resetTree(treeData) {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        treeData = null;
        if (data) {
          treeData = data.taxonomyData;
          console.log('treedata from worker in tabular tree view', treeData);
          this.setTableData(treeData);
        }
      };
      this.worker.postMessage({
        data: treeData,
        location: 'utils',
        parameters: {
          level: 1,
          reset: true
        }
      });
    }
  }


  tabularViewHeightCalculation() {
    if (document.getElementById('nodeTypeTableContainer') && document.getElementById('nodeTypeTableContainer').style.height) {
      document.getElementById('nodeTypeTableContainer').style.height = '0px';
    }
    const windowHeight = window.innerHeight;
    let tabHeight;
    let titleHeight;
    if (document.getElementById('treeTabContainer') && document.getElementById('tabContainer')) {
      tabHeight = document.getElementById('treeTabContainer').offsetHeight + document.getElementById('tabContainer').offsetHeight;
    }
    if (document.getElementsByClassName('authoring-header') && document.getElementsByClassName('authoring-header')[0]) {
      titleHeight = document.getElementsByClassName('authoring-header')[0].clientHeight;
    }
    // tslint:disable-next-line:max-line-length
    const protipHeight = document.getElementById('taxonomyProtip') && document.getElementById('taxonomyProtip').offsetHeight ? document.getElementById('taxonomyProtip').offsetHeight : 0;
    const extraHeight = 65;
    const panelHeight = windowHeight - (tabHeight + titleHeight + protipHeight + extraHeight + Utils.navBarHeight);

    // document.getElementById('right_container_id').style.height = panelHeight + 'px';
    if (document.getElementById('metadataTableContainer')) {
      document.getElementById('metadataTableContainer').style.height = panelHeight + 'px';
    }
  }

  ngOnDestroy() {
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
  }


  columnHeaderWidthCalculation() {
    setTimeout(() => {
      if (document.getElementById("tablePanel")) {
        var x = document.getElementById("tablePanel");
        this.headerHeight = x.clientHeight + 'px';
        if (document.getElementsByClassName('card-header') && document.getElementsByClassName('card-header')[0]) {
          (document.getElementsByClassName('card-header')[0] as HTMLElement).style.marginTop = (x.clientHeight - 22) + 'px';
        }
      }
    }, 1000);
  }
  widthCalculation() {
    let widthAllColums = 0;
    this.listOfColumn.forEach(element => {
      if (element.width) {
        widthAllColums = widthAllColums + parseInt(element.width);
      } else {
        const elem = document.getElementById(element.internal_name);
        if (elem) {
          widthAllColums = widthAllColums + elem.offsetWidth;
        }
      }
    });
    this.tabelWidth = widthAllColums;
    console.log('tabelWidth', this.tabelWidth);
  }

  /* --------- Functionality to emit the current selected node object start --------- */

  onNodeSelected(item) {
    if (!item.isOrphanLabel) {
      if (item.id) {
        this.selectedNodeId = item.id;
      }
      // if (item.item_id) {
      //   this.selectedNodeId = item.item_id;
      // }
      // item.location = this.viewLocation;
      this.sharedService.treeNodeSelectedEvent.next(item);
    }
  }

  /* --------- Functionality to emit the current selected node object end --------- */


  /* --------- Functionality to add focus on selected node on close of side pane start --------- */

  updateFocus(closeStatus) {
    if (closeStatus && document.getElementById(this.selectedNode.id + '-node')) {
      document.getElementById(this.selectedNode.id + '-node').focus();
    }
  }

  /* --------- Functionality to add focus on selected node on close of side pane end --------- */

}
