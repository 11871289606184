import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import {
  Params,
  ActivatedRoute,
  Router
} from '@angular/router';
import { SharedService } from '../../shared.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import Utils from '../../utils';

@Component({
  selector: 'app-notify-updates',
  templateUrl: './notify-updates.component.html',
  styleUrls: ['./notify-updates.component.scss']
})
export class NotifyUpdatesComponent implements OnInit, OnDestroy {

  PROJECT_CATEGORY_VALUE = 1;
  COMMENT_CATEGORY_VALUE = 2;
  PUBLISHED_TAXONOMY_CATEGORY_VALUE = 3;
  PUBLISHED_PACINGGUIDE_CATEGORY_VALUE = 4;
  ACCESS_CATEGORY_VALUE = 5;
  PUBLIC_REVIEW_CATEGORY_VALUE = 6;
  TAXONOMY_UPLOADED_CATEGORY_VALUE = 7;
  COMMENTS_ASSIGNED_TO_ME_VALUE = 8;
  TAXONOMY_EXPORTED_CATEGORY_VALUE = 12;
  TAXONOMY_NEW_VERSION_CATEGORY_VALUE = 13;
  TAXONOMY_UPDATE_CATEGORY_VALUE = 14;
  TAXONOMY_EXPORT_FAILED_CATEGORY_VALUE = 15;

  listOfColumn_updates = [ // table column names for notification updates tab
    {
      name: 'Description',
      propName: 'description',
      class: '',
      type: 'link',
      redirect: false,
      modal: '#notification-details'
    },
    {
      name: 'User',
      propName: 'user_name',
      class: '',
      type: 'text'
    },
    {
      name: 'Created At',
      propName: 'created_at',
      class: '',
      type: 'text'
    },
    {
      name: 'Updated At',
      propName: 'updated_at',
      class: '',
      type: 'text'
    },
    {
      name: 'Read Status',
      propName: 'read_status',
      class: '',
      type: 'text'
    }
  ];
  notificationList = [];
  filteredNotificationList; // holds filtered notification list based on category
  selectedNotificationCategory: any = 'all'; // Default notification category is 'all'
  notificationCategoryList = [ // holds notification category list
    {
      name: 'All',
      value: 'all'
    },
    {
      name: 'Project',
      value: this.PROJECT_CATEGORY_VALUE
    },
    {
      name: 'Comment',
      value: this.COMMENT_CATEGORY_VALUE
    },
    {
      name: 'Comments Assigned To Me',
      value: this.COMMENTS_ASSIGNED_TO_ME_VALUE
    },
    {
      name: 'Published Taxonomy',
      value: this.PUBLISHED_TAXONOMY_CATEGORY_VALUE
    },
    {
      name: 'Published Pacing Guide',
      value: this.PUBLISHED_PACINGGUIDE_CATEGORY_VALUE
    },
    {
      name: 'Access',
      value: this.ACCESS_CATEGORY_VALUE
    },
    {
      name: 'Public Review',
      value: this.PUBLIC_REVIEW_CATEGORY_VALUE
    },
    {
      name: 'Imported Taxonomy',
      value: this.TAXONOMY_UPLOADED_CATEGORY_VALUE
    },
    {
      name: 'Exported Taxonomy',
      value: this.TAXONOMY_EXPORTED_CATEGORY_VALUE
    },
    {
      name: 'New Version of Taxonomy',
      value: this.TAXONOMY_NEW_VERSION_CATEGORY_VALUE
    },
    {
      name: 'Updated Taxonomy',
      value: this.TAXONOMY_UPDATE_CATEGORY_VALUE
    },
    {
      name: 'Failed To Export Taxonomy',
      value: this.TAXONOMY_EXPORT_FAILED_CATEGORY_VALUE
    }
  ];
  selectedNotification = null;
  categoryName;
  showPageRedirectBtn = false;
  loadingNotification = false;
  @Output() updateCountEvent: EventEmitter<any> = new EventEmitter<any>();
  /*startIndex = 0;
  lastIndex = Utils.PAGINATION_LOWER_LIMIT;
  minItemPerPage;*/

  constructor(
    private service: CommonService,
    private router: Router,
    private sharedService: SharedService,
    private acivatedRoute: ActivatedRoute
  ) {
    this.acivatedRoute.params.subscribe((param: Params) => {
      if (param.type) {
        if (param.type === 'comments_created_by_me') {
          this.selectedNotificationCategory = this.COMMENT_CATEGORY_VALUE;

        }
        if (param.type === 'comments_assigned_to_me') {
          this.selectedNotificationCategory = this.COMMENTS_ASSIGNED_TO_ME_VALUE;

        }
      }
    });
    // this.minItemPerPage = Utils.PAGINATION_LOWER_LIMIT;
  }

  ngOnInit() {

    this.getNotificationList();
    if (this.sharedService.newNotificationEvent && this.sharedService.newNotificationEvent.closed === true) {
      this.sharedService.newNotificationEvent = new BehaviorSubject(false);
    }

    try {
      // If new notifications comes, call notification list api
      this.sharedService.newNotificationEvent.subscribe((event: any) => {
        if (event === true) { // true for new notification
          this.getNotificationList();
        }
      });
    } catch (err) { }
  }

  ngOnDestroy() {
    if (this.sharedService.newNotificationEvent) { // unsubscribe notification event
      this.sharedService.newNotificationEvent.unsubscribe();
    }
  }

  getNotificationList() {
    const url = GlobalSettings.NOTIFICATION_LIST;
    this.loadingNotification = true;
    this.service.getServiceData(url).then((res: any) => {
      this.notificationList = res;
      this.updateCountEvent.emit(this.notificationList.length);
      if (this.notificationList.length > 0) {
        Utils.sortDataArray(this.notificationList, 'updated_at', false, false);
        // tslint:disable-next-line: max-line-length
        const versionNotificationList = this.notificationList.filter(noti => noti.notification_category === this.TAXONOMY_NEW_VERSION_CATEGORY_VALUE);
        // if (versionNotificationList && versionNotificationList.length) {
        this.sharedService.notificationListEvent.next(this.notificationList);
        // }
        this.notificationList.forEach(elem => {
          (elem.read_status === 0) ? elem.read_status = 'Unread' : elem.read_status = 'Read';
          elem.showOptions = true;
          elem.isView = true;
          elem.date = Utils.getDateWithoutTime(elem.updated_at);
        });
      }
      this.getFilteredNotifications();
      this.loadingNotification = false;
    }).catch((ex) => {
      console.log('Notification List Ex', ex);
    });
  }

  /**
   * To mark unread notifications as read
   */
  markNotificationAsRead() {
    const url = GlobalSettings.NOTIFICATION_READ_MARK;
    this.service.postService(url, {}).then((res: any) => {
      console.log('Notification Mark As Read ', res);
      this.getNotificationList();
    }).catch((ex) => {
      console.log('Notification Mark As Read Ex', ex);
    });
  }

  /**
   * To filter notification list based on category
   */
  getFilteredNotifications() {
    this.filteredNotificationList = [];
    if (this.selectedNotificationCategory === 'all') {
      this.filteredNotificationList = Utils.getFilteredArray(this.notificationList, 'date');
    } else {
      this.notificationList.forEach(data => {
        if (this.selectedNotificationCategory === data.notification_category) {
          this.filteredNotificationList.push(data);
        }
      });
      this.filteredNotificationList = Utils.getFilteredArray(this.filteredNotificationList, 'date');
    }
    console.log('Notification List', this.filteredNotificationList);
  }

  /**
   * To find category name by category value
   * @param value (category value)
   */
  findCategoryNameByValue(value) {
    let name = null;
    for (let i = 0; i < this.notificationCategoryList.length; i++) {
      if (this.notificationCategoryList[i].value === value) {
        name = this.notificationCategoryList[i].name;
        break;
      }
    }
    return name;
  }

  onCancel() {
    this.selectedNotification = null;
    this.categoryName = '';
  }

  /**
   * To show/hide view button (page redirection)
   * @param data (selected notification)
   */
  showViewButton(data) {
    const categoryValue = data.notification_category;
    this.showPageRedirectBtn = false;
    this.categoryName = this.findCategoryNameByValue(data.notification_category);

    if (categoryValue === this.PROJECT_CATEGORY_VALUE || categoryValue === this.COMMENT_CATEGORY_VALUE ||
      categoryValue === this.COMMENTS_ASSIGNED_TO_ME_VALUE || categoryValue === this.PUBLISHED_TAXONOMY_CATEGORY_VALUE ||
      categoryValue === this.ACCESS_CATEGORY_VALUE || categoryValue === this.PUBLIC_REVIEW_CATEGORY_VALUE ||
      categoryValue === this.TAXONOMY_UPDATE_CATEGORY_VALUE || categoryValue === this.TAXONOMY_UPLOADED_CATEGORY_VALUE ||
      categoryValue === this.TAXONOMY_NEW_VERSION_CATEGORY_VALUE || categoryValue === this.TAXONOMY_EXPORT_FAILED_CATEGORY_VALUE) {
      this.showPageRedirectBtn = true;
      if (categoryValue === this.ACCESS_CATEGORY_VALUE && (data.target_context.access_status !== 2)) {
        // access_status=2 indicates accepted project request
        this.showPageRedirectBtn = false;
      }
      if ((categoryValue === this.COMMENT_CATEGORY_VALUE || categoryValue === this.COMMENTS_ASSIGNED_TO_ME_VALUE)
        && (!data.target_context.project_id || !data.target_context.item_id)) {
        // access_status=2 indicates accepted project request
        this.showPageRedirectBtn = false;
      }
      if ((categoryValue === this.PUBLIC_REVIEW_CATEGORY_VALUE || categoryValue === this.PUBLISHED_TAXONOMY_CATEGORY_VALUE ||
        categoryValue === this.TAXONOMY_UPLOADED_CATEGORY_VALUE) && !data.target_context.document_id) {
        // access_status=2 indicates accepted project request
        this.showPageRedirectBtn = false;
      }
    }
    return this.showPageRedirectBtn;
  }

  /**
   * Redirect to respective page from selected notification
   */
  goToPage(data) {
    console.log(data);
    this.selectedNotification = data;
    if (this.selectedNotification) {
      if ((this.selectedNotification.notification_category === this.PROJECT_CATEGORY_VALUE ||
        this.selectedNotification.notification_category === this.ACCESS_CATEGORY_VALUE)
        && this.selectedNotification.target_context.project_id) { // project and access
        // this.router.navigate(['./app/project/detail', this.selectedNotification.target_context.project_id]);
        const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_DETAIL;
        this.router.navigate([path, this.selectedNotification.target_context.project_id]);

      } else if ((this.selectedNotification.notification_category === this.COMMENT_CATEGORY_VALUE
        || this.selectedNotification.notification_category === this.COMMENTS_ASSIGNED_TO_ME_VALUE) &&
        this.selectedNotification.target_context.project_id && this.selectedNotification.target_context.item_id) { // comment
        // this.router.navigate(['/app/project/authoring', this.selectedNotification.target_context.project_id,
        //   this.selectedNotification.target_context.item_id], { queryParams: { showCommentPopup: true } });
        const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_AUTHORING;
        this.router.navigate([path, this.selectedNotification.target_context.project_id,
          this.selectedNotification.target_context.item_id], { queryParams: { showCommentPopup: true } });

      } else if ((this.selectedNotification.notification_category === this.PUBLISHED_TAXONOMY_CATEGORY_VALUE ||
        this.selectedNotification.notification_category === this.PUBLIC_REVIEW_CATEGORY_VALUE ||
        this.selectedNotification.notification_category === this.TAXONOMY_UPLOADED_CATEGORY_VALUE ||
        this.selectedNotification.notification_category === this.TAXONOMY_UPDATE_CATEGORY_VALUE ||
        this.selectedNotification.notification_category === this.TAXONOMY_EXPORT_FAILED_CATEGORY_VALUE ||
        this.selectedNotification.notification_category === this.TAXONOMY_NEW_VERSION_CATEGORY_VALUE) &&
        this.selectedNotification.target_context.document_id) { // published taxonomy and public review

        const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_DETAIL + '/' +
          this.selectedNotification.target_context.document_id;
        this.router.navigate([path], {
          queryParams: {
            needToRetry: this.selectedNotification.notification_category === this.TAXONOMY_EXPORT_FAILED_CATEGORY_VALUE
          }
        });

      }
    }
  }

  changeDateToLocalTimeZone(date) {
    return Utils.changeDateToLocalTimeZone(date);
  }

  /*showDataInRange(event) {
    this.startIndex = event.start - 1;
    this.lastIndex = event.last;
  }*/

}
