import { Component, OnInit } from '@angular/core';
import {
  CommonService
} from '../common.service';
import {
  GlobalSettings
} from '../global.settings';
import {
  ActivatedRoute,
  Params,
  Router
} from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./../nav-bar/nav-bar-logo-img.scss']
})
export class ConfirmationComponent implements OnInit {

  active_access_token: any;
  email_id: any;
  showMsg = '';
  enableProceed = true;
  constructor(private activatedRoute: ActivatedRoute, private service: CommonService, private router: Router, private sharedService: SharedService) {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] !== undefined) {
        this.active_access_token = params['id'];
        this.getUserDetail();
      } else {
        console.log('Route param is blank');
      }
      console.log(' tocken ', this.active_access_token);
    });

  }

  ngOnInit() {
  }

  getUserDetail() {

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] !== undefined) {
        this.active_access_token = params['id'];
      } else {
        console.log('Route param is blank');
      }

    });
    const url = GlobalSettings.GET_USER_DETAIL_BY_TOCKEN + '/' + this.active_access_token;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {

      if (res) {
        this.email_id = res.username;
        this.confirmEmail();
      }

    }).catch((ex) => {
      this.sharedService.sucessEvent.next({
        customMsg: ex.msg,
        type: 'error'
      });
      console.log('ex ', ex);
    });
  }

  confirmEmail() {
    const url = GlobalSettings.CONFIRM_EMAIL + this.active_access_token + '?email_id=' + this.email_id;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      console.log('msg', res);
      this.showMsg = res;
      this.enableProceed = false;
    }).catch((ex) => {
      console.log('Error caught while fetching data', ex);
      this.showMsg = ex;
      this.enableProceed = true;
    });
  }
  goToLogin() {
    const path = this.service.getOrgDetails() + '/home';
    sessionStorage.setItem('frontface_url', this.service.getOrgDetails() + '/home');
    this.router.navigate([path]);
  }
}
