import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-summary-report',
  templateUrl: './post-summary-report.component.html',
  styleUrls: ['../taxonomy/import-taxonomy/taxonomy-summary/taxonomy-summary.component.scss']
})
export class PostSummaryReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
