import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { SharedService } from '../shared.service';
import { GlobalSettings } from '../global.settings';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Utils from '../utils';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['../taxonomy/import-taxonomy/taxonomy-summary/taxonomy-summary.component.scss']
})
export class SummaryReportComponent implements OnInit, OnDestroy {

  batchId = '';
  summaryDetail = null;
  errorData = [];
  importType = 0;
  currentBatchId = '';
  isPreSummary = false;
  orgCode = '';
  taxonomyName = '';
  isSSOUser = GlobalSettings.SSO_USER;

  constructor(
    private sharedService: SharedService,
    private service: CommonService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.currentBatchId = params.id;
      }
      if (params['org_code'] !== undefined) {
        if (params['org_code'].length === 5) {
          this.orgCode = params['org_code'];
        }
      }
    });
    if (this.router.url.indexOf('/preSummaryReport/') >= 0) {
      this.isPreSummary = true;
    } else {
      this.isPreSummary = false;
    }

  }

  ngOnInit() {
    const accessToken = localStorage.getItem('access_token');
    if (document.querySelector('body')) {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
    if (accessToken) {
      this.getPreSummaryReport();
    } else {
      this.goToLogin();
    }
  }

  ngOnDestroy() {
    if (document.querySelector('body')) {
      document.querySelector('body').classList.add('overflow-hidden');
    }
  }


  getPreSummaryReport() {
    const exParam = this.isPreSummary ? '&link_type=summary' : ''; // holds parameter to be added for link invalidation
    const url = GlobalSettings.CSV_SUMMARY_URL + '/' + this.currentBatchId + '?polling_for=csv&type=batch' + exParam;

    this.service.getServiceData(url, true, true).then((response: any) => {
      if (response.success) {
        const res = response.data[0];
        if (!this.isPreSummary) {
          if (res.summary_status) {
            this.summaryDetail = res.after_import_summary.import_detail;
            this.taxonomyName = res.before_import_summary.taxonomy_name;
            this.importType = res.before_import_summary.import_type;
          } else {
            this.cancelImport(true, res.after_import_summary.message);
          }
        } else {
          if (res.summary_status) {
            this.batchId = res.batch_id;
            this.importType = res.before_import_summary.import_type;
            this.summaryDetail = res.before_import_summary.summary_detail;
            this.taxonomyName = res.before_import_summary.taxonomy_name;
          } else {
            this.cancelImport(true, 'You cannot proceed because of following error: ' + res.before_import_summary.message.join('. '));
          }
          this.errorData = this.summaryDetail['errorData'];
        }
      } else {
        this.goToLogin();
        // console.log('res msg', response.message);
        setTimeout(() => {
          this.sharedService.sucessEvent.next({
            customMsg: response.message[0],
            type: 'error'
          });
        }, 500);
      }
    }).catch((ex) => {
      this.goToLogin();
      // setTimeout(() => {
      // this.sharedService.sucessEvent.next({
      //   customMsg: 'Exception occured',
      //   type: 'error'
      // });
      // }, 500);
    });
  }


  onProceed() {
    localStorage.setItem('association_link_clicked', 'true');
    if (this.isPreSummary) {
      const body = {
        import_pass: 4,
        batch_id: this.batchId,
        import_type: this.importType
      };

      const url = GlobalSettings.UPLOAD_CSV;
      this.service.postService(url, body, 0, true).then((res: any) => {
        this.sharedService.sucessEvent.next({
          customMsg: res.message[0],
          type: res.success ? 'success' : 'error'
        });
      }).catch((ex) => {
        console.log('getTaxonomiesList Ex', ex);
      });
    }
    this.sharedService.csvStatusUpdateEvent.next({ checkStatus: true });
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_LIST;
    this.router.navigate([path]);
  }

  cancelImport(showAlert = false, msg = '') {
    localStorage.setItem('association_link_clicked', 'true');
    const url = GlobalSettings.CANCEL_CSV_IMPORT + '/' + this.batchId;
    const body = {};
    this.service.deleteServiceData(url).then((res: any) => {
      console.log('CSV import cancelled', res);
      const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_LIST;
      this.router.navigate([path]);
      if (showAlert) {
        setTimeout(() => {
          this.sharedService.sucessEvent.next({
            customMsg: msg,
            type: 'error'
          });
        }, 500);
      }
    }).catch((ex) => {
      const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_LIST;
      this.router.navigate([path]);
      if (showAlert) {
        setTimeout(() => {
          this.sharedService.sucessEvent.next({
            customMsg: msg,
            type: 'error'
          });
        }, 500);
      }
    });
  }

  goToLogin() {
    if (this.isSSOUser) {
      const orgCode = window.location.hash.substr(6, 5);
      const path = GlobalSettings.UNIFIED_LOGIN + '/' + orgCode;
      window.location.href = path;
    } else {
      const path = '/org/' + this.orgCode + '/login';
      sessionStorage.setItem('frontface_url', this.service.getOrgDetails() + '/login');
      this.router.navigate([path]);
    }
  }
}
