import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {

  SEARCH_LOCATION = 'search';
  DATATABLE_LOCATION = 'datatable';

  @Input() label = 'Name Selection'; // holds label value for dropdown
  @Input() selectedData: any = {};
  @Input() data = []; // holds list of options data which will come as Input (mandatory)
  @Input() dropdownSettings: any; // dropdown settings (mandatory)
  @Input() id = 'multi-select'; // id attribute value which is required (mandatory)
  @Input() viewLocation = this.SEARCH_LOCATION;
  @Input() customCol = false;
  @Input() isAllselect: false;
  @Input() selectedOptionList = []; // holds the options which are to be selected by default
  @Input() isNewLine = false; // holds flag for new line dropdown
  @Input() isMandatory = false; // holds flag for mandatory field
  @Input() isTreeSearch = false; // holds flag for mandatory field
  @Input() lastIndex = false;
  @Output() SelectEvent = new EventEmitter();
  @Output() dropdownEvent = new EventEmitter();

  uniqueProp: string; // holds unique field property of array object which will be used as display and to be searched
  showDropdown = false; // holds flag to show/hide dropdown panel
  originalList = []; // holds original list for main storing
  @Input() placeholder = '';
  searchKeyword = ''; // holds value for search key words
  selectedDataList = []; // holds selected data list after 'Select' button click
  isAllSelected = false; // holds boolean whether all options are selected or not
  totalElementCountInList = 0; // holds total count in list
  showLabel: boolean; // holds flag to show/hide label
  buttonType: string; // button type ('button', 'icon') i.e. button can be button type or icon button
  @Input() isadvance; // holds value for search panel component active
  constructor() { }

  ngOnInit() {
    this.initialiseDefaultValues();
    if (this.data) {
      this.originalList = this.data; // storing original list at first time
      this.totalElementCountInList = this.originalList.length; // setting list count
    }

    // set default selection to filter dropdown
    if (this.selectedOptionList && this.selectedOptionList.length > 0) {
      this.selectedOptionList.forEach((option: any) => {
        this.data.forEach((item: any) => {
          if (item[option.propName].trim() === option.value.trim()) {
            item.is_selected = 1;
          }
        });
      });
      this.checkAllSelected();
      this.addSelection(false);
    }
    if (this.isadvance) {
      this.onAdvancedSearchFocus();
    }
  }

  initialiseDefaultValues() {
    this.uniqueProp = this.dropdownSettings.textField ? this.dropdownSettings.textField : this.getDefaultSettings().textField;
    this.placeholder = this.dropdownSettings.placeholder ? this.dropdownSettings.placeholder : this.getDefaultSettings().placeholder;
    this.showLabel = (this.dropdownSettings.showLabel !== undefined) ? this.dropdownSettings.showLabel
      : this.getDefaultSettings().showLabel;
    this.buttonType = (this.dropdownSettings.buttonType !== undefined) ? this.dropdownSettings.buttonType
      : this.getDefaultSettings().buttonType;
    this.selectedData[this.uniqueProp] = this.placeholder;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedOptionList && this.selectedOptionList && this.selectedOptionList.length > 0) {
      this.selectedOptionList.forEach((option: any) => {
        this.data.forEach((item: any) => {
          if (item[option.propName].trim() === option.value.trim()) {
            item.is_selected = 1;
          }
        });
      });
      this.checkAllSelected();
      this.addSelection(false);
    }
    if (changes.data && changes.data.currentValue) {
      this.originalList = this.data; // storing original list at first time
      this.totalElementCountInList = this.originalList.length; // setting list count
    }
  }

  /* dropdown toggle i.e. open/close */
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    if (this.toggleDropdown) {
      this.focusOnSearchBox();
    }
    this.emitDropdownEvent();
    if (this.isAllselect) {
      this.checkAllSelected();
    }
  }

  /* default settings for dropdown */
  getDefaultSettings() {
    return {
      textField: 'label',
      placeholder: (this.placeholder !== '' ? this.placeholder : 'Select'),
      showLabel: true, // by default, show label
      buttonType: 'button' // or 'icon'
    };
  }

  /**
   * To search list based on search keyword
   * @param filterProp (filter property)
   */
  onSearch(filterProp: string) {
    if (this.searchKeyword && this.searchKeyword.length > 0) {
      this.data = this.originalList.filter(obj => {
        return obj[filterProp].toLowerCase().includes(this.searchKeyword.toLowerCase());
      });
    } else {
      this.data = this.originalList;
    }
  }

  /**
   * On clicking outside of dropdown
   * (hide dropdown, clear search box)
   */
  onClickOutSide() {
    this.showDropdown = false;
    this.emitDropdownEvent();
    this.onClearSearchText();
  }

  /**
   * On clear search box input
   */
  onClearSearchText() {
    this.searchKeyword = '';
    this.onSearch(this.uniqueProp); // on claer, set original list to show whole
    this.focusOnSearchBox();
  }

  focusOnSearchBox() {
    setTimeout(() => {
      if (document.getElementById(this.id + 'searchBox')) {
        document.getElementById(this.id + 'searchBox').focus();
      }
    }, 200);
  }

  /**
   * To emit selected data list on Select button click
   */
  addSelection(userInput: boolean) {
    this.selectedDataList = this.originalList.filter(obj => {
      return (obj.is_selected === 1);
    });
    this.showDropdown = false;
    this.emitDropdownEvent();
    this.selectedData[this.uniqueProp] = this.selectedDataList.length > 0 ? (this.selectedDataList.length + ' selected') : this.placeholder;

    if (userInput || (!userInput && this.selectedDataList.length)) {
      this.SelectEvent.emit({ selectedData: this.selectedDataList });
    }
  }

  /**
   * On list option's checkbox click
   * @param optionData (option data)
   */
  onOptionSelect(optionData: any) {
    optionData.is_selected = (optionData.is_selected === 0 ? 1 : 0);
    this.checkAllSelected();
  }

  /**
   * Clear all checked options
   */
  clearAllSelect() {
    this.doCheckBoxesSelection(false);
  }

  selectAll() {
    this.doCheckBoxesSelection(true);
  }

  /**
   * Method for all options as checked or unchecked
   * @param allChecked (all checked when allChecked=true else all unchecked)
   */
  doCheckBoxesSelection(allChecked = true) {
    this.isAllSelected = allChecked;
    if (allChecked) {
      this.originalList.forEach(obj => {
        obj.is_selected = 1;
        if (this.isAllselect) {
          this.originalList.forEach(obj => {
            if (obj.label === 'Other language') {
              obj.is_selected = obj.is_selected === 1 ? 0 : 1;
            }
          });
        }
      });
    } else {
      this.originalList.forEach(obj => {
        obj.is_selected = obj.label === 'Other language' && obj.is_selected === 1 ? 1 : 0;
      });
    }
  }

  // To set whether all options are selected in list
  checkAllSelected() {
    const selectedCount = this.originalList.filter(obj => obj.is_selected === 1).length;
    if (selectedCount === this.totalElementCountInList) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
    if (this.isAllselect) {
      this.isEditprofile(selectedCount);
    }
  }

  emitDropdownEvent() {
    this.dropdownEvent.emit(this.showDropdown);
  }

  isEditprofile(selectedCount) {
    this.originalList.forEach(element => {
      if (selectedCount === this.totalElementCountInList - 1 && element.label === 'Other language' && element.is_selected === 1) {
        this.isAllSelected = false;
      } else if (selectedCount === this.totalElementCountInList - 1 && element.label !== 'Other language') {
        this.isAllSelected = true;
      } else {
        this.isAllSelected = selectedCount <= this.totalElementCountInList - 2 ? false : true;
      }
    });
  }

  clearSelectedDataList() {
    this.selectedDataList = [];
    this.initialiseDefaultValues();
  }

  onAdvancedSearchFocus() {
    if (document.getElementById('btnmultiselect-0')) {
      document.getElementById('btnmultiselect-0').focus();
    }
  }

}
