import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  forwardRef
} from '@angular/core';
import Utils from '../../utils';
import {
  IMyDpOptions
} from 'mydatepicker';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined } from 'util';

const noop = () => { };

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFormFieldComponent),
      multi: true
    }
  ]
})
export class DynamicFormFieldComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

  private innerValue: any = '';
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input() selectedMetadata;
  @Input() field_type_id;
  @Input() clearFieldValue; // If true, clears field value on every changes of selectedMetadata value
  @Input() disabled: boolean;
  @Output() formItemValue: EventEmitter<any>; // emits the selected object from the dropdown
  // public currentValue;

  public myDatePickerOptionsStartDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
  };

  /* getters and setters for field value */
  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  constructor() {
    // this.formItemValue = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.value = '';
    this.innerValue = '';
    this.field_type_id = '';
  }

  ngOnChanges() {
    if (this.clearFieldValue === true) { // Clears field value
      this.value = '';
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  isNumber(evt) {
    Utils.isNumber(evt);
  }

  // Formatting string value into date format
  formatDateValue(dateVal) {
    const date = dateVal;
    if (date.length > 0) {
      let year, month, day;
      [year, month, day] = date.split('-');
      month = parseInt(month, 10);
      day = parseInt(day, 10);
      dateVal = {
        date: {
          year: year,
          month: month,
          day: day
        },
        formatted: year + '-' + month + '-' + day
      };
    }
    return dateVal;
  }

  onCustomDateChanged(evt) {
    this.onBlur();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn) {
    try {
      this.onChangeCallback = fn;
    } catch (err) { }
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn) {
    try {
      this.onTouchedCallback = fn;
    } catch (err) { }
  }

  // From ControlValueAccessor interface
  writeValue(value) {
    if (value !== this.innerValue) {
      if (value && (this.field_type_id === 4)) {
        this.innerValue = this.formatDateValue(value);
      } else if (value && (this.field_type_id !== '') && !(isNullOrUndefined(this.field_type_id))) {
        this.innerValue = value;
      }
    }
  }

  // For setting rich text value
  updateFieldFromEditor(evt) {
    this.value = evt;
  }
}
