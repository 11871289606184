import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges
} from '@angular/core';
import {
  GlobalSettings
} from '../../../global.settings';
import {
  CommonService
} from '../../../common.service';
import {
  TreeDataService
} from '../../../tree-data.service';
import Utils from '../../../utils';
import {
  SharedService
} from '../../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  ItemDetailsComponent
} from '../../../common/item-details/item-details.component';
import {
  Router
} from '@angular/router';
@Component({
  selector: 'app-search-listing',
  templateUrl: './search-listing.component.html'
})
export class SearchListingComponent implements OnInit, OnChanges {

  @Input() searchResult;
  @ViewChild('itemDetailsComponent', { static: false }) itemDetailsComponent: ItemDetailsComponent;
  view = 'taxonomy-detail';
  itemAssociations: any;
  itemExemplars = [];
  itemAssets = [];
  itemAdditionalMetadata = [];
  treeNode: any;
  showAssocciation;
  showAdditionalMetadata;
  showExemplar;
  showDocument = true;
  taxonomyData = ['test'];
  selectedTaxonomyId;
  serviceUrl;
  taxonomyTitle;
  nodeSelectedEvent: Subscription;
  dataLoaded = false;
  nodetypeData;
  currentItem = null;
  blankNodeType = true;
  clicked = '';
  blankNodeTypeExceptionEvent: Subscription;
  searchResultEvent: Subscription;
  constructor(
    private service: CommonService, private treeService: TreeDataService, private sharedService: SharedService,
    private router: Router) {
    this.searchResultEvent = this.sharedService.searchResultEvent.subscribe((item: any) => {
      if (item['showSearchList']) {
        this.dataLoaded = true;
      } else {
        this.dataLoaded = false;
      }
    });
  }

  ngOnInit() {
    // console.log('Component created');
    // setTimeout(() => {
    //   if (document.getElementById('a-tag0')) {
    //     document.getElementById('a-tag0').click();
    //     // console.log(document.getElementById('a-tag0'));
    //   }
    // }, 1000);
  }

  ngOnChanges() {
    setTimeout(() => {
      if (document.getElementById('a-tag0')) {
        document.getElementById('a-tag0').click();
        // console.log(document.getElementById('a-tag0'));
      }
    }, 1000);
  }

  onTreeNodeSelected(item) {
    // console.log('Item', item);
    // this.treeNode = item;
    this.currentItem = item;
    this.getSelectedTreeItemDetail(item);
  }

  getSelectedTreeItemDetail(item) {
    // console.log('item', JSON.stringify(item));
    let url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + item.unique_node_id;
    try {
      if (item.node_type[0] === 'Document') {
        url = GlobalSettings.GET_CFDOC_ITEM_DETAIL + '/' + item.unique_node_id;
      } else {
        url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + item.unique_node_id;
      }
    } catch (ex) {
      // this.blankNodeType = true;
      console.log('item.node_type not found ');
    }
    this.itemAssociations = [];
    this.itemExemplars = [];
    this.itemAssets = [];
    this.itemAdditionalMetadata = [];
    this.service.getServiceData(url).then((res: any) => {
      this.treeNode = res;
      if (!res.node_type_id) {
        res['node_type_id'] = this.currentItem.node_type_id;
      }
      if (this.itemDetailsComponent && this.itemDetailsComponent.nodeDetailsComponent) {
        this.itemDetailsComponent.nodeDetailsComponent.generateForm(res, item.node_type[0]);
      }

      this.showAssocciation = false;
      this.showAdditionalMetadata = false;
      this.showExemplar = false;
      this.showDocument = false;
      setTimeout(() => {
        this.showAssocciation = true;
        this.showAdditionalMetadata = true;
        this.showExemplar = true;
        this.showDocument = true;
        this.itemAssociations = res.item_associations;
        if (res.exemplar_associations) {
          this.itemExemplars = res.exemplar_associations;
        }
        if (res.assets) {
          this.itemAssets = res.assets;
        }
        if (res.custom_metadata) {
          // this.itemAdditionalMetadata = res.custom_metadata;
          this.itemAdditionalMetadata = [];
          if (res.custom_metadata) {
            res.custom_metadata.forEach(obj => {
              if (obj.is_additional_metadata === 1) {
                this.itemAdditionalMetadata.push(obj);
              }
            });
          }
        }
      }, 2000);
      this.sharedService.treeNodeSelectedEvent.next(this.treeNode);
      this.blankNodeTypeExceptionEvent = this.sharedService.blankNodeTypeExceptionEvent.subscribe((elem: any) => {
        if (elem && elem['blankNodeType']) {
          this.blankNodeType = elem['blankNodeType'];
          console.log('Search listing component authoring service failed 500 ', this.blankNodeType);
        } else {
          this.blankNodeType = false;
        }
      });

    }).catch((ex) => {
      this.sharedService.blankNodeTypeExceptionEvent.next({
        'blankNodeType': true,
      });
      console.log('getSelectedTreeItemDetail ', ex);
    });
  }


  onTaxonomySelected(id) {
    if (id) {
      // this.progressServie.start();
      this.taxonomyData = null;
      this.selectedTaxonomyId = id;
      this.serviceUrl = GlobalSettings.GET_TREE_VIEW + id;
      this.treeService.getTreeData(this.serviceUrl, true).then((response: any) => {
        const res = response.parsedTreeNodes;
        // console.log('GET_TREE_VIEW ', res);
        if (res && res.children) {
          // Utils.sortData(res.children);
          this.taxonomyData = res;
          if (res.children.length > 0) {
            this.taxonomyTitle = res.children[0].full_statement;
          }
          init_detailsViewClick();
        }
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }

  }


  /* ---------Functionality to get the result count start --------- */

  getCount() {
    if (this.searchResult.length > 0) {
      const arr = this.searchResult.filter(x => x['type'][0] !== 'project');
      return arr.length;
    } else {
      return 0;
    }
  }

  /* ---------Functionality to get the result count end --------- */


  /* ---------Functionality to navigate to taxonomy start --------- */

  navigate(object) {
    let path;
    // If any node does not have parent id, consider that as orphan node and assign dummy id as parent id
    const parentId = this.treeNode.parent_id ? this.treeNode.parent_id : Utils.DUMMY_ID_FOR_ORPHANLABEL;
    const isDocument = (object.node_type[0] && object.node_type[0].toLowerCase() === 'document') ? true : false;
    let projectId = (object.project_id && object.project_id[0]) ? object.project_id[0] : '';
    if (this.router.url.indexOf('project/list') !== -1 || this.router.url.indexOf('project/detail') !== -1) {
      path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_AUTHORING + '/';
      if (isDocument) {
        path = path + projectId; // Document node
      } else {
        if (this.treeNode['projects_associated'][0] && this.treeNode['projects_associated'][0]['project_id']) {
          projectId = this.treeNode['projects_associated'][0]['project_id'];
        }
        path = path + projectId + '/' + parentId + '/' + this.treeNode.item_id;
      }
      localStorage.setItem('project_type', '1');
      this.router.navigate([path]);
    } else {
      path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_DETAIL + '/';
      if (isDocument) {
        path = path + object['document_id'][0]; // Document node
      } else {
        path = path + object['document_id'][0] + '/' + parentId + '/' + this.treeNode.item_id + '/search';
      }
      this.router.navigate([path]);
    }

  }

  /* ---------Functionality to navigate to taxonomy end --------- */

}
