import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-front-facing-pacing-guides',
  templateUrl: './front-facing-pacing-guides.component.html'
})
export class FrontFacingPacingGuidesComponent implements OnInit {

  // constructor(private router: Router) {
  //   this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
  //     if (event.url.indexOf('/taxonomies') >= 0) {
  //       setTimeout(() => {
  //         const element = document.getElementById('Taxonomies-button');
  //         element.className += ' active';
  //       }, 100);
  //     }
  //   });
  // }

  ngOnInit() {
  }

}
