import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import Utils from '../../utils';

@Component({
  selector: 'app-additional-metadata-details',
  templateUrl: './additional-metadata-details.component.html'
})
export class AdditionalMetadataDetailsComponent implements OnInit {
  @Input() additionalMetadataData: any;
  @Input() viewMode = 'edit';
  @Input() isRightPanel = false;
  @Output() editClicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onEditMetadataClick(additionalMetadataData) {
    let obj: any;
    obj = {
      metadata_id: additionalMetadataData.metadata_id,
      metadata_value: additionalMetadataData.metadata_value,
      metadata_value_html: additionalMetadataData.metadata_value_html,
      is_additional_metadata: additionalMetadataData.is_additional_metadata,
      field_type: additionalMetadataData.field_type
    };
    setTimeout(() => {
      Utils.removeShowClass();
      focusOnModalCloseBtn();
    }, 500);
    this.editClicked.emit(obj);
  }

  onDeleteClick(id) {
    this.deleteClicked.emit(id);
  }

  getHtml(obj: any, attribute: any) {
    let htmlContent = '';
    htmlContent = obj[attribute + '_html'] ? obj[attribute + '_html'] : obj[attribute];
    return htmlContent;
  }

}
