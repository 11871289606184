import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../common.service';
import { GlobalSettings } from '../../global.settings';
import { Router, NavigationEnd } from '@angular/router';
import {
  Subscription
} from 'rxjs/Subscription';
import { SharedService } from '../../shared.service';
import Utils from 'src/app/utils';
import {
  Observable
} from 'rxjs';
import {
  HttpClient
} from '@angular/common/http';

@Component({
  selector: 'app-front-facing-documents',
  templateUrl: './front-facing-documents.component.html',
  styleUrls: ['./front-facing-documents.component.scss']
})
export class FrontFacingDocumentsComponent implements OnInit, OnDestroy {

  blocks = []; // holds the home page blocks
  orgId = ''; // holds the organistion if from local storage
  eventSubscription: Subscription;
  currentUrl = null;
  documentLoader = false; // holds loading state for document list fetching

  listOfColumn = [{
    name: 'Name',
    propName: 'title',
    class: ' ',
    type: 'text'
  },
  {
    name: 'Type',
    propName: 'extracted_asset_content_type',
    class: '',
    width: '30%',
    type: 'text'
  },
  {
    name: 'Upload Date',
    propName: 'uploaded_at',
    class: '',
    width: '15%',
    type: 'date',
    dateType: 'amCalendar'
  }
  ];

  optionList = [{
    name: 'Download',
    type: 'downloadDocument',
    value: true,
    propName: 'downloadUrl',
    url: true,
    check: 'isDownload'
  }];

  fileFormats = []; // holds all the files formats

  constructor(private service: CommonService, private router: Router, private sharedService: SharedService, private http: HttpClient) {
    // this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
    //   if (event.url.indexOf('/documents') >= 0) {
    //     setTimeout(() => {
    //       if (document.getElementById('Documents-button')) {
    //         const element = document.getElementById('Documents-button');
    //         element.className += ' active';
    //       }
    //     }, 100);
    //   }
    // });

    this.getJSON().subscribe(data => {
      this.fileFormats = data;
    });

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      if (event.url.indexOf('/documents') >= 0) {
        this.currentUrl = 'documents';
      }
    });
    this.eventSubscription = this.sharedService.activeLink.subscribe((events: any) => {
    });


  }

  ngOnInit() {
    this.documentLoader = true;
    setTimeout(() => {
      this.getHomePageBlocks();
    }, 1000);
  }


  /* --------- Fetch Notes List functionality Started --------- */

  getHomePageBlocks() {
    this.blocks = [];
    this.documentLoader = true;
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }
    const url = GlobalSettings.FRONT_FACE_UPLOAD_CACHE + '/' + this.orgId;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      res.forEach(element => {
        if (element.status === 1) {
          element['extracted_asset_content_type'] = Utils.getFileType(element['asset_name'].split('.').pop().toUpperCase(), this.fileFormats);
          const downloadUrl = GlobalSettings.BASE_URL + GlobalSettings.ASSET_ASSOCIATION + '/download/' + element.asset_target_file_name;
          element.downloadUrl = downloadUrl;
          element.showOptions = true;
          element.isDownload = true;
          this.blocks.push(element);
        }
      });
      this.documentLoader = false;
    }).catch(ex => {
      console.log('getHomePageBlocks ex ', ex);
      this.documentLoader = false;
    });
  }


  public getJSON(): Observable<any> {
    try {
      return this.http.get('./assets/json/fileFormat.json');
    } catch (err) { }
  }


  /* --------- Fetch Notes List functionality End --------- */

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}
