import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  Router
} from '@angular/router';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {

  @Output() filterObject = new EventEmitter(); // emit filter object
  @Input() dropdownObject; // dropdown object collected from searched content
  Object = Object;
  currentDropdownId = '';
  lastDropdownId = '';
  filterCriteria = {}; // holds all the filters objects applied
  selectedOptionsObj = {};
  hideDocument = false;
  dropdownSettings = {
    textField: 'label' // label property in dropdown options to display option label
  };
  constructor(private router: Router) { }

  ngOnInit() {
    // this.preFillSelectedObj(this.dropdownObject);
    if (this.router.url.indexOf('project/detail') !== -1) {
      this.hideDocument = true;
    } else {
      this.hideDocument = false;
    }
  }

  /*generateSearchObject(isSelected, id, data, index) {
    // console.log(JSON.stringify(this.dropdownObject));
    Object.keys(this.dropdownObject).forEach(element => {
      if (data.element === element && element === 'update_on') {
        for (const i in this.dropdownObject[element]) {
          if (this.dropdownObject[element][i].label !== data.label && this.dropdownObject[element][i].is_selected === 1) {
            const checkboxId = this.dropdownObject[element][i]['element'] + '-' + this.dropdownObject[element][i]['label'] + '-' + i;
            // console.log('checkboxId', checkboxId);
            if (document.getElementById(checkboxId)) {
              document.getElementById(checkboxId).click();
            }
          }
        }
      }
    });
    this.currentDropdownId = id;
    this.generateFilterCriteria(this.dropdownObject, true);
    this.getSelectedValues(id);
  }*/

  /* --------- Functionality to generate Lable for dropdown Start --------- */

  /*getKey(key) {
    let lable = '';
    key.split('_').forEach(element => {
      lable = lable + element + ' ';
    });
    return lable;
  }*/

  /* --------- Functionality to generate Lable for dropdown End --------- */


  /* --------- Functionality to toggle dropdown start --------- */

  /*toggleDropdown(id) {
    this.currentDropdownId = id;
    if (this.currentDropdownId !== this.lastDropdownId) {
      const element = document.getElementById('dropdown-' + this.currentDropdownId);
      if (this.lastDropdownId.length === 0) {
        if (element) {
          element.className += ' show';
        }
      } else {
        const oldElement = document.getElementById('dropdown-' + this.lastDropdownId);
        if (oldElement) {
          oldElement.classList.remove('show');
        }
        if (element) {
          element.className += ' show';
        }
      }
    } else {
      const element = document.getElementById('dropdown-' + this.currentDropdownId);
      if (element) {
        if (element.classList.contains('show')) {
          element.classList.remove('show');
        } else {
          element.className += ' show';
        }
      }
    }
    this.lastDropdownId = this.currentDropdownId;
  }*/

  /* --------- Functionality to toggle dropdown end --------- */


  /* ---------Functionality to generate filter criteria functionality Start --------- */

  generateFilterCriteria(data, flag) {
    let filterCriteria = [];
    this.filterCriteria = {};
    Object.keys(data).forEach(element => {
      filterCriteria = data[element].filter(item => {
        if (item.is_selected === 1) {
          return item;
        }
      });
      if (filterCriteria.length > 0) {
        this.filterCriteria[element] = filterCriteria;
      }
    });
    if (flag) {
      this.filterObject.emit(this.filterCriteria);
    }
  }

  /* ---------Functionality to generate filter criteria functionality End --------- */


  /* ---------Functionality to fill in the selected comma seperated values in dropdown start --------- */

  getSelectedValues(key) {
    let selectedOptions = '';
    Object.keys(this.filterCriteria).forEach(element => {
      if (key === element) {
        this.filterCriteria[element].forEach(data => {
          selectedOptions = selectedOptions + data['label'] + ', ';
        });
      }
    });
    selectedOptions = selectedOptions.substring(0, selectedOptions.length - 2);
    if (selectedOptions.length > 0) {
      this.selectedOptionsObj[key] = selectedOptions;
    } else {
      this.selectedOptionsObj[key] = 'Select';
    }
  }

  /* ---------Functionality to fill in the selected comma seperated values in dropdown end --------- */


  /* ---------Functionality to fill in the default value of dropdown start --------- */

  /*preFillSelectedObj(data) {
    Object.keys(data).forEach(element => {
      if (!this.selectedOptionsObj.hasOwnProperty(element)) {
        this.selectedOptionsObj[element] = 'Select';
      }
    });
  }*/

  /* ---------Functionality to fill in the default value of dropdown end --------- */

  /**
   * On emitting of option data selected
   * @param eventData (emit event data)
   */
  onSelectOption(eventData: any) {
    this.generateFilterCriteria(this.dropdownObject, true);
  }

}
