import { Injectable } from '@angular/core';
import { openDB, DBSchema, IDBPDatabase } from 'idb';

@Injectable({
    providedIn: 'root',
})
export class IndexedDBService {
    private db: IDBPDatabase<MyDB>;
    constructor() {
        this.connectToDb();
    }

    async connectToDb() {
        this.db = await openDB<MyDB>('acmt-db', 1, {
            upgrade(db) {
                db.createObjectStore('acmt');
            },
        });
    }

    clearIdbTable() {
        return this.db.clear('acmt');
    }

    addData(value, key) {
        return this.db.put('acmt', value, key);
    }

    deleteData(key: string) {
        return this.db.delete('acmt', key);
    }

    getData(tableName, key) {
        if (this.db) {
            return this.db.get(tableName, key);
        } else {
            const promise = new Promise(resolve => {
                resolve([]);
            });
            return promise;
        }
    }
}

interface MyDB extends DBSchema {
    'acmt': {
        key: string;
        value: any;
    };
}
