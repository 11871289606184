import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import {
  GlobalSettings
} from '../../../global.settings';
import {
  CommonService
} from '../../../common.service';
import {
  Router
} from '@angular/router';
import {
  SharedService
} from '../../../shared.service';
import {
  SearchFilterComponent
} from '../search-filter/search-filter.component';
import {
  AutoCompleteComponent
} from '../../../common/auto-complete/auto-complete.component';
import {
  AdvanceSearchComponent
} from '../../../common/advance-search/advance-search.component';
import Utils from '../../../utils';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
@Component({
  selector: 'app-search',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchComponent implements OnInit {
  // 'adoption_status', 'item_association_type', 'document_metadata', 'item_metadata', 'custom_metadata','workflow_stage', 'project'
  searchKeyword; // hold the keyword to search
  searchResult = []; // holds the result after search
  dropdownKeys = ['update on', 'metadata set', 'document', 'updated by']; // holds the list of dropdown keys //'updated by',
  dropdownObject = {}; // holds the entire dropdown object
  dataAfterFilter = []; // holds filtered searched data
  Object = Object;
  noDataFound = true;
  tempObj = {};
  searchString = '';
  @Input() searchPlaceholder;
  @Input() enableAdvanceSearch;
  @Input() showBackwardNav = true; // holds whether to show backward nav icon
  @Input() showNav = true; // holds flag for left menu
  filterDataLoaded = false; // check if service has hitted , used to prevent showing up filter
  dataLoaded = false; // check if service has hitted , used to prevent showing up result
  // filterSelected = false; // check if any filter has been selected
  @Output() setSearchTriggerEvent: EventEmitter<any>; // emits the selected object from the dropdown
  @ViewChild('searchFilter', { static: false }) searchFilter: SearchFilterComponent;
  inputForFilter = [];
  optionalFilter = {};
  advanceOptionalFilter = {};

  saveSearchTitle = ''; // holds the title for save search
  saveSearchObj;
  savedSearchList = []; // holds saved search result array
  recentSearchList = []; // holds recent search result array
  search_optional_filters = [];
  projects = [];
  taxonomies = [];
  context_page = '';
  filterSelected = false;
  maxWidth;
  advanceSearchToggle = false;
  appendMargin = false;
  searchText = '';
  isAdvanceSearch = false;
  textMessage = '';
  advanceSearchList = [{
    'name': 'adoption status',
    'is_selected': 0,
    'type': 'key'
  }, {
    'name': 'item association type',
    'is_selected': 0,
    'type': 'key'
  }];

  /*
,
  {
    'name': 'document metadata',
    'is_selected': 0,
    'type': 'key'
  },
  {
    'name': 'item metadata',
    'is_selected': 0,
    'type': 'key'
  },
  {
    'name': 'custom metadata',
    'is_selected': 0,
    'type': 'key'
  }
  */

  uniqueId = 'name'; // holds the unique id for the auto complete items

  selectType = 'single'; // holds the dropdown selection criteria as in multi or single
  autoCompleteTitle = 'Select Metadata'; // title to be sent for auto complete dropdown
  saveSearchForm: FormGroup;

  @ViewChild('autoComplete', { static: false }) autoComplete: AutoCompleteComponent;
  @ViewChild('advanceSearch', { static: false }) advanceSearch: AdvanceSearchComponent;



  constructor(private service: CommonService, private router: Router, private sharedService: SharedService) {
    this.setSearchTriggerEvent = new EventEmitter<any>();
  }

  ngOnInit() {
    this.saveSearchForm = new FormGroup({
      search_title: new FormControl(null, [Validators.required])
    });
    this.sharedService.setSearchKeyword.subscribe((event: any) => {
      this.searchKeyword = event.keyword;
      if (this.enableAdvanceSearch) {
        // this.onSearch();
        // this.getAssociationType();
        if (this.router.url.indexOf('taxonomy/detail') === -1) {
          this.listTaxonomies();
        } else {
          this.getAllNodeTypes();
        }
        // this.fillFilterRawData();
        // this.getSaveSearchList();
        if (this.router.url.indexOf('project/detail') === -1) {
          this.getProjects();
          this.advanceSearchList.push({
            'name': 'project',
            'is_selected': 0,
            'type': 'key'
          });
        }
        // this.getAllNodeTypes();
        // this.getAllMetadata();
        this.getUsers();
        if (this.router.url.indexOf('project/list') !== -1 || this.router.url.indexOf('taxonomy/list') !== -1) {
          // this.getWorkFlowDetails();
          this.advanceSearchList.push({
            'name': 'workflow stage',
            'is_selected': 0,
            'type': 'key'
          });
        }

        if (this.router.url.indexOf('project/list') !== -1 || this.router.url.indexOf('project/detail') !== -1) {
          const elem = this.advanceSearchList.find(x => x.name === 'adoption status');
          const index = this.advanceSearchList.indexOf(elem);
          this.advanceSearchList.splice(index, 1);
        }
      }
    });

    if (this.router.url.indexOf('taxonomy/list') !== -1) {
      this.context_page = 'taxonomy';
    } else if (this.router.url.indexOf('taxonomy/detail') !== -1) {
      this.context_page = 'taxonomy_detail';
      this.searchPlaceholder = 'detail';
      this.isAdvanceSearch = true;
    } else if (this.router.url.indexOf('project/list') !== -1) {
      this.context_page = 'project';
    } else if (this.router.url.indexOf('project/detail') !== -1) {
      this.context_page = 'project_detail';
      this.isAdvanceSearch = true;
    } else if (this.router.url.indexOf('pacingguide/list') !== -1) {
      this.context_page = 'pacingguide';
    }
  }

  fillFilterRawData() {
    this.updateOn();
    setTimeout(() => {
      // console.log('Filter Input', JSON.stringify(this.inputForFilter));
      this.createStaticDropdownObj(this.inputForFilter);
      this.filterDataLoaded = true;
    }, 1250);
  }





  listTaxonomies() {
    const url = GlobalSettings.TAXONOMY_LIST;
    this.service.getServiceData(url).then((res: any) => {
      this.taxonomies = res;
      res.forEach(element => {
        this.inputForFilter.push({
          'filter_type': 'document_id',
          'filter_value': element.document_id,
          'label': element.title,
          'is_selected': 0,
          'filter_key': '',
          'element': 'document'
        });
      });
      this.getAllNodeTypes();
    }).catch(ex => {
      this.filterDataLoaded = false;
      console.log('list of taxonomies ex ', ex);
    });
  }




  /* --------- Functionality to fetch all node types start --------- */

  getAllNodeTypes() {
    const url = GlobalSettings.GET_NODETYPE_LIST;
    this.service.getServiceData(url).then((res: any) => {
      res['nodetype'].forEach(element => {
        this.inputForFilter.push({
          'filter_type': 'node_type_id',
          'filter_value': element.node_type_id,
          'label': element.title,
          'is_selected': 0,
          'filter_key': '',
          'element': 'metadata set'
        });
      });
      this.fillFilterRawData();
    }).catch((ex) => {
      this.filterDataLoaded = false;
      console.log('Error caught while fetching metadata set list', ex);
    });
  }

  /* --------- Functionality to fetch all node types end --------- */



  /* --------- Functionality to fetch all users start --------- */

  getUsers() {
    const url = GlobalSettings.GET_USERS;
    this.service.getServiceData(url).then((res: any) => {
      res.UserList.users.forEach(element => {
        this.inputForFilter.push({
          'filter_type': 'updated_by',
          'filter_value': element.user_id,
          'label': element.first_name,
          'is_selected': 0,
          'filter_key': '',
          'element': 'updated by'
        });
      });

    }).catch((ex) => {

    });
  }

  /* --------- Functionality to fetch all users end --------- */


  updateOn() {
    this.inputForFilter.push({
      'filter_type': 'update_on',
      'filter_value': 'today',
      'label': 'today',
      'is_selected': 0,
      'filter_key': '',
      'element': 'update on'
    }, {
      'filter_type': 'update_on',
      'filter_value': 'last_seven_days',
      'label': 'last seven days',
      'is_selected': 0,
      'filter_key': '',
      'element': 'update on'
    }, {
      'filter_type': 'update_on',
      'filter_value': 'last_thirty_days',
      'label': 'last thirty days',
      'is_selected': 0,
      'filter_key': '',
      'element': 'update on'
    });
  }


  getProjects() {
    const url = GlobalSettings.PROJECT_LIST + '?project_type=1';
    this.service.getServiceData(url).then((res: any) => {
      this.projects = res.projects;

      res['projects'].forEach(element => {

        this.inputForFilter.push({
          'filter_type': 'project_id',
          'filter_value': element.project_id,
          'label': element.project_name,
          'is_selected': 0,
          'filter_key': '',
          'element': 'project'
        });

      });

    }).catch(ex => {
      console.log('list of taxonomies ex ', ex);
    });
  }

  /* ---------Search functionality Start --------- */

  search() {
    this.searchResult = [];
    this.updateFilterCriteria();
    const obj = {
      'search_term': this.searchKeyword,
      'search_optional_filters': this.search_optional_filters,
      'context_page': this.context_page,
    };

    this.saveSearchObj = obj;


    // if (this.searchKeyword && this.searchKeyword.length > 2) {
    const url = GlobalSettings.SEARCH;
    if ((this.searchKeyword && this.searchKeyword.trim().length > 2) || this.filterSelected) {
      this.service.postService(url, this.saveSearchObj).then((res: any) => {
        this.searchResult = [];
        this.dataLoaded = true;
        if (res['hit'].length > 0) {
          res['hit'].forEach(element => {
            element['fields']['unique_node_id'] = element['id'];
            this.searchResult.push(element['fields']);
          });
          setTimeout(() => {
            searchHeightCalculation();
          }, 500);
        } else {
          this.searchResult = [];
        }
        this.sharedService.searchResultEvent.next({
          'searchData': this.searchResult,
          'showSearchList': true
        });
        // console.log('Searched Data', JSON.stringify(res));
      }).catch(ex => {
        console.log('update user', ex);
      });


      // } else {
      //   console.log('Search text length < 3');
      // }
    } else {
      console.log('Search input is empty');
      this.searchResult = [];
      this.sharedService.searchResultEvent.next({
        'searchData': this.searchResult,
        'showSearchList': true
      });
    }
    this.advanceSearchToggle = false;
  }

  /* --------- Search functionality End --------- */


  /* ---------Generate dropdown object functionality Start --------- */

  createStaticDropdownObj(listingData) {
    this.dropdownKeys.forEach(key => {
      this.dropdownObject[key] = [];
      listingData.forEach(obj => {
        if (obj['element'] === key) {
          this.dropdownObject[key].push(obj);
        }
      });
    });
    // console.log('this.dropdownObject', JSON.stringify(this.dropdownObject));
  }

  /* ---------Generate dropdown object functionality End --------- */



  /* ---------Functionality to collect filter criteria functionality Start --------- */

  collectFilterCriteria(filterCriteria) {
    if (filterCriteria) {
      this.optionalFilter = filterCriteria;
    }
    this.search_optional_filters = [];
    Object.keys(this.optionalFilter).forEach(element => {
      this.optionalFilter[element].forEach(item => {
        this.search_optional_filters.push(item);
      });
    });
    this.collectAdvanceFilterCriteria(null);
    // this.search();
  }


  collectAdvanceFilterCriteria(filterCriteria) {
    console.log('filterCriteria', JSON.stringify(filterCriteria));
    if (filterCriteria) {
      if (JSON.stringify(this.advanceOptionalFilter) === JSON.stringify({})) {
        this.advanceOptionalFilter = filterCriteria;
      } else {
        let flag = false;
        Object.keys(this.advanceOptionalFilter).forEach(key => {
          Object.keys(filterCriteria).forEach(filterCriteriaKey => {
            if (key === filterCriteriaKey) {
              flag = true;
              filterCriteria[filterCriteriaKey].forEach(item => {
                this.advanceOptionalFilter[key].push(item);
              });
            }
          });
        });

        if (!flag) {

          Object.keys(filterCriteria).forEach(elem => {
            console.log('False', elem, filterCriteria[elem]);
            this.advanceOptionalFilter[elem] = filterCriteria[elem];
          });
        }
      }
    }
    // this.search_optional_filters = [];
    if (filterCriteria) {
      Object.keys(filterCriteria).forEach(element => {
        filterCriteria[element].forEach(item => {
          this.search_optional_filters.push(item);
        });
      });
    } else {
      Object.keys(this.advanceOptionalFilter).forEach(element => {
        this.advanceOptionalFilter[element].forEach(item => {
          this.search_optional_filters.push(item);
        });
      });
    }
    if (this.search_optional_filters.length > 0) {
      this.filterSelected = true;
    } else {
      this.filterSelected = false;
    }
    // console.log('this.advanceOptionalFilter', JSON.stringify(this.advanceOptionalFilter));
    this.search();
    this.calculateWidth();
  }

  calculateWidth() {
    let ul_panel = 0;
    let div_search_panel = 0;
    if (document.getElementById('ul-panel')) {
      ul_panel = document.getElementById('ul-panel').offsetLeft;
    }
    if (document.getElementById('div-search-panel')) {
      div_search_panel = document.getElementById('div-search-panel').offsetWidth;
    }
    const rightMargin = Math.ceil(Number(((45 / 100) * div_search_panel)));
    this.maxWidth = window.innerWidth - rightMargin + 'px';
    document.getElementById('div-search-panel').setAttribute('style', 'max-width: ' + this.maxWidth);
    if (document.getElementById('ul-panel') && document.getElementById('div-search-panel')) {
      // console.log(Number(ul_panel), '-------', this.maxWidth);
    }
    if (Number(ul_panel) >= Number(this.maxWidth) - rightMargin) {
      // console.log('Number(ul_panel)', Number(ul_panel));
      this.appendMargin = true;
    } else {
      this.appendMargin = false;
    }
  }

  /* --------- Functionality on deleting selected items start --------- */

  deleteObj(data, evt) {
    console.log('----', data);
    Object.keys(this.advanceOptionalFilter).forEach(key => {
      if (key === data['element']) {
        for (const i in this.advanceOptionalFilter[key]) {
          if (i) {
            if (JSON.stringify(this.advanceOptionalFilter[key][i]) === JSON.stringify(data)) {
              console.log('Match', data);
              this.advanceOptionalFilter[key].splice(i, 1);
            }
          }
        }
      }
    });
    console.log('this.advanceOptionalFilter', JSON.stringify(this.advanceOptionalFilter));
    this.collectFilterCriteria(null);
    // this.calculateWidth();
  }

  /* --------- Functionality on deleting selected items end --------- */


  updateFilterCriteria() {
    if (this.router.url.indexOf('taxonomy/list') !== -1 || this.router.url.indexOf('taxonomy/detail') !== -1) {
      this.search_optional_filters.push({
        'filter_type': 'type',
        'filter_value': 'document'
      }, {
        'filter_type': 'type',
        'filter_value': 'item'
      });
      this.updatedTaxonomyId();
    } else if (this.router.url.indexOf('project/list') !== -1 || this.router.url.indexOf('project/detail') !== -1) {
      this.search_optional_filters.push({
        'filter_type': 'type',
        'filter_value': 'project'
      }, {
        'filter_type': 'type',
        'filter_value': 'item'
      }, {
        'filter_type': 'type',
        'filter_value': 'document'
      });

      this.updatedProjectId();
    } else if (this.router.url.indexOf('pacingguide/list') !== -1) {
      this.search_optional_filters.push({
        'filter_type': 'type',
        'filter_value': 'pacing_guide'
      }, {
        'filter_type': 'type',
        'filter_value': 'document'
      }, {
        'filter_type': 'type',
        'filter_value': 'item'
      });
    }
  }

  /* ---------Functionality to collect filter criteria functionality End --------- */


  /* ---------Functionality to update taxonomy id and project id if no project or taxonomy is selected start --------- */

  updatedProjectId() {
    let flag = false;
    let projectId = null;
    if (this.router.url.split('/').length && (this.router.url.split('/').indexOf('detail') !== -1)) {
      const pos = this.router.url.split('/').indexOf('detail');
      if (this.router.url.split('/')[pos + 1]) {
        projectId = this.router.url.split('/')[pos + 1];
      }
    }
    this.search_optional_filters.forEach(element => {
      if (element['filter_type'] === 'project_id') {
        flag = true;
      }
    });
    if (!flag) {
      if (this.router.url.indexOf('project/detail') !== -1) {
        this.search_optional_filters.push({
          'filter_type': 'project_id',
          'filter_value': projectId,
          'filter_key': ''
        });
      } else {
        this.projects.forEach(element => {
          this.search_optional_filters.push({
            'filter_type': 'project_id',
            'filter_value': element.project_id,
            'filter_key': ''
          });
        });
      }
    }
  }



  updatedTaxonomyId() {
    let flag = false;
    let documentId = null;
    if (this.router.url.split('/').length && (this.router.url.split('/').indexOf('detail') !== -1)) {
      const pos = this.router.url.split('/').indexOf('detail');
      if (this.router.url.split('/')[pos + 1]) {
        documentId = this.router.url.split('/')[pos + 1];
      }
    }
    this.search_optional_filters.forEach(element => {
      if (element['filter_type'] === 'document_id') {
        flag = true;
      }
    });
    if (!flag) {
      if (this.router.url.indexOf('taxonomy/detail') !== -1) {
        this.search_optional_filters.push({
          'filter_type': 'document_id',
          // 'filter_value': this.router.url.split('/').pop(),
          'filter_value': documentId,
          'filter_key': ''
        });
      } else {
        this.taxonomies.forEach(element => {
          this.search_optional_filters.push({
            'filter_type': 'document_id',
            'filter_value': element.document_id,
            'filter_key': ''
          });
        });
      }
    }
  }

  /* ---------Functionality to update taxonomy id and project id if no project or taxonomy is selected end--------- */


  /* ---------Functionality to navigate to taxonomy start --------- */

  navigate(object) {
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_TAXONOMY_DETAIL + object['document_id'][0];
    this.router.navigate([path]);
  }

  /* ---------Functionality to navigate to taxonomy end --------- */


  /* ---------Functionality to navigate to last visited url start --------- */

  backwardNavigation() {
    const currentUrl = sessionStorage.getItem('current_url');
    this.router.navigate([currentUrl]);
    this.clearSearchKeyword();
    this.sharedService.searchResultEvent.next({
      'searchData': [],
      'showSearchList': false
    });
    this.resetSearchFilter();
    this.setSearchTriggerEvent.emit(false);
  }

  /* ---------Functionality to navigate to last visited url end --------- */


  /* ---------Functionality to close the dropdown on clicking outside dropdown start --------- */

  toggleDropdown() {
    // console.log('Clicked');
    // this.searchFilter.toggleDropdown('close');
  }

  /* ---------Functionality to close the dropdown on clicking outside dropdown start --------- */

  /* ---------Functionality to get the result count start --------- */

  getCount() {
    if (this.searchResult.length > 0) {
      return this.searchResult.length;
    } else {
      return 0;
    }
  }

  /* ---------Functionality to get the result count end --------- */


  /* ---------Functionality to clear search text box start --------- */

  clearSearchKeyword() {
    if (this.isAdvanceSearch) {
      this.searchKeyword = '';
    } else {
      this.resetSearchFilter();
    }
    if (document.getElementById('searchTaxonomies')) {
      //only for accessibility
      this.textMessage = 'search value cleared';
      setTimeout(() => {
        this.textMessage = '';
      }, 1000);
      document.getElementById('searchTaxonomies').focus();
    }
  }

  /* ---------Functionality to clear search text box end --------- */


  onClickedOutside(e: Event) {
    // this.searchFilter.toggleDropdown('close');
    if (((<HTMLInputElement>e.target).id) === 'add-more') {
      this.advanceSearchToggle = true;
      // this.advanceSearch.openPanel(e, true, '');
    } else if (document.getElementById('search-panel') && document.getElementById('search-panel').innerText === 'Select Key') {
      this.advanceSearchToggle = false;
    } else {
      // console.log('$$$$', document.getElementById('search-panel'), '------------', document.getElementById('search-panel').innerText);
      // tslint:disable-next-line:max-line-length
      if (document.getElementById('search-panel') !== null && !((document.getElementById('search-panel').innerText).trim().includes('Search for value'))) {
        this.advanceSearchToggle = false; // done for edge browser
      }
    }
  }

  toggleSearchSuggestion(event, action) {
    const element = document.getElementById('search-suggession-panel');
    if (action === 'close') {
      if (element) {
        element.style.display = 'none';
      }
    } else {
      if (element) {
        if (element.style.display === 'none') {
          element.style.display = 'block';
          this.getSaveSearchList();
        } else {
          if (event.type !== 'click' && event.keyCode === 27) {
            element.style.display = 'none';
          }
        }
      }
    }


  }


  /* ---------Functionality to save the search criteria start --------- */

  saveSearch() {

    const obj = {
      'search_term': this.searchKeyword,
      'context_page': this.context_page,
      'search_optional_filters': this.search_optional_filters
    };

    this.saveSearchObj = obj;

    const url = GlobalSettings.SAVE_SEARCH;
    this.saveSearchObj['title'] = this.saveSearchTitle.trim();
    this.service.postService(url, this.saveSearchObj).then((res: any) => {
      this.sharedService.sucessEvent.next({
        type: 'save_search'
      });
      this.saveSearchForm.reset();
      this.getSaveSearchList();
    }).catch((ex) => {
      console.log('createMetadata error occured', ex);
    });
  }

  /* ---------Functionality to save the search criteria end --------- */


  getSaveSearchList() {
    const url = GlobalSettings.GET_SAVED_SEARCH + '?context_page=' + this.context_page;
    // const url = './assets/json/getAllMetadata.json';
    this.service.getServiceData(url).then((res: any) => {
      this.savedSearchList = res['saved_searches'];
      this.recentSearchList = res['recent_searches'];
    }).catch((ex) => {
      console.log('Error caught while fetching saved search list', ex);
    });
  }


  openSavedSearch(item) {
    this.searchKeyword = item['search_details']['search_term'];
    this.search_optional_filters = item['search_details']['search_optional_filters'];
    this.search_optional_filters.forEach(sof => {
      Object.keys(this.dropdownObject).forEach(key => {
        if (sof['element'] === key) {
          this.dropdownObject[key].forEach(element => {
            if (sof['filter_value'] === element['filter_value']) {
              element['is_selected'] = 1;
              this.searchFilter.generateFilterCriteria(this.dropdownObject, false);
              this.searchFilter.getSelectedValues(sof['element']);
            }
          });
        }
      });
    });
    this.search();
  }

  getSearchFrom(filters) {
    let searchedFromString = '';
    if (filters && (filters instanceof Array)) {
      const searchedFrom = [];

      filters.forEach(filter => {
        if (filter.element) {
          searchedFrom.push(filter.element);
        }
      });
      const uniqueSearchedFrom = Array.from(new Set(searchedFrom));
      uniqueSearchedFrom.forEach(element => {
        searchedFromString = searchedFromString + element + ',';
      });
    }
    return searchedFromString.slice(0, -1);
  }

  /* ---------Search functionality Start --------- */

  onOldSearch() {
    this.searchResult = [];
    if (this.searchKeyword && this.searchKeyword.length > 2) {
      const url = GlobalSettings.SEARCH + this.searchKeyword;
      this.service.getServiceData(url).then((res: any) => {
        res['hit'].forEach(element => {
          element['fields']['unique_node_id'] = element['id'];
          this.searchResult.push(element['fields']);
        });
        this.noDataFound = false;
        // this.dataLoaded = true;
      }).catch(ex => {
        console.log('search result exception ', ex);
      });
    } else {
      console.log('Search text length < 3');
    }
  }

  /* --------- Search functionality End --------- */


  /* ---------Generate dropdown object functionality Start --------- */

  createDynamicDropdownObj() {
    this.dropdownKeys.forEach(key => {
      this.dropdownObject[key] = [];
      this.searchResult.forEach(obj => {
        if (obj.hasOwnProperty(key)) {
          let duplicate = 0;
          if (this.dropdownObject[key].length > 0) {
            for (const i in this.dropdownObject[key]) {
              if (i) {
                if (this.dropdownObject[key][i]['key'] === obj[key][0]) {
                  duplicate = 1;
                  break;
                }
              }
            }
            if (duplicate === 0) {
              this.dropdownObject[key].push({
                key: obj[key][0],
                'is_selected': 0,
                'element': key
              });
            }
          } else {
            this.dropdownObject[key].push({
              key: obj[key][0],
              'is_selected': 0,
              'element': key
            });
          }
        }
      });
    });
  }

  /* ---------Generate dropdown object functionality End --------- */



  /* ---------Functionality to filter searched data based on filter criteria Start --------- */


  // 1. Iterate through each key / dropdown in filter criteria which contains array of items from specific dropdown
  // 2. Interate through each dropdown checkbox selected get the result and match from SearchDataObj.
  // 3. Store the result in dataAfterFilter object after checking for duplicates and goto STEP 1.

  filterSearchedData(filterCriteria) {
    let SearchDataObj = [];
    let flag = 0;
    this.dataAfterFilter = [];
    this.searchString = '';
    Object.keys(filterCriteria).forEach(filter => {
      if (flag === 0) {
        SearchDataObj = this.searchResult;
        this.dataAfterFilter = [];
      }
      flag = 1;
      let searchString = '';
      let intermediate = [];
      this.noDataFound = true;
      this.tempObj = {};
      filterCriteria[filter].forEach(element => {
        searchString = element['key'];
        this.searchString = searchString;
        intermediate = SearchDataObj.filter((obj) => {
          if (obj.hasOwnProperty(filter) && obj[filter][0].includes(searchString)) {
            return obj;
          }
        });
        if (intermediate.length > 0) {
          this.storeMatchedResult(intermediate, filter);
          this.noDataFound = false;
        }

      });

      if (this.noDataFound) {
        this.dataAfterFilter = [];
      }
      this.dataAfterFilter = [];
      Object.keys(this.tempObj).forEach(i => {
        // console.log('#', this.tempObj[i]);
        this.dataAfterFilter.push(this.tempObj[i]);
      });

      SearchDataObj = this.dataAfterFilter;
    });
    // console.log('Result', JSON.stringify(this.dataAfterFilter));
  }

  /* ---------Functionality to filter searched data based on filter criteria End --------- */


  /* ---------Functionality to check duplicates in objects filtered and store in array start --------- */

  storeMatchedResult(intermediate, filter) {
    // let duplicate = 0;
    intermediate.forEach(element => {
      this.tempObj[element.unique_node_id] = element;
    });

  }

  /* ---------Functionality to check duplicates in objects filtered and store in array end --------- */


  /* ---------Functionality to get the result count start --------- */

  getCountOld() {
    if (this.dataAfterFilter.length > 0) {
      return this.dataAfterFilter.length;
    } else {
      if (this.searchString.length > 0) {
        return 0;
      }
      return this.searchResult.length;
    }
  }

  /* ---------Functionality to get the result count end --------- */


  /* --------- Functionality to update the selected object from autocomplete start --------- */
  updateSelectedObject(searchList) {
    console.log('searchList', JSON.stringify(searchList));
  }
  /* --------- Functionality to update the selected object from autocomplete end --------- */

  onClickedAutoCompleteOutside(e: Event) {
    // this.autoComplete.openPanel(false);
  }


  toggleAdvanceSearch(e: Event, action) {
    if (((<HTMLInputElement>e.target).id) === 'add-more') {
      this.advanceSearch.openPanel(e, true, '');
    } else {
      this.advanceSearch.openPanel(e, false, '');
    }
  }

  cancleSaveSearch() {
    this.saveSearchTitle = '';
    this.saveSearchForm.reset();
  }

  showAdvanceSearch(event) {
    event.stopPropagation();
    this.isAdvanceSearch = true;
    setTimeout(() => {
      if (document.getElementById('advance-search')) {
        var element = document.getElementById('advance-search');
        element.parentNode.removeChild(element);
      }
    }, 100);
  }

  resetSearchFilter() {
    this.searchText = '';
    setTimeout(() => {
      this.emitSearchText();
    }, 300);
  }
  emitSearchText() {
    this.sharedService.searchTextEvent.next({
      text: this.searchText,
      trigger: true
    });
  }
}
