import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import Utils from '../../utils';
import {
  SharedService
} from '../../shared.service';
@Component({
  selector: 'app-exemplar-details',
  templateUrl: './exemplar-details.component.html',
  //  styleUrls: ['./exemplar-details.component.css']
})
export class ExemplarDetailsComponent implements OnInit, OnChanges {
  @Input() exemplarData: any;
  @Input() serialNo: any;
  @Input() showDelete = true;
  @Input() isRightPanel;
  @Input() viewMode = 'edit';
  @Input() option: string;
  @Input() canPreview = false;
  @Input() viewLocation = '';

  @Output() editClicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter();
  @Output() previewClicked = new EventEmitter();
  @Output() downloadClicked = new EventEmitter();

  preventEdit = false;
  constructor(private sharedService: SharedService) {
    this.sharedService.preventEdit.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
      }
    });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.option && changes.option.currentValue) {
      this.option = changes.option.currentValue;
    }
    // For asset association, there must be a file
    if (this.option === 'Document') {
      this.exemplarData.has_asset = 1;
      this.exemplarData.external_url = this.exemplarData.preview_url;
      this.exemplarData.external_download_url = this.exemplarData.download_url;
      // this.exemplarData.asset_id = this.exemplarData.asset_id; // self assignment not required
    }
  }

  formatBytes(bytes) {
    let fileSize = (Utils.formatBytes(bytes));
    let size = 0;
    let measure = '';
    if (fileSize.toLowerCase().includes('bytes')) {
      size = Math.round(Number(fileSize.substring(0, fileSize.length - 5)));
      measure = fileSize.substring(fileSize.length - 5, fileSize.length);
    } else {
      size = Math.round(Number(fileSize.substring(0, fileSize.length - 2)));
      measure = fileSize.substring(fileSize.length - 2, fileSize.length);
    }

    fileSize = size + ' ' + measure;
    // console.log(fileSize);
    return fileSize;
  }

  onDelteExemplar(id) {
    this.deleteClicked.emit(id);
  }
}
