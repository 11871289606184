import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';

import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  GlobalSettings
} from '../../global.settings';
import {
  SharedService
} from '../../shared.service';
import {
  CommonService
} from '../../common.service';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  IMyDpOptions
} from 'mydatepicker';
import Utils from '../../utils';
import {
  NavigationEnd,
  Router,
  ActivatedRoute,
  Params,
  NavigationStart
} from '@angular/router';
import {
  ConfirmationDialogService
} from '../../confirmation-dialog/confirmation-dialog.service';
import { TextEditorComponent } from '../../common/text-editor/text-editor.component';
import { AuthenticateUserService } from '../../authenticateuser.service';
import {
  environment
} from '../../../environments/environment';
import { AutoCompleteComponent } from 'src/app/common/auto-complete/auto-complete.component';
@Component({
  selector: 'app-node-details',
  templateUrl: './node-details.component.html',
  styleUrls: ['./node-details.component.scss']
})
export class NodeDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  Object = Object;
  showForm = true; // hold boolean value to load editable part of form in delayed manner for side panel
  form: FormGroup; // form instance
  metadataList = []; // list of metadata fields for Node type
  formFieldDetails = {}; // holds all values received from project authoring for prefill
  // languages = ['English (en)', 'German (gn)'];
  languages;
  selectedLanguage: any = '';
  nodeTypeId; // holds node type id
  formObj = {}; // form object to be generated dynamically basedon values of metadata fields
  nodeDetails; // holds data from authoringPageNodeData
  // startDate = '';
  // endDate = '';
  year; // holds year selected from status start date
  month; // holds month selected from status start date
  day; // holds day selected from status start date
  // sourceUrlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  // tslint:disable-next-line:max-line-length
  // sourceUrlPattern = '^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$';
  sourceUrlPattern = '((http|ftp|https):\\/\\/)?[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?';
  @Output() formData = new EventEmitter(); // emit form data after edit
  @Output() formValidate = new EventEmitter(); // emit form validation status after edit
  @Output() formObject = new EventEmitter(); // emit form object on change
  @Output() nodetypeChangeEvent = new EventEmitter();
  @Input() isEditable = false;
  @Input() view;
  @Input() viewLocation;
  @Input() isAllFieldFilled;
  @Input() showNodeType = false;
  @Input() nodetypeList;
  @Input() selectedNodetype;
  @Input() margin_val;
  @Input() isUpdatedUI;
  @Input() disableOptionList;
  selectedNewNodetype;
  isCustom = false;
  listTypeValues; // hold all selection list values from custom metadata obj
  selectedObj; // hold current value from selection list from custom metadata obj
  selectedTime; // hold current time from custom metadata obj
  allCustomDropdowns = [];
  dateObj = [];
  timeObj = [];
  customMetadataIdList = [];
  listFlag = false;
  timeFlag = false;
  blankNodeType = false;
  itemHeight = 0;
  orgId = '';
  cacheData = [];
  type = 'authoring';
  // tslint:disable-next-line:max-line-length
  // mandatoryFields = ['full_statement', 'human_coding_scheme', 'concept_title', 'license_title', 'title', 'subject_title', 'official_source_url'];

  // tslint:disable-next-line:max-line-length
  editFields = ['title', 'notes', 'publisher', 'description', 'full_statement', 'alternative_label',
    'abbreviated_statement', 'concept_title', 'concept_description', 'license_title', 'license_description', 'license_text',
    'subject_title', 'subject_description'];

  showEditor = false;
  clicked;
  mandatoryFields = [];
  nodeType;
  showMore = false;
  authoringPageNodeData = [];
  currentURL = '';
  serviceWithoutTocken = false;
  treeNodeSelectevent: Subscription;
  taxonomyTitleEvent: Subscription;
  uuidValue: any; // holds UUID value
  selectedTaxonomyType: any; // holds taxonomy type
  selectedTaxonomyTypeText: any; // holds taxonomy type
  selectedCASEstatus: any; // holds CASE status for CF item
  selectedCASEstatusText: string;
  caseStatusPermission = false; // holds permission of case status
  renamePacingGuide = false; // holds boolean to show if PG needs to be renamed based on environment selected
  @Input() showUserFriendlyPage = false;

  statusList = [
    {
      label: 'Draft',
      value: 2
    },
    {
      label: 'Adopted',
      value: 3
    },
    {
      label: 'Deprecated',
      value: 4
    }
  ];
  taxonomyTypeList = [];
  lastSelectedNode;
  public myDatePickerOptionsStartDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
  };

  public myDatePickerOptionsEndDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    disableUntil: {
      year: this.year,
      month: this.month,
      day: this.day
    }
  };

  blankNodeTypeExceptionEvent: Subscription;
  @ViewChild('textEditor', { static: false }) textEditor: TextEditorComponent;
  @ViewChild('autoComplete', { static: false }) autoComplete: AutoCompleteComponent;
  rteObject = []; // holds form fields from rte
  autoCompleteTitle = 'Node Type';
  currentPathPrefix = '';

  constructor(
    private fb: FormBuilder, private router: Router, private service: CommonService,
    private sharedService: SharedService, private dialogService: ConfirmationDialogService,
    private authenticateUser: AuthenticateUserService) {
    this.form = this.fb.group(this.formObj);

    this.blankNodeTypeExceptionEvent = this.sharedService.blankNodeTypeExceptionEvent.subscribe((item: any) => {
      if (item && item['blankNodeType']) {
        this.blankNodeType = item['blankNodeType'];
        console.log('Authoring service failed 500', this.blankNodeType);
      }
    });


    this.currentURL = this.router.url;
    if (this.currentURL.indexOf('/taxonomies/detail') !== -1) {
      this.serviceWithoutTocken = true;
      this.getDataFromCache();
    }

    this.treeNodeSelectevent = this.sharedService.treeNodeSelectedEvent.subscribe((res: any) => {
      this.showMore = false;
      this.lastSelectedNode = {
        node_id: res.source_item_id,
        isDocument: res.is_document
      };
      this.selectedNodetype = {
        title: res.metadataType,
        node_type_id: res.node_type_id
      };
      this.setUserFriendlyUrl();
    });
    this.taxonomyTitleEvent = this.sharedService.setTitleEvent.subscribe((event: any) => {
      if (event) {
        switch (event.type) {
          case 'taxonomy':
            const taxonomy = {
              'formControlName': 'title',
              'value': event.title,
              'htmlValue': event.title_html ? event.title_html : event.title
            };
            this.updateForm(taxonomy);
            break;
          default:
            break;
        }
      }
    });
    this.renamePacingGuide = environment.renamePacingGuide;
    // tslint:disable-next-line: max-line-length
    const { renamePacingGuide, showPublishedTaxonomy } = Utils.checkChangesForSpecificOrg(this.renamePacingGuide, this.router.url.split('/')[2]);
    this.renamePacingGuide = renamePacingGuide;
    if (this.renamePacingGuide) {
      this.taxonomyTypeList.forEach(element => {
        if (element.label === 'Pacing guide') {
          element.label = 'Unit Planner';
        }
      });
    }
  }

  ngOnInit() {
    this.currentURL = this.router.url;
    if (this.currentURL.indexOf('/taxonomies/detail') !== -1) {
      this.serviceWithoutTocken = true;
    }
    if (this.serviceWithoutTocken) {
      if (this.cacheData['language']) {
        this.languages = this.cacheData['language'];
        this.selectedLanguage = this.cacheData['language'][0];
      }
    } else {
      const url = GlobalSettings.LANGUAGE_LIST;
      this.service.getServiceData(url).then((res: any) => {
        this.languages = res;
        this.selectedLanguage = res[0];
      }).catch((ex) => {
        console.log('Error caught while fetching metadata list', ex);
      });
    }
    this.onChange(this.form.valid);
  }


  ngOnChanges() {
    if (this.nodetypeList) {
      this.nodetypeList.forEach(element => {
        if (element.node_type_id === this.selectedNodetype.node_type_id) {
          this.selectedNewNodetype = element;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.treeNodeSelectevent) {
      this.treeNodeSelectevent.unsubscribe();
    }
    if (this.taxonomyTitleEvent) {
      this.taxonomyTitleEvent.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // this.getItemNumber();
  }

  /* --------- Button for form submit emit function start --------- */
  getFormData() {
    document.getElementById('formSubmit').click();
  }
  /* --------- Button for form submit emit function end --------- */

  /* --------- Form data emit function start --------- */
  onSubmit(e) {
    // console.log('this.form.valid' + this.form.valid);
    // if (this.isCustom === false) {
    //   // this.formData.emit(this.form.value);
    // } else {
    let formValues = [];
    const SendObj = {};
    for (let id = 0; id < this.customMetadataIdList.length; id++) {
      for (const data in this.form.value) {
        if (data === this.customMetadataIdList[id]) {
          const tempObj = {};
          tempObj['metadata_id'] = data;
          if (typeof this.form.value[data] === 'string') { // extracting value from html string
            tempObj['metadata_value'] = this.extractContent(this.form.value[data]);
          } else {
            tempObj['metadata_value'] = this.form.value[data];
          }
          tempObj['metadata_value_html'] = this.form.value[data];
          tempObj['is_additional_metadata'] = 0;
          formValues.push(tempObj);
        }
      }
    }
    for (const data in this.form.value) {
      if (data) {
        let flag = 1;
        for (let id = 0; id < this.customMetadataIdList.length; id++) {
          if (data === this.customMetadataIdList[id]) {
            flag = 0;
            break;
          }
        }
        if (flag === 1) {
          if (typeof this.form.value[data] === 'string') { // extracting value from html string
            SendObj[data] = this.extractContent(this.form.value[data]);
          } else {
            SendObj[data] = this.form.value[data];
          }
        }
      }
    }

    if (this.dateObj.length > 0) {
      for (const date in this.dateObj) {
        if (date) {
          for (const data in formValues) {
            if (formValues[data]['metadata_id'] === this.dateObj[date]['metadata_id']) {
              formValues[data]['metadata_value'] = this.dateObj[date]['data'];
            }
          }
        }
      }
    }

    if (this.timeObj.length > 0) {
      for (const time in this.timeObj) {
        if (time) {
          for (const data in formValues) {
            if (formValues[data]['metadata_id'] === this.timeObj[time]['metadata_id']) {
              formValues[data]['metadata_value'] = this.timeObj[time]['data'];
            }
          }
        }
      }
    }
    // this.formData.emit({
    //   'custom': formValues
    // });
    formValues = formValues.filter((obj, pos, arr) => { // removing duplicate metadata
      return arr.map(mapObj => mapObj['metadata_id']).indexOf(obj['metadata_id']) === pos;
    });
    SendObj['custom'] = formValues;
    this.rteObject.forEach(element => {
      if (element.is_custom === 1) { // If custom metadata
        for (const data in SendObj['custom']) {
          if (SendObj['custom'][data]['metadata_id'] === element['metadata_id']) {
            SendObj['custom'][data]['metadata_value'] = element['value'];
            SendObj['custom'][data]['metadata_value_html'] = element['htmlValue'];
          }
        }
      } else {
        const key = element.formControlName + '_html';
        SendObj[key] = element.htmlValue;
      }
    });
    SendObj['adoption_status'] = this.selectedCASEstatus;
    SendObj['document_type'] = this.selectedTaxonomyType;
    this.formData.emit(SendObj);
    this.formValidate.emit(this.form.valid);
    // console.log('---------', JSON.stringify(SendObj));
  }
  // }
  /* --------- Form data emit function end --------- */

  /* --------- Function to get all metadata fields function start --------- */

  getDataFromCache() {
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }
    const url = GlobalSettings.FRONT_FACE_METADATA_CACHE + '/' + this.orgId;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.cacheData = res;
    }).catch((ex) => {
      console.log('getTaxonomiesList Ex', ex);
    });
  }

  getAllMetadataFieldsFromCache(id) {
    let meta = this.cacheData['nodetype'];
    if (meta && meta.length > 0) {
      meta = meta.filter(obj => {
        if (obj.node_type_id === id) {
          return obj;
        }
      });
      this.metadataList = meta[0]['metadata'];
    }
    this.updateFormObjForMetadatas();
  }


  getAllMetadataFields(id) {
    this.formObj = {};
    this.mandatoryFields = [];
    const url = GlobalSettings.SAVE_NODETYPE_METADATA + '/' + id;
    this.service.getServiceData(url).then((res: any) => {
      if (res) {
        this.metadataList = [];
        res.forEach(element => {
          if (!element.hasOwnProperty('is_additional_metadata')) {
            this.metadataList.push(element);
          }
        });
        if (this.nodeDetails.node_type === 'Document' && !this.metadataList.find(data => data.internal_name === 'title')) {
          const titleObj = {
            metadata_id: '',
            parent_metadata_id: '',
            name: 'Title',
            field_type: 1,
            field_possible_values: '',
            is_custom: 0,
            is_mandatory: 1,
            order: 2,
            internal_name: 'title',
            internal_name_html: 'title_html'
          };
          this.metadataList.splice(1, 0, titleObj);
        }
        // console.log('this.metadataList' + this.metadataList);
        this.updateFormObjForMetadatas();
      }

    }).catch((ex) => {
      console.log('Error caught while fetching metadata list', ex);
    });
  }



  updateFormObjForMetadatas() {
    console.log('updateFormObjForMetadatas 4 ', new Date());
    for (const metaObj in this.metadataList) {
      if (this.metadataList[metaObj].is_custom === 0) {
        if (this.metadataList[metaObj].internal_name === 'language_name') {
          if (this.metadataList[metaObj].is_mandatory === 1) {
            this.formObj['language_name'] = ['', [Validators.required]];
            this.mandatoryFields.push(this.metadataList[metaObj].internal_name);
          } else {
            this.formObj['language_name'] = [''];
          }
        } else {
          if (this.metadataList[metaObj].is_mandatory === 1) {
            this.formObj[this.metadataList[metaObj].internal_name] = ['', [Validators.required]];
            this.mandatoryFields.push(this.metadataList[metaObj].internal_name);
          } else {
            this.formObj[this.metadataList[metaObj].internal_name] = [''];
          }
        }
      } else {

        if (this.metadataList[metaObj].is_mandatory === 1) {
          this.formObj[this.metadataList[metaObj].metadata_id] = ['', [Validators.required]];
          this.mandatoryFields.push(this.metadataList[metaObj].metadata_id);
        } else {
          this.formObj[this.metadataList[metaObj].metadata_id] = [''];
        }
      }
    }

    // this.FillCustomForm1(this.authoringPageNodeData);
    for (const item in this.formObj) {
      if (item) {
        // console.log(JSON.stringify(item))
        for (const j in this.formFieldDetails) {
          if (item === j) {
            if (item === 'official_source_url') {
              this.formObj[item][0] = this.formFieldDetails[j];
              if (this.formObj[item][1] && this.formObj[item][1].length > 0) {
                this.formObj[item][1] = [Validators.required, Validators.pattern(this.sourceUrlPattern)];
              } else {
                this.formObj[item][1] = [Validators.pattern(this.sourceUrlPattern)];
              }
            } else if (item === 'language_name') {
              this.formObj[item][0] = this.formFieldDetails['language_name'];
            } else {
              // this.formObj[item][0] = this.formFieldDetails[j];
              this.formObj[item][0] = {
                value: this.formFieldDetails[j],
                disabled: !this.isEditable
              };
            }
            break;
          }
        }
      }
    }

    this.formObj['node_type_id'] = this.nodeTypeId;
    this.form = this.fb.group(this.formObj);
    this.FillCustomForm1(this.authoringPageNodeData);
    this.formValidate.emit(this.form.valid);
    if (!this.isEditable) {
      setTimeout(() => {
        this.getItemNumber();
      }, 200);
    }

  }
  /* --------- Function to get all metadata fields function end --------- */


  /* --------- Function to change date start --------- */
  onStartDateChanged(event) {
    this.year = event['date']['year'];
    this.month = event['date']['month'];
    this.day = event['date']['day'];
    this.myDatePickerOptionsEndDate = {
      dateFormat: 'yyyy-mm-dd',
      inline: false,
      editableDateField: false,
      openSelectorOnInputClick: true,
      disableUntil: {
        year: this.year,
        month: this.month,
        day: this.day
      }
    };
    this.form.patchValue({
      status_end_date: ''
    });
  }

  onEndDateChanged(event) {
    // this.endDate = event['formatted'];
  }
  /* --------- Function to change date end --------- */


  /* --------- Function to grab form details from based on data received from authoring start -------- */
  generateForm(authoringPageNodeData, nodeType) {
    this.userRoleRight();
    console.log('CF Item detail for CASE status >>>>>>>>>>>>>>>>>', authoringPageNodeData);
    this.nodeType = nodeType;
    this.uuidValue = authoringPageNodeData['source_uuid_id'];
    this.selectedCASEstatus = authoringPageNodeData['adoption_status'];
    this.selectedTaxonomyType = authoringPageNodeData['document_type'];
    if (this.nodeType === 'Document') {
      const CASEstatus = this.statusList.find(status => status.value === this.selectedCASEstatus);
      this.selectedCASEstatusText = CASEstatus ? CASEstatus.label : '';
      this.getDictionaryMetadata();
    }
    this.rteObject = [];
    if (authoringPageNodeData['custom_metadata'] && authoringPageNodeData['custom_metadata'].length > 0) {
      this.authoringPageNodeData = authoringPageNodeData['custom_metadata'];
    } else {
      this.authoringPageNodeData = [];
    }
    // console.log('authoring ' , JSON.stringify(authoringPageNodeData) , nodeType);
    this.nodeDetails = authoringPageNodeData;
    this.nodeTypeId = authoringPageNodeData['node_type_id'];
    this.formObj = {};
    if (authoringPageNodeData['custom_metadata'] && authoringPageNodeData['custom_metadata'].length > 0) {
      this.allCustomDropdowns = [];
      this.dateObj = [];
      this.timeObj = [];
      this.isCustom = true;
      // this.fillCustomForm(authoringPageNodeData['custom_metadata']);
    }
    // else {
    this.allCustomDropdowns = [];
    this.dateObj = [];
    this.timeObj = [];
    this.isCustom = false;
    this.fillForm();
    // }
  }

  fillForm() {
    this.formFieldDetails = {};
    if (this.nodeDetails.hasOwnProperty('creator')) {
      this.formFieldDetails['creator'] = this.nodeDetails['creator'];
    }
    if (this.nodeDetails.hasOwnProperty('title')) {
      this.formFieldDetails['title'] = this.nodeDetails['title_html'] ? this.nodeDetails['title_html'] : this.nodeDetails['title'];
    }
    if (this.nodeDetails.hasOwnProperty('official_source_url')) {
      this.formFieldDetails['official_source_url'] = this.nodeDetails['official_source_url'];
    }
    if (this.nodeDetails.hasOwnProperty('description')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['description'] = this.nodeDetails['description_html'] ? this.nodeDetails['description_html'] : this.nodeDetails['description'];
    }
    if (this.nodeDetails.hasOwnProperty('subject_title')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['subject_title'] = this.nodeDetails['subject_title_html'] ? this.nodeDetails['subject_title_html'] : this.nodeDetails['subject_title'];
    }
    if (this.nodeDetails.hasOwnProperty('subject_hierarchy_code')) {
      this.formFieldDetails['subject_hierarchy_code'] = this.nodeDetails['subject_hierarchy_code'];
    }
    if (this.nodeDetails.hasOwnProperty('subject_description')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['subject_description'] = this.nodeDetails['subject_description_html'] ? this.nodeDetails['subject_description_html'] : this.nodeDetails['subject_description'];
    }
    let langFound = false;
    if (this.nodeDetails.hasOwnProperty('language_name')) {
      if (this.languages) {
        this.languages.forEach(element => {
          if (this.nodeDetails['language_name'] === element.short_code) {
            this.selectedLanguage = element;
            langFound = true;
          }
        });
      } else {
        if (this.serviceWithoutTocken) {
          this.getDataFromCache();
          if (this.cacheData['language']) {
            this.languages = this.cacheData['language'];
            this.selectedLanguage = this.cacheData['language'][0];
            this.languages.forEach(element => {
              if (this.nodeDetails['language_name'] === element.short_code) {
                this.selectedLanguage = element;
                langFound = true;
              }
            });
          }
        }
      }
    }
    if (langFound) { // If node has language, show that
      this.formFieldDetails['language_name'] = this.selectedLanguage;
    } else { // If node does not have language, don't show
      this.formFieldDetails['language_name'] = '';
      this.selectedLanguage = '';
    }
    if (this.nodeDetails.hasOwnProperty('version')) {
      this.formFieldDetails['version'] = this.nodeDetails['version'];
    }
    if (this.nodeDetails.hasOwnProperty('status_start_date')) {
      const date = this.nodeDetails['status_start_date'];
      if (date.length > 0) {
        let year, month, day;
        [year, month, day] = date.split('-');
        month = parseInt(month, 10);
        day = parseInt(day, 10);
        this.formFieldDetails['status_start_date'] = {
          date: {
            year: year,
            month: month,
            day: day
          }
        };
      } else {
        this.formFieldDetails['status_start_date'] = this.nodeDetails['status_start_date'];
      }
    }
    if (this.nodeDetails.hasOwnProperty('status_end_date')) {
      const date = this.nodeDetails['status_end_date'];
      if (date.length > 0) {
        let year, month, day;
        [year, month, day] = date.split('-');
        month = parseInt(month, 10);
        day = parseInt(day, 10);
        this.formFieldDetails['status_end_date'] = {
          date: {
            year: year,
            month: month,
            day: day
          }
        };
      } else {
        this.formFieldDetails['status_end_date'] = this.nodeDetails['status_end_date'];
      }
    }
    if (this.nodeDetails.hasOwnProperty('license_title')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['license_title'] = this.nodeDetails['license_title_html'] ? this.nodeDetails['license_title_html'] : this.nodeDetails['license_title'];
    }
    if (this.nodeDetails.hasOwnProperty('license_description')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['license_description'] = this.nodeDetails['license_description_html'] ? this.nodeDetails['license_description_html'] : this.nodeDetails['license_description'];
    }
    if (this.nodeDetails.hasOwnProperty('license_text')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['license_text'] = this.nodeDetails['license_text_html'] ? this.nodeDetails['license_text_html'] : this.nodeDetails['license_text'];
    }
    if (this.nodeDetails.hasOwnProperty('notes')) {
      this.formFieldDetails['notes'] = this.nodeDetails['notes_html'] ? this.nodeDetails['notes_html'] : this.nodeDetails['notes'];
    }
    if (this.nodeDetails.hasOwnProperty('full_statement')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['full_statement'] = this.nodeDetails['full_statement_html'] ? this.nodeDetails['full_statement_html'] : this.nodeDetails['full_statement'];
    }
    if (this.nodeDetails.hasOwnProperty('alternative_label')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['alternative_label'] = this.nodeDetails['alternative_label_html'] ? this.nodeDetails['alternative_label_html'] : this.nodeDetails['alternative_label'];
    }
    if (this.nodeDetails.hasOwnProperty('human_coding_scheme')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['human_coding_scheme'] = this.nodeDetails['human_coding_scheme'];
    }
    if (this.nodeDetails.hasOwnProperty('list_enumeration')) {
      this.formFieldDetails['list_enumeration'] = this.nodeDetails['list_enumeration'];
    }
    if (this.nodeDetails.hasOwnProperty('sequence_number')) {
      this.formFieldDetails['sequence_number'] = this.nodeDetails['sequence_number'];
    }
    if (this.nodeDetails.hasOwnProperty('abbreviated_statement')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['abbreviated_statement'] = this.nodeDetails['abbreviated_statement_html'] ? this.nodeDetails['abbreviated_statement_html'] : this.nodeDetails['abbreviated_statement'];
    }
    if (this.nodeDetails.hasOwnProperty('concept_title')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['concept_title'] = this.nodeDetails['concept_title_html'] ? this.nodeDetails['concept_title_html'] : this.nodeDetails['concept_title'];
    }
    if (this.nodeDetails.hasOwnProperty('concept_keywords')) {
      this.formFieldDetails['concept_keywords'] = this.nodeDetails['concept_keywords'];
    }
    if (this.nodeDetails.hasOwnProperty('concept_hierarchy_code')) {
      this.formFieldDetails['concept_hierarchy_code'] = this.nodeDetails['concept_hierarchy_code'];
    }
    if (this.nodeDetails.hasOwnProperty('concept_description')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['concept_description'] = this.nodeDetails['concept_description_html'] ? this.nodeDetails['concept_description_html'] : this.nodeDetails['concept_description'];
    }
    if (this.nodeDetails.hasOwnProperty('education_level')) {
      this.formFieldDetails['education_level'] = this.nodeDetails['education_level'];
    }
    if (this.nodeDetails.hasOwnProperty('publisher')) {
      // tslint:disable-next-line:max-line-length
      this.formFieldDetails['publisher'] = this.nodeDetails['publisher_html'] ? this.nodeDetails['publisher_html'] : this.nodeDetails['publisher'];
    }
    // console.log('Form fields received ' + JSON.stringify(this.formFieldDetails));
    if (this.nodeTypeId) {
      this.blankNodeType = false;
      this.sharedService.blankNodeTypeExceptionEvent.next({
        'blankNodeType': false,
      });
      this.currentURL = this.router.url;
      if (this.currentURL.indexOf('/taxonomies/detail') !== -1) {
        this.serviceWithoutTocken = true;
      }
      if (this.serviceWithoutTocken) {
        this.getAllMetadataFieldsFromCache(this.nodeTypeId);
      } else {
        this.getAllMetadataFields(this.nodeTypeId);
      }

    } else {
      this.blankNodeType = true;
      console.log('NodeTypeId is null or undefined');
      this.sharedService.blankNodeTypeExceptionEvent.next({
        'blankNodeType': true,
      });
    }
    // setTimeout(() => {
    //   setTextareaHeight();
    // }, 1500);
  }


  fillCustomForm() {
    // if (this.listFlag === false || this.timeFlag === false) {
    for (const metaObj in this.metadataList) {
      if (this.metadataList[metaObj] && this.metadataList[metaObj].is_custom === 1) {
        for (const item in this.formObj) {
          if (item === this.metadataList[metaObj]['metadata_id']) {
            this.customMetadataIdList.push(item); // && this.listFlag === false
            if (this.metadataList[metaObj]['field_type'] === 3) {
              let existsSelFlag = false;
              const data = this.metadataList[metaObj]['field_possible_values'];
              for (const i in this.allCustomDropdowns) {
                if (i) {
                  if (this.allCustomDropdowns[i]['metadata_id'] === item) {
                    existsSelFlag = true;
                    break;
                  } else {
                    existsSelFlag = false;
                  }
                }
              }
              if (existsSelFlag === false) {
                this.allCustomDropdowns.push({
                  'metadata_id': item,
                  'data': data.toString().split(','),
                  'selectedObj': ''
                });
              } else {
                console.log('fillCustomForm Sel Object exists', item);
              } // && this.timeFlag === false
            } else if (this.metadataList[metaObj]['field_type'] === 5) {
              let existsFlag = false;
              for (const i in this.timeObj) {
                if (i) {
                  if (this.timeObj[i]['metadata_id'] === item) {
                    existsFlag = true;
                    break;
                  } else {
                    existsFlag = false;
                  }
                }
              }
              if (existsFlag === false) {
                this.timeObj.push({
                  'metadata_id': item,
                  'data': ''
                });
              } else {
                console.log('fillCustomForm Time Object exists', item);
              }
            }
          }
        }
      }
    }
    // }
    setTimeout(() => {
      this.setTextareaHeight();
    }, 150);
  }

  setTextareaHeight() {
    for (let i = 0; i < document.getElementsByTagName('textarea').length; i++) {
      const height = (document.getElementsByTagName('textarea')[i].scrollHeight + 2);
      document.getElementsByTagName('textarea')[i].style.height = (height < 70 ? 70 : height) + 'px';
    }
  }

  FillCustomForm1(custom_metadata) {
    this.listFlag = false;
    this.timeFlag = false;
    // console.log('custom_metadata fillCustomForm1', JSON.stringify(custom_metadata));
    this.customMetadataIdList = [];
    for (const item in this.formObj) {
      if (item) {
        if (custom_metadata.length > 0) {
          for (const j in custom_metadata) {
            if (j) {
              // console.log(j);
              if (item === custom_metadata[j]['metadata_id']) {
                this.customMetadataIdList.push(item);
                if (custom_metadata[j]['field_type'] === 3) {
                  this.listFlag = true;
                  const data = custom_metadata[j]['field_possible_values'];
                  let existsSelFlag = false;
                  for (const i in this.allCustomDropdowns) {
                    if (i) {
                      if (this.allCustomDropdowns[i]['metadata_id'] === item) {
                        existsSelFlag = true;
                        break;
                      } else {
                        existsSelFlag = false;
                      }
                    }
                  }
                  if (existsSelFlag === false) {
                    this.allCustomDropdowns.push({
                      'metadata_id': item,
                      'data': data.toString().split(','),
                      'selectedObj': custom_metadata[j]['metadata_value']
                    });
                    this.formObj[item][0] = custom_metadata[j]['metadata_value'];
                  } else {
                    console.log('Sel Object exists', item);
                  }
                } else if (custom_metadata[j]['field_type'] === 4) {
                  const date = custom_metadata[j]['metadata_value'];
                  if (date && date.length > 0) {
                    let year, month, day;
                    [year, month, day] = date.split('-');
                    month = parseInt(month, 10);
                    day = parseInt(day, 10);
                    this.formObj[item][0] = {
                      date: {
                        year: year,
                        month: month,
                        day: day
                      }
                    };
                  } else {
                    this.formObj[item][0] = custom_metadata[j]['metadata_value'];
                  }
                  this.dateObj.push({
                    'metadata_id': item,
                    'data': date
                  });
                } else if (custom_metadata[j]['field_type'] === 5) {
                  this.timeFlag = true;
                  this.formObj[item][0] = custom_metadata[j]['metadata_value'];
                  // this.selectedTime = custom_metadata[j]['metadata_value'];
                  const data = custom_metadata[j]['metadata_value'];
                  let existsFlag = false;
                  for (const i in this.timeObj) {
                    if (i) {
                      if (this.timeObj[i]['metadata_id'] === item) {
                        existsFlag = true;
                        break;
                      } else {
                        existsFlag = false;
                      }
                    }
                  }
                  if (existsFlag === false) {
                    this.timeObj.push({
                      'metadata_id': item,
                      'data': data
                    });
                  } else {
                    console.log('Time Object exists', item);
                  }
                } else if (custom_metadata[j]['field_type'] === 6) {
                  // this.formObj[item][0] = custom_metadata[j]['metadata_value'];
                  this.formObj[item][0] = {
                    value: custom_metadata[j]['metadata_value'],
                    disabled: !this.isEditable
                  };
                } else {
                  // this.formObj[item][0] = custom_metadata[j]['metadata_value'];
                  this.formObj[item][0] = {
                    value: custom_metadata[j]['metadata_value_html'] ? custom_metadata[j]['metadata_value_html']
                      : custom_metadata[j]['metadata_value'],
                    disabled: !this.isEditable
                  };
                }
              } else {
                // console.log('Not Matched');
                // console.log(custom_metadata[j]['metadata_id'], item);
              }
            }
          }

        } else {
          // console.log('length else ');
          // this.fillCustomForm();
        }
      }
    }
    this.fillCustomForm();
    // if (custom_metadata.length === 0) {
    //   this.fillCustomForm();
    // } else {
    //   console.log('custom_metadata.length' + JSON.stringify(custom_metadata));
    // }
    this.form = this.fb.group(this.formObj);
  }

  onCustomDateChanged(evt, id) {
    if (this.dateObj.length > 0) {
      for (const date in this.dateObj) {
        if (this.dateObj[date]['metadata_id'] === id) {
          this.dateObj[date]['data'] = evt['formatted'];
        } else {
          this.dateObj.push({
            'metadata_id': id,
            'data': evt['formatted']
          });
        }
      }
    } else {
      this.dateObj.push({
        'metadata_id': id,
        'data': evt['formatted']
      });
    }
    // console.log(this.dateObj);
  }

  onCustomTimeChanged(time, id) {
    let flag = 0;
    if (this.timeObj.length > 0) {
      for (const data in this.timeObj) {
        if (this.timeObj[data]['metadata_id'] === id) {
          flag = 1;
          this.timeObj[data]['data'] = time;
        }
      }
      if (flag === 0) {
        this.timeObj.push({
          'metadata_id': id,
          'data': time
        });
      }
    }
  }

  resetForm(id) {
    this.nodeTypeId = id;
    this.allCustomDropdowns = [];
    this.dateObj = [];
    this.timeObj = [];
    this.getAllMetadataFields(this.nodeTypeId);
  }
  /* --------- Function to grab form details from based on data received from authoring end -------- */

  /* --------- Emit form validation status function start --------- */
  onChange(e) {
    // console.log(this.form.valid);
    this.formValidate.emit(this.form.valid);
    this.formObject.emit(this.form);
  }
  /* --------- Emit form validation status function end --------- */

  // Textarea height resizing
  autoGrow(elementId) {
    const textArea = document.getElementById(elementId);
    setTimeout(() => {
      if (textArea) {
        textArea.style.cssText = 'height:auto;overflow:hidden;';
        textArea.style.cssText = 'height:' + (textArea.scrollHeight + 2) + 'px';
      }
    }, 0);
  }

  isMandatory(name) {
    let val = false;
    for (let index = 0; index < this.mandatoryFields.length; index++) {
      if (this.mandatoryFields[index] === name) {
        val = true;
        break;
      }

    }
    return val;
  }

  isNumber(evt) {
    Utils.isNumber(evt);
  }

  checkValidation() {
    this.formValidate.emit(this.form.valid);
  }

  /* --------- Function to convert date into string to show in label start --------- */

  getDate(date) {
    let dateString = '';
    dateString = date['date']['year'] + '-' + date['date']['month'] + '-' + date['date']['day'];
    return dateString;
  }

  /* --------- Function to convert date into string to show in label end --------- */

  /**
   * On change of nodetype selection
   */
  onChangeNodetype(nodeType) {
    this.selectedNewNodetype = nodeType;
    this.dialogService.confirm('Confirm', 'The data may be lost if the node type is changed')
      .then((confirmed) => {
        if (confirmed) {
          for (const data in this.form.value) {
            if (this.form.value[data]) {
              this.formFieldDetails[data] = this.form.value[data];
            }
          }
          this.selectedNodetype = JSON.parse(JSON.stringify(this.selectedNewNodetype));
          this.nodeType = this.selectedNodetype.title;
          this.resetForm(this.selectedNodetype.node_type_id);
          this.nodetypeChangeEvent.emit(this.selectedNodetype);
        } else {
          this.nodetypeList.forEach(element => {
            if (element.node_type_id === this.selectedNodetype.node_type_id) {
              this.selectedNewNodetype = element;
            }
          });
        }
      })
      .catch(() => {
        console.log('User dismissed the dialog');
      });
  }

  // Adding ng-touched class on blur event of time field
  onTimeFieldBlur(event) {
    if (event.target && event.target.classList) {
      event.target.classList.replace('ng-untouched', 'ng-touched');
    }
  }

  /**
   * Valid/Invalid class adding in input field of my-date-picker for mandatory
   * @param event (date-picker event)
   * @param id (Field's id)
   * @param metadataId (metadata id)
   */
  onDateFieldChange(event, id, metadataId?) {
    const fieldName = metadataId ? metadataId : id;
    if (this.isMandatory(fieldName)) { // mandatory field checking
      if (document.querySelectorAll('#' + id + ' input')[0].classList.contains('form-control') === false) {
        document.querySelectorAll('#' + id + ' input')[0].classList.add('form-control');
      }
      if (event.formatted === undefined || event.formatted.trim() === '') { // invalid checking
        document.querySelectorAll('#' + id + ' input')[0].classList.remove('ng-valid'); // Remove valid class
        document.querySelectorAll('#' + id + ' input')[0].classList.add('ng-invalid'); // Add invalid class
      } else {
        // valid
        document.querySelectorAll('#' + id + ' input')[0].classList.remove('ng-invalid'); // Remove invalid class
        document.querySelectorAll('#' + id + ' input')[0].classList.add('ng-valid'); // Add valid class
      }
    }
  }

  changeView() {
    this.showMore = !this.showMore;
  }

  getItemNumber() {
    if (document.getElementById('form_container')) {
      this.itemHeight = document.getElementById('form_container').scrollHeight;
    }
  }

  setNodeType(selectedNodetype) {
    this.selectedNodetype = selectedNodetype;
    if (this.nodetypeList) {
      this.nodetypeList.forEach(element => {
        if (element.node_type_id === this.selectedNodetype.node_type_id) {
          this.selectedNewNodetype = element;
        }
      });
    }

  }

  /* --------- Functionality to open editor and set data start --------- */

  openEditor(nodeTypeItem, formObjControls) {
    if (nodeTypeItem['is_custom'] === 0) {
      this.clicked = nodeTypeItem['internal_name'];
    } else {
      this.clicked = nodeTypeItem['metadata_id'];
    }
    setTimeout(() => {
      Utils.removeShowClass();
      for (const key in formObjControls) {
        if (key === this.clicked && this.textEditor) {
          this.textEditor.setData(nodeTypeItem, formObjControls[key], key, this.rteObject);
        }
      }
    }, 500);
  }

  /* --------- Functionality to open editor and set data end --------- */


  /* --------- Functionality to update object of rte start --------- */

  updateFieldFromEditor(evt) {
    let flag = 0;
    if (this.rteObject.length) {
      for (const i in this.rteObject) {
        if (this.rteObject[i]['metadata_id'] === evt['metadata_id']) {
          this.rteObject[i] = evt;
          flag = 1;
          break;
        }
      }
    }
    if (flag === 0) {
      this.rteObject.push(evt);
    }
    this.updateForm(evt);
  }

  /* --------- Functionality to update object of rte end --------- */


  /* --------- Functionality to update form field with data from editor start --------- */

  updateForm(evt) {
    for (const item in this.formObj) {
      if (item === evt['metadata_id'] || item === evt['formControlName']) {
        if (this.formObj[item][0] && this.formObj[item][0]['value']) {
          this.formObj[item][0]['value'] = evt.value;
        }
        if (this.form.controls[item]) {
          this.form.controls[item].setValue(evt.htmlValue ? evt.htmlValue : evt.value);
        }
        break;
      }
    }
  }

  /* --------- Functionality to update form field with data from editor end --------- */

  validate(nodeTypeItem: any) {
    let edit = false;
    if (nodeTypeItem) {
      if (nodeTypeItem['is_custom'] === 0) {
        for (const i in this.editFields) {
          if (this.editFields[i] === nodeTypeItem['internal_name']) {
            edit = true;
            break;
          }
        }
      } else {
        edit = true;
      }
    }
    return edit;
  }

  /* --------- Functionality to string from html content from rte start --------- */

  extractContent(html) {
    return (new DOMParser).parseFromString(html, 'text/html').documentElement.textContent;

  }

  /* --------- Functionality to string from html content from rte end --------- */


  updateShowEditor(evt) {
    this.showEditor = evt;
  }

  hideControl(formObj) {
    if (!this.clicked) {
      return false;
    } else {
      if (this.showEditor) {
        if (formObj['is_custom'] === 0) {
          return (this.clicked === formObj['internal_name']);
        } else {
          return (this.clicked === formObj['metadata_id']);
        }
      }
    }
  }

  getHtml(formObj) {
    let flag = 0;
    let htmlContent = '';
    if (this.rteObject.length) {
      for (const i in this.rteObject) {
        if (this.rteObject[i]['metadata_id'] === formObj.metadata_id) {
          flag = 1;
          htmlContent = this.rteObject[i]['htmlValue'];
          break;
        }
      }
    }
    if (flag === 0) {
      if (formObj.is_custom === 1) { // If custom metadata
        let i = 0;
        for (i = 0; i < this.nodeDetails['custom_metadata'].length; i++) {
          if (this.nodeDetails['custom_metadata'][i]['metadata_id'] === formObj.metadata_id) {
            break;
          }
        }
        if (i < this.nodeDetails['custom_metadata'].length) {
          htmlContent = this.nodeDetails['custom_metadata'][i]['metadata_value_html'] ?
            this.nodeDetails['custom_metadata'][i]['metadata_value_html'] : this.nodeDetails['custom_metadata'][i]['metadata_value'];
        }
      } else {
        if (this.nodeDetails.hasOwnProperty(formObj.internal_name)) {
          htmlContent = this.nodeDetails[formObj.internal_name + '_html'] ? this.nodeDetails[formObj.internal_name + '_html']
            : this.nodeDetails[formObj.internal_name];
        }
      }
    }
    return htmlContent;
  }

  checkEditorStatus() {
    return this.showEditor;
  }

  checkRichTextEditorShow(nodeTypeItem: any) {
    if (nodeTypeItem['is_custom'] === 0) {
      return (this.clicked === nodeTypeItem['internal_name']);
    } else {
      return (this.clicked === nodeTypeItem['metadata_id']);
    }
  }

  /* --------- Functionality for fetching Case Field name from Internal name start --------- */

  getCaseField(internal_name) {
    return Utils.caseField[internal_name];
  }

  /* --------- Functionality for fetching Case Field name from Internal name end --------- */

  userRoleRight() {
    if (this.authenticateUser.authenticTaxonomy('Change CASE Status')) {
      this.caseStatusPermission = true;
    } else {
      this.caseStatusPermission = false;
    }
  }

  // To fetch 'Dictionary' type metadata's field values list
  getDictionaryMetadata() {
    if (localStorage.getItem('access_token')) {
      this.taxonomyTypeList = [];
      // For getting any metadata's field values with internal name, we have to send any random id as per backend logic
      const url = GlobalSettings.GET_METADATA_LIST + '/random_id' + '?internal_name=Taxonomy Type';
      this.service.getServiceData(url).then((res: any) => {
        if (res && res.length > 0) {
          res.forEach((element: any) => {
            this.taxonomyTypeList.push(
              {
                label: element.value,
                value: element.key
              }
            );
          });
        }
        const TaxonomyType = this.taxonomyTypeList.find(type => type.value === this.selectedTaxonomyType);
        this.selectedTaxonomyTypeText = TaxonomyType ? TaxonomyType.label : '';
      }).catch((ex) => {
        console.log('Error caught while fetching dictionary type metadata', ex);
      });
    }
  }

  onClickedOutside(event) {
    this.autoComplete.openPanel(false);
  }

  setUserFriendlyUrl() {
    this.currentPathPrefix = GlobalSettings.BASE_URL.replace('server/', '#').replace('api.', '') + this.service.getOrgDetails() + '/public/info';
  }

  openInNewTab(event, id) {
    const currentUrl = sessionStorage.getItem('current_url');
    const frontFaceUrl = sessionStorage.getItem('frontface_url');
    sessionStorage.clear();
    const url = this.currentPathPrefix + '?id=' + this.lastSelectedNode.node_id + '&isDocument=' + this.lastSelectedNode.isDocument;
    const win = window.open(url, '_blank');
    sessionStorage.setItem('current_url', currentUrl);
    sessionStorage.setItem('frontface_url', frontFaceUrl);
    win.focus();
    event.preventDefault();
  }

  /* --------- Functionality to load editable part of form in delayed manner for side panel to reflect changes in html start --------- */

  resetFormObj() {
    this.showForm = false;
    this.form = this.fb.group({});
    setTimeout(() => {
      this.showForm = true;
    }, 200);
  }

  /* --------- Functionality to load editable part of form in delayed manner for side panel to reflect changes in html end --------- */

}
