import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  ActivatedRoute,
  Params,
  NavigationStart
} from '@angular/router';
import {
  SharedService
} from '../../shared.service';
import {
  CommonService
} from '../../common.service';
import {
  GlobalSettings
} from '../../global.settings';
import {
  Subscription
} from 'rxjs/Subscription';
import { LoginService } from 'src/app/login/login.service';
import Utils from 'src/app/utils';
@Component({
  selector: 'app-front-facing',
  templateUrl: './front-facing.component.html'
})
export class FrontFacingComponent implements OnInit, OnDestroy {

  isPublic = false;
  title = ''; // holds the title to be displayed based on route path
  selectedTopMenu = ''; // holds the selected tab based on route path
  currentURL = ''; // holds the current url
  searchPlaceholder; // holds the place holder for search input box
  searchTrigger = false; // holds the boolean condition for search component to toggle
  orgCode = ''; // holds the org code from the url
  orgId = ''; // holds the org id from the url

  taxonomyTitleEvent: Subscription;
  taxonomyDetail = false;
  prevOrgCode = '';
  constructor(
    private router: Router, private activatedRoute: ActivatedRoute, private service: CommonService,
    private sharedService: SharedService, private loginService: LoginService) {
    this.isPublic = false;
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['org_code'] !== undefined) {
        if (params['org_code'].length === 5) {
          this.prevOrgCode = params['org_code'];
          this.getOrgCode(params['org_code']);
        } else {
          this.getOrgCode(this.prevOrgCode);
        }
      } else {
        this.getOrgCode('undefined');
      }
    });

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      if (event.url.indexOf('/taxonomies/detail') >= 0) {
        this.taxonomyDetail = true;
      } else {
        this.taxonomyDetail = false;
      }
    });

    // this.sharedService.orgDetails.subscribe((event: any) => {
    //   if (event.orgCode !== undefined) {
    //     this.orgCode = event.orgCode;
    //   }
    // });
  }

  ngOnInit() {
    if (document.querySelector('body')) {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }

  getOrgCode(org_code) {
    // localStorage.removeItem('access_token');
    const url = GlobalSettings.GET_ORGANIZATION_CODE + '/' + org_code;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.orgCode = res['org_code'];
      this.orgId = res['organization_id'];
      localStorage.setItem('orgDetails', JSON.stringify({
        orgCode: this.orgCode,
        orgId: this.orgId
      }));

      if (org_code !== this.orgCode) {
        if (sessionStorage.getItem('frontface_url')) {
          console.log('$$ FRONT IF $$', '--org_code--', org_code, '--this.orgCode--', this.orgCode);
          // this.router.navigate([sessionStorage.getItem('frontface_url')]);
        } else {
          console.log('$$ FRONT ELSE $$', '--org_code--', org_code, '--this.orgCode--', this.orgCode, this.router.url);
          this.router.navigate(['/org/' + this.orgCode + '/home']);
        }

      } else {
        console.log('Match Found Front');
      }
      this.sharedService.orgDetails.next(res);
    }).catch(err => { });
  }

  searchTriggered() {
    console.log('this.currentURL', this.currentURL);
    if (this.currentURL) {
      this.searchPlaceholder = this.currentURL.split('/')[this.currentURL.split('/').length - 2];
      if (this.searchPlaceholder === 'taxonomies') {
        this.searchPlaceholder = 'Taxonomies';
      }
      if (this.searchPlaceholder === 'pacingguides') {
        this.searchPlaceholder = 'Pacing Guide';
      }
      if ((this.currentURL.indexOf('taxonomies') !== -1) || (this.currentURL.indexOf('pacingguides') !== -1)) {
        this.searchTrigger = true;
      } else {
        this.searchTrigger = false;
      }
    } else {
      this.searchTrigger = false;
    }
  }

  setSearchTrigger($event) {
    this.searchTrigger = $event;
  }

  ngOnDestroy() {
    if (this.taxonomyTitleEvent) {
      this.taxonomyTitleEvent.unsubscribe();
    }
  }

}
