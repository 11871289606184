import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ConfirmationDialogService
} from './../../confirmation-dialog/confirmation-dialog.service';
import {
  GlobalSettings
} from '../../global.settings';
import {
  CommonService
} from '../../common.service';
import {
  SharedService
} from '../../shared.service';
import {
  TreeDataService
} from '../../tree-data.service';
import Utils from '../../utils';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  saveAs
} from 'file-saver';
import {
  AuthenticateUserService
} from '../../authenticateuser.service';
@Component({
  selector: 'app-export-publish',
  templateUrl: './export-publish.component.html'
})
export class ExportPublishComponent implements OnInit {
  @Input() publishPermission = false;
  @Input() canUnpublishTaxonomy = false;
  @Input() taxonomyId;
  @Input() taxonomyTitle;
  @Input() taxonomyData;
  @Input() currentTab;
  @Input() exportPermission = true;
  @Input() option: string;
  @Input() publishStatus: string;
  @Input() adoptionStatus = 2;
  @Input() exportedHistory = [];
  @Input() exportCSV = true;
  @Input() isCustomView = false;
  @Input() dropdownList = []; // holds list of export file formats
  @Input() isOnlyExport = false; // holds flag for export only functionality
  @Output() exportEvent: EventEmitter<any>;
  @Output() publishEvent: EventEmitter<any>;

  selectedFileType: string = null;

  caseDataPercent = 0;
  coverageDataPercent = 0;
  unMappedNodes = [];
  associatedProjects = [];
  publishHistoryList = [];
  project_status = true;
  form: FormGroup;
  caseStatusPermission = false; // holds permission of case status
  listOfColumn = [{
    name: 'Project',
    propName: 'project_name',
    class: '',
    type: 'text'
  },
  {
    name: 'Workflow Status',
    propName: 'workflow_status',
    class: '',
    type: 'text'
  },
  {
    name: 'Open Issues',
    propName: 'open_comment_count',
    class: '',
    type: 'text'
  }
  ];
  // ----------------for Common Data table----------------- //
  listOfColumnPublishHistory = [{
    name: 'Published date',
    propName: 'publish_date',
    class: '',
    type: 'date',
    dateType: 'amCalendar'
  },
  {
    name: 'Published By',
    propName: 'published_by',
    class: '',
    type: 'text'
  },
  {
    name: 'Unpublished Date',
    propName: 'unpublish_date',
    class: '',
    type: 'date',
    dateType: 'amCalendar'
  },
  {
    name: 'Unpublished By',
    propName: 'unpublished_by',
    class: '',
    type: 'text'
  }
  ];

  optionList = [];

  preventEdit = false;
  selectedCASEstatus = 2;
  publishLoader = false;
  downloadLoader = false;
  publishVersionTag = ''; // holds current publish version tag of taxonomy

  constructor(
    private sharedService: SharedService,
    private service: CommonService,
    private dialogService: ConfirmationDialogService,
    private authenticateUser: AuthenticateUserService) {
    this.exportEvent = new EventEmitter<any>();
    this.publishEvent = new EventEmitter<any>();
    this.sharedService.publishEvent.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
      }
    });
  }

  ngOnInit() {
    this.form = new FormGroup({
      file_format: new FormControl(null, [Validators.required])
    });
    this.getAssociatedProjects();
    this.onTaxonomySelected();
    if (this.adoptionStatus) {
      this.selectedCASEstatus = this.adoptionStatus;
    } else {
      this.selectedCASEstatus = 2;
    }
    if (!this.isOnlyExport) {
      this.getPublishHistory();
    }
    this.userRoleRight();
    this.optionList = [{
      name: 'Download',
      type: 'downloadDocument',
      value: true,
      propName: 'downloadUrl',
      url: false,
      check: 'isDownload'
    }];
  }

  getAssociatedProjects() {
    const url = GlobalSettings.PROJECT_STATUS_TO_PUBLISH + '/' + this.taxonomyId;
    this.service.getServiceData(url).then((res: any) => {
      this.associatedProjects = res;
      this.checkStatus(this.associatedProjects);
    }).catch((ex) => {
      console.log('Exception getUnmappedNodes');
    });
  }

  checkStatus(associatedProjects) {
    let flag = 0;
    for (let i = 0; i < associatedProjects.length; i++) {
      if (associatedProjects.length > 0 && associatedProjects[i]['open_comment_count'] > 0) {
        flag = 1;
        break;
      }
    }
    if (flag === 1) {
      this.project_status = false;
    } else {
      this.project_status = true;
    }
  }


  publishTaxonomy(event) {
    event.preventDefault();
    event.stopPropagation();
    // if (this.caseDataPercent && this.coverageDataPercent) {
    let confirmationMsg = 'Are you sure you want to publish?';
    if (this.caseDataPercent !== 100 || this.coverageDataPercent !== 100) {
      confirmationMsg = 'This taxonomy is CASE compliant ' + this.caseDataPercent +
        '% and mandatory field compliant ' + this.coverageDataPercent + '%. Are you sure you want to publish ?';
    } else if (this.unMappedNodes.length > 0) {
      confirmationMsg = 'This taxonomy has nodes that are not assigned to projects.Are you sure you want to publish?';
    }
    this.dialogService.confirm('Confirm', confirmationMsg)
      .then((confirmed) => {
        if (confirmed) {
          this.preventEdit = true;
          const url = GlobalSettings.PUBLISH_TAXONOMY;
          const body = {
            adoption_status: this.selectedCASEstatus,
            document_id: this.taxonomyId,
            publish_status: 1,
            version_tag: this.publishVersionTag
          };
          this.service.postService(url, body).then((res: any) => {
            // console.log('publish res', res);
            this.sharedService.sucessEvent.next({
              type: 'publish_taxonomy'
            });

            this.closePublish();
            if (!this.isOnlyExport) {
              this.getPublishHistory();
              this.publishEvent.emit(this.preventEdit);
            }
          }).catch((ex) => {
            console.log('publish service error occured', ex);
            this.preventEdit = false;
          });
        } else {
          console.log('User cancel the dialog');
        }
      })
      .catch(() => {
        console.log('User dismissed the dialog');
      });
    // }
  }

  getCaseData(data) {
    this.caseDataPercent = data.progressPercentage;
  }

  getCoverageData(data) {
    this.coverageDataPercent = data.progressPercentage;
  }

  onTaxonomySelected() {
    if (this.taxonomyData) {
      this.iterate(this.taxonomyData, 0);
    }
  }



  iterate(current, depth) {
    if (current && current.children) {
      const children = current.children;
      if (current.project_enabled) {
        if (current.project_enabled === '0') {
          if (current.is_document === 1) {
            this.unMappedNodes.push({
              'key': current.id,
              'value': current.title
            });
          } else {
            this.unMappedNodes.push({
              'key': current.id,
              'value': current.human_coding_scheme + ' ' + current.full_statement
            });
          }
        }
      }
      for (let i = 0, len = children.length; i < len; i++) {
        this.iterate(children[i], depth + 1);
      }
    }
  }


  /* --------- Cancel function start --------- */

  closePublish() {
    if (document.getElementById('publishModal')) {
      document.getElementById('publishModal').click();
    }
  }

  /* --------- Cancel function end --------- */

  onCancel() {
    this.selectedFileType = null;
    this.form.reset();
  }


  /**
   * On Export modal open
   */
  onExportModalOpen() {
    this.onCancel();
    setTimeout(() => {
      focusOnModalCloseBtn();
    }, 500);
  }

  /**
   * On Export confirm clicked
   * @param event
   */
  onExportClick(event) {
    if (this.selectedFileType) {
      this.exportEvent.emit(this.selectedFileType);
    }
  }

  getPublishHistory() {
    const url = GlobalSettings.TAXONOMY_PUBLISH_HISTORY + '/' + this.taxonomyId;
    this.publishHistoryList = [];
    this.publishLoader = true;
    this.service.getServiceData(url).then((res: any) => {
      console.log('publish history response --->', res);
      this.publishHistoryList = res;
      Utils.sortDataArray(this.publishHistoryList, 'publish_number', false, false);
      this.publishHistoryList.forEach(item => {
        let uuid;
        if (item.unpublish_date === '0000-00-00 00:00:00') {
          item.unpublish_date = '';
        }
        if (item.taxonomy_json_file_s3) {
          uuid = item.taxonomy_json_file_s3.replace('.json', '');
        } else {
          uuid = this.taxonomyId;
        }
        item.downloadUrl = GlobalSettings.DOWNLOAD_PUBLISHED_TAXONOMY + '/' + uuid;
        item.isDownload = true;
        item.showOptions = true;
      });
      this.publishLoader = false;
    }).catch((ex) => {
      this.publishHistoryList = [];
      this.publishLoader = false;
      console.log('getPublishHistory ex ', ex);
    });
  }

  unpublishTaxonomy() {
    const url = GlobalSettings.PUBLISH_TAXONOMY;
    const body = {
      adoption_status: this.selectedCASEstatus,
      document_id: this.taxonomyId,
      publish_status: 2
    };
    this.preventEdit = false;
    this.service.postService(url, body).then((res: any) => {
      console.log('unpublished', res);
      this.sharedService.sucessEvent.next({
        type: 'unpublish_taxonomy'
      });
      if (!this.isOnlyExport) {
        this.getPublishHistory();
        this.publishEvent.emit(this.preventEdit);
      }
    });
  }

  downloadTaxonomyJSON(event) {
    const url = event.downloadUrl;
    const type = 'JSON_WITH_FORMATTED';
    const contentType = 'text/plain';
    const publishId = event.taxonomy_json_file_s3;
    this.downloadLoader = true;
    this.service.downloadServiceData(url, type).then((res: any) => {
      try {
        if (res.data !== null) {
          this.saveToFileSystem(res, contentType, publishId);
        } else {
          this.sharedService.sucessEvent.next({
            customMsg: res.message,
            type: 'error'
          });
          this.downloadLoader = false;
        }
      } catch (error) {
        console.log('onExportTaxonomy error ', error);
        this.downloadLoader = false;
      }

    }).catch(ex => {
      this.downloadLoader = false;
    });
  }

  private saveToFileSystem(response, contentType, name) {
    let blob = null;
    blob = new Blob([JSON.stringify(response.data)], {
      type: contentType
    });

    const title = name;

    saveAs(blob, title);
    this.downloadLoader = false;
  }

  onOptionClicked(event) {
    switch (event.clickedOn) {
      case 'downloadDocument':
        this.downloadTaxonomyJSON(event.data);
        break;
      default:
    }
  }


  userRoleRight() {
    if (this.authenticateUser.authenticTaxonomy('Change CASE Status')) {
      this.caseStatusPermission = true;
    } else {
      this.caseStatusPermission = false;
    }
  }

}
