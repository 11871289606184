import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalSettings } from '../../global.settings';
import { TreeDataService } from '../../tree-data.service';
import Utils from '../../utils';
import { CommonService } from '../../common.service';
import { SharedService } from '../../shared.service';
import { TabularTreeSidePaneComponent } from '../tabular-tree-side-pane/tabular-tree-side-pane.component';
import { ProjectMappedNodeService } from '../../project-mapped-node.service';


@Component({
  selector: 'app-tabular-tree-cell',
  templateUrl: './tabular-tree-cell.component.html'
})
export class TabularTreeCellComponent implements OnInit, OnChanges {

  // @Input() items;
  items;
  taxonomyId;
  orgId; // holds organisation id
  rawTreeData;
  parsedTreeData;
  taxonomyTitle = '';
  preventEdit = true;
  title;
  dataReceived = false;
  viewLocation = 'front-facing';
  addedAdditionalMetadata;
  hierarchyUrl = '';
  detailUrl = '';
  complianceUrl = '';
  withTocken = false;
  itemComplianceReports = [];
  showComments = false;
  dataWithAllComments;
  dataWithAssignedComments;
  projectComments = null;
  documentFutureOffset;
  offsetObject = {};
  limit = 1000;
  clicked = '';
  detailsData = {};

  @ViewChild('tabularTreeSidePaneComponent', { static: false }) tabularTreeSidePaneComponent: TabularTreeSidePaneComponent;
  @Input() listOfColumn;
  @Input() assignedToMe;

  @Input() listOfClass;
  @Input() dynamicClass;
  charLimit = 120;
  filenameCharlmt = 20;
  constructor(
    private route: ActivatedRoute, private service: CommonService,
    private router: Router, private mappedNodeService: ProjectMappedNodeService) {
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
      this.route.params.subscribe(params => {
        this.taxonomyId = params.id;
        this.onTaxonomySelected(params.id);
      });
    }

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      if (event.url.indexOf('/taxonomies') >= 0) {
        setTimeout(() => {
          if (document.getElementById('Taxonomies-button')) {
            const element = document.getElementById('Taxonomies-button');
            element.className += ' active';
          }
        }, 100);
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() { }


  onTaxonomySelected(id) {
    this.hierarchyUrl = '';
    this.detailUrl = '';
    if (this.router.url.indexOf('/taxonomies') >= 0) {
      this.hierarchyUrl = GlobalSettings.FRONT_FACE_TAXONOMY_HIERARCHY + id + '?type=hierarchy&organization_id=' + this.orgId;
      this.detailUrl = GlobalSettings.FRONT_FACE_TAXONOMY_DETAIL + this.taxonomyId + '?type=details&organization_id=' + this.orgId;
      this.withTocken = false;
      this.showComments = false;
    } else if (this.router.url.indexOf('taxonomy/detail') >= 0) {
      this.hierarchyUrl = GlobalSettings.TAXONOMY_CACHE_DETAIL_POC + id + '?offset=0&limit=' + this.limit + '&is_document=1';
      // this.hierarchyUrl = GlobalSettings.TAXONOMY_CACHE_HIERARCHY + id + '?type=hierarchy&organization_id=' + this.orgId;
      this.detailUrl = GlobalSettings.TAXONOMY_CACHE_DETAIL + this.taxonomyId + '?type=details&organization_id=' + this.orgId;
      this.complianceUrl = GlobalSettings.ITEM_COMPLIANCE_REPORT + '/' + this.taxonomyId + '/1';
      this.withTocken = true;
      this.showComments = false;
    } else if (this.router.url.indexOf('project/detail') >= 0) {
      this.hierarchyUrl = GlobalSettings.PROJECT_CACHE_HIERARCHY + id + '?type=hierarchy';
      this.detailUrl = GlobalSettings.PROJECT_CACHE_DETAIL + this.taxonomyId + '?type=details';
      this.complianceUrl = GlobalSettings.ITEM_COMPLIANCE_REPORT + '/' + this.taxonomyId + '/2';
      this.withTocken = true;
      this.showComments = true;
    }

    if (id && this.hierarchyUrl !== '') {
      if (!this.withTocken) {
        this.service.getUserServiceDataWithoutTocken(this.hierarchyUrl).then((res: any) => {
          this.rawTreeData = res;
          this.documentFutureOffset = res.futureOffset;
          this.performOperations();
        }).catch(ex => {
          console.log('list of taxonomies ex ', ex);
        });
      } else {
        this.service.getServiceData(this.hierarchyUrl).then((res: any) => {
          this.rawTreeData = res;
          // console.log('res', JSON.stringify(res));
          this.documentFutureOffset = res.futureOffset;
          this.performOperations();
          this.dataReceived = true;
        }).catch((ex) => {
          this.dataReceived = true;
          console.log('list of taxonomies ex ', ex);
        });
      }

    }
  }

  performOperations() {
    this.parsedTreeData = this.generateTree();
    if (this.parsedTreeData && this.parsedTreeData[0]['children']) {
      Utils.sortData(this.parsedTreeData[0]['children']);
    }

    if (this.parsedTreeData && this.parsedTreeData[0]['children'] && this.parsedTreeData[0]['children'].length > 0) {
      this.taxonomyTitle = this.parsedTreeData[0]['children'][0].full_statement;
    }

    this.setData();
  }


  generateTree() {
    this.mappedNodeService.setTreeResponse(this.rawTreeData);
    this.mappedNodeService.setNodes();
    this.mappedNodeService.setRelations();
    return this.mappedNodeService.parseTreeData();
  }

  openDetails(item) {
    this.getSelectedTreeItemDetail(item);
    // if (this.tabularTreeSidePaneComponent) {
    //   this.tabularTreeSidePaneComponent.setData(newObject);
    //   this.tabularTreeSidePaneComponent.showHideNav(true);
    // }
  }


  getSelectedTreeItemDetail(item) {
    let url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + item.id;
    if (item['is_document'] === 1) {
      url = GlobalSettings.GET_CFDOC_ITEM_DETAIL + '/' + item.id;
    } else {
      url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + item.id;
    }
    this.service.getServiceData(url).then((res: any) => {
      const newObject = {};
      delete Object.assign(newObject, res, { ['itemExemplars']: res['exemplar_associations'] })['exemplar_associations'];
      delete Object.assign(newObject, res, { ['itemAssets']: res['assets'] })['assets'];
      delete Object.assign(newObject, res, { ['itemAssociations']: res['item_associations'] })['item_associations'];
      this.addedAdditionalMetadata = [];
      if (res.custom_metadata) {
        res.custom_metadata.forEach(obj => {
          if (obj.is_additional_metadata === 1) {
            this.addedAdditionalMetadata.push(obj);
            newObject['addedAdditionalMetadata'] = this.addedAdditionalMetadata;
          }
        });
      }

      if (this.tabularTreeSidePaneComponent) {
        this.tabularTreeSidePaneComponent.setData(newObject);
        this.tabularTreeSidePaneComponent.showHideNav(true);
      }

    }).catch((ex) => {
      console.log('getSelectedTreeItemDetail ', ex);
    });
  }

  calculatePadding(level) {
    /* if (this.viewLocation === 'taxonomy_builder') {
       return Utils.calculateTaxonomyBuilderPadding(level, this.treeType);
     } else {
       return Utils.calculatePadding(level, this.treeType);
     }*/
    return Utils.calculatePadding(level, 'treeview');
  }


  setData() {
    this.items = this.parsedTreeData[0]['children'];
    // this.dataWithAllComments[0]['title'] = this.title;
    this.dataReceived = true;
    // console.log('Complete Tree with data #', JSON.stringify(this.parsedTreeData[0]['children']));
  }

  navigate(id) {
    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_AUTHORING;
    localStorage.setItem('project_type', '1');
    this.router.navigate([path, this.taxonomyId, id]);
  }


  fetchNodes(item) {
    if (item.is_document === 1) {
      this.hierarchyUrl = GlobalSettings.TAXONOMY_CACHE_DETAIL_POC + this.taxonomyId + '?offset=' + this.documentFutureOffset
        + '&limit=' + this.limit + '&is_document=1';
      this.service.getServiceData(this.hierarchyUrl).then((res: any) => {
        res['nodes'].forEach(element => {
          if (element.id !== this.taxonomyId) {
            element['children'] = [];
            element['level'] = item.level + 1;
            element.hasMoreChild = true;
            this.items[0]['children'].push(element);
          }
        });
        item.hasMoreChild = false;
        // console.log('RES', JSON.stringify(this.items));
        this.documentFutureOffset = res.futureOffset;
      }).catch((ex) => {
        console.log('list of taxonomies ex ', ex);
      });
    } else {
      this.offsetObject['item.id'] = 0;
      this.hierarchyUrl = GlobalSettings.TAXONOMY_CACHE_DETAIL_POC + item.id + '?offset=' + this.offsetObject['item.id']
        + '&limit=' + this.limit + '&is_document=0';
      this.service.getServiceData(this.hierarchyUrl).then((res: any) => {
        this.offsetObject['item.id'] = res['futureOffset'];
        res['nodes'].forEach(element => {
          if (element.id !== this.taxonomyId) {
            element['children'] = [];
            element['level'] = item.level + 1;
            element.hasMoreChild = true;
            item['children'].push(element);
          }
        });
        item.hasMoreChild = false;
        // console.log('RES', JSON.stringify(this.items));
        this.documentFutureOffset = res.futureOffset;
      }).catch((ex) => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }


}
