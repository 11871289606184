import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-navigation-arrow',
  templateUrl: './navigation-arrow.component.html'
})
export class NavigationArrowComponent implements OnInit, OnChanges {

  @Input() isAllNode = false;
  @Input() taxonomyData; // main taxonomy tree data
  @Input() showNavigation = false; // holds boolean value for showing up navigation arrow
  @Input() firstNode: any; // first node in tree
  @Input() lastNode: any; // last node in tree
  @Input() selectedNode: any; // current selected node on tree
  @Output() nextClickEvent = new EventEmitter();
  @Output() previousClickEvent = new EventEmitter();
  tempNode: any;
  parentNode: any;
  tempTaxonomyData: any = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taxonomyData && changes.taxonomyData.currentValue) {
      this.taxonomyData = changes.taxonomyData.currentValue;
      if (this.taxonomyData) {
        if (this.isAllNode === false && this.taxonomyData[0] && this.taxonomyData[0].children[0]) {
          this.tempTaxonomyData = this.taxonomyData;
          if (this.taxonomyData[0].children[0]['isOrphanLabel']) { // skipping orphan node lable if any
            if (!this.firstNode) {
              this.firstNode = this.taxonomyData[0].children[0].children[0];
            }
          } else {
            if (!this.firstNode) {
              this.firstNode = this.taxonomyData[0].children[0];
            }
          }
        } else if (this.isAllNode === true) {
          this.tempTaxonomyData = this.taxonomyData.children;
          this.firstNode = this.taxonomyData.children[0];
        }
        this.lastNode = this.findLastNode(this.taxonomyData);
      }
    }
  }

  ngOnInit() {
  }

  /**
   * On next button click
   */
  onTreeNavigateNextClick() {
    this.selectedNode['expand'] = true;
    this.selectedNode = this.loadNextNode(this.selectedNode);
    if (this.selectedNode.isOrphanLabel) { // Don't navigate to orphan node label
      this.onTreeNavigateNextClick();
    }
    this.nextClickEvent.emit({ 'node': this.selectedNode });
  }

  /**
   * On previous button click
   */
  onTreeNavigatePreviousClick() {
    this.loadPreviousNode(this.selectedNode);
    if (this.tempNode.isOrphanLabel) { // Don't navigate to orphan node label
      this.selectedNode = this.tempNode;
      this.onTreeNavigatePreviousClick();
    }
    this.selectedNode = this.tempNode;
    this.previousClickEvent.emit({ 'node': this.selectedNode });
  }

  /**
   * To load next node of current node
   * @param selectedNode (current node)
   */
  loadNextNode(selectedNode) {
    if (selectedNode) {
      if (selectedNode.children && selectedNode.children.length > 0) { // If node has children
        return selectedNode.children[0];
      } else { // If no children
        this.getAvailableNode(selectedNode);
        return this.tempNode;
      }
    } else {
      return null;
    }
  }

  /**
   * To get next available node
   * @param currNode
   */
  getAvailableNode(currNode) {
    let parent, childList = [],
      position = 0;
    if (currNode.parent_id) {
      this.getParentNodeObject(currNode.parent_id);
      parent = this.parentNode;
      childList = parent.children;
      if (childList.length > 0) {
        position = this.findIndexInList(currNode, childList);
        if (position < childList.length - 1) { // If node is not in last position
          this.tempNode = childList[position + 1];
        } else { // If last position
          this.getAvailableNode(parent);
        }
      }
    } else {
      if (this.tempTaxonomyData && this.tempTaxonomyData.length > 1) {
        position = this.findIndexInList(currNode, this.tempTaxonomyData);
        this.tempNode = this.tempTaxonomyData[position + 1].children[0];
      } else {
        this.tempNode = this.firstNode;
      }
    }
  }

  /**
   * To load previous node of current node
   * @param selectedNode (current node)
   */
  loadPreviousNode(selectedNode) {
    let parent, childList = [],
      position = 0;
    if (selectedNode && selectedNode.parent_id) {
      this.getParentNodeObject(selectedNode.parent_id);
      parent = this.parentNode;
      childList = parent.children;
      if (childList.length > 0) {
        position = this.findIndexInList(selectedNode, childList);
        if (position > 0) { // If node is not in first position
          this.tempNode = childList[position - 1];
          if (this.tempNode.children.length > 0) {
            this.tempNode = this.findLastNode(this.tempNode.children[this.tempNode.children.length - 1]);
          }
        } else { // in first position
          if (parent && parent.parent_id) {
            this.tempNode = parent;
          } else {
            this.loadPreviousNode(parent);
          }
        }
      }
    } else {
      const tempTaxonomyData = this.taxonomyData.children ? this.taxonomyData.children : this.taxonomyData;
      if (tempTaxonomyData && tempTaxonomyData.length > 1) {
        position = this.findIndexInList(selectedNode, tempTaxonomyData);
        const childPosition = tempTaxonomyData[position - 1].children.length - 1;
        this.tempNode = this.findLastNode(tempTaxonomyData[position - 1].children[childPosition]);
      } else {
        this.tempNode = tempTaxonomyData.length === 1 ? this.firstNode : this.lastNode;
      }
    }
  }

  /**
   * To fond position of element in a list
   * @param element
   * @param list
   */
  findIndexInList(element, list) {
    return list.findIndex(obj => ((obj.id === element.item_id) || (obj.id === element.id)));
  }

  /**
   * To find last node of tree
   * @param node
   */
  findLastNode(node: any) {
    if (node.children && node.children.length > 0) {
      return this.findLastNode(node.children[node.children.length - 1]);
    } else if (node && node.length > 0) {
      return this.findLastNode(node[node.length - 1]);
    } else {
      return JSON.parse(JSON.stringify(node));
    }
  }

  getParentNodeObject(parentId) {
    const taxoData = this.taxonomyData.children ? this.taxonomyData.children : this.taxonomyData;
    if (this.isAllNode === false) {
      taxoData.forEach(element => {
        this.getParent(element, parentId);
      });
    } else {
      taxoData.forEach(element => {
        this.getParent(element, parentId);
      });
    }
  }

  getParent(node, parentId) {
    if (node.id === parentId) {
      this.parentNode = node;
    } else {
      if (node.children.length > 0) {
        node.children.forEach(element => {
          this.getParent(element, parentId);
        });
      }
    }
  }

}
