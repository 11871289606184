import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

import Utils from '../../utils';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit, OnChanges {

  search; // hold metadata text which needs to be searched
  searchListReult; // holds object after searched the term
  currentItem; //holds the current item from the autocomplete list on tab focus
  @Input() listingObj; // holds the complete data passed to component for populating dropdown
  @Output() selectedObject: EventEmitter<any>; // emits the selected object from the dropdown
  @Output() validationEvent: EventEmitter<any>; // emits the selected object from the dropdown
  @Input() selectType; // holds the select type as in multi select or single select
  @Input() uniqueId; // holds the unique id name in the object
  @Input() propName; // holds the property name in the object
  @Input() autoCompleteTitle; // holds the title to be displayed for auto complete
  @Input() defaultSelection; // holds the default selection for dropdown
  @Input() isMandatory = true; // holds the flag to display mandatory sign for auto complete
  @Input() disableOption = true; // holds the flag to disable the selected option for auto complete
  @Input() clearArray = false; // holds the flag to disable the selected option for auto complete
  @Input() isLableShow = true; // holds the flag whether to show or not label
  @Input() disabled = false; // holds the flag whether to disable autocomplete dropdown or not
  @Input() showValidMsg = true; // holds flag whether to display validation message or not from outside component
  @Input() clrDisOnSelect = true; // it indicates whether to enable all options before current selection or not, only for single select
  @Input() showSelectionInput = true; // it holds the flag value to show the input view of dropdown
  @Input() index = 0; // holds each index value of each dropdown while creating same multiple dropdowns in same page
  // to distinguish each component
  @Input() showSpinner = false; // to display spinner into input box for loading state
  @Input() viewLocation = '';
  @Input() treeView = false; // holds flag for tree-view screen (css purpose)
  @Input() canDelete = true; // holds permission for delete icon
  @Input() disableOptionList = []; // holds option(s) to disable other than default selection
  selectedArrayObj = [];
  toggle = false;
  valid = false; // holds validation for + button
  targetElementId = ''; // holds the target element id in case of delete of selected items
  isTouched = false;
  showErr = false;
  constructor() {
    this.selectedObject = new EventEmitter<any>();
    this.validationEvent = new EventEmitter<any>();
  }
  ngOnInit() {
    this.initialiseSelectedArray();
    if (document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index))) {
      (<HTMLElement>document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index))).style.display = 'none';
    }
    if (this.defaultSelection) {
      this.doDefaultSelection();
    }
    if (this.disableOptionList && this.disableOptionList.length) {
      this.setDisableOption();
    }
  }

  ngOnChanges() {
    if (this.defaultSelection) {
      this.doDefaultSelection();
    }
    if (this.disableOptionList && this.disableOptionList.length) {
      this.setDisableOption();
    }
  }

  /* --------- Functionality for searching the object from the list start --------- */
  searchMetadata() {
    this.searchListReult = [];
    if (this.search === undefined || this.search.length === 0) {
      this.searchListReult = this.listingObj;
    } else {
      const result = this.listingObj.filter((element) => {
        if ((element[this.propName].toLowerCase()).includes(this.search.toLowerCase())) {
          return element;
        }
      });
      result.forEach(item => {
        if (!this.searchListReult.find(data => item[this.uniqueId] === data[this.uniqueId])) {
          this.searchListReult.push(item);
        }
      });
      if (this.searchListReult.length === 0) {
        this.searchListReult.push({ [this.propName]: 'No Record Found', [this.uniqueId]: null, is_selected: null });
      }
    }
  }
  /* --------- Functionality for searching the object from the list end --------- */




  /* --------- Functionality to emit the selected object from the list start --------- */
  selectObject(selectedObject) {
    if (this.disableOption ? (selectedObject.is_selected !== 1 && selectedObject[this.uniqueId] !== null) : (selectedObject[this.uniqueId] !== null)) {
      if (this.selectType === 'multi') {
        selectedObject.is_selected = 1;
        let flag = false;
        if (this.selectedArrayObj.length > 0) {
          this.selectedArrayObj.forEach(element => {
            if (JSON.stringify(element) === JSON.stringify(selectedObject)) {
              flag = true;
            }
          });
          if (flag === false && selectedObject[this.uniqueId] !== null) {
            this.selectedArrayObj.push(selectedObject);
          }
        } else {
          if (selectedObject[this.uniqueId] !== null) {
            this.selectedArrayObj.push(selectedObject);
          }
        }
      } else {
        if (this.clrDisOnSelect) {
          this.unSelectAll();
        }
        if (this.searchListReult && this.searchListReult.length) {
          this.searchListReult.forEach(element => {
            if (JSON.stringify(selectedObject) === JSON.stringify(element)) {
              selectedObject.is_selected = 1;
            }
            if (this.selectedArrayObj.length > 0 && JSON.stringify(element) === JSON.stringify(this.selectedArrayObj[0])) {
              element.is_selected = 0;
            }
          });
        }
        this.initialiseSelectedArray();
        if (selectedObject[this.uniqueId] !== null && selectedObject.is_selected === 1) {
          this.selectedArrayObj.push(selectedObject);
        }
        this.addObj();
      }
      this.checkValidation();
    }
  }
  /* --------- Functionality to emit the selected object from the list end --------- */




  /* --------- Functionality to toggle between open and close the dropdown functionality start --------- */
  openPanel(val) {
    if (!this.disabled) {
      if (val === true) {
        this.isTouched = true;
        this.showErr = false;
        if ((<HTMLElement>document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index)))) {
          (<HTMLElement>document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index))).style.display = 'block';
        }
        this.searchMetadata();
      } else {
        if (this.isTouched && this.selectedArrayObj.length === 0) {
          this.showErr = true;
        } else {
          this.showErr = false;
        }
        if ((<HTMLElement>document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index)))) {
          (<HTMLElement>document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index))).style.display = 'none';
        }
        this.toggle = false;
        this.search = '';
      }
      this.validationEvent.emit(this.showErr);
      if (this.targetElementId.length > 0) {
        // (<HTMLElement>document.querySelector('#auto-complete__list_' + this.uniqueId)).style.display = 'block';
        this.searchMetadata();
      }
    }
  }
  /* --------- Functionality to toggle between open and close the dropdown functionality end --------- */


  navigateDropdownElements(event) {
    Utils.navigateElemWithUpDownKey(event);
  }

  /* --------- Functionality to emit selected object start --------- */

  emitSelectedObject() {
    if (this.selectType === 'multi') {
      this.selectedObject.emit(this.selectedArrayObj);
    } else {
      this.selectedObject.emit(this.selectedArrayObj[0]);
    }
  }

  /* --------- Functionality to emit selected object end --------- */


  /* --------- Functionality on click of + button start --------- */

  addObj() {
    this.emitSelectedObject();
    if ((<HTMLElement>document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index)))) {
      (<HTMLElement>document.querySelector('#auto-complete__list_' + (this.uniqueId + '' + this.index))).style.display = 'none';
    }
    this.toggle = false;
    this.search = '';
    if (this.clearArray) {
      this.initialiseSelectedArray();
    }
  }

  /* --------- Functionality on click of + button end --------- */


  /* --------- Functionality on deleting selected items start --------- */

  deleteObj(data, evt) {
    this.targetElementId = evt.target.id;
    data.is_selected = 0;
    this.isTouched = true;
    this.selectedArrayObj = this.selectedArrayObj.filter(obj => {
      return data[this.uniqueId] !== obj[this.uniqueId];
    });
    this.unselectObjInList(data);
    if (this.selectedArrayObj.length === 0) {
      this.targetElementId = '';
    }
    if (this.viewLocation !== 'workflow' && this.viewLocation !== 'covreport') {
      this.emitSelectedObject();
    }
    this.checkValidation();
  }

  /* --------- Functionality on deleting selected items end --------- */


  /* --------- Validation functionality for + button start --------- */

  checkValidation() {
    if (this.selectedArrayObj.length > 0) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  /* --------- Validation functionality for + button end --------- */


  /* --------- Initialse selected array used in case of single select edit functionality start --------- */

  initialiseSelectedArray() {
    this.selectedArrayObj = [];
  }

  /* --------- Initialse selected array used in case of single select edit functionality end --------- */


  /* --------- Functionality to deselect list item if deleted start --------- */

  unselectObjInList(unselectObj) {
    if (this.searchListReult && this.searchListReult.length > 0) {
      this.searchListReult.forEach(element => {
        if (element[this.uniqueId] === unselectObj[this.uniqueId]) {
          element['is_selected'] = 0;
        }
      });
    }
  }

  /* --------- Functionality to deselect list item if deleted end --------- */

  selectObjInList(selectObj) {
    if (this.searchListReult && this.searchListReult.length > 0) {

      this.searchListReult.forEach(element => {
        if (element[this.uniqueId] === selectObj[this.uniqueId]) {
          element['is_selected'] = 1;
        } else {
          const prop = element[this.uniqueId] ? this.uniqueId : this.propName;
          if (this.defaultSelection instanceof Array && this.defaultSelection.findIndex(obj => obj[prop] === element[prop]) > -1) {
            element.is_selected = 1;
          } else {
            element.is_selected = 0;
          }
        }
      });
    }

  }

  unSelectAll() {
    if (this.listingObj && this.listingObj.length) {
      this.listingObj.forEach(element => {
        element['is_selected'] = 0;
      });
    }
  }

  /* --------- Functionality to clear selection start --------- */

  clearSelection(unSelecteAll = true) {
    this.initialiseSelectedArray();
    if (unSelecteAll) {
      this.unSelectAll();
    }
    this.isTouched = false;
    this.showErr = false;
    this.checkValidation();
  }

  /* --------- Functionality to clear selection end --------- */

  showValueInDropdown(selectedObj: any) {
    this.clearSelection();
    this.selectedArrayObj.push(selectedObj);
  }

  doDefaultSelection() {
    this.selectedArrayObj = [];
    this.searchMetadata();
    if (this.defaultSelection instanceof Array) {
      for (const obj of this.defaultSelection) {
        obj.is_selected = 1;
        this.selectObjInList(obj);
        if (!this.clearArray) {
          this.selectedArrayObj.push(obj);
        }
      }
    } else {
      this.defaultSelection.is_selected = 1;
      this.selectObjInList(this.defaultSelection);
      this.selectedArrayObj.push(this.defaultSelection);
    }
  }

  setDisableOption() {
    if (this.listingObj && this.listingObj.length) {
      this.disableOptionList.forEach(option => {
        const idx = this.listingObj.findIndex(obj => obj[this.uniqueId] === option[this.uniqueId]);
        if (idx > -1) {
          this.listingObj[idx].is_selected = 1;
        }
      });
    }
  }

  onFocus(data) {
    this.currentItem = data;
  }

}
