import {
  Component,
  OnInit,
  Input,
  OnChanges
} from '@angular/core';

import {
  GlobalSettings
} from '../../global.settings';
import {
  CommonService
} from '../../common.service';
import {
  environment
} from '../../../environments/environment';
import Utils from '../../utils';
import { Router } from '@angular/router';
@Component({
  selector: 'app-protip',
  templateUrl: './protip.component.html',
  styleUrls: ['./protip.component.scss']
})
export class ProtipComponent implements OnInit, OnChanges {
  protip;
  tips = [];
  @Input() type;
  protipData = null;
  @Input() protipBullet = false;
  @Input() showMath = false;
  constructor(private service: CommonService, private router: Router) {

    this.service.getServiceData(GlobalSettings.PROTIP_JSON_URL).then((res: any) => {
      this.protipData = res.data;
      this.setProtip();
    }).catch((ex) => {
      console.log('ProtipComponent ', ex);
    });


  }

  setProtip() {
    if (this.protipData) {

      for (const item of this.protipData) {
        if (this.type === item.type) {
          this.protip = item;
          if (!this.protip.target) {
            this.protip.target = '_self';
          }
          this.tips = item.tips;
        }
      }
      this.protipData.forEach(element => {
        if (element.type === 'taxonomydetail') {
          element.link = '#/app/project/list';
        }
        if (element.type === 'public_review') {
          element.link = environment.faq_url;
        }
      });
    } else {
      console.log('ProtipComponent data is null');
    }

  }
  ngOnInit() {

  }

  ngOnChanges() {
    console.log('Type ', this.type, this.protipData);
    this.setProtip();

  }

  setPath() {
    if (localStorage.getItem('orgDetails')) {
      const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_LIST;
      return path;
    }
  }
  navigate(link) {
    if (localStorage.getItem('orgDetails')) {
      const path = this.service.getOrgDetails() + link.replace('#', '');
      this.router.navigate([path]);
      return path;
    }
  }
}
