import {
  Injectable
} from '@angular/core';
import {
  GlobalSettings
} from './global.settings';
import {
  CommonService
} from './common.service';
import {
  SharedService
} from './shared.service';
import Utils from './utils';

@Injectable()
export class TreeDataService {

  DUMMY_ID_FOR_ORPHANLABEL = Utils.DUMMY_ID_FOR_ORPHANLABEL;

  projectName = '';
  projectDesc = '';
  workflowList = [];
  taxonomyList = [];
  taxonomyData = {
    children: []
  };
  selectedWorkFlow = null;
  selectedTaxonomy = null;
  textLength = 50;
  selectedTaxonomyNodeIs = [];
  nodes = [];
  relations: any;
  associations: any = [];
  treeResponseData: any;
  selectedTaxonomyId = null;
  addWrapperNode = true;
  expandLevel = 1;
  isPacingGuide = false;
  import_type = 0;
  isDeleted = false; // holds flag for taxonomy deleted or not
  rootNodeId: any; // holds root node i.e. document node id
  orphanNodeList = []; // holds list of orphan node list if any
  topMostOrphanNodes = []; // holds list of top most orphan nodes
  worker: any;

  constructor(private service: CommonService, private sharedService: SharedService) { }


  getTreeData(url, _addWrapperNode, level = 2, isHeader = true, isPacingGuide = false, appendBaseUrl = true, checkCookie = true) {
    this.isPacingGuide = isPacingGuide;
    this.expandLevel = level;
    const self = this;
    this.addWrapperNode = _addWrapperNode;
    this.import_type = 0;
    this.isDeleted = false;
    return new Promise((resolve, reject) => {

      this.service.getServiceData(url, isHeader, false, appendBaseUrl, checkCookie).then((res: any) => {
        // console.log('GET_TREE_VIEW start', Date.now());
        self.sharedService.showLoader.next(true);
        if (res.import_type) {
          this.import_type = res.import_type;
        }
        if (res.is_deleted) {
          this.isDeleted = (res.is_deleted === 1) ? true : false;
        }

        if (typeof Worker !== 'undefined') {
          this.worker = new Worker('./treeService.worker', { type: 'module' });
        }

        if (this.worker) {
          this.worker.onmessage = ({ data }) => {
            if (data) {
              // console.log('data received from worker', data);
              resolve(data);
            }
          };
          this.worker.postMessage({
            data: res,
            parameters: {
              _addWrapperNode,
              level,
              isHeader,
              isPacingGuide,
              DUMMY_ID_FOR_ORPHANLABEL: this.DUMMY_ID_FOR_ORPHANLABEL
            }
          });
        }

        // this.setTreeResponse(res);
        // this.setNodes();
        // this.setRelations();
        // this.findTopmostOrphan(); // Finding list of topmost orphan nodes
        // this.setAssociations();
        // resolve(this.parseTreeData());
        // console.log('GET_TREE_VIEW end', Date.now());
        /*setTimeout(function () {
            self.sharedService.showLoader.next(false);
          },
          500);*/
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
        reject(ex);
      });
    });

  }

  setTreeResponse(data: any): void {
    this.treeResponseData = data;
  }

  setNodes(): void {
    const apiResponse: any = this.treeResponseData;
    this.nodes = apiResponse.nodes;
  }

  getNodes(): any {
    return this.nodes;
  }

  setRelations(): void {
    const apiResponse: any = this.treeResponseData;
    this.relations = apiResponse.relations;
  }

  getRelations(): any {
    return this.relations;
  }

  setAssociations(): void {
    const apiResponse: any = this.treeResponseData;
    this.associations = [];
    if (apiResponse && apiResponse.associations) {
      this.associations = apiResponse.associations;
    }
  }

  getAssociations(): any {
    return this.associations;
  }

  parseTreeData() {

    const treeNodes: any = this.getNodes();
    const documentNode: any = treeNodes[0] ? treeNodes[0] : treeNodes;
    this.rootNodeId = documentNode.id;
    const parsedTree = documentNode;
    if (parsedTree && documentNode && this.addWrapperNode) {
      parsedTree.full_statement = documentNode.title;
      parsedTree.full_statement_html = documentNode.title_html ? documentNode.title_html : documentNode.title;
      parsedTree.is_document = 1;
      parsedTree.title = documentNode.title;
      parsedTree.expand = true;
      parsedTree.cut = 0;
      parsedTree.paste = 0;
      parsedTree.reorder = 0;
      parsedTree.level = 1;
      parsedTree.isFirst = true;
      parsedTree.list_enumeration = parsedTree.sequence_number ? '' + parsedTree.sequence_number : parsedTree.list_enumeration;
      parsedTree.sequence_number = '' + parsedTree.sequence_number;
    }
    parsedTree.children = this.createTreeStructure(documentNode.id, 1);
    Utils.sortData(parsedTree.children);
    /* Orphan lable node appending under document node STARTS */
    // this.orphanNodeList = this.getOrphanNodes();
    // if (this.orphanNodeList.length > 0) {
    this.buildOrphanLableNode(parsedTree);
    // }
    /* Orphan lable node appending under document node ENDS */

    const wrapperNode = {
      children: [parsedTree],
    };
    if (this.addWrapperNode) {
      return {
        parsedTreeNodes: wrapperNode,
        relations: this.relations
      };
    } else {
      return {
        parsedTreeNodes: parsedTree,
        relations: this.relations
      };
    }
  }

  createTreeStructure(nodeId: string, level) {
    const data: any[] = [];
    const childrenNodes = this.extractChildren(nodeId);
    if (childrenNodes.length > 0) {
      level = level + 1;
      for (const key in childrenNodes) {
        if (childrenNodes[key]) {
          const childNode = childrenNodes[key];
          if (childNode) {
            nodeId = childNode.id;
            childNode.children = [];
            childNode.expand = level <= this.expandLevel ? true : false;
            childNode.cut = 0;
            childNode.paste = 0;
            childNode.reorder = 0;
            childNode.level = level;
            childNode.list_enumeration = childNode.sequence_number ? '' + childNode.sequence_number : childNode.list_enumeration;
            childNode.sequence_number = '' + childNode.sequence_number;
            const nodeChildren = this.createTreeStructure(nodeId, level);
            if (nodeChildren.length > 0) {
              childNode.children = nodeChildren;
            }
            data.push(childNode);
          }
        }
      }
    }
    return data;
  }

  extractChildren(nodeId: string): any {
    const treeNodes: any = this.getNodes();
    const nodeRelations: any = this.getRelations();
    const data: any[] = [];
    for (const key in nodeRelations) {
      if (nodeRelations[key]) {

        const relation: any = nodeRelations[key];
        if (relation.child_id !== relation.parent_id) {
          const parentId: string = relation.parent_id;
          // console.log(' extractChildren  ', relation, '  ', parentId);
          if (nodeId === parentId) {
            const childId: string = relation.child_id;
            const childNode = this.extractNode(childId, parentId);
            if (childNode) {
              childNode.parent_id = parentId;
              // These properties used in Export CSV to show association and association type
              if (nodeRelations[key].tree_association !== undefined) {
                childNode.tree_association = nodeRelations[key].tree_association;
                childNode.association_type = nodeRelations[key].association_type;
              }
              data.push(childNode);
            }
          }
        }
      }
    }
    return data;
  }

  extractNode(nodeIdToSearchWith: string, parentId): any {
    const treeNodes: any = this.getNodes();
    let nodeFound: any;
    for (const key in treeNodes) {
      if (treeNodes[key]) {
        const node: any = treeNodes[key];
        if (this.isPacingGuide) {
          // This to handle if hierarchy_id is null
          if (node.hierarchy_id == null) {
            node.hierarchy_id = treeNodes[0].id;
          }
          if (nodeIdToSearchWith === node.id && parentId === node.hierarchy_id) {
            nodeFound = Object.assign({}, node);
            break;
          }
        } else {
          if (nodeIdToSearchWith === node.id) {
            nodeFound = Object.assign({}, node);
            break;
          }
        }
      }
    }
    return nodeFound;
  }

  /**
   * To get orphan node list
   */
  /*getOrphanNodes() {
    const nodes = this.getNodes();
    const orphanNodes = [];
    for (const node of nodes) {
      // Check node is orphan or not skipping root node
      if ((node.id !== this.rootNodeId) && this.isNodeOrphan(node)) {
        node.isOrphan = true;
        node.cut = 0;
        node.paste = 0;
        node.reorder = 0;
        node.parent_id = '';
        node.children = [];
        // node.list_enumeration = node.sequence_number ? '' + node.sequence_number : node.list_enumeration;
        orphanNodes.push(node);
      }
    }
    return orphanNodes;
  }*/

  /**
   * To check node is orphan or not by giving node object as parameter
   * @param node (selected node object)
   */
  /*isNodeOrphan(node: any) {
    const nodeId = node.id;
    const nodeRelations = this.getRelations();
    let flag = false;
    let parentId: any;
    for (const key in nodeRelations) {
      if (nodeRelations[key]) {
        const relation: any = nodeRelations[key];
        if ((relation.child_id === nodeId) && relation.parent_id) { // If node exists in relation, set flag=true
          parentId = relation.parent_id;
          flag = true;
          break;
        }
      }
    }
    if (flag) { // If flag is true, then check its parent is orphan or not recursively
      if (parentId !== this.rootNodeId) { // excluding root node checking
        node.firstRootOrphan = false;
        return this.isNodeOrphan({
          id: parentId
        });
      } else {
        return false; // not orphan
      }
    } else {
      // If node exists in relation as only parent_id but not exists in nodes list, don't cosider the node as orphan
      const orphan = !this.findNodeById(nodeId) ? false : true;
      node.firstRootOrphan = orphan;
      return orphan; // orphan
    }
  }*/

  /**
   * To find list of top most orphan root nodes
   */
  findTopmostOrphan() {
    const nodes = this.getNodes();
    this.topMostOrphanNodes = [];
    for (const node of nodes) {
      if (node.is_orphan === 1) { // top most orphan node
        this.topMostOrphanNodes.push(node);
      }
    }
  }

  /**
   * To create Orphan level root node which will be non-clickable i.e. only a text, under which orphan node list
   * will remain. This root node can be identified by attribute 'isOrphanLabel' which will be true
   * @param parentNode (Node of which one children will be orphan lable node i.e. orphan lable node will be one
   * of children of given parameter node- 'parentNode')
   */
  buildOrphanLableNode(parentNode: any) {
    let orphanFound = false;
    const nodes = this.getNodes();
    const orphanLableNode: any = {}; // 'orphanLableNode' is one dummy node
    const title = 'Nodes with no linked parent';
    orphanLableNode.full_statement = title;
    orphanLableNode.full_statement_html = title;
    orphanLableNode.human_coding_scheme = '';
    orphanLableNode.is_document = 0;
    // orphanLableNode.title = title;
    orphanLableNode.cut = 0;
    orphanLableNode.paste = 0;
    orphanLableNode.reorder = 0;
    orphanLableNode.children = [];
    orphanLableNode.sequence_number = '';
    orphanLableNode.isOrphan = true;
    orphanLableNode.isOrphanLabel = true;
    orphanLableNode.parent_id = parentNode.id;
    orphanLableNode.id = this.DUMMY_ID_FOR_ORPHANLABEL; // assigning dummy id
    orphanLableNode.list_enumeration = '' + (Utils.getMaxLE(parentNode.children));
    orphanLableNode.sequence_number = '' + (Utils.getMaxLE(parentNode.children));
    orphanLableNode.level = parentNode.level + 1;
    // orphanLableNode.expand = orphanLableNode.level <= this.expandLevel ? true : false;
    orphanLableNode.expand = true;

    // for (const node of nodes) {
    for (const node of this.topMostOrphanNodes) {
      orphanFound = true;
      // const node = this.findNodeById(orphanNode.id);
      node.cut = 0;
      node.paste = 0;
      node.reorder = 0;
      node.parent_id = this.DUMMY_ID_FOR_ORPHANLABEL;
      node.level = orphanLableNode.level + 1;
      node.expand = node.level <= this.expandLevel ? true : false;
      node.children = this.createTreeStructure(node.id, node.level);
      orphanLableNode.children.push(node);
    }
    console.log('Orphan node tree =========================>', orphanLableNode);
    if (orphanFound) {
      parentNode.children.push(orphanLableNode);
    }
  }

  /**
   * To fetch node from node list by node id
   * @param nodeId (selected node id)
   */
  /*findNodeById(nodeId: any) {
    const nodeList = this.getNodes();
    for (const node of nodeList) {
      if (node.id.trim() === nodeId.trim()) {
        return node;
      }
    }
  }*/

}
