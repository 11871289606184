import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges
} from '@angular/core';


@Component({
  selector: 'app-pacing-guide-tree-reorder',
  templateUrl: './pacing-guide-tree-reorder.component.html',
  styleUrls: ['./pacing-guide-tree-reorder.component.scss', '../../tree-accordian/tree-reorder-success.scss']
})
export class PacingGuideTreeReorderComponent implements OnInit, OnChanges {

  @Input() dataList: any;
  @Input() viewType = 'pacing-guide';
  @Input() containerDeletePermission = false;
  @Output() treeUpdateEvent: EventEmitter<any>;
  @Output() deleteEvent: EventEmitter<any>;
  @Output() clickedItemEvent: EventEmitter<any>;
  @Output() addStandardEvent: EventEmitter<any>;
  @Output() invalidMoveEvent: EventEmitter<any>;
  updateItem = false;
  dragStarted = false;
  selectedNodeId = null;
  selectedObj = null;
  dragOverId = null;
  level = 1;
  addNodeFromOutside = false;
  selectedParentId = '';
  copyPasteFlag = 0; // holds value to display copy icon or paste icon
  sourceElement; // holds copied element
  targetElement; // holds paste destination element
  sourceElementSiblings; // holds siblings for copied element, used to iterate and remove copied element after paste
  firstNode; // holds the first single node
  constructor(private cd: ChangeDetectorRef) {
    this.treeUpdateEvent = new EventEmitter<any>();
    this.deleteEvent = new EventEmitter<any>();
    this.clickedItemEvent = new EventEmitter<any>();
    this.addStandardEvent = new EventEmitter<any>();
    this.invalidMoveEvent = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dragOverId && changes.dragOverId.currentValue) {
      this.dragOverId = changes.dragOverId.currentValue;
    }
    if (this.dataList && this.dataList.length === 1) {
      this.firstNode = this.dataList[0];
    } else {
      this.firstNode = {
        id: ''
      };
    }
  }

  drop(ev) {
    console.log('---------------drop event--------------');

    this.dragOverId = null;
    this.setItemDragStarted(this.dataList, this.selectedNodeId, false);
    localStorage.setItem('dragStarted', 'false');
    this.dragStarted = false;
    this.selectedObj = null;
    const id = ev.dragData.id;
    if (this.addNodeFromOutside) {
      this.selectedObj = JSON.parse(JSON.stringify(ev.dragData));
      this.selectedObj.parent_id = undefined;
      if (this.viewType === 'pacing-guide') {
        this.selectedObj.new_parent_id = undefined;
      }
      this.selectedObj.children = [];
      this.selectedObj['item_type'] = 'Standard';
      if (this.dataList.length === 0) {
        this.dataList.push(this.selectedObj);
        if (this.addNodeFromOutside) {
          this.addStandardEvent.emit({
            obj: this.selectedObj,
            parentId: null
          });
        }
        // this.treeUpdateEvent.emit(this.dataList);
        return;
      }
    }
    let dragObj = null,
      dropObj = null;
    const self = this;
    // let updateTree = true;
    this.getNodeById2(this.dataList, id, this.addNodeFromOutside, function (_dragObj) {
      _dragObj.isSelected = false;
      dragObj = _dragObj;
      console.log('DragObj ', dragObj);
      self.getNodeById2(self.dataList, ev.nativeEvent.target.id, false, function (_dropObj) {
        dropObj = _dropObj;
        console.log('DropObj ', dropObj);
        self.validate(dragObj, dropObj, function (val) {
          console.log('1 checkValidMove  ', val);
          if (val) {

            if (ev.nativeEvent.target.className.indexOf('drop-zone') > -1) {
              if (!dragObj.parent_id) {
                console.log(`2 if make child node dragObj `, dragObj, dropObj);
                self.removeFromList(self.dataList, id);
              } else {
                console.log(`2 else make child node dragObj `, dragObj, dropObj);
                self.getNodeById2(self.dataList, dragObj.parent_id, false, function (parentObj) {
                  self.removeFromList(parentObj.children, id);
                });
              }
              // console.log('2--------dropObj.id drag.parent_id',dropObj.id);
              // console.log('2--------dropObj.item_id drag.new_parent_id ',dropObj.item_id);
              dragObj.parent_id = dropObj.id;
              dragObj.level = dropObj.level + 1;
              if (self.viewType === 'pacing-guide') {
                dragObj.new_parent_id = dropObj.item_id;
              }
              dropObj.children.push(dragObj);
            } else {

              //  when both drag & drop obj has same parent
              if (dragObj && dragObj.parent_id && dropObj && dropObj.parent_id) {
                if (dragObj.parent_id === dropObj.parent_id) {
                  console.log(`3 re-order node ${dragObj} and ${dropObj} when both have same parent`);
                  self.getNodeById2(self.dataList, dragObj.parent_id, false, function (dragParentObj) {

                    const dragNo = self.getNodeIndex(dragParentObj.children, dragObj),
                      dropNo = self.getNodeIndex(dragParentObj.children, dropObj);
                    [dragParentObj.children[dragNo], dragParentObj.children[dropNo]] = [dragParentObj.children[dropNo], dragParentObj.children[dragNo]];
                  });

                } else {
                  console.log(`4 re-order node ${dragObj} and ${dropObj} when both have different parent`);
                  self.getNodeById2(self.dataList, dragObj.parent_id, self.addNodeFromOutside, function (dragParentObj) {
                    console.log(`4.1 re-order drag parent ${dragParentObj} `);
                    const dragParentNode = dragParentObj;
                    self.getNodeById2(self.dataList, dropObj.parent_id, false, function (dropParentObj) {
                      if (dragParentNode) {
                        self.removeFromList(dragParentNode.children, dragObj.id);
                      }
                      if (dropParentObj) {
                        console.log(`4.2 re-order drop parent ${dropParentObj} `);
                        const dropParent = dropParentObj;
                        const dropNo = self.getNodeIndex(dropParent.children, dropObj);
                        const arr1 = dropParent.children.slice(0, dropNo);
                        const arr2 = dropParent.children.slice(dropNo + 1);
                        arr1.push(dropObj);
                        arr1.push(dragObj);
                        dragObj.parent_id = dropObj.parent_id;
                        dragObj.level = dropObj.level + 1;
                        if (self.viewType === 'pacing-guide') {
                          dragObj.new_parent_id = dropObj.new_parent_id;
                        }
                        dropParent.children = arr1.concat(arr2);
                      } else {
                        console.log(`4.3 re-order drop parent ${dropParentObj} `);
                        const dropNo = self.getNodeIndex(self.dataList, dropObj);
                        const arr1 = self.dataList.slice(0, dropNo);
                        const arr2 = self.dataList.slice(dropNo + 1);
                        arr1.push(dropObj);
                        arr1.push(dragObj);
                        dragObj.parent_id = null;
                        if (self.viewType === 'pacing-guide') {
                          dragObj.new_parent_id = null;
                        }
                        self.dataList = arr1.concat(arr2);
                      }
                    });
                  });

                }
              } else {
                if (dragObj && dragObj.parent_id && !dropObj.parent_id) {
                  console.log(`5 re-order node ${dragObj} and ${dropObj} when drag has parent, drop don't`);
                  self.getNodeById2(self.dataList, dragObj.parent_id, self.addNodeFromOutside, function (dragParentObj) {
                    const parentNode = dragParentObj;
                    self.removeFromList(parentNode.children, dragObj.id);
                    self.removeFromList(self.dataList, dragObj.id);
                    const dropNo = self.getNodeIndex(self.dataList, dropObj);
                    dragObj.parent_id = null;
                    if (self.viewType === 'pacing-guide') {
                      dragObj.new_parent_id = null;
                    }
                    const arr1 = self.dataList.slice(0, dropNo);
                    const arr2 = self.dataList.slice(dropNo + 1);
                    arr1.push(dropObj);
                    arr1.push(dragObj);
                    self.dataList = arr1.concat(arr2);
                  });
                } else
                  if (dragObj && !dragObj.parent_id && dropObj.parent_id) {
                    console.log(`6 re-order node ${dragObj} and ${dropObj} when drop has parent, drag don't`);
                    self.getNodeById2(self.dataList, dropObj.parent_id, false, function (dropParentObj) {
                      self.removeFromList(self.dataList, dragObj.id);
                      if (dropParentObj) {
                        const dropParent = dropParentObj;
                        const dropNo = self.getNodeIndex(dropParent.children, dropObj);
                        const arr1 = dropParent.children.slice(0, dropNo);
                        const arr2 = dropParent.children.slice(dropNo + 1);
                        arr1.push(dropObj);
                        arr1.push(dragObj);
                        dragObj.parent_id = dropObj.parent_id;
                        // console.log('6 -------------', dropObj.parent_id);
                        // console.log('6 -------------', dropObj);
                        if (self.viewType === 'pacing-guide') {
                          dragObj.new_parent_id = dropObj.new_parent_id;
                        }
                        dropParent.children = arr1.concat(arr2);
                      } else {
                        const dropNo = self.getNodeIndex(self.dataList, dropObj);
                        const arr1 = self.dataList.slice(0, dropNo);
                        const arr2 = self.dataList.slice(dropNo + 1);
                        arr1.push(dropObj);
                        arr1.push(dragObj);
                        dragObj.parent_id = null;
                        if (self.viewType === 'pacing-guide') {
                          dragObj.new_parent_id = null;
                        }
                        self.dataList = arr1.concat(arr2);
                      }
                    });

                  } else {
                    if (dragObj.id !== dropObj.id) {
                      console.log(`7 re-order node ${dragObj} and ${dropObj} when drop and drag don't have parent`);
                      self.removeFromList(self.dataList, dragObj.id);
                      const dragNo = self.getNodeIndex(self.dataList, self.getNodeById(self.dataList, id)),
                        dropNo = self.getNodeIndex(self.dataList, self.getNodeById(self.dataList, ev.nativeEvent.target.id));
                      const arr1 = self.dataList.slice(0, dropNo);
                      const arr2 = self.dataList.slice(dropNo + 1);
                      arr1.push(dragObj);
                      arr1.push(dropObj);
                      self.dataList = arr1.concat(arr2);
                    }
                  }
              }

            }
            /*else {
                         updateTree = false;
                         console.log(`8  no condition match`);
                       }
                       if (updateTree)*/
            if (self.addNodeFromOutside) {
              self.addStandardEvent.emit({
                obj: self.selectedObj,
                parentId: dragObj.new_parent_id
              });
            }
            if (self.addNodeFromOutside === false) {
              self.treeUpdateEvent.emit(self.dataList);
            }
          } else {
            console.log('invalid move');
          }
        });


      });

    });

  }

  validate(dragObj, dropObj, callback) {
    console.log('------------------validate called -----------------');
    if (dragObj.id === dropObj.id || dragObj.id === dropObj.parent_id) {
      console.log('1 validate called ');
      callback(false);
      return;
    }

    if (this.viewType === 'pacing-guide') {
      console.log(' validate called ', this.viewType, dragObj, dropObj);
      let found = false;
      if ((dragObj.item_id === dropObj.item_id ||
        dragObj.id === dropObj.item_id) &&
        dragObj.item_type.toLowerCase() === 'standard' &&
        dragObj.parent_id !== dropObj.parent_id) {
        console.log('Found 1');
        found = true;
      } else {
        for (let index = 0; index < dropObj.children.length; index++) {
          const child = dropObj.children[index];
          if ((dragObj.item_id === child.item_id ||
            dragObj.id === child.item_id ||
            dragObj.item_id === child.id) &&
            (dragObj.item_type.toLowerCase() === 'standard' &&
              dragObj.parent_id !== dropObj.id)) {
            found = true;
            console.log('Found 2');
            break;
          }
        }
        if (!found) {
          this.getNodeById2(this.dataList, dropObj.parent_id, false, function (dropParentObj) {
            for (let index = 0; index < dropParentObj.children.length; index++) {
              const child = dropParentObj.children[index];
              if ((dragObj.item_id === child.item_id ||
                dragObj.id === child.item_id ||
                dragObj.item_id === child.id) &&
                (dragObj.item_type.toLowerCase() === 'standard' &&
                  dragObj.parent_id !== dropParentObj.id)) {
                console.log('Found 3');
                found = true;
                break;
              }
            }
          });
        }

      }
      if (found) {
        this.invalidMoveEvent.emit('Can\'t add same standard multiple time in one container');
        callback(false);
        return;
      }
    }
    const arr = dragObj.children;
    if (arr.length === 0) {
      console.log('2 validate called ');
      callback(true);
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      this.checkValidMove(element, dropObj, function (val) {
        if (!val) {
          console.log('3 validate called ');
          callback(false);
          return;
        }
        if (i === arr.length - 1) {
          console.log('5 validate called ');
          callback(true);
          return;
        }
      });
    }

  }
  checkValidMove(element, dropObj, callback) {

    if (element.id === dropObj.id) {
      console.log('4 validate called ');
      callback(false);
      return;
    }
    if (element.children && element.children.length) {
      element.children.forEach(childElement => {
        this.checkValidMove(childElement, dropObj, callback);
      });
    } else {
      callback(true);
      return;
    }
  }

  allowDrop(ev) {
    // ev.preventDefault();
    // ev.stopPropagation();
    return false;
  }

  drag(ev, value) {
    this.addNodeFromOutside = value;
    localStorage.setItem('dragStarted', 'true');
    this.selectedNodeId = ev.target.id;
    this.setItemDragStarted(this.dataList, this.selectedNodeId, true);
    ev.dataTransfer.setData('text', ev.target.id);
    // ev.dataTransfer.setDragImage(ev.nativeEvent.target, 0, 0);
    this.dragStarted = true;
    const self = this;
    if (this.dataList && this.dataList.length > 0) {
      this.getNodeById2(this.dataList, ev.target.id, true, function (obj) {
        if (obj) {
          obj.isSelected = true;
          self.selectedObj = obj;

        }
      });
    }
  }


  onDragEnter(ev) {
    console.log('onDragEnter  ', window.pageYOffset);
    const self = this;
    if (self.dragOverId !== ev.target.id) {
      setTimeout(() => {
        self.dragOverId = ev.target.id;
        self.cd.detectChanges();
      }, 5);
    }
  }
  onDragOver(ev) { }
  onDragLeave(ev) { }

  onDragEnd(ev) {
    this.dragOverId = null;
    console.log('Drag END--------------------');
    this.dragStarted = false;
    localStorage.setItem('dragStarted', 'false');
    const self = this;
    this.setItemDragStarted(this.dataList, this.selectedNodeId, false);
    this.getNodeById2(this.dataList, this.selectedNodeId, false, function (obj) {
      obj.isSelected = false;
      self.selectedNodeId = null;
    });
    this.selectedObj = null;
  }
  getNodeById(arr, id) {
    let obj = null;
    for (let i = 0; i < arr.length; i++) {

      if (arr[i].id === id) {
        // console.log('-------return ', arr[i]);
        obj = arr[i];
        break;
      } else {

        if (arr[i].children && arr[i].children.length > 0) {
          //  console.log('-----------', arr[i].children);
          this.getNodeById(arr[i].children, id);
        }
      }
    }
    return obj;
  }
  getNodeById2(arr, id, isDragObj, callback) {

    if (isDragObj) {
      callback(this.selectedObj);
      return;
    }
    let obj = null;
    for (let i = 0; i < arr.length; i++) {

      if (arr[i].id === id) {
        // console.log('-------return ', arr[i]);
        obj = arr[i];
        callback(obj);
        console.log('-------return---------- ', arr[i]);
        i = arr.length;
        break;
      } else {
        if (obj == null) {
          if (arr[i].children && arr[i].children.length > 0) {
            // console.log('-----------', arr[i].children);
            this.getNodeById2(arr[i].children, id, isDragObj, callback);
          }
        } else {
          callback(obj);
          return;
        }
      }
    }

  }


  getNodeIndex(arr, obj) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === obj) {
        return i;
      }
    }
  }

  removeFromList(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        arr.splice(i, 1);
      }
    }
  }

  onDeleteEvent(item) {
    console.log('onDeleteEvent  ', item);
    this.deleteEvent.emit(item);
  }

  onNodeDeleted(id) {

    const self = this;
    self.getNodeById2(self.dataList, id, false, function (obj) {
      if (obj.parent_id) {
        self.getNodeById2(self.dataList, obj.parent_id, false, function (parentObj) {
          self.removeFromList(parentObj.children, id);
        });
      } else {
        self.removeFromList(self.dataList, id);
      }
    });
  }

  setItemDragStarted(arr, id, val) {

    for (let i = 0; i < arr.length; i++) {
      /* if (arr[i].id !== id && val) {
         arr[i].dragStarted = val;
       } else {
         arr[i].dragStarted = val;
       }*/
      arr[i].dragStarted = val;
      if (arr[i].children && arr[i].children.length > 0) {
        this.setItemDragStarted(arr[i].children, id, val);
      }
    }

  }

  setNodeUnSelected(arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i]['selected'] = false;
      if (arr[i].children && arr[i].children.length > 0) {
        this.setNodeUnSelected(arr[i].children);
      }
    }
  }

  onItemClick(item) {
    console.log(item);
    this.setNodeUnSelected(this.dataList);
    item['selected'] = true;
    this.clickedItemEvent.emit(item);
  }

  /**
   * This function get called from pacing-guild-authoring component after sucessfully added standad to update its item_association_id
   */
  updateStandard(item, isAdded) {
    this.findAndUpdate(this.dataList, item, isAdded);
  }

  findAndUpdate(array, item, isAdded) {
    const self = this;
    array.forEach(element => {
      if (element.id === item.id) {
        if (isAdded) {
          element.item_association_id = item.item_association_id;
          // --------------- //
          element.item_id = item.id;
          element.id = element.item_association_id;
          self.addNodeFromOutside = false;
          self.treeUpdateEvent.emit(self.dataList);
        } else {
          // TODO need to delete object
        }
        // --------------- //
        return;
      } else {
        if (element.children && element.children.length > 0) {
          this.findAndUpdate(element.children, item, isAdded);
        }
      }
    });
  }

  /* --------- Functionality to cut and paste any node start --------- */

  copySourceElement(item, siblings) {
    this.sourceElement = item;
    this.sourceElementSiblings = siblings;
    item.cut = 1;
    this.updateCutReorderValue(item.children, 'cut', 1);
  }

  pasteTargetElement(item) {
    this.targetElement = item;
    this.sourceElement.parent_id = this.targetElement.id;
    this.sourceElement.level = this.targetElement.level + 1;
    if (this.sourceElement.children && this.sourceElement.children.length > 0) {
      this.updatePadding(this.sourceElement.children, this.targetElement.level + 2);
    }
    this.removeFromList(this.sourceElementSiblings, this.sourceElement.id);
    item.children.push(this.sourceElement);
    this.targetElement.paste = 1;
    this.sourceElement.cut = 0;
    this.updateCutReorderValue(this.sourceElement.children, 'cut', 0);
    setTimeout(() => {
      this.targetElement.paste = 0;
      // this.updateCutReorderValue(sourceElement['children'], 'reorder', 0);
    }, 1300);
  }

  /* --------- Functionality to cut and paste any node end --------- */

  /* --------- Functionality to update the key for cut or paste used for updating css for cut and paste start --------- */

  updateCutReorderValue(items, key, val) {
    items.forEach(element => {
      element[key] = val;
      if (element.children && element.children.length > 0) {
        this.updateCutReorderValue(element.children, key, val);
      }
    });
  }

  /* --------- Functionality to update the key for cut or paste used for updating css for cut and paste end --------- */

  /* --------- Functionality to update the padding after pasting new node start --------- */

  updatePadding(sourceElement, level) {
    sourceElement.forEach(element => {
      element.level = level;
      if (element.children && element.children.length > 0) {
        this.updatePadding(element.children, level + 1);
      }
    });
  }

  /* --------- Functionality to update the padding after pasting new node end --------- */

  /* --------- Functionality to re order sibliings under same parent start --------- */

  reOrderSiblings(siblings, index, itemId) {
    // console.log('items siblings', JSON.stringify(siblings));
    let sourceElement;
    let targetElement;
    for (const i in siblings) {
      if (siblings[i]['id'] === itemId) {
        sourceElement = siblings[Number(i)];
        sourceElement.reorder = 1;
        // this.updateCutReorderValue(sourceElement['children'], 'reorder', 1);
        targetElement = siblings[Number(i) + index];
        siblings[Number(i)] = targetElement;
        siblings[Number(i) + index] = sourceElement;
        break;
      }
    }
    setTimeout(() => {
      sourceElement.reorder = 0;
      // this.updateCutReorderValue(sourceElement['children'], 'reorder', 0);
    }, 1300);
    // console.log('target element', targetElement);
    // console.log('source element', sourceElement);
  }

  /* --------- Functionality to re order sibliings under same parent end --------- */
}
