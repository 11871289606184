import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import Utils from '../../utils';
import { SortListComponent } from '../../common/sort-list/sort-list.component';
import {
  Router, NavigationEnd
} from '@angular/router';
import { SharedService } from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
@Component({
  selector: 'app-front-facing-taxonomies',
  templateUrl: './front-facing-taxonomies.component.html'
})
export class FrontFacingTaxonomiesComponent implements OnInit, OnDestroy {

  taxonomyList = [];
  orgId = '';
  viewType = '';

  startIndex = 0;
  lastIndex = 10;
  minItemPerPage;
  eventSubscription: Subscription;
  currentUrl = null;
  taxonomyLoader = false; // holds loading state for fetching taxonomy list

  @ViewChild('sortListRef', { static: false }) sortListRef: SortListComponent;

  // ----------------for Common Data table----------------- //
  listOfColumn = [{
    name: 'Name',
    propName: 'title',
    class: '',
    type: 'link',
    redirect: true,
    loadPage: true
  },
  {
    name: 'Published',
    propName: 'updated_at',
    class: '',
    width: '10%',
    type: 'date',
    dateType: 'amCalendar'
  }
  ];
  optionList = [];

  sortOptions = [{
    'type': 'title',
    'isDate': false,
    'isAsce': true,
    'display': 'Title (A-Z)'
  }, {
    'type': 'title',
    'isDate': false,
    'isAsce': false,
    'display': 'Title (Z-A)'
  }, {
    'type': 'updated_at',
    'isDate': true,
    'isAsce': true,
    'display': 'Published (Asc)'
  }, {
    'type': 'updated_at',
    'isDate': true,
    'isAsce': false,
    'display': 'Published (Desc)'
  }];

  searchingText; // holds text input to search in list
  filterData = []; // holds result array of search
  filterDataEvent: Subscription;
  /*
  , {
    'type': 'description',
    'isDate': false,
    'isAsce': true,
    'display': 'Description (A-Z)'
  }, {
    'type': 'description',
    'isDate': false,
    'isAsce': false,
    'display': 'Description (Z-A)'
  }, */

  constructor(private service: CommonService, private router: Router, private sharedService: SharedService) {
    this.minItemPerPage = Utils.PAGINATION_LOWER_LIMIT;
    // this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
    //   if (event.url.indexOf('/taxonomies') >= 0) {
    //     setTimeout(() => {
    //       if (document.getElementById('Taxonomies-button')) {
    //         const element = document.getElementById('Taxonomies-button');
    //         element.className += ' active';
    //       }
    //     }, 100);
    //   }
    // });


    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      if (event.url.indexOf('/taxonomies') >= 0) {
        this.currentUrl = 'taxonomy';
      }
    });
    this.eventSubscription = this.sharedService.activeLink.subscribe((events: any) => {
    });

    this.sharedService.searchTextEvent.subscribe((res: any) => {
      this.searchingText = res.text;
    });

    this.filterDataEvent = this.sharedService.filterDataTable.subscribe((res: any) => {
      this.filterData = res.data;
    });
  }

  ngOnInit() {
    this.searchingText = '';
    this.getTaxonomiesList();
    if (document.querySelector('body')) {
      const element = document.querySelector('body');
      element.classList.remove('hide-vScroll');
    }
  }

  /* --------- Get taxonomies functionality Started --------- */
  getTaxonomiesList() {
    this.taxonomyList = [];
    this.taxonomyLoader = true;
    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }
    const url = GlobalSettings.FRONT_FACE_TAXONOMY_LIST + '/' + this.orgId;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.taxonomyList = res;
      setTimeout(() => {
        this.sortListRef.sortPublished('Published (Desc)');
      }, 75);
      this.taxonomyLoader = false;
    }).catch((ex) => {
      console.log('getTaxonomiesList Ex', ex);
      this.taxonomyLoader = false;
    });
  }
  /* --------- Get taxonomies functionality Ended --------- */


  changeDateToLocalTimeZone(date) {
    return Utils.changeDateToLocalTimeZone(date);
  }

  changeView(type) {
    this.viewType = type;
  }



  showDataInRange(event) {
    this.startIndex = event.start - 1;
    this.lastIndex = event.last;
  }

  onTaxonomySelected(item) {
    this.sharedService.setTitleEvent.next({
      'type': 'taxonomy',
      'title': item.title,
      'document_id': item.document_id

    });

    const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_FRONT_FACE_TAXONOMY_DETAIL;
    this.router.navigate([path, item.document_id]);
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  getColumnFilterObj() {
    const obj = {};
    obj['title'] = this.searchingText;
    return obj;
  }

}
