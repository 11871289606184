import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler } from '@angular/common/http';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheMapService } from './cache-map.service';

const CACHABLE_URL = [ // Definition for cachable url
    {
        url: '/project/getTreeHierarchy',
        method: 'GET'
    },
    {
        url: '/taxonomy/getTreeHierarchy',
        method: 'GET'
    },
    {
        url: '/project/nodesWithTaxonomyTree',
        method: 'GET'
    },
    {
        url: '/metadata?',
        method: 'GET'
    },
    {
        url: '/workflows/',
        method: 'GET'
    },
    {
        url: '/cache/getTaxonomyList/',
        method: 'GET'
    },
    {
        url: '/cache/getTaxonomyHierarchy/',
        method: 'GET'
    },
    {
        url: '/cache/getTaxonomyDetails/',
        method: 'GET'
    },
    {
        url: '/cache/node_types/',
        method: 'GET'
    },
    {
        url: '/cache/notes/',
        method: 'GET'
    },
    {
        url: '/cache/file/',
        method: 'GET'
    }
    // {
    //     url: '/cfitem/fetch/',
    //     method: 'GET'
    // },
    // {
    //     url: '/cfdoc/',
    //     method: 'GET'
    // }
    ,
    {
        url: '/nodeTypeMetadata/',
        method: 'GET'
    }
];

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cacheMapService: CacheMapService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // If request url is not cachable, then do normal api call
        if (!this.isRequestCachable(req)) {
            // If any update or add or delete api called, then clear all cache data
            if (req.method === 'PUT' || req.method === 'POST' || req.method === 'DELETE') {
                this.cacheMapService.deleteAllCache();
            }
            return next.handle(req);
        }
        const cachedResponse = this.cacheMapService.get(req); // fetch response from cache
        if (cachedResponse !== null) {
            return of(cachedResponse);
        }

        // If response is not available in cache, then take normal api call response
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    this.cacheMapService.put(req, event);
                }
            })
        );
    }

    // Method to check request is cachable or not
    private isRequestCachable(req: HttpRequest<any>) {
        let cachable = false;
        if (CACHABLE_URL.length) {
            const found = CACHABLE_URL.find((elem: any) => {
                return (req.url.includes(elem.url) && (req.method === elem.method));
            });
            if (found) {
                cachable = true;
            }
        }
        return cachable;
    }
}
