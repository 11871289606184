// src/app/auth/auth-guard.service.ts
import {
  Injectable
} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRoute,
  Params, NavigationStart, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import {
  CommonService
} from './common.service';
import {
  SharedService
} from './shared.service';
import {
  GlobalSettings
} from './global.settings';
@Injectable()
export class AuthGuardService implements CanActivate {
  orgCode = '';
  orgId = '';

  constructor(public router: Router, private service: CommonService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // this.router.events.subscribe((url: any) => console.log('####', url));
    if (sessionStorage.getItem('current_url') && sessionStorage.getItem('current_url') !== state.url) {
      // console.log('####', sessionStorage.getItem('current_url'), '####', state.url);
      return false;
    } else {
      const cookieObj = document.cookie;
      console.log('####', sessionStorage.getItem('current_url'), '#### else', state.url, localStorage.getItem('access_token'));
      if (localStorage.getItem('access_token') || (cookieObj.indexOf('SSOUser') !== -1 && cookieObj.indexOf('central_auth_token') !== -1)) {
        return true;
      } else {
        const org = state.url.split('/')[2];
        console.log('############', org);
        localStorage.clear();
        localStorage.setItem('orgDetails', JSON.stringify({
          'orgCode': org,
          'orgId': ''
        }));
        this.setOrgId(org);
        return false;
      }
    }
  }

  setOrgId(org_code) {

    const url = GlobalSettings.GET_ORGANIZATION_CODE + '/' + org_code;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.orgCode = res['org_code'];
      this.orgId = res['organization_id'];
      if (org_code === this.orgCode) {
        localStorage.setItem('orgDetails', JSON.stringify({
          'orgCode': this.orgCode,
          'orgId': this.orgId
        }));
      }
      console.log('this.orgCode', this.orgCode, org_code)
      this.router.navigate(['/org/' + this.orgCode + '/home']);
    });
  }
}
