import {
  Injectable
} from '@angular/core';
import {
  BehaviorSubject
} from 'rxjs/BehaviorSubject';
import {
  Router
} from '@angular/router';

@Injectable()
export class SharedService {
  treeNodeSelectedEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  treeAddNodeEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  treeDeleteNodeEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  sucessEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  setTitleEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  searchEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  nodeUrl: BehaviorSubject<Object> = new BehaviorSubject({});
  projectTreeViewEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  showLoader: BehaviorSubject<any> = new BehaviorSubject(false);
  progressEvent: BehaviorSubject<any> = new BehaviorSubject(null);
  uploadFileIndexEvent: BehaviorSubject<any> = new BehaviorSubject(0);
  setSearchKeyword: BehaviorSubject<Object> = new BehaviorSubject({});
  workFlowId: BehaviorSubject<Object> = new BehaviorSubject({});
  workFlowStages: BehaviorSubject<Object> = new BehaviorSubject({});
  workFlowPermission: BehaviorSubject<Object> = new BehaviorSubject({});
  searchResultEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  blankNodeTypeExceptionEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  getCommentReport: BehaviorSubject<Object> = new BehaviorSubject({});
  preventEdit: BehaviorSubject<Object> = new BehaviorSubject({});
  publishEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  searchText: BehaviorSubject<Object> = new BehaviorSubject({});
  newNotificationEvent: BehaviorSubject<Object> = new BehaviorSubject(false);
  activeLink: BehaviorSubject<Object> = new BehaviorSubject({});
  openTree: BehaviorSubject<Object> = new BehaviorSubject({});
  currentComponentEvent: BehaviorSubject<Object> = new BehaviorSubject(null);
  updateTaxonomyEvent: BehaviorSubject<Object> = new BehaviorSubject(null);
  windowResizeEvent: BehaviorSubject<Object> = new BehaviorSubject(null);
  addExemplerDoc: BehaviorSubject<Object> = new BehaviorSubject(null);
  searchTextEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  filterDataTable: BehaviorSubject<Object> = new BehaviorSubject({});
  faqEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  orgNameChangeEvent: BehaviorSubject<Object> = new BehaviorSubject(null);
  searchFiltersLoaded: BehaviorSubject<Object> = new BehaviorSubject({});
  taxonomyColumnListEvent: BehaviorSubject<Object> = new BehaviorSubject({});
  MSG_TYPE_SUCESS = 0;
  MSG_TYPE_FAILED = 1;
  MSG_TYPE_WARNING = 1;
  backToSearch: BehaviorSubject<Object> = new BehaviorSubject({});
  profileNameChangeEvent: BehaviorSubject<object> = new BehaviorSubject(null);
  csvStatusUpdateEvent: BehaviorSubject<object> = new BehaviorSubject(null);
  preventReorder: BehaviorSubject<object> = new BehaviorSubject(null);
  modalTriggerLocation: BehaviorSubject<object> = new BehaviorSubject(null);
  loadChild: BehaviorSubject<object> = new BehaviorSubject(null);
  notificationListEvent: BehaviorSubject<object> = new BehaviorSubject({});
  orgDetails: BehaviorSubject<object> = new BehaviorSubject({});
  downloadReview: BehaviorSubject<object> = new BehaviorSubject({});
  downloadStatusEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);
  tenantSwitchEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hidePopup: BehaviorSubject<boolean> = new BehaviorSubject(false);
  editTitle: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectRequestClicked: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectPermissionLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userPermissionLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() {

  }


}
