import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import {
  AuthenticateUserService
} from '../../authenticateuser.service';
import Utils from '../../utils';
import {
  CommonService
} from '../../common.service';
import {
  AdditionalMetadataComponent
} from '../../project/additional-metadata/additional-metadata.component';
import {
  ConfirmationDialogService
} from '../../confirmation-dialog/confirmation-dialog.service';
import {
  SharedService
} from '../../shared.service';
import {
  NodeDetailsComponent
} from '../../project/node-details/node-details.component';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  GlobalSettings
} from '../../global.settings';
import {
  ActivatedRoute,
  Params,
  Router
} from '@angular/router';
@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showAdditionalMetadata: boolean;
  @Input() showExemplar: boolean;
  @Input() showDocument: boolean;
  @Input() showAssociation: boolean;
  @Input() view;
  @Input() showNodeType;
  @Input() isEditable;
  @Input() isAllFieldFilled;
  @Input() taxonomyData;
  @Input() projectId;
  @Input() addedAdditionalMetadata;
  @Input() itemExemplars;
  @Input() itemAssets;
  @Input() itemLinkedNodes = [];
  @Input() itemAssociations = [];
  @Input() canPreview = false;
  @Input() viewLocation = '';
  @Input() showEmptyFields = true;
  @Input() nodetypeList; // holds nodetypeList object
  @Input() selectedNodeType; // holds current node type
  @Input() isAllNode = false; // taxonomy structure is selected based this variable (for navigation)
  @Input() treeTaxonomyData; // main taxonomy tree structure data (for navigation)
  @Input() showControls = false; // holds boolean value for showing up controls as save
  @Input() showNavigation = false; // holds boolean value for showing up navigation arrow (for navigation)
  @Input() firstNode: any; // first node in tree (for navigation [optional])
  @Input() lastNode: any; // last node in tree (for navigation [optional])
  @Input() currentTreeTab = '';
  @Input() showModal = true; // to determine if modal should be loaded in DOM, used for confirmation modal multiple instance
  @Output() receiveFormDataEvent = new EventEmitter();
  @Output() formValidateEvent = new EventEmitter();
  @Output() formObjectEvent = new EventEmitter();
  @Output() addActionEvent = new EventEmitter();
  @Output() editActionEvent = new EventEmitter();
  @Output() deleteActionEvent = new EventEmitter();
  @Output() receiveAddonMetaObjEvent = new EventEmitter();
  @Output() assocationCreatedEvent = new EventEmitter();
  @Output() nextClickEvent = new EventEmitter(); // (for navigation)
  @Output() previousClickEvent = new EventEmitter(); // (for navigation)
  @Input() showUserFriendlyPage = false;
  @ViewChild('additionalMetadataComponent', {
    static: false
  }) additionalMetadataComponent: AdditionalMetadataComponent;
  @ViewChild('nodeDetailsComponent', {
    static: false
  }) nodeDetailsComponent: NodeDetailsComponent;

  createAdditionalMetadataPermission = false;
  deleteAssociationsPermission = false;
  addAssociationsPermission = false;

  blankNodeTypeExceptionEvent: Subscription;
  blankNodeType = true;
  formData;
  option = ''; // store the option selected exempler or asset
  action;
  fetchedMetadata: any = {};
  itemAdditionalMetadata: any;
  project_id; // holds project from route params
  preventEdit = false;
  nodeSelectedEvent: Subscription;
  selectedNode; // current selected node on tree
  invalidUrl = false;
  editNodePermission = false;
  addExemplerDocSubscription: Subscription;
  @Input() modal = false;
  associatedNodes = [];
  combinedAssociationArr = [];
  selectedWorkFlowStageEvent: Subscription;
  accessTocken = false;
  selectedStageId = ''; // holds selected stage from project authoring
  /// <reference path='../../..assets/js/common.d.ts'>

  constructor(
    private authenticateUser: AuthenticateUserService, private service: CommonService,
    private dialogService: ConfirmationDialogService, private sharedService: SharedService, private acivatedRoute: ActivatedRoute,
    private router: Router) {
    this.acivatedRoute.params.subscribe((params: Params) => {
      console.log('params', this.acivatedRoute);
      if (params['id'] !== undefined) {
        this.project_id = params['id'];
      }
    });

    this.blankNodeTypeExceptionEvent = this.sharedService.blankNodeTypeExceptionEvent.subscribe((item: any) => {
      this.blankNodeType = item['blankNodeType'];
      console.log('blankNodeType received ', this.blankNodeType);
    });

    this.sharedService.preventEdit.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
      }
    });

    this.nodeSelectedEvent = this.sharedService.treeNodeSelectedEvent.subscribe((item: any) => {
      console.log('item', item);
      if (item) {
        if (item.item_id || item.id) {
          this.selectedNode = item;
          // if (item['is_editable']) {
          //   this.isEditable = item['is_editable'] === (0 || 2) ? false : true;
          // }
        }
      }
    });

    this.addExemplerDocSubscription = this.sharedService.addExemplerDoc.subscribe((item: any) => {
      if (item) {
        this.updateData(item);
      }
    });

    this.selectedWorkFlowStageEvent = this.sharedService.workFlowId.subscribe((event: any) => {
      if (event.workFlowId !== undefined) {
        setTimeout(() => {
          this.selectedStageId = event.currentWorkflowStage;
          this.userRoleRight();
        }, 1000);
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem('access_token')) {
      this.accessTocken = true;
    } else {
      this.accessTocken = false;
    }
    this.showAdditionalMetadata = true;
    // this.showExemplar = false;
    // this.showDocument = false;
    // this.showAssociation = false;
    this.userRoleRight();
    if (this.router.url.indexOf('/project/detail') === -1) {
      this.showControls = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taxonomyData && changes.taxonomyData.currentValue) {
      this.taxonomyData = changes.taxonomyData.currentValue;
    }
    if (changes.treeTaxonomyData && changes.treeTaxonomyData.currentValue) {
      this.treeTaxonomyData = changes.treeTaxonomyData.currentValue;
    }
    if (changes.firstNode && changes.firstNode.currentValue) {
      this.firstNode = changes.firstNode.currentValue;
    }
    if (changes.lastNode && changes.lastNode.currentValue) {
      this.lastNode = changes.lastNode.currentValue;
    }
    if (changes.itemAssociations && changes.itemAssociations.currentValue) {
      this.itemAssociations = Utils.reArrangeArray(changes.itemAssociations.currentValue);
    }
    if (this.nodeDetailsComponent && this.selectedNodeType) {
      this.nodeDetailsComponent.setNodeType(this.selectedNodeType);
    }
    if (changes.addedAdditionalMetadata && changes.addedAdditionalMetadata.currentValue) {
      this.addedAdditionalMetadata = changes.addedAdditionalMetadata.currentValue;
    }

    if ((changes.itemAssociations && changes.itemAssociations.currentValue && changes.itemAssociations.currentValue.length > 0)
      || (changes.itemLinkedNodes && changes.itemLinkedNodes.currentValue && changes.itemLinkedNodes.currentValue.length > 0)) {

      // modifying association array
      if (changes.itemAssociations && changes.itemAssociations.currentValue && changes.itemAssociations.currentValue.length) {
        changes.itemAssociations.currentValue.forEach(element => {
          element['link_type'] = 'association';
          element['target_node'] = element.destination_node;
          element['taxonomy_name'] = element.target_node.document.document_title;
        });
      }

      // modifying linked nodes array
      if (changes.itemLinkedNodes && changes.itemLinkedNodes.currentValue && changes.itemLinkedNodes.currentValue.length) {
        changes.itemLinkedNodes.currentValue.forEach(element => {
          element['link_type'] = 'linked node';
          element['target_node'] = element.source_node;
          element['taxonomy_name'] = element.target_node.document.document_title;
        });
      }

      // combining association and linked nodes in a single array
      if (changes.itemLinkedNodes && changes.itemLinkedNodes.currentValue) {
        this.combinedAssociationArr = changes.itemAssociations.currentValue.concat(changes.itemLinkedNodes.currentValue);
      } else {
        this.combinedAssociationArr = changes.itemAssociations.currentValue;
      }

      // taking association_type_name at outer level for grouping the array
      this.combinedAssociationArr.forEach(node => {
        node['association_type_name'] = node.association_type.type_name;
      });

      // grouping the combined array by taxonomy name
      this.associatedNodes = Utils.groupByType(this.combinedAssociationArr, 'taxonomy_name');

      // grouping the modified array by association type
      this.associatedNodes.forEach(item => {
        item.value = Utils.groupByType(item.value, 'association_type_name', 'sequence_number');
      });
    }
    console.log('changes', changes);
  }

  onReceiveFormObj(event) {
    this.formData = event;
    // this.receiveFormDataEvent.emit(event);
  }
  onFormValidate(event) {
    this.formValidateEvent.emit(event);
  }
  onFormObject(event) {
    if (event.controls['official_source_url'] && event.controls['official_source_url'].value.length > 0) {
      if (event.controls['official_source_url'].valid === false) {
        this.invalidUrl = true;
      } else {
        this.invalidUrl = false;
      }
    } else {
      this.invalidUrl = false;
    }
    this.formObjectEvent.emit(event);
  }
  onReceiveAddonMetaObj(event) {
    this.receiveAddonMetaObjEvent.emit(event);
  }
  onAssocationCreated(event) {
    this.assocationCreatedEvent.emit(event);
  }

  /**
   * User permission checking
   */
  userRoleRight() {
    this.createAdditionalMetadataPermission = this.authenticateUser.AuthenticProjectPermissions('Add Additional Metadata');
    if (this.projectId) {
      const creatAdditionaleMetadata = Utils.checkProjectPermission(this.projectId,
        'Add Additional Metadata', 'project_permissions', this.selectedStageId, 'individual_project_role_permissions');
      if (creatAdditionaleMetadata.hasProject) {
        this.createAdditionalMetadataPermission = creatAdditionaleMetadata.valid;
      }
    }
    this.deleteAssociationsPermission = this.authenticateUser.AuthenticProjectPermissions('Delete Associations');
    if (this.projectId) {
      const deleteAsso = Utils.checkProjectPermission(this.projectId,
        'Delete Associations', 'project_permissions', this.selectedStageId, 'individual_project_role_permissions');
      if (deleteAsso.hasProject) {
        this.deleteAssociationsPermission = deleteAsso.valid;
      }
    }
    this.addAssociationsPermission = this.authenticateUser.AuthenticProjectPermissions('Create Associations');
    if (this.projectId) {
      const createAsso = Utils.checkProjectPermission(this.projectId,
        'Create Associations', 'project_permissions', this.selectedStageId, 'individual_project_role_permissions');
      if (createAsso && createAsso.hasProject) {
        this.addAssociationsPermission = createAsso.valid;
      }
    }

    this.editNodePermission = this.authenticateUser.AuthenticProjectPermissions('Edit Node');
    const editNode = Utils.checkProjectPermission(this.projectId,
      'Edit Node', 'project_permissions', this.selectedStageId, 'individual_project_role_permissions');
    if (editNode && editNode.hasProject) {
      this.editNodePermission = editNode.valid;
    }
  }

  onAddButtonClick(sectionType, data?) {
    const eventData: any = {
      'sectionType': sectionType,
      'data': (data ? data : null)
    };
    this.addActionEvent.emit(eventData);
  }

  onEditButtonClick(sectionType, data?) {
    const eventData: any = {
      'sectionType': sectionType,
      'data': (data ? data : null)
    };
    this.editActionEvent.emit(eventData);
  }

  onDeleteButtonClick(sectionType, data?) {
    const eventData: any = {
      'sectionType': sectionType,
      'data': (data ? data : null)
    };
    this.deleteActionEvent.emit(eventData);
  }

  /**
   * To generate node details form data
   * @param data (Selected tree node response)
   * @param nodeTypeTitle (nodeTypeTitle)
   */
  generateFormData(data, nodeTypeTitle) {
    if (this.nodeDetailsComponent) {
      this.nodeDetailsComponent.generateForm(data, nodeTypeTitle);
    }
  }

  // Added code for editable functionality

  /* --------- Functionality to identify which add + button is clicked start --------- */

  optionSelected(data) {
    this.option = data;
    // Resizing height of right panel box of add-exemplar modal body
    setTimeout(() => {
      exemplarAssetPanelHeight();
      focusOnModalCloseBtn();
      Utils.removeShowClass();
    }, 500);

    console.log(this.option);
  }

  /* --------- Functionality to identify which add + button is clicked end --------- */


  /* --------- Functionality for height and setting action for additional metadata add start --------- */

  onAddMetadata() {
    this.action = 'add';
    this.fetchedMetadata = new Object();
    // Resizing height of right panel box of adding additional-metadata modal body
    setTimeout(() => {
      additionalMetadataPanelHeight();
      focusOnModalCloseBtn();
    }, 500);
  }

  /* --------- Functionality for height and setting action for additional metadata add start --------- */


  /* --------- Functionality to edit additional metadata start --------- */

  onEditAdditionalMetadata(obj) {
    this.fetchedMetadata = new Object();
    const url = GlobalSettings.GET_METADATA_LIST + '/' + obj.metadata_id;

    // Fetching metadata value againgst metadata id and combining the value associated to the selected item
    this.service.getServiceData(url).then((res: any) => {
      this.fetchedMetadata = res;
      if (typeof this.fetchedMetadata.field_possible_values === 'string') {
        this.fetchedMetadata.field_possible_values = this.fetchedMetadata.field_possible_values.split(',');
      }
      this.fetchedMetadata.metadata_value = obj.metadata_value;
      this.action = 'edit';
    }).catch((ex) => {
      console.log('Error caught while fetching metadata', ex);
    });
  }

  /* --------- Functionality to edit additional metadata end --------- */

  /**
   * Delete Project Resource like Exemplar, Assets, Additional metadata
   */
  onDeleteProjectResource(item_association_id, resourceType) {
    let msg, url, successEventType;
    if (resourceType === 'Document') {
      msg = 'Are you sure you want to delete this document?';
      url = GlobalSettings.ASSET_ASSOCIATION + '/' + item_association_id;
      successEventType = 'delete_asset';
    } else if (resourceType === 'Exemplar') {
      msg = 'Do you want to delete the exemplar?';
      url = GlobalSettings.EXEMPLER_ASSOCIATION + '/' + item_association_id;
      successEventType = 'delete_exemplar';
    } else if (resourceType === 'AdditionalMetadata') {
      msg = 'Do you want to delete this additional metadata?';
      if (this.selectedNodeType['is_document'] === 1) { // Document level selection
        url = GlobalSettings.DOCUMENT_LEVEL_ADDITIONAL_METADATA + '/' + this.taxonomyData.item_id + '/' + item_association_id;
        // item_association_id => metadata id
      } else if (this.selectedNodeType['is_document'] === 0) { // Item level selection
        url = GlobalSettings.ITEM_LEVEL_ADDITIONAL_METADATA + '/' + this.taxonomyData.item_id + '/' + item_association_id;
        // item_association_id => metadata id
      }
      successEventType = 'delete_additional_metadata';
      // this.updateNodeDetail()
    }

    this.dialogService.confirm('Confirm', msg)
      .then((confirmed) => {
        if (confirmed) {
          this.service.deleteServiceData(url).then((res) => {
            console.log('onDeleteProjectresourceEvent  ', item_association_id);
            this.sharedService.addExemplerDoc.next({
              'res': {
                'item_association_id': item_association_id
              },
              'type': resourceType,
              'action': 'delete'
            });
            if (resourceType === 'Document') {
              this.itemAssets = this.removeProjectResourceFromList(this.itemAssets, item_association_id, resourceType);
            } else if (resourceType === 'Exemplar') {
              this.itemExemplars = this.removeProjectResourceFromList(this.itemExemplars, item_association_id, resourceType);
            } else if (resourceType === 'AdditionalMetadata') {
              // this.onAddDelteExemplar();
              this.itemAdditionalMetadata = this.removeProjectResourceFromList(this.itemAdditionalMetadata,
                item_association_id,
                resourceType);
              this.addedAdditionalMetadata = this.removeProjectResourceFromList(this.addedAdditionalMetadata,
                item_association_id,
                resourceType);
              if (this.additionalMetadataComponent &&
                this.additionalMetadataComponent.metadataList &&
                this.additionalMetadataComponent.metadataList.length > 0) {
                this.additionalMetadataComponent.getNodeTypeMetadata();
              } else {
                this.additionalMetadataComponent.getAllMetadata();
              }
              setTimeout(() => {
                additionalMetadataPanelHeight();
              }, 100);
            }
            this.sharedService.sucessEvent.next({
              type: successEventType
            });
          }).catch((ex) => {
            console.log('onDeleteProjectresourceEvent  ', ex);
          });
        } else {
          console.log('User cancel the dialog');
        }
      })
      .catch(() => {
        console.log('User dismissed the dialog');
      });
  }



  /**
   * Removing resource (Exemplar, Assets) item from array
   * @param list[]
   * @param item_association_id
   * @param resourceType
   */
  removeProjectResourceFromList(list, item_association_id, resourceType) {
    let modifiedList = [];
    if (list) {
      let index = 0;
      let foundIndex = -1;
      if (resourceType === 'Document') {
        list.forEach(element => { // For Assets, unique field is asset_id
          if (item_association_id === element.asset_id) {
            foundIndex = index;
          }
          index++;
        });
      } else if (resourceType === 'Exemplar') {
        list.forEach(element => { // For Exemplar, unique field is association_id
          if (item_association_id === element.association_id) {
            foundIndex = index;
          }
          index++;
        });
      } else if (resourceType === 'AdditionalMetadata') {
        list.forEach(element => { // For AdditionalMetadata, unique field is metadata_id
          if (item_association_id === element.metadata_id) {
            foundIndex = index;
          }
          index++;
        });
      }
      if (foundIndex > -1) {
        if (list.length > foundIndex) {
          list.splice(foundIndex, 1);
        }
      }
      modifiedList = list;
    }
    return modifiedList;
  }


  /* --------- Functionality to add and delete association start --------- */

  onAddAssociation() {
    // Resizing height of right panel box of adding association modal body
    setTimeout(() => {
      assetPanelHeight();
      focusOnModalCloseBtn();
    }, 500);
  }


  deleteAssociation(item) {

    this.dialogService.confirm('Confirm', 'Do you want to delete the association?')
      .then((confirmed) => {
        if (confirmed) {
          // console.log(' deleteAssociation ', item);
          if (item.item_association_id) {
            const url = GlobalSettings.DELETE_ITEM_ASSOCIATION + '/' + item.item_association_id;
            this.service.deleteServiceData(url).then((res) => {
              this.sharedService.sucessEvent.next({
                type: 'delete_association'
              });
              const id = this.taxonomyData.item_id ? this.taxonomyData.item_id : this.taxonomyData['id'];
              // this.getSelectedTreeItemDetail(id);
              if (document.getElementById(id + '-editnode')) {
                document.getElementById(id + '-editnode').click();
              }
              if (this.currentTreeTab === 'tableview' && item.association_type.type_name === 'isChildOf') {
                location.reload();
              }
            }).catch((ex) => {
              console.log('deleteAssociation ', ex);
              this.sharedService.sucessEvent.next({
                type: 'not_delete_child_association'
              });
            });
          }
        } else {
          console.log('User cancel the dialog');
        }
      })
      .catch(() => {
        console.log('User dismissed the dialog');
      });

  }

  /* --------- Functionality to add and delete association end --------- */


  /* --------- Functionality to add and delete exemplar and document start --------- */

  onAddDelteExemplar() {
    const id = this.taxonomyData.item_id ? this.taxonomyData.item_id : this.taxonomyData['id'];
    // this.getSelectedTreeItemDetail(id);
    if (document.getElementById(id + '-editnode')) {
      document.getElementById(id + '-editnode').click();
    }
  }

  /* --------- Functionality to add and delete exemplar and document end --------- */

  editNode() {
    this.nodeDetailsComponent.checkValidation();
    this.nodeDetailsComponent.getFormData();
    this.formData['parent_id'] = this.taxonomyData.parent_id;
    this.formData['document_id'] = this.taxonomyData.document_id;
    this.formData['projectId'] = this.projectId;
    console.log('dateformat ********* ', this.formData);
    if (this.formData['status_start_date']) {
      if (this.formData['status_start_date']['formatted']) {
        this.formData['status_start_date'] = this.formData['status_start_date']['formatted'];
      } else {
        if (this.formData['status_start_date']['date']) {
          this.formData['status_start_date'] = this.formData['status_start_date']['date']['year'] +
            '-' + this.formateNumber(this.formData['status_start_date']['date']['month']) +
            '-' + this.formateNumber(this.formData['status_start_date']['date']['day']);
        }
      }
    }
    if (this.formData['status_end_date']) {
      if (this.formData['status_end_date']['formatted']) {
        this.formData['status_end_date'] = this.formData['status_end_date']['formatted'];
      } else {
        if (this.formData['status_end_date']['date']) {
          this.formData['status_end_date'] = this.formData['status_end_date']['date']['year'] +
            '-' + this.formateNumber(this.formData['status_end_date']['date']['month']) +
            '-' + this.formateNumber(this.formData['status_end_date']['date']['day']);
        }
      }
    }
    if (this.formData['language_name']) {
      this.formData['language_name'] = this.formData['language_name']['short_code'];
    }
    const body = this.formData;
    let url;
    const id = this.taxonomyData.item_id ? this.taxonomyData.item_id : this.taxonomyData['id'];

    if (this.taxonomyData['document_type']) {
      url = GlobalSettings.SAVE_CFDOC_NODE + '/' + id;
    } else {
      url = GlobalSettings.EDIT_NODE + '/' + id;
    }
    this.service.putService(url, body).then((res: any) => {
      this.taxonomyData['human_coding_scheme'] = res.human_coding_scheme;
      this.taxonomyData['full_statement'] = res.full_statement;
      this.taxonomyData['education_level'] = res.education_level;
      this.taxonomyData['sequence_number'] = res.sequence_number;

      this.selectedNode['human_coding_scheme'] = res.human_coding_scheme;
      // this.selectedNode['human_coding_scheme_html'] = res.human_coding_scheme_html;
      this.selectedNode['full_statement'] = res.full_statement;
      this.selectedNode['full_statement_html'] = res.full_statement_html;
      this.selectedNode['education_level'] = res.education_level;
      this.selectedNode['sequence_number'] = res.sequence_number;

      if (this.nodetypeList) {
        this.nodetypeList.forEach(element => {
          if (element.node_type_id === res.node_type_id) {
            this.selectedNode['node_type'] = element.title;
          }
        });
      }
      if (this.taxonomyData['document_type'] === 1) {
        this.taxonomyData['full_statement'] = res.title;
        this.selectedNode['full_statement'] = res.title;
        this.selectedNode['full_statement_html'] = res.title_html;
        this.selectedNode['title_html'] = res.title_html;
      }

      this.sharedService.sucessEvent.next({
        type: 'edit_node'
      });
      this.getItemsComplianceReport();
    }).catch((ex) => {
      console.log('createNode ', ex);
    });
  }

  formateNumber(num) {
    return ('0' + num).slice(-2);
  }


  /* --------- Functionality to update exempler and document on table start --------- */

  updateData(data) {
    if (data.action === 'add') {
      if (data.type === 'Exemplar') {
        if (this.selectedNode && this.selectedNode['itemExemplars']) {
          this.updateObjForTable(this.selectedNode['itemExemplars'], data.res, data.fileDetails);
        }
      } else if (data.type === 'Document') {
        this.updateObjForTable(this.selectedNode['itemAssets'], data.res, data.fileDetails);
      }
    } else {
      if (data.action === 'delete') {
        // console.log('data.res', data.res['item_association_id'], data.type);
        if (data.type === 'Exemplar') {
          for (const i in this.selectedNode['itemExemplars']) {
            if (i) {
              if (this.selectedNode['itemExemplars'][i]['association_id'] === data.res['item_association_id']) {
                this.selectedNode['itemExemplars'].splice(i, 1);
                break;
              }
            }
          }
        } else if (data.type === 'Document') {
          // association_id become asset_id when data come from backend
          for (const i in this.selectedNode['itemAssets']) {
            if (i) {
              if (this.selectedNode['itemAssets'][i]['association_id'] === data.res['item_association_id'] ||
                this.selectedNode['itemAssets'][i]['asset_id'] === data.res['item_association_id']) {
                this.selectedNode['itemAssets'].splice(i, 1);
                break;
              }
            }
          }
        }
      }
    }
  }

  updateObjForTable(selectedNode, res, fileDetails) {
    let asset_file_name = '';
    if (fileDetails && fileDetails['name']) {
      asset_file_name = fileDetails['name'];
    } else if (res['asset_name']) {
      asset_file_name = res['asset_name'];
    }
    if (selectedNode && this.selectedNode.id === res.item_linked_id &&
      !(selectedNode.find((x) => x.association_id === res['item_association_id'] ? res['item_association_id'] : res['asset_id'])) &&
      !(selectedNode.find((x) => x.asset_id === res['asset_id']))

    ) {
      selectedNode.unshift({
        association_id: res['item_association_id'] ? res['item_association_id'] : res['asset_id'],
        title: res['external_node_title'] ? res['external_node_title'] : res['title'],
        description: res['description'],
        has_asset: res['has_asset'] ? res['has_asset'] : 0,
        external_url: res['external_node_url'],
        external_download_url: res['external_node_url'],
        asset_id: res['asset_id'] ? res['asset_id'] : '',
        asset_file_name
      });
    }
  }

  /* --------- Functionality to update exempler and document on table end --------- */

  ngOnDestroy() {
    if (this.nodeSelectedEvent) {
      this.nodeSelectedEvent.unsubscribe();
    }
    if (this.addExemplerDocSubscription) {
      this.addExemplerDocSubscription.unsubscribe();
    }
    if (this.selectedWorkFlowStageEvent) {
      this.selectedWorkFlowStageEvent.unsubscribe();
    }
  }

  getItemsComplianceReport() {
    if (this.project_id) {
      const url = GlobalSettings.ITEM_COMPLIANCE_REPORT + '/' + this.project_id + '/2';
      this.service.getServiceData(url).then((res: any) => {
        for (const i in res) {
          if (i) {
            if (res[i]['item_id'] === this.selectedNode['item_id'] || res[i]['item_id'] === this.selectedNode['id']) {
              this.selectedNode['compStatus'] = res[i]['status'];
              break;
            }
          }
        }
      }).catch((ex) => {
        console.log('getItemsComplianceReport ', ex);
      });
    }
  }

  /**
   * On next button click event emitter
   */
  onTreeNavigateNextClick(eventData) {
    this.selectedNode = eventData.node;
    this.nextClickEvent.emit(eventData);
  }

  /**
   * On previous button click event emitter
   */
  onTreeNavigatePreviousClick(eventData) {
    this.selectedNode = eventData.node;
    this.previousClickEvent.emit(eventData);
  }

  /* open taxonomy in new tab */
  navigateToTaxonomy(node: any, associationId) {
    let url = '';
    const urlPrefix = GlobalSettings.BASE_URL.replace('server/', '#').replace('api.', '') + this.service.getOrgDetails();
    if (node) {
      const docId = node.document.document_id;
      const parentId = node.parent_id;
      const nodeId = node.item_id;
      const projId = node.project_id;
      const itemAssoId = node.item_association_id; // for pacing guide
      if (node.project_type === 2 && projId) { // If pacing guide and project id exists
        if (parentId && nodeId && itemAssoId) { // If pacing guide's item id found
          url = urlPrefix + '/' + Utils.ROUTE_PACING_GUIDE_DETAIL + '/' + projId + '/' + parentId + '/' + nodeId +
            '/' + itemAssoId + '/search';
        } else {
          url = urlPrefix + '/' + Utils.ROUTE_PACING_GUIDE_DETAIL + '/' + projId + '/search';
        }
      } else if (docId) { // If not pacing guide and document id exists
        if (nodeId && parentId) {
          url = urlPrefix + '/' + Utils.ROUTE_TAXONOMY_DETAIL + '/' + docId + '/' + parentId + '/' + nodeId + '/search';
        } else if (parentId) {
          url = urlPrefix + '/' + Utils.ROUTE_TAXONOMY_DETAIL + '/' + docId + '/' + parentId;
        } else {
          url = urlPrefix + '/' + Utils.ROUTE_TAXONOMY_DETAIL + '/' + docId;
        }
      } else { // If nothing exists, set empty url and disabled navigation
        url = '';
      }
    }
    return url;
  }

  openNewTab(event, id) {
    // event.preventDefault();
    // if (document.getElementById(id)) {
    //   document.getElementById(id).dispatchEvent(new MouseEvent('click', {
    //     ctrlKey: true,
    //     cancelable: true
    //   }));
    // }
  }

  openInNewTab(event, node, id) {
    localStorage.setItem('association_link_clicked', 'true');
    const currentUrl = sessionStorage.getItem('current_url');
    const frontFaceUrl = sessionStorage.getItem('frontface_url');
    sessionStorage.clear();
    const url = this.navigateToTaxonomy(node, id);
    const win = window.open(url, '_blank');
    sessionStorage.setItem('current_url', currentUrl);
    sessionStorage.setItem('frontface_url', frontFaceUrl);
    win.focus();
    event.preventDefault();
  }


  /**
   * To decide whether to show Node's full statement/human coding scheme or Uri or empty
   * @param node (destination node from association list)
   */
  isNodeOrUri(node: any) {
    let showItem = '';
    if (node) {
      if (node.is_external === 1) {
        showItem = 'uri';
      } else {
        if ((node.full_statement || node.human_coding_scheme) && node.item_id) {
          showItem = 'node';
        } else if ((node.full_statement === '' && node.human_coding_scheme === '') &&
          (node.node_type_title.toLowerCase().trim() !== 'document')) {
          showItem = 'uri';
        } else if (node.node_type_title && (node.node_type_title.toLowerCase().trim() === 'document')) {
          showItem = 'node';
        } else {
          showItem = '';
        }
      }

    }
    return showItem;
  }

  assModalClosedEvent(evt) {
    location.reload();
  }

}
