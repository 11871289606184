import { HttpResponse } from '@angular/common/http';
import Utils from '../utils';

export interface CacheEntry {
    url: string;
    response: HttpResponse<any>;
    entryTime: number;
}

export const MAX_CACHE_AGE = Utils.MAX_CACHE_AGE; // in milliseconds
