import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-compliance-details',
  templateUrl: './compliance-details.component.html',
  styleUrls: ['./compliance-details.component.scss']
})
export class ComplianceDetailsComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() itemList: any[];
  @Input() itemType: string; // issues or instances

  constructor() { }

  ngOnInit() {
  }

  /* --------- Functionality to generate Lable Start --------- */

  getKey(key) {
    let lable = '';
    key.split('_').forEach(element => {
      lable = lable + element + ' ';
    });
    return lable;
  }

  /* --------- Functionality to generate Lable End --------- */

}
