import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Inject
} from '@angular/core';
import {
  ActivatedRoute,
  Params,
  Router
} from '@angular/router';
import {
  CommonService
} from '../common.service';
import {
  GlobalSettings
} from '../global.settings';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  CustomValidators
} from 'ng2-validation';
import {
  environment
} from '../../environments/environment';

// import {
//   DOCUMENT
// } from '@angular/platform-browser';
import {
  SharedService
} from '../shared.service';
import Utils from '../utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./../nav-bar/nav-bar-logo-img.scss']
})


export class RegisterComponent implements OnInit, AfterViewInit {
  isFirstTime = false;
  form: FormGroup;
  // public reactiveForm: FormGroup = new FormGroup({
  //   recaptchaReactive: new FormControl(null, Validators.required)
  // });
  captchaValidate = false;
  siteKey = '6Le7tYsUAAAAACM8LIHTr9dWMHQlxUIKVqTPHGLV';
  username: FormControl;
  username_last: FormControl;
  // password: FormControl;
  // cpassword: FormControl;
  tocken;
  userDetail: any;
  invalidPassword = false;
  match = false;
  pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/;
  emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  email: any;
  showAlert = false;
  alertMsg = '';
  passwordEnt = '';
  repasswordEnt = '';
  checkRadio = '';
  checkRadio_K12 = '';
  checkRadio_HE = '';
  gradeLevels = [];
  selectedSubjects = [];
  selectedLanguages = [];
  default_org_id = '';
  otherLangs = '';
  success = false;
  showTextBox = false;
  isSuccess = false;
  valid = false;
  windowHeight;
  panelHeight;
  disableEmail = false;
  languagesList = [];
  gradeLevelList = Utils.GRADE_LEVEL_LIST;
  subjectAreas = Utils.SUBJECT_AREA_LIST;
  languageList = Utils.LANGUAGE_LIST;
  isSSOUser = GlobalSettings.SSO_USER;
  loginLoader = false;

  @ViewChild('emailInput', { static: false }) emailInput: ElementRef;
  @ViewChild('formPanel', { static: false }) formPanel: ElementRef;
  isAlradyregister: boolean;
  isRegister = true;

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
    this.captchaValidate = true;
  }

  constructor(
    // @Inject(DOCUMENT) private document: any,
    private activatedRoute: ActivatedRoute,
    private service: CommonService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private router: Router) {
    console.log('document.location.hostname', document.location.hostname);

    if (document.location.hostname.indexOf('localhost') !== -1) {
      this.siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    } else if (document.location.hostname.indexOf('acmt-stg2.learningmate') !== -1) {
      this.siteKey = '6LfMyYsUAAAAAC54odbs2OA5APwf9kO7BDqjJU0k';
    } else if (document.location.hostname.indexOf('comet-stg.learningmate') !== -1) {
      this.siteKey = '6LcBxIsUAAAAAK1YHuKGMAlCv4a3OvbfC2rTESAU';
    } else if (document.location.hostname.indexOf('acmt-scde-stag.learningmate') !== -1) {
      this.siteKey = '6LcExIsUAAAAAHVL97xaoNK74eBPXYoHTdJKyxXU';
    } else if (document.location.hostname.indexOf('acmt-scde.learningmate') !== -1) {
      this.siteKey = '6Lemx4sUAAAAADl6Tb4DnpCbwYchdFyhFlOY2tUZ';
    } else if (document.location.hostname.indexOf('standards.ed.sc.gov') !== -1) {
      this.siteKey = '6LdBWIwUAAAAAFedAHDCfxMXRfqR7w5H31lDiFcC';
    } else if (document.location.hostname.indexOf('acmt-qa2.learningmate') !== -1) {
      this.siteKey = '6LcVEagUAAAAAI0lWovf3JQpQ3f3diIqKTo0gVRT';
    } else if (document.location.hostname.indexOf('acmt-dev2.learningmate') !== -1) {
      this.siteKey = '6LdHEagUAAAAAC_WIM45-Y6xosHhrR4_c2B3UI5D';
    } else if (document.location.hostname.indexOf('acmt-prod.learningmate') !== -1) {
      this.siteKey = '6LerEKgUAAAAAB-TBlf9o_vVlj8noVmxXMj5JrxI';
    } else if (document.location.hostname.indexOf('acmt-learningmate.com') !== -1) {
      this.siteKey = '6LdF2bcUAAAAAByIP5Ah4hPiK-fo6prPvn6WTLZY';
    } else if (document.location.hostname.indexOf('standards.isbe.net') !== -1) {
      this.siteKey = '6LeS5t4UAAAAAA9u8ldp4GLoWk6-tWyT9_Bwfdm6';
    } else if (document.location.hostname.indexOf('acmt-preprod.learningmate.com') !== -1) {
      this.siteKey = '6LdvHvoUAAAAAORO9AYYVDK-SCCWCBWEvaAXz1yd';
    } else if (document.location.hostname.indexOf('acmtapp-mtdev.learningmate.co') !== -1) {
      this.siteKey = '6LdshK8ZAAAAAOeLl4Bjzyr557-Ws9vBwHtKM_Al';
    } else if (document.location.hostname.indexOf('acmt-dev.learningmate.co') !== -1) {
      this.siteKey = '6Lcb9q4ZAAAAADg4g57q0TncR5rqs5lrMyAYTTnB';
    } else if (document.location.hostname.indexOf('aks.gcpsk12.org') !== -1) {
      this.siteKey = '6LeqCbwZAAAAACHYZpFn0wFShu_fTO0wbizmneyA';
    } else if (document.location.hostname.indexOf('admin.frostplatform.com') !== -1) {
      this.siteKey = '6Le3h8IZAAAAADPUbov1GpseHiIWah1jb199SZb6';
    } else if (document.location.hostname.indexOf('unifiedfrost-acmt-qa.learningmate.co') !== -1) {
      this.siteKey = '6LeGV8wZAAAAAPBxjUpaIGtRbgQS-TQpIBHTfzKw';
    } else if (document.location.hostname.indexOf('unifiedfrost-acmt-stage.learningmate.co') !== -1) {
      this.siteKey = '6LePV8wZAAAAAJKr8uKlc3whe3RoOKP5Ih8UGfgr';
    } else if (document.location.hostname.indexOf('acmt.frostplatform.com') !== -1) {
      this.siteKey = '6LejV8wZAAAAAFEUrA8TlJn4rxBeCGfHZ30xK2V_';
    } else if (document.location.hostname.indexOf('acmt-qa.learningmate') !== -1) {
      this.siteKey = '6Lelx4sUAAAAAEh-FLW5q8HyZum1xmzf92n20Mfr';
    }


    const password = new FormControl(null, Validators.required);

    this.showAlert = false;
    if (this.isSSOUser) {
      this.form = this.fb.group({
        username: [null, Validators.compose([Validators.required])],
        username_last: [null, Validators.compose([Validators.required])],
        email: ['', [Validators.required, CustomValidators.email]]
      });
      this.match = true;
    } else {
      this.form = this.fb.group({
        username: [null, Validators.compose([Validators.required])],
        username_last: [null, Validators.compose([Validators.required])],
        email: ['', [Validators.required, CustomValidators.email]],
        password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(12), Validators.pattern(this.pwdPattern)]],
        cpassword: [null, Validators.compose([Validators.required])],
      });
    }

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] !== undefined) {
        this.showAlert = false;
        this.tocken = params['id'];
        this.getUserDetail();
      } else {
        console.log('Route param is blank');
      }
      console.log(' tocken ', this.tocken);
    });

    if (this.router.url && this.router.url.split('/').pop() === 'register') {
      this.disableEmail = false;
    } else {
      this.disableEmail = true;
    }
  }

  ngOnInit() {
    document.title = 'ACMT : Register';
    document.querySelector('body').classList.remove('overflow-hidden');
    this.getOrganizationDetails();
    //for excludes other lang in languagelist object
    this.languageList.forEach(element => {
      if (element.label !== 'Other language') {
        this.languagesList.push(element);
      }
    });
    setTimeout(() => {
      if (document.getElementById('first_name')) {
        document.getElementById('first_name').focus();
      }
    }, 500);
  }

  ngAfterViewInit() {
    if (document.getElementById('name')) {
      document.getElementById('name').focus();
    }
  }

  getheight() {
    this.windowHeight = (window.innerHeight) + 'px';
    this.panelHeight = this.formPanel.nativeElement.offsetHeight + 'px';
    return (this.windowHeight < this.panelHeight ? false : true);
  }

  getUserDetail() {

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] !== undefined) {
        this.showAlert = false;
        this.tocken = params['id'];
      } else {
        console.log('Route param is blank');
      }

    });
    const url = GlobalSettings.GET_USER_DETAIL_BY_TOCKEN + '/' + this.tocken;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      if (res) {
        this.userDetail = res;
        this.email = res.username;
        if (this.emailInput) {
          this.emailInput.nativeElement.value = res.username;
        }
        if (document.getElementById('emailInputId')) {
          document.getElementById('emailInputId').innerText = res.username;
        }
        console.log('res ', res);
      }

    }).catch((ex) => {
      this.sharedService.sucessEvent.next({
        customMsg: ex.msg,
        type: 'error'
      });
      console.log('ex ', ex);
      this.isAlradyregister = true;
      this.isRegister = false;
    });
  }

  radioOptionSelected(eVal, type, event) {
    if ((event && event.keyCode === 32)) {
      setTimeout(() => {
        if (this.checkRadio === '1') {
          if (!this.isFirstTime) {
            document.getElementById('k12Instruction').setAttribute('role', 'alert');
            this.isFirstTime = true;
          } else {
            document.getElementById('k12Instruction').removeAttribute('role');
          }
        }
      }, 300);
    }
    switch (type) {
      case 'teacher':
        this.checkRadio = eVal;
        break;
      case 'admin':
        this.checkRadio_K12 = eVal;
        break;
      case 'HEworker':
        this.checkRadio_HE = eVal;
        break;
      default:
    }
  }

  checkboxSelected(obj: any, type: string) {
    obj.checked = !obj.checked;
    if (obj.checked) {
      switch (type) {
        case 'grade':
          this.gradeLevels.push(obj.id);
          break;
        case 'subject':
          this.selectedSubjects.push(obj.id);
          break;
        case 'language':
          this.selectedLanguages.push(obj.id);
          break;
        default:
      }
    } else {
      switch (type) {
        case 'grade':
          this.gradeLevels.splice(this.gradeLevels.indexOf(obj.id), 1);
          break;
        case 'subject':
          this.selectedSubjects.splice(this.selectedSubjects.indexOf(obj.id), 1);
          break;
        case 'language':
          this.selectedLanguages.splice(this.selectedLanguages.indexOf(obj.id), 1);
          break;
        default:
      }
    }
  }
  onRegister() {
    this.loginLoader = true;
    console.log('onRegister ', this.form.invalid, this.form.value + 'this.userDetail' + this.userDetail);
    let url;
    let body;
    if (this.tocken === undefined) {
      url = GlobalSettings.SELF_REGISTRATION_URL;
      body = {
        first_name: this.username,
        last_name: this.username_last,
        email_id: this.email,
        // password: this.passwordEnt,
        // confirm_password: this.repasswordEnt,
        organization_id: JSON.parse(localStorage.getItem('orgDetails'))['orgId'],
        reg_user_type: this.checkRadio,
        teacher_grade_level_taught: [],
        teacher_subject_area_taught: [],
        admin_district_language_taught: [],
        admin_district_other_language_taught: this.otherLangs
      };

      if (!this.isSSOUser) {
        body['password'] = this.passwordEnt;
        body['confirm_password'] = this.repasswordEnt;
      }
      if (this.checkRadio && parseInt(this.checkRadio, 10) && (parseInt(this.checkRadio, 10) === 1)) {
        for (let i = 0; i < this.gradeLevels.length; i++) {
          body.teacher_grade_level_taught.push({
            'key': this.gradeLevels[i]
          });
        }
        for (let j = 0; j < this.selectedSubjects.length; j++) {
          body.teacher_subject_area_taught.push({
            'key': this.selectedSubjects[j]
          });
        }
      }
      for (let k = 0; k < this.selectedLanguages.length; k++) {
        body.admin_district_language_taught.push({
          'key': this.selectedLanguages[k]
        });
      }
    } else {
      url = GlobalSettings.REGISTER_COMPLETE;
      body = {
        'new_user_id': this.userDetail.user_id,
        'new_first_name': this.username,
        'new_last_name': this.username_last,
        'new_password': this.form.value.password,
        'new_user_name': this.userDetail.username,
        'new_organization_id': this.userDetail.organization_id
      };
    }

    this.service.registerService(url, body).then((res: any) => {
      if (res.data.result === 'exist' || res.data.result === 'deactive') {
        this.sharedService.sucessEvent.next({
          customMsg: res.message,
          type: 'login_error'
        });
      } else {
        this.sharedService.sucessEvent.next({
          customMsg: 'You are successfully registered.',
          type: 'reg_success'
        });
        localStorage.setItem('register_mail', res.email);
        this.success = true;
        this.isRegister = false;
        this.isAlradyregister = false;
      }
      this.loginLoader = false;
    }).catch((ex) => {
      this.isSuccess = false;
      this.success = false;
      this.isRegister = true;
      this.sharedService.sucessEvent.next({
        customMsg: ex.msg,
        type: 'login_error'
      });
      this.loginLoader = false;
    });
  }

  click() {
    // this.erromessage = true;
  }

  getOrganizationDetails() {
    if (localStorage.getItem('orgDetails')) {
      this.default_org_id = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
    }


  }
  toggleEditable(event) {
    if (event.checked) {
      this.showTextBox = true;
      if (document.getElementById('other-lang')) {
        setTimeout(() => {
          document.getElementById('otherlang').focus();
        }, 100);
      }
    } else {
      this.showTextBox = false;
    }
  }

  goToLogin() {

    // const orgCode = this.service.getOrgDetails();
    // localStorage.setItem('orgDetails', JSON.stringify({
    //   'orgCode': orgCode.split('/')[2],
    //   'orgId': ''
    // }));
    if (this.isSSOUser) {
      const orgCode = window.location.hash.substr(6, 5);
      const path = GlobalSettings.UNIFIED_LOGIN + '/' + orgCode;
      window.location.href = path;
    } else {
      const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_LOGIN;
      this.router.navigate([path]);
    }
    setTimeout(() => {
      // localStorage.clear();
    }, 100);

  }

  repasswordEntered(control) {

    if (this.passwordEnt === this.repasswordEnt) {
      this.match = true;
    } else {
      this.match = false;
    }
    console.log(this.match);
  }

  checkValidation() {

    if ((this.form.controls['username'].value && (this.form.controls['username'].value).trim().length > 0) &&
      (this.form.controls['username_last'].value && (this.form.controls['username_last'].value).trim().length > 0)) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

}
