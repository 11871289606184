import {
  Component,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  AfterContentInit,
  AfterContentChecked,
  OnDestroy
} from '@angular/core';
import {
  Observable
} from 'rxjs';
import {
  HttpClient
} from '@angular/common/http';
import {
  SharedService
} from '../../shared.service';
import {
  NavigationEnd,
  Router,
  ActivatedRoute,
  Params,
  NavigationStart
} from '@angular/router';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  environment
} from '../../../environments/environment';
import Utils from 'src/app/utils';
import { LoginService } from 'src/app/login/login.service';
import { CommonService } from 'src/app/common.service';
import { GlobalSettings } from 'src/app/global.settings';
@Component({
  selector: 'app-front-facing-nav-bar',
  templateUrl: './front-facing-nav-bar.component.html',
  styleUrls: ['./front-facing-nav-bar.component.scss', './../../nav-bar/nav-bar-skip-main.scss']
})
export class FrontFacingNavBarComponent implements OnInit, AfterViewChecked, OnDestroy {

  isPublic = false;
  title = ''; // holds the title to be displayed based on route path
  selectedTopMenu = ''; // holds the selected tab based on route path
  currentURL = ''; // holds the current url
  taxonomyTitleEvent: Subscription;
  showPublishedTaxonomy = true; // holds boolean to show if published taxonomy needs to be viewed based on environment selected
  navBarItems = [];
  orgCode = '';
  isSSO = GlobalSettings.SSO_USER;
  tenantListPR = [];
  hideLoginBtn = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private sharedService: SharedService,
    private loginService: LoginService,
    private service: CommonService
  ) {
    this.getNavJson();
    this.router.events.subscribe((val: any) => {
      // if (val.url) {
      //   this.getNavJson();
      // }
    });
    if (localStorage.getItem('orgDetails')) {
      this.orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
    }

    // this.sharedService.orgDetails.subscribe((event: any) => {
    //   if (event.orgCode !== undefined) {
    //     this.orgCode = event.orgCode;
    //   }
    // });
  }

  getNavJson() {
    // this.navBarItems = [];
    this.getJSON().subscribe(data => {
      const orgCode = window.location.hash.substr(6, 5);
      if (this.navBarItems && this.navBarItems.length === 0) {
        this.navBarItems = data;
      }
      this.navBarItems.forEach(navItem => {
        if (!this.showPublishedTaxonomy && navItem.name === 'Taxonomies') {
          // this.navBarItems.splice(this.navBarItems.indexOf(navItem), 1);
          navItem.show = false;
        }
        if (navItem.name === 'Public Review' && !environment.prTenants.find(org => org === orgCode)) {
          // this.navBarItems.splice(this.navBarItems.indexOf(navItem), 1);
          navItem.show = false;
        }
      });
      this.navBarItems.forEach(navItem => {
        if (this.router.url.includes(navItem.path)) {
          navItem.active = true;
        } else {
          navItem.active = false;
        }
      });
    });
  }


  ngOnInit() {
    // for the taxonomy details heading
    console.log('#### front face nav bar initialised');
    for (const i in environment.tenant) {
      if (i && this.router.url.split('/')[2] === environment.tenant[i]) {
        this.showPublishedTaxonomy = false;
        break;
      } else {
        this.showPublishedTaxonomy = environment.showPublishedTaxonomy;
        // tslint:disable-next-line: max-line-length
        const { renamePacingGuide, showPublishedTaxonomy } = Utils.checkChangesForSpecificOrg(this.showPublishedTaxonomy, this.router.url.split('/')[2]);
        this.showPublishedTaxonomy = showPublishedTaxonomy;
      }
    }
    const cookieObj = document.cookie;

    if (cookieObj.indexOf('SSOUser') !== -1 && cookieObj.indexOf('central_auth_token') !== -1) {
      this.hideLoginBtn = true;
    } else {
      this.hideLoginBtn = false;
      localStorage.removeItem('access_token');
    }

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {

      if (localStorage.getItem('orgDetails')) {
        this.orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
      }
      this.currentURL = event.url;
      const url = event.url;
      switch (url) {
        case '/org/' + this.orgCode:
          this.title = '';
          this.isPublic = true;
          this.selectedTopMenu = this.title;
          break;
        case '/org/' + this.orgCode + '/home':
          this.title = 'Home';
          this.isPublic = true;
          this.selectedTopMenu = this.title;
          break;
        case '/org/' + this.orgCode + '/taxonomies':
          this.title = 'Taxonomies';
          this.isPublic = true;
          this.selectedTopMenu = this.title;
          break;
        case '/org/' + this.orgCode + '/publicreview':
          this.title = 'Public Review';
          this.isPublic = true;
          this.selectedTopMenu = this.title;
          break;
        case '/org/' + this.orgCode + '/pacingguides':
          this.title = 'Pacing Guide';
          this.isPublic = true;
          this.selectedTopMenu = this.title;
          break;
        case '/org/' + this.orgCode + '/documents':
          this.title = 'Documents';
          this.isPublic = true;
          this.selectedTopMenu = this.title;
          break;
        default:
          console.log('default');
          this.isPublic = false;
          break;
      }
      document.title = 'ACMT : ' + this.title;
      if (this.currentURL.indexOf('/taxonomies/detail') >= 0) {
        this.taxonomyTitleEvent = this.sharedService.setTitleEvent.subscribe((titleEvent: any) => {
          if (titleEvent && titleEvent.title) {
            switch (titleEvent.type) {
              case 'taxonomy':
                this.title = titleEvent.title;
                break;
              default:
                break;
            }
          }
        });
      }
    });

    // this.router.navigate(['/home']);
    if (sessionStorage.getItem('frontface_url')) {
      setTimeout(() => {
        const pathArr = sessionStorage.getItem('frontface_url').split('/');
        const path = pathArr[3];
        if (path === 'taxonomies') {
          if (document.getElementById('taxonomies-button')) {
            document.getElementById('taxonomies-button').click();
          }
        } else if (path === 'pacingguides') {
          if (document.getElementById('pacingguides-button')) {
            console.log('$$$$ id to click', path);
            document.getElementById('pacingguides-button').click();
          }
        } else if (path === 'documents') {
          if (document.getElementById('documents-button')) {
            document.getElementById('documents-button').click();
          }
        } else {
          if (document.getElementById('Home-button')) {
            document.getElementById('Home-button').click();
          }
        }
      }, 150);
    } else {
      setTimeout(() => {
        if (document.getElementById('Home-button')) {
          // document.getElementById('Home-button').click();
        }
      }, 1000);
    }
    // sessionStorage.setItem('frontface_url', this.router.url);
    if (this.router.url !== '/') {
      sessionStorage.setItem('frontface_url', this.router.url);
    }
  }

  ngAfterViewChecked() {
    this.sharedService.activeLink.next({
      activeLink: true
    });

  }

  public getJSON(): Observable<any> {
    return this.http.get('./assets/json/front-facing-nav.json');
  }

  navigate(item) {
    if (localStorage.getItem('orgDetails')) {
      this.orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
    }

    if (item.path === 'login') {

      if (this.isSSO) {
        const cookieObj = document.cookie;

        if (cookieObj.indexOf('SSOUser') !== -1 && cookieObj.indexOf('central_auth_token') !== -1) {
          const SSOUser = JSON.parse(Utils.getCookie('SSOUser'));
          this.loginService.regenerateToken(SSOUser.refresh_token, SSOUser.platform_access, SSOUser.TenantShortCode).then((res: any) => {
            const SSOUserCookie = JSON.parse(Utils.getCookie('SSOUser'));
            // const token = Utils.getCookie('central_auth_token');
            this.validateLogin(SSOUserCookie.Email, SSOUser.TenantId);
          });
        } else {
          const path = GlobalSettings.UNIFIED_LOGIN + '/' + this.orgCode;
          window.location.href = path;
        }

      } else {
        const nav = [];
        const path = 'org/' + this.orgCode + '/login';
        nav.push('/' + path);
        this.router.navigate(nav);
        // sessionStorage.setItem('frontface_url', this.router.url);
        if (this.router.url !== '/') {
          sessionStorage.setItem('frontface_url', this.router.url);
        }
      }

    } else {
      // this.getNavJson();
      if (item.path !== 'login') {
        const nav = [];
        const path = 'org/' + this.orgCode + '/' + item.path;
        setTimeout(() => {
          if (this.navBarItems && this.navBarItems.length > 0) {
            this.navBarItems.forEach(navItem => {
              if (navItem.path === item.path) {
                navItem.active = true;
              } else {
                navItem.active = false;
              }
            });
          }
        }, 160);
        nav.push('/' + path);
        this.router.navigate(nav);
        // sessionStorage.setItem('frontface_url', this.router.url);
        if (this.router.url !== '/') {
          sessionStorage.setItem('frontface_url', this.router.url);
        }
      }
    }
  }

  onHelpClick() {

  }

  ngOnDestroy() {
    if (this.taxonomyTitleEvent) {
      this.taxonomyTitleEvent.unsubscribe();
    }
  }

  skipToMain() {
    if (document.getElementById('mainContent')) {
      document.getElementById('mainContent').focus();
    }
  }

  validateLogin(userName, orgId) {

    this.loginService.validateUserToken(userName, orgId).then((respones: any) => {
      this.service.setOrgDetails(respones.organization);
      if (sessionStorage.getItem('current_url') && (sessionStorage.getItem('current_url').indexOf('/login') !== -1
        || sessionStorage.getItem('current_url').indexOf('/home') !== -1)) {
        sessionStorage.clear();
      }
      if (sessionStorage.getItem('current_url') && sessionStorage.getItem('current_url').split(',')[0].length > 1) {
        if (sessionStorage.getItem('current_url').split(',')[0] === '/app/login') {
          if (respones && respones.system_role &&
            respones.system_role.role_code && respones.system_role.role_code === 'PPR00') {
            const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PUBLIC_REVIEW_LIST;
            this.router.navigate([path]);
          } else {
            const permissions = respones.system_role_permissions; // holds entire permission list
            const role = respones.system_role_permissions.role_user_permissions; // holds role permissions
            const permissionArr = []; // holds available permissions for the user

            let path: any; // holds url to redirect after successful login

            // storing valued permission names into the array
            for (const key in permissions) {
              if (permissions[key].length) {
                permissionArr.push(key);
              }
            }

            if (permissionArr.length === 0) {
              // when user has no permission
              this.sharedService.sucessEvent.next({
                type: 'no_permission'
              });
              path = this.service.getOrgDetails() + '/' + Utils.ROUTE_HOME;
            } else {
              // when user has permissions
              path = this.service.getOrgDetails() + '/' + this.findPath(role, permissionArr);
            }

            this.router.navigate([path]);
          }
        } else {
          this.router.navigate([sessionStorage.getItem('current_url').split(',')[0]]);
        }
      } else {

        if (respones && respones.system_role &&
          respones.system_role.role_code && respones.system_role.role_code === 'PPR00') {
          const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PUBLIC_REVIEW_LIST;
          this.router.navigate([path]);
        } else {
          const permissions = respones.system_role_permissions; // holds entire permission list
          const role = respones.system_role_permissions.role_user_permissions; // holds role permissions
          const permissionArr = []; // holds available permissions for the user

          let path: any; // holds url to redirect after successful login

          // storing valued permission names into the array
          for (const key in permissions) {
            if (permissions[key].length) {
              permissionArr.push(key);
            }
          }

          if (permissionArr.length === 0) {
            // when user has no permission
            this.sharedService.sucessEvent.next({
              type: 'no_permission'
            });
            path = this.service.getOrgDetails() + '/' + Utils.ROUTE_HOME;
          } else {
            // when user has permissions
            path = this.service.getOrgDetails() + '/' + this.findPath(role, permissionArr);
          }

          this.router.navigate([path]);
        }
      }
    }).catch(ex => {
      console.log('signin ex ', ex);
      this.sharedService.sucessEvent.next({
        customMsg: ex.error.message,
        type: 'login_error'
      });
      // tslint:disable-next-line: max-line-length
      // const path = 'https://' + GlobalSettings.DOMAIN + '-' + (GlobalSettings.ENVIRONMENT ? GlobalSettings.ENVIRONMENT : 'dev') + '.learningmate.co /# / login';
      const path = GlobalSettings.UNIFIED_LOGIN + '/' + this.orgCode;
      window.location.href = path;
    });
  }

  hasPermission(array, name) {
    for (const index of array) {
      const element = index;
      if (element.display_name === name) {
        return true;
      }
    }
    return false;
  }

  findPath(role, permissionArr) {
    let path;
    if (role.length > 0 && (this.hasPermission(role, 'View Home Screen') ||
      this.hasPermission(role, 'View Additional Statistics'))) {
      path = Utils.ROUTE_HOME;
    } else {
      if (permissionArr.find((item: any) => item.includes('taxonomy_permissions'))) {
        path = Utils.ROUTE_TAXONOMY_LIST;
      } else {
        if (permissionArr.find((item: any) => item.includes('project_permissions'))) {
          path = Utils.ROUTE_PROJECT_LIST;
        } else {
          if (permissionArr.find((item: any) => item.includes('pacing_guide_permissions'))) {
            path = Utils.ROUTE_PACING_GUIDE_LIST;
          } else {
            if (permissionArr.find((item: any) => item.includes('public_review_permissions'))) {
              path = Utils.ROUTE_PUBLIC_REVIEW_LIST;
            } else {
              if (role.length) {
                path = Utils.ROUTE_ADMIN_TEAM;
              } else {
                if (permissionArr.find((item: any) => item.includes('workflow_permissions'))) {
                  path = Utils.ROUTE_ADMIN_WORKFLOW_LIST;
                } else {
                  if (permissionArr.find((item: any) => item.includes('metadata_permissions'))) {
                    path = Utils.ROUTE_ADMIN_METADATA_LIST;
                  } else {
                    if (permissionArr.find((item: any) => item.includes('node_template_permissions'))) {
                      path = Utils.ROUTE_ADMIN_NODETEMPLATE_LIST;
                    } else {
                      if (permissionArr.find((item: any) => item.includes('note_permissions'))) {
                        path = Utils.ROUTE_ADMIN_CASESERVER_LIST;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
    return path;

  }
}
