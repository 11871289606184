import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html'
})
export class TextEditorComponent implements OnInit {

  editorConfig = {
    'editable': true,
    'spellcheck': true,
    'height': '250px',
    'minHeight': '0',
    'width': 'auto',
    'minWidth': '0',
    'translate': 'yes',
    'enableToolbar': true,
    'showToolbar': true,
    'placeholder': 'Enter text here...',
    'imageEndPoint': '',
    'toolbar': [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['horizontalLine', 'orderedList', 'unorderedList'],
      ['fontName'], ['removeFormat'], ['link']
    ]
  };

  @Input() fieldValue; // holds the value of the field
  @Input() metadata; // holds the metadata info for which the rte is clicked
  @Input() showCancel = true; // holds boolean whether to show cancel button or not
  @Input() showUpdateBtn = true; // holds boolean whether to show update button or not
  @Input() updateBtnText = 'Done';
  formControlName; // holds the formControlName
  @Output() updateField: EventEmitter<any>; // emits updated object from rte to node details
  @Output() updateFieldOnChange: EventEmitter<any>; // emits updated object from rte on change text
  @Output() updateShowEditor: EventEmitter<any>; // emits boolean for showing editor based on controls clicked

  constructor() {
    this.updateField = new EventEmitter<any>();
    this.updateFieldOnChange = new EventEmitter<any>();
    this.updateShowEditor = new EventEmitter<any>();
  }

  ngOnInit() { }


  /* --------- Functionality to set data for rte from rteObject if exists or form object if not start --------- */

  setData(nodeTypeItem, formObj, formControlName, rteObject) {
    this.fieldValue = '';
    if (rteObject) {
      for (const i in rteObject) {
        if (rteObject[i]['metadata_id'] === nodeTypeItem['metadata_id']) {
          this.fieldValue = rteObject[i]['htmlValue'];
          break;
        }
      }
    }
    this.metadata = nodeTypeItem;
    if (this.fieldValue.length === 0) {
      this.fieldValue = formObj.value;
    }
    this.formControlName = formControlName;
  }

  /* --------- Functionality to set data for rte from rteObject if exists or form object if not end --------- */


  /* --------- Functionality to emit the updated object from rte to node details start --------- */

  updateData() {
    if (navigator.userAgent.match(/Edge/)) {
      const elements = document.getElementsByClassName('ngx-editor-textarea');
      for (const key in elements) {
        if (elements.hasOwnProperty(key)) {
          this.fieldValue = elements[key].innerHTML;
        }
      }
    } else {
      if (document.getElementsByClassName('ngx-editor-textarea') &&
        document.getElementsByClassName('ngx-editor-textarea')[0] &&
        document.getElementsByClassName('ngx-editor-textarea')[0].innerHTML) {
        this.fieldValue = document.getElementsByClassName('ngx-editor-textarea')[0].innerHTML;
      }
    }
    const Obj = {
      'metadata_id': this.metadata ? this.metadata.metadata_id : null,
      'is_custom': this.metadata ? this.metadata.is_custom : null,
      'formControlName': this.formControlName,
      'htmlValue': this.extractContent(this.fieldValue).length > 0 ? this.fieldValue : '',
      'value': this.extractContent(this.fieldValue),
    };
    return Obj;
  }

  // Update data event emitting on update button click
  updateDataOnClick() {
    const obj = this.updateData();
    this.updateField.emit(obj);
    this.onCancle();
  }

  // Update data event emitting on text change
  updateDataOnChange() {
    const obj = this.updateData();
    this.updateFieldOnChange.emit(obj);
  }

  /* --------- Functionality to emit the updated object from rte to node details end --------- */


  /* --------- Functionality to string from html content from rte start --------- */

  extractContent(html) {
    return (new DOMParser).parseFromString(html, 'text/html').documentElement.textContent;

  }

  /* --------- Functionality to string from html content from rte end --------- */

  onCancle() {
    this.updateShowEditor.emit(false);
  }

}
