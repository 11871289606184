import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-association-modal',
  templateUrl: './association-modal.component.html'
})
export class AssociationModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  closeAssociationModal(event) {

  }

}
