import {
    Component,
    OnInit,
    Injectable,
    ViewChild,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    AfterViewInit
} from '@angular/core';
import {
    TreeviewI18n,
    TreeviewItem,
    TreeviewConfig,
    TreeviewHelper,
    TreeviewComponent,
    TreeviewEventParser,
    OrderDownlineTreeviewEventParser,
    DownlineTreeviewItem
} from 'ngx-treeview';
import * as _ from 'lodash';
import Utils from '../utils';
import { SharedService } from '../shared.service';
@Injectable()
export class ProductTreeviewConfig extends TreeviewConfig {
    hasAllCheckBox = false;
    hasFilter = true;
    hasCollapseExpand = false;
    maxHeight = 400;
}
@Component({
    selector: 'app-tree-view',
    templateUrl: './tree-view.component.html',
    styleUrls: ['./tree-view.component.scss'],
    providers: [{
        provide: TreeviewEventParser,
        useClass: OrderDownlineTreeviewEventParser
    },
    {
        provide: TreeviewConfig,
        useClass: ProductTreeviewConfig
    }
    ]
})
export class TreeViewComponent implements OnChanges, OnInit, AfterViewInit {


    title = 'app';
    @Input() treedata;
    @Input() decoupleChild = false;
    @Input() originNodeId = null; // holds node id of source taxonomy
    @Input() documentId = null; // holds document id passed to tree view component for disabling document node
    items = [];
    data = '';
    config = {
        hasAllCheckBox: false,
        hasFilter: true,
        hasCollapseExpand: false,
        decoupleChildFromParent: this.decoupleChild
        // maxHeight: 3000
    };
    itCategory;
    @Output() selectedNodeIds: EventEmitter<any>;
    @Output() currentNode: EventEmitter<any>;
    @Output() selectedNodesDetail: EventEmitter<any>;
    @Output() hybridSelectedNodes: EventEmitter<any>;
    @ViewChild(TreeviewComponent, { static: false }) treeviewComponent: TreeviewComponent;
    @Input() viewLocation = '';
    @Input() selectedDocumentId = null;
    nodes: string[];
    partialNodes = [];
    nodesDetail: any[] = [];
    partialNodesDetail: any[] = [];
    clicked = null; // holds current clicked item, used for adding active class
    disableDocument = false;
    constructor(private sharedService: SharedService) {
        this.selectedNodeIds = new EventEmitter<any>();
        this.currentNode = new EventEmitter<any>();
        this.selectedNodesDetail = new EventEmitter<any>();
        this.hybridSelectedNodes = new EventEmitter<any>();
    }
    // onSelectedChange(event) {
    //   this.data = event;
    //   console.log('onSelectedChange ', event);
    // }
    expandCollapse = true;
    ngOnChanges() {
        this.items = [];

        if (this.treedata) {
            this.itCategory = new TreeviewItem(this.treedata);
            this.items.push(this.itCategory);
        }
        // const itCategory = new TreeviewItem(this.treedata);
        // this.items.push(itCategory);
    }
    onFilterChange(event) {

        console.log('onFilterChange ', event);
    }

    ngOnInit() {
        this.config.decoupleChildFromParent = this.decoupleChild;
        if (this.viewLocation === 'create-project') {
            this.config.hasCollapseExpand = true;
        }
    }
    onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
        // console.log('downlineItems', downlineItems);
        this.nodes = [];
        this.partialNodes = [];
        this.nodesDetail = [];
        this.partialNodesDetail = [];
        const selectedNodes = {};
        const partiallySelectedNodes = {};
        let i = 0;
        downlineItems.forEach(downlineItem => {
            const item = downlineItem.item;
            const value = item.value;
            const texts = [item.text];
            let parent = downlineItem.parent;
            this.getParent(downlineItem, i, selectedNodes, partiallySelectedNodes);
            i++;
            while (!_.isNil(parent)) {
                texts.push(parent.item.text);
                parent = parent.parent;
            }
            const reverseTexts = _.reverse(texts);
            // this.rows.push(value);
        });

        // console.log('selectedNodes ', selectedNodes);
        // console.log('partiallySelectedNodes ', partiallySelectedNodes);
        for (const key in selectedNodes) {
            // Restricting dummy node sending
            if (key && key !== Utils.DUMMY_ID_FOR_ORPHANLABEL) {
                this.nodes.push(key);
                this.nodesDetail.push({ 'id': key, 'name': selectedNodes[key].trim() });
            }
        }
        for (const key in partiallySelectedNodes) {
            // Restricting dummy node sending
            if (key && key !== Utils.DUMMY_ID_FOR_ORPHANLABEL) {
                this.partialNodes.push(key);
                this.partialNodesDetail.push({ 'id': key, 'name': partiallySelectedNodes[key].trim() });
            }
        }
        //  console.log('this.rows -------- ', this.nodes);
        this.selectedNodeIds.emit({
            'nodes': this.nodes,
            'partialNodes': this.partialNodes
        });
        this.selectedNodesDetail.emit({
            'nodes': this.nodesDetail,
            'partialNodes': this.partialNodesDetail
        });
    }

    getParent(node, i, selectedNodes, partiallySelectedNodes) {
        if (node['item']['internalChecked']) {
            selectedNodes[node.item.value] = node.item.text;
            // console.log( i,' -- ', node.item.text, '   ', node.item.value);
            if (node.parent) {
                // console.log('getParent ', node.parent);
                const parentNode = node.parent;
                if (parentNode['item']['internalChecked']) {
                    selectedNodes[parentNode.item.value] = parentNode.item.text;
                } else {
                    partiallySelectedNodes[parentNode.item.value] = parentNode.item.text;
                }
                // console.log(i, ' -- ', parentNode.item.text, '   ', parentNode.item.value, parentNode['item']['internalChecked']);
                this.getParent(parentNode, i, selectedNodes, partiallySelectedNodes);
            }
        }
    }

    removeItem(currentNode: TreeviewItem) {
        currentNode.checked = !currentNode.checked;
        this.onSelectedChange([{
            item: currentNode,
            parent: null
        }]);
        this.currentNode.emit({
            node: currentNode
        });
        // let isRemoved = false;
        // for (const tmpItem of this.items) {
        //   if (tmpItem === item) {
        //     _.remove(this.items, item);
        //   } else {
        //     isRemoved = TreeviewHelper.removeItem(tmpItem, item);
        //     if (isRemoved) {
        //       break;
        //     }
        //   }
        // }

        // if (isRemoved) {
        //   this.treeviewComponent.raiseSelectedChange();
        // }
    }

    levelExpand(evt) {
        this.sharedService.windowResizeEvent.next({
            event: evt
        });
    }


    /* --------- Functionality to update current node status and call update / remove accordingly start --------- */

    getCurrentItem(currentNode: TreeviewItem) {
        // console.log('currentNode', currentNode);
        currentNode.checked = !currentNode.checked;
        if (this.viewLocation === 'create-project') {
            if (currentNode.checked) {
                this.updateNodes(currentNode.value);
            } else {
                this.removeCurrentNode(currentNode.value, currentNode);
            }
        }
    }

    /* --------- Functionality to update current node status and call update / remove accordingly end --------- */


    /* --------- Functionality to select all child nodes and update status and call update method start --------- */

    selectAllChildren(currentNode) {
        if (currentNode.children && currentNode.children.length > 0) {
            this.iterateChildren(currentNode.children);
            const [nodeId, parentId] = currentNode.value.split('::');
            const index = this.nodes.indexOf(nodeId, 0);
            if (index > -1) {
                // this.removeParentNode(nodeId);
            }
        }
    }

    iterateChildren(children) {
        children.forEach(element => {
            if (!element.internalDisabled) {
                if (!element.checked) {
                    element.checked = true;
                    this.updateNodes(element.value);
                }
            }
            if (element.children && element.children.length > 0) {
                this.iterateChildren(element.children);
            }
        });
    }

    /* --------- Functionality to select all child nodes and update status and call update method end --------- */


    /* --------- Functionality to push selected node in updated nodes object start --------- */

    updateNodes(data) {
        const [nodeId, parentId] = data.split('::');
        // if (this.documentId !== nodeId) {
        // }
        const index = this.nodes.indexOf(nodeId, 0);
        if (index === -1) {
            this.nodes.push(nodeId);
            this.updatePartialNodes(parentId);
        } else {
            return;
        }
    }

    /* --------- Functionality to push selected node in updated nodes object end --------- */


    /* --------- Functionality to push selected node's parent id in partial nodes object start --------- */

    updatePartialNodes(parentId) {
        if (!parentId || parentId === 'undefined') {
            parentId = this.documentId;
        }
        this.partialNodes.push(parentId);
        // this.filterParentNodes();
        this.hybridSelectedNodes.emit({
            nodes: this.nodes,
            partialNodes: this.partialNodes
        });
    }

    /* --------- Functionality to push selected node's parent id in partial nodes object end --------- */


    /* --------- Functionality to remove current node id and corresponding parent id on uncheck start --------- */

    removeCurrentNode(data, currentNode) {
        const [nodeId, parentId] = data.split('::');
        // this.nodes.push(nodeId);
        const index = this.findNodeIndex(nodeId);
        this.nodes = this.nodes.filter(obj => obj !== nodeId);

        delete this.partialNodes[index];
        this.partialNodes = this.partialNodes.filter((el) => el);

        this.hybridSelectedNodes.emit({
            nodes: this.nodes,
            partialNodes: this.partialNodes
        });

    }

    /* --------- Functionality to remove current node id and corresponding parent id on uncheck end --------- */


    /* --------- Functionality to grab current node's index start --------- */

    findNodeIndex(nodeId) {
        return this.nodes.findIndex(item => item === nodeId);
    }

    /* --------- Functionality to grab current node's index end --------- */


    removeParentNode(parentId) {
        delete this.partialNodes[this.partialNodes.findIndex(item => item === parentId)];
        this.partialNodes = this.partialNodes.filter((el) => el);
    }

    /* --------- Functionality to remove nodes for partial nodes if same present in selected node start --------- */

    filterParentNodes() {
        this.nodes.forEach(nodeId => {
            this.partialNodes = this.partialNodes.filter(parent => nodeId !== parent);
        });
    }

    /* --------- Functionality to remove nodes for partial nodes if same present in selected node end --------- */

    checkIfChildrenSelected(nodeId, currentNode) {
        let childSelected = false;
        for (const i in currentNode) {
            if (currentNode.i.internalDisabled === true || currentNode.i.checked === true) {
                childSelected = true;
                break;
            }
        }
        return childSelected;
    }


    /* --------- Functionality to refine and emit final object start --------- */

    emitSelectedNodes() {
        this.filterParentNodes();
        this.makePartialNodesUnique();
        this.hybridSelectedNodes.emit({
            nodes: this.nodes,
            partialNodes: this.partialNodes
        });
    }

    /* --------- Functionality to refine and emit final object end --------- */


    /* --------- Functionality to make partial nodes unique start --------- */

    makePartialNodesUnique() {
        this.partialNodes = this.partialNodes.filter((item, i, ar) => ar.indexOf(item) === i);
    }

    /* --------- Functionality to make partial nodes unique end --------- */


    /* --------- Functionality to update expand collapse button via javascript start --------- */

    ngAfterViewInit() {
        if (document.getElementsByClassName('pull-right form-check-label') && this.viewLocation === 'create-project') {
            const element = document.getElementsByClassName('pull-right form-check-label')[0];
            if (element) {
                // tslint:disable-next-line:max-line-length
                element.innerHTML = '<em class="fa fa-compress" aria-hidden="true" id="expandCollapse" title="Collapse"></em> <span id="expandCollapseText" class="cursor-pointer"> Collapse </span>';
            }
            document.getElementById('expandCollapse').addEventListener('click', () => {
                this.updateExpandCollapseButton();
            });

            document.getElementById('expandCollapseText').addEventListener('click', () => {
                this.updateExpandCollapseButton();
            });

        }
    }

    updateExpandCollapseButton() {
        this.expandCollapse = !this.expandCollapse;
        let button;
        let buttonText;
        if (document.getElementById('expandCollapse')) {
            button = document.getElementById('expandCollapse');
        }
        if (document.getElementById('expandCollapseText')) {
            buttonText = document.getElementById('expandCollapseText');
        }
        if (!this.expandCollapse) {
            buttonText.innerText = ' Expand';
            button.setAttribute('title', 'Expand');
            button.setAttribute('class', 'fa fa-expand');
        } else {
            buttonText.innerText = ' Collapse';
            button.setAttribute('title', 'Collapse');
            button.setAttribute('class', 'fa fa-compress');
        }
    }

    /* --------- Functionality to update expand collapse button via javascript end --------- */


    /* --------- Functionality to update emittion object at start on open of modal while update start --------- */

    updatedExistingIds() {
        const nodeIds = [];
        const partialNodeIds = [];
        this.nodes.forEach(obj => {
            const id = obj.split('::')[0];
            if (id !== 'orphan_label_node_id' && id !== 'undefined') {
                nodeIds.push(id);
            }
        });
        this.partialNodes.forEach(obj => {
            const id = obj.split('::')[0];
            if (id !== 'orphan_label_node_id' && id !== 'undefined') {
                partialNodeIds.push(id);
            }
        });
        this.nodes = nodeIds;
        this.partialNodes = partialNodeIds;
    }

    /* --------- Functionality to update emittion object at start on open of modal while update end --------- */

    // deselectDocument(selectedAssociationType) {
    //     this.disableDocument = false;
    //     console.log('source node document', this.originNodeId, this.nodes, this.partialNodes);
    //     if (selectedAssociationType && selectedAssociationType.type_name === 'isChildOf') {
    //         if (this.documentId === this.selectedDocumentId) {
    //             if ((document.getElementById('currentNode-' + this.originNodeId) as any).checked) {
    //                 document.getElementById('currentNode-' + this.originNodeId).click();
    //             }
    //             (document.getElementById('currentNode-' + this.originNodeId) as any).disabled = true;
    //         }

    //         if (this.originNodeId === this.documentId) {
    //             this.disableDocument = true;
    //         }
    //     } else {
    //         this.disableDocument = false;
    //         if (this.documentId === this.selectedDocumentId) {
    //             (document.getElementById('currentNode-' + this.originNodeId) as any).disabled = false;
    //         }
    //         // if (document.getElementById('currentNode-' + this.documentId)) {
    //         //     if (this.items[0].checked) {
    //         //         document.getElementById('currentNode-' + this.documentId).click();
    //         //     }
    //         //     this.disableDocument = true;
    //         // }
    //     }
    // }

    deselectDocument(selectedAssociationType) {
        if (selectedAssociationType && selectedAssociationType.type_name === 'isChildOf') {
            if (document.getElementById('currentNode-' + this.documentId)) {
                // if (!this.items[0].checked) {
                //     document.getElementById('currentNode-' + this.documentId).click();
                // }
                this.disableDocument = false;
            }
        } else {
            if (document.getElementById('currentNode-' + this.documentId)) {
                if (this.items[0].checked) {
                    document.getElementById('currentNode-' + this.documentId).click();
                }
                this.disableDocument = true;
            }
        }
    }


}
