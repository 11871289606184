import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CommonService } from '../common.service';
import {
  GlobalSettings
} from '../global.settings';
@Component({
  selector: 'app-user-friendly-page',
  templateUrl: './user-friendly-page.component.html',
  styleUrls: ['./user-friendly-page.component.scss']
})
export class UserFriendlyPageComponent implements OnInit {
  nodeId = '';
  isDocument = 0;
  nodeDetails: any;
  metadataList = [
    {
      displayName: 'Node Type',
      propName: 'CFItemType'
    },
    {
      displayName: 'Human Coding Scheme',
      propName: 'humanCodingScheme'
    },
    {
      displayName: 'Full Statement',
      propName: 'fullStatement'
    },
    {
      displayName: 'List Enumeration',
      propName: 'listEnumeration'
    },
    {
      displayName: 'Language Name',
      propName: 'language'
    }
  ];
  constructor(private acivatedRoute: ActivatedRoute, private service: CommonService) {
    this.acivatedRoute.queryParams.subscribe(params => {
      // console.log('params', this.acivatedRoute);
      if (params['id'] !== undefined) {
        this.nodeId = params['id'];
      }
      if (params['isDocument'] !== undefined) {
        this.isDocument = JSON.parse(params['isDocument']);
      }
    });
  }

  ngOnInit() {
    this.getNodeData();
  }

  getOrgDetails() {
    if (localStorage.getItem('orgDetails')) {
      const orgCode = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
      return orgCode;
    }
  }

  getNodeData() {
    let url = GlobalSettings.VERSION + this.getOrgDetails();
    if (this.isDocument === 1) {
      url = url + GlobalSettings.CASE_API_CFDOC_URL;
    } else {
      url = url + GlobalSettings.CASE_API_CFITEM_URL;
    }
    url = url + '/' + this.nodeId;
    this.service.getUserServiceDataWithoutTocken(url, true).then((res: any) => {
      console.log('stand alone data received', res);
      this.nodeDetails = res;
    });

  }

}
