const incomMsgEL = '#IEIncomMsg';
const rightContainerId = '#right_container_id';
const taxonomyProtip = '#taxonomyProtip';
const tabContainer = '#tabContainer';
const containerFluid = '.container-fluid';
const addAdditionalMetadata = '#addAdditionalMetadata';
const authoringHeader = '.authoring-header';
const filePanel = '.file-panel';
const searchFieldInput = '.search-field input';
const treeDetailsView = '#treeDetailsView';
//activating the tooltip globally
function init_globalTooltip() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });
}

//making the dropdown menu appear on hover
function init_dropdownHover() {
  $('ul.more-options li.dropdown__more').hover(function () {
    $(this).find('.dropdown-menu').addClass('show');
  }, function () {
    $(this).find('.dropdown-menu').removeClass('show');
  });
};

//file upload
function readUrl(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var imgData = e.target.result;
      var imgName = input.files[0].name;
      input.setAttribute("data-title", imgName);
      console.log(e.target.result);
    }
    reader.readAsDataURL(input.files[0]);
  }
};

function init_accordionTree() {
  $('.collapse').on('show.bs.collapse', function () {
    $(this).prev().addClass('active');
  });

  $('.collapse').on('hidden.bs.collapse', function () {
    $(this).prev().removeClass('active');
  });
};

function init_numberCounter() {
  $('.total').each(function () {
    $(this).prop('Total', 0).animate({
      Total: $(this).text()
    }, {
      duration: 4000,
      easing: 'swing',
      step: function (now) {
        $(this).text(Math.ceil(now));
      }
    });
  });
}
// Detail view height calculation
function detailView() {
  var windowHeight = $(window).height();
  var headerHeight = $(authoringHeader).outerHeight(true);
  var protipHeight = $('.info').outerHeight(true);
  var rolePanelHeight = $('.role-panel').outerHeight(true);
  var projectFilterHeight = $('.project--filter').outerHeight(true);
  var detailViewHeight = windowHeight - (headerHeight + protipHeight + rolePanelHeight + projectFilterHeight + 46);
  $('.treeDetailsView, .preview-panel').css({
    'height': detailViewHeight
  });
}

function init_detailsViewClick() {
  // if($('#detailviewView')){
  //   $('#detailviewView').on('click', function () {
  //     $("html, body").animate({
  //       scrollTop: 0
  //     }, {
  //       duration: 500,
  //       complete: function () {
  //         // detailView();
  //         $(treeDetailsView).addClass('treeDetailsView');
  //         $('body').addClass('hide-vScroll');
  //         $(treeDetailsView).addClass('tree--details-left--nodes');
  //         $('#taxonomyListingHeading').insertBefore('#accordion');
  //       }
  //     });
  //   });
  // }
}

function init_treeViewClick() {
  $('#btnTreeView, #btnTabularView, .nav-item').on('click', function () {
    $(treeDetailsView).removeClass('treeDetailsView');
    $('body').removeClass('hide-vScroll');
    $(treeDetailsView).removeClass('tree--details-left--nodes');
    $('#taxonomyListingHeading').insertBefore('#treeViewContainer');
  });
}

// Tab animation
function init_tabAnimation() {
  $(".list-inline li").click(function () {
    var position = $(this).position();
    var width = $(this).innerWidth();
    $(".slider").css({
      "left": +position.left,
      "width": width
    });
  });
  var actWidth = $(".list-inline").find(".tab_selected").innerWidth();
  var actPosition = $(".list-inline .tab_selected").position();
  $(".slider").css({
    "left": +actPosition.left,
    "width": actWidth
  });
}
// Search
function init_searchAnimation() {
  $(".search-icon").off("click").on("click", function (e) {
    e.stopPropagation();
    e.preventDefault();
    $(".search-field").toggleClass('show-search');
    // $(searchFieldInput).val('');
    if ($(searchFieldInput).attr('tabindex') == -1) {
      $(".search-field input").attr("tabindex", "0");
      $(".search-field input").focus();
    } else {
      $(".search-field input").attr("tabindex", "-1");
    }
  });

  $(document.body).click(function () {
    $('.search-field').removeClass('show-search');
    // $(searchFieldInput).val('');
    $(".search-field input").attr("tabindex", "-1");
  });

  $('.search-field').click(function (e) {
    e.stopPropagation();
  });
}
// Side bar interaction
function init_sidebar() {
  $('.sidebar-handle').click(function (e) {
    $('.sidebar').toggleClass('sidebar-show');
    e.stopPropagation();
  })
  $(document.body).click(function () {
    $('.sidebar').removeClass('sidebar-show');
  });
  $('.sidebar-show').click(function (e) {
    e.stopPropagation();
  });
}

function openNav() {
  if (document.getElementById("mySidenav")) {
    document.getElementById("mySidenav").style.right = "0px";
  }
  document.getElementById("close-note").focus();
}

function closeNav() {
  if (document.getElementById("mySidenav")) {
    document.getElementById("mySidenav").style.right = "-250%";
  }
}

function preventBehavior(e) {
  e.preventDefault();
};

function exemplarAssetPanelHeight() {
  var filePanelHeight = $('#add-exemplar').find(filePanel).children().height();
  var containrHeight = $('#add-exemplar').find(containerFluid).height();
  if (filePanelHeight > containrHeight) {
    $('#add-exemplar .file-panel').css('height', filePanelHeight);
  } else {
    $('#add-exemplar .file-panel').css('height', containrHeight);
  }
}

function assetPanelHeight() {
  var associationfilePanelHeight = $('#add-association').find(filePanel).children().height();
  var associationcontainrHeight = $('#add-association').find(containerFluid).height();
  if (associationfilePanelHeight > associationcontainrHeight) {
    $('#add-association .file-panel').css('height', associationfilePanelHeight);
  } else {
    $('#add-association .file-panel').css('height', associationcontainrHeight);
  }
}

function additionalMetadataPanelHeight() {
  var modalBodyHeight = $(addAdditionalMetadata).find('.modal-body').height(),
    containerSiblingsHeight = $(addAdditionalMetadata).find(containerFluid).siblings().height(),
    metadataPanelChildrenHeight = $(addAdditionalMetadata).find(filePanel).children().height(),
    newPanelHeight = modalBodyHeight - containerSiblingsHeight;

  if (newPanelHeight > metadataPanelChildrenHeight) {
    $('#addAdditionalMetadata .file-panel').css('height', newPanelHeight);
  } else {
    $('#addAdditionalMetadata .file-panel').css('height', metadataPanelChildrenHeight);
  }
}

function focusOnModalCloseBtn() {
  $("app-modal").attr("aria-hidden", "true");
  setTimeout(() => {
    $('.modal-content .js-close-btn').focus();
    $("app-modal").removeAttr("aria-hidden");
    $('.modal-content .js-close-btn').focus();
  }, 500);
}

// function focusOnToastCloseBtn() {
//   $('.toast-close-button').focus();
// }

function focusOnCancelBtn(className) {
  $(className).focus();
}

function focusOnAlertCloseBtn() {
  $('.alert .close').focus();
}

function focusOnEmailInputBox() {
  $("#emailID").focus();
}

function taxonomyDetailsHeightCalculation() {
  var windowHeight = $(window).height(),
    tabHeight = $('#treeTabContainer').outerHeight(true) + $(tabContainer).outerHeight(true),
    titleHeight = $(authoringHeader).outerHeight(true),
    protipHeight = $(taxonomyProtip).outerHeight(true) ? $(taxonomyProtip).outerHeight(true) : 0,
    extraHeight = 56,
    panelHeight = windowHeight - (tabHeight + titleHeight + protipHeight + extraHeight);
  $(treeDetailsView).height(panelHeight);
  $(rightContainerId).height(panelHeight - 16);
}

function pacingGuideDetailsHeightCalculation() {
  var windowHeight = $(window).height(),
    tabHeight = $('#treeTabContainer').outerHeight(true) + $(tabContainer).outerHeight(true),
    titleHeight = $(authoringHeader).outerHeight(true),
    protipHeight = $(taxonomyProtip).outerHeight(true) ? $(taxonomyProtip).outerHeight(true) : 0,
    extraHeight = 60,
    navBarHeight = $('#navBar').outerHeight(true),
    panelHeight = windowHeight - (tabHeight + titleHeight + protipHeight + extraHeight + navBarHeight);
  $(treeDetailsView).height(panelHeight);
  $(rightContainerId).height(panelHeight - 16);
}

function searchHeightCalculation() {
  var windowHeight = $(window).height(),
    resultCountHeight = $(".row.search-result-count").height(),
    titleHeight = $(authoringHeader).outerHeight(true),
    panelHeight = windowHeight - (titleHeight + resultCountHeight + 60);
  $('.search-result .panel-left').height(panelHeight);
  $('.search-result .panel-right').height(panelHeight);
}

function pacingGuideHeightCalculation() {
  var modalBody = $('#createPacingGuide .modal-body'),
    modalBodyHeight = $(modalBody).height(),
    bodyContentHeight = $('#createPacingGuide .modal-body #pgModalContent'),
    totalHeight = 0;
  $(modalBody).find('.taxonomy-selection-list').children().each(function () {
    totalHeight = totalHeight + $(this).outerHeight(true);
  });
  if (totalHeight > modalBodyHeight) {
    $(bodyContentHeight).height(totalHeight);
  } else {
    $(bodyContentHeight).height(modalBodyHeight);
  }
}

function setTextareaHeight() {
  for (var i = 0; i < document.getElementsByTagName('textarea').length; i++) {
    var height = (document.getElementsByTagName('textarea')[i].scrollHeight + 2);
    document.getElementsByTagName('textarea')[i].style.height = (height < 70 ? 70 : height) + 'px';
  }
}

function publicReviewHeightCalculation() {
  var windowHeight = $(window).height(),
    titleHeight = $(authoringHeader).outerHeight(true),
    protipHeight = $('.review-comment .review-protip').outerHeight(true) ? $('.review-comment .review-protip').outerHeight(true) : 0,
    extraHeight = 0,
    navBarHeight = $('#navBar').outerHeight(true),
    panelHeight = windowHeight - (titleHeight + protipHeight + extraHeight + navBarHeight);
  $('.review-comment #tree_container_id').height(panelHeight);
  $('.review-comment #right_container_id').height(panelHeight - 17);
}

function frontFacetaxonomyDetailsHeightCal() {
  var windowHeight = $(window).height(),
    headerHeight = $('.ff-header').outerHeight(true),
    contentExtraHeight = $('.container-fluid.ff').outerHeight(true),
    protipHeight = $(taxonomyProtip).outerHeight(true) ? $(taxonomyProtip).outerHeight(true) : 0,
    extraHeight = 0,
    panelHeight = windowHeight - (headerHeight + contentExtraHeight + protipHeight + extraHeight);
  $('#tree_container_id').height(panelHeight - 20);
  $(rightContainerId).height(panelHeight - 16);
}

function onDateRangeCalendarOpenClick() {
  $(document).on('click', '.btnpicker', function () {
    document.querySelector('.mydrp .selector .titleareatxt').setAttribute('aria-live', 'assertive');
    document.querySelector('.mydrp .selector .headerclearbtn').setAttribute('aria-label', 'Clear date range');
    document.querySelector('.mydrp .selector .headerokbtn').setAttribute('aria-label', 'Finish date range');
  });
}

function customTaxonomyHeightCal() {
  var windowHeight = $(window).height(),
    headerHeight = $(authoringHeader).outerHeight(true),
    rightPanelDetailHeaderHeight = $('.association-details-header').outerHeight(true),
    rightPanelDetailFilterHeight = $('.association-details-filter').outerHeight(true),
    rightPanelDetailContentHeight = windowHeight - (headerHeight + rightPanelDetailHeaderHeight + rightPanelDetailFilterHeight),
    extraHeight = 0,
    panelHeight = windowHeight - (headerHeight + extraHeight);
  $('.custom-view').height(panelHeight);
  $('.custom-view .association-details-content').height(rightPanelDetailContentHeight - 20);
}

function jasperReportHeightCal() {
  const windowHeight = window.innerHeight,
    headerPanel = document.querySelector(authoringHeader),
    headerHeight = headerPanel ? headerPanel.clientHeight : 0,
    panelHeight = windowHeight - (headerHeight + 99),
    leftPanel = document.querySelector('.report-container .report-left'),
    rightPanel = document.querySelector('.report-container .report-right');
  if (leftPanel) {
    leftPanel.style.height = (panelHeight + 68) + 'px';
  }
  if (rightPanel) {
    rightPanel.style.height = (panelHeight + 12) + 'px';
  }
}

// Height calculation for report tab in taxonomy details
function reportTabHeightCalculation() {
  var windowHeight = window.innerHeight,
    tabPanel = document.querySelector(tabContainer),
    titlePanel = document.querySelector(authoringHeader),
    protipPanel = document.querySelector(taxonomyProtip),
    tabHeight = tabPanel ? tabPanel.clientHeight : 0,
    titleHeight = titlePanel ? titlePanel.clientHeight : 0,
    protipHeight = protipPanel ? protipPanel.clientHeight : 0,
    extraHeight = 56 + 57,
    panelHeight = windowHeight - (tabHeight + titleHeight + protipHeight + extraHeight),
    leftPanel = document.querySelector('.report-tab-container .report-left'),
    rightPanel = document.querySelector('.report-tab-container .report-right');
  if (leftPanel) {
    leftPanel.style.height = (panelHeight + 57) + 'px';
  }
  if (rightPanel) {
    rightPanel.style.height = panelHeight + 'px';
  }
}

function detectIECompatible() {
  var ua = window.navigator.userAgent;
  var version;
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return false
    version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    return false;
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return false
    var rv = ua.indexOf('rv:');
    version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    return false;
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return true
    version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    return true;
  }

  // other browser
  return true;
}

function showIEBrowserIncompatibleMsg() {
  if (detectIECompatible() === false) { // not compatible
    // Show alert
    document.querySelector(incomMsgEL).classList.remove('hideIEmsg');
    document.querySelector(incomMsgEL).classList.add('showIEmsg');
  } else { // compatible
    // hide alert
    document.querySelector(incomMsgEL).classList.remove('showIEmsg');
    document.querySelector(incomMsgEL).classList.add('hideIEmsg');
  }
  //On ok button click, hide alert
  $("#IEAlertOk").click(function () {
    document.querySelector(incomMsgEL).classList.remove('showIEmsg');
    document.querySelector(incomMsgEL).classList.add('hideIEmsg');
  });
}

//init all function in document ready 
$(document).ready(function () {
  // init_sidebar();
  init_globalTooltip();
  init_dropdownHover();
  init_accordionTree();
  //init_numberCounter();  
  init_detailsViewClick();
  init_treeViewClick();
  // init_searchAnimation();
  assetPanelHeight();
  exemplarAssetPanelHeight();
  // openNav();
  // closeNav();
  //init_tabAnimation();
  onDateRangeCalendarOpenClick();
  document.addEventListener("touchmove", preventBehavior, false);
  showIEBrowserIncompatibleMsg();
});


$(window).on('popstate', function (event) {
  $('body').removeClass('modal-open');
  $('.modal').modal('hide');
});




// Window resize functions
$(window).resize(function () {
  // detailView();
  assetPanelHeight();
  exemplarAssetPanelHeight();
  additionalMetadataPanelHeight();
  // taxonomyDetailsHeightCalculation();
  searchHeightCalculation();
  pacingGuideHeightCalculation();
  publicReviewHeightCalculation();
  frontFacetaxonomyDetailsHeightCal();
  pacingGuideDetailsHeightCalculation();
  customTaxonomyHeightCal();
  jasperReportHeightCal();
  reportTabHeightCalculation();
});
