import { Component, OnInit } from '@angular/core';
import {
  GlobalSettings
} from '../global.settings';
import {
  SharedService
} from '../shared.service';
import {
  FormGroup,
  FormControl
} from '@angular/forms';
import { CommonService } from '../common.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
  tabItems = ['updates', 'project request']; // contains all the tabs for the current view
  counts = [0, 0]; // holds counts for respective tab data
  currentTab = this.tabItems[0].toLowerCase(); // selects the current tab as default view
  emailOption;



  constructor(
    private sharedService: SharedService,
    private service: CommonService
  ) { }

  ngOnInit() {
    this.sharedService.setTitleEvent.next({
      'type': 'notification',
      'title': 'Notifications'
    });
    this.getAllProjectAccessRequest();
    this.getUserInfo();
  }

  /* --------- Tab Selection functionality Started --------- */

  onTabSelected(tab) {
    this.currentTab = tab;
  }

  /* --------- Tab Selection functionality Ended --------- */

  getAllProjectAccessRequest() {
    const url = GlobalSettings.PROJECT_ACCESS_REQUEST;
    this.service.getServiceData(url).then((res: any) => {
      if (res && res instanceof Array) {
        const pending = [];
        res.forEach(element => {
          if (element.status === 1) { // pending
            pending.push(element);
          }
        });
        this.counts[1] = pending.length;
      }
    }).catch((ex) => {
      console.log('Error caught while fetching project access request list', ex);
    });
  }

  getUserInfo() {
    const url = GlobalSettings.SET_USER_INFO;
    this.service.getServiceData(url).then((res: any) => {
      this.emailOption = res[0].email_setting;
    })
      .catch((err: any) => { });
  }
  setUserInfo(val) {
    const userId = localStorage.getItem('user_id'),
      url = GlobalSettings.SET_USER_INFO + '/' + userId,
      body = {
        enable_email: val
      };
    this.service.postService(url, body).then((res: any) => {
      console.log(res);
      if (res[0].enable_email_notification === 1) {
        this.sharedService.sucessEvent.next({
          type: 'email_notification_on'
        });
      } else {
        this.sharedService.sucessEvent.next({
          type: 'email_notification_off'
        });
      }
    })
      .catch((err: any) => { });
  }
}
