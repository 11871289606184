import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { GlobalSettings } from 'src/app/global.settings';
import { CommonService } from 'src/app/common.service';
import Utils from 'src/app/utils';
import { TreeDataService } from 'src/app/tree-data.service';
import { AutoCompleteComponent } from 'src/app/common/auto-complete/auto-complete.component';
import { SharedService } from 'src/app/shared.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-front-facing-public-review',
  templateUrl: './front-facing-public-review.component.html',
  styleUrls: ['./front-facing-public-review.component.scss']
})
export class FrontFacingPublicReviewComponent implements OnInit {
  publicReviewList = []; // holds list of Public Review
  reviewListLoaded = false; // holds flag to display loader while loading Public Review list
  uniqueId = ''; // holds unique ID of Public Review object
  title = ''; // holds display-text property of Public Review object
  autoCompleteTitle = 'Review'; // holds title of Public Review list drop-down
  nodeTypeList = []; // holds list of nodetypes of selected Public Review
  selectedReview: any; // holds selected Public Review
  orgId = ''; // holds current organization ID
  nodesLoaded = false; // holds flag to display loader while loading tree-nodes & filtering them
  filteredNodeList = []; // holds list of nodes, filtered by configuration filter-level
  worker: any; // holds worker instance
  treeData: any; // holds tree-hierarchy of selected Public Review
  loadingNodes = false; // holds flag to display loader while loading children of selected filter-level node
  filterCriteriaList = []; // holds list of filter criteria (for worker utilization purpose)
  selectedNode: any; // holds the node selected on LAST filter-level
  configFilterLevel = []; // holds array of filter-levels (as per configuration)
  selectedFilter: any; // holds list of selected filters (level & node_type_id) to filter nodes from tree
  selectedFilterLevelNode: any; // holds the node selected on filter-level
  configFeedbackLevel = []; // holds array of feedback levels (as per configuration)
  frontFaceParams = ''; // holds front-facing parameters to call APIs
  userInfoParams = []; // holds list of parameters to get user information
  finalObjToSubmit = {
    unregister_user_metadata: [],
    comments: []
  }; // holds object to submit Public Review
  isSubmitting = false; // holds flag to display loader while submitting feedback
  siteKey = '6Le7tYsUAAAAACM8LIHTr9dWMHQlxUIKVqTPHGLV'; // holds site key for captcha
  captchaValidate = false; // holds flag to validate captcha
  textJsonData: any;

  @ViewChild('autoComplete_review', { static: false }) autocompleteReview: AutoCompleteComponent;
  @ViewChild('autoComplete_node1', { static: false }) autocompleteNodeL1: AutoCompleteComponent;
  @ViewChild('autoComplete_node2', { static: false }) autocompleteNodeL2: AutoCompleteComponent;
  @ViewChildren('autoComplete_info') autoCompleteInfo: QueryList<AutoCompleteComponent>;
  @ViewChild('captchaElem', { static: false }) captchaElem: RecaptchaComponent;

  constructor(
    private service: CommonService,
    private treeService: TreeDataService,
    private sharedService: SharedService,
    private http: HttpClient) {
    if (document.location.hostname.indexOf('localhost') !== -1) {
      this.siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    } else if (document.location.hostname.indexOf('acmt-stg2.learningmate') !== -1) {
      this.siteKey = '6LfMyYsUAAAAAC54odbs2OA5APwf9kO7BDqjJU0k';
    } else if (document.location.hostname.indexOf('comet-stg.learningmate') !== -1) {
      this.siteKey = '6LcBxIsUAAAAAK1YHuKGMAlCv4a3OvbfC2rTESAU';
    } else if (document.location.hostname.indexOf('acmt-scde-stag.learningmate') !== -1) {
      this.siteKey = '6LcExIsUAAAAAHVL97xaoNK74eBPXYoHTdJKyxXU';
    } else if (document.location.hostname.indexOf('acmt-scde.learningmate') !== -1) {
      this.siteKey = '6Lemx4sUAAAAADl6Tb4DnpCbwYchdFyhFlOY2tUZ';
    } else if (document.location.hostname.indexOf('standards.ed.sc.gov') !== -1) {
      this.siteKey = '6LdBWIwUAAAAAFedAHDCfxMXRfqR7w5H31lDiFcC';
    } else if (document.location.hostname.indexOf('acmt-qa2.learningmate') !== -1) {
      this.siteKey = '6LcVEagUAAAAAI0lWovf3JQpQ3f3diIqKTo0gVRT';
    } else if (document.location.hostname.indexOf('acmt-dev2.learningmate') !== -1) {
      this.siteKey = '6LdHEagUAAAAAC_WIM45-Y6xosHhrR4_c2B3UI5D';
    } else if (document.location.hostname.indexOf('acmt-prod.learningmate') !== -1) {
      this.siteKey = '6LerEKgUAAAAAB-TBlf9o_vVlj8noVmxXMj5JrxI';
    } else if (document.location.hostname.indexOf('acmt-learningmate.com') !== -1) {
      this.siteKey = '6LdF2bcUAAAAAByIP5Ah4hPiK-fo6prPvn6WTLZY';
    } else if (document.location.hostname.indexOf('standards.isbe.net') !== -1) {
      this.siteKey = '6LeS5t4UAAAAAA9u8ldp4GLoWk6-tWyT9_Bwfdm6';
    } else if (document.location.hostname.indexOf('acmt-preprod.learningmate.com') !== -1) {
      this.siteKey = '6LdvHvoUAAAAAORO9AYYVDK-SCCWCBWEvaAXz1yd';
    } else if (document.location.hostname.indexOf('acmtapp-mtdev.learningmate.co') !== -1) {
      this.siteKey = '6LdshK8ZAAAAAOeLl4Bjzyr557-Ws9vBwHtKM_Al';
    } else if (document.location.hostname.indexOf('acmt-dev.learningmate.co') !== -1) {
      this.siteKey = '6Lcb9q4ZAAAAADg4g57q0TncR5rqs5lrMyAYTTnB';
    } else if (document.location.hostname.indexOf('aks.gcpsk12.org') !== -1) {
      this.siteKey = '6LeqCbwZAAAAACHYZpFn0wFShu_fTO0wbizmneyA';
    } else if (document.location.hostname.indexOf('admin.frostplatform.com') !== -1) {
      this.siteKey = '6Le3h8IZAAAAADPUbov1GpseHiIWah1jb199SZb6';
    } else if (document.location.hostname.indexOf('unifiedfrost-acmt-qa.learningmate.co') !== -1) {
      this.siteKey = '6LeGV8wZAAAAAPBxjUpaIGtRbgQS-TQpIBHTfzKw';
    } else if (document.location.hostname.indexOf('unifiedfrost-acmt-stage.learningmate.co') !== -1) {
      this.siteKey = '6LePV8wZAAAAAJKr8uKlc3whe3RoOKP5Ih8UGfgr';
    } else if (document.location.hostname.indexOf('acmt.frostplatform.com') !== -1) {
      this.siteKey = '6LejV8wZAAAAAFEUrA8TlJn4rxBeCGfHZ30xK2V_';
    } else if (document.location.hostname.indexOf('acmt-qa.learningmate') !== -1) {
      this.siteKey = '6Lelx4sUAAAAAEh-FLW5q8HyZum1xmzf92n20Mfr';
    }
    this.sharedService.orgDetails.subscribe((data: any) => {
      if (data && data.organization_id) {
        this.orgId = data.organization_id;
        this.frontFaceParams = 'organization_id=' + this.orgId + '&front_facing=1';
        this.getPublicReviewList();
        this.getUserInfoParams();
      }
    });
  }

  ngOnInit() {
    this.getTextData();
  }

  public getJSON(): Observable<any> {
    return this.http.get('./assets/json/publicReviewData.json');
  }


  getTextData() {
    this.textJsonData = null;
    this.getJSON().subscribe(data => {
      this.textJsonData = data;
      console.log('JSON data', this.textJsonData);
    });
  }

  // FUNCTION to get list of Public Reviews with configuration

  getPublicReviewList() {
    this.reviewListLoaded = false;
    const url = GlobalSettings.PROJECT_LIST + '?project_type=3&return_order=1&' + this.frontFaceParams;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      if (res) {
        this.publicReviewList = res.projects.filter((item: any) => item.config_id && item.public_review_status === 2);
        this.uniqueId = 'project_id';
        this.title = 'project_name';
        this.publicReviewList.forEach(item => {
          switch (item.review_status) {
            case 1:
              item.status = 'Review not yet started';
              break;
            case 2:
              item.status = 'Review in progress';
              break;
            case 3:
              item.status = 'Completed & submitted the review';
              break;
            default:
          }
        });
      }
      this.reviewListLoaded = true;
    }).catch(ex => {
      this.reviewListLoaded = true;
    });
  }


  // FUNCTION to get list of parameters for User Information

  getUserInfoParams() {
    this.userInfoParams = [];
    const url = GlobalSettings.PR_USER_INFO_PARAMS + '?' + this.frontFaceParams;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.userInfoParams = res;
      Utils.sortDataArray(this.userInfoParams, 'user_metadata_name', false, true);
      this.userInfoParams.forEach((info: any) => {
        info.user_metadata_value = '';
      });
    });
  }


  // FUNCTION to perform next steps after selecting / deselecting a Public Review from drop-down

  onPublicReviewSelected(data) {
    if (this.autocompleteNodeL1) {
      this.autocompleteNodeL1.clearSelection();
    }
    this.selectedFilterLevelNode = null;
    this.selectedNode = null;
    this.finalObjToSubmit = {
      unregister_user_metadata: [],
      comments: []
    };
    this.selectedReview = data;
    if (data) {
      const taxonomyId = data[this.uniqueId];
      this.getConfigDetails(data.config_id);
      this.loadNodeTypes(taxonomyId);
      this.loadTreeData(taxonomyId);
    }
  }


  // FUNCTION to load the list of nodetypes of selected Public Review

  loadNodeTypes(taxonomyId) {
    const viewType = 'node_type';
    this.nodeTypeList = [];
    const url = GlobalSettings.TAXONOMY_NODE_TYPE_DETAILS + '/' + taxonomyId + '/2/' + viewType + '?return_order=1&' + this.frontFaceParams;

    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      if (res && res.nodeTypes) {
        res.defaultNodeTypesOrder.forEach(nodeType => {
          const elem = res.nodeTypes.find(item => item.node_type_id === nodeType);
          this.nodeTypeList.push(elem);
        });
      }
    });

  }


  // FUNCTION to load the tree-hierarchy of selected Public Review

  loadTreeData(id) {
    this.nodesLoaded = false;
    if (id) {
      this.treeData = null;
      const url = GlobalSettings.GET_MAPPED_NODES_ALL + '/' + id + '?' + this.frontFaceParams;
      this.treeService.getTreeData(url, true, Utils.EXPAND_LEVEL, false).then((response: any) => {
        const res = response.parsedTreeNodes;
        if (res && res.children) {
          this.treeData = res.children;
          this.createFilter();
        }
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }


  // FUNCTION to get configuration details (Public Review specific)

  getConfigDetails(configId) {
    this.configFeedbackLevel = [];
    this.configFilterLevel = [];
    const url = GlobalSettings.PR_CONFIG_DETAILS_URL + configId + '?' + this.frontFaceParams;

    this.service.getUserServiceDataWithoutTocken(url, true).then((res: any) => {
      if (res) {

        res.forEach(item => {
          if (item.is_applicable_for_filter) {
            this.configFilterLevel.push(item);
          }

          if (item.is_open_for_feedback) {
            this.configFeedbackLevel.push(item.level);
          }
        });
        Utils.sortDataArray(this.configFilterLevel, 'level', false, true);
      }
    });
  }


  // FUNCTION to perform next steps after selected filter level node (as per configuration)

  onFilterLevelNodeSelected(data, index) {
    if ((index + 1) !== this.configFilterLevel.length) {
      this.getNextlevelNodes(data);
    } else {
      this.onNodeSelected(data);
    }
  }


  // FUNCTION to load next level nodes of selected filter-level node

  getNextlevelNodes(data) {
    this.loadingNodes = true;
    if (this.autocompleteNodeL2) {
      this.autocompleteNodeL2.clearSelection();
    }
    this.selectedNode = null;
    this.selectedFilterLevelNode = data;
    if (this.selectedFilterLevelNode && this.selectedFilterLevelNode.children.length) {
      this.selectedFilterLevelNode.children.forEach(node => {
        node['display_name'] = (node.human_coding_scheme_html ? node.human_coding_scheme_html : node.human_coding_scheme) + ' ' +
          (node.full_statement_html ? node.full_statement_html : node.full_statement);
      });
    }
    this.loadingNodes = false;
  }


  // FUNCTION to select last filter-level node

  onNodeSelected(node) {
    this.selectedNode = node;
  }


  // FUNCTION to create filter-list for filtering nodes

  createFilter() {
    this.filterCriteriaList = [
      {
        display_name: 'Level',
        internal_name: 'level',
        metadata_id: 'level_1234',
        name: 'Level',
        propName: 'level'
      }, {
        display_name: 'Node Type',
        internal_name: 'node_type_id',
        metadata_id: 'nodeType_1234',
        name: 'Node Type',
        propName: 'node_type_id'
      }];
    this.selectedFilter = [{
      label: this.configFilterLevel[0].level,
      colName: 'level'
    },
    {
      label: this.configFilterLevel[0].node_type_id,
      colName: 'node_type_id'
    }];
    this.callGetFilterResult();
  }


  // FUNCTION to get list of filtered nodes (based on configuration) from tree-hierarchy

  callGetFilterResult() {
    this.filteredNodeList = [];
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        if (data) {
          this.treeData = data.treeData;
          this.filteredNodeList = data.filterResult;
          this.filteredNodeList.forEach(node => {
            node['display_name'] = (node.human_coding_scheme_html ? node.human_coding_scheme_html : node.human_coding_scheme) + ' ' +
              (node.full_statement_html ? node.full_statement_html : node.full_statement);
          });
          setTimeout(() => {
            this.nodesLoaded = true;
          }, 500);
        }
      };
      this.worker.postMessage({
        data: this.treeData, location: 'tree-search', parameters:
        {
          filterCriteriaList: this.filterCriteriaList,
          search: false,
          searchText: '',
          isReset: false,
          selectedFilterList: this.selectedFilter,
          getFilterResult: true,
          dataForGetFilterResult: this.treeData,
          searchResultList: [],
          filterResult: [],
          filterObj: []
        }
      });
    }
  }


  // FUNCTION to save user-detail inputs

  saveDetails() {
    this.userInfoParams.forEach(param => {
      this.finalObjToSubmit['unregister_user_metadata'].push({
        user_metadata_id: param.user_metadata_id,
        user_metadata_value: (param.user_metadata_value.toLowerCase() === 'none' ||
          param.user_metadata_value.toLowerCase() === 'not applicable') ? '' : param.user_metadata_value
      });
    });

    this.submitReview();

    if (document.getElementById('cancel-modal-btn-userDetailPR')) {
      document.getElementById('cancel-modal-btn-userDetailPR').click();
    }

  }


  // FUNCTION to clear user inputs

  clearInputs() {
    this.userInfoParams.forEach(info => {
      info.user_metadata_value = '';
    });
    if (this.autoCompleteInfo) {
      const userInfo = this.autoCompleteInfo.toArray();
      userInfo.forEach(info => {
        info.clearSelection();
      });
    }
    this.captchaValidate = false;
    this.captchaElem.reset();
  }


  // FUNCTION to like a node

  likeNode(node) {
    node.liked = !node.liked;
    this.saveFeedback(node);
  }


  // FUNCTION to save user feedback (like & comment) for each node

  saveFeedback(node) {
    const index = this.finalObjToSubmit['comments'].findIndex(item => item.thread_source_id === node.id);
    if (index > -1) {
      const obj = this.finalObjToSubmit['comments'][index];
      obj.is_like = node.liked ? 1 : 0;
      obj.comment = node.comment && node.comment.trim() !== '' ? node.comment : '';
      obj.updated_at = this.getTimeStamp();
    } else {
      this.finalObjToSubmit['comments'].push(this.createFeedbackObj(node));
    }
    const optimizedList = this.finalObjToSubmit.comments.filter(item => item.is_like || item.comment);
    this.finalObjToSubmit.comments = optimizedList;
  }


  // FUNCTION to create new object for feedback

  createFeedbackObj(node) {
    const obj = {
      thread_source_id: node.id,
      status: 1,
      comment: node.comment && node.comment.trim() !== '' ? node.comment : '',
      thread_source_type: 1,
      is_like: node.liked ? 1 : 0,
      updated_at: this.getTimeStamp()
    };

    return obj;
  }

  getTimeStamp() {
    const dt = new Date();
    const date = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    const time = dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds();
    return (date + ' ' + time);
  }

  // FUNCTION to submit Public Review

  submitReview() {
    this.isSubmitting = true;
    const url = GlobalSettings.SUBMIT_REVIEW_FRONT_FACE_URL + '?' + this.frontFaceParams;
    const body = this.finalObjToSubmit;
    this.service.registerService(url, body).then((res: any) => {
      this.sharedService.sucessEvent.next({
        type: 'success',
        customMsg: 'Thank you, your feedback has been submitted successfully.'
      });
      if (this.autocompleteReview) {
        this.autocompleteReview.clearSelection();
      }
      this.onPublicReviewSelected(undefined);
      this.finalObjToSubmit.comments = [];
      this.finalObjToSubmit.unregister_user_metadata = [];
      this.isSubmitting = false;
    });
  }


  // function to close dropdown on outside click

  onClickedOutside(e, type, index?) {
    switch (type) {
      case 'review':
        if (this.autocompleteReview) {
          this.autocompleteReview.openPanel(false);
        }
        break;
      case 'node1':
        if (this.autocompleteNodeL1) {
          this.autocompleteNodeL1.openPanel(false);
        }
        break;
      case 'node2':
        if (this.autocompleteNodeL2) {
          this.autocompleteNodeL2.openPanel(false);
        }
        break;
      case 'info':
        if (this.autoCompleteInfo) {
          const targets = this.autoCompleteInfo.toArray();
          for (let i = 0; i < targets.length; i++) {
            if (i === index) {
              targets[i].openPanel(false);
            }

          }
        }
        break;
      default:
    }
  }


  //  FUNCTION to resolve captcha

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
    this.captchaValidate = true;
  }


  // FUNCTION to check user-input validation

  checkInput() {
    const validInput = this.userInfoParams.every(param => param.user_metadata_value !== '');
    return validInput;
  }

  // FUNCTION to select value from user-info drop-downs

  onUserInfoSelected(selectedOption, metadata) {
    metadata.user_metadata_value = selectedOption.name;
  }
}
