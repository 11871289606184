// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    base_url: 'https://acmtapp-mtdev.learningmate.co/server/',
    version: 'api/v1/',
    auth_url: '',
    s3_bucket: 'acmthost',
    faq_url: 'https://acmtapp-mtdev.learningmate.co/faq/',
    recaptcha_site_key: '6LdshK8ZAAAAAOeLl4Bjzyr557-Ws9vBwHtKM_Al',
    showPublishedTaxonomy: true,
    renamePacingGuide: false,
    tenant: [''],
    env: 'mtdev',
    allowTenantSpecificChangesOnly: [''],
    domain: 'oauthapp',
    unifiedLogin: 'https://unifiedfrost-auth-dev.learningmate.co/#/login',
    unifiedLogout: 'https://unifiedfrost-auth-dev.learningmate.co/#/logout',
    unifiedAdmin: 'https://unifiedfrost-admin-dev.learningmate.co/api/',
    unifiedForgotPassword: 'https://unifiedfrost-auth-dev.learningmate.co/#/forget-password',
    SSO_USER: true,
    prTenants: ['GWIN1', 'TST78', 'nik01'],
    adminHost: 'https://unifiedfrost-admin-dev.learningmate.co/',
    apiHostAuthServer: 'https://unifiedfrost-auth-api-dev.learningmate.co/oauth/',
    client_id: '387a93bd-358b-4ac6-9c6b-f4a62accb478',
    client_secret: 'gninraeletam',
    grant_type: 'refresh_token',
    env_file: 'mtdev'
};
