import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CdkVirtualScrollViewport, FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';
import Utils from 'src/app/utils';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared.service';
import { TabularTreeSidePaneComponent } from '../tabular-tree-side-pane/tabular-tree-side-pane.component';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-lazy-tabular-tree',
  templateUrl: './lazy-tabular-tree.component.html',
  styleUrls: ['./lazy-tabular-tree.component.scss', '../lazy-tree/lazy-tree-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: LazyTabularTreeComponent }]
})
export class LazyTabularTreeComponent extends FixedSizeVirtualScrollStrategy implements OnInit, OnChanges, OnDestroy {
  headerHeight = '30px'; // holds the height of header row
  windowResizeSubscription: Subscription;
  currentItemId = ''; // holds current node item on which focus is supposed to be shifted after expansion
  offset = 0; // holds the offset of current node, to used provide focus back on scroll after expansion
  loadChild = false; // holds boolean to determine loader on node expansion
  selectedNode: any; // holds current node data
  loadChildSubscription: Subscription; // holds subscription to determine whether or not children have been loaded
  openMode = false; // holds boolean value of right side panel to be opened in Readonly(false) or Edit(true) mode
  charLimit = 30; // holds character limit to trim HC and FS
  filenameCharlmt = 10; // holds character limit to trim file name
  clicked = ''; // identifies the the current clicked expand button

  @Input() treeNodes;
  @Input() refinedData;
  @Input() viewLocation; // holds boolean value to determin css
  @Input() showComplaince; // holds boolean value to determin compliance icons
  @Input() isAllNode = false;
  @Input() showNavigation = true; // holds boolean value to determine compliance icons
  @Input() showUserFriendlyPage = true; // holds boolean value to determine compliance icons
  @Input() selectedNodeId; // holds the current selected node id
  @Input() listOfColumn; // holds the current selected node id
  @Output() toggleExpandEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  @ViewChild('tabularTreeSidePaneComponent', { static: false }) tabularTreeSidePaneComponent: TabularTreeSidePaneComponent;

  constructor(private cdRef: ChangeDetectorRef, private viewScroller: ViewportScroller, private http: HttpClient, private sharedService: SharedService, private router: Router) {
    super(25, 0, 0);
    this.loadChildSubscription = this.sharedService.loadChild.subscribe((item: any) => {
      if (item && item.loadChild) {
        this.loadChild = item.loadChild;
      } else {
        this.loadChild = false;
      }
    });

    //
    this.windowResizeSubscription = this.sharedService.windowResizeEvent.subscribe((item: any) => {
      if (item) {
        this.columnHeaderWidthCalculation();
      }
    });
  }

  ngOnInit() {
    this.columnHeaderWidthCalculation();
  }

  ngOnChanges() { }

  showChildren(item) {
    this.toggleExpandEvent.emit(item);
  }

  calculatePadding(level, index) {
    if (index === 0) {
      return Utils.calculatePadding(level, 'treeview');
    }
  }


  /* --------- Functionality to capture offset, call scrolltoOffset functionality after refined data is updated start --------- */

  scrollViewPort(time) {
    const self = this;
    // tslint:disable-next-line: no-unused-expression
    new Promise(() => {
      setTimeout(() => {
        // if (self.viewPort && self.viewPort.scrollToOffset) {
        //   // self.viewPort.scrollToOffset(self.offset);
        //   // this.viewScroller.scrollToAnchor('#' + this.currentItemId + '-expand');
        if (document.getElementById(this.currentItemId)) {
          document.getElementById(this.currentItemId).focus();
        }
        // }
      }, time);
    }
    );
  }

  async scroll() {
    await this.scrollViewPort(200);
    // this.sharedService.loadChild.next({
    //   loadChild: false
    // });
  }

  getOffset(id) {
    this.currentItemId = id;
    this.offset = this.viewPort.measureScrollOffset();
    this.sharedService.loadChild.next({
      loadChild: true
    });
  }

  /* --------- Functionality to capture offset, call scrolltoOffset functionality after refined data is updated end --------- */

  ngOnDestroy() {
    if (this.loadChildSubscription) {
      this.loadChildSubscription.unsubscribe();
    }
    Utils.addBodyScroll();

    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
  }


  /* --------- Functionality to navigate the nodes in table start --------- */

  onNextNavigationClick(event: any) {
    this.selectNodeOnNavigation(event.node);
  }

  onPreviousNavigationClick(event: any) {
    this.selectNodeOnNavigation(event.node);
  }


  selectNodeOnNavigation(node: any) {
    if (!this.refinedData.find(x => x.id === node.id)) {
      if (node.parent_id) {
        const parentNode = this.refinedData.find(x => x.id === node.parent_id);
        parentNode.expand = true;
        this.getOffset(node.parent_id);
        this.showChildren(parentNode);
      }
    }

    // if (this.treeNodes instanceof Array) {
    //   for (let i = 0; i < this.treeNodes.length; i++) {
    //     Utils.expandTreeTillSelectedNode(node, this.treeNodes[i]);
    //   }
    // } else {
    //   Utils.expandTreeTillSelectedNode(node, this.treeNodes);
    // }
    setTimeout(() => {
      const id = node['id'] ? node['id'] : node['item_id'];
      this.currentItemId = id;
      if (document.getElementById(id)) {
        document.getElementById(id).focus();
      }
    }, 100);
    this.openDetails(node, this.openMode);
  }


  /* --------- Functionality to navigate the nodes in table end --------- */


  /* --------- Functionality to make node editable in side pane start --------- */

  setEditable(editable) {
    this.tabularTreeSidePaneComponent.setEditable(editable);
  }

  /* --------- Functionality to make node editable in side pane end --------- */


  /* --------- Functionality to open drawer on set data start --------- */

  openDetails(item, editable) {
    this.openMode = editable;
    this.selectedNode = item;
    this.setEditable(editable);
    if (this.router.url.indexOf('/taxonomies') >= 0) {
      if (this.tabularTreeSidePaneComponent) {
        this.tabularTreeSidePaneComponent.setData(item);
        this.tabularTreeSidePaneComponent.showHideNav(true);
      }
    }
    // else {
    //   this.getSelectedItemDrawerDetail(item);
    // }
    setTimeout(() => {
      this.sharedService.treeNodeSelectedEvent.next(item);
    }, 50);
  }

  /* --------- Functionality to open drawer on set data end --------- */


  /* --------- Functionality to capture and set table header height start --------- */

  columnHeaderWidthCalculation() {
    let padding = '';
    setTimeout(() => {
      // if (document.getElementById("rowHeightInfo")) {
      //   var x = document.getElementById("rowHeightInfo");
      //   this.headerHeight = x.clientHeight + 'px';
      //   if (document.getElementById('1-row') && document.getElementById('1-row')[0]) {
      //     (document.getElementById('1-row')[0] as HTMLElement).style.marginTop = (x.clientHeight - 22) + 'px';
      //   }   
      // }
      if (document.getElementById('1-1-td') && document.getElementById('1-1-td').style['padding-left']) {
        padding = document.getElementById('1-1-td').style['padding-left'];
        (document.getElementById('1-header')).style['padding-left'] = padding;
      }
    }, 1000);
  }

  /* --------- Functionality to capture and set table header height end --------- */

  preventRoute(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  trackByIdx(i) {
    return i;
  }
}
