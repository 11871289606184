var showLineBarChart = function (Data,itemCount) {

  var margin = {
      top: 25,
      right: 80,
      bottom: 60,
      left: 50
    },
    width = 1000 - margin.left - margin.right,
    height = 250 - margin.top - margin.bottom;

  var w = width + margin.left + margin.right;
  var h = height + margin.top + margin.bottom;
  var textWidthHolder = 0;
  /// Adding Date in LineCategory
  Data.forEach(function (d) {
    d.LineCategory.forEach(function (b) {
      b.Date = d.Date;
    })
  });


  var Categories = new Array();
  // Extension method declaration

  Categories.pro

  var x0 = d3.scale.ordinal()
    .rangeRoundBands([0, width], .1);
  var XLine = d3.scale.ordinal()
    .rangePoints([0, width], .1);
  var x1 = d3.scale.ordinal();

  var y = d3.scale.linear()
    .range([height, 0]);

  var YLine = d3.scale.linear().range([height, 0])
    .domain([0, d3.max(Data, function (d) {
      return d3.max(d.LineCategory, function (b) {
        return b.Value
      })
    })]);

  var color = d3.scale.ordinal()
    .range(["rgba(0, 110, 255, 0.8)"]);

  var line = d3.svg.line().x(function (d) {
    return x0(d.Date) + x0.rangeBand() / 2;
  }).y(function (d) {
    return YLine(d.Value)
  }).interpolate("basis");



  var formatVal = d3.format('.0f');

  var xAxis = d3.svg.axis()
    .scale(x0)
    .orient("bottom");

  var yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")
    .tickFormat(formatVal)
    .innerTickSize(-width)
    .outerTickSize(0)
    .tickPadding(10);

  var YLeftAxis = d3.svg.axis().scale(YLine).orient("right").tickFormat(formatVal);

  // clear all the child
  d3.select("svg").remove();
  var svg = d3.select("#chart_container").append("svg")
    .attr("viewBox", "0 0 " + w + " " + h)
    .attr("preserveAspectRatio", "xMidYMid meet")
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  /* var svg = d3.select("#chart_container").append("svg")
   .attr("viewBox", "0 0 " + w + " " + h)
   .attr("preserveAspectRatio", "xMidYMid meet")
   .append("g")
   .attr("transform", "translate(" + margin.left + "," + margin.top + ")");*/



  // Bar Data categories
  Data.forEach(function (d) {
    d.Categories.forEach(function (b) {
      if (Categories.findIndex(function (c) {
          return c.Name === b.Name
        }) == -1) {
        b.Type = "bar";
        console.log(JSON.stringify(b))
        Categories.push(b)
      }
    })
  });


  // Line Data categories
  Data.forEach(function (d) {
    d.LineCategory.forEach(function (b) {
      if (Categories.findIndex(function (c) {
          return c.Name === b.Name
        }) == -1) {
        b.Type = "line";
        console.log(JSON.stringify(b))
        Categories.push(b)
      }
    })
  });

  // Processing Line data
  var lineData = DataSegregator(Data.map(function (d) {
    return d.LineCategory
  }), "Name");

  // Line Coloring
  var LineColor = d3.scale.ordinal();
  LineColor.domain(Categories.filter(function (d) {
    return d.Type == "line"
  }).map(function (d) {
    return d.Name
  }));
  LineColor.range(["#42526E", "#06bf00", "#98bdc5", "#671919", "#0b172b"])
  x0.domain(Data.map(function (d) {
    return d.Date;
  }));
  XLine.domain(Data.map(function (d) {
    return d.Date;
  }));
  x1.domain(Categories.filter(function (d) {
    return d.Type == "bar"
  }).map(function (d) {
    return d.Name
  })).rangeRoundBands([0, x0.rangeBand()]);
  y.domain([0, d3.max(Data, function (d) {
    return d3.max(d.Categories, function (d) {
      return d.Value;
    });
  })]);

  if (itemCount <= 10) {
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);
  }else {
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis)
      .selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")   
      .attr("transform", "translate(-50,50) rotate(-45)")
      .style("text-anchor", "start");
  }

  svg.append("g")
    .attr("class", "y axis")
    .attr("transform", "translate(" + (width) + ",0)")
    .call(YLeftAxis)
    .append("text")
    .attr("transform", "rotate(90)")
    .attr("y", -50)
    .attr("x", 130)
    .attr("dy", ".71em")
    .style("text-anchor", "end")
    .text("Time Spent");

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -40)
    .attr("x", -70)
    .attr("dy", ".71em")
    .style("text-anchor", "end")
    .text("Visits");


  var state = svg.selectAll(".state")
    .data(Data)
    .enter().append("g")
    .attr("class", "state")
    .attr("transform", function (d) {
      return "translate(" + x0(d.Date) + ",0)";
    });

  state.selectAll("rect")
    .data(function (d) {
      return d.Categories;
    })
    .enter().append("rect")
    .attr("width", x1.rangeBand())
    .attr("x", function (d) {
      return x1(d.Name);
    })
    .attr("y", function (d) {
      return y(d.Value);
    })
    //.attr("height", function (d) { return height - y(d.Value); })
    .style("fill", function (d) {
      return color(d.Name);
    })
    .transition().delay(500).attrTween("height", function (d) {
      var i = d3.interpolate(0, height - y(d.Value));
      return function (t) {
        return i(t);
      }
    });

  // drawaing lines
  svg.selectAll(".lines").data(lineData).enter().append("g").attr("class", "line")
    .each(function (d) {
      if (d[0]) {
        var Name = d[0].Name
        d3.select(this).append("path").attr("d", function (b) {
          return line(b)
        }).style({
          "stroke-width": "1px",
          "fill": "none"
        }).style("stroke", LineColor(Name)).transition().duration(1500);
      }
    })


  // Legends

  var LegendHolder = svg.append("g").attr("class", "legendHolder");
  LegendHolder.selectAll(".legend")
    .data(Categories.map(function (d) {
      return {
        "Name": d.Name,
        "Type": d.Type
      }
    }))
    .enter().append("g")
    .attr("class", "legend")
    .attr("transform", function (d, i) {
      return "translate(0," + (height + 40) + ")";
    })
    .each(function (d, i) {
      //  Legend Symbols

      if (d.Name !== 'cat1' && d.Name !== 'cat3') {
        d3.select(this).append("rect")
          .attr("width", function () {
            return 12
          })
          .attr("x", function (b) {

            var left = (i + 1) * 15 + i * 18 + i * 5 + textWidthHolder;
            return left;
          })
          .attr("y", function (b) {
            return b.Type == 'bar' ? 10 : 15
          })
          .attr("height", function (b) {
            return b.Type == 'bar' ? 12 : 3
          })
          .style("fill", function (b) {
            return b.Type == 'bar' ? color(d.Name) : LineColor(d.Name)
          });

        //  Legend Text

        d3.select(this).append("text")
          .attr("x", function (b) {

            var left = (i + 1) * 15 + (i + 1) * 18 + (i + 1) * 5 + textWidthHolder;

            return left;
          })
          .attr("y", 15)
          .attr("dy", ".35em")
          .style("text-anchor", "start")
          .text(d.Name);

        textWidthHolder += getTextWidth(d.Name, "10px", "calibri");
      }

    });


  // Legend Placing

  d3.select(".legendHolder").attr("transform", function (d) {
    var thisWidth = d3.select(this).node().getBBox().width;
    return "translate(" + ((width) / 2 - thisWidth / 2) + ",0)";
  })
}

function getTextWidth(text, fontSize, fontName) {
  var c = document.createElement("canvas"),
    ctx = c.getContext("2d");
  ctx.font = fontSize + ' ' + fontName;
  return ctx.measureText(text).width;
}

function DataSegregator(array, on) {
  var SegData,
    thisObject = this,
    OrdinalPositionHolder = {

      valueOf: function () {
        var keys = Object.keys(thisObject);
        keys.splice(keys.indexOf("valueOf"), 1);
        keys.splice(keys.indexOf("keys"), 1);
        return keys.length == 0 ? -1 : d3.max(keys, function (d) {
          return thisObject[d]
        })
      },
      keys: function () {
        var keys = Object.keys(thisObject);
        keys.splice(keys.indexOf("valueOf"), 1);
        keys.splice(keys.indexOf("keys"), 1);
        return keys;
      }
    }
  array[0].map(function (d) {
    return d[on]
  }).forEach(function (b) {
    var value = OrdinalPositionHolder.valueOf();
    OrdinalPositionHolder[b] = OrdinalPositionHolder > -1 ? ++value : 0;
  })

  SegData = OrdinalPositionHolder.keys().map(function () {
    return [];
  });

  array.forEach(function (d) {
    d.forEach(function (b) {
      SegData[OrdinalPositionHolder[b[on]]].push(b);
    })
  });

  return SegData;
}
