import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Utils from '../utils';

@Component({
  selector: 'app-platform-switch',
  templateUrl: './platform-switch.component.html',
  styleUrls: ['./platform-switch.component.scss']
})
export class PlatformSwitchComponent implements OnInit {

  platformAccessRecord: any; // holds permission object to access platforms
  rowIndex = 1; // holds static value (for HTML purpose only)
  env = environment.env_file; // holds current environment
  platformList = [
    {
      displayName: 'Admin',
      propName: 'admin',
      flag: 1
    },
    {
      displayName: 'ACMT',
      propName: 'standards',
      flag: 1
    },
    {
      displayName: 'Analytics',
      propName: 'analytics',
      flag: 0
    },
    {
      displayName: 'Designer',
      propName: 'frost',
      flag: 0
    },
    {
      displayName: 'QuAD',
      propName: 'quad',
      flag: 0
    },
    {
      displayName: 'LO Repository',
      propName: 'lor',
      flag: 0
    },
    {
      displayName: 'Explore',
      propName: 'explore',
      flag: 0
    },
    {
      displayName: 'Transform',
      propName: 'transform',
      flag: 0
    }
  ]; // holds the list of platforms to be displayed in drop-down
  urlJson: any; // holds the entire object of platform URLs

  constructor(private http: HttpClient) { }

  ngOnInit() {
    const SSOUser = JSON.parse(Utils.getCookie('SSOUser'));
    if (SSOUser) {
      this.platformAccessRecord = SSOUser['platform_access'];

      this.platformList.forEach(item => {
        item.flag = this.platformAccessRecord[item.propName];
      })

      this.getPlatformUrlJson();
    }
  }

  // FUNCTION to fetch JSON data of platform URLs

  getPlatformUrlJson() {
    this.getJSON().subscribe(data => {
      this.urlJson = data;
    }, err => { });
  }

  public getJSON(): Observable<any> {
    try {
      return this.http.get('./assets/json/platform-url.json');
    } catch (err) { }
  }

  // FUNCTION to navigate to new platform

  openExternalLink(platform) {
    let path = this.urlJson[this.env][platform];
    const orgCode = JSON.parse(localStorage.getItem('orgDetails')).orgCode;
    if (platform === 'explore') {
      path = path + orgCode;
    }
    window.open(path, '_self');
  }
}
