import { Component, OnInit, OnChanges } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { CommonService } from 'src/app/common.service';
import { GlobalSettings } from 'src/app/global.settings';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-preview-report',
  templateUrl: './preview-report.component.html',
  styleUrls: ['./preview-report.component.scss']
})

export class PreviewReportComponent implements OnInit, OnChanges {

  apiBody: any; // holds body object to call preview API
  apiURL = ''; // holds URL to call preview API
  previewloader; // holds flag to display loader
  sourceTaxonomytitle = ''; // holds title of source taxonomy
  reportType = ''; // holds type of report to preview

  constructor(private sharedService: SharedService, private service: CommonService) {
  }

  ngOnInit() {
    this.apiBody = JSON.parse(localStorage.getItem('previewbody'));
    this.apiURL = JSON.parse(localStorage.getItem('previewURL'));
    this.sourceTaxonomytitle = (localStorage.getItem('sourceTaxonomyTitle'));
    this.reportType = (localStorage.getItem('reportType'));
    if (this.apiBody && this.apiURL) {
      this.downloadCoverageReport('preview');
    }
  }

  ngOnChanges() {

  }

  downloadCoverageReport(action, fileType = 'html') {
    this.previewloader = action === 'preview';
    const url = this.apiURL;
    const body = this.apiBody;
    body['file_type'] = fileType;
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    this.service.downloadServiceData(url, fileType.toUpperCase(), 'post', body)
      .then((res: any) => {
        try {
          if (action === 'preview') {
            document.getElementById('previewContainer').innerHTML = res;
            this.previewloader = false;
          } else {
            this.saveToFileSystem(res, fileType, contentType);
          }
        } catch (error) {
          this.sharedService.sucessEvent.next({
            type: 'error',
            customMsg: error
          });
          this.previewloader = false;
        }
      }).catch((ex) => {
        this.sharedService.sucessEvent.next({
          type: 'error',
          customMsg: 'Failure to download file from server response'
        });
        this.previewloader = false;
      });

  }

  private saveToFileSystem(response, type, contentType) {
    const blob = new Blob([response], {
      type: contentType
    });
    const title = this.sourceTaxonomytitle ? (this.sourceTaxonomytitle + '_') : '';
    saveAs(blob, (title + 'coverage_report') + '.' + type.toLowerCase());
  }


}
