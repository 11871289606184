import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../shared.service';


@Pipe({
  name: 'customFilter'
})
export class CustomFilterPipe implements PipeTransform {
  constructor(private service: SharedService) {

  }

  transform(items: any, filter: any, defaultFilter = false): any {
    let filterArr = [];
    if (!filter) {
      return items;
    }

    if (!Array.isArray(items)) {
      return items;
    }

    if (filter && Array.isArray(items)) {
      const filterKeys = Object.keys(filter);

      if (defaultFilter) {
        filterArr = items.filter(item =>
          filterKeys.reduce((x, keyName) =>
            (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === '', true));
      } else {
        filterArr = items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
          });
        });
      }
      this.service.filterDataTable.next({ data: filterArr });
      return filterArr;
    }
  }

}
