import { Component, OnInit, EventEmitter, Output, Input, ViewChild, OnDestroy } from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { SharedService } from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
@Component({
  selector: 'app-select-association',
  templateUrl: './select-association.component.html',
  styleUrls: ['./select-association.component.scss']
})
export class SelectAssociationComponent implements OnInit {

  @Output() setStep: EventEmitter<any>; // holds the event which includes info for the step to toggle view on association modal
  @Output() removeOptions: EventEmitter<any>; // holds event to remove options to copy
  @Output() associationsObj: EventEmitter<any>; // holds the all selected node ids
  @Output() settingsModalOpen: EventEmitter<any>; // holds boolean if settings modal is opened

  @Input() optionsToCopySaved = false; // holds flag save is clicked on modal for options to append or append with child nodes
  @Input() isCopy = false; // holds additional options to append or append with child nodes
  @Input() isCopyAssociation = false; // holds additional options to append or append with child nodes
  @Input() displayText = '';
  @Input() displayOption = '';
  @Input() currentTab;
  @Input() viewLocation = '';
  backToSearch: Subscription; // holds subscription to load search list again, and back button on click of back to search on select ass comp

  searchResultLength = 0; // holds search result count
  showBackToSearch = false; // holds flag to determine visibility of back to search button

  requiredFullHierarchyFlag = null; // holds requiredFullHierarchyFlag to identify if append or append with childs is selected
  selectedAssociationType = null; // holds selected association from drop down
  associationTypes: any; // holds list of associations
  form: FormGroup;
  formAssociationType: FormControl;
  searchResultEvent: Subscription;
  constructor(private service: CommonService, private sharedService: SharedService) {
    this.setStep = new EventEmitter<any>();
    this.removeOptions = new EventEmitter<any>();
    this.associationsObj = new EventEmitter<any>();
    this.settingsModalOpen = new EventEmitter<any>();
  }

  ngOnInit() {
    this.form = new FormGroup({
      formAssociationType: new FormControl()
    });
    this.getAssociationType();
    this.searchResultEvent = this.sharedService.searchResultEvent.subscribe((item: any) => {
      if (item) {
        const searchResult = item.searchData;
        if (searchResult && searchResult.length) {
          this.searchResultLength = searchResult.length;
        } else {
          this.searchResultLength = 0;
        }
      }
    });

    this.backToSearch = this.sharedService.backToSearch.subscribe((item: any) => {
      // console.log('item.back', item.back);
      if (item && item.back) {
        this.showBackToSearch = item.back;
      } else {
        this.showBackToSearch = false;
      }
    });

  }

  /* --------- Functionality to navigate back to browse taxonomy start --------- */

  backToTaxonomyList() {
    this.setStep.emit({ step: 1 });
    if (document.getElementById('modal-body-taxonomyAssociation')) {
      document.getElementById('modal-body-taxonomyAssociation').style.overflowY = 'auto';
    }
  }

  /* --------- Functionality to navigate back to browse taxonomy end --------- */

  /* --------- Functionality to remove or reset copy options start --------- */

  removeOptionsToCopySaved() {
    this.optionsToCopySaved = false;
    this.isCopy = false;
    this.isCopyAssociation = false;
    this.requiredFullHierarchyFlag = null;
    this.removeOptions.emit();
  }

  /* --------- Functionality to remove or reset copy options end --------- */


  /* --------- Functionality to capture and emit selected association type start --------- */

  onTypeSelected() {
    if (this.selectedAssociationType && this.selectedAssociationType.type_name !== 'isParentOf') {
      this.requiredFullHierarchyFlag = null;
    }
    if (this.selectedAssociationType === null) {
      this.selectedAssociationType = null;
    }
    // console.log('this.selectedAssociationType', this.selectedAssociationType);
    this.associationsObj.emit({
      associationType: this.selectedAssociationType,
      requiredFullHierarchyFlag: this.requiredFullHierarchyFlag,
    });
  }

  /* --------- Functionality to capture and emit selected association type end --------- */

  /* --------- Functionality to load all association types start --------- */

  getAssociationType() {
    if (localStorage.getItem('access_token')) {
      const url = GlobalSettings.GET_CASE_ASSOCIATION_TYPES;
      this.associationTypes = [];
      this.service.getServiceData(url).then((res: any) => {
        res.forEach(element => {
          if (element.type_name !== 'exemplar') {
            this.associationTypes.push(element);
          }
        });
        this.associationTypes.push({
          type_id: 0,
          type_name: 'isParentOf',
          display_name: 'Is Parent Of'
        });
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }

  /* --------- Functionality to load all association types end --------- */


  /* --------- Functionality called on open of association append settings modal start --------- */

  selectOptions() {
    this.settingsModalOpen.emit(true);
  }

  /* --------- Functionality called on open of association append settings modal end --------- */


  /* --------- Functionality on click of back to search result, emit boolean to display search result and
  search set step to 1 to remove select association component start  --------- */

  navigateToSearchResults() {
    this.clearAssociations();
    setTimeout(() => {
      this.sharedService.backToSearch.next({
        loadSearchResult: true,
        back: false
      });
      this.setStep.emit({ step: 3 });
      this.showBackToSearch = false;
      this.removeOptionsToCopySaved();
    }, 100);
    if (document.getElementById('modal-body-taxonomyAssociation')) {
      document.getElementById('modal-body-taxonomyAssociation').style.overflowY = 'auto';
    }
  }

  /* --------- Functionality on click of back to search result end --------- */

  clearAssociations() {
    this.selectedAssociationType = null;
    this.onTypeSelected();
  }

}
