import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { SharedService } from '../../shared.service';
import Utils from '../../utils';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { ActivatedRoute } from '@angular/router';
import {
  Subscription
} from 'rxjs/Subscription';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss', '../../tree-accordian/tree-reorder-success.scss']
})
export class TreeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  CUSTOM_VIEW_LOCATION = 'customview';
  PUBLIC_REVIEW_LOCATION = 'public_review';
  PROJECT_AUTH_LOCATION = 'project_auth';

  /* Related to taxonomu builder */

  TAXONOMY_BUILDER = 'taxonomy_builder';
  prevTitleVal = ''; // holds document title from taxonomy builder view
  @Output() document_title = new EventEmitter(); // emit document title from taxonomy builder view
  keyPress = false;
  currentNode; // holds the current focused node
  @Input() level: 0;
  @Input() levelLimit = -1; // this is for setting the tree level ristriction
  prevHumanCodeVal = 'undefined'; // holds human code from taxonomy builder view
  prevFullStatementVal = 'undefined'; // holds full statement from taxonomy builder view
  @Output() human_coding_scheme = new EventEmitter(); // emit human code scheme from taxonomy builder view
  @Output() full_statement = new EventEmitter(); // emit full statement from taxonomy builder view
  @Output() metadataType = new EventEmitter(); // holds the metadata type from the dropdown list to emit
  @Output() nodeTypeIdEvent = new EventEmitter(); // holds the node type id from the dropdown list to emit
  @Input() nodetypeList = []; // holds the list of node types to populate in dropdown
  modalTriggerLocation = ''; // holds source location from where modal was triggered
  /* Related to taxonomu builder */

  constructor(private acivatedRoute: ActivatedRoute, private sharedService: SharedService, private dialogService: ConfirmationDialogService) {
    this.hybridSelectedNodes = new EventEmitter<any>();

    this.sharedService.modalTriggerLocation.subscribe((event: any) => {
      if (event && event.modalTriggerLocation) {
        this.modalTriggerLocation = event.modalTriggerLocation;
      } else {
        this.modalTriggerLocation = '';
      }
    });

    this.sharedService.sucessEvent.subscribe((event: any) => {
      if (event && event.type === 'delete_node') {
        this.removeFromList(this.sourceElementSiblings, this.currentActionItem);
      }
    });
  }
  @Input() treeNodes: any; // holds all the tree nodes
  @Input() selectedNodeId; // holds the current selected node id
  @Input() viewLocation; // holds the location informarion where the component is applied
  @Input() showComplaince; // hold the flag to determine whether or not to show complaince status
  charLimit = 100; // holds character limit to trim hc and full statement
  copyPasteFlag = 0; // holds value to display copy icon or paste icon
  sourceElement; // holds copied element
  targetElement; // holds paste destination element
  sourceElementSiblings; // holds siblings for copied element, used to iterate and remove copied element after paste
  @Input() reOrder = false; // holds condition if to show re order icons
  @Input() showNoComment = false; // holds boolean value to denote if close button is hit on submit review modal for public review screen
  @Input() allCommented = false; // holds boolean value to denote if first 2 levels comments are filled for public review screen
  @Input() isAllNode = false; // holds if complete tree option is selected in authoring screen
  @Input() isAddNode = false; // holds if add node button will be shown or not
  @Input() isAddAssociations = false; // holds if add from taxonomy button will be shown or not based on create association permissions
  @Input() isDeleteNode = false; // holds if delete node button will be shown or not
  @Input() searchText = ''; // holds user text input to search in tree
  @Input() searchResultList = []; // holds search result list
  @Input() isFilterApplied = false; // holds flag for filter or search
  @Input() isReset = false; // holds flag for reset tree on clear filter or search
  @Input() preventEdit = false; // holds boolean value to determine if taxonomy is published

  @Output() addNodeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteNodeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() addAssociationEvent: EventEmitter<any> = new EventEmitter<any>(); // holds current node data emiited as event

  @Input() itemComplianceReports;

  firstNode; // holds the first single node
  worker: any;
  nodes = []; // holds array of selected ids while creating project
  partialNodes = []; // holds array of parent ids while creating project
  @Input() documentId = null; // holds document id passed to tree view component for disabling document node
  @Output() hybridSelectedNodes: EventEmitter<any>;
  clicked = null; // holds current clicked item, used for adding active class
  expandCollapse = true; // holds boolean to toggle expand and collapse
  @ViewChildren('autoCompleteNodetype') autoComplete: QueryList<AutoCompleteComponent>;
  autoCompleteTitle = 'Node type';
  currentActionItem = ''; // holds item id on which any action is performed like add or delete
  firstTime = false; // holds boolean if the component is called first time
  showCommentPopup = 'true'; // holds whether comment popup is to be opened
  commentPopupSubscription: Subscription; // holds subscription for comment popup query params
  ngOnInit() {
    this.firstTime = true;
    this.commentPopupSubscription = this.acivatedRoute.queryParams.subscribe((params) => {
      if (params['showCommentPopup'] !== undefined) {
        this.showCommentPopup = params['showCommentPopup'];
      } else {
        this.showCommentPopup = 'false';
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('DP', this.treeNodes);
    // if (changes.itemComplianceReports && changes.itemComplianceReports.currentValue && changes.itemComplianceReports.currentValue.length) {
    // this.setCompliance();
    // }
    if (changes.searchResultList && changes.searchResultList.currentValue && changes.searchResultList.currentValue.length
      && (!changes.isReset || !changes.isReset.currentValue)) {

      if (typeof Worker !== 'undefined') {
        this.worker = new Worker('../../treeService.worker', { type: 'module' });
      }
      if (this.worker) {
        this.worker.onmessage = ({ data }) => {
          this.treeNodes = [];
          console.log('data received from worker in tree', data);
          if (data) {
            this.treeNodes.push(data.taxonomyData);
          }
        };
        this.worker.postMessage({
          data: this.treeNodes[0],
          location: 'utils',
          parameters: {
            searchResultList: this.searchResultList,
            propName: 'isSearchResult',
            isMultipleCall: true
          }
        });
      }


      // this.searchResultList.forEach(node => {
      //   Utils.expandTreeTillSelectedNode(node, this.treeNodes[0], 'isSearchResult');
      // });

    }

    if (this.treeNodes && changes.hasOwnProperty('isReset') && changes['isReset']['currentValue']) {
      setTimeout(() => {
        if (!this.showCommentPopup) {
          this.resetTree();
        }
      }, 500);
    }

    if (this.nodetypeList && this.treeNodes) {
      this.setNodeTypeList(this.treeNodes);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if ((this.viewLocation === this.TAXONOMY_BUILDER || this.viewLocation === 'project_auth')
        && document.getElementById('' + this.treeNodes[0]['id'] + '-node') && this.firstTime) {
        document.getElementById('' + this.treeNodes[0]['id'] + '-node').click();
      }
    }, 1000);
    if (this.treeNodes && this.treeNodes.length === 1) {
      this.firstNode = this.treeNodes[0];
    } else {
      this.firstNode = {
        id: ''
      };
    }
    this.firstTime = false;
  }




  /* --------- Functionality to emit the current selected node object start --------- */

  onNodeSelected(item) {
    if (!item.isOrphanLabel) {
      if (item.id) {
        this.selectedNodeId = item.id;
      }
      // if (item.item_id) {
      //   this.selectedNodeId = item.item_id;
      // }
      item.location = this.viewLocation;
      this.sharedService.treeNodeSelectedEvent.next(item);
    }
  }

  /* --------- Functionality to emit the current selected node object end --------- */


  /* --------- Functionality to add padding on tree nodes start --------- */


  calculatePadding(level) {
    // if (this.viewLocation === this.CUSTOM_VIEW_LOCATION) {
    //   return 0;
    // } else {
    // return Utils.calculatePadding(level, 'treeview');
    // }


    if (this.viewLocation === 'taxonomy_builder') {
      return Utils.calculateTaxonomyBuilderPadding(level, '');
    } else {
      return Utils.calculatePadding(level, 'treeview');
    }
  }

  /* --------- Functionality to add padding on tree nodes end --------- */


  /* --------- Functionality to re order sibliings under same parent start --------- */

  reOrderSiblings(siblings, index, itemId) {
    // console.log('items siblings', JSON.stringify(siblings));
    let sourceElement;
    let targetElement;
    for (const i in siblings) {
      if (siblings[i]['id'] === itemId) {
        sourceElement = siblings[Number(i)];
        sourceElement['reorder'] = 1;
        // this.updateCutReorderValue(sourceElement['children'], 'reorder', 1);
        targetElement = siblings[Number(i) + index];
        siblings[Number(i)] = targetElement;
        siblings[Number(i) + index] = sourceElement;
        break;
      }
    }
    setTimeout(() => {
      sourceElement['reorder'] = 0;
      // this.updateCutReorderValue(sourceElement['children'], 'reorder', 0);
    }, 1300);
    // console.log('target element', targetElement);
    // console.log('source element', sourceElement);
  }

  /* --------- Functionality to re order sibliings under same parent end --------- */


  /* --------- Functionality to cut and paste any node start --------- */

  copySourceElement(item, siblings) {
    this.sourceElement = item;
    this.sourceElementSiblings = siblings;
    item.cut = 1;
    this.updateCutReorderValue(item['children'], 'cut', 1);
  }

  pasteTargetElement(item, targetSiblings) {
    this.targetElement = item;
    this.sourceElement.parent_id = this.targetElement.id;
    this.sourceElement.level = this.targetElement.level + 1;
    console.log('this.targetSiblings', targetSiblings);
    // for(let i=0; i<targetSiblings.length;i++){
    //   if(targetSiblings[i]['id'] === this.targetElement.id )
    //   {
    //     this.sourceElement.list_enumeration = targetSiblings[i]['children'].length + 1; 
    //     this.sourceElement.sequence_number = targetSiblings[i]['children'].length + 1;
    //     break;
    //   }
    // }
    if (this.sourceElement['children'] && this.sourceElement['children'].length > 0) {
      this.updatePadding(this.sourceElement['children'], this.targetElement.level + 2);
    }
    this.removeFromList(this.sourceElementSiblings, this.sourceElement.id);
    item.children.push(this.sourceElement);
    this.targetElement['paste'] = 1;
    this.sourceElement['cut'] = 0;
    this.updateCutReorderValue(this.sourceElement['children'], 'cut', 0);
    setTimeout(() => {
      this.targetElement['paste'] = 0;
      // this.updateCutReorderValue(sourceElement['children'], 'reorder', 0);
    }, 1300);
  }

  /* --------- Functionality to cut and paste any node end --------- */


  /* --------- Functionality to remove the pasted element from previous node start --------- */

  removeFromList(arr, id) {
    if (arr && arr.length) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          arr.splice(i, 1);
        }
      }
    }
  }

  /* --------- Functionality to remove the pasted element from previous node end --------- */


  /* --------- Functionality to update the padding after pasting new node start --------- */

  updatePadding(sourceElement, level) {
    sourceElement.forEach(element => {
      element.level = level;
      if (element.children && element.children.length > 0) {
        this.updatePadding(element.children, level + 1);
      }
    });
  }


  /* --------- Functionality to update the padding after pasting new node end --------- */


  /* --------- Functionality to update the key for cut or paste used for updating css for cut and paste start --------- */

  updateCutReorderValue(items, key, val) {
    items.forEach(element => {
      element[key] = val;
      if (element.children && element.children.length > 0) {
        this.updateCutReorderValue(element.children, key, val);
      }
    });
  }

  /* --------- Functionality to update the key for cut or paste used for updating css for cut and paste end --------- */


  /* --------- Functionality to cancle re order functionality start --------- */

  cancleReorder() {
    this.copyPasteFlag = 0;
    this.sourceElement = null;
    this.targetElement = null;
  }

  /* --------- Functionality to cancle re order functionality end --------- */

  /* --------- Event emitter to add node -------- */
  addNode(item) {
    // if (item.id) {
    //   this.selectedNodeId = item.id;
    // }
    // item.location = this.viewLocation;
    // console.log('addNode tree-accordian ------------- ', item);
    // this.addNodeEvent.emit(item);

    item.location = this.viewLocation;
    item.expand = true;
    // item.level = this.level; // kunal level
    console.log('addNode tree-accordian ------------- ', item);
    // this.selectedEvent.emit(item);
    // this.sharedService.treeAddNodeEvent.next(item);
    if (item.location === 'taxonomy_builder' && item.parent !== 'root') {
      if (item.full_statement.length > 0) {
        this.addNodeEvent.emit(item);
      }
    } else {
      this.addNodeEvent.emit(item);
    }
    this.keyPress = false;
  }

  /* --------- Event emitter to delete node -------- */
  deleteNode(item, sourceElementSiblings) {
    this.sourceElementSiblings = sourceElementSiblings;
    this.currentActionItem = item.id;
    console.log('deleteNode tree-accordian ', item);
    const delMsg = 'Deleting this node will remove it from all the nodes it is referred to. Are you sure you want to continue?';
    if (this.viewLocation !== 'taxonomy_builder') {
      this.dialogService.confirm('Confirm', delMsg)
        .then((confirmed) => {
          if (confirmed) {
            // console.log('deleteNode tree-accordian ', item);
            item.location = this.viewLocation;
            // this.sharedService.treeDeleteNodeEvent.next(item);
            this.deleteNodeEvent.emit(item);
          } else {
            console.log('User cancel the dialog');
          }
        })
        .catch(() => {
          console.log('User dismissed the dialog');
        });
    } else {
      this.deleteNodeEvent.emit(item);
    }
  }

  // parsing style data as Object for binding into ngStyle
  calculateStyle(viewData) {
    if (viewData && this.viewLocation === this.CUSTOM_VIEW_LOCATION) {
      return JSON.parse(viewData).style;
    } else {
      return '';
    }
  }

  /* Show/hide expand button */
  showExpandBtn(item) {
    let show = false;
    if (this.isFilterApplied) {
      show = item.children.some(node => node.isSearchResult); // condition for search in tree
    } else {
      show = item.children && item.children.length > 0; // default condition for all locations tree except customview taxonomy
    }

    if (this.viewLocation === this.CUSTOM_VIEW_LOCATION) {
      if (item.custom_view_data) { // condition for customview taxonomy
        show = show && JSON.parse(item.custom_view_data).allow_expand.toLowerCase() === 'yes';
      }
    }

    return show;
  }

  /* Show/hide children container */
  showChildrenContainer(item) {
    let show = false;
    show = item.children && item.expand; // default condition for all locations tree except customview taxonomy

    if (this.viewLocation === this.CUSTOM_VIEW_LOCATION) {
      if (item.custom_view_data) { // condition for customview taxonomy
        show = show && JSON.parse(item.custom_view_data).allow_expand.toLowerCase() === 'yes';
      }
    }

    return show;
  }

  // To show title from custom view taxonomy node
  getCustomViewTitle(viewData) {
    if (viewData && this.viewLocation === this.CUSTOM_VIEW_LOCATION) {
      return JSON.parse(viewData).title;
    } else {
      return '';
    }
  }

  /* --------- Not used -------- */

  checkHeight() {
    let elemHeight = 0;
    const maxHeight = 80;
    if (document.getElementById('html-content')) {
      const elem = document.getElementById('html-content');
      elemHeight = maxHeight - elem.clientHeight + elem.offsetHeight;
    }
    if (elemHeight > 32) {
      return true;
    } else {
      return false;
    }
  }

  /* --------- Not used -------- */


  /* --------- Functionality to update the document node on focus shift start -------- */

  blurDocumentTitle(item, evt) {
    const elt = evt.target;
    elt.innerText = elt.innerText.replace(/\n/g, ' ');
    const document_title = elt.innerText;
    if (this.prevTitleVal !== document_title.trim()) {
      this.prevTitleVal = document_title;
      const tempObj = [item, document_title];
      this.document_title.emit(tempObj);
    }
  }

  /* --------- Functionality to update the document node on focus shift end -------- */

  onKeyUp(event) {
    this.keyPress = false;
  }


  /* --------- Functionality to emit HC,FS,Doc node for keyboard functionality start -------- */

  onKeyPress(item, event, control) {
    if (item.project_enabled === 0 && this.modalTriggerLocation === 'project') {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const key = event.which || event.keyCode || event.charCode;
      const parentId = this.currentNode.parent_id;
      const itemId = this.currentNode.id;
      if (key === 13 && event.shiftKey) {
        event.preventDefault();
        if (item.level !== this.levelLimit) {
          if (this.keyPress === false) {
            this.keyPress = true;
            if (control === 'humanCodeScheme') {
              this.blurHumanCodingScheme(item, event);
            }
            if (control === 'fullStatement') {
              this.blurFullStatement(item, event);
            }
            if (control === 'document') {
              this.blurDocumentTitle(item, event);
            }
            this.addButtonEvent(itemId);
          }
        }
      } else if (key === 13) {
        event.preventDefault();
        if (this.keyPress === false) {
          this.keyPress = true;
          if (control === 'humanCodeScheme') {
            this.blurHumanCodingScheme(item, event);
          }
          if (control === 'fullStatement') {
            this.blurFullStatement(item, event);
          }
          if (control === 'document') {
            this.blurDocumentTitle(item, event);
          }
          this.addButtonEvent(parentId);
        }
      }
    }
  }

  /* --------- Functionality to emit HC,FS,Doc node for keyboard functionality end -------- */


  /* --------- Functionality to update the HC on focus shift start -------- */

  blurHumanCodingScheme(item, evt) {
    const elt = evt.target;
    elt.innerText = elt.innerText.replace(/\n/g, ' ');
    const human_coding_scheme = elt.innerText;
    // if (this.addNodeFired === false && this.prevHumanCodeVal !== human_coding_scheme.trim())
    if (this.prevHumanCodeVal !== human_coding_scheme.trim()) {
      this.prevHumanCodeVal = human_coding_scheme;
      const tempObj = [item, human_coding_scheme];
      this.human_coding_scheme.emit(tempObj);
    }
    // this.addNodeFired = false;
  }

  /* --------- Functionality to update the HC on focus shift end -------- */


  /* --------- Functionality to update the FS on focus shift start -------- */

  blurFullStatement(item, evt) {
    const elt = evt.target;
    elt.innerText = elt.innerText.replace(/\n/g, ' ');
    const full_statement = elt.innerText;
    if (this.prevFullStatementVal !== full_statement.trim()) {
      this.prevFullStatementVal = full_statement;
      const tempObj = [item, full_statement];
      this.full_statement.emit(tempObj);
    }
  }

  /* --------- Functionality to update the FS on focus shift end -------- */


  /* --------- Functionality to emit current node on click of add start -------- */

  addButtonEvent(buttonId) {
    if (document.getElementById(buttonId + '-add')) {
      document.getElementById(buttonId + '-add').click();
    }
  }

  /* --------- Functionality to emit current node on click of add end -------- */


  /* --------- Functionality to check previous value on current node to decide event emission on focus start -------- */

  onFocus(item) {
    this.currentNode = item;
    this.prevHumanCodeVal = item['human_coding_scheme'];
    this.prevFullStatementVal = item['full_statement'];
  }

  /* --------- Functionality to check previous value on current node to decide event emission on focus end -------- */


  /* --------- Functionality to focus on current node start -------- */

  focusNode(id) {
    if (document.getElementById(id + '-span')) {
      document.getElementById(id + '-span').focus();
    }
  }

  /* --------- Functionality to focus on current node end -------- */


  /* --------- Functionality to update the emit the metadata set and item for current node start -------- */

  selectMetaData(evt, item) {
    const metadataType = evt.title;
    const nodetypeid = evt.node_type_id;
    const tempObj = [item, metadataType];

    this.metadataType.emit(tempObj);
    this.getNodeTypeId(item, nodetypeid);
  }

  getNodeTypeId(item, nodetypeid) {
    const node_type_id = nodetypeid;
    const tempObj = [item, node_type_id];
    this.nodeTypeIdEvent.emit(tempObj);
    // console.log('111', tempObj);
  }

  /* --------- Functionality to update the emit the metadata set and item for current node end -------- */


  /* --------- Functionality to emit current node while adding associations start -------- */

  addAssociation(item) {
    if (item.id) {
      this.onNodeSelected(item);
    }
    this.addAssociationEvent.emit(item);
  }

  /* --------- Functionality to emit current node while adding associations end -------- */

  /* --------- Functionality to update current node status and call update / remove accordingly start --------- */

  getCurrentItem(currentNode, event) {
    if (currentNode.project_enabled === 1) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      console.log('currentNode', currentNode);
      this.clicked = currentNode;
      currentNode.checked = !currentNode.checked;
      if (this.viewLocation === 'create-project') {
        if (currentNode.checked) {
          this.updateNodes(currentNode.value);
        } else {
          this.removeCurrentNode(currentNode.value, currentNode);
        }
      }
    }

  }

  /* --------- Functionality to update current node status and call update / remove accordingly end --------- */


  /* --------- Functionality to select all child nodes and update status and call update method start --------- */

  selectAllChildren(currentNode) {
    if (currentNode.children && currentNode.children.length > 0) {
      this.iterateChildren(currentNode.children);
      const [nodeId, parentId] = currentNode.value.split('::');
      const index = this.nodes.indexOf(nodeId, 0);
      if (index > -1) {
        // this.removeParentNode(nodeId);
      }
    }
  }

  iterateChildren(children) {
    children.forEach(element => {
      if (!element.internalDisabled) {
        if (!element.checked) {
          element.checked = true;
          this.updateNodes(element.value);
        }
      }
      if (element.children && element.children.length > 0) {
        this.iterateChildren(element.children);
      }
    });
  }

  /* --------- Functionality to select all child nodes and update status and call update method end --------- */


  /* --------- Functionality to push selected node in updated nodes object start --------- */

  updateNodes(data) {
    const [nodeId, parentId] = data.split('::');
    // if (this.documentId !== nodeId) {
    // }
    const index = this.nodes.indexOf(nodeId, 0);
    if (index === -1) {
      this.nodes.push(nodeId);
      this.updatePartialNodes(parentId);
    } else {
      return;
    }
  }

  /* --------- Functionality to push selected node in updated nodes object end --------- */


  /* --------- Functionality to push selected node's parent id in partial nodes object start --------- */

  updatePartialNodes(parentId) {
    if (!parentId || parentId === 'undefined') {
      parentId = this.documentId;
    }
    this.partialNodes.push(parentId);
    // this.filterParentNodes();
    this.hybridSelectedNodes.emit({
      nodes: this.nodes,
      partialNodes: this.partialNodes
    });
  }

  /* --------- Functionality to push selected node's parent id in partial nodes object end --------- */


  /* --------- Functionality to remove current node id and corresponding parent id on uncheck start --------- */

  removeCurrentNode(data, currentNode) {
    const [nodeId, parentId] = data.split('::');
    // this.nodes.push(nodeId);
    const index = this.findNodeIndex(nodeId);
    this.nodes = this.nodes.filter(obj => obj !== nodeId);

    delete this.partialNodes[index];
    this.partialNodes = this.partialNodes.filter((el) => el);

    this.hybridSelectedNodes.emit({
      nodes: this.nodes,
      partialNodes: this.partialNodes
    });

  }

  /* --------- Functionality to remove current node id and corresponding parent id on uncheck end --------- */


  /* --------- Functionality to grab current node's index start --------- */

  findNodeIndex(nodeId) {
    return this.nodes.findIndex(item => item === nodeId);
  }

  /* --------- Functionality to grab current node's index end --------- */


  removeParentNode(parentId) {
    delete this.partialNodes[this.partialNodes.findIndex(item => item === parentId)];
    this.partialNodes = this.partialNodes.filter((el) => el);
  }

  /* --------- Functionality to remove nodes for partial nodes if same present in selected node start --------- */

  filterParentNodes() {
    this.nodes.forEach(nodeId => {
      this.partialNodes = this.partialNodes.filter(parent => nodeId !== parent);
    });
  }

  /* --------- Functionality to remove nodes for partial nodes if same present in selected node end --------- */

  checkIfChildrenSelected(nodeId, currentNode) {
    let childSelected = false;
    for (const i in currentNode) {
      if (currentNode.i.internalDisabled === true || currentNode.i.checked === true) {
        childSelected = true;
        break;
      }
    }
    return childSelected;
  }


  /* --------- Functionality to refine and emit final object start --------- */

  emitSelectedNodes() {
    this.filterParentNodes();
    this.makePartialNodesUnique();
    this.hybridSelectedNodes.emit({
      nodes: this.nodes,
      partialNodes: this.partialNodes
    });
  }

  /* --------- Functionality to refine and emit final object end --------- */


  /* --------- Functionality to make partial nodes unique start --------- */

  makePartialNodesUnique() {
    this.partialNodes = this.partialNodes.filter((item, i, ar) => ar.indexOf(item) === i);
  }

  /* --------- Functionality to make partial nodes unique end --------- */


  /* --------- Functionality to update expand collapse button via javascript start --------- */

  // ngAfterViewInit() {
  //   if (document.getElementsByClassName('pull-right form-check-label') && this.viewLocation === 'create-project') {
  //     const element = document.getElementsByClassName('pull-right form-check-label')[0];
  //     if (element) {
  //       // tslint:disable-next-line:max-line-length
  //       element.innerHTML = '<em class="fa fa-compress" aria-hidden="true" id="expandCollapse" title="Collapse"></em> <span id="expandCollapseText" class="cursor-pointer"> Collapse </span>';
  //     }
  //     document.getElementById('expandCollapse').addEventListener('click', () => {
  //       this.updateExpandCollapseButton();
  //     });

  //     document.getElementById('expandCollapseText').addEventListener('click', () => {
  //       this.updateExpandCollapseButton();
  //     });

  //   }
  // }

  updateExpandCollapseButton() {
    this.expandCollapse = !this.expandCollapse;
    let button;
    if (document.getElementById('expandCollapse')) {
      button = document.getElementById('expandCollapse');
    }

    if (!this.expandCollapse) {
      button.innerText = ' Expand';
      button.setAttribute('title', 'Expand');
      button.setAttribute('class', 'fa fa-expand btn-clear');
    } else {
      button.innerText = ' Collapse';
      button.setAttribute('title', 'Collapse');
      button.setAttribute('class', 'fa fa-compress btn-clear');
    }
    this.treeNodes[0].expand = this.expandCollapse;
    // this.iterateChildren1();
  }

  updateExpansion(nodes) {
    nodes.forEach(element => {
      element.expand = this.expandCollapse;
      if (element.children && element.children.length > 0) {
        this.updateExpansion(element.children);
      }
    });
  }

  /* --------- Functionality to update expand collapse button via javascript end --------- */


  /* --------- Functionality to update emittion object at start on open of modal while update start --------- */

  updatedExistingIds(nodeIds, partialNodeIds) {
    this.nodes = nodeIds;
    this.partialNodes = partialNodeIds;
  }

  /* --------- Functionality to update emittion object at start on open of modal while update end --------- */


  // iterateChildren1_(children) {
  //   if (this.worker) {
  //     this.worker.onmessage = ({ data }) => {
  //       console.log('data received from worker', data);
  //       if (data) {
  //         this.worker.postMessage(data);
  //       }
  //     };
  //     this.worker.postMessage(children);
  //   }
  //   setTimeout(() => {
  //     console.log(this.treeNodes);
  //   }, 3000);
  // }
  // iterateChildren1() {
  //   if (this.worker) {
  //     this.worker.onmessage = ({ data }) => {
  //       console.log('data received from worker', data);
  //       if (data) {
  //         this.treeNodes = data;
  //         // this.worker.postMessage(data);
  //       }
  //     };
  //     this.worker.postMessage({ data: this.treeNodes, isExpand: this.expandCollapse });
  //   }
  //   setTimeout(() => {
  //     console.log(this.treeNodes);
  //   }, 3000);
  // }

  getText(value) {
    if (value) {
      const textToShow = value;
      const regexp = new RegExp(this.searchText, 'gi');
      const result = textToShow.match(regexp);
      if (this.searchText && this.searchText.length && textToShow.includes(result)) {
        return textToShow.replace(result, '<mark>' + result + '</mark>');
      } else {
        return value;
      }
    }

  }

  showBullet(item) {
    let show = false;
    if (item && item.children && item.children.length === 0) {
      show = true;
    } else {
      show = this.isFilterApplied && (item.children.every(node => !node.isSearchResult)); // condition for search in tree
    }
    return show;
  }

  onClickedOutside(e: Event, index) {
    for (const element of this.autoComplete.toArray()) {
      if (element.index === index) {
        element.openPanel(false);
        break;
      }
    }
  }

  getDefaultSelection(type) {
    const nodeType = this.nodetypeList ? this.nodetypeList.find(node => node.title === type) : null;
    return nodeType;
  }

  setNodeTypeList(treeData) {
    treeData.forEach(node => {
      node.nodetypeList = [];
      if (node.id || node.item_id) {
        this.nodetypeList.forEach(type => {
          if (node.node_type_id === type.node_type_id || node.metadataType === 'Document') {
            node.nodetypeList.push({
              node_type_id: type.node_type_id,
              title: type.title,
              is_selected: 1
            });
          } else {
            node.nodetypeList.push({
              node_type_id: type.node_type_id,
              title: type.title,
              is_selected: 0
            });
          }
        });
      }

      if (node.children && node.children.length) {
        this.setNodeTypeList(node.children);
      }
    });
  }

  resetTree() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        this.treeNodes = null;
        if (data) {
          this.treeNodes = data.taxonomyData;
          // console.log('data received from worker in project', this.treeNodes);
        }
      };
      this.worker.postMessage({
        data: this.treeNodes,
        location: 'utils',
        parameters: {
          level: 1,
          reset: true
        }
      });
    }
  }

  getTreeData(tree?) {
    if (tree) {
      this.treeNodes = tree;
      return this.treeNodes;
    }
    return this.treeNodes;
  }

  updateItemComplianceReport(itemComplianceReports) {
    this.itemComplianceReports = itemComplianceReports;
  }

  setCompliance() {
    // if (this.isAllNode === false) {
    //   for (let i = 0; i < this.treeNodes.length; i++) {
    //     this.setComplianceStatusForAllNode(this.treeNodes[i]); // setting compliance icon for project taxonomy
    //   }
    // } else {
    if (this.treeNodes) {
      for (let i = 0; i < this.treeNodes.length; i++) {
        this.setComplianceStatusForAllNode(this.treeNodes[i]); // setting compliance icon for complete taxonomy
      }
    }
    // }

  }

  setComplianceStatusForAllNode(data: any) {
    if (data) {
      if (data.children.length > 0) {
        data.compStatus = this.getComplianceStatusForItem(data.id); // 'compStatus' will hold compliance status value
        for (let i = 0; i < data.children.length; i++) {
          this.setComplianceStatusForAllNode(data.children[i]);
        }
      } else {
        data.compStatus = this.getComplianceStatusForItem(data.id);
      }
    }
  }

  /**
   * To get compliance status value for respective item id (status values: 0,1,2)
   * @param itemId
   */
  getComplianceStatusForItem(itemId) {
    if (itemId) {
      for (let i = 0; i < this.itemComplianceReports.length; i++) {
        if (itemId === this.itemComplianceReports[i].item_id) {
          return this.itemComplianceReports[i].status;
        }
      }
    }
    return null;
  }

  ngOnDestroy() {
    if (this.commentPopupSubscription) {
      this.commentPopupSubscription.unsubscribe();
    }
  }

}

