import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Inject, HostListener, AfterViewInit } from '@angular/core';
import Utils from 'src/app/utils';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['../../confirmation-dialog/confirmation-dialog.component.scss', './confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit, OnChanges, AfterViewInit {


  title: string; // holds title of modal
  message: string; // holds message to display of modal
  btnOkText: string; // holds text of button
  btnCancelText: string; // holds text of button
  onlyOk = false; // holds boolean to determine if only ok button is available
  keyboard = false;
  dialogSized = 'sm';
  @Output() acceptEvent = new EventEmitter(); // emit for accept
  @Output() declineEvent = new EventEmitter(); // emit for decline

  constructor() {
    if (document.getElementById('myModal')) {
      (document.getElementById('myModal')).style.zIndex = '1052';
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
  }


  public setData(title: string,
    message: string,
    keyboard: boolean = true,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm' = 'sm',
    onlyOk = false) {
    this.title = title;
    this.message = message;
    this.keyboard = keyboard;
    this.btnOkText = btnOkText;
    this.btnCancelText = btnCancelText;
    this.dialogSized = dialogSize;
    this.onlyOk = onlyOk;
    if (document.getElementById('confirmattentiondialog')) {
      (document.getElementById('confirmattentiondialog')).style.zIndex = '1052';
    }
  }

  /* --------- Functionality to focus on first button of modal start --------- */

  setFocus() {
    if (document.getElementById('NoBtncdConfirm')) {
      (document.getElementById('NoBtncdConfirm')).focus();
    } else if (document.getElementById('NoBtncdConfirm')) {
      document.getElementById('YesBtncdConfirm').focus();
    } else if (document.getElementById('OkBtncdConfirm')) {
      document.getElementById('OkBtncdConfirm').focus();
    }
  }

  /* --------- Functionality to focus on first button of modal end --------- */


  /* --------- Functionality on click of cancle button of modal start --------- */

  decline() {
    this.acceptEvent.emit(false);
  }

  /* --------- Functionality on click of cancle button of modal end --------- */


  /* --------- Functionality on click of yes button of modal start --------- */

  accept() {
    this.acceptEvent.emit(true);
  }

  /* --------- Functionality on click of yes button of modal end --------- */
}