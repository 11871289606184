import { Component, OnInit, EventEmitter, Output, Input, ViewChild, OnDestroy } from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import { TreeDataService } from '../../tree-data.service';
import Utils from '../../utils';
import { CommonService } from '../../common.service';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { SharedService } from '../../shared.service';
import {
  from, Observable
} from 'rxjs';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import {
  Subscription
} from 'rxjs/Subscription';
import { TreeViewComponent } from '../../tree-view/tree-view.component';
import { TreeSearchComponent } from '../tree-search/tree-search.component';
import { CreateProjectTreeComponent } from '../create-project-tree/create-project-tree.component';

@Component({
  selector: 'app-select-taxonomy',
  templateUrl: './select-taxonomy.component.html',
  styleUrls: ['./select-taxonomy.component.scss']
})
export class SelectTaxonomyComponent implements OnInit, OnDestroy {

  isFirstTime = true;
  isSearching = false;
  initialiseSearch = true;
  worker: any;
  LEFT_MIN_SIZE = Utils.LEFT_PANEL_MIN_SIZE; // Left panel min size
  LEFT_MAX_SIZE = Utils.LEFT_PANEL_MAX_SIZE; // Left panel max size
  @Output() setStep: EventEmitter<any>; // holds the event which includes info for the step to toggle view on association modal
  @Output() updatedAssociationsObj: EventEmitter<any>; // holds the all selected node ids
  @Output() settingsModalOpen: EventEmitter<any>; // holds boolean if settings modal is opened
  @Output() removeOptions: EventEmitter<any>; // holds event to remove options to copy
  @Input() selectedTaxonomy; // holds the selected taxonomy details from listing
  @Input() saveButtonClickedForCopy = false;
  @Input() displayText = '';
  @Input() displayOption = '';
  @Input() currentTab;
  @Input() originNodeId = null; // holds id of source node
  @Input() selectedDocumentId = null; // holds document id of source node
  selectedTaxonomyId; // holds selected taxonomy's id, used for calling tree api
  taxonomyData = []; // holds the tree passed to tree view component to render tree
  taxonomyLoaded = false; // holds boolean value to determine if tree is loaded for showing loader
  // textLength = 300; // holds the length to be set for each node's text limit to display
  selectedNodeIds = []; // holds array of node ids selected from tree, for creating associations
  selectedAssociationType = null; // holds selected association from drop down
  documentId = null; // holds document id passed to tree view component for disabling document node

  viewLocation = 'taxonomyDetails';

  itemAssociations: any;
  itemExemplars = [];
  itemAssets = [];
  itemAdditionalMetadata = [];
  itemLinkedNodes = [];
  treeNode: any;
  selectedNode = null;
  selectedTaxonomySourceId = null;
  nodetypeData;
  selectedNodeType = {
    title: '',
    node_type_id: ''
  };
  showAssocciation = false;
  showExemplar = false;
  showAddMetadatas = false;
  showNavigation = true; // holds boolean value for showing up navigation arrow
  relations = []; // holds relation from taxonomy hierarchy

  @Input() isCopy = false; // holds additional options to append or append with child nodes
  @Input() isCopyAssociation = false; // holds additional options to append or append with child nodes
  @Input() optionsToCopySaved = false; // holds flag save is clicked on modal for options to append or append with child nodes
  requiredFullHierarchyFlag = null; // holds requiredFullHierarchyFlag to identify if append or append with childs is selected
  currentItem = null;
  @ViewChild('itemDetailsComponent', { static: false }) itemDetailsComponent: ItemDetailsComponent;
  // @ViewChild('treeView', { static: false }) treeView: TreeViewComponent;
  @ViewChild('treeSearch', { static: false }) treeSearch: TreeSearchComponent;
  @ViewChild('tree', { static: false }) taxonomyTree: CreateProjectTreeComponent;

  windowResizeSubscription: Subscription;
  isFilterApplied = false;
  searchResultList = [];
  isReset = false;
  disableDocument = true;

  constructor(private treeService: TreeDataService, private service: CommonService, private sharedService: SharedService) {
    this.setStep = new EventEmitter<any>();
    this.updatedAssociationsObj = new EventEmitter<any>();
    this.removeOptions = new EventEmitter<any>();
    this.settingsModalOpen = new EventEmitter<any>();
    this.windowResizeSubscription = this.sharedService.windowResizeEvent.subscribe((item: any) => {
      if (item) {
        this.taxonomyDetailsHeightCalculation(0);
      }
    });
  }

  ngOnInit() {

    if (this.selectedTaxonomy) {
      if (document.getElementById('modal-body-taxonomyAssociation')) {
        document.getElementById('modal-body-taxonomyAssociation').style.overflowY = 'auto';
        document.getElementById('modal-body-taxonomyAssociation').style.paddingTop = '0';
        document.getElementById('modal-body-taxonomyAssociation').style.paddingBottom = '0';
      }
      this.onTaxonomySelected(this.selectedTaxonomy);
      this.currentItem = this.selectedTaxonomy.unique_node_id;
      this.getAllMetadata();
    } else {
      if (document.getElementById('modal-body-taxonomyAssociation')) {
        document.getElementById('modal-body-taxonomyAssociation').style.overflowY = 'auto';
      }
    }
  }




  /* --------- Functionality to load taxonomy for selected taxonomy from browse list start --------- */

  onTaxonomySelected(taxonomy) {
    if (taxonomy) {
      this.taxonomyData = [];
      if (this.selectedTaxonomy) {
        this.selectedTaxonomyId = this.selectedTaxonomy.document_id;
      }
      if (this.selectedTaxonomyId) {
        this.taxonomyLoaded = false;
        const newUrl = GlobalSettings.GET_TREE_VIEW_ENHANCED + this.selectedTaxonomyId;
        this.treeService.getTreeData(newUrl, false).then((response: any) => {
          const res = response.parsedTreeNodes;
          this.relations = response.relations;
          /*if (res && res.children) {
            Utils.sortData(res.children);
          }*/
          // if (res.children.length > 0) {
          //   this.treeNode = this.taxonomyData.children[0];
          // }
          this.taxonomyData.push(res);
          // this.iterate(this.taxonomyData[0], 0);

          if (typeof Worker !== 'undefined') {
            this.worker = new Worker('../../treeService.worker', { type: 'module' });
          }

          if (this.worker) {
            this.worker.onmessage = ({ data }) => {
              if (data) {
                this.taxonomyData = data;
                this.taxonomyLoaded = true;
                if (this.taxonomyData[0].is_document === 1) {
                  this.documentId = this.taxonomyData[0].id;
                  this.getSelectedTreeItemDetail(this.documentId);
                  this.sharedService.treeNodeSelectedEvent.next({ id: this.documentId });
                }
                if (this.treeSearch) {
                  this.treeSearch.getFilterData(this.taxonomyData[0]);
                }
                setTimeout(() => {
                  if (document.getElementById('currentNode-' + this.currentItem)) {
                    document.getElementById('currentNode-' + this.currentItem).click();
                  }
                }, 150);
                setTimeout(() => {
                  this.taxonomyDetailsHeightCalculation(0);
                  setTimeout(() => {
                    if (document.getElementById('currentDiv-' + this.currentItem)) {
                      document.getElementById('currentDiv-' + this.currentItem).scrollIntoView(true);
                    }
                  }, 10);
                }, 500);
              }
            };
            this.worker.postMessage(
              {
                data: this.taxonomyData,
                location: 'mergeHumanCodeFullStatement',
                parameters: {
                  expandLevel: Utils.EXPAND_LEVEL,
                  textLength: Utils.textLength
                }
              });
          }
        }).catch(ex => {
          console.log('list of taxonomies ex ', ex);
        });
      } else {
        console.log('onTaxonomySelected id is null');
      }
    } else {
      this.taxonomyData = null;
    }
  }

  iterate(current, depth) {
    if (current && current.children) {
      const children = current.children;
      if (current.id) {
        current.value = current.id + '::' + current.parent_id;;
        current.checked = false;
        current.disabled = false;
        // current.collapsed = depth < Utils.EXPAND_LEVEL ? false : true;
        current.expand = depth <= Utils.EXPAND_LEVEL ? true : false;
        // current.collapsed = false;
      }
      if (current.title) {
        const maxLengthExceed = current.title.length > Utils.textLength ? true : false;
        current.text = current.title.substr(0, Utils.textLength);
        maxLengthExceed ? current.text = current.text + ' ...' : current.text = current.text + '';
      }
      if (!current.title) {
        if (current.full_statement) {
          const maxLengthExceed = current.full_statement.length > Utils.textLength ? true : false;
          current.text = current.human_coding_scheme + ' ' + current.full_statement.substr(0, Utils.textLength);
          maxLengthExceed ? current.text = current.text + ' ...' : current.text = current.text + '';
        } else {
          current.text = current.human_coding_scheme;
        }
      }
      // console.log('------------ ', current['text']);
      for (let i = 0, len = children.length; i < len; i++) {
        this.iterate(children[i], depth + 1);
      }
    }
  }

  /* --------- Functionality to load taxonomy for selected taxonomy from browse list end --------- */


  /* --------- Functionality to capture selected nodeids from taxonomy loaded start --------- */

  onTaxonomyTreeNodeChecked(event) {
    this.selectedNode = event.node.id;
    this.taxonomyData = event.treeData;
    this.selectedNodeIds = event.nodes;
    let present = false;
    // for (const i in this.selectedNodeIds) {
    //   if (i && this.selectedNodeIds[i] === event.node.id) {
    //     present = true;
    //     this.selectedNodeIds.splice(Number(i), 1);
    //     break;
    //   }
    // }
    // if (!present) {
    //   this.selectedNodeIds.push(event.node.id);
    // }
    // this.treeSearch.getFilterData(this.taxonomyData);
    // console.log('updated treedata', this.taxonomyData);
    this.updatedAssociationsObj.emit({
      node: this.selectedNodeIds,
      associationType: this.selectedAssociationType,
      requiredFullHierarchyFlag: this.requiredFullHierarchyFlag,
      relations: this.relations,
      documentId: this.documentId
    });
    // this.getSelectedTreeItemDetail(this.selectedNode);
  }


  onTaxonomyTreeNodeSelected(event) {
    this.sharedService.treeNodeSelectedEvent.next({ id: event.node.id });
    this.getSelectedTreeItemDetail(event.node.id);
  }

  /* --------- Functionality to capture selected nodeids from taxonomy loaded end --------- */





  /* --------- Functionality to capture and emit selected association type start --------- */

  updateAssociationsObj(evt) {
    if (evt.associationType) {
      this.selectedAssociationType = evt.associationType;
    } else {
      this.selectedAssociationType = null;
    }

    this.requiredFullHierarchyFlag = evt.requiredFullHierarchyFlag;
    this.deselectDocument(this.selectedAssociationType);
    this.updatedAssociationsObj.emit({
      node: this.selectedNodeIds,
      associationType: this.selectedAssociationType,
      requiredFullHierarchyFlag: this.requiredFullHierarchyFlag,
      relations: this.relations,
      documentId: this.documentId
    });
  }

  /* --------- Functionality to capture and emit selected association type end --------- */


  /* --------- Functionality to load item details for selected node start --------- */

  getSelectedTreeItemDetail(itemId) {

    let url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + itemId;
    if (itemId === this.documentId) {
      url = GlobalSettings.GET_CFDOC_ITEM_DETAIL + '/' + itemId;
    }
    this.itemAssociations = [];
    this.itemExemplars = [];
    this.itemAssets = [];
    this.itemAdditionalMetadata = [];
    this.itemLinkedNodes = [];
    this.service.getServiceData(url).then((res: any) => {
      if (this.taxonomyData[0] && this.taxonomyData[0].children &&
        this.taxonomyData[0].children[0].is_document && this.taxonomyData[0].children[0].is_document === 1) {
        this.selectedTaxonomySourceId = res.source_document_id;
        res.is_editable = 1;
        if (!res.node_type_id) {
          res.node_type_id = this.taxonomyData[0].children[0].node_type_id;
          res.node_type = this.taxonomyData[0].children[0].title;
        }
        res.source_uuid_id = res.source_document_id;
      } else {
        res.source_uuid_id = res.source_item_id;
      }

      if (res.node_type_id) {
        this.setNodeTypeById(res.node_type_id);
      }

      if (this.itemDetailsComponent) {
        this.itemDetailsComponent.generateFormData(res, this.selectedNodeType.title);
      }
      const rightContainer = document.getElementById('right_container_id');
      if (rightContainer) {
        rightContainer.scrollTop = 0;
      }

      setTimeout(() => {
        this.showAssocciation = true;
        this.showExemplar = itemId === this.documentId ? false : true;
        this.itemAssociations = res.item_associations;
        if (res.exemplar_associations) {
          this.itemExemplars = res.exemplar_associations;
        }
        if (res.assets) {
          this.itemAssets = res.assets;
        }
        if (res.linked_item) {
          this.itemLinkedNodes = res.linked_item;
        }
        if (res.custom_metadata) {
          this.itemAdditionalMetadata = [];
          if (res.custom_metadata) {
            res.custom_metadata.forEach(obj => {
              if (obj.is_additional_metadata === 1) {
                this.itemAdditionalMetadata.push(obj);
              }
            });
          }
        }
        this.showAddMetadatas = true;

      }, 2000);
    }).catch((ex) => {
      console.log('getSelectedTreeItemDetail ', ex);
    });
  }


  /* --------- Functionality to load item details for selected node end --------- */


  /* --------- Functionality to load node type for match of node type for item details start --------- */

  setNodeTypeById(nodeTypeId) {
    if (this.nodetypeData) {
      from(this.nodetypeData)
        .filter((w: any) => w.node_type_id === nodeTypeId)
        .subscribe(result => {
          this.selectedNodeType = JSON.parse(JSON.stringify(result));
        });
    }
  }


  /* --------- Functionality to load node type for match of node type for item details end --------- */


  /* --------- Functionality to fetch all node types start --------- */

  getAllMetadata() {
    const url = GlobalSettings.GET_NODETYPE_LIST;
    this.service.getServiceData(url).then((res: any) => {
      this.nodetypeData = res.nodetype;
    });
  }

  /* --------- Functionality to fetch all node types end --------- */


  /* --------- Functionality to calculate height start --------- */


  taxonomyDetailsHeightCalculation(removeTempHeight = 0) {
    const windowHeight = window.innerHeight;
    let tabHeight = 0;
    let titleHeight = 0;
    let selectTaxonomyRowHeader = 0;
    if (document.getElementById('modal-header-taxonomyAssociation') && document.getElementById('associationsModalHeader')) {
      titleHeight = document.getElementById('modal-header-taxonomyAssociation').offsetHeight +
        document.getElementById('associationsModalHeader').offsetHeight;
    }
    if (document.getElementById('tabContainer')) {
      tabHeight = document.getElementById('tabContainer').clientHeight;
    }
    if (document.getElementById('selectTaxonomyRowHeader')) {
      selectTaxonomyRowHeader = document.getElementById('selectTaxonomyRowHeader').clientHeight;
    }
    const extraHeight = 5;
    const panelHeight = windowHeight - (tabHeight + titleHeight + selectTaxonomyRowHeader + extraHeight) + removeTempHeight;

    if (document.getElementById('left_container_id_association_modal')) {
      document.getElementById('left_container_id_association_modal').style.height = panelHeight + 'px';
      document.getElementById('left_container_id_association_modal').style.overflow = 'auto';
    }
    if (document.getElementById('right_container_id_association_modal')) {
      document.getElementById('right_container_id_association_modal').style.height = panelHeight + 'px';
      document.getElementById('right_container_id_association_modal').style.overflow = 'auto';
    }
  }

  /* --------- Functionality to calculate height end --------- */

  ngOnDestroy() {
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
  }


  /* --------- Functionality to navigate back to browse taxonomy start --------- */

  setPreviousStep(evt) {
    this.setStep.emit(evt);
  }

  /* --------- Functionality to navigate back to browse taxonomy end --------- */


  /* --------- Functionality to capture and emit event to remove or reset copy options start --------- */

  removeCopiedOptions(evt) {
    setTimeout(() => {
      this.taxonomyDetailsHeightCalculation(0);
    }, 5);
    this.removeOptions.emit(evt);
  }

  /* --------- Functionality to capture and emit event to remove or reset copy options end --------- */


  /* --------- Functionality called on open of association append settings modal start --------- */

  selectOptions(event) {
    this.settingsModalOpen.emit(true);
  }

  /* --------- Functionality called on open of association append settings modal end --------- */

  /* ------------------------------------- Tree Search -------------------------------------------- */
  captureSearchEvent(searchData) {
    this.isFilterApplied = searchData.isFilterApplied;
    this.searchResultList = (searchData.filterData);
    this.taxonomyData = searchData.treeData;
    console.log('get tree data', this.taxonomyData);
    if (this.isFilterApplied) {
      this.isReset = false;
    }
    if (!this.isFilterApplied && !this.isReset) {
      // this.getProjectAssociatedNodes();
      this.isReset = true;
    }

  }

  deselectDocument(selectedAssociationType) {
    if (selectedAssociationType && selectedAssociationType.type_name !== 'isChildOf') {
      if (this.taxonomyData && this.taxonomyData[0] && this.taxonomyData[0].checked) {
        this.taxonomyTree.getCurrentItem(this.taxonomyData[0]);
      }
      this.disableDocument = true;
    } else {
      this.disableDocument = false;
    }

  }

  setLoaderEvent(val) {
    this.isFirstTime = val['isFirstTime'];
    // if (!this.initialiseSearch) {
    this.isSearching = val['isLoading'];
    // }
    this.initialiseSearch = false;
  }


}

