import {
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import {
  CommonModule
} from '@angular/common';
import {
  TabComponent
} from '../../tab/tab.component';

import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  SearchListingComponent
} from '../../common/search/search-listing/search-listing.component';

// import {
//   ProtipComponent
// } from '../../help/protip/protip.component';

import {
  DataTableComponent
} from '../../common/data-table/data-table.component';
import {
  PaginationComponent
} from '../../common/pagination/pagination.component';
import {
  ComplianceProgressComponent
} from '../../common/report/compliance-progress/compliance-progress.component';
import {
  ComplianceDetailsComponent
} from '../../common/report/compliance-details/compliance-details.component';
import {
  ProjectNotesComponent
} from '../../project/projects-notes/projects-notes.component';
import {
  NgDragDropModule
} from 'ng-drag-drop';
import {
  PublicReviewComponent
} from '../../common/public-review/public-review.component';
import {
  ExportPublishComponent
} from '../../common/export-publish/export-publish.component';
import {
  ViewTypeComponent
} from '../../common/view-type/view-type.component';
import {
  CommentListComponent
} from '../../common/comment-list/comment-list.component';

import {
  ItemDetailModule
} from '../item-detail/item-detail.module';
import {
  CustomFilterPipe
} from '../../common/custom-filter.pipe';
import {
  AutoCompleteModule
} from '../auto-complete/auto-complete.module';
import { BrowseTaxonomyListComponent } from '../../common/browse-taxonomy-list/browse-taxonomy-list.component';
import { TreeViewComponent } from '../../tree-view/tree-view.component';
import {
  TreeviewModule
} from 'ngx-treeview';
import { MultiSelectDropdownComponent } from 'src/app/common/multi-select-dropdown/multi-select-dropdown.component';
import { TaxonomyComplianceReportComponent } from '../../taxonomy/taxonomy-compliance-report/taxonomy-compliance-report.component';
import { CoverageReportModalComponent } from '../../common/coverage-report-modal/coverage-report-modal.component';
import { SelectAssociationComponent } from '../../common/select-association/select-association.component';
import { PreLoaderModule } from '../preloader/pre-loader.module';
import { TreeSearchComponent } from '../../common/tree-search/tree-search.component';
import { AssociationSettingsComponent } from '../../project/association-settings/association-settings.component';
import { TaxonomyVersionComponent } from '../../versioning/taxonomy-version/taxonomy-version.component';
import { VersionSummaryComponent } from '../../versioning/version-summary/version-summary.component';
import { MetadataVersionComponent } from '../../versioning/metadata-version/metadata-version.component';
import { NodetypeVersionComponent } from '../../versioning/nodetype-version/nodetype-version.component';
import { AssociationVersionComponent } from '../../versioning/association-version/association-version.component';
import { SearchListModule } from '../search-list/search-list.module';
// import 'src/js/multi-select-dropdown';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgDragDropModule.forRoot(),
    AutoCompleteModule,
    TreeviewModule.forRoot(),
    PreLoaderModule,
    SearchListModule,
  ],
  declarations: [TabComponent,
    DataTableComponent,
    PaginationComponent,
    ComplianceProgressComponent,
    ComplianceDetailsComponent,
    ProjectNotesComponent,
    AssociationSettingsComponent,
    PublicReviewComponent,
    ExportPublishComponent,
    ViewTypeComponent,
    CommentListComponent,
    CustomFilterPipe,
    BrowseTaxonomyListComponent,
    TreeViewComponent,
    MultiSelectDropdownComponent,
    TaxonomyComplianceReportComponent,
    CoverageReportModalComponent,
    SelectAssociationComponent,
    TreeSearchComponent,
    TaxonomyVersionComponent,
    VersionSummaryComponent,
    MetadataVersionComponent,
    NodetypeVersionComponent,
    AssociationVersionComponent
  ],
  exports: [
    TabComponent,
    DataTableComponent,
    PaginationComponent,
    ComplianceProgressComponent,
    ComplianceDetailsComponent,
    ProjectNotesComponent,
    AssociationSettingsComponent,
    PublicReviewComponent,
    ExportPublishComponent,
    ViewTypeComponent,
    CommentListComponent,
    NgDragDropModule,
    AutoCompleteModule,
    CustomFilterPipe,
    BrowseTaxonomyListComponent,
    TreeViewComponent,
    TreeviewModule,
    MultiSelectDropdownComponent,
    TaxonomyComplianceReportComponent,
    CoverageReportModalComponent,
    SelectAssociationComponent,
    TreeSearchComponent,
    TaxonomyVersionComponent,
    VersionSummaryComponent,
    MetadataVersionComponent,
    NodetypeVersionComponent,
    AssociationVersionComponent,
    SearchListModule
  ],
  schemas: [NO_ERRORS_SCHEMA]

})
export class Shared3Module { }
