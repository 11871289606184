import {
  Injectable
} from '@angular/core';
import {
  from
} from 'rxjs';
import {
  CommonService
} from './common.service';


@Injectable()
export class AuthenticateUserService {

  constructor(private service: CommonService) { }
  // check project permissions for user role
  public AuthenticateUserRole(userRight) {
    let valid = false;
    if (this.service.getUserRolePermission() !== undefined) {
      from(this.service.getUserRolePermission())
        .filter((x: any) => x.display_name === userRight)
        .subscribe(data => {
          valid = true;
        });
    }
    return valid;
  }

  /// check project permissions for project
  public AuthenticProjectPermissions(projectRight) {
    let valid = false;
    if (this.service.getProjectPermission()) {
      from(this.service.getProjectPermission())
        .filter((x: any) => x.display_name === projectRight)
        .subscribe(data => {
          valid = true;
        });
    }
    return valid;
  }

  // check project permissions for Taxonomy

  public authenticTaxonomy(taxonomy) {
    let valid = false;
    // if (this.service.getTaxonomyPermission()) {
    //   from(this.service.getTaxonomyPermission())
    //     .filter((x: any) => x.display_name === taxonomy)
    //     .subscribe(data => {
    //       valid = true;
    //     });
    // }
    const data = this.service.getTaxonomyPermission();

    if (data) {
      for(let i=0; i<data.length ;i++){
        if(data[i]['display_name'] === taxonomy){
          valid = true;
          break;
        }
      }
    }
    return valid;
  }


  /// check workflow permission

  public AuthenticateWorkFlow(workflowName) {
    let valid = false;
    if (this.service.getUserWorkFlowPermission() !== undefined) {
      from(this.service.getUserWorkFlowPermission())
        .filter((x: any) => x.display_name === workflowName)
        .subscribe(data => {
          valid = true;
        });
    }
    return valid;
  }


  /// check metadata permission
  public AuthenticateMetadata(metadata) {
    let valid = false;
    if (this.service.getMetadataPermission() !== undefined) {
      from(this.service.getMetadataPermission())
        .filter((x: any) => x.display_name === metadata)
        .subscribe(data => {
          valid = true;
        });
    }
    return valid;
  }



  /// check note doc permission
  public AuthenticateNoteDocData(permission) {
    let valid = false;
    if (this.service.getNoteDocPermission() !== undefined) {
      from(this.service.getNoteDocPermission())
        .filter((x: any) => x.display_name === permission)
        .subscribe(data => {
          valid = true;
        });
    }
    return valid;
  }





  /// check metadata set permission
  public AuthenticateMetadataSet(metadata) {
    let valid = false;
    if (this.service.getMetadataSetPermission() !== undefined) {
      from(this.service.getMetadataSetPermission())
        .filter((x: any) => x.display_name === metadata)
        .subscribe(data => {
          valid = true;
        });
    }
    return valid;
  }

  /// check Node Template permission
  public AuthenticateNodeTemplate(metadata) {
    let valid = false;
    if (this.service.getNodeTemplatePermission() !== undefined) {
      from(this.service.getNodeTemplatePermission())
        .filter((x: any) => x.display_name === metadata)
        .subscribe(data => {
          valid = true;
        });
    }
    return valid;
  }

  // check Comment permission

  public AuthenticateComment(metadata, type) {
    let valid = false;
    if (this.service.getCommnetPermission(type) !== undefined) {
      from(this.service.getCommnetPermission(type))
        .filter((x: any) => x.display_name === metadata)
        .subscribe(data => {
          // console.log('viewCommentList==>1123', data);
          valid = true;
        });
    }
    return valid;
  }

  public authenticatePublicReview(name, callback) {
    this.service.getPublicReviewPermission(function (obj) {
      from(obj)
        .filter((x: any) => x.display_name === name)
        .subscribe(data => {
          console.log('authenticatePublicReview==>1123', data);
          callback(true);
          return;
        });
      // callback(false);
    });

  }
}
