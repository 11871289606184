import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import {
  SharedService
} from '../shared.service';
@Component({
  selector: 'app-tree-tab',
  templateUrl: './tree-tab.component.html'
})
export class TreeTabComponent implements OnInit {

  @Output() treeTabSelectedEvent: EventEmitter<any>;
  @Input() currentTreeTab = 'treeview';
  @Input() showExport = true;
  @Output() exportTaxonomy: EventEmitter<any>;
  @Input() canPublicReview = true;
  @Input() viewLocation = '';
  @Input() exactTextNeeded = false;
  @Input() showTooltip = true;
  showTableTab = true;
  preventEdit = false;
  previousSelectedTab = '';
  @Input() treeTabItems;
  constructor(private sharedService: SharedService, ) {
    this.treeTabSelectedEvent = new EventEmitter<any>();
    this.exportTaxonomy = new EventEmitter<any>();
    this.sharedService.preventEdit.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
      }
    });

  }

  ngOnInit() {
  }

  onTreeTabSelected(tab) {
    if (this.previousSelectedTab !== tab) {
      this.currentTreeTab = tab;
      this.previousSelectedTab = this.currentTreeTab;
      this.treeTabSelectedEvent.emit(this.currentTreeTab);
    }
  }

  onExportClick(type) {
    this.exportTaxonomy.emit(type);
  }
}
