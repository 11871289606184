import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import {
    Injectable
} from '@angular/core';
import { SharedService } from '../../shared.service';
@Injectable()
export class SearchFilterDataService {

    dropdownKeys = ['update on', 'metadata set', 'taxonomies', 'updated by',
        'projects', 'adoption status', 'item association type', 'workflows']; // holds the list of dropdown keys //'updated by',
    inputForFilter = []; // holds entire list of filter dropdown contents (all filter flat array)
    dropdownObject = {}; // holds the entire dropdown object

    loadedFilters = {
        'update on': false,
        'metadata set': false,
        taxonomies: false,
        'updated by': false,
        'adoption status': false,
        'item association type': false,
        projects: false,
        workflows: false
    }; // holds boolean if filter is loaded

    constructor(private service: CommonService, private sharedService: SharedService) { }




    /* ---------Generate dropdown object functionality Start --------- */

    createStaticDropdownObj() {
        this.dropdownKeys.forEach(key => {
            this.dropdownObject[key] = [];
            this.inputForFilter.forEach(obj => {
                if (obj.element === key) {
                    this.dropdownObject[key].push(obj);
                }
            });
        });
        return this.dropdownObject;
    }

    /* ---------Generate dropdown object functionality End --------- */






    /* --------- Functionality to fetch all node types start --------- */

    getAllNodeTypes() {
        const url = GlobalSettings.GET_NODETYPE_LIST;
        this.service.getServiceData(url).then((res: any) => {
            res.nodetype.forEach(element => {
                this.inputForFilter.push({
                    filter_type: 'node_type_id',
                    filter_value: element.node_type_id,
                    label: element.title,
                    is_selected: 0,
                    filter_key: '',
                    element: 'metadata set'
                });
            });
            this.loadedFilters['metadata set'] = true;
            this.emitFilterLoadedEvent('metadata set', res);
            // this.fillFilterRawData();
        }).catch((ex) => {
            // this.filterDataLoaded = false;
            console.log('Error caught while fetching metadata set list', ex);
        });
    }

    /* --------- Functionality to fetch all node types end --------- */



    /* --------- Functionality to fetch all users start --------- */

    getUsers() {
        const url = GlobalSettings.GET_USERS;
        this.service.getServiceData(url).then((res: any) => {
            res.UserList.users.forEach(element => {
                this.inputForFilter.push({
                    filter_type: 'updated_by',
                    filter_value: element.user_id,
                    label: element.first_name,
                    is_selected: 0,
                    filter_key: '',
                    element: 'updated by'
                });
            });
            this.loadedFilters['updated by'] = true;
            this.emitFilterLoadedEvent('updated by', res);
        }).catch((ex) => {

        });
    }

    /* --------- Functionality to fetch all users end --------- */

    /* --------- Functionality to fetch updated on duration start --------- */

    updateOn() {
        this.inputForFilter.push({
            filter_type: 'update_on',
            filter_value: 'today',
            label: 'today',
            is_selected: 0,
            filter_key: '',
            element: 'update on'
        }, {
            filter_type: 'update_on',
            filter_value: 'last_seven_days',
            label: 'last seven days',
            is_selected: 0,
            filter_key: '',
            element: 'update on'
        }, {
            filter_type: 'update_on',
            filter_value: 'last_thirty_days',
            label: 'last thirty days',
            is_selected: 0,
            filter_key: '',
            element: 'update on'
        });
        this.loadedFilters['update on'] = true;
        this.emitFilterLoadedEvent('update on', []);
    }

    /* --------- Functionality to fetch updated on duration end --------- */

    /* --------- Functionality to fetch all project start --------- */

    getProjects() {
        const url = GlobalSettings.PROJECT_LIST + '?project_type=1';
        this.service.getServiceData(url).then((res: any) => {
            // this.projects = res.projects;

            res.projects.forEach(element => {

                this.inputForFilter.push({
                    filter_type: 'project_id',
                    filter_value: element.project_id,
                    label: element.project_name,
                    is_selected: 0,
                    filter_key: '',
                    element: 'projects'
                });

            });
            this.loadedFilters.projects = true;
            this.emitFilterLoadedEvent('projects', res);
        }).catch(ex => {
            console.log('********list of taxonomies ex ', ex);
        });
    }

    /* --------- Functionality to fetch all project end --------- */


    /* --------- Functionality to fetch all taxonomies start --------- */

    listTaxonomies() {
        const url = GlobalSettings.TAXONOMY_LIST;
        this.service.getServiceData(url).then((res: any) => {
            res.forEach(element => {
                this.inputForFilter.push({
                    filter_type: 'document_id',
                    filter_value: element.document_id,
                    label: element.title,
                    is_selected: 0,
                    filter_key: '',
                    element: 'taxonomies'
                });
            });
            this.loadedFilters.taxonomies = true;
            this.emitFilterLoadedEvent('taxonomies', res);
            return res;
        }).catch(ex => {
            console.log('list of taxonomies ex ', ex);
        });
    }

    /* --------- Functionality to fetch all taxonomies end --------- */

    /* --------- Functionality to add all adoption status start --------- */

    fillAdoptionStatus() {
        this.inputForFilter.push({
            filter_type: 'adoption_status',
            filter_value: 2,
            label: 'draft',
            is_selected: 0,
            filter_key: '',
            element: 'adoption status',
            type: 'value'
        }, {
            filter_type: 'adoption_status',
            filter_value: 3,
            label: 'adopted',
            is_selected: 0,
            filter_key: '',
            element: 'adoption status',
            type: 'value'
        }, {
            filter_type: 'adoption_status',
            filter_value: 4,
            label: 'deprecated',
            is_selected: 0,
            filter_key: '',
            element: 'adoption status',
            type: 'value'
        });
        this.loadedFilters['adoption status'] = true;
        this.emitFilterLoadedEvent('adoption status', []);
    }

    /* --------- Functionality to add all adoption status end --------- */

    /* --------- Functionality to fetch all association types start --------- */

    getAssociationType() {
        if (localStorage.getItem('access_token')) {
            const url = GlobalSettings.GET_CASE_ASSOCIATION_TYPES;
            this.service.getServiceData(url).then((res: any) => {
                res.forEach(element => {
                    if (element.type_name !== 'exemplar') {
                        this.inputForFilter.push({
                            filter_type: 'item_association_type',
                            filter_value: element.type_id,
                            label: element.type_name,
                            is_selected: 0,
                            filter_key: '',
                            element: 'item association type',
                            type: 'value'
                        });
                    }
                });
                this.loadedFilters['item association type'] = true;
                this.emitFilterLoadedEvent('item association type', res);
            }).catch(ex => {
                console.log('list of taxonomies ex ', ex);
            });
        }
    }

    /* --------- Functionality to fetch all association types end --------- */

    /* --------- Functionality to fetch all workflow start --------- */

    getWorkFlowDetails() {
        const url = GlobalSettings.GET_WORKFLOW;
        this.service.getServiceData(url).then((res: any) => {
            res.WorkflowList.workflows.forEach(element => {
                this.inputForFilter.push({
                    filter_type: 'project_workflow_id',
                    filter_value: element.workflow_id,
                    label: element.name,
                    is_selected: 0,
                    filter_key: '',
                    element: 'workflows',
                    type: 'value'
                });
            });
            this.loadedFilters.workflows = true;
            this.emitFilterLoadedEvent('workflows', res);
        }).catch((ex) => {

        });

    }
    /* --------- Functionality to fetch all workflow start --------- */

    emitFilterLoadedEvent(label, response) {
        this.sharedService.searchFiltersLoaded.next({
            loadedFilters: this.loadedFilters,
            key: label,
            data: response
        });
    }

    /* --------- Functionality to clear raw array holding filter data start --------- */

    clearFilterArray() {
        this.inputForFilter = [];
    }

    /* --------- Functionality to clear raw array holding filter data end --------- */
}
