import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy
} from '@angular/core';
import {
  format
} from 'date-fns';
import {
  GlobalSettings
} from '../../global.settings';
import {
  CommonService
} from '../../common.service';
import Utils from '../../utils';
import {
  AuthenticateUserService
} from '../../authenticateuser.service';
import {
  SharedService
} from '../../shared.service';
import {
  from
} from 'rxjs';
import {
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  Subscription
} from 'rxjs/Subscription';
@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent implements OnInit, OnChanges, OnDestroy {
  commentedNodeList = [];
  filteredCommentedNodeList = [];
  commentBody = false;
  userList = [];
  userName: string;
  assign_to: string;
  comment: string;
  tempAssignCommnet: string;
  commentIndex: any;
  projectId: string;
  assignToPlaceholder: string;
  showHistoryBtn = true;
  viewCommentList = false;
  createCommentButton = false;
  selectedUser: any;
  hasCommentLimit = false;
  showAssignTo = true;
  showReply = true;
  preventEdit = false;
  @Input() itemID: string; // holds project id or taxonomy id
  @Input() itemType: number; // holds type (2 for project, 1 for taxonomy)

  @Input() editComments = true;
  @Input() defaultSelection: any;
  @Output() notificationEvent: EventEmitter<any>;
  @Output() actionEvent: EventEmitter<any>;
  @Input() viewLocation = 'project';
  today = format(new Date(), 'MM/DD/YYYY HH:mm:ss');
  todayDate = new Date().toISOString().split('T')[0];
  current_date = this.todayDate;
  replyToOthers: boolean;
  editOtherComments: boolean;
  editMyComments: boolean;
  delteMyComments: boolean;
  delteOtherComments: boolean;
  changeMyCommentStatus: boolean;
  changeOthersCommentStatus: boolean;
  viewMyComments = true;
  viewOtherComments = true;
  commentActions = [{ // For status dropdown
    name: 'Opened',
    value: 1
  },
  {
    name: 'Need Clarification',
    value: 2
  },
  {
    name: 'Fixed',
    value: 3
  },
  {
    name: 'Closed',
    value: 4
  }
  ];
  currentNodeIndex: any;
  filteredStatus: any = 'all';
  filteredList = [];
  loadingComments = false;
  threadSourceType: any;
  startIndex = 0;
  lastIndex = Utils.PAGINATION_LOWER_LIMIT;
  minItemPerPage;
  expandAll = false;
  collapseAll = true;
  hasAnyComments = false;
  selectedStageId = ''; // holds selected stage from project authoring
  selectedWorkFlowStageEvent: Subscription;
  windowResizeSubscription: Subscription;
  constructor(
    private service: CommonService,
    private AuthenticateUser: AuthenticateUserService,
    private sharedService: SharedService) {
    this.sharedService.preventEdit.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
        if (this.preventEdit) {
          this.editComments = false;
        } else {
          // this.editComments = true;
        }
      }
    });
    this.minItemPerPage = Utils.PAGINATION_LOWER_LIMIT;

    this.selectedWorkFlowStageEvent = this.sharedService.workFlowId.subscribe((event: any) => {
      if (event.workFlowId !== undefined) {
        setTimeout(() => {
          this.selectedStageId = event.currentWorkflowStage;
          this.userRoleRight();
        }, 1000);
      }
    });
    this.windowResizeSubscription = this.sharedService.windowResizeEvent.subscribe((item: any) => {
      if (item) {
        this.getPanelHeight();
      }
    });
  }

  ngOnInit() {
    if (this.defaultSelection) {
      this.filteredStatus = this.defaultSelection;
    }
    this.userName = localStorage.getItem('first_name');
    this.userRoleRight();
    this.getCommentFilterDropdownList();
  }

  ngOnChanges() {
    if (this.itemID && this.itemType) {
      this.getComments(this.itemID, this.itemType);
    }
    if (this.itemID && this.itemType && this.itemType === 2) {
      this.projectId = this.itemID;
      this.getProjectUsers(this.itemID);
    }
  }

  ngOnDestroy() {
    if (this.selectedWorkFlowStageEvent) {
      this.selectedWorkFlowStageEvent.unsubscribe();
    }
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
  }

  /**
   * To get comment list of project or taxonomy
   * @param itemId (project id or taxonomy id)
   * @param itemType (2 for project, 1 for taxonomy)
   */
  getComments(itemId, itemType) {
    this.userRoleRight();
    this.loadingComments = true;
    setTimeout(() => {
      this.today = format(new Date(), 'MM/DD/YYYY HH:mm:ss');
      const url = GlobalSettings.COMMENT_LIST + '/' + itemId + '/' + itemType;
      let commentedNode = {};
      this.expandAll = false;
      this.collapseAll = true;
      this.service.getServiceData(url).then((res: any) => {
        this.commentedNodeList = [];
        if (res && res.length > 0) {
          res.forEach(node => {
            commentedNode = {};
            // this.notificationEvent.emit(res.length > 0 ? true : false);
            commentedNode['node_name'] = node.node_name;
            commentedNode['node_id'] = node.node_id;
            commentedNode['node_updated_at'] = node.node_updated_at;
            commentedNode['threads'] = [];
            if (node.threads && node.threads.length > 0) {
              const tempObj = [];
              node.threads.forEach(element => {
                try {
                  const dateTimeArr = element.updated_at.split(' ');
                  const dateArr = dateTimeArr[0].split('-');
                  element.updated_at = dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0] + ' ' + dateTimeArr[1];
                  element['temp_updated_at'] = format(new Date(element.updated_at), 'MM/DD/YYYY');
                  element['temp_created_at'] = format(new Date(element.created_at), 'MM/DD/YYYY');
                  element.comments.forEach(commentsDate => {
                    const dateTimeArr1 = commentsDate.updated_at.split(' '),
                      dateArr1 = dateTimeArr1[0].split('-');
                    commentsDate.updated_at = dateArr1[1] + '/' + dateArr1[2] + '/' + dateArr1[0] + ' ' + dateTimeArr1[1];
                    commentsDate['temp_comments_updated_at'] = format(new Date(commentsDate.updated_at), 'MM/DD/YYYY');
                  });
                  // element['status'] = 2;
                  // element['assign_to_id'] = '4fa46dbd-e314-40cb-9439-929b94779fd6';
                  tempObj.push(element);
                } catch (ex) {
                  console.log('getComments ', ex);
                }
              });
              tempObj.forEach(result => {
                if (this.viewMyComments && this.viewOtherComments === false) {
                  if (result.created_by === localStorage.getItem('user_id')) {
                    commentedNode['threads'].push(result);
                  }
                } else if (!this.viewMyComments && this.viewOtherComments) {
                  if (result.created_by !== localStorage.getItem('user_id')) {
                    commentedNode['threads'].push(result);
                  }
                } else if (this.viewMyComments && this.viewOtherComments) {
                  commentedNode['threads'].push(result);
                }
                /* else {
                                  commentedNode['threads'].push(result);
                                }*/
              });
              if (commentedNode['threads'] && commentedNode['threads'].length > 0) {
                this.commentedNodeList.push(commentedNode);
                Utils.sortDataArray(this.commentedNodeList, 'node_updated_at', false, false);
              }
            } else {
              // this.actionEvent.emit('no_comments');
            }
          });
        }
        this.filterCommentList();
        console.log(this.commentedNodeList);
        if (this.showAssignTo) {
          this.showHistoryBtn = true;
        }
        this.loadingComments = false;
      }).catch(ex => {
        console.log('comment list ex', ex);
      });
    }, 600);
  }

  userRoleRight() {
    if (localStorage.getItem('system_role_code') === 'PPR00') {

      const self = this;
      this.createCommentButton = false;
      this.AuthenticateUser.authenticatePublicReview('Create comment', function (val) {
        self.createCommentButton = val;
        console.log('Global Create comment', val);
        const createPro = Utils.checkProjectPermission(self.projectId,
          'Create comment', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (createPro.hasProject) {
          self.createCommentButton = createPro.valid;
          console.log('Project Create comment', createPro.valid);
        }
      });

      this.editMyComments = false;
      this.AuthenticateUser.authenticatePublicReview('Edit comment', function (val) {
        self.editMyComments = val;
        const editMyComments = Utils.checkProjectPermission(self.projectId,
          'Edit comment', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (editMyComments.hasProject) {
          self.editMyComments = editMyComments.valid;
        }
      });
      this.changeMyCommentStatus = false;
      this.viewCommentList = false;
      this.AuthenticateUser.authenticatePublicReview('View my comments', function (val) {
        self.viewMyComments = val;
        const viewMyComments = Utils.checkProjectPermission(self.projectId,
          'View my comments', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (viewMyComments.hasProject) {
          self.viewMyComments = viewMyComments.valid;
        }
      });

      this.AuthenticateUser.authenticatePublicReview('View others comments', function (val) {
        self.viewOtherComments = val;
        const viewOthComments = Utils.checkProjectPermission(self.projectId,
          'View others comments', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (viewOthComments.hasProject) {
          self.viewOtherComments = viewOthComments.valid;
        }
      });
      this.viewCommentList = true;
      this.replyToOthers = false;
      this.hasCommentLimit = true;
      this.showHistoryBtn = false;
      this.showAssignTo = false;
      this.showReply = false;
    } else {
      this.hasCommentLimit = false;
      this.showAssignTo = true;
      this.showReply = true;
      // this.viewCommentList = this.AuthenticateUser.AuthenticateComment('View My Comments', this.viewLocation);
      // const viewComment = Utils.checkProjectPermission(this.projectId, 'View My Comments', 'comment_permissions');
      // if (viewComment.hasProject) {
      //   this.viewCommentList = viewComment.valid;
      // }
      const commentPermissionName = this.viewLocation === 'project' ? 'comment_permissions' : 'pacing_guide_permissions';
      if (this.viewLocation !== 'project') {
        this.showAssignTo = false;
      }
      this.viewMyComments = this.AuthenticateUser.AuthenticateComment('View My Comments', this.viewLocation);
      const viewComment = Utils.checkProjectPermission(this.projectId,
        'View My Comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (viewComment.hasProject && this.viewLocation === 'project') {
        this.viewMyComments = viewComment.valid;
      }

      this.viewOtherComments = this.AuthenticateUser.AuthenticateComment('View Others Comments', this.viewLocation);
      const viewOtrComment = Utils.checkProjectPermission(this.projectId,
        'View Others Comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (viewOtrComment.hasProject && this.viewLocation === 'project') {
        this.viewOtherComments = viewOtrComment.valid;
      }

      this.createCommentButton = this.AuthenticateUser.AuthenticateComment('Create Comments', this.viewLocation);
      const createPro = Utils.checkProjectPermission(this.projectId,
        'Create Comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (createPro.hasProject && this.viewLocation === 'project') {
        this.createCommentButton = createPro.valid;
      }
      // console.log('createCommentButton viewMyComments  ', this.viewMyComments, this.viewOtherComments, this.viewLocation);
      this.replyToOthers = this.AuthenticateUser.AuthenticateComment('Reply to others comments', this.viewLocation);
      const replyToOthers = Utils.checkProjectPermission(this.projectId,
        'Reply to others comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      // console.log('replyToOthers ', replyToOthers, this.projectId, this.replyToOthers);
      if (replyToOthers.hasProject && this.viewLocation === 'project') {
        this.replyToOthers = replyToOthers.valid;
      }

      this.editMyComments = this.AuthenticateUser.AuthenticateComment('Edit my comments', this.viewLocation);
      const editMyComments = Utils.checkProjectPermission(this.projectId,
        'Edit my comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (editMyComments.hasProject && this.viewLocation === 'project') {
        this.editMyComments = editMyComments.valid;
      }


      this.editOtherComments = this.AuthenticateUser.AuthenticateComment('Edit others\' comments', this.viewLocation);
      const editOtherComments = Utils.checkProjectPermission(this.projectId,
        'Edit others\' comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (editOtherComments.hasProject && this.viewLocation === 'project') {
        this.editOtherComments = editOtherComments.valid;
      }

      this.delteMyComments = this.AuthenticateUser.AuthenticateComment('Delete my comments', this.viewLocation);
      const delteMyComments = Utils.checkProjectPermission(this.projectId,
        'Delete my comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (delteMyComments.hasProject && this.viewLocation === 'project') {
        this.delteMyComments = delteMyComments.valid;
      }

      this.delteOtherComments = this.AuthenticateUser.AuthenticateComment('Delete others\' comments', this.viewLocation);
      const delteOtherComments = Utils.checkProjectPermission(this.projectId,
        'Delete others\' comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (delteOtherComments.hasProject && this.viewLocation === 'project') {
        this.delteOtherComments = delteOtherComments.valid;
      }
      this.changeMyCommentStatus = this.AuthenticateUser.AuthenticateComment('Change Status of my comment', this.viewLocation);
      const changeMyCommentStatus = Utils.checkProjectPermission(this.projectId,
        'Change Status of my comment', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (changeMyCommentStatus.hasProject && this.viewLocation === 'project') {
        this.changeMyCommentStatus = changeMyCommentStatus.valid;
      }

      this.changeOthersCommentStatus = this.AuthenticateUser.AuthenticateComment('Change Status of others\' comments', this.viewLocation);
      const changeOthersCommentStatus = Utils.checkProjectPermission(this.projectId,
        'Change Status of others\' comments',
        commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (changeOthersCommentStatus.hasProject && this.viewLocation === 'project') {
        this.changeOthersCommentStatus = changeOthersCommentStatus.valid;
      }
    }
  }

  checkCanDelete(comment) {

    const userId = comment.created_by; // this.getUserIdByName(comment.created_by);
    if (userId) {
      if (userId === localStorage.getItem('user_id')) {
        return this.delteMyComments;
      } else {
        return this.delteOtherComments;
      }
    }
  }

  checkCanEdit(comment) {
    const userId = comment.created_by; // this.getUserIdByName(comment.created_by);
    if (userId) {
      if (userId === localStorage.getItem('user_id')) {
        return this.editMyComments;
      } else {
        return this.editOtherComments;
      }
    }
  }

  checkCanChageStatus(comment) {

    const userId = comment.created_by; // this.getUserIdByName(comment.created_by);
    if (userId) {
      if (userId === localStorage.getItem('user_id')) {
        return this.changeMyCommentStatus;
      } else {
        return this.changeOthersCommentStatus;
      }
    }
  }

  checkCanReplyToOthers(comment) {

    const userId = comment.created_by; // this.getUserIdByName(comment.created_by);
    if (userId) {
      // User can always reply to his comments
      if (userId === localStorage.getItem('user_id')) {
        return true;
      } else {
        return this.replyToOthers;
      }
    }
  }

  addReply(nodeIndex, index, data) {
    this.currentNodeIndex = nodeIndex;
    this.commentIndex = index;
    this.commentBody = true;
    if (data.assign_to_id !== '') {
      this.assign_to = data.assign_to_id;
      this.assignToPlaceholder = data.assign_to;
    } else {
      this.assign_to = '';
      this.assignToPlaceholder = 'Assign-to';
    }
    this.threadSourceType = data.thread_source_type;
    setTimeout(() => {
      if (document.getElementById('writeReply' + nodeIndex + index)) {
        document.getElementById('writeReply' + nodeIndex + index).focus();
      }
    }, 300);
  }

  formateDate(commentsDate) {
    let formatedDate: any;
    if (commentsDate['temp_comments_updated_at'] === this.todayDate) {
      formatedDate = format(new Date(commentsDate.updated_at), 'h:mm a') + ', today';
    } else if (format(new Date(commentsDate.updated_at), 'YYYY') === format(new Date(this.todayDate), 'YYYY')) {
      formatedDate = format(new Date(commentsDate.updated_at), 'h:mm a, MMM D');
    } else {
      formatedDate = format(new Date(commentsDate.updated_at), 'h:mm a, MMM D, YYYY');
    }
    return formatedDate;
  }

  formateDate1(commentsDate) {
    let formatedDate: any;
    if (commentsDate['temp_created_at'] === this.todayDate) {
      formatedDate = format(new Date(commentsDate.created_at), 'h:mm a') + ', today';
    } else if (format(new Date(commentsDate.created_at), 'YYYY') === format(new Date(this.todayDate), 'YYYY')) {
      formatedDate = format(new Date(commentsDate.created_at), 'h:mm a, MMM D');
    } else {
      formatedDate = format(new Date(commentsDate.created_at), 'h:mm a, MMM D, YYYY');
    }
    return formatedDate;
  }

  selectAssignTo() {
    if (this.selectedUser && this.selectedUser.user_id) {
      this.assign_to = this.selectedUser.user_id;
      from(this.userList)
        .filter((x: any) => (x.user_id === this.assign_to))
        .subscribe((result: any) => {
          this.tempAssignCommnet = result.role_name;
        });
    }
  }

  getProjectUsers(projectId) {
    this.userList = [];
    this.userList.push(this.getDefaultUser());
    const url = GlobalSettings.GET_All_PROJECT_USER + '/' + projectId;
    this.service.getServiceData(url).then((res: any) => {

      res.forEach(element => {
        this.userList.push(element);
      });
      if (this.userList && this.userList.length > 0) {
        this.selectedUser = this.userList[0];
      }
    }).catch((ex) => {

    });
  }

  getDefaultUser() {
    return {
      'user_id': undefined,
      'user_name': 'Assign-to',
      'user_email': undefined,
      'workflow_stage_role_id': undefined,
      'role_name': undefined
    };
  }

  onCancel() {
    this.assign_to = '';
    this.comment = null;
    if (this.userList && this.userList.length > 0) {
      this.selectedUser = this.userList[0];
    }
  }

  cancelCommentForCard() {
    this.commentBody = false;
    this.commentIndex = null;
    this.onCancel();
  }

  getCommentFilterDropdownList() {
    this.filteredList = [];
    this.filteredList.push({
      name: 'All',
      value: 'all'
    });
    this.commentActions.forEach(elem => {
      const obj = {
        name: elem.name,
        value: elem.name.toLowerCase()
      };
      this.filteredList.push(obj);
    });
    this.filteredList.push({
      name: 'Assigned To Me',
      value: 'assigned_to_me'
    });
  }

  changeStatus(statusValue, thread_id, thread_comment_id, thread_source_type, node_id, statusName?) {
    const url = GlobalSettings.ITEM_COMMENT + '/' + thread_comment_id;
    const tempObj = {
      thread_id: thread_id,
      thread_source_id: node_id,
      status: statusValue,
      updated_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      thread_source_type: thread_source_type
    };

    this.service.putService(url, tempObj).then((res: any) => {
      this.getComments(this.itemID, this.itemType);
      this.sharedService.sucessEvent.next({
        type: 'note_status'
      });
      if (this.actionEvent) {
        this.actionEvent.emit(statusName);
      }
    }).catch(ex => {
      console.log('change comment status ex', ex);
      this.sharedService.sucessEvent.next({
        type: 'error',
        customMsg: ex.msg ? ex.msg : 'Error occured in status changed. Please try again later.'
      });
    });
  }

  filterCommentList() {
    console.log(this.filteredStatus);
    this.hasAnyComments = false;
    this.filteredCommentedNodeList = [];
    if (this.filteredStatus === 'all') {
      this.filteredCommentedNodeList = this.commentedNodeList;
    } else {
      if (this.commentedNodeList && this.commentedNodeList.length > 0) {
        this.commentedNodeList.forEach(node => {
          const commentedNode = {};
          commentedNode['node_name'] = node.node_name;
          commentedNode['node_id'] = node.node_id;
          commentedNode['threads'] = [];
          if (node.threads && node.threads.length > 0) {
            const tempObj = [];
            node.threads.forEach(element => {
              if (this.filteredStatus === 'opened' && element.status === 1) {
                tempObj.push(element);
              } else if (this.filteredStatus === 'need clarification' && element.status === 2) {
                tempObj.push(element);
              } else if (this.filteredStatus === 'fixed' && element.status === 3) {
                tempObj.push(element);
              } else if (this.filteredStatus === 'closed' && element.status === 4) {
                tempObj.push(element);
              } else if (this.filteredStatus === 'assigned_to_me' && element.assign_to_id === localStorage.getItem('user_id')) {
                tempObj.push(element);
              }
            });
            commentedNode['threads'] = tempObj;
            if (commentedNode['threads'] && commentedNode['threads'].length > 0) {
              this.filteredCommentedNodeList.push(commentedNode);
            }
          }
        });
      }
    }
    this.filteredCommentedNodeList.forEach(node => {
      if (node.threads && node.threads.length > 0) {
        node.threads.forEach(element => {
          if (element.comments && element.comments.length > 0 && this.hasAnyComments === false) {
            this.hasAnyComments = true;
          }
        });
      }
    });
    this.getPanelHeight();
    console.log(this.filteredCommentedNodeList);
  }

  addCommentForCard(rowIndex, data, nodeId) {
    this.commentBody = false;
    this.commentIndex = null;

    const tempObj = {
      thread_id: data.thread_id,
      assign_to: this.assign_to,
      status: 1,
      parent_id: data.thread_comment_id,
      comment: this.comment,
      created_by: this.userName,
      updated_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      thread_source_type: this.threadSourceType
      // thread_source_id: data.thread_source_id
    };
    this.postComment(tempObj, 'reply_notes', nodeId);
  }

  postComment(obj, type, itemId) {
    const url = GlobalSettings.ITEM_COMMENT + '/' + itemId;
    this.service.postService(url, obj).then((res: any) => {
      this.getComments(this.itemID, this.itemType);
      this.onCancel();
      this.sharedService.sucessEvent.next({
        type: type
      });
      this.actionEvent.emit(type);
    }).catch(ex => {
      console.log('create comment', ex);
      this.onCancel();
    });
  }

  // For pagination index
  showDataInRange(event) {
    this.startIndex = event.start - 1;
    this.lastIndex = event.last;
  }

  /**
   * On Expand/Collapse all button click
   */
  onExpandAllClick() {
    if (this.expandAll === false || this.collapseAll === true) { // If all comments are not expanded, expand all
      if (document.querySelectorAll('#commentListMain .reply.collapse')) {
        if (document.querySelectorAll('#commentListMain .reply.collapse').length > 0) {
          const htmlElements = document.querySelectorAll('#commentListMain .reply.collapse');
          for (let i = 0; i < htmlElements.length; i++) {
            if (htmlElements[i].childElementCount > 0) {
              htmlElements[i].classList.remove('show');
              htmlElements[i].classList.add('show');
            }
          }
          this.expandAll = true;
          this.collapseAll = false;
        }
      }
    } else { // If all comments are expanded, collapse all
      if (document.querySelectorAll('#commentListMain .reply.collapse')) {
        if (document.querySelectorAll('#commentListMain .reply.collapse').length > 0) {
          const htmlElements = document.querySelectorAll('#commentListMain .reply.collapse');
          for (let i = 0; i < htmlElements.length; i++) {
            if (htmlElements[i].childElementCount > 0) {
              htmlElements[i].classList.remove('show');
            }
          }
          this.expandAll = false;
          this.collapseAll = true;
        }
      }
    }
  }

  /**
   * To check all reply sections expanded or collapsed
   */
  checkAllExpanded(event) {
    setTimeout(() => {
      if (document.querySelectorAll('#commentListMain .reply.collapse')) {
        if (document.querySelectorAll('#commentListMain .reply.collapse').length > 0) {
          const htmlElements = document.querySelectorAll('#commentListMain .reply.collapse');
          this.expandAll = true;
          this.collapseAll = true;
          for (let i = 0; i < htmlElements.length; i++) {
            if (htmlElements[i].childElementCount > 0 && htmlElements[i].classList.contains('show') === false) {
              this.expandAll = false;
              break;
            }
          }
          for (let i = 0; i < htmlElements.length; i++) {
            if (htmlElements[i].childElementCount > 0 && htmlElements[i].classList.contains('show') === true) {
              this.collapseAll = false;
              break;
            }
          }
        }
      } else {
        this.expandAll = false;
        this.collapseAll = false;
      }
    }, 700);
  }

  /**
   * To check if reply section is expanded or collapsed
   * @param id (Reply collapse section id)
   * @returns true/false for expanded/collapse respectively
   */
  checkCommentExpanded(id) {
    if (id) {
      const htmlElements = document.querySelector('#' + id);
      return (htmlElements && htmlElements.classList.contains('show') === true);
    } else {
      return false;
    }
  }

  editCommentTitle(event, thread_comment_id, thread_id, thread_source_type, index, comments, node_id, from) {
    if (event.target.textContent && event.target.textContent.trim().length > 0) {
      // both if and else code is same so commenting the code
      /*if (from === 'reply') {
        if (comments[index].comment === event.target.textContent.trim()) {
          return;
        }
      } else {
        if (comments[index].comment === event.target.textContent.trim()) {
          return;
        }
      }*/
      if (comments[index].comment === event.target.textContent.trim()) {
        return;
      }
    }

    const url = GlobalSettings.ITEM_COMMENT + '/' + thread_comment_id;
    const tempObj = {
      thread_id: thread_id,
      thread_source_id: node_id,
      comment: event.target.textContent,
      updated_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      thread_source_type: thread_source_type
    };

    if (event.target.textContent && event.target.textContent.trim().length > 0) {
      this.service.putService(url, tempObj).then((res: any) => {
        this.getComments(this.itemID, this.itemType);
        this.sharedService.sucessEvent.next({
          type: 'update_comment'
        });
      }).catch(ex => {
        console.log('edit comment', ex);
      });
    } else {
      // this.cancleComment();
    }
  }

  getPanelHeight() {
    const windowHeight = window.innerHeight;
    const extraHeight = 27;
    let titleHeight;
    let tabPanelHeight;
    let commentPanelHeight;
    if (document.getElementById('topNav')) {
      titleHeight = document.getElementById('topNav').offsetHeight;
    }
    if (document.getElementById('tabPanel')) {
      tabPanelHeight = document.getElementById('tabPanel').offsetHeight;
    }

    commentPanelHeight = windowHeight - (titleHeight + tabPanelHeight + extraHeight);

    if (document.getElementById('commentPanel')) {
      document.getElementById('commentPanel').style.height = commentPanelHeight + 'px';
    }
  }
}
