import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from '../../common.service';
import { GlobalSettings } from '../../global.settings';
import Utils from '../../utils';
import { AutoCompleteComponent } from '../../common/auto-complete/auto-complete.component';
import { TreeDataService } from '../../tree-data.service';
import { saveAs } from 'file-saver';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { SharedService } from 'src/app/shared.service';

const caseCompliance = 'CASE Compliance';
const coverage = 'Coverage/Completion';

@Component({
  selector: 'app-taxonomy-compliance-report',
  templateUrl: './taxonomy-compliance-report.component.html',
  styleUrls: ['./taxonomy-compliance-report.component.scss']
})
export class TaxonomyComplianceReportComponent implements OnInit, OnChanges {

  caseCompliance: string = caseCompliance;
  coverage: string = coverage;
  caseComplianceData: any = {
    filledNodes: 0,
    totalNodes: 0,
    progressPercentage: 0,
    fields: [],
    nodes: []
  };
  coverageComplianceData: any = {
    filledNodes: 0,
    totalNodes: 0,
    progressPercentage: 0,
    fields: [],
    nodes: []
  };
  reportFound: boolean;
  loadingCompliance = false;

  listOfColumn = [ // Table column property
    {
      name: 'Name',
      propName: 'title',
      class: '',
      type: 'link',
      redirect: true,
      loadPage: true
    }
  ];
  menuList = [
    {
      id: 1,
      title: 'Compliance Report'
    },
    {
      id: 2,
      title: 'Coverage Report'
    }
  ];
  selectedMenu = this.menuList[0];
  versionList = [];
  selectedFirstVersion = '';
  selectedSecondVersion = '';
  showSpinner = false;

  @Input() taxonomyId;
  @Input() viewLocation = '';
  @Input() taxonomyData;
  @Input() isSubscribed;
  @Input() subscribedVersion = 1;
  @Output() caseData: EventEmitter<any>;
  @Output() coverageData: EventEmitter<any>;
  @Output() loadReportEvent: EventEmitter<any>;

  @ViewChild('autoComplete_first', {
    static: false
  }) autoCompleteFirst: AutoCompleteComponent;
  @ViewChild('autoComplete_second', {
    static: false
  }) autoCompleteSecond: AutoCompleteComponent;

  constructor(private service: CommonService, private dialogService: ConfirmationDialogService, private sharedService: SharedService) {
    this.caseData = new EventEmitter<any>();
    this.coverageData = new EventEmitter<any>();
    this.loadReportEvent = new EventEmitter<any>();
  }

  ngOnInit() {
    this.reportFound = false;
  }

  ngOnChanges() {
    this.getComplianceReport();
    setTimeout(() => {
      reportTabHeightCalculation();
    }, 50);
    if (this.isSubscribed) {
      if (!this.menuList.find(item => item.title === 'Comparison Report')) {
        this.menuList.push({
          id: 3,
          title: 'Comparison Report'
        });
      }
    }
  }

  /**
   * To get compliance report for project
   */
  getComplianceReport() {
    if (this.taxonomyId) {
      this.loadingCompliance = true;
      const url = GlobalSettings.COMPLIANCE_REPORTS + '/' + this.taxonomyId + '/1';
      this.service.getServiceData(url).then((res) => {
        if (res === 'No Compliance Report Found') {
          this.reportFound = false;
        } else {
          this.reportFound = true;
          this.setComplianceData('case-compliance', res);
          this.setComplianceData('coverage-compliance', res);
        }
        this.loadingCompliance = false;
        console.log('getComplianceReport ', res);
      }).catch((ex) => {
        console.log('getComplianceReport ', ex);
      });
    }
  }

  setComplianceData(complianceType: any, res: any) {
    const data: any = {
      filledNodes: 0,
      totalNodes: 0,
      progressPercentage: 0,
      fields: [],
      nodes: []
    };

    if (res) {
      data.filledNodes = (complianceType === 'case-compliance') ? res[complianceType]['case-filled-nodes'] :
        res[complianceType]['custom-filled-nodes'];
      data.totalNodes = (complianceType === 'case-compliance') ? res[complianceType]['case-total-nodes'] :
        res[complianceType]['custom-total-nodes'];
      data.progressPercentage = res[complianceType]['overall-percentage'];
      data.fields = [];
      res[complianceType]['fields'].forEach(element => {
        data.fields.push({ 'name': element.key, 'number': element.value });
      });
      data.nodes = [];
      res[complianceType]['nodes'].forEach(element => {
        data.nodes.push({ 'name': element.key, 'number': element.value });
      });
    }

    if (complianceType === 'case-compliance') {
      this.caseComplianceData = data;
      this.caseData.emit(this.caseComplianceData);
    } else if (complianceType === 'coverage-compliance') {
      this.coverageComplianceData = data;
      this.coverageData.emit(this.coverageComplianceData);
    }
    reportTabHeightCalculation();
  }

  onMenuSelected(event: any) {
    this.selectedMenu = event;
    if (this.selectedMenu.id === 3) {
      this.getPublishedVersionList();
    }
  }

  onExportBtnClick() {
    setTimeout(() => {
      if (document.getElementById('closecoverageReport')) {
        document.getElementById('closecoverageReport').focus();
      }
    }, 400);
    this.taxonomyData = JSON.parse(JSON.stringify(this.taxonomyData));
  }

  getPublishedVersionList() {
    this.showSpinner = true;
    this.versionList = [];
    let url;
    let prefixNeeded = false;
    const pubUri = this.taxonomyData.children[0].uri;
    const sourceDocId = this.taxonomyData.children[0].source_document_id;
    if (pubUri.length) {
      const idx = pubUri.indexOf('ims/case/');
      const serverUri = pubUri.substr(0, idx);
      url = serverUri + 'getVersion?document_id=' + sourceDocId;
    } else {
      const org = JSON.parse(localStorage.getItem('orgDetails'))['orgCode'];
      url = GlobalSettings.VERSION + org + '/getVersion?document_id=' + sourceDocId;
      prefixNeeded = true;
    }

    this.service.getServiceData(url, true, true, prefixNeeded).then((res: any) => {
      if (res.success) {
        this.versionList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].publish_number >= this.subscribedVersion) {
            res.data[i].opt_id = (i + 1);
            res.data[i].display = JSON.stringify(res.data[i].publish_number);
            this.versionList.push(res.data[i]);
          }
        }
      }
      this.showSpinner = false;
    }).catch(ex => {
      this.showSpinner = false;
    });
  }

  onVersionSelected(version, type) {
    if (type === 'first') {
      this.selectedFirstVersion = version;
    } else {
      this.selectedSecondVersion = version;
    }
  }

  onClickedOutside(event, type) {
    if (type === 'first') {
      this.autoCompleteFirst.openPanel(false);
    } else {
      this.autoCompleteSecond.openPanel(false);
    }
  }

  loadComparisonRepot() {
    if (!this.selectedFirstVersion && !this.selectedSecondVersion) {
      const message = 'No previous versions available, this will give you comparison between your draft version with new version available';

      this.sharedService.sucessEvent.next({
        type: 'success',
        customMsg: message
      });

    }
    this.emitLoadReportEvent();

  }

  emitLoadReportEvent() {
    const versionObj = {
      firstObj: this.selectedFirstVersion ? this.selectedFirstVersion['display'] : '',
      secondObj: this.selectedSecondVersion ? this.selectedSecondVersion['display'] : ''
    };
    this.loadReportEvent.emit(versionObj);
  }
}
