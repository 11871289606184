import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  AfterViewChecked,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import Utils from 'src/app/utils';
import {
  TabularTreeSidePaneComponent
} from '../tabular-tree-side-pane/tabular-tree-side-pane.component';
import {
  SharedService
} from 'src/app/shared.service';
import {
  Router
} from '@angular/router';
import {
  GlobalSettings
} from 'src/app/global.settings';
import {
  CommonService
} from 'src/app/common.service';
// import htmlToImage from 'html-to-image';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import {
  Subscription
} from 'rxjs/Subscription';

@Component({
  selector: 'app-node-type-table-view',
  templateUrl: './node-type-table-view.component.html',
  styleUrls: ['./node-type-table-view.component.scss']
})
export class NodeTypeTableViewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  exportAsConfig: ExportAsConfig = {
    type: 'xlsx', // the type you want to download
    elementId: 'myTableElementId', // the id of html/table element
  }

  @Input() headerData: any = [];
  @Input() treeData: any;
  @Input() isCompatible: any;
  @Input() showNavigation = false;
  @Input() showCustomTable = false;
  @ViewChild('tabularTreeSidePaneComponent', {
    static: false
  }) tabularTreeSidePaneComponent: TabularTreeSidePaneComponent;

  nodeTypeData: any = {};
  Object = Object;
  isValidTable = false;
  isValidHeader = false;
  clicked = null;
  openMode = false;
  addedAdditionalMetadata = [];
  isLoading = true;
  nodeTypeDataFlatArray = [];
  worker: any;
  array = [1, 2, 3, 4];
  // items = Array.from({
  //   length: 10000
  // }).map((_, i) => `Item #${i}`);
  object = new Object();
  rowArray = [];
  nodeTypeArray = [];
  rowSpanArray = [];
  columns = {};
  virtualRenderItems = [];
  firstRowColWidth = [];
  headerHeight = '30px';
  windowResizeSubscription: Subscription;
  loadingTable = false;
  currentFocusId = ''; // holds cell id of current clicked cell item

  constructor(private sharedService: SharedService, private router: Router, private service: CommonService, private exportAsService: ExportAsService) {
    this.windowResizeSubscription = this.sharedService.windowResizeEvent.subscribe((item: any) => {
      if (item) {
        this.nodeTypeViewHeightCalculation();
      }
    });
  }
  export() {
    if (this.isValidHeader && this.isValidTable) {
      // download the file using old school javascript method
      let fileName = 'export';
      if (this.treeData && this.treeData['children'] && this.treeData['children'][0] && this.treeData['children'][0]['title']) {
        fileName = this.treeData['children'][0]['title'];
      } else if (this.treeData[0] && this.treeData[0]['children'] && this.treeData[0]['children'][0] && this.treeData[0]['children'][0]['title']) {
        fileName = this.treeData[0]['children'][0]['title'];
      } else {
        fileName = 'export';
      }
      if (fileName.length > 26) {
        fileName = fileName.substr(0, 23) + '...'
      }

      this.exportAsService.save(this.exportAsConfig, fileName).subscribe(() => {
        // save started
      });
      // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
      // this.exportAsService.get(this.config).subscribe(content => {
      //   console.log(content);
      // });
    }

  }
  ngOnInit() {
  }

  ngOnChanges() { }

  configTable(taxonomyData) {
    this.resetData();
    this.treeData = taxonomyData;
    if (this.treeData) {
      this.configureTreeData(this.treeData);
      const treeData = this.treeData.children ? this.treeData.children : this.treeData;
    }
    const headLen = this.headerData.length;
    if (this.headerData && headLen > 1) {
      for (let i = 0; i < headLen; i++) {
        if (i < headLen - 1) {
          if (this.headerData[i].order <= this.headerData[i + 1].order) {
            this.isValidHeader = true;
          } else {
            this.isValidHeader = false;
            break;
          }
        } else {
          if (this.headerData[i].order >= this.headerData[i - 1].order) {
            this.isValidHeader = true;
          } else {
            this.isValidHeader = false;
          }
        }
      }
    } else {
      this.isValidHeader = true;
    }
  }

  ngAfterViewInit() {
    console.log('view loaded');
    // this.alignTable();
  }

  configureTreeData(dataArray) {
    this.loadingTable = true;
    this.resetData();
    const treeData = dataArray.children ? dataArray.children : dataArray;
    this.nodeTypeData = {};
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', {
        type: 'module'
      });
    }
    if (this.worker) {
      this.worker.onmessage = ({
        data
      }) => {
        if (data) {
          this.nodeTypeData = data.nodeTypeData;
          // console.log('data received from worker', this.nodeTypeData);
          console.log('node_type tree data', this.nodeTypeData);
          this.checkValidTable();
          if (this.isValidTable) {
            Object.keys(this.nodeTypeData).forEach(column => {
              const headerIndex = this.headerData.findIndex(col => this.nodeTypeData[column][0].key === col.display_name);
              if (headerIndex !== -1) {
                this.nodeTypeData[column][0]['display'] = true;
                this.nodeTypeData[column][0]['metadataList'] = this.headerData[headerIndex].selectedMetadataList;
                this.nodeTypeData[column][0]['width'] = this.headerData[headerIndex].width;
              } else {
                this.nodeTypeData[column][0]['display'] = false;
              }
              this.nodeTypeArray.push(this.nodeTypeData[column][0]['key'] + '_' + column);
            });
            // setTimeout(() => {
            // this.alignTable();
            // this.generateFlatArray();
            this.createData(treeData);
            // }, 0);
          } else {
            this.loadingTable = false;
          }
          this.showCustomTable = true;
        }
      };
      this.worker.postMessage({
        data: treeData,
        parameters: {
          'nodeTypeData': this.nodeTypeData,
          alignTable: false,
          nodeTypeArray: this.nodeTypeArray
        },
        generateVirualItems: false,
        location: 'node-type-table-view'
      });
    }




    /* treeData.forEach(data => {
       const level = data.level;
       if (level && data.node_type && data.node_type !== 'Document') {
         if (this.nodeTypeData[level]) {
           const index = this.nodeTypeData[level].findIndex(item => item.key === data.node_type);
           if (index !== -1) {
             if (!this.nodeTypeData[level][index].value.find(node => node.id === data.id)) {
               this.nodeTypeData[level][index].value.push(data);
             }
           } else {
             const group = Utils.groupByType(treeData, 'node_type');
             group.forEach(type => {
               if (!this.nodeTypeData[level].find(nodeType => nodeType.key === type.key)) {
                 this.nodeTypeData[level].push(type);
               }
             });
           }
         } else {
           this.nodeTypeData[level] = Utils.groupByType(treeData, 'node_type');
         }
         this.addBlankChild(this.nodeTypeData[level][0].value);
       }
       if (data.is_orphan !== 1 && data.children && data.children.length > 0) {
         this.configureTreeData(data.children);
       }
     });*/


  }

  createData(treeData) {
    this.rowArray = [];
    // treeData.forEach(data => {
    //   const level = data.level;
    //   let obj = new Object();
    //   this.createData_(data, 2);
    // });
    // console.log('Finalarray ', this.rowArray);
    // this.generateVirtualScrollArr();


    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', {
        type: 'module'
      });
    }
    if (this.worker) {
      this.worker.onmessage = ({
        data
      }) => {
        this.virtualRenderItems = null;
        if (data) {
          this.virtualRenderItems = data.virtualRenderItems;
          this.columns = data.columns;
          this.showCustomTable = true;
          setTimeout(() => {
            this.nodeTypeViewHeightCalculation();
            this.columnHeaderWidthCalculation();
          }, 200);
          this.loadingTable = false;
        }
      }
    }
    this.worker.postMessage({
      data: treeData,
      parameters: {
        nodeTypeData: this.nodeTypeData,
        alignTable: false,
        nodeTypeArray: this.nodeTypeArray
      },
      generateVirualItems: true,
      location: 'node-type-table-view'
    });
  }




  createData_(treeData, level) {
    if (treeData.node_type && treeData.node_type !== 'Document') {
      return;
    }
    treeData = treeData.children ? treeData.children : treeData;

    treeData.forEach((data) => {
      level = data.level;
      if (this.nodeTypeData[level] && this.nodeTypeData[level][0] && this.nodeTypeData[level][0].metadataList) {
        this.object[this.nodeTypeData[level][0].key] = {};
        this.nodeTypeData[level][0].metadataList.forEach(element => {
          this.object[this.nodeTypeData[level][0].key][element.internal_name] = data[element.internal_name];
          this.object[this.nodeTypeData[level][0].key]['generateCell'] = true;
          this.object[this.nodeTypeData[level][0].key]['level'] = level;
          this.object[this.nodeTypeData[level][0].key]['item_id'] = data['id'];
          this.columns[this.nodeTypeData[level][0].key + '#||#' + element.display_name + '#||#' + element.internal_name] = this.nodeTypeData[level][0].width;
        });
      }

      // console.log('Finalarray Cr_ ', this.object, level, data);
      if (data.children && data.children.length > 0) {
        const length = data.children.length;
        if (this.nodeTypeData && this.nodeTypeData[level] && length > 1) {
          this.object['rowspan_' + this.nodeTypeData[level][0].key + '#||#' + level] = length;
        }
        this.createData_(data.children, level);

      } else {
        this.updateRowSpan(this.object);
        this.rowArray.push(this.object);
        // console.log('array ********* ', array);
        this.object = new Object();
      }

    });
  }

  updateRowSpan(obj) {
    let tempArr = [];
    Object.keys(obj).forEach(key => {
      if (key.includes('rowspan_')) {
        tempArr.push(key + '#||#' + obj[key]);
      }
    });
    // console.log('#########', tempArr);
    if (tempArr && tempArr.length) {
      tempArr.forEach((element, i) => {

        console.log('OBJ', element, i);
        let values = element.split('#||#');
        let key = values[0];
        let column = values[1] - 1;
        let value = Number(values[2]);
        obj[key] = value;
        let columnCount = tempArr.length + 1;
        let totalSpanCount = Number(value);
        for (let j = column; j <= tempArr.length; j++) {
          if (tempArr[j]) {
            console.log('tempArr', tempArr, j);
            let tempvalues = tempArr[j].split('#||#');
            let newValue = Number(tempvalues[2]);
            totalSpanCount = totalSpanCount + newValue;
          }
        }
        let calculatedSpanValue = totalSpanCount - (columnCount - column - 1);
        obj[key] = calculatedSpanValue;
        // console.log('$$$$$$$$$$$$$', key, 'COLUMN ', column, 'CURRENT SPAN ', value, 'TOTAL COLUMNS ', columnCount, 'totalSpanCount ', totalSpanCount);
      });
    }

  }

  checkValidTable() {
    this.isValidTable = Object.keys(this.nodeTypeData).every(item => this.nodeTypeData[item].length === 1);
  }

  // onNavigationClick(event) {
  //   this.clicked = event.node.id;
  //   this.selectNodeOnNavigation(event.node);
  // }

  openDetails(itemId, level, editable) {
    if (itemId) {
      this.clicked = itemId;
      this.openMode = editable;
      this.setEditable(editable);
      const item = this.nodeTypeData[level][0].value.find(x => x.id === itemId);
      if (this.router.url.indexOf('/taxonomies') >= 0) {
        if (this.tabularTreeSidePaneComponent) {
          this.tabularTreeSidePaneComponent.setData(item);
          this.tabularTreeSidePaneComponent.showHideNav(true);
        }
      } else {
        this.getSelectedItemDrawerDetail(itemId);
      }
      setTimeout(() => {
        this.sharedService.treeNodeSelectedEvent.next(item);
      }, 50);
    }
  }

  setEditable(editable) {
    this.tabularTreeSidePaneComponent.setEditable(editable);
  }

  getSelectedItemDrawerDetail(itemId) {
    let url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + itemId;
    // if (item['is_document'] === 1) {
    //   url = GlobalSettings.GET_CFDOC_ITEM_DETAIL + '/' + itemId;
    // } else
    //  {
    url = GlobalSettings.GET_TREE_ITEM_DETAIL + '/' + itemId;
    // }
    this.service.getServiceData(url).then((res: any) => {
      const newObject = {};
      delete Object.assign(newObject, res, {
        ['itemExemplars']: res['exemplar_associations']
      })['exemplar_associations'];
      delete Object.assign(newObject, res, {
        ['itemAssets']: res['assets']
      })['assets'];
      delete Object.assign(newObject, res, {
        ['itemAssociations']: res['item_associations']
      })['item_associations'];
      this.addedAdditionalMetadata = [];
      if (res.custom_metadata) {
        res.custom_metadata.forEach(obj => {
          if (obj.is_additional_metadata === 1) {
            this.addedAdditionalMetadata.push(obj);
            newObject['addedAdditionalMetadata'] = this.addedAdditionalMetadata;
          }
        });
      }
      if (this.tabularTreeSidePaneComponent) {
        this.tabularTreeSidePaneComponent.setData(newObject);
        this.tabularTreeSidePaneComponent.showHideNav(true);
      }

    }).catch((ex) => {
      console.log('getSelectedTreeItemDetail ', ex);
    });
  }

  addBlankChild(array) {
    let childNodeType = '';
    const arrayWithChild = array.some(node => {
      if (node.children && node.children.length) {
        childNodeType = node.children[0].node_type;
        return true;
      }
    });
    if (arrayWithChild) {
      for (const node of array) {
        if (node.id && node.children && !node.children.length) {
          node.children.push({
            id: node.id + '_blank_child',
            parent_id: node.id,
            node_type: childNodeType,
            level: node.level + 1,
            isBlankChild: true,
            full_statement: '',
            human_coding_sceme: ''
          });
        }
      }
    }

  }

  alignTable() {
    // if (typeof Worker !== 'undefined') {
    //   this.worker = new Worker('../../treeService.worker', { type: 'module' });
    // }
    // if (this.worker) {
    //   this.worker.onmessage = ({ data }) => {
    //     if (data) {
    //       this.nodeTypeData = data.nodeTypeData;
    //     }
    //   }
    // };
    // this.worker.postMessage({ data: null, parameters: { 'nodeTypeData': this.nodeTypeData, alignTable: false, document1: document }, location: 'node-type-table-view' });
    Object.keys(this.nodeTypeData).forEach(column => {
      if (this.nodeTypeData[column][0].display) {
        this.nodeTypeData[column][0].value.forEach(node => {
          this.calculateHeight(node, this.nodeTypeData[column][0].metadataList);
        });
      }
    });
  }
  calculateHeight(node: any, metadataList: any) {
    let totalHeight = 0;
    if (node.children && node.children.length) {
      node.children.forEach(child => {
        if (!child.isBlankChild) {
          const elemId = child.id + '-node-0';
          if (document.getElementById(elemId)) {
            const elemHeight = document.getElementById(elemId).offsetHeight;
            totalHeight += elemHeight;
          }
        }
      });
    } else {
      if (node.isBlankChild) {
        const elemId = node.parent_id + '-node-0';
        if (document.getElementById(elemId)) {
          const elemHeight = document.getElementById(elemId).offsetHeight;
          totalHeight = elemHeight;
        }
      }
    }
    node.height = totalHeight ? totalHeight + 'px' : this.setBiggerHeight(node, metadataList) + 'px';
    // this.checkTextHeight(node, metadataList);
  }

  checkTextHeight(node, metadataList) {
    const elemId = node.id;
    let textHeight = 0;
    for (let i = 0; i < metadataList.length; i++) {
      const domId = elemId + '-button-' + i;
      const element = document.getElementById(domId);
      if (element) {
        textHeight = element.offsetHeight;
        node.height = textHeight > JSON.parse(node.height.replace('px', '')) ? textHeight + 'px' : node.height;
      }
    }

  }

  setBiggerHeight(node, metadataList) {
    const elemeId = node.id;
    let biggerHeight = 0;
    for (let i = 0; i < metadataList.length; i++) {
      const domId = elemeId + '-node-' + i;
      const element = document.getElementById(domId);
      if (element) {
        const currentHeight = element.offsetHeight;
        biggerHeight = currentHeight > biggerHeight ? currentHeight : biggerHeight;
      }
    }
    return biggerHeight;
  }


  generateVirtualScrollArr() {
    this.virtualRenderItems = [];
    this.firstRowColWidth = [];
    this.rowArray.forEach(row => {
      let tableRow = {};
      this.nodeTypeArray.forEach(data => {
        if (row[data] && row[data]['generateCell']) {
          Object.keys(row[data]).forEach(key => {
            if (row[data][key] !== true && key !== 'item_id' && key !== 'level') {
              tableRow[data + '_' + key] = (row[data][key] ? row[data][key] : '') + '#||#' + (row['rowspan_' + data] ? row['rowspan_' + data] : 1) + '#||#' + row[data]['level'] + '#||#' + row[data]['item_id'];
              // tableRow['rowspan_' + data] = row['rowspan_' + data] ? row['rowspan_' + data] : 1;
            }
          });
        }
      });
      this.virtualRenderItems.push(tableRow)
    });
    this.generateColSpan();
    console.log('virtualRenderItems', this.virtualRenderItems);
  }


  generateColSpan() {

    // document is considered as column 1

    const maximumColumns = this.nodeTypeArray.length + 1;

    this.virtualRenderItems.forEach(element => {
      let last_value = Object.values(element)[Object.values(element).length - 1] + '';
      const data = last_value.split('#||#');
      const currentColumnNo = Number(data[2]);
      let colSpan = 1;
      if (currentColumnNo < maximumColumns) {
        console.log('last_value', last_value, currentColumnNo, maximumColumns);
        const nextColumnNo = currentColumnNo + 1
        for (let i = nextColumnNo; i <= maximumColumns; i++) {
          colSpan = colSpan + this.nodeTypeData[i] ? this.nodeTypeData[i][0]['metadataList'].length : 0;
          if (this.nodeTypeData[i]) {
            for (let j = 0; j < this.nodeTypeData[i][0]['metadataList'].length; j++) {
              element[this.nodeTypeData[i][0]['key'] + '_' + j] = '';
            }
          }
        }
        // element[Object.keys(element)[Object.keys(element).length - 1]] = last_value + '#||#' + colSpan;
      }
    });
  }

  resetData() {
    this.rowArray = [];
    this.nodeTypeArray = [];
    this.rowSpanArray = [];
    this.columns = {};
    this.virtualRenderItems = [];
    this.firstRowColWidth = [];
    this.showCustomTable = false;
  }


  nodeTypeViewHeightCalculation() {
    Utils.addBodyScroll();
    if (document.getElementById('metadataTableContainer') && document.getElementById('metadataTableContainer').style.height) {
      document.getElementById('metadataTableContainer').style.height = '0px';
    }
    const windowHeight = window.innerHeight;
    let tabHeight;
    let titleHeight;
    if (document.getElementById('treeTabContainer') && document.getElementById('tabContainer')) {
      tabHeight = document.getElementById('treeTabContainer').offsetHeight + document.getElementById('tabContainer').offsetHeight;
    }
    if (document.getElementsByClassName('authoring-header') && document.getElementsByClassName('authoring-header')[0]) {
      titleHeight = document.getElementsByClassName('authoring-header')[0].clientHeight;
    }
    // tslint:disable-next-line:max-line-length
    const protipHeight = document.getElementById('taxonomyProtip') && document.getElementById('taxonomyProtip').offsetHeight ? document.getElementById('taxonomyProtip').offsetHeight : 0;
    const extraHeight = 61;
    const panelHeight = windowHeight - (tabHeight + titleHeight + protipHeight + extraHeight + Utils.navBarHeight);

    // document.getElementById('right_container_id').style.height = panelHeight + 'px';
    if (document.getElementById('nodeTypeTableContainer')) {
      document.getElementById('nodeTypeTableContainer').style.height = panelHeight + 'px';
    }
  }

  ngOnDestroy() {
    Utils.addBodyScroll();
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
  }

  columnHeaderWidthCalculation() {
    setTimeout(() => {
      if (document.getElementById("myTableElementId")) {
        var x = document.getElementById("myTableElementId");
        if (x.getElementsByTagName('tr')) {
          var rows = x.getElementsByTagName('tr')
        }
        if (rows && rows[2] && rows[2]['cells']) {
          var cells = rows[2]['cells'];
          this.firstRowColWidth = [];
          Array.prototype.map.call(cells, cell => {
            this.firstRowColWidth.push(cell.offsetWidth);
          })
          this.headerHeight = rows[0].clientHeight + 'px';
        }
      }
    }, 1000);
  }

  /* --------- Functionality to add focus on selected node on close of side pane start --------- */

  setFocusCellItem(id, index) {
    this.currentFocusId = id + '-node-' + index;
  }

  updateFocus(closeStatus) {
    if (closeStatus && document.getElementById(this.currentFocusId)) {
      document.getElementById(this.currentFocusId).focus();
    }
  }

  /* --------- Functionality to add focus on selected node on close of side pane end --------- */

}
