import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  IMyDpOptions
} from 'mydatepicker';
import Utils from '../../utils';
// import { TextEditorComponent } from '../../common/text-editor/text-editor.component';
import { SharedService } from '../../shared.service';
import { isObject } from 'util';
import { MultiSelectDropdownComponent } from '../../common/multi-select-dropdown/multi-select-dropdown.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-association-settings',
  templateUrl: './association-settings.component.html',
  styleUrls: ['./association-settings.component.scss', '../../common/tabular-tree-side-pane/tabular-tree-side-pane.component.scss']
})
export class AssociationSettingsComponent implements OnInit {

  itemAssociationId; // holds item association id for selected association
  assTypeId; // holds item association type id for selected association
  metadataList; // holds list of all metadatas in the system
  addedMetdataList; // holds list of metadatas to add
  additionalMetadataList; // holds list of metadatas not in preset
  form: FormGroup; // form instance
  formObj = {}; // form object to be generated dynamically based on values of metadata fields
  // editFields = ['title', 'notes', 'publisher', 'description', 'full_statement', 'alternative_label',
  //   'abbreviated_statement', 'concept_title', 'concept_description', 'license_title', 'license_description', 'license_text',
  //   'subject_title', 'subject_description'];
  // clicked;
  // showEditor = false; // determines is text editor is in open state
  // @ViewChild('textEditor', { static: false }) textEditor: TextEditorComponent;
  Object = Object;
  originNodeId = ''; // hold node item on which associations are made, source taxonomy id
  serviceWithoutTocken = false;
  languages;
  public myDatePickerOptionsStartDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
  };
  moreFilterDropdownSettings = {
    textField: 'label',
    showLabel: false
  }; // settings for more filter dropdown
  serviceType = 'post';
  @ViewChild('multiSelect', {
    static: false
  }) multiSelect: MultiSelectDropdownComponent;
  constructor(
    private service: CommonService,
    private dialogService: ConfirmationDialogService, private fb: FormBuilder, private sharedService: SharedService, private router: Router) {
    this.form = this.fb.group(this.formObj);
  }

  ngOnInit() {
    const currentURL = this.router.url;
    if (currentURL.indexOf('/taxonomies/detail') !== -1) {
      this.serviceWithoutTocken = true;
    }
    if (this.serviceWithoutTocken) {
      // if (this.cacheData['language']) {
      //   this.languages = this.cacheData['language'];
      //   this.selectedLanguage = this.cacheData['language'][0];
      // }
    } else {
      const url = GlobalSettings.LANGUAGE_LIST;
      this.service.getServiceData(url).then((res: any) => {
        this.languages = res;
        // this.selectedLanguage = res[0];
      }).catch((ex) => {
        console.log('Error caught while fetching metadata list', ex);
      });
    }
    this.getMetadataList();
  }

  /* --------- Functionality to fetch entire metadata list start --------- */

  getMetadataList() {
    const url = GlobalSettings.GET_METADATA_LIST;
    this.service.getServiceData(url).then((res) => {
      // this.metadataList = res['metadata'];
      let data = res['metadata'];
      data.forEach(element => {
        element.is_selected = 0;
        element.label = element.name;
      });
      this.metadataList = data;
    }).catch((ex) => {
      console.log('getProjectDetail ', ex);
    });
  }

  /* --------- Functionality to fetch entire metadata list end --------- */


  /* --------- Functionality to open and close slider panel start --------- */

  showHideNav(value) {
    if (value) {
      this.openNav();
    } else {
      this.closeNav();
    }
  }

  openNav() {
    if (document.getElementById('assSidenav')) {
      document.getElementById('assSidenav').style.right = '0px';
    }
    document.getElementById('assSettingsClose').focus();
  }

  closeNav() {
    if (document.getElementById('assSidenav')) {
      document.getElementById('assSidenav').style.right = '-50%';
    }
  }

  /* --------- Functionality to open and close slider panel end --------- */


  /* --------- Functionality to fetch and set existing preset data start --------- */

  setData(node) {
    this.itemAssociationId = node.item_association_id;
    this.assTypeId = node.association_type['type_id'];
    setTimeout(() => {
      this.getMetadataValues();
    }, 500);
  }

  getMetadataValues() {
    this.resetForm();
    const url = GlobalSettings.ASSOCIATION_METADATA + '/' + this.itemAssociationId + '/' + this.assTypeId + '/' + this.originNodeId;
    this.service.getServiceData(url).then((res) => {
      const data = [].concat(res);
      data.forEach(element => {
        this.updateAddedMetadata(element);
      });
      if (data && data[0] && data[0].hasOwnProperty("metadata_value")) {
        this.serviceType = 'put';
      } else {
        this.serviceType = 'post';
      }
      this.addedMetdataList = data;
      Utils.sortDataArray(this.addedMetdataList, 'sort_order', false, true);
      this.updateInitialFormFields();
      this.updateAdditionalMetadataFields();
    }).catch((ex) => {
      console.log('getProjectDetail ', ex);
    });
  }

  /* --------- Functionality to fetch and set existing preset data end --------- */


  /* --------- Functionality to update fetched preset data with details from metadata start --------- */

  updateAddedMetadata(obj) {
    if (this.metadataList && this.metadataList.length) {
      for (let i = 0; i < this.metadataList.length; i++) {
        if (this.metadataList[i]['metadata_id'] === obj.metadata_id) {
          this.metadataList[i]['is_selected'] = 1;
          obj.field_type_id = this.metadataList[i]['field_type_id'];
          if (this.metadataList[i]['field_type_id'] === 7) {
            let tempArr = [];
            this.metadataList[i]['field_possible_values'].forEach(element => {
              tempArr.push(element.value + '#||#' + element.key);
            });
            obj.field_possible_values = tempArr;
          } else {
            if (this.metadataList[i]['internal_name'] === 'language') {
              let tempArr = [];
              this.languages.forEach(element => {
                tempArr.push(element.display_text);
              });
              obj.field_possible_values = tempArr;
            } else {
              obj.field_possible_values = this.metadataList[i]['field_possible_values'];
            }
          }
          obj.internal_name = this.metadataList[i]['internal_name'];
          break;
        }
      }
      return obj;
    }
  }

  /* --------- Functionality to update fetched preset data with details from metadata end --------- */


  /* --------- Functionality to update the array for additional metadata dropdown start --------- */

  updateAdditionalMetadataFields() {
    this.additionalMetadataList = [];
    this.additionalMetadataList = this.metadataList.filter(function (obj) {
      return obj.is_selected !== 1;
    });
  }

  /* --------- Functionality to update the array for additional metadata dropdown end --------- */


  /* --------- Functionality to update form object start --------- */

  updateInitialFormFields() {
    this.formObj = {};
    this.addedMetdataList.forEach(element => {
      if (element.field_type_id === 4) {
        const value = this.updateDateField(element.metadata_value);
        this.formObj[element.metadata_id] = [value, Validators.compose([Validators.required])];
      } else {
        this.formObj[element.metadata_id] = [element.metadata_value, Validators.compose([Validators.required])];
      }
    });
    this.form = this.fb.group(this.formObj);
  }

  updateDateField(date) {
    let fetchedDate = {};
    if (date && date.length > 0) {
      let year, month, day;
      [year, month, day] = date.split('-');
      month = parseInt(month, 10);
      day = parseInt(day, 10);
      fetchedDate = {
        date: {
          year: year,
          month: month,
          day: day
        }
      };
    } else {
      fetchedDate = '';
    }
    return fetchedDate;
  }

  /* --------- Functionality to update form object end --------- */


  /* --------- Functionality to save data from form object start --------- */

  onSave() {
    let tempArr = [];
    Object.keys(this.form.value).forEach((element, index) => {
      let value = '';
      if (this.form.value[element] && (this.form.value[element]['formatted'] || isObject(this.form.value[element]))) {
        if (this.form.value[element]['formatted']) {
          value = this.form.value[element]['formatted'];
        } else if (this.form.value[element]['date']) {
          value = this.getDate(this.form.value[element]);
        }
      } else {
        value = this.form.value[element] ? this.form.value[element] : '';
      }
      tempArr.push({
        'metadata_id': element,
        'is_additional': this.checkIfAdditionalMetadata(element),
        'metadata_value': value,
        'sort_order': index
      });
    });
    const body = {
      'item_association_id': this.itemAssociationId,
      'metadata': tempArr,
      'association_type_id': this.assTypeId,
      'item_id': this.originNodeId
    }
    const url = GlobalSettings.SAVE_ASSOCIATION_METADATA;
    if (this.serviceType === 'post') {
      this.service.postService(url, body).then((res: any) => {
        this.sharedService.sucessEvent.next({
          type: 'add_ass_metadata'
        });
        this.serviceType = 'put';
      }).catch((ex) => {
        console.log('add metadata to association post', ex);
      });
    } else {
      this.service.putService(url, body).then((res: any) => {
        this.sharedService.sucessEvent.next({
          type: 'add_ass_metadata'
        });
        this.serviceType = 'put';
      }).catch((ex) => {
        console.log('add metadata to association put', ex);
      });
    }
  }

  /* --------- Functionality to save data from form object end --------- */


  /* --------- Functionality to check if enter text is a number start --------- */

  isNumber(evt) {
    Utils.isNumber(evt);
  }

  /* --------- Functionality to check if enter text is a number end --------- */


  /* --------- Functionality to capture dropdown elements from additional metadata list start --------- */

  captureKeys(evt) {
    for (let i = 0; i < this.additionalMetadataList.length; i++) {
      if (this.additionalMetadataList[i]['is_selected'] === 1) {
        console.log('MATCH', this.additionalMetadataList[i]);
        let present = false;
        for (let j = 0; j < this.addedMetdataList.length; j++) {
          if (this.addedMetdataList[j]['metadata_id'] === this.additionalMetadataList[i]['metadata_id']) {
            present = true;
            break;
          }
        }
        if (!present) {
          this.addedMetdataList.push({
            'metadata_id': this.additionalMetadataList[i]['metadata_id'],
            'field_type_id': this.additionalMetadataList[i]['field_type_id'],
            'field_possible_values': this.additionalMetadataList[i]['field_possible_values'],
            'internal_name': this.additionalMetadataList[i]['internal_name'],
            "is_mandatory": 0,
            "sort_order": this.addedMetdataList.length + 1,
            "name": this.additionalMetadataList[i]['name'],
            "metadata_value": "",
            "can_delete": 1
          });
        }
      }
    }

    // const evtData = evt.selectedData;

    // let self = this;
    // this.addedMetdataList = this.addedMetdataList.filter(function (obj) {
    //   return (obj.is_mandatory === 0 || self.check(obj, evtData));
    // });

    this.updateInitialFormFields();
    this.updateAdditionalMetadataFields();
    this.multiSelect.clearSelectedDataList();
  }

  check(obj, evtData) {
    let flag = false;
    for (let i = 0; i < evtData.length; i++) {
      if (evtData[i]['metadata_id'] === obj.metadata_id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  /* --------- Functionality to capture dropdown elements from additional metadata list end --------- */


  /* --------- Functionality to remove metadata from form object and update in additional metadata list start --------- */

  removeMetadata(element) {
    this.addedMetdataList = this.addedMetdataList.filter(function (obj) {
      return obj.metadata_id !== element.metadata_id;
    });
    this.updateInitialFormFields();

    for (const key in this.metadataList) {
      if (key && this.metadataList[key]['metadata_id'] === element.metadata_id) {
        this.metadataList[key]['is_selected'] = 0
        break;
      }
    }
    this.updateAdditionalMetadataFields();
    this.deleteMatadataService(element);
  }

  /* --------- Functionality to remove metadata from form object and update in additional metadata list end --------- */


  /* --------- Functionality to extract and generate formatted date from date object start --------- */

  getDate(date) {
    let dateString = '';
    dateString = date['date']['year'] + '-' + date['date']['month'] + '-' + date['date']['day'];
    return dateString;
  }

  /* --------- Functionality to extract and generate formatted date from date object end --------- */


  /* --------- Functionality to delete metadata added start --------- */

  deleteMatadataService(element) {
    const url = GlobalSettings.ASSOCIATION_METADATA + '/' + this.itemAssociationId + '/' + element.metadata_id + '/' + element.is_mandatory;
    this.service.deleteServiceData(url).then((res: any) => {

    }).catch((ex) => {
      console.log('Error while deleting the metadata', ex);
    });
  }

  /* --------- Functionality to delete metadata added end --------- */


  checkIfAdditionalMetadata(id) {
    for (const key in this.addedMetdataList) {
      if (this.addedMetdataList[key] && this.addedMetdataList[key]['metadata_id'] === id) {
        return this.addedMetdataList[key]['is_mandatory'];
      }
    }
  }

  resetForm() {
    this.formObj = {};
    this.addedMetdataList = [];
    this.metadataList.forEach(element => {
      element.is_selected = 0;
    });
  }

  updateAddedMetadataValues(obj, val) {
    for (const key in this.addedMetdataList) {
      if (this.addedMetdataList[key] && this.addedMetdataList[key]['metadata_id'] === obj.metadata_id) {
        this.addedMetdataList[key]['metadata_value'] = val;
      }
    }
  }

  setOriginNodeId(id) {
    this.originNodeId = id;
  }
}
