import { Component, OnInit } from '@angular/core';
import { PagedData } from './paged-data';
import { Page } from "./page";
@Component({
  selector: 'app-custom-table-view',
  templateUrl: './custom-table-view.component.html',
  styleUrls: ['./custom-table-view.component.scss']
})
export class CustomTableViewComponent implements OnInit {
  items = Array.from({ length: 10000 }).map((_, i) => `Item #${i}`);
  timeout;
  page = new Page();
  rows = [{ 'name': 'a', 'gender': 'm', age: 18 }];
  constructor() {
    this.fetch(data => {
      this.rows = data;
      // console.log('rows', this.rows);
    });
  }

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  ngOnInit() {
  }
  onPage(event) {
    // clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/json/data.json`);

    req.onload = () => {
      const rows = JSON.parse(req.response);

      for (const row of rows) {
        row.height = Math.floor(Math.random() * 80) + 50;
      }

      cb(rows);
    };

    req.send();
  }

  getRowHeight(row) {
    return row.height;
  }



}
