import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import {
  GlobalSettings
} from '../../global.settings';
import {
  CommonService
} from '../../common.service';
import {
  ItemDetailsComponent
} from '../../common/item-details/item-details.component';
import {
  ActivatedRoute, NavigationEnd, Router
} from '@angular/router';
import {
  TreeDataService
} from '../../tree-data.service';
import Utils from '../../utils';
import {
  TreeAccordianComponent
} from '../../tree-accordian/tree-accordian.component';
import {
  SharedService
} from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import { TabularTreeViewComponent } from '../../common/tabular-tree-view/tabular-tree-view.component';
import { NodeTypeTableViewComponent } from 'src/app/common/node-type-table-view/node-type-table-view.component';
import { TreeSearchComponent } from 'src/app/common/tree-search/tree-search.component';
import {
  saveAs
} from 'file-saver';
@Component({
  selector: 'app-front-facing-taxonomy-detail',
  templateUrl: './front-facing-taxonomy-detail.component.html'
})
export class FrontFacingTaxonomyDetailComponent implements OnInit, OnDestroy {

  firstLoad = false; // holds flag to indicate first time loading of component
  currentItem = null; // holds current node to be displayed
  itemAssociations: any;  // holds association array of current node
  itemExemplars = []; // holds list of exemplers of current node
  itemAssets = []; // holds list of assets of current node
  itemAdditionalMetadata = []; // holds list of additional metadata of current node
  treeNode: any; // holds a reference of tree data

  showAssocciation; // flag to show association accordion
  showAdditionalMetadata; // flag to show additional metadata accordion
  showExemplar; // flag to show exempler accordion
  showDocument = true; // flag to show document

  urlParams: any; // holds the url params / document id from url
  taxonomyId: any; // holds the current taxonomy id

  taxonomyData: any; // holds the data after calling tree service with children
  selectedTaxonomyId = null;
  taxonomyTitle = ''; // holds full statement of document node
  parentNodeIds = [];
  orgId; // holds organisation id

  selectedNode = {}; // holds object of selected node
  nodeSelectedEvent: Subscription;

  completeTreeData = []; // holds entire tree data
  timeOut = 0;
  hierarchy; // holds tree hierarchy

  treeTabItems = ['detailview', 'tableview'];

  viewType = 'detailview'; // holds the tab selected as view
  viewLocation = 'front-facing';

  listOfColumn = []; // holds list of column headers for table view
  dynamicClass = 'col-sm-7 col-lg-7 col-xl-6';

  listOfClass = [{
    class: 'col-sm-2 col-lg-2 col-xl-3',
  },

  {
    class: 'col-sm-4 col-lg-3 col-xl-3',
  }
  ];
  tabItems = ['taxonomy']; // holds list of tab items
  selectedTab = 'taxonomy'; // holds currently selected tab
  eventSubscription: Subscription;
  currentUrl = null; // holds current URL
  isFilterApplied = false; // holds flag to indicate if any filter is applied on tree data
  searchResultList = []; // holds search result
  isReset = false; // flag to reset search and selected filter criteria
  searchText = ''; // holds user input text to search within tree
  isFirstTime = true; // holds flag to indicate if the search is being performed for the first time
  isSearching = false; // holds flag to show loader while searching
  initialiseSearch = true; // holds flag to initialise search component
  filterCriteriaList = []; // holds the list of filters
  @ViewChild('itemDetailsComponent', { static: false }) itemDetailsComponent: ItemDetailsComponent;
  @ViewChild('treeAccordianRef', { static: false }) treeAccordianComponet: TreeAccordianComponent;
  @ViewChild('tabularView', { static: false }) tabularView: TabularTreeViewComponent;
  @ViewChild('nodeTypeTableView', { static: false }) nodeTypeTableView: NodeTypeTableViewComponent;
  @ViewChild('treeSearch', {
    static: false
  }) treeSearch: TreeSearchComponent;
  showUserFriendlyPage = true; // holds flag to display icon to open user-friendly page
  cacheData = [];
  colHeaders = ['full_statement', 'node_type', 'human_coding_scheme'];
  metadataList = [];
  currentTable = 'supper_default_table';
  selectedNodeId = ''; // holds id of selected node
  worker: any; // holds worker instance
  showExportButton = false; // holds flag to display export button
  listOfFormats = []; // holds list of file formats to be displayed in export drop-down
  exportLoading = false; // holds flag to display loader while exporting taxonomy

  JSON = 'JSON';
  PDF_FORMAT = 'PDF';
  CSV_FORMAT = 'CSV';
  SAMPLE_CSV_FORMAT = 'SAMPLE-CSV';
  JSON_WITH_CUSTOM = 'JSON_WITH_CUSTOM';
  CUSTOM_PDF_FORMAT = 'CUSTOM_PDF';
  DOCX_FORMAT = 'DOCX';

  constructor(
    private service: CommonService, private route: ActivatedRoute, private treeService: TreeDataService,
    private sharedService: SharedService, private router: Router) {

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: any) => {
      if (event.url.indexOf('/taxonomies') >= 0) {
        this.currentUrl = 'taxonomy';
      }
    });
    this.eventSubscription = this.sharedService.activeLink.subscribe((events: any) => {
    });

    if (localStorage.getItem('orgDetails')) {
      this.orgId = JSON.parse(localStorage.getItem('orgDetails'))['orgId'];
      this.route.params.subscribe(params => {
        this.urlParams = params;
        // console.log('ngOnInit TaxonomyDetail subscribe', params);
        this.onTaxonomySelected(params.id);
        this.taxonomyId = params.id;
      });
    }

    this.nodeSelectedEvent = this.sharedService.treeNodeSelectedEvent.subscribe((item: any) => {
      if (item && item.id && this.firstLoad) {
        this.onTreeNodeSelected(item, this.taxonomyData);
      }
    });
  }

  ngOnInit() {
    if (document.querySelector('body')) {
      const element = document.querySelector('body');
      element.classList.remove('hide-vScroll');
    }
    this.checkExportPermission();
    this.getFileFormatList();
    // console.log('ngOnInit TaxonomyDetail');

  }

  // FUNCTION to fetch taxonomy hierarchy after opening the taxonomy
  onTaxonomySelected(id) {
    if (id) {
      // this.progressServie.start();
      this.taxonomyData = null;
      this.isFirstTime = true;
      this.selectedTaxonomyId = id;
      const url = GlobalSettings.FRONT_FACE_TAXONOMY_HIERARCHY + id + '?type=hierarchy&organization_id=' + this.orgId;
      this.treeService.getTreeData(url, true, Utils.EXPAND_LEVEL, false, false, true, false).then((response: any) => {
        const res = response.parsedTreeNodes;
        // console.log('GET_TREE_VIEW ', res);
        if (res && res.children) {
          // Utils.sortData(res.children);
          this.onTreeNodeSelected(res.children[0], res);
          this.hierarchy = res;
          if (this.tabularView && this.viewType === 'tableview' && this.completeTreeData.length && this.taxonomyData) {
            this.tabularView.setTableData(this.taxonomyData);
          }
        }
        this.loadTable();
        this.isFirstTime = true;
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }
  }

  // FUNCTION to perform activities while a tree-node is clicked
  onTreeNodeSelected(item, treeData) {
    // this.treeNode = item;
    this.currentItem = item;
    this.getSelectedTreeItemDetail(item, treeData);
  }

  // FUNCTION to fetch selected node details
  getSelectedTreeItemDetail(item, treeData) {
    const url = GlobalSettings.FRONT_FACE_TAXONOMY_DETAIL + this.taxonomyId + '?type=details&organization_id=' + this.orgId;
    this.firstLoad = true;
    this.itemAssociations = [];
    this.itemExemplars = [];
    this.itemAssets = [];
    this.itemAdditionalMetadata = [];
    if (this.completeTreeData && this.completeTreeData.length === 0) {
      this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {

        this.completeTreeData = res['details'];
        setTimeout(() => {
          this.getNodeDetails(item);
        }, 2000);
        /** */
        this.taxonomyData = treeData;
        this.setDetailData(this.completeTreeData);
        this.treeSearch.getFilterData(this.taxonomyData);
        if (this.nodeTypeTableView) {
          this.nodeTypeTableView.configTable(this.taxonomyData.children);
        }
        if (treeData && treeData.children && treeData.children.length > 0) {
          this.taxonomyTitle = treeData.children[0].full_statement;
          this.treeNode = this.taxonomyData.children[0];
          this.sharedService.setTitleEvent.next({
            type: 'taxonomy',
            title: this.taxonomyTitle,
            document_id: this.selectedTaxonomyId
          });
        }
        if (this.tabularView && this.viewType === 'tableview' && this.completeTreeData.length && this.taxonomyData) {
          this.tabularView.setTableData(this.taxonomyData);
        }
        if (this.nodeTypeTableView && this.viewType === 'tableview' && this.completeTreeData.length &&
          this.currentTable === 'node_type_table') {
          this.nodeTypeTableView.configTable(this.taxonomyData.children);
        }
        try {
          init_detailsViewClick();
          frontFacetaxonomyDetailsHeightCal();
        } catch (error) {
          console.log('Error ', error);
        }
        /** */


        this.timeOut = 0;
      }).catch((ex) => {
        console.log('getSelectedTreeItemDetail front facing taxonomy ', ex);
      });
    } else {
      this.timeOut = 0;
      this.getNodeDetails(item);
    }
  }


  // FUNCTION get node-details for complete tree data
  getNodeDetails(item) {
    let source_item_id = '';

    this.selectedNode = item;
    source_item_id = item['source_item_id'];
    for (const i in this.completeTreeData) {
      if (i) {
        if (this.completeTreeData[i].item_id === item.id) {
          this.selectedNode = this.completeTreeData[i];
          if (this.completeTreeData[i]['source_item_id']) {
            source_item_id = this.completeTreeData[i]['source_item_id'];
          } else {
            source_item_id = '';
          }
          break;
        }
      }
    }

    if (!this.selectedNode['node_type_id']) {
      this.selectedNode['node_type_id'] = this.currentItem.node_type_id;
    }
    if (this.itemDetailsComponent && this.itemDetailsComponent.nodeDetailsComponent) {
      // console.log('this.selectedNode TD ', JSON.stringify(this.selectedNode));
      this.itemDetailsComponent.nodeDetailsComponent.generateForm(this.selectedNode, item.node_type);
      this.cacheData = this.itemDetailsComponent.nodeDetailsComponent.cacheData;
    }

    // this.showAssocciation = false;
    this.showAdditionalMetadata = false;
    this.showExemplar = false;
    this.showDocument = false;

    this.showAssocciation = true;
    this.showAdditionalMetadata = true;
    if (this.taxonomyId === this.selectedNode['item_id']) {
      this.showExemplar = false;
    } else {
      this.showExemplar = true;
    }

    this.showDocument = true;
    this.itemAssociations = item.itemAssociations ? item.itemAssociations : this.selectedNode['item_associations'];
    this.itemExemplars = item.itemExemplars ? item.itemExemplars : this.selectedNode['exemplar_associations'];
    this.itemAssets = item.itemAssets ? item.itemAssets : this.selectedNode['assets'];
    // if (this.selectedNode['exemplar_associations']) {
    //   this.itemExemplars = this.selectedNode['exemplar_associations'];
    // }
    // if (this.selectedNode['assets']) {
    //   this.itemAssets = this.selectedNode['assets'];
    // }
    if (this.selectedNode['custom_metadata']) {
      // this.itemAdditionalMetadata = this.treeNode.custom_metadata;
      this.itemAdditionalMetadata = [];
      if (this.selectedNode['custom_metadata']) {
        this.selectedNode['custom_metadata'].forEach(obj => {
          if (obj.is_additional_metadata === 1) {
            this.itemAdditionalMetadata.push(obj);
          }
        });
      }
    }

    console.log('this.itemAssociations', this.itemAssociations);
    this.selectedNode['source_item_id'] = source_item_id;
    frontFacetaxonomyDetailsHeightCal();
  }

  ngOnDestroy() {
    if (this.nodeSelectedEvent) {
      this.nodeSelectedEvent.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    this.firstLoad = false;
  }

  // FUNCTION to switch tabs between 'detail' & 'table' view
  onTreeTabSelected(tab) {
    this.viewType = tab;
    if (tab === 'detailview') {
      this.selectFirstNode();
    } else {
      if (document.querySelector('body')) {
        const element = document.querySelector('body');
        element.classList.remove('hide-vScroll');
      }
      // this.loadTable();
      if (this.viewType === 'tableview' && this.completeTreeData.length && this.taxonomyData) {
        if (this.currentTable === 'node_type_table' && this.nodeTypeTableView) {
          this.setDetailData(this.completeTreeData);
          this.nodeTypeTableView.configTable(this.taxonomyData);
        } else if (this.currentTable !== 'node_type_table' && this.tabularView) {
          this.tabularView.setTableData(this.taxonomyData);
        }
      }
    }
  }

  // FUNCTION to select first node
  selectFirstNode() {
    setTimeout(() => {
      if (this.hierarchy && this.hierarchy.children[0]
        && this.hierarchy.children[0]['id']
        && document.getElementById(this.hierarchy.children[0]['id'] + '-node')) {
        // document.getElementById(this.hierarchy.children[0]['id'] + '-node').click();
        if (this.tabularView) {
          this.tabularView.onNodeSelected(this.taxonomyData.children[0]);
          this.selectedNodeId = this.taxonomyData.children[0].id;
        }
      }
    }, 700);
  }

  // FUNCTION to set loader
  setLoaderEvent(val) {
    this.isFirstTime = val['isFirstTime'];
    // if (!this.initialiseSearch) {
    this.isSearching = val['isLoading'];
    // }
    this.initialiseSearch = false;
  }

  // FUNCTION to get search result and update view accordingly
  captureSearchEvent(event) {
    this.isFilterApplied = event.isFilterApplied;
    this.searchResultList = event.filterData;
    this.taxonomyData = event.treeData;
    if (this.isFilterApplied) {
      this.isReset = false;
    }
    if (!this.isFilterApplied && !this.isReset) {
      this.isReset = true;
      // this.onTaxonomySelected(this.taxonomyId);
    }

    if (this.isReset) {
      this.resetTree();
    }
    if (this.tabularView && !this.isReset) {
      this.tabularView.setTableData(this.taxonomyData);
    }

  }

  // FUNCTION to construct the list of filters to be displayed
  extractFilterList() {
    this.filterCriteriaList = [];
    if (this.currentTable !== 'metadata_table') {
      const filterObj = {
        display_name: 'Type',
        internal_name: 'node_type',
        metadata_id: 'node_type_1234',
        is_custom: 0
      };
      this.filterCriteriaList.push(filterObj);
    } else {
      this.filterCriteriaList = [...this.listOfColumn];
    }
    setTimeout(() => {
      if (this.treeSearch) {
        this.treeSearch.getFilterData(this.taxonomyData, true);
      }
    }, 500);
  }

  /* --------- Fetch metadata List functionality Started --------- */

  // getMetadataList() {
  //   this.metadataList = this.cacheData['nodetype'][0].metadata;
  //   this.colHeaders.forEach(header => {
  //     this.listOfColumn.forEach(element => {
  //       if (element.propName === header) {
  //         element.name = this.extractMetadataName(header);
  //       }
  //     });
  //   });
  // }

  /* --------- Fetch metadata List functionality Ended --------- */


  /* --------- Functionality to fetch metadata name from internal name start --------- */

  // extractMetadataName(interalName) {
  //   for (const i in this.metadataList) {
  //     if (i && this.metadataList[i]['internal_name'] === interalName) {
  //       return this.metadataList[i]['name'];
  //     } else if (interalName === 'node_type') {
  //       return 'Type';
  //     }
  //   }
  // }

  // FUNCTION to load the column headers and set table-data for table view to display
  loadTable() {
    const featureId = 3;
    const settingId = 3;
    const Url = GlobalSettings.TABLE_CONFIG + '/' + featureId + '/' + settingId + '?organization_id=' + this.orgId +
      '&front_facing=true&document_id=' + this.selectedTaxonomyId;

    this.service.getUserServiceDataWithoutTocken(Url).then((res: any) => {
      console.log('front-face config response', res);
      if (res.last_table_view) {
        this.currentTable = res.last_table_view;
        const currentTable = res.taxonomy_table_views.find(table => table.table_name === res.last_table_view);
        this.listOfColumn = currentTable.table_config;
      } else {
        const defaultList = [];
        res.taxonomy_table_views[0].table_config.forEach(element => {
          const metadata = element.metadata[0];
          if (metadata.internal_name === 'node_type') {
            metadata.metadata_id = 'node_type_1234';
          }
          metadata.propName = metadata.internal_name;
          defaultList.push(metadata);
        });
        this.listOfColumn = defaultList;
      }
      if (this.viewType === 'tableview' && this.completeTreeData.length && this.taxonomyData) {
        if (this.currentTable === 'node_type_table' && this.nodeTypeTableView) {
          this.setDetailData(this.completeTreeData);
          this.nodeTypeTableView.configTable(this.taxonomyData);
        } else if (this.currentTable !== 'node_type_table' && this.tabularView) {
          this.tabularView.setTableData(this.taxonomyData);
        }
      }
      this.extractFilterList();
    }).catch(ex => {
      console.log('loadTable ex ', ex);
    });
  }

  // Function to merge hierarchy with details data

  setDetailData(detailData) {
    for (const j in detailData) {
      if (detailData[j]) {
        // tslint:disable-next-line:max-line-length
        this.setDetail(detailData[j], this.taxonomyData['children'] ? this.taxonomyData['children'] : this.taxonomyData[0]['children']);
      }
    }
  }

  setDetail(data, nodes) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i]) {
        if (nodes[i].id === data['item_id']) {
          nodes[i]['itemAssets'] = data['assets'];
          nodes[i]['itemExemplars'] = data['exemplar_associations'];
          nodes[i]['itemAssociations'] = data['item_associations'];
          // delete data['assets'];
          // delete data['exemplar_associations'];
          // delete data['item_associations'];
          // if (!this.withTocken) {
          // Object.assign(nodes[i], data);

          for (const key of Object.keys(data)) {
            if (key === 'parent_id') { // or obj1.hasOwnProperty(key)
              data[key] = nodes[i][key];
            } else if (data[key]) {
              nodes[i][key] = data[key];
            }
          }
          const addedAdditionalMetadata = [];
          if (data.custom_metadata) {
            data.custom_metadata.forEach(obj => {
              if (obj.is_additional_metadata === 1) {
                addedAdditionalMetadata.push(obj);
                nodes[i]['addedAdditionalMetadata'] = addedAdditionalMetadata;
              }
            });
          }
          // }
          break;
        }
        if (nodes[i].children && nodes[i].children.length > 0) {
          this.setDetail(data, nodes[i].children);
        }
      }
    }
  }

  // FUNCTION to reset tree data after search reset

  resetTree() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('../../treeService.worker', { type: 'module' });
    }
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        this.taxonomyData = null;
        if (data) {
          this.taxonomyData = data.taxonomyData;
          // console.log('data received from worker in project', this.treeNodes);
          if (this.tabularView) {
            this.tabularView.setTableData(this.taxonomyData);
          }
        }
      };
      this.worker.postMessage({
        data: this.taxonomyData,
        location: 'utils',
        parameters: {
          level: 1,
          reset: true
        }
      });
    }
  }

  // FUNCTION to check export permission

  checkExportPermission() {
    const url = GlobalSettings.EXPORT_PERMISSION_URL + '?organization_id=' + this.orgId;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      if (res) {
        this.tabItems.push('export');
        this.showExportButton = true;
      } else {
        const index = this.tabItems.findIndex(tab => tab === 'export');
        if (index > -1) {
          this.tabItems.splice(index, 1);
          this.showExportButton = false;
        }
      }
    });
  }


  // FUNCTION to switch tab

  onTabSelected(tab) {
    this.selectedTab = tab.toLowerCase();
    if (tab.toLowerCase() === 'taxonomy') {
      this.selectFirstNode();
    }
  }


  // FUNCTION to get list of file formats

  getFileFormatList() {
    const url = GlobalSettings.EXPORT_TAXONOMY_URL + '?document_id=' + this.selectedTaxonomyId;
    this.service.getUserServiceDataWithoutTocken(url).then((res: any) => {
      this.listOfFormats = res;
    });
  }


  // FUNCTION to export taxonomy in desired format

  onExportTaxonomy(type) {
    const url = GlobalSettings.DOWNLOAD_TAXONOMY_URL + '?document_id=' + this.selectedTaxonomyId + '&file_type=' + type;
    let contentType;
    switch (type) {
      case 'PDF':
        contentType = 'application/pdf';
        break;
      case 'JSON':
      case 'CUSTOM_JSON':
        contentType = 'text/plain';
        break;
      case 'CSV':
        contentType = 'text/html';
        break;

    }
    // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    this.service.downloadServiceWithoutToken(url, type === this.CUSTOM_PDF_FORMAT ? this.PDF_FORMAT : type).then((res: any) => {
      this.saveToFileSystem(res, type, contentType);
    });
  }

  private saveToFileSystem(response, fileType, contentType) {
    const type = fileType === 'CUSTOM_JSON' ? 'JSON' : fileType;
    let blob = null;
    if (type === this.PDF_FORMAT || type === this.CSV_FORMAT || type === this.DOCX_FORMAT) {
      blob = new Blob([response], {
        type: contentType
      });
    } else {
      blob = new Blob([JSON.stringify(response)], {
        type: contentType
      });
    }
    const title = this.taxonomyTitle ? (this.taxonomyTitle + (fileType === 'CUSTOM_JSON' ? '_custom' : '_')) : '';
    saveAs(blob, (title) + '.' + type.toLowerCase());
  }

}
