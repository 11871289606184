import {
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import {
  CommonModule
} from '@angular/common';
import {
  AdditionalMetadataDetailsComponent
} from '../../project/additional-metadata-details/additional-metadata-details.component';
import {
  ExemplarDetailsComponent
} from '../../project/exemplar-details/exemplar-details.component';
import {
  AccordionComponent
} from '../../common/accordion/accordion.component';
import {
  NodeDetailsComponent
} from '../../project/node-details/node-details.component';
import {
  ItemDetailsComponent
} from '../../common/item-details/item-details.component';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  MomentModule
} from 'angular2-moment';
import {
  MyDatePickerModule
} from 'mydatepicker';
import {
  AmazingTimePickerModule
} from 'amazing-time-picker';
import {
  ClickOutsideModule
} from 'ng-click-outside';
import {
  AddExemplarComponent
} from '../../project/add-exemplar/add-exemplar.component';
import {
  AdditionalMetadataComponent
} from '../../project/additional-metadata/additional-metadata.component';
import {
  AssociationComponent
} from '../../project/project-authoring/association/association.component';
import { ModalComponent } from '../../common/modal/modal.component';
import { DynamicFormFieldComponent } from '../../common/dynamic-form-field/dynamic-form-field.component';
import { TreeAccordianComponent } from '../../tree-accordian/tree-accordian.component';
import { ImportFileComponent } from '../../common/import-file/import-file.component';
import { TextEditorComponent } from '../../common/text-editor/text-editor.component';
import { NgxEditorModule } from 'ngx-editor';
import { AutoCompleteModule } from '../auto-complete/auto-complete.module';
import { NavigationArrowComponent } from '../../common/item-details/navigation-arrow/navigation-arrow.component';
import { PreLoaderModule } from '../preloader/pre-loader.module';
import { AssociationModalComponent } from '../../common/association-modal/association-modal.component';
import { TreeModule } from '../tree.module';
import { MathJaxModule } from '../mathJax/mathJax.module';
import { ConfirmationModalComponent } from 'src/app/common/modal/confirmation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    MyDatePickerModule,
    AmazingTimePickerModule,
    ClickOutsideModule,
    NgxEditorModule,
    AutoCompleteModule,
    PreLoaderModule,
    TreeModule,
    MathJaxModule
  ],
  declarations: [ItemDetailsComponent,
    NodeDetailsComponent,
    ExemplarDetailsComponent,
    AccordionComponent,
    AdditionalMetadataDetailsComponent,
    AdditionalMetadataComponent,
    AddExemplarComponent,
    AssociationComponent,
    ModalComponent,
    ConfirmationModalComponent,
    DynamicFormFieldComponent,
    TreeAccordianComponent,
    ImportFileComponent,
    TextEditorComponent,
    NavigationArrowComponent,
    AssociationModalComponent
  ],
  exports: [ItemDetailsComponent,
    NodeDetailsComponent,
    ExemplarDetailsComponent,
    AccordionComponent,
    AdditionalMetadataDetailsComponent,
    MomentModule,
    MyDatePickerModule,
    AmazingTimePickerModule,
    ClickOutsideModule,
    AutoCompleteModule,
    AdditionalMetadataComponent,
    AddExemplarComponent,
    AssociationComponent,
    ModalComponent,
    ConfirmationModalComponent,
    DynamicFormFieldComponent,
    TreeAccordianComponent,
    ImportFileComponent,
    TextEditorComponent,
    NavigationArrowComponent,
    AssociationModalComponent,
    TreeModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ItemDetailModule { }
