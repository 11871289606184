import {
    NgModule,
    NO_ERRORS_SCHEMA
} from '@angular/core';
import {
    CommonModule
} from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { MathJaxModule } from '../mathJax/mathJax.module';
import { SearchListingComponent } from 'src/app/common/search/search-listing/search-listing.component';
import { ItemDetailModule } from '../item-detail/item-detail.module';

@NgModule({
    imports: [CommonModule, FormsModule,
        ReactiveFormsModule, MathJaxModule, ItemDetailModule
    ],
    exports: [
        SearchListingComponent,
        MathJaxModule,
        ItemDetailModule
    ],
    declarations: [
        SearchListingComponent
    ],
    schemas: [NO_ERRORS_SCHEMA],
})
export class SearchListModule { }
