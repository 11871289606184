import { Component, Input, OnInit, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Utils from '../utils';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() onlyOk: false;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public decline() {
    if (this.activeModal) {
      this.activeModal.close(false);
      this.checkForFocus();
    }
  }

  public accept() {
    if (this.activeModal) {
      this.activeModal.close(true);
      if (this.message.includes('You have unsaved changes. Are you sure you want to leave this page?')) {
        this.focusMoreoption();
      }
      // localStorage.setItem('datatableItem', '');
    }
  }

  public dismiss() {
    if (this.activeModal) {
      this.activeModal.dismiss();
      this.checkForFocus();
    }
  }

  checkForFocus() {
    if (!this.message.includes('You have unsaved changes. Are you sure you want to leave this page?')) {
      this.focusMoreoption();
    }
  }

  focusMoreoption() {
    if (localStorage.getItem('datatableItem') && localStorage.getItem('datatableItem').length > 0) {
      setTimeout(() => {
        Utils.focusOnDataTableElement();
      }, 500);
    }
  }


  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.dismiss();
  }

}
