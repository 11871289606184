import {
  Component,
  OnInit,
  OnChanges,
  Input,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import {
  CommonService
} from '../../common.service';
import {
  GlobalSettings
} from '../../global.settings';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  SharedService
} from '../../shared.service';
// import {
//   getValueFromObject
// } from 'ngx-bootstrap/typeahead';
import {
  ImportFileComponent
} from '../../common/import-file/import-file.component';
import Utils from '../../utils';

@Component({
  selector: 'app-add-exemplar',
  templateUrl: './add-exemplar.component.html',
  styleUrls: ['./add-exemplar.component.scss', './../../taxonomy/import-taxonomy/import-taxonomy.component.scss']
})
export class AddExemplarComponent implements OnInit, OnChanges {

  @Input() selectedNodeId: string;
  @Input() documentId: string;
  @Input() option: string;
  @Input() nodeDetails;
  @Input() itemExemplars;
  @Input() showModal = true; // to determine if modal should be loaded in DOM, used for confirmation modal multiple instance
  // @Input() itemAssets;
  @Output() addDeleteEvent: EventEmitter<any>;
  @ViewChild('importFileComponent', { static: false }) importFileComponent: ImportFileComponent;
  externalLink: string;
  addedLinks = [];
  selectedAssets = [];
  selectedAssetsPreview = [];
  hasAsset = 0;
  messages = [];
  addedData = []; // hold added urls and uploaded data
  intermediateData = []; // hold urls and data to be added after service call
  form: FormGroup; // form instance
  valid = false; // holds validation for title and description for add button
  validateTitle = false; // holds validation for title alert
  validateDescription = false; // holds validation for description alert
  externalLinkInput: FormControl; // input control which holds external link
  sourceUrlPattern = '((http|ftp|https):\\/\\/)?[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?';
  titleData;
  descData;
  externalInputData;
  actionComplete = true;
  showUrl = 1;
  showtext = false;
  listEndingData = {
    title: 'Getting added at the moment'
  };
  addLoader = false; // holds loading state for Exemplar and Asset adding
  constructor(private service: CommonService, private fb: FormBuilder, private sharedService: SharedService) {
    this.form = this.fb.group({
      externalLinkInput: [null, Validators.pattern(this.sourceUrlPattern)],
      title: [null],
      description: [null]
    });
    this.addDeleteEvent = new EventEmitter<any>();
  }

  ngOnInit() {
    if (document.getElementById('url')) {
      document.getElementById('url').click();
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.option && changes.option.currentValue) {
      this.option = changes.option.currentValue;
    }
    if (this.option === 'Document') {
      // For asset, there will be only file upload option, no any URL option
      this.hasAsset = 1;
      this.showUrl = 0;
      if (document.getElementById('uploadFile')) {
        document.getElementById('uploadFile').click();
      }
    } else {
      this.showUrl = 1;
      this.hasAsset = 0;
      if (document.getElementById('url')) {
        document.getElementById('url').click();
      }
    }
    setTimeout(() => {
      exemplarAssetPanelHeight();
    }, 300);
  }

  onAddButton() {
    this.showtext = false;
    if (this.option === 'Exemplar') {
      this.addExempler();
    } else if (this.option === 'Document') {
      this.addAsset();
    }
  }


  /* --------- POST Service Functionality when url or file is uploaded for exemplar: start --------- */
  addExempler() {
    this.addLoader = true;
    let fileDetails = {};
    const index = this.intermediateData.length - 1;
    let obj: any;
    if (this.hasAsset === 0) {
      this.externalLink = this.appendPrefix(this.intermediateData[index]['external_link']);
      this.intermediateData[index]['external_link'] = this.externalLink;
      this.intermediateData[index]['origin_node_id'] = this.selectedNodeId;
      this.intermediateData[index]['document_id'] = this.documentId;
      this.intermediateData[index]['has_asset'] = this.hasAsset;
      obj = this.intermediateData[index];
    }

    if (this.hasAsset === 1) {
      const fd = new FormData();
      fd.append('asset', this.intermediateData[index]['asset']);
      fileDetails = this.intermediateData[index]['asset'];
      fd.append('title', this.intermediateData[index]['title']);
      fd.append('description', this.intermediateData[index]['description']);
      fd.append('origin_node_id', this.selectedNodeId);
      fd.append('document_id', this.documentId);
      fd.append('has_asset', '1');
      obj = fd;
    }
    this.service.postService(GlobalSettings.EXEMPLER_ASSOCIATION, obj).then((res) => {
      this.sharedService.sucessEvent.next({
        type: 'add_exemplar'
      });
      if (this.hasAsset === 1) {
        this.importFileComponent.setUploaedFile('');
      }
      if (this.intermediateData && this.intermediateData[index] && res && res['item_association_id']) {
        this.intermediateData[index]['item_association_id'] = res['item_association_id'];
      }
      // this.addDeleteEvent.emit('');
      this.sharedService.addExemplerDoc.next({
        'res': res,
        'type': this.option,
        'action': 'add',
        'fileDetails': fileDetails
      });

      this.fillObject(res, fileDetails);
      this.checkValidation();
      // this.addedData.push(this.intermediateData[0]);
      this.intermediateData = [];
      this.form.reset();
      this.actionComplete = true;
      this.addLoader = false;
      this.valid = false;
    }).catch((ex) => {
      console.log('addExempler ex ', ex);
    });

  }

  fillObject(res, fileDetails) {
    this.itemExemplars.unshift({
      'association_id': res['item_association_id'] ? res['item_association_id'] : res['asset_id'],
      'title': res['external_node_title'] ? res['external_node_title'] : res['title'],
      'description': res['description'],
      'has_asset': res['has_asset'] ? res['has_asset'] : 0,
      'external_url': res['external_node_url'],
      'external_download_url': res['external_node_url'],
      'asset_id': res['asset_id'] ? res['asset_id'] : '',
      'asset_file_name': fileDetails ? fileDetails['name'] : '',
      'asset_content_type': fileDetails ? fileDetails['type'] : '',
      'asset_size_in_bytes': fileDetails ? fileDetails['size'] : '',
      'asset_target_file_name': '',
      'asset_uploaded_date_time': ''
    });
  }
  /* --------- POST Service Functionality when url or file is uploaded for exemplar: end --------- */

  /* --------- POST Service Functionality when url or file is uploaded for assets: start --------- */
  addAsset() {
    this.addLoader = true;
    let fileDetails = {};
    const index = this.intermediateData.length - 1;
    let obj: any;
    // console.log('this.nodeDetails   ', this.nodeDetails);
    if (this.hasAsset === 1) {
      const fd = new FormData();
      fd.append('asset', this.intermediateData[index]['asset']);
      fileDetails = this.intermediateData[index]['asset'];
      fd.append('title', this.intermediateData[index]['title']);
      fd.append('description', this.intermediateData[index]['description']);
      fd.append('asset_linked_type', (((this.nodeDetails.is_document === 1) || this.nodeDetails.document_type === 1) ? '1' : '2')); // 1 for document and 2 for item
      fd.append('item_linked_id', this.selectedNodeId);
      obj = fd;
    }
    this.service.postService(GlobalSettings.ASSET_ASSOCIATION, obj).then((res) => {
      this.sharedService.sucessEvent.next({
        type: 'add_assets'
      });
      this.sharedService.addExemplerDoc.next({
        'res': res,
        'type': this.option,
        'action': 'add'
      });
      if (this.hasAsset === 1) {
        this.importFileComponent.setUploaedFile('');
      }
      this.intermediateData[index]['item_association_id'] = res['asset_id'];
      // this.addDeleteEvent.emit('');
      this.fillObject(res, fileDetails);
      this.checkValidation();
      this.addedData.push(this.intermediateData[0]);
      this.intermediateData = [];
      this.form.reset();
      this.actionComplete = true;
      this.addLoader = false;
    }).catch((ex) => {
      console.log('addAssets ex ', ex);
      this.addLoader = false;
    });
  }
  /* --------- POST Service Functionality when url or file is uploaded for assets: end --------- */


  /* --------- Capture the File object when selected on UI Functionality start --------- */

  onFileUploadEvent(data) {
    const type = data.file.name;
    this.showtext = true;
    this.intermediateData.push({
      'asset': data.file,
      'item_association_id': '',
      'title': (this.titleData ? this.titleData : ''),
      'description': (this.descData ? this.descData : ''),
      'hasAsset': 1,
      'type': type.split('.').pop()
    });
    this.hasAsset = 1;
  }

  /* --------- Capture the File object when selected on UI Functionality end --------- */


  /* --------- Create Object for URL Functionality start --------- */

  validateURL() {
    this.showtext = true;
    if (this.form.controls.externalLinkInput.valid) {
      this.intermediateData.push({
        'external_link': this.form.value.externalLinkInput,
        'item_association_id': '',
        'title': (this.titleData ? this.titleData : ''),
        'description': (this.descData ? this.descData : ''),
        'hasAsset': 0,
        'type': 'URL'
      });
      this.hasAsset = 0;
      this.actionComplete = false;
      this.checkValidation();
    } else {
      this.valid = false;
      if (!this.form.value.externalLinkInput && !this.form.value.title) {
        this.showtext = false;
      }
    }
  }

  /* --------- Create Object for URL Functionality start --------- */

  /* --------- On Delete Uploaded item Functionality start --------- */

  onFileDeleteEvent(item_association_id) {
    let url, successEventType;
    if (this.option === 'Document') {
      url = GlobalSettings.ASSET_ASSOCIATION + '/' + item_association_id;
      successEventType = 'delete_asset';
    } else if (this.option === 'Exemplar') {
      url = GlobalSettings.EXEMPLER_ASSOCIATION + '/' + item_association_id;
      successEventType = 'delete_exemplar';
    }

    if (item_association_id.length > 0) {
      this.service.deleteServiceData(url).then((res) => {
        this.addedData = this.addedData.filter(obj => {
          return obj.item_association_id !== item_association_id;
        });
        this.sharedService.sucessEvent.next({
          type: successEventType
        });
        this.sharedService.addExemplerDoc.next({
          'res': {
            'item_association_id': item_association_id
          },
          'type': this.option,
          'action': 'delete'
        });
        this.itemExemplars = this.itemExemplars.filter(obj => {
          console.log(JSON.stringify(obj), item_association_id);
          return obj.item_association_id !== item_association_id &&
            obj.asset_id !== item_association_id && obj.association_id !== item_association_id;
        });
        // this.addDeleteEvent.emit('');
      }).catch((ex) => {
        console.log('onFileDeleteEvent  ', ex);
      });
    }
    // this.addDeleteEvent.emit('');
    this.checkValidation();
    // this.importFileComponent.setUploaedFile('');
  }

  onIntermediateDel() {
    this.intermediateData.pop();
    this.actionComplete = true;
    document.getElementById('title').focus();
    // this.sharedService.sucessEvent.next({
    //   type: 'delete_exemplar'
    // });
    if (this.hasAsset === 1) {
      this.importFileComponent.setUploaedFile('');
      if (!this.form.value.title) {
        this.showtext = false;
      }
    }
    this.onCancel(event);
    // this.addDeleteEvent.emit('');
    this.checkValidation();
  }
  /* --------- On Delete Uploaded item Functionality end --------- */


  onCancel(event) {
    const eventData = event.event;
    if (this.hasAsset === 0) {
      this.intermediateData = [];
    }
    // if (this.intermediateData.length > 0) {
    //   eventData.stopPropagation();
    //   eventData.preventDefault();
    //   this.sharedService.sucessEvent.next({
    //     type: 'unsaved_exempler_data'
    //   });
    // } else {
    if (this.importFileComponent) {
      this.importFileComponent.setUploaedFile('');
    }
    this.addDeleteEvent.emit('');
    this.form.reset();
    this.intermediateData = [];
    this.addedData = [];
    this.actionComplete = true;
    this.showtext = false;
    // }
  }

  /* --------- Appending prefix to uploaded URL Functionality start --------- */

  appendPrefix(link) {
    const prefixHttp = 'http://';
    const prefixHttps = 'https://';
    if ((link.substr(0, prefixHttp.length) !== prefixHttp) && (link.substr(0, prefixHttps.length) !== prefixHttps)) {
      link = prefixHttp + link;
    }
    return link;
  }

  /* --------- Appending prefix to uploaded URL Functionality end --------- */


  /* --------- Capture the radio button option selected Functionality start --------- */

  selectedOption(value) {
    this.hasAsset = Number(value);
    // this.form.reset();
    this.actionComplete = true;
    this.intermediateData = [];
  }

  /* --------- Capture the radio button option selected Functionality end --------- */


  /* --------- On excape key press function start --------- */
  onMouseCall(event) {
    if (event.keyCode === 27) {
      document.getElementById('close').click();
    }
  }
  /* --------- On excape key press function end --------- */

  /* --------- Validation Functionality for Add button to enable start --------- */

  checkValidation() {
    if (this.form.value.title) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  /* --------- Validation Functionality for Add button to enable end --------- */

  /* --------- Validation Functionality for alert to be displayed start --------- */
  onValidateDesc() {
    if (this.form.value.description) {
      this.validateDescription = true;
    } else {
      this.validateDescription = false;
    }
  }

  onValidateTitle() {
    if (this.form.value.title) {
      this.validateTitle = true;
      this.showtext = true;
    } else {
      this.validateTitle = false;
      if (!this.form.value.externalLinkInput && this.intermediateData.length === 0) {
        this.showtext = false;
      }
    }
  }
  /* --------- Validation Functionality for alert to be displayed end --------- */


  /* --------- Functionality to update title and description on the fly start --------- */
  titleChange() {
    if (this.intermediateData && this.intermediateData.length > 0) {
      this.intermediateData[this.intermediateData.length - 1]['title'] = this.titleData;
    }
  }

  descriptionChange() {
    if (this.intermediateData && this.intermediateData.length > 0) {
      this.intermediateData[this.intermediateData.length - 1]['description'] = this.descData;
    }
  }
  /* --------- Functionality to update title and description on the fly end --------- */


  /* --------- Functionality to convert bytes to appropriate file size start --------- */
  formatBytes(bytes) {
    let fileSize = (Utils.formatBytes(bytes));
    let size = 0;
    let measure = '';
    if (fileSize.toLowerCase().includes('bytes')) {
      size = Math.round(Number(fileSize.substring(0, fileSize.length - 5)));
      measure = fileSize.substring(fileSize.length - 5, fileSize.length);
    } else {
      size = Math.round(Number(fileSize.substring(0, fileSize.length - 2)));
      measure = fileSize.substring(fileSize.length - 2, fileSize.length);
    }

    fileSize = size + ' ' + measure;
    // console.log(fileSize);
    return fileSize;
  }
  /* --------- Functionality to convert bytes to appropriate file size end --------- */

}
