import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Cache } from './cache';
import { CacheEntry, MAX_CACHE_AGE } from './cache-entry';

@Injectable()
export class CacheMapService implements Cache {
    cacheMap = new Map<string, CacheEntry>();
    get(req: HttpRequest<any>): HttpResponse<any> | null {
        // if (this.getMapFromStorage()) {
        //     this.cacheMap = this.getMapFromStorage();
        // }
        const entry = this.cacheMap.get(req.urlWithParams);
        if (!entry) {
            return null;
        }
        const isExpired = (Date.now() - entry.entryTime) > MAX_CACHE_AGE;
        return isExpired ? null : entry.response;
    }
    put(req: HttpRequest<any>, res: HttpResponse<any>): void {
        // if (this.getMapFromStorage()) {
        //     this.cacheMap = this.getMapFromStorage();
        // }
        const entry: CacheEntry = { url: req.urlWithParams, response: res, entryTime: Date.now() };
        this.cacheMap.set(req.urlWithParams, entry);
        // this.setMapInStorage(this.cacheMap);
        if (req.method === 'PUT' || req.method === 'POST' || req.method === 'DELETE') {
            // If any update or add or delete api called, then clear all cache data
            this.deleteAllCache();
        } else {
            // Else clear only expired cache data
            this.deleteExpiredCache();
        }
    }

    public deleteExpiredCache() {
        this.cacheMap.forEach(entry => {
            if ((Date.now() - entry.entryTime) > MAX_CACHE_AGE) {
                this.cacheMap.delete(entry.url);
            }
        });
        // this.setMapInStorage(this.cacheMap);
    }
    public deleteAllCache() {
        this.cacheMap.forEach(entry => {
            this.cacheMap.delete(entry.url);
        });
        // this.setMapInStorage(this.cacheMap);
    }
    public deleteSpecificCache(url: string) {
        this.cacheMap.forEach(entry => {
            if ((Date.now() - entry.entryTime) > MAX_CACHE_AGE) { // delete expired
                this.cacheMap.delete(entry.url);
            } else if (entry.url.includes(url)) { // delete specific
                this.cacheMap.delete(entry.url);
            }
        });
        // this.setMapInStorage(this.cacheMap);
    }

    /*setMapInStorage(map: Map<string, CacheEntry>) {
        const array = [];
        map.forEach(entry => {
            array.push(entry);
        });
        localStorage.setItem('cacheApiData', JSON.stringify(array));
    }

    getMapFromStorage() {
        const mapData = new Map<string, CacheEntry>();
        let temp = [];
        if (localStorage.getItem('cacheApiData')) {
            temp = JSON.parse(localStorage.getItem('cacheApiData'));
        }
        if (temp.length) {
            temp.forEach(entry => {
                mapData.set(entry.url, entry);
            });
            return mapData;
        } else {
            return null;
        }
    }*/
}
