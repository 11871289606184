import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  UUID
} from 'angular2-uuid';
import {
  CommonService
} from '../../common.service';
import {
  ActivatedRoute,
  Params,
  Router
} from '@angular/router';
import {
  SharedService
} from '../../shared.service';
import {
  TreeDataService
} from '../../tree-data.service';

import {
  ConfirmationDialogService
} from '../../confirmation-dialog/confirmation-dialog.service';

import {
  GlobalSettings
} from '../../global.settings';
import Utils from '../../utils';
import {
  from
} from 'rxjs';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  AutoCompleteComponent
} from '../../common/auto-complete/auto-complete.component';
import {
  TreeComponent
} from '../../common/tree/tree.component';

@Component({
  selector: 'app-taxonomy-builder',
  templateUrl: './build-taxonomy.component.html',
  styleUrls: ['../taxonomy-create-options/taxonomy-create-options.component.scss']
})
export class BuildTaxonomyComponent implements OnInit, OnChanges, OnDestroy {

  @Input() full_statement;
  @Input() human_coding_scheme;
  @Input() resetTree;
  @Input() metadataType;
  @Input() currentStep;
  @Input() editTaxonomy = false; // check if edit or create
  @Input() document_id;
  @Input() showReorderBtn = true;
  @Input() associatedProjectId;
  @Input() viewLocation = 'taxonomy-list';
  @Output() taxonomyUpdateEvent;
  @Output() templateDataEvent; // Emits all metadatas in node template used in taxonomy list passed to template preview
  @Output() emptyFieldsAlert = new EventEmitter();
  @Output() validEvent = new EventEmitter();
  @Output() nodeRearrengeEvent = new EventEmitter();
  @Input() taxonomyData; // parent json to for rendering
  taxonomyDataSubmission; // parent json to submit
  isFirstTime = true;
  currentLevel = 1;
  uuid; // random id generated for each node
  isAllNode = true;
  isAddNode = true;
  selectedNode = {
    id: '',
    item_id: ''
  };
  flag = 1;
  @Input() createNodePermission = true;
  @Input() deleteNodePermission = true;
  saveTaxonomy = false;
  parentNode = null;
  type = 'buildtaxonomy';
  showProtipTaxonmyBuilder = true;
  allNodeTemplateList = [];
  select_template_data; // holds all metadata for specific template
  templateLevelLenght = -1; // holds the length of array for selected node template
  node_template_id; // holds node type if for the template selected
  default_document_node_type;
  nodetypeData;
  selectedNodeTemplate: any; // holds node template object selected
  defalutNodeTemplate = {
    'node_template': '',
    'name': 'No Template! I want to create my own hierarchy.',
    'usage': 0
  };
  intervalTimer = null;
  emptyFields = false;
  documentName: any;
  documentCreated = false;
  enableDND = false;
  btnText = 'Reorder';
  dragDropData = [];
  postAPIData = [];
  old_itemsData = [];
  oldData = [];
  savingData = false;
  form: FormGroup;
  selectType = 'single';
  uniqueId = 'node_template';
  autoCompleteTitle = 'Select Taxonomy Template';
  updateItem = false;
  preventReorder = false; // holds boolean to determine if to allow reorder, disabled for active projects
  @Input() reorderPermission = false;
  constructor(private service: CommonService,
    private sharedService: SharedService,
    private dialogService: ConfirmationDialogService,
    private treeService: TreeDataService) {

    this.sharedService.preventReorder.subscribe((event: any) => {
      if (event && event.preventReorder && event.preventReorder === true) {
        this.preventReorder = true;
      } else {
        this.preventReorder = false;
      }
    });

    this.taxonomyUpdateEvent = new EventEmitter();
    this.templateDataEvent = new EventEmitter();
  }
  // @ViewChild('treeAccordian') treeAccordianComponet: ProjectTreeAccordianComponent;
  @ViewChild('tree', {
    static: false
  }) treeComponent: TreeComponent;
  @ViewChild('autoComplete', {
    static: false
  }) autoComplete: AutoCompleteComponent;
  ngOnInit() {
    this.form = new FormGroup({
      taxonomyName: new FormControl(null, [Validators.required]),
      template: new FormControl(null),
    });
    console.log('editTaxonomy', JSON.stringify(this.editTaxonomy));
    // this.createNodePermission = true;
    // this.deleteNodePermission = true;

    if (!this.editTaxonomy) {
      this.treeInitialize();
    } else if (this.viewLocation === 'project-auth') {
      this.documentCreated = true;
      this.setTaxonomyData(this.taxonomyData, this.document_id);
    } else {
      this.documentCreated = true;
      this.onTaxonomySelected(this.document_id);
    }
    this.getAllNodeTemplates();
    this.getAllMetadata();
    this.skipIdealActity();
  }



  onTaxonomySelected(id) {
    const parentNodeId = UUID.UUID();
    if (id) {
      this.taxonomyData = null;
      this.dragDropData = null;
      // const serviceUrl = GlobalSettings.GET_TREE_VIEW + id;
      const serviceUrl = GlobalSettings.GET_TREE_VIEW_ENHANCED + id;
      this.treeService.getTreeData(serviceUrl, true).then((response: any) => {
        const res = response.parsedTreeNodes;
        this.setTaxonomyData(res, id);
      }).catch(ex => {
        console.log('list of taxonomies ex ', ex);
      });
    }

  }

  setTaxonomyData(taxonomy: any, document_id: any) {
    const parentNodeId = UUID.UUID();
    if (taxonomy && taxonomy.children) {
      // Utils.sortData(taxonomy.children);
      if ((this.viewLocation !== 'project-auth')) {
        // taxonomy structure for all node
        taxonomy['id'] = parentNodeId;
        taxonomy['children'][0]['parent'] = 'root';
        taxonomy['children'][0]['document_title'] = taxonomy['children'][0]['full_statement'];
        taxonomy['children'][0]['full_statement'] = '';
        taxonomy['children'][0]['parent_id'] = '';
        taxonomy['children'][0]['document_id'] = document_id;
        taxonomy['children'][0]['parent_id'] = parentNodeId;
        taxonomy['children'][0]['location'] = 'taxonomy_builder';
        taxonomy['children'][0]['level'] = 1;
        taxonomy['children'][0]['node_type'] = '';
        taxonomy['children'][0]['is_editable'] = 1;
      } else {
        // taxonomy structure for project associated nodes
        taxonomy['id'] = parentNodeId;
        taxonomy['parent'] = 'root';
        taxonomy['document_title'] = taxonomy['children'][0]['full_statement'];
        taxonomy['full_statement'] = '';
        // taxonomy['parent_id'] = '';
        taxonomy['document_id'] = document_id;
        taxonomy['parent_id'] = parentNodeId;
        taxonomy['location'] = 'taxonomy_builder';
        taxonomy['level'] = 1;
        taxonomy['node_type'] = '';
        taxonomy['is_editable'] = 1;
      }

      this.taxonomyData = [taxonomy];
      this.dragDropData = JSON.parse(JSON.stringify([taxonomy]));
      this.taxonomyDataSubmission = [{
        'node_template_id': '',
        'document_title': taxonomy['children'][0]['document_title'],
        // 'document_id': document_id,
        'document_node_type_id': '',
        'language_id': '',
        'metadataType': '',
        'items': [],
        'template_tiltle': ''

      }];
    }

    if (this.taxonomyData && this.taxonomyData[0] &&
      this.taxonomyData[0]['children'] &&
      this.taxonomyData[0]['children'][0] &&
      this.taxonomyData[0]['children'][0]['children'] &&
      this.taxonomyData[0]['children'][0]['children'].length === 0) {
      this.showProtipTaxonmyBuilder = true;
    } else {
      this.showProtipTaxonmyBuilder = false;
    }
    // this.setNodeTypeList(this.taxonomyData);
    // setTimeout(() => {
    //   if (this.treeAccordianComponet) {
    //     console.log('treeAccordianComponet', this.taxonomyData);
    //     this.treeAccordianComponet.expendNode(this.document_id);
    //     this.treeAccordianComponet.onNodeSelected(this.taxonomyData[0]['children'][0]);
    //   } else {
    //     console.log('treeAccordianComponet Else', this.taxonomyData[0]['children'][0][document_id]);
    //   }
    // }, 200);
  }



  /* --------- Initialise tree object function start --------- */
  treeInitialize() {
    const parentNodeId = UUID.UUID();
    const parentId = UUID.UUID();
    this.document_id = parentId;
    this.taxonomyData = [{
      'id': parentNodeId,
      'children': [{
        parent: 'root',
        id: parentId,
        document_id: parentId,
        // 'document_title': 'Document',
        document_title: this.documentName,
        document_title_html: this.documentName,
        title: '',
        list_enumeration: '',
        sequence_number: '',
        human_coding_scheme: '',
        full_statement: this.documentName,
        node_type: '',
        node_type_id: '',
        is_editable: 1,
        parent_id: parentNodeId,
        currentIndex: '',
        children: [],
        metadataType: '',
        template_tiltle: this.select_template_data === undefined ? '' : this.select_template_data.node_types[0].title,
        expand: true,
        level: 1

      }]
    }];
    console.log('this.select_template_data', this.taxonomyData);
    this.taxonomyDataSubmission = [{
      'node_template_id': '',
      'document_title': this.documentName,
      'document_title_html': this.documentName,
      // 'document_id': parentId,
      'document_node_type_id': '',
      'language_id': '',
      'metadataType': '',
      'items': [],
      'template_tiltle': ''

    }];
  }
  /* --------- Initialise tree object function end --------- */


  ngOnChanges() {
    if (this.resetTree === 0 || this.resetTree === 1) {
      if (this.viewLocation !== 'project-auth') {
        this.cleartaxonomy();
      }
    }
    if (this.currentStep === 2) {
      this.documentName = null;
    }
    return this.taxonomyData;
  }


  /* --------- Capture updated human coding scheme value with object function start --------- */
  setHumanCodingSchemeEvent(val) {
    let evt;
    let human_coding_scheme;
    [evt, human_coding_scheme] = val;
    evt['human_coding_scheme'] = human_coding_scheme;
    if (evt.item_id || evt.id) {
      this.selectedNode = evt;
      this.editService('human_coding_scheme', human_coding_scheme, evt['node_type_id']);
    }
  }
  /* --------- Capture updated human coding scheme value with object function end --------- */



  /* --------- Capture updated full statement value with object function start --------- */
  setFullStatementEvent(val) {
    let evt;
    let full_statement;
    [evt, full_statement] = val;
    evt['full_statement'] = full_statement;
    if (evt.item_id || evt.id) {
      this.selectedNode = evt;
      this.editService('full_statement', full_statement, evt['node_type_id']);
    }
  }
  /* --------- Capture updated full statement value with object function start --------- */


  /* --------- Capture updated full statement value with object function start --------- */
  setDocumentTitleEvent(val) {
    let evt;
    let document_title;
    [evt, document_title] = val;
    evt['document_title'] = document_title;
    evt['expand'] = true;
    this.taxonomyDataSubmission[0]['document_title'] = document_title;
    console.log('resetTree' + this.resetTree);
    if (evt.item_id || evt.id) {
      this.selectedNode = evt;
      this.editService('document_title', document_title, evt['node_type_id']);
    }
  }
  /* --------- Capture updated full statement value with object function start --------- */

  setNodeTypeIdEvent(val) {
    let evt;
    let node_type_id;
    [evt, node_type_id] = val;
    evt.node_type = evt.metadataType;
    evt['node_type_id'] = node_type_id;
    if (evt.item_id || evt.id) {
      this.selectedNode = evt;
      this.editService('node_type_id', node_type_id, node_type_id);
    }
  }
  setMetadataTypeEvent(val) {
    let evt;
    let metadataType;
    [evt, metadataType] = val;
    evt['metadataType'] = metadataType;
    this.taxonomyDataSubmission[0]['metadataType'] = metadataType;
  }


  editService(placeholder, value, node_type_id) {
    const id = this.selectedNode.item_id ? this.selectedNode.item_id : this.selectedNode['id'];
    let url = '';
    let body = {};
    if (placeholder === 'full_statement') {
      body = {
        'full_statement': value,
        'full_statement_html': value,
        'node_type_id': node_type_id
      };
    } else if (placeholder === 'human_coding_scheme') {
      body = {
        'human_coding_scheme': value,
        'human_coding_scheme_html': value,
        'node_type_id': node_type_id
      };
    } else if (placeholder === 'document_title') {
      body = {
        'title': value,
        'title_html': value,
        'node_type_id': node_type_id
      };
    } else if (placeholder === 'node_type_id') {
      body = {
        'node_type_id': value
      };
    }
    // console.log('body', body);
    body['custom'] = [];
    if (placeholder === 'document_title') {
      url = GlobalSettings.SAVE_CFDOC_NODE + '/' + (this.taxonomyData[0]['children'][0]['document_id'] ?
        this.taxonomyData[0]['children'][0]['document_id'] : this.document_id);
    } else {
      url = GlobalSettings.EDIT_NODE + '/' + id;
    }
    if (this.documentCreated) {
      this.service.putService(url, body).then((res: any) => {
        /*if (placeholder === 'document_title') {
          // this.sharedService.sucessEvent.next({
          //   type: 'taxonomy_title'
          // });
        } else {
          // this.sharedService.sucessEvent.next({
          //   type: 'edit_node'
          // });
        }*/
      }).catch((ex) => {
        console.log('edit Node build taxonomy ', ex);
      });
    }
  }

  /* --------- Add node function start */
  addNodeEvent(evt) {

    if (!this.documentCreated) {
      /* this.saveDocument();
       setTimeout(() => {
         this.createChild(evt);
       }, 2000);*/
    } else {
      this.createChild(evt);
    }
  }


  createChild(evt) {
    console.log('addNodeEvent==>', evt);
    const parentId = evt.id;
    const currentLevel = evt.level;
    evt.cut = 0;
    evt.reorder = 0;
    evt.paste = 0;


    const tempObj = {
      'id': '',
      'item_id': '',
      'title': '',
      'list_enumeration': '',
      'sequence_number': '',
      'human_coding_scheme': '',
      'full_statement': '',
      'node_type': '',
      'node_type_id': '',
      'is_editable': 1,
      'parent_id': parentId,
      'currentIndex': '',
      'metadataType': '',
      'children': [],
      'template_tiltle': '',
      'document_id': (this.viewLocation === 'project-auth') ? this.document_id : this.taxonomyData[0]['children'][0]['document_id'],
      'project_id': (this.viewLocation === 'project-auth' && this.associatedProjectId) ? this.associatedProjectId : null,
      'cut': 0,
      'reorder': 0,
      'paste': 0
    };



    let list_enum = 0;
    if (evt['children']) {
      list_enum = evt['children'].length + 1;
      tempObj['list_enumeration'] = String(list_enum);
      tempObj['sequence_number'] = String(list_enum);
      tempObj['human_coding_scheme'] = evt['human_coding_scheme'] !== '' ? evt['human_coding_scheme'] : '';
      tempObj['metadataType'] = '';

      if (evt['node_type_id'] === '' || evt['node_type_id'].length) {
        from(this.nodetypeData)
          .filter((w: any) => w.title === 'Default')
          .subscribe(result => {
            tempObj['node_type_id'] = result.node_type_id;
          });
      }

      const nextLevel = currentLevel ? currentLevel + 1 : 1;

      if (this.select_template_data !== undefined) {
        // console.log('Kunal', nextLevel, this.select_template_data.node_types);
        from([this.select_template_data.node_types])
          .subscribe(data => {
            from(data)
              .filter((x: any) => x.sort_order === nextLevel)
              .subscribe(filterData => {
                tempObj['template_tiltle'] = filterData.title;
                tempObj['node_type_id'] = filterData.node_type_id;
              });

          });
      } else {
        if (evt['children'] && evt['children'][0]) {
          tempObj.metadataType = evt['children'][0]['metadataType'];
          tempObj['node_type_id'] = evt['children'][0]['node_type_id'];
        } else {
          tempObj['template_tiltle'] = evt['template_tiltle'];
        }
      }

      if (tempObj.metadataType === '') {
        tempObj.metadataType = 'Default';
      }
    }


    const url = GlobalSettings.CREATE_NODE;
    this.service.postService(url, tempObj).then((res: any) => {
      tempObj['id'] = res.item_id;
      tempObj['item_id'] = res.item_id;
      tempObj['parent_id'] = res.parent_id;
      tempObj['expand'] = true;
      tempObj['level'] = currentLevel ? currentLevel + 1 : 1; // added newly kunal
      evt['children'].push(tempObj);
      this.currentLevel = tempObj['level'];
      // if (this.treeAccordianComponet) {
      //   setTimeout(() => {
      //     console.log('parentId  ', parentId);
      //     this.treeAccordianComponet.expendNode(res.parent_id);
      //     this.treeAccordianComponet.focusNode(res.parent_id);
      //     this.treeAccordianComponet.focusNode(res.item_id);
      //   }, 100);
      // }

      if (this.treeComponent) {
        setTimeout(() => {
          console.log('parentId  ', parentId);
          // this.treeComponent.expendNode(res.parent_id);
          this.treeComponent.focusNode(res.parent_id);
          this.treeComponent.focusNode(res.item_id);
          this.treeComponent.setNodeTypeList(this.taxonomyData);
        }, 100);
      }


      this.selectedNode = tempObj;
      // if (this.treeAccordianComponet) {
      //   this.treeAccordianComponet.onNodeSelected(this.selectedNode);
      // }
      if (this.treeComponent) {
        this.treeComponent.onNodeSelected(this.selectedNode);
      }

      // console.log('taxonomyData', JSON.stringify(this.taxonomyData));

      // this.taxonomyUpdateEvent.emit(this.saveTaxonomy);

      this.sharedService.sucessEvent.next({
        type: 'create_node'
      });
      this.showProtipTaxonmyBuilder = false;
    }).catch((ex) => {
      console.log('createNode ', ex);
    });

  }
  /* --------- Add node function end --------- */

  /* --------- Delete node function start --------- */

  deleteNodeEvent(item) {
    if (item.item_id || item.id) {
      this.selectedNode = item;
      this.deleteNode();
    }
  }


  deleteNode() {
    if (this.selectedNode.item_id || this.selectedNode['id']) {
      const id = this.selectedNode.item_id ? this.selectedNode.item_id : this.selectedNode['id'];
      const url = GlobalSettings.DELETE_NODE + '/' + id + '?hasProject=1';

      this.service.deleteServiceData(url).then((res) => {
        const delMsg = 'Deleting this node will remove it from all the nodes it is referred to. ';
        const delConfirmMsg = ' Are you sure you want to delete?';

        if (res['result'] === 'assigned') {
          // tslint:disable-next-line:max-line-length
          this.dialogService.confirm('Confirm', delMsg + 'Deleting this will also remove the node for its assigned project.' + delConfirmMsg)
            .then((confirmed) => {
              if (confirmed) {
                const projectId = res['project_id'];
                const deleteUrl = GlobalSettings.DELETE_NODE + '/' + id + '?project_id=' + projectId;
                this.deleteService(deleteUrl, id);
              } else {
                console.log('User cancel the dialog');
              }
            })
            .catch(() => {
              console.log('User dismissed the dialog');
            });
        } else {
          this.dialogService.confirm('Confirm', delMsg + delConfirmMsg)
            .then((confirmed) => {
              if (confirmed) {
                const deleteUrl = GlobalSettings.DELETE_NODE + '/' + id;
                this.deleteService(deleteUrl, id);
              } else {
                console.log('User cancel the dialog');
              }
            })
            .catch(() => {
              console.log('User dismissed the dialog');
            });
        }


      }).catch((ex) => {
        console.log('deleteNode ', ex);
      });
    }

  }

  deleteService(deleteUrl, id) {
    this.service.deleteServiceData(deleteUrl).then((response) => {
      this.getParentNodeObject(this.selectedNode['parent_id']);
      this.deleteObjectFromTree(id);
      this.sharedService.sucessEvent.next({
        type: 'delete_node'
      });
      if (this.parentNode && this.parentNode.children) {
        Utils.sortData(this.parentNode.children);
      }
      if (this.taxonomyData[0]['children'][0]['children'].length === 0) {
        this.showProtipTaxonmyBuilder = true;
      } else {
        // console.log('this.taxonomyData[', JSON.stringify(this.taxonomyData[0]['children'][0]['children']));
      }
    }).catch((ex) => {
      console.log('deleteNode ', ex);
    });
  }

  getParentNodeObject(parentId) {

    if (this.isAllNode === true) {
      this.taxonomyData.forEach(element => {
        this.getParent(element, parentId);
      });
    } else {
      this.taxonomyData.children.forEach(element => {
        this.getParent(element, parentId);
      });
    }
  }

  getParent(node, parentId) {
    if (node.id === parentId) {
      this.parentNode = node;
      console.log('getParent*********** ', node.id, parentId);
    } else {
      if (node.children.length > 0) {
        node.children.forEach(element => {
          this.getParent(element, parentId);
        });
      }
    }
  }

  deleteObjectFromTree(id) {
    if (this.parentNode) {
      if (this.parentNode.children) {
        let nodeIndex = -1;
        for (let index = 0; index < this.parentNode.children.length; index++) {
          const child = this.parentNode.children[index];
          if (child.id === id) {
            nodeIndex = index;
            break;
          }
        }
        if (nodeIndex > -1) {
          Utils.delNodeOccurance(this.taxonomyData, id);
          // const deltedNode = this.parentNode.children.splice(nodeIndex, 1);
          // console.log('deltedNode  ', deltedNode);

          if (nodeIndex === 0) {
            // if (this.treeAccordianComponet) {
            //   setTimeout(() => {
            //     this.treeAccordianComponet.selectedNodeId = this.parentNode.id;
            //     this.treeAccordianComponet.onNodeSelected(this.parentNode);
            //     this.treeAccordianComponet.focusNode(this.parentNode.id);
            //   }, 100);
            // }
            if (this.treeComponent) {
              setTimeout(() => {
                this.treeComponent.selectedNodeId = this.parentNode.id;
                this.treeComponent.onNodeSelected(this.parentNode);
                this.treeComponent.focusNode(this.parentNode.id);
              }, 100);
            }
          } else {
            // if (this.treeAccordianComponet) {
            //   const prevNode = this.parentNode.children[nodeIndex - 1];
            //   if (prevNode) {
            //     if (this.treeAccordianComponet) {
            //       setTimeout(() => {
            //         this.treeAccordianComponet.expendNode(prevNode.id);
            //       }, 100);
            //     }
            //     this.treeAccordianComponet.onNodeSelected(prevNode);
            //     this.treeAccordianComponet.focusNode(prevNode.id);
            //   }
            // }
            if (this.treeComponent) {
              const prevNode = this.parentNode.children[nodeIndex - 1];
              if (prevNode) {
                if (this.treeComponent) {
                  setTimeout(() => {
                    // this.treeComponent.expendNode(prevNode.id);
                  }, 100);
                }
                this.treeComponent.onNodeSelected(prevNode);
                this.treeComponent.focusNode(prevNode.id);
              }
            }
          }
        } else {
          console.log('deleteObjectFromTree Deleted node not found');
        }
      } else {
        console.log('deleteObjectFromTree ParentNode children is null');
      }

    } else {
      console.log('deleteObjectFromTree ParentNode is null');
    }
  }
  /* --------- Delete node function end --------- */

  /* --------- Save taxonomy function start --------- */
  savetaxonomy() {
    if (this.taxonomyDataSubmission[0]['document_title'].length > 0) {
      for (const item in this.taxonomyDataSubmission[0]['items']) {
        if (item) {
          if (this.taxonomyDataSubmission[0]['items'][item]['full_statement'].length < 1) {
            this.emptyFields = true;
            this.sharedService.sucessEvent.next({
              type: 'taxonomy_empty_fields'
            });
            break;
          } else {
            this.emptyFields = false;
          }
        }
      }
      this.emptyFieldsAlert.emit(this.emptyFields);
      if (!this.emptyFields) {
        if (this.select_template_data !== undefined) {
          this.taxonomyDataSubmission[0].document_node_type_id = this.select_template_data.node_types[0].node_type_id;
        } else {
          if (this.default_document_node_type && this.default_document_node_type.node_type_id) {
            this.taxonomyDataSubmission[0].document_node_type_id = this.default_document_node_type.node_type_id;
          }
        }
        // console.log(JSON.stringify(this.taxonomyData));
        // console.log(JSON.stringify(this.taxonomyDataSubmission));
        const url = GlobalSettings.BUILD_TAXONOMY;
        if (this.node_template_id) {
          this.taxonomyDataSubmission[0].node_template_id = this.node_template_id;
        }
        this.taxonomyDataSubmission[0].document_type = 1;
        this.service.postService(url, this.taxonomyDataSubmission[0]).then((res: any) => {
          this.sharedService.sucessEvent.next({
            type: 'taxonomy_add'
          });
          this.treeInitialize();
          this.saveTaxonomy = false;
          // this.saveTaxonomyEvent.emit();
        }).catch((ex) => {
          console.log('createNode ', ex);
        });
      }
    } else {
      this.emptyFields = true;
      this.sharedService.sucessEvent.next({
        type: 'document_title_empty'
      });
      this.emptyFieldsAlert.emit(this.emptyFields);
    }

  }
  /* --------- Save taxonomy function end --------- */


  /* --------- Update taxonomy's parent object function start --------- */
  update_parent_obj(obj) {
    // console.log('Updated called on parent in build ' + JSON.stringify(obj));
    this.taxonomyData[0]['children'][0]['human_coding_scheme'] = obj.human_coding_scheme;
    this.taxonomyData[0]['children'][0]['full_statement'] = obj.full_statement;
    this.taxonomyData[0]['children'][0]['document_title'] = obj.document_title;

    this.taxonomyDataSubmission[0]['document_title'] = obj.document_title;
  }
  /* --------- Update taxonomy's parent object function end --------- */


  /* --------- Reset taxonomy function start --------- */
  cleartaxonomy() {
    this.documentCreated = false;
    this.treeInitialize();
    this.saveTaxonomy = false;
    // this.showProtipTaxonmyBuilder = false;
    this.selectTemplate();
    if (this.allNodeTemplateList && this.allNodeTemplateList.length > 0 && this.allNodeTemplateList[0]) {
      this.selectedNodeTemplate = this.allNodeTemplateList[0];
    } else {
      this.selectedNodeTemplate = this.defalutNodeTemplate;
    }
    // this.cancelEvent.emit();
  }
  /* --------- Reset taxonomy function end --------- */


  /* --------- Functionality to fetch all node templates start --------- */

  getAllNodeTemplates() {
    const url = GlobalSettings.Get_All_Node_Templates;
    this.service.getServiceData(url).then((res: any) => {
      // this.allNodeTemplateList = res;
      this.allNodeTemplateList = [];
      this.allNodeTemplateList.push(this.defalutNodeTemplate);
      res.forEach(element => {
        // {
        this.allNodeTemplateList.push(element);
        // }
      });
      this.selectedNodeTemplate = this.allNodeTemplateList[0];
      // console.log('getAllNodeTemplates', JSON.stringify(this.selectedNodeTemplate));
    });
  }

  /* --------- Functionality to fetch all node templates end --------- */


  /* --------- Functionality to select specific template start --------- */

  selectTemplate() {
    if (this.selectedNodeTemplate) {
      this.setTemplate(this.selectedNodeTemplate);
    }

  }

  setTemplate(template) {
    this.selectedNodeTemplate = template;
    const template_id = this.selectedNodeTemplate.node_template;
    if (template_id) {
      this.node_template_id = template_id;
      const url = GlobalSettings.Get_All_Node_Templates + '/' + template_id;
      this.service.getServiceData(url).then((res: any) => {
        this.select_template_data = res;
        this.templateDataEvent.emit(this.select_template_data.node_types);
        if (res.node_types) {
          this.templateLevelLenght = res.node_types.length;
        }
        this.treeInitialize();
      });
    } else {
      this.select_template_data = undefined;
      this.templateLevelLenght = -1;
      this.treeInitialize();

    }
  }

  /* --------- Functionality to select specific template end --------- */


  /* --------- Functionality to fetch all metadata set, used in case no template is selected start --------- */

  getAllMetadata() {
    const url = GlobalSettings.GET_NODETYPE_LIST;
    this.service.getServiceData(url).then((res: any) => {
      this.nodetypeData = res.nodetype;
      // console.log(JSON.stringify(res));
      from(res.nodetype)
        .filter((w: any) => w.title === 'Document')
        .subscribe(result => {
          this.default_document_node_type = result;
        });
      this.nodetypeData = this.nodetypeData.filter(function (elem) {
        return elem.title !== 'Document';
      });
    });
  }

  /* --------- Functionality to fetch all metadata set, used in case no template is selected ens --------- */

  /* --------- Functionality for keeping backend connection active for every 5 minutes in order to prevent session timeout start -------- */

  skipIdealActity() {
    this.intervalTimer = setInterval(() => this.getAllNodeTemplates(), 1000 * 60 * 5);
  }

  /* --------- Functionality for keeping backend connection active for every 5 minutes in order to prevent session timeout end -------- */

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.documentName = null;
  }

  onNameChange(event) {
    const valid = (event.target.value && (event.target.value.length > 0)) ? true : false;
    this.validEvent.emit(valid);
  }


  saveDocument() {
    if (this.taxonomyDataSubmission[0]['document_title'] && this.taxonomyDataSubmission[0]['document_title'].length > 0) {
      this.emptyFields = false;
      if (!this.emptyFields) {
        if (this.select_template_data !== undefined) {
          this.taxonomyDataSubmission[0].document_node_type_id = this.select_template_data.node_types[0].node_type_id;
        } else {
          if (this.default_document_node_type && this.default_document_node_type.node_type_id) {
            this.taxonomyDataSubmission[0].document_node_type_id = this.default_document_node_type.node_type_id;
          }
        }
        const url = GlobalSettings.BUILD_TAXONOMY;
        if (this.node_template_id) {
          this.taxonomyDataSubmission[0].node_template_id = this.node_template_id;
        }
        this.taxonomyDataSubmission[0].document_type = 1;
        this.service.postService(url, this.taxonomyDataSubmission[0]).then((res: any) => {
          this.sharedService.sucessEvent.next({
            type: 'taxonomy_add'
          });
          if (res) {
            this.document_id = res;
            this.taxonomyData[0]['children'][0]['document_id'] = this.document_id;
            this.taxonomyData[0]['children'][0]['id'] = this.document_id;
          }
          // this.treeInitialize();
          this.saveTaxonomy = false;
          this.documentCreated = true;
        }).catch((ex) => {
          console.log('createNode ', ex);
        });
      }
    } else {
      this.sharedService.sucessEvent.next({
        type: 'document_title_empty'
      });
    }
  }

  onToggelDragAndDrop(evt) {
    if (this.btnText === 'Reorder') {
      if (this.preventReorder === true && this.viewLocation !== 'project-auth') {
        evt.stopPropagation();
        evt.preventDefault();
        this.sharedService.sucessEvent.next({
          type: 'preventReorder'
        });
      } else {
        this.enableDND = true;
        this.btnText = 'Update';
        this.dragDropData = JSON.parse(JSON.stringify(this.taxonomyData)); // Reset Data
        this.oldData = JSON.parse(JSON.stringify(this.taxonomyData));
        this.nodeRearrengeEvent.emit({
          reArrenged: false,
          data: this.dragDropData
        });
      }
    } else {
      this.onUpdate();
    }
  }

  onCancelDragAndDrop() {
    this.enableDND = false;
    this.btnText = 'Reorder';
    this.dragDropData = JSON.parse(JSON.stringify(this.taxonomyData)); // Reset Data
    this.nodeRearrengeEvent.emit({
      reArrenged: false,
      data: this.dragDropData
    });
  }

  onTreeUpdate(data) {
    console.log('onTreeUpdate  ', data);
    this.nodeRearrengeEvent.emit({
      reArrenged: true,
      data: data
    });
  }

  onUpdate() {

    this.postAPIData = [];
    this.old_itemsData = [];
    let list_enumeration = 1;
    let sequence_number = 1;
    this.dragDropData.forEach(element => {
      this.createData(element, sequence_number, false);
      sequence_number++;
    });

    let old_sequence_number = 1;
    this.oldData.forEach(element => {
      this.createData(element, old_sequence_number, true);
      old_sequence_number++;
    });

    console.log('onTreeUpdate ', this.postAPIData);
    this.savingData = true;
    let body: any = null;
    if (this.viewLocation === 'project-auth') {
      body = {
        items: this.postAPIData,
        old_items: this.old_itemsData,
        type: 'project',
        project_id: this.associatedProjectId,
        taxonomy_type: 1
      };
    } else {
      body = {
        items: this.postAPIData,
        old_items: this.old_itemsData,
        type: '',
        project_id: '',
        taxonomy_type: 1
      };
    }
    this.service.postService(GlobalSettings.SET_TREE_HIERARCHY_ENHANCED + '/' + this.document_id, body).then((res) => {
      this.savingData = false;
      console.log('onTreeUpdate res ', res);
      this.taxonomyData = this.dragDropData;
      this.sharedService.sucessEvent.next({
        type: 'taxonomy_reorder_update'
      });
      this.onCancelDragAndDrop();
    }).catch((ex) => {
      this.savingData = false;
      console.log('Set Tree ', ex);
    });
  }

  createData(element, list_enumeration, isOld) {
    // Setting empty parent id of a node if node contains dummy parent id
    if (element.parent_id === Utils.DUMMY_ID_FOR_ORPHANLABEL) {
      element.parent_id = '';
    }
    if (element.level) {
      const obj: any = {
        item_id: element.id,
        parent_id: (element.id !== this.document_id) && element.parent_id ? element.parent_id : '',
        id: element.id,
        list_enumeration: '' + list_enumeration,
        sequence_number: '' + list_enumeration
      };
      if ((obj.id !== obj.parent_id) && (!element.isOrphanLabel)) {
        if (!isOld) {
          this.postAPIData.push(obj);
        } else {
          this.old_itemsData.push(obj);
        }
      }
    }
    if (element.children && element.children.length > 0) {
      for (let index = 0; index < element.children.length; index++) {
        const child = element.children[index];
        this.createData(child, index + 1, isOld);
      }
    }

  }

  updateSelectedObject(event) {

    if (event) {
      this.selectedNodeTemplate = event;
      this.selectTemplate();
    } else {
      this.selectedNodeTemplate = this.allNodeTemplateList[0];
    }

  }
  onClickedOutside(e) {
    this.autoComplete.openPanel(false);
  }

  setNodeTypeList(treeData) {
    treeData.forEach(node => {
      node.nodetypeList = [];
      if (node.id || node.item_id) {
        this.nodetypeData.forEach(type => {
          node.nodetypeList.push({
            node_type_id: type.node_type_id,
            title: type.title
          });
        });
      }

      if (node.children && node.children.length) {
        this.setNodeTypeList(node.children);
      }
    });
  }
}
