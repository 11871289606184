import {
  Component,
  OnInit
} from '@angular/core';
import {
  CommonService
} from 'src/app/common.service';
import {
  GlobalSettings
} from 'src/app/global.settings';
import {
  SharedService
} from 'src/app/shared.service';

@Component({
  selector: 'app-status-popup-window',
  templateUrl: './status-popup-window.component.html',
  styleUrls: ['./status-popup-window.component.scss']
})
export class StatusPopupWindowComponent implements OnInit {

  statusData = [];
  checkStatus = false;
  seconds = 60;
  firstLoad = false; // boolean flag to determine if the component is loaded first time
  hidePopup = true;
  constructor(private service: CommonService, private sharedService: SharedService) {
    this.sharedService.csvStatusUpdateEvent.subscribe((event: any) => {
      console.log('csvStatusUpdateEvent   ', event);
      if (event && event.checkStatus !== undefined && event.checkStatus) {
        this.checkStatus = event.checkStatus;
        this.checkCSVStatus();
      }
    });
  }

  ngOnInit() {
    this.checkCSVStatus();
  }

  checkCSVStatus() {
    // if (this.checkStatus) {
    const self = this;
    if (!this.firstLoad) {
      this.firstLoad = true;
      this.callCSVStatusService();
    } else {
      let refreshId = setInterval(function () {
        self.callCSVStatusService();
        if (!self.checkStatus) {
          // clearInterval(refreshId);
        }
      }, this.seconds * 1000);
    }
    // }
  }

  callCSVStatusService() {
    const url = GlobalSettings.CSV_STATUS + localStorage.getItem('user_id') + '?polling_for=taxonomy';
    let needToCallServiceAgain = false;
    this.service.getServiceData(url, true, false).then((res: any) => {
      this.statusData = res;
      if (this.statusData && this.statusData.length) {
        if (this.statusData.some((elem: any) => (elem.status !== 3 && elem.status !== 4))) {
          this.hidePopup = false;
          this.statusData.forEach(item => {
            if (item.import_taxonomy_setting) {
              switch (item.status) {
                case 1: // Not Started
                case 2: // Inprogress
                case 5: // Summary Started
                case 6: // View Summary
                  needToCallServiceAgain = true;
                  break;
                default:
                  break;
              }
            }
          });
          this.sharedService.hidePopup.next(false);
        } else {
          this.hidePopup = true;
          needToCallServiceAgain = true;
          this.sharedService.hidePopup.next(true);
        }
        this.checkStatus = needToCallServiceAgain;

      }
    }).catch((ex) => {
      console.log('checkCSVStatus  Ex', ex);
    });
  }

  onViewSummary(event, batch_id, isPre) {
    const urlPrefix = GlobalSettings.BASE_URL.replace('server/', '#').replace('api.', '') + this.service.getOrgDetails();
    let url = urlPrefix + '/' + 'app/preSummaryReport' + '/' + batch_id;
    if (isPre) {
      url = urlPrefix + '/' + 'app/preSummaryReport' + '/' + batch_id;

    } else {
      url = urlPrefix + '/' + 'app/postSummaryReport' + '/' + batch_id;
    }

    const currentUrl = sessionStorage.getItem('current_url');
    const frontFaceUrl = sessionStorage.getItem('frontface_url');
    sessionStorage.clear();
    var win = window.open(url, '_blank');;
    sessionStorage.setItem('current_url', currentUrl);
    sessionStorage.setItem('frontface_url', frontFaceUrl);
    win.focus();
    event.preventDefault();

  }

}
