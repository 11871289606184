import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-connection',
  templateUrl: './internet-connection.component.html'
})
export class InternetConnectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
