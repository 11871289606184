import {
    NgModule,
    NO_ERRORS_SCHEMA
} from '@angular/core';
import {
    CommonModule
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MathJaxComponent } from 'src/app/directive/mathjax.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [MathJaxComponent],
    exports: [MathJaxComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class MathJaxModule { }
