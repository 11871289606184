import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from '@angular/core';
import Utils from '../../utils';
@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit, OnChanges {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  selectedFiles: any[] = [];
  messages = [];
  children_taxonomies_count = 0;
  associations_count = 0;
  sucessUploadCount = 0;
  fileCount = 0;
  descTxt = '';
  titleTxt = '';
  fileSizeExceeds = false;
  alertMsg = 'File size exceeds 20 MB';
  @Input() disableFileUpload = false;
  @Input() label;
  @Input() disabled = true;
  @Input() helpText;
  @Input() loaderDisplay = false;
  @Output() uploadFilesEvent: EventEmitter<any>;
  @Output() deleteFileEvent: EventEmitter<any>;
  constructor() {
    this.uploadFilesEvent = new EventEmitter<any>();
    this.deleteFileEvent = new EventEmitter<any>();
  }

  ngOnInit() { }

  ngOnChanges() {
    /* if (this.selectedFiles.length === 0) {
       this.disableFileUpload = false;
     }*/

  }
  onFilesSelection() {
    console.log(' onFilesSelection  ', this.fileInput);
    let fileSize;
    if (this.fileInput) {
      const inputFile: HTMLInputElement = this.fileInput.nativeElement as HTMLInputElement;
      if (inputFile.files && inputFile.files[0]['size']) {
        if (inputFile.files[0]['size'] < 1073741824) {
          fileSize = (inputFile.files[0]['size'] / 1048576).toFixed(1);
        }
      }
      if (Number(fileSize) <= 20) {
        this.fileSizeExceeds = false;
        for (let index = 0; index < inputFile.files.length; index++) {
          console.log('this.selectedFiles.length', this.selectedFiles.length);
          this.selectedFiles.push(inputFile.files[index]);
          // this.uploadFiles(inputFile.files[0], 0);
          this.fileSizeExceeds = false;
        }
        console.log(' onFilesSelection  ', inputFile.files[0]['size']);
      } else {
        this.fileSizeExceeds = true;
      }
    }

    if (this.fileCount === this.selectedFiles.length) {
      console.log('user click cancel button');
    } else {
      const length = this.selectedFiles.length;
      this.fileCount = length;
      if (length > 0) {
        const data = {
          'file': this.selectedFiles[length - 1],
          'len': length - 1,
          'val': false,
          title: this.titleTxt,
          desc: this.descTxt
        };
        this.uploadFilesEvent.emit(data);
        console.log(' onFilesSelection  ', this.selectedFiles[length - 1].name);
      }
    }
  }

  formatBytes(bytes) {
    return Utils.formatBytes(bytes);
  }

  deleteFile(index) {
    if (this.selectedFiles.length > index) {
      this.selectedFiles.splice(index, 1);
    }
    this.fileCount = 0;
    this.deleteFileEvent.emit(index);
  }

  deleteFileFromUI(id) {
    for (let index = 0; index < this.selectedFiles.length; index++) {
      const element = this.selectedFiles[index];
      if (id === element.item_association_id) {
        this.selectedFiles.splice(index, 1);
        break;
      }
    }
  }

  clearData() {
    this.titleTxt = '';
    this.descTxt = '';
  }

  setUploaedFile(fileInfo) {
    this.selectedFiles = [];
    this.fileCount = 0;
    /* if (this.selectedFiles && this.selectedFiles.length > fileInfo.returnIndex) {
       this.selectedFiles[fileInfo.returnIndex]['item_association_id'] = fileInfo.item_association_id;
     }*/
  }

  onCloseAlert() {
    this.fileSizeExceeds = false;
  }

}
