import {
  Component,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ConfirmationDialogService
} from '../../confirmation-dialog/confirmation-dialog.service';
import {
  SharedService
} from '../../shared.service';
import {
  CommonService
} from '../../common.service';
import {
  GlobalSettings
} from '../../global.settings';
import {
  FormGroup,
  FormControl
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import Utils from '../../utils';

@Component({
  selector: 'app-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./profile.scss']
})
export class AccessRequestComponent implements OnInit {
  projectRequestList: any[];

  projects = []; // holds all projects
  roles = []; // holds all roles
  currentUser : any; // holds user details of current request
  selectedRole = []; // holds array of selected roles
  selectedProject = this.getDefaultProject(); // holds current or selected project
  selectedProjectId; // holds current or selected project id
  workflow_id; // holds current workflow id
  comment; // holds comment for decline request
  action; // holds the action to be performed accept,reject,edit
  enableEdit = false; // validation for edit button
  allRequest;
  pending; // pending request
  approved = []; // approved request
  declined = []; // declined request
  selectedProjectName = ''; // holds the selected project name
  defaultRole = null;
  project_dd: FormControl;
  role_dd: FormControl;
  form: FormGroup;
  declineForm: FormGroup;
  roleDeleted = false;
  // ----------------for Common Data table----------------- //
  listOfColumn_pr = [{
    name: 'Projects',
    propName: 'project_name',
    class: '',
    type: 'text'
  },
  {
    name: 'Name',
    propName: 'user_name',
    class: '',
    type: 'link',
    redirect: false,
    modal: '#user-profile'
  },
  {
    name: 'Email',
    propName: 'user_email',
    class: '',
    type: 'text'
  },
  {
    name: 'Role',
    propName: 'workflow_stage_roles',
    class: '',
    type: 'text'
  },
  {
    name: 'Date',
    propName: 'date_of_application',
    class: '',
    type: 'text'
  }
  ];
  listOfColumn_aprvd = [{
    name: 'Projects',
    propName: 'project_name',
    class: '',
    type: 'text'
  },
  {
    name: 'Name',
    propName: 'user_name',
    class: '',
    type: 'link',
    redirect: false,
    modal: '#user-profile'
  },
  {
    name: 'Email',
    propName: 'user_email',
    class: '',
    type: 'text'
  },
  {
    name: 'Role',
    propName: 'workflow_stage_roles',
    class: '',
    type: 'text'
  },
  {
    name: 'Date',
    propName: 'date_of_application',
    class: '',
    type: 'text'
  }
  ];
  listOfColumn_dclnd = [{
    name: 'Projects',
    propName: 'project_name',
    class: '',
    type: 'text'
  },
  {
    name: 'Name',
    propName: 'user_name',
    class: '',
    type: 'link',
    redirect: false,
    modal: '#user-profile'
  },
  {
    name: 'Email',
    propName: 'user_email',
    class: '',
    type: 'text'
  },
  {
    name: 'Role',
    propName: 'workflow_stage_roles',
    class: '',
    type: 'text'
  },
  {
    name: 'Date of application',
    propName: 'date_of_application',
    class: '',
    type: 'text'
  },
  {
    name: 'Date of rejection',
    propName: 'date_of_last_status_change',
    class: '',
    type: 'text'
  },
  {
    name: 'Rejected by',
    propName: 'updated_by_name',
    class: '',
    type: 'text'
  }
  ];

  optionList = [];
  optionList_declined = [];
  selectedRequestType = 'pending'; // default project request type
  @Output() accessCountEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private service: CommonService,
    private sharedService: SharedService,
    private dialogService: ConfirmationDialogService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      project_dd: new FormControl(null),
      role_dd: new FormControl(null)
    });
    this.declineForm = new FormGroup({
      reason: new FormControl(null)
    });
    // ----------------for Common Data table----------------- //
    this.optionList = [{
      name: 'Accept',
      type: 'acceptPR',
      value: true,
      modal: '#accept-request',
      check: 'isAccepted'
    },
    {
      name: 'Reject',
      type: 'rejectPR',
      value: true,
      modal: '#decline-request',
      check: 'isRejected'
    },
    {
      name: 'Edit',
      type: 'editPR',
      value: true,
      modal: '#accept-request',
      check: 'isEditable'
    }
    ];
    this.optionList_declined = [{
      name: 'Accept',
      type: 'acceptPR',
      value: true,
      modal: '#accept-request',
      check: 'isAccepted'
    },
    {
      name: 'Edit',
      type: 'editPR',
      value: true,
      modal: '#accept-request',
      check: 'isEditable'
    }
    ];
    this.getAllProjectAccessRequest();
    this.getAllOrganizationWorkflows();
  }

  // On click Menu option

  onOptionClicked(event) {
    switch (event.clickedOn) {
      case 'acceptPR':
        this.populateRequestModal(event.data, 'accept');
        break;
      case 'rejectPR':
        this.populateRequestModal(event.data, 'decline');
        break;
      case 'editPR':
        this.populateRequestModal(event.data, 'edit');
        break;
      default:
    }
  }

  populateRequestModal(userData, action) {
    setTimeout(() => {
      focusOnModalCloseBtn();
    }, 500);
    this.selectedProjectId = '';
    this.comment = '';
    this.enableEdit = true;
    this.currentUser = userData;
    this.defaultRole = null;
    // this.roles = [];
    // this.projects = [];
    this.selectedRole = [];
    this.action = action;
    if (!this.selectedProjectId) {
      this.selectedProjectId = this.currentUser['project_id'];
      this.selectedProjectName = this.currentUser['project_name'];
    }
    this.getRoles();
    this.getCurrentRequestRoles();
    this.getCurrentRequestProject();
    // console.log('userData', JSON.stringify(this.currentUser));
  }

  /* --------- Functionality for fetching the list of all request start --------- */

  getAllProjectAccessRequest() {
    this.approved = [];
    this.declined = [];
    const url = GlobalSettings.PROJECT_ACCESS_REQUEST;
    this.service.getServiceData(url).then((res: any) => {
      // this.allRequest = [];
      this.pending = [];
      res.forEach(element => {
        // this.allRequest.push(element);
        if (element.status === 2) {
          this.approved.push(element); // accepted
        } else if (element.status === 3) {
          this.declined.push(element); // rejected
        } else if (element.status === 1) {
          this.pending.push(element); // pending
        }
      });
      this.pending.forEach(pr => {
        pr.isAccepted = true;
        pr.isRejected = true;
        pr.isEditable = true;
        pr.showOptions = (!pr.isAccepted && !pr.isRejected && !pr.isEditable) ? false : true;
      });
      this.declined.forEach(pr => {
        pr.isAccepted = true;
        pr.isEditable = true;
        pr.showOptions = (!pr.isAccepted && !pr.isEditable) ? false : true;
      });
      this.accessCountEvent.emit(this.pending.length);
    }).catch((ex) => {
      console.log('Error caught while fetching metadata list', ex);
    });
  }

  /* --------- Functionality for fetching the list of all request end --------- */


  /* --------- Functionality to get current requester details start --------- */

  getCurrentRequestDetails(user) {
    this.currentUser = user;
    setTimeout(() => {
      focusOnModalCloseBtn();
    }, 500);
  }

  /* --------- Functionality to get current requester details end --------- */


  /* --------- Functionality to get current requester roles start --------- */

  getCurrentRequestRoles() {
    const roleNames = this.currentUser['workflow_stage_roles'];
    const roleIds = this.currentUser['workflow_stage_role_ids'];
    for (let i = 0; i < roleIds.length; i++) {
      this.selectedRole.push({
        'roleName': roleNames[i],
        'roleId': roleIds[i]
      });
    }
  }

  /* --------- Functionality to get current requester roles end --------- */


  /* --------- Functionality to get all workflow roles start --------- */

  getAllOrganizationWorkflows() {
    const url = GlobalSettings.GET_WORKFLOW;
    this.service.getServiceData(url).then((res: any) => {
      this.workflow_id = res.WorkflowList.workflows[0].workflow_id;
      // this.getRoles();
      this.getProjects();
    }).catch((ex) => {
      console.log('notifications component getAllOrganizationWorkflows error occured', ex);
    });
  }

  getRoles() {
    this.roles = [];
    const url = GlobalSettings.GET_All_WORKFLOW_ROLES + '/' + this.workflow_id;
    this.service.getServiceData(url).then((res: any) => {
      res.forEach(element => {
        this.roles.push({
          'roleName': element.role_name,
          'roleId': element.workflow_stage_role_id
        });
      });
    }).catch((ex) => {
      console.log('notifications component getRoles error occured', ex);
    });
  }

  /* --------- Functionality to get all workflow roles end --------- */


  /* --------- Functionality to selected roles start --------- */

  deleteRole(role) {
    this.selectedRole = this.selectedRole.filter(obj => {
      return obj.roleId !== role.roleId;
    });
    this.roleDeleted = true;
    this.checkValidation();
  }

  /* --------- Functionality to selected roles end --------- */


  /* --------- Functionality to fetch all projects start --------- */

  getProjects() {
    const url = GlobalSettings.PROJECT_LIST;
    this.service.getServiceData(url).then((res: any) => {
      const projectList = res.projects;
      this.projects.push(this.getDefaultProject());
      projectList.forEach(element => {
        this.projects.push({
          'projectName': element.project_name,
          'projectId': element.project_id,
          workflow_id: element.workflow_id
        });
      });
      this.selectedProject = this.projects[0];
    }).catch(ex => {
      console.log('notifications component list of project ex ', ex);
    });
  }

  getCurrentRequestProject() {
    this.projects.forEach(element => {
      if (element.projectId === this.currentUser['project_id']) {
        this.selectedProject = element;
      }
    });
    console.log('this.selectedProject', this.selectedProject);
    this.selectedProjectId = this.selectedProject['projectId'];
  }

  getDefaultProject() {
    return {
      projectName: 'Select Project',
      projectId: '',
    };
  }

  /* --------- Functionality to fetch all projects end --------- */


  /* --------- Functionality to accept or decline request start --------- */

  actionOnRequest(action, event) {
    event.stopPropagation();
    event.preventDefault();
    // console.log(JSON.stringify(this.selectedRole), JSON.stringify(this.selectedProject));
    let roles = '';
    if (this.selectedRole.length > 0) {
      this.selectedRole.forEach(obj => {
        roles = roles + obj.roleName + ',';
      });
      roles = roles.substring(0, roles.length - 1);
    }
    if (!this.selectedProjectId) {
      // console.log('this.currentUser', JSON.stringify(this.currentUser));
      this.selectedProjectId = this.currentUser['project_id'];
      this.selectedProjectName = this.currentUser['project_name'];
    }
    const message = 'Are you sure you want to ' + action + ' ' + roles +
      ' roles to ' + this.currentUser['user_name'] + ' for ' + this.selectedProjectName + ' project?';
    this.dialogService.confirm('Confirm', message)
      .then((confirmed) => {
        if (confirmed) {
          const url = GlobalSettings.PROJECT_ACCESS_REQUEST + '/' + this.currentUser['project_access_request_id'];
          const roleIds = [];
          let messageType = '';
          this.selectedRole.forEach(element => {
            roleIds.push(element.roleId);
          });
          const requestAcceptObj = {
            'project_id': this.selectedProjectId,
            'workflow_stage_role_ids': roleIds,
            'status': (action === 'decline' ? 3 : 2),
            'comment': (action === 'decline' ? this.comment : '')
          };
          this.service.putService(url, requestAcceptObj).then((res: any) => {
            if (action === 'decline') {
              messageType = 'reject_project_request';
            } else {
              messageType = 'accept_project_request';
            }
            this.sharedService.sucessEvent.next({
              type: messageType
            });
            this.onCancel();
            // document.getElementById(action).click();
            setTimeout(() => {
              if (document.getElementById('cancel-modal-btn-' + action + '-request')) {
                document.getElementById('cancel-modal-btn-' + action + '-request').click();
              }
            }, 600);
            this.getAllProjectAccessRequest();
          }).catch((ex) => {
            console.log('createNodeTemplate error occured', ex);
          });
          this.selectedProjectId = '';
          this.selectedProjectName = '';
        } else {
          console.log('User cancel the dialog while confirming');
        }
      })
      .catch(() => {
        console.log('User dismissed the dialog while confirming');
      });
  }

  /* --------- Functionality to accept or decline request end --------- */


  /* --------- Functionality to select role and update role list start --------- */

  selectRole(role) {
    let roleName;
    let roleId;
    let flag = 0;
    if (role !== 'null') {
      [roleId, roleName] = role.split('#||#');
      this.selectedRole.forEach(obj => {
        if (obj.roleId === roleId) {
          flag = 1;
        }
      });
      if (flag === 0) {
        this.selectedRole.push({
          'roleName': roleName,
          'roleId': roleId
        });
      } else {
        this.sharedService.sucessEvent.next({
          type: 'role_mapping_exists'
        });
      }
    }
    this.checkValidation();
  }

  /* --------- Functionality to select role and update role list end --------- */


  /* --------- Functionality to select project start --------- */

  selectProject() {
    console.log('Selected project', JSON.stringify(this.selectedProject));
    this.selectedProjectId = this.selectedProject['projectId'];
    this.selectedProjectName = this.selectedProject['projectName'];
    this.workflow_id = this.selectedProject['workflow_id'];
    this.selectedRole = [];
    this.defaultRole = null;
    this.checkValidation();
    this.getRoles();
  }

  /* --------- Functionality to select project start --------- */

  /* --------- On excape key press function start --------- */
  onMouseCall(event) {
    if (event.keyCode === 27) {
      this.onCancel();
    }
  }
  /* --------- On excape key press function end --------- */


  /* --------- Functionality on canceling the modal start --------- */

  mouseLeave() {
    const modal = document.getElementById('accept-request');
    if (event.target !== modal) {
      this.onCancel();
    }

  }

  onCancel() {
    this.selectedProject = this.projects[0];
    this.defaultRole = null;
    this.enableEdit = false;
    this.selectedProjectId = '';
    this.roleDeleted = false;
    if (this.form) {
      this.form.reset();
    }
    if (this.declineForm) {
      this.declineForm.reset();
    }
    // document.getElementById('selectDefaultRole').click();
    // this.selectedRole = [];
    // this.selectedProject = ;
  }

  /* --------- Functionality on canceling the modal end --------- */


  checkValidation() {
    if (this.selectedProjectId && this.selectedProjectId.length > 0 && this.selectedRole.length > 0) {
      this.enableEdit = true;
    } else {
      this.enableEdit = false;
    }
  }

  onProjectSelect(data) {
    if (data.project_id) {
      // this.router.navigate(['./app/project/detail', data.project_id]);
      const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PROJECT_DETAIL + data.project_id;
      this.router.navigate([path]);
    }
  }

}
