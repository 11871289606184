import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy
} from '@angular/core';
import {
  SharedService
} from '../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import Utils from '../utils';
@Component({
  selector: 'app-tree-accordian',
  templateUrl: './tree-accordian.component.html',
  styleUrls: ['./tree-accordian.component.scss', './tree-reorder-success.scss']
})
export class TreeAccordianComponent implements OnInit, OnChanges, OnDestroy {

  @Input() treeData: any;
  @Input() level: 0;
  @Output() selectedEvent: EventEmitter<any>;
  @Input() selectedNodeId = null;
  @Input() viewLocation = '';
  @Input() treeType = 'treeview';
  nodeSelectedEvent: Subscription;
  eventSubscription: Subscription;
  openTreeNodes = false;

  constructor(private sharedService: SharedService) {
    this.selectedEvent = new EventEmitter<any>();
    this.sharedService.treeNodeSelectedEvent.subscribe((item: any) => {

      if (item && item.location === this.viewLocation) {
        if (item && item.item_id) {
          this.selectedNodeId = item.item_id;
        }
        if (item && item.id) {
          this.selectedNodeId = item.id;
        }
      }
    });

    this.eventSubscription = this.sharedService.openTree.subscribe((events: any) => {
      console.log('events.openTree', events.openTree);
      if (events.openTree !== undefined && events.openTree === true) {
        this.openTreeNodes = events.openTree;

      }
    });
  }


  ngOnInit() {
    if (this.treeData) {
      setTimeout(() => {

        this.treeData.forEach(element => {
          if (element.children && element.children.length > 0) {
            if (element.expand) {
              const id = element.id;
              if (document.getElementById(id + '-atag')) {
                if (document.getElementById(id + '-atag').getAttribute('aria-expanded') === 'false') {
                  document.getElementById(id + '-atag').click();
                }
              }
            }
          }
        }, 1000);
      });
    }

  }
  calculatePadding(level) {
    return Utils.calculatePadding(level, this.treeType);
  }

  ngOnChanges() {
    if (this.treeData) {
      setTimeout(() => {
        this.treeData.forEach(element => {
          if (element.children && element.children.length > 0) {
            if (element.expand) {
              const id = element.id;
              if (document.getElementById(id + '-atag')) {
                if (document.getElementById(id + '-atag').getAttribute('aria-expanded') === 'false') {
                  document.getElementById(id + '-atag').click();
                }
              }
            }
          }
        }, 1000);
      });
    }
  }


  onNodeSelected(item) {
    if (item.id) {
      this.selectedNodeId = item.id;
    }
    if (item.item_id) {
      this.selectedNodeId = item.item_id;
    }
    item.location = this.viewLocation;
    console.log('onNodeSelected ------------- ', item);
    // this.selectedEvent.emit(item);
    this.sharedService.treeNodeSelectedEvent.next(item);
  }

  addNode(item) {
    item.location = this.viewLocation;
    console.log('onNodeSelected ------------- ', item);
    // this.selectedEvent.emit(item);
    this.sharedService.treeAddNodeEvent.next(item);
  }
  deleteNode(item) {
    item.location = this.viewLocation;
    console.log('onNodeSelected ------------- ', item);
    // this.selectedEvent.emit(item);
    this.sharedService.treeDeleteNodeEvent.next(item);
  }

  expendNode(id) {
    // console.log('expendNode ', document.getElementById(id + '-atag'));
    if (document.getElementById(id + '-atag')) {
      if (document.getElementById(id + '-atag').getAttribute('aria-expanded') === 'false') {
        document.getElementById(id + '-atag').click();
        document.getElementById(id + '-atag').setAttribute('aria-expanded', 'true');
      }
    }
  }


  ngOnDestroy() {
    if (this.nodeSelectedEvent) {
      this.nodeSelectedEvent.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}
