import { Component, OnInit, OnDestroy, ViewChild, Input, Output, OnChanges, EventEmitter, ViewChildren, QueryList, SimpleChanges } from '@angular/core';
import Utils from '../../utils';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { TreeTabComponent } from 'src/app/tree-tab/tree-tab.component';
@Component({
  selector: 'app-table-configuration',
  templateUrl: './table-configuration.component.html',
  styleUrls: ['./table-configuration.component.scss', './../../taxonomy/custom-taxonomy-view/custom-taxonomy-view.component.scss']
})
export class TableConfigurationComponent implements OnInit, OnChanges {
  @Input() currentData;
  // @Input() columnList;
  @Input() location;
  @Input() defaultColumnListData;
  @Input() defaultNodeTypeListData;
  @Input() additionalColumnList;
  @Input() canSetDefaultForAll;
  @Input() dropdownData;
  @Input() emitAddColEvent;
  @Input() currentTreeTab;
  @Input() text = '';
  @Output() savetableColEvent: EventEmitter<any>;
  @Output() revertChangesEvent: EventEmitter<any>;
  @Output() toggleColumnViewEvent: EventEmitter<any>;
  @Output() addColumnEvent: EventEmitter<any>;
  @Output() enableSaveEvent: EventEmitter<any>;
  @Output() cancelEvent: EventEmitter<any>;
  @ViewChild('autoComplete', { static: false }) autoComplete: AutoCompleteComponent;
  @ViewChildren('autoComplete_metadata') autoCompleteMetadata: QueryList<AutoCompleteComponent>;
  @ViewChildren('metadataList') metadataListDropdown: QueryList<AutoCompleteComponent>;
  @ViewChild('treeTab', {
    static: false
  }) treeTab: TreeTabComponent;
  columnList = [];
  defaultColumnList = [];
  treeTabItems = ['Metadata Fields', 'Node Type'];
  dragulaName = 'tableColumnContainer';
  selectType = 'single';
  uniqueId;
  autoCompleteTitle = 'Add Columns';
  userDefault: any;
  allUserDefault: any;
  metadataList = [];
  dropdownSettings = {
    textField: 'display_name',
    showLabel: true,
    buttonType: 'button'
  };
  selectedOptionList = [];
  showDropdown = false;
  isFirstTime = true;

  constructor() {

    this.savetableColEvent = new EventEmitter<any>();
    this.revertChangesEvent = new EventEmitter<any>();
    this.toggleColumnViewEvent = new EventEmitter<any>();
    this.addColumnEvent = new EventEmitter<any>();
    this.enableSaveEvent = new EventEmitter<any>();
    this.cancelEvent = new EventEmitter<any>();

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentData && changes.currentData.currentValue) {
      this.columnList = null;
      this.columnList = JSON.parse(JSON.stringify(this.currentData.taxonomy_list_setting));
      this.userDefault = this.currentData.is_user_default ? true : false;
      this.allUserDefault = this.currentData.default_for_all_user ? true : false;
      this.uniqueId = this.dropdownData.uniqueId;

      if (this.columnList && this.columnList.every(col => !col.hasOwnProperty('order'))) {
        for (let i = 0; i < this.columnList.length; i++) {
          this.columnList[i]['order'] = i + 1;
        }
      }
    }
    if (changes.defaultColumnListData && changes.defaultColumnListData.currentValue) {
      this.defaultColumnList = this.defaultColumnListData.taxonomy_list_setting;
      if (this.defaultColumnList && this.defaultColumnList.every(col => !col.hasOwnProperty('order'))) {
        for (let i = 0; i < this.defaultColumnList.length; i++) {
          this.defaultColumnList[i]['order'] = i + 1;
        }
      }
    }

    if (this.defaultColumnList && this.defaultColumnList.length) {
      setTimeout(() => {
        this.setDefaultWidth();
      }, 1000);
    }
  }


  setDefaultWidth() {
    if (document.getElementById('split_area')) {
      const indivWidth = document.getElementById('split_area').offsetWidth / this.defaultColumnList.length;
      this.defaultColumnList.forEach(col => {
        col.width = indivWidth;
      });
      this.columnList.forEach(col => {
        if (!col.width) {
          col.width = indivWidth;
        }
      });
    }
  }

  onTreeTabSelected(e) {
    this.currentTreeTab = e;
    this.onCancel();
    this.toggleColumnViewEvent.emit(this.currentTreeTab);
  }

  revertChanges() {
    this.columnList = null;
    this.columnList = JSON.parse(JSON.stringify(this.defaultColumnList));
  }

  addNewCol(col) {
    col.order = this.columnList.length + 1;
    col.display_name = col.name ? col.name : col.title ? col.title : col.display_name;
    col.is_custom = col.is_custom !== undefined ? col.is_custom : null;
    col.class = '';
    col.customCol = true;
    col.isAdded = true;
    col.internal_name = col.internal_name ? (col.internal_name.toLowerCase().replace(/ /gi, '_')) :
      col.is_custom ? (col.display_name.toLowerCase().replace(/ /gi, '_') + '_' + col.metadata_id) :
        (col.display_name.toLowerCase().replace(/ /gi, '_'));
    col.propName = col.internal_name;
    col.canRemove = true;
    col.canFilter = true;
    if (this.location === 'listing') {
      col.type = 'text';
    }
    if (this.emitAddColEvent) {
      this.addColumnEvent.emit(col);
    }
    this.columnList.push(col);
    const totalPanelWidth = document.getElementById('split_area').offsetWidth;
    const colWidth = totalPanelWidth / this.columnList.length;
    this.columnList.forEach((column: any) => {
      column.width = colWidth;
    });
    this.checkValidation();
    setTimeout(() => {
      col.isAdded = false;
    }, 1000);

  }

  deleteCol(event: Event, col) {
    event.stopPropagation();
    const index = this.columnList.indexOf(col);
    for (let i = (index + 1); i < this.columnList.length; i++) {
      this.columnList[i]['order'] = (this.columnList[i]['order'] - 1);
    }
    this.columnList.splice(index, 1);
    this.autoComplete.unselectObjInList(col);
    this.checkValidation();
  }

  onCancel() {

    this.autoComplete.clearSelection();
    for (const item of this.autoCompleteMetadata.toArray()) {
      item.clearSelection();
    }
    if (this.treeTab) {
      this.treeTab.previousSelectedTab = '';
    }
  }

  onClickedOutside(e, type, index?) {
    if (this.uniqueId) {
      if (type === 'column') {
        this.autoComplete.openPanel(false);
      } else {
        for (let i = 0; i < this.autoCompleteMetadata.toArray().length; i++) {
          if (i === index) {
            this.autoCompleteMetadata.toArray()[i].openPanel(false);
            break;
          }
        }
      }
    }
  }

  saveChanges() {
    if (this.isFirstTime) {
      this.getColumnWidth();
    }
    this.savetableColEvent.emit({
      columnList: this.columnList,
      userDefault: this.userDefault ? 1 : 0,
      allUserDefault: this.allUserDefault ? 1 : 0,

    });
  }

  changeList(e) {
    let array = JSON.parse(JSON.stringify(e));
    this.columnList = [];
    this.columnList = array;
    array = null;
  }

  checkValidation() {
    if ((this.userDefault || this.allUserDefault) && this.columnList.length) {
      this.enableSaveEvent.emit(true);
    } else {
      this.enableSaveEvent.emit(false);
    }
  }

  cancelChanges() {
    this.columnList = null;
    this.columnList = JSON.parse(JSON.stringify(this.currentData.taxonomy_list_setting));
    this.userDefault = this.currentData.is_user_default;
    this.allUserDefault = this.currentData.default_for_all_user;
  }

  updateSelection(event, col) {
    col.metadataSelected = true;
    col.metadata.forEach(metadata => {
      if (event.selectedData.find(data => data.metadata_id === metadata.metadata_id)) {
        metadata.selectDisplay = true;
        metadata.internal_name = metadata.is_custom ? (metadata.display_name.toLowerCase().replace(/ /gi, '_') + '_' + metadata.metadata_id) : metadata.internal_name;
      } else {
        metadata.selectDisplay = false;
      }
    });

  }

  checkDropdownEvent(index?: number) {
    const dropdownList = this.metadataListDropdown.toArray();
    this.showDropdown = false;

    for (let i = 0; i < dropdownList.length; i++) {
      if (!dropdownList[i]['showDropdown']) {
        if (this.columnList[i]) {
          this.columnList[i].showDropdown = false;
        }
      } else {
        if (this.columnList[i]) {
          this.columnList[i].showDropdown = true;
        }
        this.showDropdown = true;
        break;
      }
    }


  }

  columnResized(event: any) {
    this.isFirstTime = false;
    for (let i = 0; i < event.sizes.length; i++) {
      if (event.sizes[i] === '*') {
        this.columnList[i].width = document.getElementById('col_' + i).offsetWidth;
      } else {
        this.columnList[i].width = event.sizes[i];
      }
    }
  }

  getColumnWidth() {
    for (let i = 0; i < this.columnList.length; i++) {
      this.columnList[i].width = document.getElementById('col_' + i).offsetWidth;
    }
  }


}

