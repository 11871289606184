import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-compliance-progress',
  templateUrl: './compliance-progress.component.html',
  styleUrls: ['./compliance-progress.component.scss']
})
export class ComplianceProgressComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() progressDone: number;
  @Input() progressTotal: number;
  @Input() progressPercentage: number;
  @Input() progressColor: string; // 'Y' for yellow, 'R' for red and 'G' for green
  @Input() viewLocation = '';
  constructor() { }

  ngOnInit() {
    this.convertToLowercase();
  }

  ngOnChanges(changes:SimpleChanges) {
    this.convertToLowercase();
    if (isNullOrUndefined(this.progressPercentage)) {
      this.setDefaultPercentage();
    }
    if ((isNullOrUndefined(this.progressDone) || isNullOrUndefined(this.progressTotal))) {
      this.setDefaultItemsCompleted();
    }
    if (isNullOrUndefined(this.progressColor)) {
      this.setDefaultColor();
    }
    this.setProgressColor();
  }

  convertToLowercase() {
    if (this.progressColor) {
      this.progressColor = this.progressColor.toLowerCase();
    }
  }

  setDefaultPercentage() {
    this.progressPercentage = 0;
  }

  setDefaultItemsCompleted() {
    this.progressDone = 0;
    this.progressTotal = 0;
  }

  setDefaultColor() {
    this.progressColor = 'y';
  }

  // Setting progress color according to range
  setProgressColor() {
    if (this.progressPercentage >= 0 && this.progressPercentage < 50) {
      this.progressColor = 'r'; // Red
    } else if (this.progressPercentage > 49 && this.progressPercentage < 76) {
      this.progressColor = 'y'; // Yellow
    } else if (this.progressPercentage > 75 && this.progressPercentage <= 100) {
      this.progressColor = 'g'; // Green
    }
  }

}
