import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import {
  SharedService
} from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input() title;
  @Input() dataLength;
  @Input() accordionNo;
  @Input() expanded;
  @Input() addModalId;
  @Input() showAddButton;
  @Input() showEmptyFields = true;
  @Input() currentTreeTab;
  @Output() addButtonClickedEvent = new EventEmitter<any>();

  @ViewChild('accordionContent', { static: false }) accordionContent;

  constructor(private sharedService: SharedService) {
    this.sharedService.treeNodeSelectedEvent.subscribe((item: any) => {
      // this.expanded = false;
      if (this.accordionNo) {
        if (document.getElementById('collapse_' + this.accordionNo)) {
          if (this.accordionNo === 1 || this.accordionNo === '1_modal') {
            this.expanded = true;
            document.getElementById('collapse_' + this.accordionNo).classList.add('show');
          } else {
            this.expanded = false;
            document.getElementById('collapse_' + this.accordionNo).classList.remove('show');
          }
        }
      }
    });
  }

  ngOnInit() {
  }

  onArrowClicked(event) {
    const elem = event.target;
    this.expanded = !this.expanded;
    // if (elem.getAttribute('aria-expanded') === 'true') {
    //   this.expanded = false;
    // } else {
    //   this.expanded = true;
    // }
  }

  onAddButtonClick(evt) {
    // console.log('#### $$$', evt);

    // this.addButtonClickedEvent.emit();
    // console.log('#### $$$', evt, this.currentTreeTab);

    if (evt === 'taxonomyAssociation') {
      this.sharedService.openTree.next({
        addAssociation: true,
        oldAddAssociationClicked: false
      });
    } else {
      if (evt === 'add-association') {
        this.sharedService.openTree.next({
          openTree: true,
          addAssociation: false,
          oldAddAssociationClicked: true
        });
      } else {
        this.sharedService.openTree.next({
          openTree: false,
          addAssociation: false,
          oldAddAssociationClicked: false
        });
      }
    }

    this.addButtonClickedEvent.emit();
  }
}
