import {
    NgModule,
    NO_ERRORS_SCHEMA
} from '@angular/core';
import {
    CommonModule
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloaderComponent } from 'src/app/common/preloader/preloader.component';
import { ProtipComponent } from 'src/app/help/protip/protip.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [PreloaderComponent, ProtipComponent],
    exports: [PreloaderComponent, ProtipComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class PreLoaderModule { }
