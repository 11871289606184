import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metadata-version',
  templateUrl: './metadata-version.component.html',
  styleUrls: ['./metadata-version.component.scss']
})
export class MetadataVersionComponent implements OnInit {

  listOfColumn = []; // holds list of column headers in version-summary table
  dataArray = []; // holds the data array to be shown in version-summary table
  defaultSortByColumn: any; // holds the column object by which the table data will be sorted by default
  pageDetail: any; // holds object for pagination details

  constructor() { }

  ngOnInit() {
    this.listOfColumn = [
      {
        name: 'New Metadata',
        propName: 'title',
        class: '',
        type: 'text'
      },
      {
        name: 'Path',
        propName: 'title',
        class: '',
        type: 'text'
      }
    ];
    this.defaultSortByColumn = this.listOfColumn[2];
  }

  // FUNCTION to change pagination
  getChanges(evt) {
  }
}
