import { Component, ElementRef, OnChanges, Input } from '@angular/core';

declare var MathJax: {
    Hub: {
        Queue: (param: Object[]) => void;
        Config: (param) => void;
    }
};

@Component({
    selector: '[mathJax]',
    template: ''
})
export class MathJaxComponent implements OnChanges {

    @Input() mathJax: string;

    constructor(private element: ElementRef) { }

    ngOnChanges() {
        // setTimeout(() => {
        const de = /{{ '{' }}/gi;
        if (this.mathJax && this.mathJax.length && this.mathJax.replace) {
            this.element.nativeElement.innerHTML = !!this.mathJax ? this.mathJax.replace(de, "{") : '';
            MathJax.Hub.Config({ tex2jax: { inlineMath: [['$', '$'], ['`', '`'], ['\\(', '\\)']] } });
            MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.element.nativeElement]);
        } else {
            this.element.nativeElement.innerHTML = '';
            // MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.element.nativeElement]);
        }
        // }, 300);
    }
}