import {
  NgModule
} from '@angular/core';
import {
  RouterModule,
  Routes,
  PreloadAllModules

} from '@angular/router';
import {
  LoginComponent
} from './login/login.component';
import {
  BaseComponent
} from './base/base.component';
import {
  RegisterComponent
} from './register/register.component';
import {
  ConfirmationComponent
} from './confirmation/confirmation.component';
import {
  PageNotFoundComponent
} from './page-not-found/page-not-found.component';
import {
  ResetPasswordComponent
} from './reset-password/reset-password.component';
import {
  SearchComponent
} from './common/search/search-panel/search-panel.component';

import {
  NotificationsComponent
} from './notifications/notifications.component';
import {
  FrontFacingComponent
} from './front-face/front-facing-page/front-facing.component';
import {
  FrontFacingTaxonomiesComponent
} from './front-face/front-facing-taxonomies/front-facing-taxonomies.component';
import {
  FrontFacingPacingGuidesComponent
} from './front-face/front-facing-pacing-guides/front-facing-pacing-guides.component';
import {
  FrontFacingDocumentsComponent
} from './front-face/front-facing-documents/front-facing-documents.component';
import {
  FrontFacingHomeComponent
} from './front-face/front-facing-home/front-facing-home.component';
import {
  AppComponent
} from './app.component';
import {
  AuthGuardService as AuthGuard
} from '../../src/app/auth-guard.service';
import {
  FrontFacingTaxonomyDetailComponent
} from './front-face/front-facing-taxonomy-detail/front-facing-taxonomy-detail.component';
import {
  TabularTreeViewComponent
} from './common/tabular-tree-view/tabular-tree-view.component';
import {
  AcceptInviteComponent
} from './register/accept-invite/accept-invite.component';
import {
  AppCustomPreloader
} from './app-custom-preloader';
import {
  UserFriendlyPageComponent
} from './user-friendly-page/user-friendly-page.component';
import {
  SummaryReportComponent
} from './summary-report/summary-report.component';
import {
  PostSummaryReportComponent
} from './post-summary-report/post-summary-report.component';
import { PreviewReportComponent } from './administration/jasper-reports/preview-report/preview-report.component';
import { FrontFacingPublicReviewComponent } from './front-face/front-facing-public-review/front-facing-public-review.component';

const routes: Routes = [{

  path: 'org/:org_code',
  component: FrontFacingComponent,
  children: [{
    path: 'taxonomies',
    component: FrontFacingTaxonomiesComponent
  }, {
    path: 'taxonomies/detail/:id',
    component: FrontFacingTaxonomyDetailComponent
  }, {
    path: 'publicreview',
    component: FrontFacingPublicReviewComponent
  }, {
    path: 'pacingguides',
    component: FrontFacingPacingGuidesComponent
  }, {
    path: 'documents',
    component: FrontFacingDocumentsComponent
  }, {
    path: 'home',
    component: FrontFacingHomeComponent
  }]
}, {
  path: 'org/:org_code/login',
  component: LoginComponent
}, {
  path: 'org/:org_code/login/:reset_password',
  component: LoginComponent
}, {
  path: 'org/:org_code/register',
  component: RegisterComponent
}, {
  path: 'org/:org_code/register/:id',
  component: RegisterComponent
}, {
  path: 'org/:org_code/acceptInvite/:active_access_token/:organization_id',
  component: AcceptInviteComponent
}, {
  path: 'org/:org_code/confirm/:id',
  component: ConfirmationComponent
},
{
  path: 'org/:org_code/reset/:id',
  component: ResetPasswordComponent
},
{
  path: 'org/:org_code/tabularTreeView',
  component: TabularTreeViewComponent
}, {
  path: 'org/:org_code/app',
  canActivate: [AuthGuard],
  component: BaseComponent,
  children: [{
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      preload: true
    }
  }, {
    path: 'project',
    loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
    data: {
      preload: true // Custom property we will use to track what route to be preloaded
    }
  },
  {
    path: 'taxonomy',
    loadChildren: () => import('./taxonomy/taxonomy.module').then(m => m.TaxonomyModule),
    data: {
      preload: true
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
  },
  {
    path: 'notifications',
    component: NotificationsComponent
  },
  {
    path: 'notifications/:type',
    component: NotificationsComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'pacingguide',
    loadChildren: () => import('./pacing-guide/pacing-guide.module').then(m => m.PacingGuideModule),
  },
  {
    path: 'publicreview',
    loadChildren: () => import('./public-review/public-review.module').then(m => m.PublicReviewModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
  }
  ]
},
{
  path: '',
  component: AppComponent
}, {
  path: 'org/:org_code/public/info',
  component: UserFriendlyPageComponent
}, {
  path: 'org/:org_code/app/previewReport',
  component: PreviewReportComponent
}, {
  path: 'org/:org_code/app/preSummaryReport/:id',
  component: SummaryReportComponent
}, {
  path: 'org/:org_code/app/postSummaryReport/:id',
  component: SummaryReportComponent
}, {
  path: '**',
  component: PageNotFoundComponent
}
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
