import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { GlobalSettings } from 'src/app/global.settings';
import { TreeDataService } from 'src/app/tree-data.service';
import { CommonService } from 'src/app/common.service';
import Utils from 'src/app/utils';
import { saveAs } from 'file-saver';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-coverage-report-modal',
  templateUrl: './coverage-report-modal.component.html',
  styleUrls: ['./../../pacing-guide/create-pacing-guide/create-pacing-guide.component.scss',
    './../../administration/workflow/create-workflow/overide.scss',
    './../../project/create-project/create-project.component.scss']
})
export class CoverageReportModalComponent implements OnInit, OnChanges {
  EDIT_MODE = 'edit';
  CREATE_MODE = 'create';
  TARGET_TAXONOMY = 'Taxonomy';
  TARGET_PACING_GUIDE = 'Pacing Guide';

  currentStep = 1;
  taxonomyLoading = false;
  textLength = 50;
  sourceTaxonomytitle: string; // holds source title
  taxonomyList = []; // holds whole taxonomy list
  nodetypeList = []; // holds source taxonomy node type list
  selectedTargetTaxonomyNodetypeList = []; // holds target taxonomy node type list
  allNodetypeList; // holds all the node types available in the tenant
  pacingGuideList = []; // holds whole pacing guide list
  dropdownList = []; // holds taxonomy and pacing guide list for target dropdown
  selectedTargetTaxonomy: any; // holds selected target taxonomy data
  targetTaxonomyData: any; // holds target taxonomy's tree data
  addedTaxonomy = {
    'source': [],
    'target': []
  };
  targetNodes = []; // holds target screen's selected node list
  sourceNodes = []; // holds source screen's selected node list
  targetNodeTypes = []; // holds target screen's selected nodetype list
  sourceNodeTypes = []; // holds source screen's selected nodetype list
  taxonomyIndex = -1;
  actionMode = this.CREATE_MODE; // 'create/edit'
  downloadFileFormat = 'XLS'; // download file format
  tabItems = [this.TARGET_TAXONOMY, this.TARGET_PACING_GUIDE];
  defaultTab = this.tabItems[0];
  currentTab = this.defaultTab;
  downloadLoader = false;

  @Input() sourceTaxonomyData: any;
  @Input() reportFor = 'taxonomy';
  @ViewChild('autoCompleteTaxonomy', { static: false }) autoCompleteTaxonomy: AutoCompleteComponent;
  @ViewChild('autoCompleteNodetype', { static: false }) autoCompleteNodetype: AutoCompleteComponent;

  constructor(private service: CommonService, private treeService: TreeDataService, private sharedService: SharedService) { }

  ngOnInit() {
    this.currentStep = 1;
    this.dropdownList = [];
    this.getAllTaxonomyList();
    this.getAllPacingGuideList();
    this.getAllMetadataList();
  }

  ngOnChanges() {
    this.currentStep = 1;
    if (this.sourceTaxonomyData) {
      this.sourceTaxonomytitle = this.sourceTaxonomyData.full_statement;
    }
    this.initSourceTaxonomy();
  }

  /**
   * To get all taxonomy list for target screen taxonomy dropdown list
   */
  getAllTaxonomyList() {
    this.taxonomyList = [];
    const url = GlobalSettings.TAXONOMY_LIST;
    this.service.getServiceData(url).then((res: any) => {
      if (res) {
        this.taxonomyList = res;
        this.taxonomyList.forEach(item => {
          item.type = this.TARGET_TAXONOMY;
        });
      }
      this.onTabSelected(this.defaultTab); // by default, keep taxonomy tab as selected after getting taxonomy list
    }).catch(ex => {
      console.log('list of taxonomies ex ', ex);
    });
  }

  /**
   * To get all pacing guide list for target dropdown list
   */
  getAllPacingGuideList() {
    this.pacingGuideList = [];
    const url = GlobalSettings.PACING_GUIDES + '?project_type=2';
    this.service.getServiceData(url).then((res: any) => {
      if (res && res.projects) {
        this.pacingGuideList = res.projects;
        this.pacingGuideList.forEach(item => {
          item.title = item.project_name;
          item.type = this.TARGET_PACING_GUIDE;
        });
      }
    }).catch(ex => {
      console.log('list of pacing guides ex ', ex);
    });
  }

  /**
   * To get node type list for dropdown
   */
  getAllMetadataList() {
    const url = GlobalSettings.GET_NODETYPE_LIST;
    this.service.getServiceData(url).then((res: any) => {
      this.allNodetypeList = res.nodetype;
      this.getCurrentTaxonomyNodeTypes();
    });
  }

  getCurrentTaxonomyNodeTypes() {

    const url = GlobalSettings.TAXONOMY_NODE_TYPE_DETAILS + '/' + this.sourceTaxonomyData.id + '/' + 1 + '/' + 'node_type' + '?return_order=1';
    this.service.getServiceData(url).then((res: any) => {
      this.nodetypeList = [];
      if (res && res.nodeTypes) {
        res.nodeTypes.forEach(element => {
          this.nodetypeList.push(element);
        });
      }
      // res.defaultNodeTypesOrder.forEach(element => {
      //   for (let i = 0; i <= this.allNodetypeList.length; i++) {
      //     if (element === this.allNodetypeList[i]['node_type_id']) {
      //       if (this.nodetypeList &&
      //         (this.nodetypeList.findIndex(data => data.node_type_id === element) === -1)) {
      //         this.nodetypeList.push(this.allNodetypeList[i]);
      //       }
      //       break;
      //     }
      //   }
      // });
    });
  }


  getTargetTaxonomyNodeTypes() {
    // const type = this.currentTab.toLowerCase() === 'taxonomy' ? 1 : 1;
    const type = 1;
    this.selectedTargetTaxonomyNodetypeList = [];
    const url = GlobalSettings.TAXONOMY_NODE_TYPE_DETAILS + '/' +
      this.selectedTargetTaxonomy.document_id + '/' + type + '/' + 'node_type' + '?return_order=1';
    // if (this.currentTab.toLowerCase() === 'taxonomy') {
    this.service.getServiceData(url).then((res: any) => {
      if (res && res.nodeTypes) {
        res.nodeTypes.forEach(element => {
          this.selectedTargetTaxonomyNodetypeList.push(element);
        });
      }
      // res.defaultNodeTypesOrder.forEach(element => {
      //   for (let i = 0; i <= this.allNodetypeList.length; i++) {
      //     if (element === this.allNodetypeList[i]['node_type_id']) {
      //       if (this.selectedTargetTaxonomyNodetypeList &&
      //         (this.selectedTargetTaxonomyNodetypeList.findIndex(data => data.node_type_id === element) === -1)) {
      //         this.selectedTargetTaxonomyNodetypeList.push(this.allNodetypeList[i]);
      //       }
      //       break;
      //     }
      //   }
      // });
    });
    // }
    // else {
    //   this.selectedTargetTaxonomyNodetypeList = this.allNodetypeList;
    // }
  }

  initSourceTaxonomy() {
    if (this.sourceTaxonomyData) {
      this.taxonomyLoading = true;
      this.taxonomyIndex = -1;
      this.clearSourceNodes();
      this.addedTaxonomy.source = [];
      this.sourceTaxonomyData.document_id = this.sourceTaxonomyData.id;
      this.addedTaxonomy.source.push(
        {
          'taxonomy': this.sourceTaxonomyData,
          'items': [],
          'nodeTypes': []
        }
      );
      this.taxonomyLoading = false;
    }
  }

  /**
   * On Tree node select
   * @param event (selected tree nodes data)
   * @param type (tree is for source or target, possible values: 'source','target')
   */
  onTreeNodeSelectedEvent(event: any, type: string) {
    if (type === 'source') {
      if (this.addedTaxonomy.source[0]) {
        this.addedTaxonomy.source[0]['items'] = [];
        if (event.nodes.length > 0) {
          this.addedTaxonomy.source[0]['nodeTypes'] = [];
          this.clearSourceNodeType(); // on selected tree node, clear node type for source
          event.nodes.forEach(element => {
            this.addedTaxonomy.source[0]['items'].push(element);
          });
        }
      }
      this.sourceNodes = event.nodes;
    } else {
      if (event.nodes.length > 0) {
        this.clearTargetNodeType(); // on selected tree node, clear node type for target
      }
      this.targetNodes = event.nodes;
    }
  }

  /**
   * Function calling on auto complete dropdown change
   * @param event (emitted data from auto complete component)
   * @param fieldType (auto complete for 'nodetype' or 'taxonomy')
   * @param originName (auto complete placed in 'source' or 'destination' screen)
   */
  selectFromAutocomplete(event: any, fieldType: string, originName: string) {
    if (originName === 'source') {
      if (fieldType === 'nodetype') { // source nodetype auto complete dropdown
        if (event.length > 0) {
          this.addedTaxonomy.source[0]['items'] = [];
          this.clearSourceNodes(); // clearing source nodes with tree uncheck
          event.forEach(element => { // pushing new nodetypes
            this.sourceNodeTypes.push(element);
          });
          this.sourceNodeTypes = this.removeDuplicateFromArray(this.sourceNodeTypes, 'node_type_id');
          this.addedTaxonomy.source[0]['nodeTypes'] = this.sourceNodeTypes;
        }
      }
    } else if (originName === 'target') {
      if (fieldType === 'taxonomy') { // target taxonomy auto complete dropdown
        this.actionMode = this.CREATE_MODE;
        this.clearTargetInformation(false);
        this.selectedTargetTaxonomy = event;
        this.onTaxonomySelected();
        this.getTargetTaxonomyNodeTypes();
      } else if (fieldType === 'nodetype') { // target nodetype auto complete dropdown
        if (event.length > 0) {
          this.iterate(this.targetTaxonomyData, 0, false);
          this.targetTaxonomyData = JSON.parse(JSON.stringify(this.targetTaxonomyData));
          this.targetNodes = [];
          event.forEach(element => {
            this.targetNodeTypes.push(element);
          });
          this.targetNodeTypes = this.removeDuplicateFromArray(this.targetNodeTypes, 'node_type_id');
        }
      }
    }
  }

  /**
   * On delete element from added auto complete list
   * @param data (data to be deleted from list)
   * @param originName ('source' or 'target' screen)
   */
  deleteValueFromAutoComplete(data: any, originName: string) {
    this.autoCompleteNodetype.unselectObjInList(data);
    if (originName === 'source') {
      this.sourceNodeTypes = this.removeElemFromArray(this.sourceNodeTypes, data, 'node_type_id');
      this.addedTaxonomy.source[0]['nodeTypes'] = this.sourceNodeTypes;
    } else {
      this.targetNodeTypes = this.removeElemFromArray(this.targetNodeTypes, data, 'node_type_id');
    }
  }

  /**
   * On select target taxonomy dropdown, set tree data
   */
  onTaxonomySelected() {
    this.taxonomyIndex = this.findIndexInList(this.selectedTargetTaxonomy, this.addedTaxonomy.target, 'document_id');
    if (this.selectedTargetTaxonomy && this.selectedTargetTaxonomy.document_id) {
      /* If selected taxonomy exists in right side panel, then set edit mode otherwise set create mode */
      if (this.taxonomyIndex > -1) { // edit mode
        this.actionMode = this.EDIT_MODE;
        this.targetNodeTypes = this.addedTaxonomy.target[this.taxonomyIndex]['nodeTypes'];
        this.updateMultiSelectAsSelected('nodetype'); // setting selected of nodetype dropdown if any
        this.updateMultiSelectAsSelected('taxonomy'); // setting selected of target dropdown if any
      } else { // create mode
        this.actionMode = this.CREATE_MODE;
        this.targetTaxonomyData = null;
      }
      this.taxonomyLoading = true;
      const newUrl = GlobalSettings.GET_TREE_VIEW_ENHANCED + this.selectedTargetTaxonomy.document_id;
      this.treeService.getTreeData(newUrl, false).then((response: any) => {
        const res = response.parsedTreeNodes;
        console.log('GET_TREE_VIEW ', res);
        /*if (res && res.children) {
          Utils.sortData(res.children);
        }*/
        this.targetTaxonomyData = res;
        this.iterate(this.targetTaxonomyData, 0);
        this.taxonomyLoading = false;
      }).catch(ex => {
        console.log('taxonomy nodes ex ', ex);
      });
    } else {
      this.clearTargetInformation();
    }
  }

  iterate(current, depth, checkRequired = true) {
    if (current && current.children) {
      const children = current.children;
      if (current.id) {
        current['value'] = current.id;
        current['checked'] = false;
        current['disabled'] = false;
        current['collapsed'] = depth < Utils.EXPAND_LEVEL ? false : true;
        if (checkRequired) {
          this.updateTreeNodeCheck(current);
        }
      }
      if (current.title) {
        current['text'] = current.title.substr(0, this.textLength);
      }
      if (!current.title) {
        if (current.full_statement) {

          current['text'] = current.human_coding_scheme + ' ' + current.full_statement.substr(0, this.textLength);
        } else {
          current['text'] = current.human_coding_scheme;
        }
      }
      // console.log('------------ ', current['text']);

      for (let i = 0, len = children.length; i < len; i++) {
        this.iterate(children[i], depth + 1, checkRequired);
      }
    }
  }

  /**
   * On Continue button click
   * @param event (click event)
   */
  onContinueClick(event: any) {
    this.currentStep = this.currentStep + 1;
    this.resetArrayForMultiselect(this.nodetypeList);
  }

  /**
   * On add selection in target screen to add target which will be added in right side panel
   */
  addSelection() {
    if (this.actionMode === this.CREATE_MODE) { // Adding taxonomy
      this.addedTaxonomy.target.push(
        {
          'taxonomy': this.selectedTargetTaxonomy,
          'items': this.targetNodes,
          'nodeTypes': this.targetNodeTypes
        }
      );
    } else { // updating existing taxonomy
      this.addedTaxonomy.target[this.taxonomyIndex].items = this.targetNodes;
      this.addedTaxonomy.target[this.taxonomyIndex].nodeTypes = this.targetNodeTypes;
    }
    this.clearTargetInformation(false);
    this.clearMultiSelect('taxonomy', false);
    console.log(this.addedTaxonomy);
  }

  /**
   * To delete target from right side panel
   * @param index (index)
   */
  deleteTarget(index: number) {
    this.autoCompleteTaxonomy.unselectObjInList(this.addedTaxonomy.target[index].taxonomy);
    if (this.selectedTargetTaxonomy &&
      (this.selectedTargetTaxonomy.document_id === this.addedTaxonomy.target[index].taxonomy.document_id)) {
      /* If target data to be deleted remains as selected in form, then clear form for that deleted data */
      this.clearTargetInformation();
    }
    this.addedTaxonomy.target.splice(index, 1);
  }

  /**
   * On click added taxonomy on right side, fetch added info and update in dropdown and tree as selected and checked
   * @param selectedTargetTaxonomy (seledted taxonomy from right side panel)
   * @param index (taxonomy index in array)
   */
  onClickAddedTaxonomy(selectedTargetTaxonomy: any, index: number) {
    this.actionMode = this.EDIT_MODE;
    if (this.currentTab.toLowerCase() !== selectedTargetTaxonomy.type.toLowerCase()) {
      /* If selected target does not belong to current tab, then change current tab accordingly */
      this.currentTab = selectedTargetTaxonomy.type;
      this.setTargetDropdown(selectedTargetTaxonomy.type); // changing dropdown list according to tab
    }
    if (this.selectedTargetTaxonomy && (this.findIndexInList(this.selectedTargetTaxonomy, this.addedTaxonomy.target, 'document_id') < 0)) {
      this.autoCompleteTaxonomy.unselectObjInList(this.selectedTargetTaxonomy);
    }
    this.taxonomyIndex = index;
    this.selectedTargetTaxonomy = selectedTargetTaxonomy;
    this.autoCompleteTaxonomy.showValueInDropdown(selectedTargetTaxonomy); // show selected data in dropdown
    this.onTaxonomySelected(); // after selecting, fetch data accordingly
  }

  /**
   * Download Coverage Report API calling function
   */
  downloadCoverageReport() {
    this.downloadLoader = true;
    const url = GlobalSettings.COVERAGE_REPORT;
    const body = this.getDownloadAPIBody();
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    this.service.downloadServiceData(url, this.downloadFileFormat, 'post', body)
      .then((res: any) => {
        try {
          this.saveToFileSystem(res, this.downloadFileFormat, contentType);
        } catch (error) {
          console.log('onSaveToFileSystem error ', error);
        } finally {
          this.downloadLoader = false;
          this.closeModal();
        }
      }).catch((ex) => {
        this.closeModal();
        this.sharedService.sucessEvent.next({
          type: 'error',
          customMsg: 'Failure to download file from server response'
        });
        console.log('Coverage Report Download ex ', ex);
      });
  }

  private saveToFileSystem(response, type, contentType) {
    const blob = new Blob([response], {
      type: contentType
    });
    const title = this.sourceTaxonomytitle ? (this.sourceTaxonomytitle + '_') : '';
    saveAs(blob, (title + 'coverage_report') + '.' + type.toLowerCase());
  }

  /**
   * On tab selection for target dropdown list between Taxonomy and Pacing Guide list
   * @param tab (tab name i.e. Taxonomy and Pacing Guide)
   */
  onTabSelected(tab: string) {
    this.currentTab = tab;
    this.clearTargetInformation();
    this.setTargetDropdown(tab);
    this.updateMultiSelectAsSelected('taxonomy');
  }

  /**
   * To set target autocomplete dropdown list according to tab selection on target screen
   * @param targetType (target type i.e. Taxonomy or Pacing Guide)
   */
  setTargetDropdown(targetType: string) {
    if (targetType.toLowerCase() === this.TARGET_TAXONOMY.toLowerCase()) {
      this.dropdownList = this.taxonomyList; // setting dropdown list as taxonomy list
    } else if (targetType.toLowerCase() === this.TARGET_PACING_GUIDE.toLowerCase()) {
      this.dropdownList = this.pacingGuideList; // setting dropdown list as pacing guide list
    }
  }

  /**
   * On cancel modal
   */
  onCancelClick() {
    this.currentStep = 1;
    this.addedTaxonomy = {
      'source': [],
      'target': []
    };
    this.downloadLoader = false;
    this.taxonomyLoading = false;
    this.clearSourceInformation();
    this.clearTargetInformation();
    this.setTargetDropdown(this.defaultTab);
  }

  /**
   * To clear whole source information
   */
  clearSourceInformation() {
    this.clearSourceNodeType();
    this.clearSourceNodes();
  }

  clearSourceNodeType() {
    this.sourceNodeTypes = [];
    this.resetArrayForMultiselect(this.nodetypeList);
    this.clearMultiSelect('nodetype');
  }
  clearSourceNodes() {
    this.sourceNodes = [];
    this.iterate(this.sourceTaxonomyData, 0, false);
    this.sourceTaxonomyData = JSON.parse(JSON.stringify(this.sourceTaxonomyData));
  }

  /**
   * To clear whole target information
   */
  clearTargetInformation(isTaxonomyMulti = true) {
    this.selectedTargetTaxonomy = null;
    this.clearTargetNodeType();
    this.clearTargetNodes(isTaxonomyMulti);
  }

  clearTargetNodeType() {
    this.targetNodeTypes = [];
    this.resetArrayForMultiselect(this.nodetypeList);
    this.clearMultiSelect('nodetype');
  }
  clearTargetNodes(isTaxonomyMulti = true) {
    this.targetTaxonomyData = null;
    this.targetNodes = [];
    if (isTaxonomyMulti) {
      this.resetArrayForMultiselect(this.dropdownList);
      this.clearMultiSelect('taxonomy');
    }
  }

  /**
   * Event function to be called when clicking outside of multi select dropdown
   * @param e (click event)
   * @param type (taxonomy/nodetype dropdown)
   */
  onClickedOutside(e: any, type: string) {
    if (type === 'taxonomy') {
      if (this.autoCompleteTaxonomy) {
        this.autoCompleteTaxonomy.openPanel(false);
      }
    } else if (type === 'nodetype') {
      if (this.autoCompleteNodetype) {
        this.autoCompleteNodetype.openPanel(false);
      }
    }
  }

  /**
   * To find index of taxonomy in array
   * @param taxonomy (selected taxonomy)
   */
  findIndexInList(element: any, list: any[], uniuqeProp: string) {
    if (element) {
      return list.findIndex(obj => (obj['taxonomy'][uniuqeProp] === element[uniuqeProp]));
    } else {
      return -1;
    }
  }

  /**
   * To check whether selected node is checked and added in list(addedTaxonomy) and accordingly update
   * the tree node as checked.
   * @param node (tree node)
   */
  updateTreeNodeCheck(node: any) {
    if (this.taxonomyIndex > -1) {
      const items = this.addedTaxonomy.target[this.taxonomyIndex].items;
      for (const item of items) {
        if (item['id'] === node['id']) {
          node['checked'] = true;
          break;
        }
      }
    }
  }

  removeDuplicateFromArray(array: any[], uniuqeProp: string) {
    return array.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[uniuqeProp]).indexOf(obj[uniuqeProp]) === pos;
    });
  }

  removeElemFromArray(array: any[], elem: any, uniuqeProp: string) {
    return array.filter(element => {
      return element[uniuqeProp] !== elem[uniuqeProp];
    });
  }

  /**
   * To reset array of elements for multiselect dropdown i.e. deleting 'is_selected' attribute if any
   * @param arr (array elements)
   */
  resetArrayForMultiselect(arr: any[], elem?: any, uniuqeProp?: string) {
    if (elem && uniuqeProp) {
      for (const obj of arr) {
        if (obj[uniuqeProp] === elem[uniuqeProp]) {
          delete obj.is_selected;
          break;
        }
      }
    } else {
      for (const obj of arr) {
        delete obj.is_selected;
      }
    }
  }

  /**
   * To mark checkbox as checked for target or nodetype dropdown as per added in right side
   * @param type ('taxonomy' or 'nodetype' multiselect)
   */
  updateMultiSelectAsSelected(type: string) {
    if (type === 'taxonomy') {
      const addedTaxonomyList = [];
      for (const target of this.addedTaxonomy.target) {
        addedTaxonomyList.push(target.taxonomy);
      }
      // adding selecting mark i.e. disabled on nodetype which is added for selected taxonomy
      this.updateMultiSelect(this.dropdownList, addedTaxonomyList, 'document_id');
    } else if (type === 'nodetype') {
      const nodeTypes = this.addedTaxonomy.target[this.taxonomyIndex]['nodeTypes'];
      // adding selecting mark i.e. disabled on nodetype which is added for selected taxonomy
      this.updateMultiSelect(this.nodetypeList, nodeTypes);
    }
  }

  /**
   * To update main list with selected list for auto complete dropdown
   * @param mainArray (main array)
   * @param updateDataArray (selected array)
   */
  updateMultiSelect(mainArray: any[], updateDataArray: any[], prop = 'node_type_id') {
    for (const updateData of updateDataArray) {
      for (const mainData of mainArray) {
        if (mainData[prop] === updateData[prop]) {
          mainData.is_selected = 1;
          break;
        }
      }
    }
  }

  // Clearing multi select
  clearMultiSelect(type: string, clearAll = true) {
    if (type === 'taxonomy') {
      if (this.autoCompleteTaxonomy) {
        this.autoCompleteTaxonomy.clearSelection(clearAll);
      }
    } else if (type === 'nodetype') {
      if (this.autoCompleteNodetype) {
        this.autoCompleteNodetype.clearSelection();
      }
    }
  }

  /**
   * To build API body for download report
   */
  getDownloadAPIBody() {
    const obj = {
      source_taxonomy_id: [],
      target_taxonomy_id: []
    };
    for (const sourceObj of this.addedTaxonomy.source) {
      const result = this.buildSingleObject(sourceObj);
      obj.source_taxonomy_id.push(result);
    }
    for (const targetObj of this.addedTaxonomy.target) {
      const result = this.buildSingleObject(targetObj);
      obj.target_taxonomy_id.push(result);
    }
    return obj;
  }

  private buildSingleObject(obj: any) {
    const temp = {
      taxonomy_id: obj.taxonomy.document_id,
      item_id: [],
      node_type_id: []
    };
    for (const nodeType of obj.nodeTypes) {
      temp.node_type_id.push(nodeType.node_type_id);
    }
    for (const item of obj.items) {
      temp.item_id.push(item.id);
    }
    return temp;
  }

  closeModal() {
    if (document.getElementById('closecoverageReport')) {
      document.getElementById('closecoverageReport').click();
    }
  }

}
