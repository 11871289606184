import {
  BrowserModule
} from '@angular/platform-browser';
import {
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import {
  MomentModule
} from 'angular2-moment';
import {
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import {
  AppComponent
} from './app.component';
import {
  LoginComponent
} from './login/login.component';
import {
  AppRoutingModule
} from './app-routing.module';
/*import {
  NgxBootstrapProductTourModule,
  NgxBootstrapProductTourService
} from 'ngx-bootstrap-product-tour';*/
import {
  LoginService
} from './login/login.service';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
  HeaderComponent
} from './header/header.component';
import {
  BaseComponent
} from './base/base.component';
import {
  CommonService
} from './common.service';
import {
  FieldErrorComponent
} from './field-error/field-error.component';
import {
  RolemodalComponent
} from './role-modal/role-modal.component';
import {
  NavBarComponent
} from './nav-bar/nav-bar.component';
import {
  RegisterComponent
} from './register/register.component';
import {
  SharedService
} from './shared.service';

import {
  TreeDataService
} from './tree-data.service';

import {
  AuthenticateUserService
} from './authenticateuser.service';

import {
  NgProgressModule,
  NgProgressInterceptor
} from 'ngx-progressbar';
import {
  ProjectMappedNodeService
} from './project-mapped-node.service';
import {
  ConfirmationDialogComponent
} from './confirmation-dialog/confirmation-dialog.component';
import {
  ConfirmationDialogService
} from './confirmation-dialog/confirmation-dialog.service';

// import {
//   WalktroughService
// } from './help/walkthrough/walktrough.service';
import {
  MyDateRangePickerModule
} from 'mydaterangepicker';
import {
  PageNotFoundComponent
} from './page-not-found/page-not-found.component';
import {
  DropDownComponent
} from './common/drop-down/drop-down.component';
import {
  NotificationsComponent
} from './notifications/notifications.component';
import {
  ResetPasswordComponent
} from './reset-password/reset-password.component';


import {
  ProjectStatusComponent
} from './project/project-status/project-status.component';
import {
  Shared3Module
} from './shared/shared3/shared3.module';
import {
  InternetConnectionComponent
} from './common/internet-connection/internet-connection.component';
import {
  ConfirmationComponent
} from './confirmation/confirmation.component';

// import { PaginationComponent } from './common/pagination/pagination.component';
// import { DataTableComponent } from './common/data-table/data-table.component';

import {
  FrontFacingComponent
} from './front-face/front-facing-page/front-facing.component';
import {
  FrontFacingTaxonomiesComponent
} from './front-face/front-facing-taxonomies/front-facing-taxonomies.component';
import {
  FrontFacingPacingGuidesComponent
} from './front-face/front-facing-pacing-guides/front-facing-pacing-guides.component';
import {
  FrontFacingDocumentsComponent
} from './front-face/front-facing-documents/front-facing-documents.component';
import {
  FrontFacingHomeComponent
} from './front-face/front-facing-home/front-facing-home.component';
import {
  FrontFacingNavBarComponent
} from './front-face/front-facing-nav-bar/front-facing-nav-bar.component';
import {
  AuthGuardService
} from './auth-guard.service';
import {
  PublicModule
} from './shared/public.module';
import {
  FrontFacingTaxonomyDetailComponent
} from './front-face/front-facing-taxonomy-detail/front-facing-taxonomy-detail.component';
import {
  RecaptchaModule
} from 'ng-recaptcha';
import {
  RecaptchaFormsModule
} from 'ng-recaptcha/forms';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
/*import {
  ToasterModule,
  ToasterService
} from 'angular5-toaster';*/
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule
} from 'ngx-toastr';
import {
  ToastMessageComponent
} from './common/toast-message/toast-message.component';
import {
  NotifyUpdatesComponent
} from './notifications/notify-updates/notify-updates.component';
import {
  AccessRequestComponent
} from './notifications/access-request/access-request.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule
} from 'ng-pick-datetime';

import {
  AcceptInviteComponent
} from './register/accept-invite/accept-invite.component';
import { SearchFilterDataService } from './common/acmt-search-block/SearchFilterDataService';
import { PreLoaderModule } from './shared/preloader/pre-loader.module';
import { CachingInterceptor } from './cache/caching-interceptor';
import { CacheMapService } from './cache/cache-map.service';
import { UserFriendlyPageComponent } from './user-friendly-page/user-friendly-page.component';
import { SummaryReportComponent } from './summary-report/summary-report.component';
import { PostSummaryReportComponent } from './post-summary-report/post-summary-report.component';
import { StatusPopupWindowComponent } from './base/status-popup-window/status-popup-window.component';
import { PreviewReportComponent } from './administration/jasper-reports/preview-report/preview-report.component';
import { FrontFacingPublicReviewComponent } from './front-face/front-facing-public-review/front-facing-public-review.component';
import '../assets/js/TenantSwitchApp_1.0.0_es2015';
import { IndexedDBService } from './services/indexed-db.service';
import { CookieService } from 'ngx-cookie-service';
import { PlatformSwitchComponent } from './platform-switch/platform-switch.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    BaseComponent,
    FieldErrorComponent,
    RolemodalComponent,
    NavBarComponent,
    RegisterComponent,
    ConfirmationDialogComponent,
    PageNotFoundComponent,
    DropDownComponent,
    NotificationsComponent,
    ResetPasswordComponent,
    ProjectStatusComponent,
    InternetConnectionComponent,
    ConfirmationComponent,
    ToastMessageComponent,
    NotifyUpdatesComponent,
    AccessRequestComponent,
    FrontFacingComponent,
    FrontFacingTaxonomiesComponent,
    FrontFacingPacingGuidesComponent,
    FrontFacingDocumentsComponent,
    FrontFacingHomeComponent,
    FrontFacingNavBarComponent,
    FrontFacingTaxonomyDetailComponent,
    AcceptInviteComponent,
    UserFriendlyPageComponent,
    SummaryReportComponent,
    PostSummaryReportComponent,
    StatusPopupWindowComponent,
    PreviewReportComponent,
    FrontFacingPublicReviewComponent,
    PlatformSwitchComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgProgressModule,
    NgbModule,
    /*NgxBootstrapProductTourModule.forRoot(),*/
    MyDateRangePickerModule,
    MomentModule,
    Shared3Module,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    // ToasterModule,
    PublicModule,
    PreLoaderModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ToastrModule.forRoot()
  ],
  exports: [FormsModule,
    /* NgxBootstrapProductTourModule,*/
    MyDateRangePickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MomentModule
  ],
  // tslint:disable-next-line: max-line-length
  providers: [LoginService, CommonService, IndexedDBService, SharedService, TreeDataService, AuthenticateUserService, ProjectMappedNodeService,
    SearchFilterDataService, CookieService,
    AuthGuardService, {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: NgProgressInterceptor,
      multi: true
    },
    ConfirmationDialogService, /* NgxBootstrapProductTourService, WalktroughService, ToasterService*/
    { provide: 'Window', useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    CacheMapService,
    // { provide: Cache, useClass: CacheMapService }
  ],


  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
