import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy

} from '@angular/core';

import {
  GlobalSettings
} from '../../global.settings';
import {
  CommonService
} from '../../common.service';
import {
  SharedService
} from '../../shared.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import {
  from
} from 'rxjs';
import {
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  ConfirmationDialogService
} from '../../confirmation-dialog/confirmation-dialog.service';
import {
  AuthenticateUserService
} from '../../authenticateuser.service';
import Utils from '../../utils';
import {
  format
} from 'date-fns';
import {
  Subscription
} from 'rxjs/Subscription';

@Component({
  selector: 'app-projects-notes',
  templateUrl: './projects-notes.component.html',
  styleUrls: ['../../common/tabular-tree-side-pane/tabular-tree-side-pane.component.scss']
})
export class ProjectNotesComponent implements OnInit, OnChanges, OnDestroy {
  isClassVisible = true;
  commentBody = false;
  addNewNotes = false;
  userList: any[] = [];
  form: FormGroup;
  userName: string;
  assign_to: string;
  status: string;
  comment: string;
  tempCommentList: any[] = [];
  assignCommnet: string;
  tempAssignCommnet: string;
  commentIndex: any;
  projectId: string;
  assignToPlaceholder: string;
  archiveList = false;
  showHistoryBtn = true;
  showBackBtn = false;
  commentNote: string;
  viewCommentList = false;
  createCommentButton = false;
  selectedUser: any;
  hasCommentLimit = false;
  commentLimit = 1;
  showAssignTo = true;
  @Input() showReply = true; // holds boolean value (true/false) for reply button showing
  @Input() showDelete = true; // holds boolean value (true/false) for delete option showing
  @Input() showChangeStatus = true; // holds boolean value (true/false) whether change status option will be shown or not
  preventEdit = false;
  @Input() selectNodeItemID: string;
  @Input() editComments = true;
  @Output() notificationEvent: EventEmitter<any>;
  @Output() actionEvent: EventEmitter<any>;
  @Input() selecteNodeType = 0;
  @Input() viewLocation = 'project';
  isShow = false;
  today = format(new Date(), 'MM/DD/YYYY HH:mm:ss');
  todayDate = new Date().toISOString().split('T')[0];
  current_date = this.todayDate;
  replyToOthers: boolean;
  editOtherComments: boolean;
  editMyComments: boolean;
  delteMyComments: boolean;
  delteOtherComments: boolean;
  changeMyCommentStatus: boolean;
  changeOthersCommentStatus: boolean;
  viewMyComments = true;
  viewOtherComments = true;
  noOfMyComments = 0;
  commentActions = [{
    name: 'Open',
    value: 1
  },
  {
    name: 'Need Clarification',
    value: 2
  },
  {
    name: 'Fixed',
    value: 3
  },
  {
    name: 'Closed',
    value: 4
  }
  ];
  title = 'Notes';
  valid = false;
  selectedWorkFlowStageEvent: Subscription;
  selectedStageId = ''; // holds selected stage from project authoring
  constructor(private service: CommonService,
    private dialogService: ConfirmationDialogService,
    private AuthenticateUser: AuthenticateUserService,
    private sharedService: SharedService, private fb: FormBuilder, private acivatedRoute: ActivatedRoute) {
    this.sharedService.preventEdit.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
        if (this.preventEdit) {
          this.editComments = false;
        } else {
          // this.editComments = true;
        }
      }
    });


    this.notificationEvent = new EventEmitter();
    this.actionEvent = new EventEmitter();
    this.selectedWorkFlowStageEvent = this.sharedService.workFlowId.subscribe((event: any) => {
      if (event.workFlowId !== undefined) {
        setTimeout(() => {
          this.selectedStageId = event.currentWorkflowStage;
          this.userRoleRight();
        }, 1000);
      }
    });
  }

  showHideNav(value) {
    if (value) {
      this.openNav();
    } else {
      this.closeNav();
    }
  }

  ngOnChanges() {
    /* if (this.selectNodeItemID) {
       this.getItemComments(this.selectNodeItemID);
     }*/
    if (this.userList && this.userList.length > 0) {
      this.selectedUser = this.userList[0];
    }

  }

  ngOnInit() {
    console.log('date##', this.current_date, format(new Date(this.current_date), 'MM/DD/YYYY'));
    console.log('tempCommentList', this.tempCommentList);
    this.userName = localStorage.getItem('first_name');
    this.acivatedRoute.params.subscribe((params: Params) => {
      if (params['id'] !== undefined) {
        this.projectId = params['id'];
        this.getProjectUsers();
        this.userRoleRight();
      } else {
        //  this.router.navigate(['./app/projects']);
      }
    });

    this.form = this.fb.group({
      description: [null, Validators.compose([Validators.required])],
      //  AssignTo: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnDestroy() {
    if (this.selectedWorkFlowStageEvent) {
      this.selectedWorkFlowStageEvent.unsubscribe();
    }
  }

  checkValidation() {
    if (this.commentNote && this.commentNote.trim().length > 0) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  getItemComments(selectNodeItemID) {
    this.userRoleRight();
    setTimeout(() => {
      this.today = format(new Date(), 'MM/DD/YYYY HH:mm:ss');
      const url = GlobalSettings.ITEM_COMMENT + '/' + selectNodeItemID;
      this.tempCommentList = [];
      this.noOfMyComments = 0;
      this.service.getServiceData(url).then((res: any) => {
        this.archiveList = false;
        if (res) {
          this.notificationEvent.emit(res.length > 0 ? true : false);
          if (res.length > 0) {
            const tempObj = [];
            const comments = [];
            res.forEach(element => {
              try {
                const dateTimeArr = element.updated_at.split(' ');
                const dateArr = dateTimeArr[0].split('-');
                element.updated_at = dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0] + ' ' + dateTimeArr[1];
                element['temp_updated_at'] = format(new Date(element.updated_at), 'MM/DD/YYYY');
                element.comments.forEach(commentsDate => {
                  const dateTimeArr1 = commentsDate.updated_at.split(' ');
                  const dateArr1 = dateTimeArr1[0].split('-');
                  commentsDate.updated_at = dateArr1[1] + '/' + dateArr1[2] + '/' + dateArr1[0] + ' ' + dateTimeArr1[1];
                  commentsDate['temp_comments_updated_at'] = format(new Date(commentsDate.updated_at), 'MM/DD/YYYY');
                });
                tempObj.push(element);
              } catch (ex) {
                console.log('getItemComments ', ex);
              }
            });
            tempObj.forEach(result => {

              if (result.created_by_id === localStorage.getItem('user_id')) {
                this.noOfMyComments++;
              }
              if (this.viewMyComments && this.viewOtherComments === false) {
                if (result.created_by_id === localStorage.getItem('user_id')) {
                  this.tempCommentList.push(result);

                }
              } else if (!this.viewMyComments && this.viewOtherComments) {
                if (result.created_by_id !== localStorage.getItem('user_id')) {
                  this.tempCommentList.push(result);
                }
              } else if (this.viewMyComments && this.viewOtherComments) {
                this.tempCommentList.push(result);
              }

            });
            // console.log('comments -------------',  this.viewMyComments, this.viewOtherComments,this.tempCommentList);
          } else {
            // this.tempCommentList = [];
            this.actionEvent.emit('no_comments');
          }
        }
        if (this.showAssignTo) {
          this.showHistoryBtn = true;
        }
        this.showBackBtn = false;
        // console.log('commentList len', this.tempCommentList.length);
      }).catch(ex => {
        if (ex.status === 400) { // If no comments data found
          this.archiveList = false;
          this.notificationEvent.emit(false);
          this.actionEvent.emit('no_comments');
          if (this.showAssignTo) {
            this.showHistoryBtn = true;
          }
          // this.showBackBtn = false;
        }
        console.log('create comment', ex);
      });
    }, 1500);

  }

  formateDate(commentsDate) {
    let formatedDate: any;
    if (commentsDate['temp_comments_updated_at'] === this.todayDate) {
      formatedDate = format(new Date(commentsDate.updated_at), 'h:mm a') + ', today';
    } else if (format(new Date(commentsDate.updated_at), 'YYYY') === format(new Date(this.todayDate), 'YYYY')) {
      formatedDate = format(new Date(commentsDate.updated_at), 'h:mm a, MMM D');
    } else {
      formatedDate = format(new Date(commentsDate.updated_at), 'h:mm a, MMM D, YYYY');
    }
    return formatedDate;
  }

  getProjectUsers() {
    this.userList = [];
    this.userList.push(this.getDefaultUser());
    const url = GlobalSettings.GET_All_PROJECT_USER + '/' + this.projectId;
    this.service.getServiceData(url).then((res: any) => {

      res.forEach(element => {

        this.userList.push(element);
      });
      /*Observable.merge(res)
        .distinct((x: any) => x.user_id)
        .subscribe(y => {
          this.userList.push(y);
        });*/
    }).catch((ex) => {

    });
    this.selectedUser = this.userList[0];
  }
  addNewNote() {
    this.addNewNotes = true;
    // this.commentBody = false;
    // this.onCancel();
  }

  addComment() {

    this.addNewNotes = false;
    const tempObj = {
      assign_to: this.assign_to,
      status: 1,
      comment: this.commentNote,
      updated_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      thread_source_type: this.selecteNodeType
    };
    this.postComment(tempObj, 'create_notes');
  }

  postComment(obj, type) {
    const url = GlobalSettings.ITEM_COMMENT + '/' + this.selectNodeItemID;
    this.service.postService(url, obj).then((res: any) => {
      this.getItemComments(this.selectNodeItemID);
      this.onCancel();
      this.sharedService.sucessEvent.next({
        type: type
      });
      this.actionEvent.emit(type);
    }).catch(ex => {
      this.sharedService.sucessEvent.next({
        customMsg: ex.msg,
        type: 'error'
      });
      console.log('create comment', ex);
    });
  }

  addReplay(index, data) {
    this.commentIndex = index;
    this.commentBody = true;
    this.addNewNotes = false;
    if (data.assign_to_id !== '') {
      this.assign_to = data.assign_to_id;
      this.assignToPlaceholder = data.assign_to;
    } else {
      this.assign_to = '';
      this.assignToPlaceholder = 'Assign-to';
    }

  }

  canselComment() {
    this.addNewNotes = false;
    this.onCancel();
  }

  selectAssignTo() {
    if (this.selectedUser && this.selectedUser.user_id) {
      this.assign_to = this.selectedUser.user_id;
      from(this.userList)
        .filter((x: any) => (x.user_id === this.assign_to))
        .subscribe((result: any) => {
          this.tempAssignCommnet = result.role_name;
        });
    }
  }


  onCancel() {
    this.assign_to = '';
    this.comment = null;
    this.commentNote = null;
    if (this.userList && this.userList.length > 0) {
      this.selectedUser = this.userList[0];
    }
    this.form.reset();
  }

  addCommentForCard(rowIndex, data) {
    this.commentBody = false;
    this.commentIndex = null;

    const tempObj = {
      thread_id: data.thread_id,
      assign_to: this.assign_to,
      status: 1,
      // comments: {
      parent_id: data.thread_comment_id,
      comment: this.comment,
      created_by: this.userName,
      updated_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      thread_source_type: this.selecteNodeType
      // }
    };
    this.postComment(tempObj, 'reply_notes');
  }


  cancelCommentForCard() {
    this.commentBody = false;
    this.commentIndex = null;
    this.onCancel();
  }


  changeStatus(statusValue, thread_id, thread_comment_id, statusName?) {
    const url = GlobalSettings.ITEM_COMMENT + '/' + thread_comment_id;
    const tempObj = {
      thread_id,
      thread_source_id: this.selectNodeItemID,
      status: statusValue,
      updated_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      thread_source_type: this.selecteNodeType
    };

    this.service.putService(url, tempObj).then((res: any) => {
      this.getItemComments(this.selectNodeItemID);
      this.sharedService.sucessEvent.next({
        type: 'note_status'
      });
      this.actionEvent.emit(statusName);
    }).catch(ex => {
      console.log('create comment', ex);
      this.sharedService.sucessEvent.next({
        type: 'error',
        customMsg: ex.msg ? ex.msg : 'Error occured in status changed. Please try again later.'
      });
    });
  }


  editCommentTitle(event, thread_comment_id, thread_id, index, comments, from) {
    if (event.target.textContent && event.target.textContent.trim().length > 0) {
      if (from === 'reply') {
        if (comments[index].comment === event.target.textContent.trim()) {
          return;
        }
      } else {
        if (comments[index].item_comment === event.target.textContent.trim()) {
          return;
        }
      }
    }

    const url = GlobalSettings.ITEM_COMMENT + '/' + thread_comment_id;
    const tempObj = {
      thread_id,
      thread_source_id: this.selectNodeItemID,
      comment: event.target.textContent,
      updated_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      thread_source_type: this.selecteNodeType
    };

    if (event.target.textContent && event.target.textContent.trim().length > 0) {
      this.service.putService(url, tempObj).then((res: any) => {
        this.getItemComments(this.selectNodeItemID);
        this.sharedService.sucessEvent.next({
          type: 'update_comment'
        });
      }).catch(ex => {
        console.log('create comment', ex);
      });
    } else {
      this.cancleComment();
    }
  }

  cancleComment() {
    this.getItemComments(this.selectNodeItemID);
    this.showHideNav(false);


  }

  openNav() {
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.right = '0px';
    }
    document.getElementById('close-note').focus();
  }

  closeNav() {
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.right = '-250%';
    }
  }


  deletComment(thread_id, status) {
    this.dialogService.confirm('Confirm', 'Do you want to delete comment ?')
      .then((confirmed) => {
        if (confirmed) {
          const url = GlobalSettings.ITEM_COMMENT + '/' + thread_id;
          this.service.deleteServiceData(url).then((res: any) => {
            this.getItemComments(this.selectNodeItemID);
            this.onCancel();
            this.sharedService.sucessEvent.next({
              type: 'delete_comment'
            });
            this.actionEvent.emit(status);
          }).catch((ex) => {
            if (ex.status === 403) {
              this.sharedService.sucessEvent.next({
                type: 'permission_access'
              });
            }
            // console.log('Error while deleting the User ', ex);
          });
        } else {
          console.log('User cancel the dialog');
        }
      })
      .catch(() => {
        console.log('User dismissed the dialog');
      });

  }

  getarchiveList() {
    const url = GlobalSettings.ITEM_COMMENT + '/archive/' + this.selectNodeItemID;
    this.tempCommentList = [];
    this.service.getServiceData(url).then((res: any) => {
      this.archiveList = true;

      const tempObj = [];
      const comments = [];
      res.forEach(element => {
        element['temp_updated_at'] = format(new Date(element.updated_at), 'MM/DD/YYYY');
        element.comments.forEach(commentsDate => {
          commentsDate['temp_comments_updated_at'] = format(new Date(commentsDate.updated_at), 'MM/DD/YYYY');
        });
        tempObj.push(element);
      });

      tempObj.forEach(result => {
        this.tempCommentList.push(result);
      });
      this.showBackBtn = true;
      // this.showHistoryBtn = false;
    }).catch(ex => {
      console.log('create comment', ex);
    });
  }

  getDefaultUser() {
    return {
      user_id: undefined,
      user_name: 'Assign-to',
      user_email: undefined,
      workflow_stage_role_id: undefined,
      role_name: undefined
    };
  }

  userRoleRight() {
    if (localStorage.getItem('system_role_code') === 'PPR00') {

      const self = this;
      this.createCommentButton = false;
      this.AuthenticateUser.authenticatePublicReview('Create comment', function (val) {
        self.createCommentButton = val;
        console.log('Global Create comment', val);
        const createPro = Utils.checkProjectPermission(self.projectId,
          'Create comment', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (createPro.hasProject) {
          self.createCommentButton = createPro.valid;
          console.log('Project Create comment', createPro.valid);
        }
      });

      this.editMyComments = false;
      this.AuthenticateUser.authenticatePublicReview('Edit comment', function (val) {
        self.editMyComments = val;
        const editMyComments = Utils.checkProjectPermission(self.projectId,
          'Edit comment', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (editMyComments.hasProject) {
          self.editMyComments = editMyComments.valid;
        }
      });
      this.changeMyCommentStatus = false;
      this.viewCommentList = false;
      this.AuthenticateUser.authenticatePublicReview('View my comments', function (val) {
        self.viewMyComments = val;
        const viewMyComments = Utils.checkProjectPermission(self.projectId,
          'View my comments', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (viewMyComments.hasProject) {
          self.viewMyComments = viewMyComments.valid;
        }
      });

      this.AuthenticateUser.authenticatePublicReview('View others comments', function (val) {
        self.viewOtherComments = val;
        const viewOthComments = Utils.checkProjectPermission(self.projectId,
          'View others comments', 'public_review_permissions', this.selectedStageId, 'individual_project_role_permissions');
        if (viewOthComments.hasProject) {
          self.viewOtherComments = viewOthComments.valid;
        }
      });
      this.viewCommentList = true;
      this.replyToOthers = false;
      this.hasCommentLimit = true;
      this.showHistoryBtn = false;
      this.showAssignTo = false;
      this.showReply = false;
      this.title = 'Comments';
    } else {
      this.title = 'Notes';
      this.hasCommentLimit = false;
      this.showAssignTo = true;
      // this.showReply = true;
      // this.viewCommentList = this.AuthenticateUser.AuthenticateComment('View My Comments', this.viewLocation);
      // const viewComment = Utils.checkProjectPermission(this.projectId, 'View My Comments', 'comment_permissions',this.selectedStageId);
      // if (viewComment.hasProject) {
      //   this.viewCommentList = viewComment.valid;
      // }
      const commentPermissionName = this.viewLocation === 'project' ? 'comment_permissions' : 'pacing_guide_permissions';
      /* if (this.viewLocation !== 'project') {
         this.showAssignTo = true;
       }
       if (this.viewLocation === 'pacingguide') {
         this.showReply = true;
       }*/
      this.viewMyComments = this.AuthenticateUser.AuthenticateComment('View My Comments', this.viewLocation);
      const viewComment = Utils.checkProjectPermission(this.projectId,
        'View My Comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (viewComment && viewComment.hasProject) {
        this.viewMyComments = viewComment.valid;
      }

      this.viewOtherComments = this.AuthenticateUser.AuthenticateComment('View Others Comments', this.viewLocation);
      const viewOtrComment = Utils.checkProjectPermission(this.projectId,
        'View Others Comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (viewOtrComment && viewOtrComment.hasProject) {
        this.viewOtherComments = viewOtrComment.valid;
      }

      this.createCommentButton = this.AuthenticateUser.AuthenticateComment('Create Comments', this.viewLocation);
      const createPro = Utils.checkProjectPermission(this.projectId,
        'Create Comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (createPro && createPro.hasProject) {
        this.createCommentButton = createPro.valid;
      }
      console.log('createCommentButton viewMyComments  ', this.viewMyComments, this.viewOtherComments, this.viewLocation);
      this.replyToOthers = this.AuthenticateUser.AuthenticateComment('Reply to others comments', this.viewLocation);
      const replyToOthers = Utils.checkProjectPermission(this.projectId,
        'Reply to others comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      // console.log('replyToOthers ', replyToOthers, this.projectId, this.replyToOthers);
      if (replyToOthers && replyToOthers.hasProject) {
        this.replyToOthers = replyToOthers.valid;
      }

      this.editMyComments = this.AuthenticateUser.AuthenticateComment('Edit my comments', this.viewLocation);
      const editMyComments = Utils.checkProjectPermission(this.projectId,
        'Edit my comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (editMyComments && editMyComments.hasProject) {
        this.editMyComments = editMyComments.valid;
      }


      this.editOtherComments = this.AuthenticateUser.AuthenticateComment('Edit others\' comments', this.viewLocation);
      const editOtherComments = Utils.checkProjectPermission(this.projectId,
        'Edit others\' comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (editOtherComments && editOtherComments.hasProject) {
        this.editOtherComments = editOtherComments.valid;
      }

      this.delteMyComments = this.AuthenticateUser.AuthenticateComment('Delete my comments', this.viewLocation);
      const delteMyComments = Utils.checkProjectPermission(this.projectId,
        'Delete my comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (delteMyComments && delteMyComments.hasProject) {
        this.delteMyComments = delteMyComments.valid;
      }

      this.delteOtherComments = this.AuthenticateUser.AuthenticateComment('Delete others\' comments', this.viewLocation);
      const delteOtherComments = Utils.checkProjectPermission(this.projectId,
        'Delete others\' comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (delteOtherComments && delteOtherComments.hasProject) {
        this.delteOtherComments = delteOtherComments.valid;
      }
      this.changeMyCommentStatus = this.AuthenticateUser.AuthenticateComment('Change Status of my comment', this.viewLocation);
      const changeMyCommentStatus = Utils.checkProjectPermission(this.projectId,
        'Change Status of my comment', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (changeMyCommentStatus && changeMyCommentStatus.hasProject) {
        this.changeMyCommentStatus = changeMyCommentStatus.valid;
      }

      this.changeOthersCommentStatus = this.AuthenticateUser.AuthenticateComment('Change Status of others\' comments', this.viewLocation);
      const changeOthersCommentStatus = Utils.checkProjectPermission(this.projectId,
        'Change Status of others\' comments', commentPermissionName, this.selectedStageId, 'individual_project_role_permissions');
      if (changeOthersCommentStatus && changeOthersCommentStatus.hasProject) {
        this.changeOthersCommentStatus = changeOthersCommentStatus.valid;
      }
    }

  }

  checkCanDelete(comment) {

    const userId = comment.created_by_id; // this.getUserIdByName(comment.created_by);
    if (userId) {
      if (userId === localStorage.getItem('user_id')) {
        return this.delteMyComments;
      } else {
        return this.delteOtherComments;
      }
    }
  }

  checkCanEdit(comment) {
    const userId = comment.created_by_id; // this.getUserIdByName(comment.created_by);
    // console.log('canEdit ', userId, localStorage.getItem('user_id'), comment.comment);
    if (userId) {
      if (userId === localStorage.getItem('user_id')) {
        return this.editMyComments;
      } else {
        return this.editOtherComments;
      }
    }
  }

  checkCanChageStatus(comment) {

    if (comment.is_unregister_user === 1) {
      return false;
    }
    const userId = comment.created_by_id; // this.getUserIdByName(comment.created_by);
    if (userId) {
      if (userId === localStorage.getItem('user_id')) {
        return this.changeMyCommentStatus;
      } else {
        return this.changeOthersCommentStatus;
      }
    }
  }

  checkCanReplyToOthers(comment) {

    if (comment.is_unregister_user === 1) {
      return false;
    }
    const userId = comment.created_by_id; // this.getUserIdByName(comment.created_by_id);
    if (userId) {
      // User can always reply to his comments
      if (userId === localStorage.getItem('user_id')) {
        return true;
      } else {
        return this.replyToOthers;
      }
    }
  }


  getUserIdByName(userName) {
    for (let index = 0; index < this.userList.length; index++) {
      const element = this.userList[index];
      if (element.user_id) {
        if (element.user_name.trim() === userName.trim()) {
          return element.user_id;
        }
      }
    }
    return null;
  }
}
