import {
  Injectable
} from '@angular/core';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';

import {
  ConfirmationDialogComponent
} from './confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    keyboard: boolean = true,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm' = 'sm',
    onlyOk = false): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: dialogSize,
      centered: true,
      backdrop: 'static',
      keyboard: keyboard
      // backdropClass: 'backdrop'
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.onlyOk = onlyOk;
    (document.getElementsByTagName('ngb-modal-backdrop')[0] as HTMLElement).style.zIndex = '1051';
    (document.getElementsByTagName('ngb-modal-window')[0] as HTMLElement).style.zIndex = '1052';
    setTimeout(() => {
      focusOnCancelBtn('.btn.btn-decline');
    }, 200);

    return modalRef.result;
  }

}
