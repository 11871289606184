import {
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import {
  CommonModule
} from '@angular/common';
import {
  AutoCompleteComponent
} from '../../common/auto-complete/auto-complete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AutoCompleteComponent],
  exports: [AutoCompleteComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AutoCompleteModule {}
