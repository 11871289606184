import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ElementRef,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import Utils from '../../utils';
import {
  SharedService
} from '../../shared.service';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  filter
} from 'rxjs/operators';
import {
  PaginationComponent
} from '../pagination/pagination.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {
  isAsc = false;
  Object = Object;
  currentDataList = [];
  selectedLength: any;
  filterDataEvent: Subscription;
  searchTextEvent: Subscription;
  originalDataSet = [];
  @Input() tableCategory = '';
  @Input() showMenu;
  @Input() columnList;
  @Input() dataSet;
  @Input() optionList;
  @Input() roleStatusButton;
  @Input() dropdownItems;
  @Input() defaultSortBy;
  @Input() sortRequired = true;
  @Input() viewLocation = '';
  @Input() selectedData;
  @Input() selectedOptionList;
  @Input() filterOperationType = 'AND';
  @Input() showLoader = false;
  @Input() pagination: any;
  @Input() showCheckBox = false; // holds boolean value whether to show select checkbox or not
  @Input() uniqueField: string; // holds property name which holds unique value in table list
  @Input() isConfigurable: any = false;
  @Input() isSearchNeeded: any = true;
  @Input() searchText = '';
  @Output() clickedOption: EventEmitter<any>;
  @Output() clickedStatus: EventEmitter<any>;
  @Output() showData: EventEmitter<any>;
  @Output() linkClicked: EventEmitter<any>;
  @Output() pageChangeEvent: EventEmitter<any>;
  @Output() rowCheckedEvent: EventEmitter<any>;
  @Output() downloadEvent: EventEmitter<any>;

  @ViewChild('paginationRef', { static: false }) paginationRef: PaginationComponent;

  noOfPages;
  startIndex;
  lastIndex;
  isFirstTime = true;
  minItemPerPage;
  // searchText;
  filterData = [];
  filterList = {};
  filterArray = [];
  lastSortBy: any;
  checkedRows = []; // holds list of checked rows
  pageIndex = -1; // holds current page index
  checkedPages = []; // holds list of checked/unchecked pages where all rows are selected or not
  maxPages = -1; // holds number of maximum pages
  itemPerPage = 0;
  disabledAllCheckbox = false; // holds disabled for all checkbox
  showAllCheckBox = false; // to show or hide all checkbox
  tabindex; // holds index
  dropdownSettings = {
    textField: 'label',
    showLabel: false,
    buttonType: 'icon'
  };
  tempObj: any = {
    pageDetail: this.pagination,
    lastSortBy: this.defaultSortBy,
    filterList: this.filterArray

  };
  buttonId = ''; // holds id of button on which operation is performed
  moreOptionAvailable = true; // holds boolean if more option is available for any data table item

  constructor(private sharedService: SharedService, private _elem: ElementRef) {
    this.clickedOption = new EventEmitter<any>();
    this.clickedStatus = new EventEmitter<any>();
    this.showData = new EventEmitter<any>();
    this.linkClicked = new EventEmitter<any>();
    this.pageChangeEvent = new EventEmitter<any>();
    this.rowCheckedEvent = new EventEmitter<any>();
    this.downloadEvent = new EventEmitter<any>();
    this.minItemPerPage = Utils.PAGINATION_LOWER_LIMIT;
    this.filterDataEvent = this.sharedService.filterDataTable.subscribe((res: any) => {
      this.filterData = res.data;
    });

    this.searchTextEvent = this.sharedService.searchTextEvent.subscribe((res: any) => {
      this.searchText = res.text;
      if (res.text) {
        this.disabledAllCheckbox = true;
      } else {
        this.disabledAllCheckbox = false;
      }
    });

  }

  ngOnInit() {
    this.searchText = '';
    this.startIndex = this.pagination && this.pagination.start > 1 ? this.pagination.start : 0;
    this.lastIndex = this.pagination ? this.pagination.last : Utils.PAGINATION_LOWER_LIMIT;
    if (this.dataSet && this.dataSet.length <= this.minItemPerPage) {
      this.lastIndex = this.dataSet.length;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSet && (JSON.stringify(changes.dataSet.currentValue) !== JSON.stringify(changes.dataSet.previousValue))) {
      this.onFocusMoreoption();
    }
    if (this.dataSet && this.dataSet.length > 0) {
      this.originalDataSet = [];
      this.moreOptionAvailable = false;
      this.dataSet.forEach(element => {
        if (element.showOptions) {
          this.moreOptionAvailable = true;
        }
        element.checked = false; // initializing row checked as false
        this.originalDataSet.push(element);
      });
      this.columnList.forEach(col => {
        if (this.defaultSortBy && col.name === this.defaultSortBy.name) {
          col.isAsc = this.defaultSortBy.isAsc;
        } else {
          col.isAsc = false;
        }
        if (col.canFilter && (!changes.columnList || !this.filterList[col['propName']])) {
          this.filterList[col['propName']] = [];
          this.dataSet.forEach(data => {
            if (data[col['propName']] || data[col['propName']] === 0) {
              data[col['propName']].toString().split(', ').forEach(element => {
                if (!this.filterList[col['propName']].find((obj) => element.trim() === (obj.label))) {
                  this.filterList[col['propName']].push({
                    colName: col['propName'],
                    label: element.trim(),
                    is_selected: 0
                  });
                }
              });
            }

            if (this.filterList[col['propName']].length > 1) {
              col['showFilter'] = true;
            } else {
              col['showFilter'] = false;
            }
          });
          if (this.filterList[col['propName']].length) {
            Utils.sortDataArray(this.filterList[col['propName']], 'label', false, true);
          }
        }
      });
      if (this.sortRequired) {
        if (this.defaultSortBy) {
          this.columnList.forEach(element => {
            if (element.name === this.defaultSortBy.name) {
              element.isAsc = !this.defaultSortBy.isAsc;
              this.sortData(element);
            }
          });
        } else {
          this.defaultSortBy = this.columnList[0];
          this.sortData(this.columnList[0]);
        }
      }
      if (this.dataSet.length < this.minItemPerPage) {
        this.selectedLength = this.dataSet.length;
      } else {
        this.selectedLength = this.minItemPerPage;
      }
      if (this.dataSet.length <= this.minItemPerPage) {
        this.lastIndex = this.dataSet.length;
      }
      this.resizeDataTable();
    }
  }

  ngOnDestroy() {
    if (this.filterDataEvent) {
      this.filterDataEvent.unsubscribe();
    }
  }
  sortData(col) {
    // col.isAsc = true;
    if (col) {
      this.columnList.forEach(column => {
        column.isSorted = false;
      });
      col.isAsc = !col.isAsc;
      col.isSorted = true;
      Utils.sortDataArray(this.dataSet, col.propName, col.type === 'date', col.isAsc);
      this.emitPageChanges(null, col, null);
    }
  }

  changeStatus(p1, p2) {
    const statusDetails = {
      val: p1,
      id: p2
    };
    this.clickedStatus.emit(statusDetails);
  }
  onProjectSelected(data) {
    this.selectedData = data;
    if (!data.is_locked) {
      this.linkClicked.emit(data);
    } else {
      this.sharedService.sucessEvent.next({
        customMsg: 'This taxonomy is getting updated.',
        type: 'error'
      });
    }
  }
  onOptionClicked(event, option, data, index, optionName) {
    const opt = {
      clickedOn: option,
      data,
      index,
      event
    };
    this.buttonId = optionName.split(' ').join('') + '_' + this.tabindex;
    localStorage.setItem('datatableItem', JSON.stringify({
      idToClick: 'dropdown_' + this.tabindex,
      idToFocus: this.buttonId,
      option: optionName,
      viewLocation: this.viewLocation
    }));
    console.log('viewLocation', this.viewLocation);

    this.clickedOption.emit(opt);
  }

  changeDateToLocalTimeZone(date) {
    return Utils.changeDateToLocalTimeZone(date);
  }

  resizeDataTable() {
    const length = this.selectedLength < this.dataSet.length ? this.selectedLength : this.dataSet.length;
    this.currentDataList = [];
    for (let i = 0; i < length; i++) {
      this.currentDataList.push(this.dataSet[i]);
    }
  }

  // getTableLength(option) {
  //   this.selectedLength = option.value;
  //   this.resizeDataTable();
  // }

  getCurrentRequestDetails(data) {
    this.showData.emit(data);
  }

  showDataInRange(event) {
    console.log('showDataInRange ', event);
    this.startIndex = event.start - 1;
    this.lastIndex = event.last;
    this.maxPages = event.maxPages; // getting maximum pages count
    this.pageIndex = event.pageIndex - 1;
    this.itemPerPage = event.itemPerPage;
    this.checkedPages = [];
    for (let i = 0; i < this.maxPages; i++) {
      this.checkedPages[i] = false;
    }
    this.checkedPages[this.pageIndex] = this.isAllChecked(this.startIndex, this.lastIndex);
    this.emitPageChanges(event, null, null);
  }

  getColumnFilterObj() {
    const obj = {};
    this.columnList.forEach(column => {
      obj[column.propName] = this.searchText;
    });
    // console.log('filter values', obj);
    return obj;
  }

  // toggleDropdown(col, action) {
  //   switch (action) {
  //     case 'toggle':
  //       this.columnList.filter(column => column !== col).forEach(element => {
  //         element.openDropdown = false;
  //       });
  //       col.openDropdown = !col.openDropdown;
  //       break;
  //     case 'open':
  //       col.openDropdown = true;
  //       break;
  //     case 'close':
  //       col.openDropdown = false;
  //       break;
  //     default:
  //   }
  // }

  /**
   * Function to filter data based on selected filters 
   * It applied OR condition withing same coulumn filters and AND condition withing different column filters
   * @param data selected filter
   * @param column current column
   */
  filterDataList(data, column) {
    let newArr = [];
    this.filterArray = data;
    let hasFilter = false; // to check any filter selected from column
    // First filter on current column filters and (OR condition ) add all the matched object to newArr 
    this.filterArray.forEach(option => {
      if (column.propName === option.colName && option.is_selected) {
        hasFilter = true;
        newArr = newArr.concat(this.filter(option, this.originalDataSet));
      }
    });
    // Get Unique obj array
    newArr = Array.from(new Set(newArr));
    // Now filter over other filters and use newArr as data set (AND condition)
    let arr = [];
    newArr = hasFilter ? newArr : this.originalDataSet;
    hasFilter = false;
    this.filterArray.forEach(option => {

      if (column.propName !== option.colName && option.is_selected) {
        hasFilter = true;
        arr = arr.concat(this.filter(option, newArr));
      }
    });
    // Get Unique obj array
    arr = Array.from(new Set(arr));
    if (hasFilter) {
      newArr = arr;
    }
    this.dataSet = this.filterArray.length ? newArr : this.originalDataSet;
    if (this.dataSet.length > 1) {
      Utils.sortDataArray(this.dataSet, this.defaultSortBy.propName, this.defaultSortBy.type === 'date', this.defaultSortBy.isAsc);
    }
    if (this.dataSet && this.dataSet.length <= this.minItemPerPage) {
      this.startIndex = 0;
      this.lastIndex = this.dataSet.length;
    }
    if (this.paginationRef) {
      this.paginationRef.updatePagination(this.dataSet.length);
    }

  }

  /**
   * TODO need to fix lint issue
   * @param option
   * @param dataSet
   */
  filter(option, dataSet) {
    const dataSetArr = [];
    dataSet.filter(obj => {
      const arr = obj[option['colName']] || (obj[option['colName']] === 0) ? obj[option['colName']].toString().split(',') : [];

      arr.forEach(element => {
        if (element && element.trim().toLowerCase() === option['label'].toLowerCase()) {
          dataSetArr.push(obj);
        }
      });

    });
    return dataSetArr;
  }

  /**
   * On filtered options selected
   * @param event (filtered options selected data)
   * @param column (current column from where filter is triggered)
   */
  onFilterOptionSelect(event: any, column: any) {
    let filterCriteria = [];

    if (this.filterOperationType === 'OR' && this.selectedOptionList && this.selectedOptionList.length > 0) {
      // add selected options to the filter list
      event.selectedData.forEach(option => {
        if (!this.filterArray.find((obj) => obj.label.trim() === option.label.trim())) {
          this.filterArray.push(option);
        }
      });

      // remove unselected options from the filter list
      this.filterArray = this.filterArray.filter(obj => obj.is_selected === 1);

      this.filterDataList_OR();
    } else {
      this.filterArray = [];
      event.selectedData.forEach(option => {
        if (!this.filterArray.find((obj) => obj.label.trim() === option.label.trim())) {
          this.filterArray.push(option);
        }
      });

      // remove unselected options from the filter list
      this.filterArray = this.filterArray.filter(obj => obj.is_selected === 1);
      filterCriteria = this.filterArray;
      this.filterDataList(filterCriteria, column);
      this.emitPageChanges(this.pagination, null, filterCriteria);
    }

  }

  filterDataList_OR() {
    const newArr = [];
    this.filterArray.forEach(option => {
      this.originalDataSet.filter(obj => {
        if (obj[option.colName].toLowerCase().includes(option.label.toLowerCase().trim())) {
          if (newArr.indexOf(obj) === -1) {
            newArr.push(obj);
          }
        }
      });
    });
    this.dataSet = newArr.length ? newArr : this.originalDataSet;
    Utils.sortDataArray(this.dataSet, this.defaultSortBy.propName, this.defaultSortBy.type === 'date', this.defaultSortBy.isAsc);
  }

  emitPageChanges(pagination: any, sortBy: any, filterBy: any) {
    // let changes: any;
    if (pagination) {
      this.pageIndex = pagination.pageIndex - 1;
      this.tempObj.pageDetail = pagination;
    }
    if (sortBy) {
      this.defaultSortBy = sortBy;
      this.tempObj.lastSortBy = sortBy;
    }
    if (filterBy) {
      this.tempObj.filterList = this.filterArray;
    }
    this.pageChangeEvent.emit(this.tempObj);
  }

  /**
   * On checkbox clicked for 'single row' and 'all paginated rows' checked/unchecked
   * For Single row select, value field is mandatory and for all, value field is not required 
   * @param type (type of checkbox clicked i.e. 'single' or 'paginatedAll')
   * @param event (clicked event)
   * @param value (selected row which is required only for single row checkbox)
   */
  onRowChecked(type: string, event: any, value?: any) {
    if (type === 'single' && value) {
      value.checked = event.target.checked;
      this.doCheckForSingle(value);
    } else if (type === 'paginatedAll' && !this.disabledAllCheckbox) {
      this.checkedPages[this.pageIndex] = event.target.checked;
      this.doCheckForPagedData(event.target.checked);
    }
    this.emitCheckedRows();
  }

  // To get index index of selected element in row checked list
  getIndexOfChecked(value: any): number {
    return this.checkedRows.findIndex(elem => (elem[this.uniqueField] === value[this.uniqueField]));
  }

  // On single row check
  doCheckForSingle(value: any) {
    const index = this.getIndexOfChecked(value);
    if (value.checked && (index < 0)) { // checked clicked
      this.checkedRows.push(value); // push row value if not exists in emitted list
    } else if (!value.checked && index > -1) { // unchecked clicked
      this.checkedRows.splice(index, 1); // remove row value if exists in emitted list
    }
    this.checkedPages[this.pageIndex] = this.isAllChecked(this.startIndex, this.lastIndex);
  }

  // On all check box clicked, check/uncheck for paginated data list which is visible
  doCheckForPagedData(isChecked: boolean) {
    if (isChecked) { // checked clicked
      for (let i = this.startIndex; i < this.lastIndex; i++) {
        this.dataSet[i].checked = true;
        const index = this.getIndexOfChecked(this.dataSet[i]);
        if (index < 0) {
          this.checkedRows.push(this.dataSet[i]); // push row value if not exists in emitted list
        }
      }
    } else { // unchecked clicked
      for (let i = this.startIndex; i < this.lastIndex; i++) {
        this.dataSet[i].checked = false;
        const index = this.getIndexOfChecked(this.dataSet[i]);
        if (index > -1) {
          this.checkedRows.splice(index, 1); // remove row value if exists in emitted list
        }
      }
    }
  }

  emitCheckedRows() {
    this.rowCheckedEvent.emit({ data: this.checkedRows, pageIndex: this.pageIndex, temp: this.checkedPages });
  }

  // Clear all selection
  clearAllSelection() {
    if (this.dataSet) {
      this.checkedRows = [];
      for (const elem of this.dataSet) {
        elem.checked = false;
      }
      for (let i = 0; i < this.maxPages; i++) {
        this.checkedPages[i] = false;
      }
    }
    this.emitCheckedRows();
  }

  // To check all rows checked or unchecked between specific range for page (i.e. startindex and lastindex)
  isAllChecked(startIndex: number, lastIndex: number) {
    let checked = true;
    for (let i = startIndex; i < lastIndex; i++) {
      if (this.dataSet[i] && this.dataSet[i].checked) {
        continue;
      } else {
        checked = false;
        break;
      }
    }
    return checked;
  }

  // On focus any row's button, select that data
  onFocus(data: any, rowIndex: number) {
    this.tabindex = rowIndex;
    this.selectedData = data;
  }

  setWidth(column) {
    if (this.isConfigurable) {
      return column.width ? (column.width + 'px') : this.calWidth();
    } else {
      return column.width;
    }
  }

  setData(data) {
    this.dataSet = data;
    if (this.dataSet && this.dataSet.length <= this.minItemPerPage) {
      this.lastIndex = this.dataSet.length;
    }
  }

  calWidth() {
    const tableWidth = document.getElementById('dataTable') ? document.getElementById('dataTable').offsetWidth : 0;
    const optionColWidth = document.getElementById('optionColumn') ? document.getElementById('optionColumn').offsetWidth : 0;
    const dataColWidth = tableWidth - optionColWidth;
    const noOfCol = this.columnList.length;
    const defaultColWidth = (dataColWidth / noOfCol);
    return defaultColWidth + 'px';
  }

  onFocusMoreoption() {
    // this focus will be applied in individual component where data table is applied
    if (localStorage.getItem('datatableItem') && localStorage.getItem('datatableItem').length > 0) {
      setTimeout(() => {
        Utils.focusOnDataTableElement();
      }, 500);
    }
  }

  setDownloadEvent(data) {
    this.downloadEvent.next({
      data
    });
  }
}
