import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SharedService } from '../../shared.service';
import { GlobalSettings } from '../../global.settings';
import { CommonService } from '../../common.service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { AuthenticateUserService } from '../../authenticateuser.service';
import Utils from '../../utils';

@Component({
  selector: 'app-public-review',
  templateUrl: './public-review.component.html'
})
export class PublicReviewComponent implements OnInit, OnChanges {

  @Input() publicReviewPermission = true;
  @Input() taxonomyId;

  @Output() showProtipEvent = new EventEmitter<any>();

  listOfColumn = [ // holds table colums
    {
      name: 'Public Review',
      propName: 'public_review_name',
      class: '',
      width: '30%',
      type: 'text'
    },
    {
      name: 'Initiated by',
      propName: 'initiated_by',
      class: '',
      type: 'text'
    },
    {
      name: 'Completed by',
      propName: 'completed_by',
      class: '',
      type: 'text'
    },
    {
      name: 'Start date',
      propName: 'start_date',
      class: '',
      type: 'text'
    },
    {
      name: 'End date',
      propName: 'end_date',
      class: '',
      type: 'text'
    },
    {
      name: 'Status',
      propName: 'status_name',
      class: '',
      type: 'text'
    }
  ];
  preventEdit = false;
  publicReviewList = [];
  loadingPublicReview = false;
  optionList = [];
  NO_DATE_VALUE = '0000-00-00 00:00:00'; // holds default value of date if not selected
  activeReviewPermission = true; // permission for showing active public review
  archivedReviewPermission = true; // permission for showing completed public review
  showProtip = false;

  constructor(
    private sharedService: SharedService,
    private service: CommonService,
    private router: Router,
    private dialogService: ConfirmationDialogService,
    private authenticateUser: AuthenticateUserService) {

    this.sharedService.preventEdit.subscribe((event: any) => {
      if (event.preventEdit !== undefined) {
        this.preventEdit = event.preventEdit;
      }
    });
  }

  ngOnInit() {
    this.userRoleRight();
    this.optionList = [ // table's additional options
      {
        name: 'View Details',
        type: 'viewDetails',
        check: 'isView'
      },
      {
        name: 'Stop Public Review',
        type: 'stopReview',
        check: 'isEndReview'
      }
    ];
  }

  ngOnChanges() {
    this.getPublicReviewHistory();
  }

  /**
   * To get public review list based on taxonomy id
   */
  getPublicReviewHistory() {
    this.publicReviewList = [];
    if (this.taxonomyId) {
      this.loadingPublicReview = true;
      const url = GlobalSettings.PUBLIC_REVIEW_HISTORY + '/' + this.taxonomyId;
      this.service.getServiceData(url).then((res: any) => {
        if (res && res.length > 0) {
          res.forEach(element => {
            element['isEndReview'] = false;
            if (element['status'] === 1) {
              element['status_name'] = 'Scheduled';
              element['isEndReview'] = true;
            } else if (element['status'] === 2) {
              element['status_name'] = 'In progress';
              element['isEndReview'] = true;
            } else if (element['status'] === 3) {
              element['status_name'] = 'Completed';
            }
            if (element['end_date'] === this.NO_DATE_VALUE) {
              element['end_date'] = '';
            }
            element['isView'] = true;
            element.showOptions = (!element['isView'] && !element['isEndReview']) ? false : true;
            if ((element['status'] === 1 || element['status'] === 2) && this.activeReviewPermission === true) {
              this.publicReviewList.push(element);
            }
            if ((element['status'] === 3) && this.archivedReviewPermission === true) {
              this.publicReviewList.push(element);
            }
            // this.publicReviewList.push(element);
          });
        }
        this.showProtip = this.publicReviewList.length > 0 ? false : true;
        this.showProtipEvent.emit(this.showProtip);
        this.loadingPublicReview = false;
      }).catch((ex) => {
        console.log('getPublicReviewHistory ex', ex);
      });
    }
  }

  /**
   * On Public review modal open
   */
  onPublicReviewModalOpen() {
    this.sharedService.editTitle.next(false);
    setTimeout(() => {
      focusOnModalCloseBtn();
    }, 500);
  }

  onOptionClicked(event) {
    console.log(event);
    const data = event.data;
    switch (event.clickedOn) {
      case 'viewDetails':
        this.onViewDetails(data.project_id);
        break;
      case 'stopReview':
        this.onStopReview(data);
        break;
      default:
    }
  }

  /**
   * On view details of public review
   * @param id (project id)
   */
  onViewDetails(id) {
    if (id) {
      const path = this.service.getOrgDetails() + '/' + Utils.ROUTE_PUBLIC_REVIEW_PENDING;
      this.router.navigate([path, id]);
    }
  }

  /**
   * To stop public review
   * @param reviewData
   */
  onStopReview(reviewData) {
    let msg = '';
    if (reviewData) {
      if ((reviewData.end_date.trim() === this.NO_DATE_VALUE) || (reviewData.end_date.trim() === '')) { // no end date
        msg = 'Are you sure you want to stop the public review?';
      } else { // with end date
        msg = 'This review is scheduled to stop on ' + reviewData.end_date + '. Are you sure you want to stop?';
      }
      this.dialogService.confirm('Confirm', msg)
        .then((confirmed) => {
          if (confirmed) {
            const bodyObj = {
              'document_id': reviewData.document_id,
              'project_id': reviewData.project_id,
              'review_number': reviewData.review_number
            };
            const url = GlobalSettings.STOP_PUBLIC_REVIEW;
            this.service.postService(url, bodyObj).then((res: any) => {
              this.sharedService.sucessEvent.next({
                type: 'stop_public_review'
              });
              this.getPublicReviewHistory();
              this.preventEdit = false;
            }).catch((ex) => {
              console.log('Stop Public Review error', ex);
            });
          } else {
            console.log('User cancel the dialog');
          }
        })
        .catch(() => {
          console.log('User dismissed the dialog');
        });
    }
  }

  // Permission setup
  userRoleRight() {
    const self = this;
    this.activeReviewPermission = false;
    this.authenticateUser.authenticatePublicReview('View Active Public Review Taxonomies', function (val) {
      self.activeReviewPermission = val;
    });

    this.archivedReviewPermission = false;
    this.authenticateUser.authenticatePublicReview('View Archived Public Review Taxonomies', function (val) {
      self.archivedReviewPermission = val;
    });
  }

  /**
   * To check any active public review is present or not
   */
  hasAnyActivePublicReview(list: any) {
    let hasActivePublicReview = false;
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        if ((list[i]['status'] === 1) || (list[i]['status'] === 2)) {
          hasActivePublicReview = true;
          break;
        }
      }
    }
    return hasActivePublicReview;
  }

}
